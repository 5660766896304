import {useTranslation} from "react-i18next";
import {Styled} from "../BecomeSupplier.styles";

const WhyBecomeSupplier = () => {

    const {t} = useTranslation()

    const reasonsList = [
        'lbl_become_supplier_why_global_reach',
        'lbl_become_supplier_why_easy_operating',
        'lbl_become_supplier_why_strategic_alliance',
        'lbl_become_supplier_why_increased_visibility',
        'lbl_become_supplier_why_sustainable_practices'
    ]

    return (
        <Styled.Why__Us__Holder>
            <Styled.Become__Supplier__Why__Us>
                {t('lbl_become_supplier_why')}:
            </Styled.Become__Supplier__Why__Us>
            <Styled.Become__Supplier__Why__Us__Holder>
                {reasonsList.map((option: string) => (
                    <Styled.Become__Supplier__Why__Us__Option
                        key={option}
                    >
                        {t(option).split(':').map((item: string, index: number) => index === 0 ?
                            <span style={{fontWeight: 'bold'}}>
                                <span
                                    style={{
                                        marginRight: '20px'
                                    }}>
                                    {'•'}
                                </span>
                                {item}{':'}
                            </span> : item)}
                    </Styled.Become__Supplier__Why__Us__Option>
                ))}
            </Styled.Become__Supplier__Why__Us__Holder>
        </Styled.Why__Us__Holder>
    )
}

export default WhyBecomeSupplier