import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Button from "../../lib/UI/Button";
import {isMobileOnly} from "react-device-detect";

const Region__Options__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  width: calc(100% - 20px);
  height: auto;
  padding: 10px;
  gap: 10px;
`
const Filter__Button = styled(Button)`
  margin: 10px 10px 10px auto;
  padding: 0 15px;

`

const FiltersHolder = styled(FlexBox)<{ isOpen: boolean }>`
  flex-direction: column;
  flex-wrap: nowrap;
  padding: ${({isOpen}) => isOpen ? '  15px 10px 15px 10px' : '0px 10px 0px 10px'};;
  transition: height 200ms, padding-bottom 200ms, padding-top 200ms;
  overflow: hidden;
  height: ${({isOpen}) => isOpen ? 'auto' : '0px'};
`
const Filters__Content = styled(FlexBox)`
  flex-direction: column;
  height: auto;
  width: 100%;
`

const Filters__Controls__Holder = styled(FlexBox)`
  gap: 10px;
  flex-direction: ${isMobileOnly ? 'column' : 'initial'};
  margin-left: ${isMobileOnly ? 'unset' : 'auto'};;
  flex-wrap: nowrap;
`
const Filter__Controls__Button = styled(Button)`
  padding: 0 15px;
  min-width: 100px;
`
export const Styled = {
    Filter__Controls__Button,
    Filters__Controls__Holder,
    Filters__Content,
    FiltersHolder,
    Filter__Button,
    Region__Options__Content__Holder
}