import {Styled} from "../../AdminPage.styles"
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Admin__options} from "../../../../lib/Const/AdminOptions";

const Options = () => {

    const {t} = useTranslation()

    const navigate = useNavigate()


    return (
        <Styled.Options__Holder>
            <Styled.Options__Title>
                {t('lbl_business_options_title')}
            </Styled.Options__Title>

            <Styled.Options__Content__Holder>
                {Admin__options.map((option: any) => {

                    return (
                        <Styled.Option
                            onClick={() => navigate(option.pathname)}
                        >
                            <Styled.Option__Align__Content>
                                <Styled.Option__Icon
                                    icon={option.icon}
                                />
                                <Styled.Option__Title>
                                    {option.title}
                                </Styled.Option__Title>
                            </Styled.Option__Align__Content>
                        </Styled.Option>
                    )
                })}
            </Styled.Options__Content__Holder>
        </Styled.Options__Holder>
    )
}

export default Options
