import {Styled} from "./Navigation.styles"
import PageHolder from "../../lib/UI/PageHolder";
import PublicNavigation from "./PublicNavigation";
import {useLocation} from "react-router-dom";
import {Admin__Page} from "../../lib/Const/Routes/Routes";
import BusinessNavigation from "./BusinessNavigation";


const Navigation = () => {


    const location = useLocation()

    return (
        <Styled.Navigation__Place__Holder>
            <Styled.Navigation__Fixed__Holder>
                <PageHolder>
                    <Styled.Navigation__Content__Holder>
                        {location.pathname.includes(Admin__Page) ? (
                            <BusinessNavigation/>
                        ) : (
                            <PublicNavigation/>
                        )}
                    </Styled.Navigation__Content__Holder>
                </PageHolder>
            </Styled.Navigation__Fixed__Holder>
        </Styled.Navigation__Place__Holder>

    )
}

export default Navigation