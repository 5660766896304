import {Styled} from "./TravelTips.styles"
import CountryOption from "./CountryOption";
import {faChevronDown, faChevronUp} from "@fortawesome/pro-solid-svg-icons";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import BoxFilters from "../../components/BoxFilters";
import {
    faGlobeAfrica,
    faGlobeAmericas,
    faGlobeAsia,
    faGlobeEurope,
    faGlobeOceania
} from "@fortawesome/pro-light-svg-icons";
import {includes} from "lodash-es";

const PerCountry = () => {

    const [filters, setFilters] = useState({isOpen: false, selected: []})

    const options = [
        {
            title: 'Europe',
            subOptions: [
                {
                    countryName: 'Poland',
                    countryFlag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Flag_of_Poland_%28normative%29.svg/2000px-Flag_of_Poland_%28normative%29.svg.png',
                    countryImage: 'https://www.studyinpoland.pl/en/images/articles/why-poland-new.jpg'
                },
                {
                    countryName: 'Germany',
                    countryFlag: 'https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/1200px-Flag_of_Germany.svg.png',
                    countryImage: 'https://www.germany.travel/media/redaktion/staedte_kultur_content/Berlin_Brandenburger_Tor_im_Sonnenuntergang_Leitmotiv_German_Summer_Cities.jpg'
                },
                {
                    countryName: 'Greece',
                    countryFlag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Greece.svg/1200px-Flag_of_Greece.svg.png',
                    countryImage: 'https://images.ctfassets.net/wv75stsetqy3/18jOEJrhKM7WA81nkCKZV8/6f70df258ed1233d5c3a8e3f01298b06/Greece.jpg?q=60&fit=fill&fm=webp'
                },
                {
                    countryName: 'Spain',
                    countryFlag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Bandera_de_Espa%C3%B1a.svg/800px-Bandera_de_Espa%C3%B1a.svg.png',
                    countryImage: 'https://i.natgeofe.com/k/e800ca90-2b5b-4dad-b4d7-b67a48c96c91/spain-madrid_16x9.jpg?w=1200'
                },
                {
                    countryName: 'Austria',
                    countryFlag: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3gQAQAqdyk0MZVavC0_W7FB6Gp_pvWnVyzA&s',
                    countryImage: 'https://www.national-geographic.pl/media/cache/slider_big/uploads/media/default/0014/14/austria-z-czego-slynie-ten-europejski-kraj-przewodnik-po-austrii-fot-getty-images.jpeg'
                },
            ]
        },
        {
            title: 'Asia',
            subOptions: [
                {
                    countryName: 'Japan',
                    countryFlag: 'https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/Flag_of_Japan.svg/640px-Flag_of_Japan.svg.png',
                    countryImage: 'https://www.state.gov/wp-content/uploads/2019/04/Japan-2107x1406.jpg'
                },
                {
                    countryName: 'Thailand',
                    countryFlag: 'https://cdn.britannica.com/38/4038-050-BDDBA6AB/Flag-Thailand.jpg',
                    countryImage: 'https://www.travelandleisure.com/thmb/nDDNqO2EctQhiIfZrxeXTF47zhE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/TAL-koh-phi-phi-PLACESTHAILAND1023-09b9d347b3cd4844b4ae19e4e06a9a6d.jpg'
                },
                {
                    countryName: 'India',
                    countryFlag: 'https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png',
                    countryImage: 'https://i.natgeofe.com/k/42e832f5-fd48-43ff-b338-091bdf4048ca/india-tajmahal_16x9.jpg?w=1200'
                },
                {
                    countryName: 'China',
                    countryFlag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Flag_of_the_People%27s_Republic_of_China.svg/640px-Flag_of_the_People%27s_Republic_of_China.svg.png',
                    countryImage: 'https://cdn.britannica.com/89/179589-138-3EE27C94/Overview-Great-Wall-of-China.jpg?w=800&h=450&c=crop'
                },
                {
                    countryName: 'Vietnam',
                    countryFlag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Vietnam.svg/1200px-Flag_of_Vietnam.svg.png',
                    countryImage: 'https://i.natgeofe.com/k/139b8a25-92d6-4fd5-875f-b747e5d6245a/vietnam-rice-field.jpg'
                },
            ]
        }
    ]

    const filterOptions: any = [
        {
            title: 'Filters',
            priority: true,
            options: [
                {
                    type: 'continent_europe',
                    icon: faGlobeEurope,
                    color: '#1991bb'
                },
                {
                    type: 'continent_asia',
                    icon: faGlobeAsia,
                    color: '#1991bb'
                },
                {
                    type: 'continent_america',
                    icon: faGlobeAmericas,
                    color: '#1991bb'
                },
                {
                    type: 'continent_africa',
                    icon: faGlobeAfrica,
                    color: '#1991bb'
                },
                {
                    type: 'continent_oceania',
                    icon: faGlobeOceania,
                    color: '#1991bb'
                },
            ]
        },
    ]

    const {t} = useTranslation()

    const onFiltersToggle = () => {
        setFilters((state: any) => ({
            ...state,
            isOpen: !state.isOpen,

        }))
    }

    const onFiltersOptionClick = (newOption: string) => {
        setFilters((state: any) => ({
            ...state,
            selected: includes(state.selected, newOption) ? state.selected.filter((item: string) => item !== newOption) : [...state.selected, newOption]
        }))
    }

    console.log({tst: window.location})

    return (
        <Styled.Travel__Tips__Country__Section__Holder>
            {filters.isOpen && (
                <Styled.Travel__Tips__Filter__Box__Holder>
                    <BoxFilters
                        selectedOptions={filters.selected}
                        onOptionClick={onFiltersOptionClick}
                        options={filterOptions}
                        showAllOptions={true}
                    />
                </Styled.Travel__Tips__Filter__Box__Holder>
            )}
            <Styled.Travel__Tips__Show__Filters__Label
                onClick={onFiltersToggle}
            >
                {filters?.isOpen ? t('lbl_hide_filters') : t('lbl_show_filters')}
                <Styled.Travel__Tips__Show__Filters__Icon
                    icon={filters?.isOpen ? faChevronUp : faChevronDown}
                />
            </Styled.Travel__Tips__Show__Filters__Label>
            {options.map((option: any) => (
                <Styled.Travel__Tips__Country__Continent__Holder
                    key={'continent_section' + option.title}
                >
                    <Styled.Travel__Tips__Country__Continent__Title>
                        {option.title}
                    </Styled.Travel__Tips__Country__Continent__Title>
                    <Styled.Travel__Tips__Country__Options>
                        {option.subOptions.map((option: any) => (
                            <CountryOption
                                link={window.location.href + `/${option.countryName}`}
                                key={'travel_tips_' + option.countryName}
                                countryName={option.countryName}
                                countryFlag={option.countryFlag}
                                countryImage={option.countryImage}
                            />
                        ))}
                    </Styled.Travel__Tips__Country__Options>
                </Styled.Travel__Tips__Country__Continent__Holder>
            ))}
        </Styled.Travel__Tips__Country__Section__Holder>
    )
}
export default PerCountry