import {Styled} from "./Advertisement.styles"
import {faLaptop, faMobile, faTabletButton} from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "../../../lib/UI/Tooltip";
import {viewTypes} from "./AdvertisementTypes/MainPage/MainPageAdvertisment";


type viewModeProps = {
    active: viewTypes
    onChange: (type: viewTypes) => void
}

const ViewMode = (
    {
        active,
        onChange,
    }: viewModeProps
) => {


    const options = [
        {
            type: viewTypes.desktop,
            icon: faLaptop
        },
        {
            type: viewTypes.tablet,
            icon: faTabletButton
        },
        {
            type: viewTypes.mobile,
            icon: faMobile
        }
    ]


    return (
        <Styled.Views__Holder>
            {options.map((option: any) => (
                <Tooltip
                    content={option.type}
                >
                    <Styled.View__Icon
                        onClick={() => onChange(option.type)}
                        isSelected={option.type === active}
                        icon={option.icon}
                    />
                </Tooltip>
            ))}
        </Styled.Views__Holder>
    )
}

export default ViewMode