import {Styled} from "./TravelTips.styles"
import {useRef, useState} from "react";
import {faTimes} from "@fortawesome/pro-solid-svg-icons";
import DescriptionContentPart from "./DescriptionContentPart";
import {useTranslation} from "react-i18next";


type TravelTipsProps = {
    values: any[]
}

const TravelTips = (
    {
        values
    }: TravelTipsProps
) => {

    const defaultState = {
        open: undefined
    }

    const ref = useRef<any>({})

    const {t} = useTranslation()

    const [travelTipsState, setTravelTipsState] = useState<any>(defaultState)


    const customSort = (array: any) => {
        array.sort((a: any, b: any) => {
            if (a.title === travelTipsState.open) return -1;
            if (b.title === travelTipsState.open) return 1;
            return a.title.localeCompare(b.title);
        });
        return array;
    }

    return (
        <Styled.Travel__Tips__Holder
            ref={ref}
        >
            {customSort(values).map((option: any, index: number) => {

                const onOptionClickHandler = () => {
                    setTimeout(() => {
                        ref.current.scrollIntoView({behavior: 'smooth', inline: 'start', block: 'start'})
                    }, 50)
                    setTravelTipsState((state: any) => ({
                        ...state,
                        open: option.title
                    }))
                }

                const onOptionCloseHandler = (event: any) => {
                    event.stopPropagation()
                    setTravelTipsState((state: any) => ({
                        ...state,
                        open: undefined
                    }))
                }

                const isOpen = option.title === travelTipsState?.open

                return (
                    <Styled.Travel__Tips__Option
                        onClick={onOptionClickHandler}
                        isOpen={isOpen}
                    >

                        {!isOpen && (
                            <>
                                <Styled.Travel__Tips__Closed__Option__Image
                                    src={option?.image}
                                />
                                <Styled.Travel__Tips__Option__Title>
                                    {option.title}
                                </Styled.Travel__Tips__Option__Title>
                            </>
                        )}

                        {isOpen && (
                            <>

                                <Styled.Travel__Tips__Open__Content__Holder>
                                    <Styled.Travel__Tips__Open__Content__Image
                                        src={option.image}
                                    />
                                    <Styled.Travel__Tips__Open__Image__Black__Cover/>
                                    <Styled.Travel__Tips__Open__Content__Description>
                                        <Styled.Travel__Tips__Open__Option__Header>
                                            <Styled.Travel__Tips__Open__Option__Header__Title>
                                                {option.title}
                                            </Styled.Travel__Tips__Open__Option__Header__Title>
                                            <Styled.Travel__Tips__Close__Icon
                                                onClick={onOptionCloseHandler}
                                                icon={faTimes}
                                            />
                                        </Styled.Travel__Tips__Open__Option__Header>
                                        {option.options.map((subOption: any, index: number) => {
                                            return (
                                                <>
                                                    <Styled.Travel__Tips__Description__Section__Title
                                                        index={index}
                                                    >
                                                        {t(subOption.title)}
                                                    </Styled.Travel__Tips__Description__Section__Title>
                                                    <DescriptionContentPart
                                                        sectionType={subOption.type}
                                                        value={subOption.options}
                                                    />
                                                </>
                                            )

                                        })}
                                    </Styled.Travel__Tips__Open__Content__Description>
                                </Styled.Travel__Tips__Open__Content__Holder>
                            </>
                        )}
                    </Styled.Travel__Tips__Option>
                )
            })}
        </Styled.Travel__Tips__Holder>
    )
}

export default TravelTips