import {Styled} from "../../AdminPage.styles"
import {useContext} from "react";
import {Admin__Page__Context} from "../../../../lib/Context/ContextList";
import {Quick__Access} from "../../../../lib/Const/AdminOptions";

const QuickActionSection = () => {


    const [state] = useContext<any>(Admin__Page__Context)

    const {
        quickActions
    } = state

    return (
        <Styled.Quick__Action>
            {quickActions.map((action: any) => (
                <Styled.Action__Option>
                    <Styled.Action__Option__Icon
                        icon={Quick__Access[action.type].icon}
                    />
                    {action.title}
                </Styled.Action__Option>
            ))}
        </Styled.Quick__Action>
    )
}

export default QuickActionSection