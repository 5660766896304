import {Styled} from "./ArticleBox.styles"
import {useTranslation} from "react-i18next";

type ArticleBoxProps = {
    option: any
}

const ArticleBox = (
    {
        option = {}
    }: ArticleBoxProps
) => {

    const {t} = useTranslation()

    const {
        title,
        image,
        shortDesc,
        date,
        time,
        link
    } = option


    return (
        <Styled.Article__Box
            href={link}
        >
            <Styled.Article__Image
                src={image}
            />
            <Styled.Article__Addition__Time>
                {date}
            </Styled.Article__Addition__Time>
            <Styled.Article__Content__Holder>
                <Styled.Article__Title>
                    {title}
                </Styled.Article__Title>
                <Styled.Article__Description>
                    {shortDesc}
                </Styled.Article__Description>
            </Styled.Article__Content__Holder>
        </Styled.Article__Box>
    )
}
export default ArticleBox