import {Styled} from "./HotelPage.styles"
import PageHolder from "../../lib/UI/PageHolder";
import PageTitle from "../../components/PageTitle";
import {useParams} from "react-router-dom";
import ImagesGallery from "../../components/ImagesGallery";
import SectionTabs, {tabSizes} from "../../components/Sections/SectionTabs";
import Section from "../../components/Sections/Section";
import React, {createElement, useRef} from "react";
import {useTranslation} from "react-i18next";
import HotelBookingModule from "../../components/Sections/SectionTypes/BookingModule/HotelBookingModule";
import HotelRooms from "../../components/Sections/SectionTypes/HotelRooms";

const HotelPage = () => {

    const {HotelName} = useParams()

    const {t} = useTranslation()

    const sectionsRef = useRef<any>({})

    const pageValues: any = {
        generalData: {
            name: 'Modica Boutique Hotel',
            rating: 4,
            location: 'Modica • Italy',

        },
        imageGallery: [
            'https://cf.bstatic.com/xdata/images/hotel/max1024x768/294069773.jpg?k=3e4b80026cd3dc1a0688ae5081d0f6b4e887cb29cc815e3d046e66962ce58802&o=&hp=1',
            'https://lh3.googleusercontent.com/p/AF1QipNnxOab3gnVvASlci1vugwlKPKAK7nr3Uxd4amx=s1360-w1360-h1020',
            'https://lh3.googleusercontent.com/p/AF1QipO27uVtZty_coJ2qx26EvMiAynP_4K3EF0gcWXh=s1360-w1360-h1020',
            'https://lh3.googleusercontent.com/p/AF1QipPTzi8tm7T9VVVbNJaSJU_QTEr4N-Qf3kUXuQNH=s1360-w1360-h1020',
            'https://lh3.googleusercontent.com/p/AF1QipM-MZQpFuTB922G9rULQfdxLNA5qlMnxU0oi6G9=s1360-w1360-h1020',
            'https://lh3.googleusercontent.com/p/AF1QipOx9vFV9_nmrOZkClHIPxnkQSvYKLp39rbziMRk=s1360-w1360-h1020',
            'https://lh3.googleusercontent.com/proxy/CreH2lCFksGlwpV60NUN6SXDaDly9-3sEUALuM7QbsF5UdXeHGz2qFkGeVzo7IdgLfCugmSgqqsFFJAHcNRykOovZLm-tiPjwQTPa35305ANL7YTMaqvzeC4Df24MjG-CIJxhyAltIgnywHbXJl9li8jws6Kbjk=s1360-w1360-h1020',
            'https://lh3.googleusercontent.com/p/AF1QipNfY4Z7gTTzfTVJp8rPL2towFBZls1MKIuXL_9z=s1360-w1360-h1020'
        ],
        sections: {
            mainHotelInformation: {},
            hotelRoomList: {
                options: [
                    {
                        images: [
                            'https://lh3.googleusercontent.com/p/AF1QipPTzi8tm7T9VVVbNJaSJU_QTEr4N-Qf3kUXuQNH=s1360-w1360-h1020',
                            'https://lh3.googleusercontent.com/p/AF1QipNnxOab3gnVvASlci1vugwlKPKAK7nr3Uxd4amx=s1360-w1360-h1020',
                            'https://lh3.googleusercontent.com/p/AF1QipO27uVtZty_coJ2qx26EvMiAynP_4K3EF0gcWXh=s1360-w1360-h1020',
                        ],
                        name: 'Double Superior King Room',
                        details: {
                            roomPeople: 2,
                            roomBedsNumber: 2,
                            roomSize: '16 m²',
                        },
                        facilities: [
                            {
                                id: 'room_minibar',
                                title: 'Minibar'
                            },
                            {
                                id: 'room_bathtub',
                                title: 'Bathtub'
                            },
                            {
                                id: 'room_safe',
                                title: 'Private safe'
                            },
                            {
                                id: 'room_flat_tv',
                                title: 'Flat TV'
                            },
                            {
                                id: 'room_working_desk',
                                title: 'Working Desk'
                            },
                            {
                                id: 'room_ethernet',
                                title: "Ethernet Port"
                            },
                            {
                                id: 'room_lounge_access',
                                title: 'Access to Lounge'
                            },

                        ]
                    },
                ],
            },
            hotelBookingModule: {
                name: 'Modica Boutique Hotel',
                rating: 4,
                location: 'Modica • Italy',
                amount: 230,
                currency: '$',
                bookingOptions: [
                    {
                        image: 'https://firebasestorage.googleapis.com/v0/b/bookbrd.appspot.com/o/BookBrdLinkImage.png?alt=media&token=7d39f0d4-4f57-416c-9daa-88b8335a762f',
                        name: "BookBrd"
                    },
                    {
                        image: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/294069773.jpg?k=3e4b80026cd3dc1a0688ae5081d0f6b4e887cb29cc815e3d046e66962ce58802&o=&hp=1',
                        name: 'Official Hotel Site'
                    }
                ],
            }
        }
    }

    const sectionElements: any = {
        hotelBookingModule: HotelBookingModule,
        hotelRoomList: HotelRooms
    }

    const {
        imageGallery,
        generalData,
        sections
    }: any = pageValues

    return (
        <PageHolder>
            <Styled.Hotel__Page__Holder>
                <PageTitle
                    rating={4}
                    title={HotelName ? HotelName.split('_').join(' ') : ''}
                />
                <ImagesGallery
                    images={imageGallery}
                />
                <SectionTabs
                    size={tabSizes.large}
                    tabs={Object.keys(sections)}
                    onClick={() => {
                    }}
                />
                {Object.keys(sections).map((section: string) => {

                    return (
                        <Section
                            content={sectionElements[section] ?
                                createElement(
                                    sectionElements[section] as any,
                                    {...sections[section]},
                                ) : null
                            }
                            ref={sectionsRef}
                            sectionName={section}
                            title={t(`lbl_${section}`)}
                        />
                    )
                })}

            </Styled.Hotel__Page__Holder>
        </PageHolder>
    )
}

export default HotelPage