import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Text from "../../lib/UI/Text";
import Button from "../../lib/UI/Button";
import TextArea from "../../lib/UI/TextArea";
import {rgba} from "polished";
import Image from "../../lib/UI/Image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Discover__World__Content__Holder = styled(FlexBox)`
  padding: 20px 10px;
  flex-direction: column;
  flex-wrap: nowrap;
`
const Where__To__Go__Title = styled(Text)`
  padding-bottom: 15px;
  color: ${({theme}) => theme.color};
  font-size: 4vw;
  font-weight: bold;

  @media (min-width: 1100px) {
    font-size: 44px;
  }
  @media (max-width: 800px) {
    font-size: 30px;
  }
  @media (max-width: 400px) {
    font-size: 20px;
  }
`
const Where__To__Go__Subtitle = styled(Text)`
  font-size: 18px;
`
const Where__To__Go__Panel__Holder = styled(FlexBox)`
  flex-direction: column;

  padding-top: 40px;
  padding-bottom: 20px;
`
const Where__To__Go__Agent__Panel = styled(FlexBox)`
  flex-direction: column;
  margin-top: 20px;
`
const Send__Message__Button = styled(Button)`
  margin-left: auto;
  margin-top: 10px;
  min-width: 125px;
  padding: 2px 8px;
`
const Where__To__Go__TextArea = styled(TextArea)`
  min-height: 400px;
`
const Recommended__Destinations__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-top: 15px;
  padding-top: 20px;
  border-top: 1px solid ${({theme}) => rgba(theme.color, 0.3)};
`
const Recommended__Destinations__Title = styled(Text)`
  font-weight: bold;
  font-size: 20px;
`
const Recommended__Destinations__Empty__List = styled(Text)`
  text-align: center;
  padding: 70px 0;
`
const Recommended__Destinations__Sections__Holder = styled(FlexBox)`
  flex-direction: column;
  padding-top: 20px;
  gap: 20px 0;
`
const Recommended__Destinations__Section = styled.a`
  display: flex;
  flex-direction: column;
`
const Recommended__Destinations__Section__Title = styled(Text)`
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 15px;
`
const Recommended__Destinations__Section__Options = styled(FlexBox)`

`
const Recommended__Destinations__Option = styled(FlexBox)`
  flex-direction: column;
  box-sizing: border-box;
  width: 25%;
  padding: 10px;
  @media (max-width: 900px) {
    width: calc(100% / 3);
  }
  @media (max-width: 600px) {
    width: 50%;
  }
`
const Recommended__Destinations__Image = styled(Image)`
  aspect-ratio: 1/0.75;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
  height: auto;
`
const Recommended__Destinations__Option__Title = styled(Text)`
  font-size: 24px;
  padding-top: 8px;
`
const Recommended__Destinations__Ready__Panel = styled(FlexBox)`
  flex-direction: column;
  gap: 20px 0;
  padding-bottom: 20px;
`
const Ready__Panel__Section = styled(FlexBox)`
  flex-direction: column;
`
const Ready__Panel__Section__Title = styled(Text)`
  font-size: 20px;
  font-weight: bold;
`
const Ready__Panel__Section__Options = styled(FlexBox)`
  margin-top: 10px;
  gap: 10px;
`
const Ready__Panel__Section__Option = styled(FlexBox)<{ customColor: string, isSelected: boolean }>`
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: 200ms;
  color: ${({theme, isSelected, customColor}) => isSelected ? customColor ?? theme.color : theme.color};
  border: 1px solid ${({
                         theme,
                         isSelected,
                         customColor
                       }) => isSelected ? customColor ?? theme.color : theme.color};
`
const Ready__Panel__Section__Icon = styled(FontAwesomeIcon)<{ customColor: string, isSelected: boolean }>`
  color: ${({theme, isSelected, customColor}) => isSelected ? customColor ?? theme.color : theme.color};
  width: 26px;
  height: 26px;
  transition: 200ms;
`
const Ready__Panel__Section__Option__Title = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
  transition: 200ms;
  margin-left: 10px;
  color: inherit;
`
const Ready__Panel__Show__All_Options = styled(FlexBox)`
  flex-wrap: nowrap;
  cursor: pointer;
  width: 100%;
  font-size: 20px;
  transition: 200ms;

  &:hover {
    font-size: 22px;
  }
`
const Ready__Panel__Show__All__Separator__Line = styled(FlexBox)`
  flex-grow: 1;
  margin: auto 0;
  border-top: 1px solid ${({theme}) => theme.color};
`
const Ready__Panel__Show__All_Separator__Title = styled(Text)`
  font-size: inherit;
`
const Ready__Panel__Show__All_Separator__Icon = styled(FontAwesomeIcon)`
  margin: 0 10px;
`

export const Styled = {
    Ready__Panel__Show__All_Separator__Icon,
    Ready__Panel__Show__All_Separator__Title,
    Ready__Panel__Show__All__Separator__Line,
    Ready__Panel__Show__All_Options,
    Ready__Panel__Section__Option__Title,
    Ready__Panel__Section__Icon,
    Ready__Panel__Section__Option,
    Ready__Panel__Section__Options,
    Ready__Panel__Section__Title,
    Ready__Panel__Section,
    Recommended__Destinations__Ready__Panel,
    Recommended__Destinations__Option__Title,
    Recommended__Destinations__Image,
    Recommended__Destinations__Option,
    Recommended__Destinations__Section__Options,
    Recommended__Destinations__Section__Title,
    Recommended__Destinations__Section,
    Recommended__Destinations__Sections__Holder,
    Recommended__Destinations__Empty__List,
    Recommended__Destinations__Title,
    Recommended__Destinations__Holder,
    Where__To__Go__TextArea,
    Send__Message__Button,
    Where__To__Go__Agent__Panel,
    Where__To__Go__Panel__Holder,
    Where__To__Go__Subtitle,
    Where__To__Go__Title,
    Discover__World__Content__Holder
}