import React from 'react';
import ThemeProviderContext from "./lib/Context/Providers/ThemeProvider";
import WorldOfRegions from "./Pages/WorldOfRegions/WorldOfRegions";
import {BrowserRouter} from "react-router-dom";
import "./i18n";
import AdditionalModules from "./components/AdditionalModules";
import GlobalStyle from "./lib/Style/GlobalStyles";
import AppConfigProvider from "./lib/Context/Providers/AppConfigProvider";
import PageLoader from "./components/PageLoader";

function App() {
    return (
        <ThemeProviderContext>
            <BrowserRouter>
                <AppConfigProvider>
                    <>
                        <PageLoader/>
                        <GlobalStyle/>
                        <WorldOfRegions/>
                        <AdditionalModules/>
                    </>
                </AppConfigProvider>
            </BrowserRouter>
        </ThemeProviderContext>
    );
}

export default App;
