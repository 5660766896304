import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Image from "../../lib/UI/Image";
import Text from "../../lib/UI/Text";
import {rgba} from "polished";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Admin__Page__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Welcome__Section = styled(FlexBox)`
  flex-direction: column;
  padding: 0 10px;
  margin-top: 40px;
  margin-bottom: 30px;
`
const Welcome__Title__Holder = styled(FlexBox)`
  flex-direction: initial;
  flex-wrap: nowrap;
`
const Welcome__Logo = styled(Image)`
  height: 55px;
  width: 55px;
  background-color: transparent;
`
const Welcome__Title = styled(Text)`
  font-size: 36px;
  font-weight: bold;
  margin: auto 0 auto 15px;
`
const Welcome__Employment__Information = styled(Text)`
  margin-top: 30px;
  font-size: 18px;
`
const Welcome__Account__Information = styled(FlexBox)`
  margin-top: 20px;
  gap: 15px 30px;
`
const Welcome__Account__Information__Option = styled(FlexBox)`
  flex-wrap: nowrap;
`
const Welcome__Account__Status = styled(Text)<{ backgroundColor: string }>`
  background-color: ${({backgroundColor}) => backgroundColor};
  padding: 3px 10px;
  margin-top: -3px;
  margin-left: 8px;
  font-weight: bold;
  border-radius: 15px;
`
const Quick__Action = styled(FlexBox)`
  gap: 15px 30px;
  padding: 0 10px;
  margin-bottom: 30px;
`
const Action__Option = styled(Text)`
  padding: 6px 12px;
  border-radius: 5px;
  box-shadow: ${({theme}) => `0px 0px 4px 0px ${rgba(theme.color, 0.6)}`};
  transition: box-shadow 200ms, background-color 200ms;
  cursor: pointer;


  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.05)};
    box-shadow: ${({theme}) => `0px 0px 8px 0px ${rgba(theme.color, 0.6)}`};
  }
`
const Action__Option__Icon = styled(FontAwesomeIcon)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`
const Options__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 0 10px;
`
const Options__Title = styled(Text)`
  font-size: 26px;
`

const Options__Content__Holder = styled(FlexBox)`
  padding: 15px 0;
  gap: 20px;
`
const Option = styled(FlexBox)`
  padding: 25px 20px;
  width: calc(25% - 15px);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid ${({theme}) => rgba(theme.color, 0.3)};
  transition: 200ms border, 200ms box-shadow;

  @media (max-width: 900px) {
    width: calc(100% / 3 - 40px / 3);
  }
  @media (max-width: 700px) {
    width: calc(100% / 2 - 10px);
  }
  @media (max-width: 500px) {
    width: 100%;
  }

  &:hover {
    box-shadow: ${({theme}) => `0px 0px 8px 0px ${rgba(theme.color, 0.6)}`};
    border: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
  }
`
const Option__Icon = styled(FontAwesomeIcon)`
  height: 20px;
  width: 20px;
  margin: auto 0;
  color: ${({theme}) => theme.color};
`
const Option__Title = styled(Text)`
  margin-left: 10px;
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const Option__Align__Content = styled(FlexBox)`
  flex-wrap: nowrap;
  margin: auto 0;
  overflow: hidden;
`

export const Styled = {
    Option__Align__Content,
    Option__Title,
    Option__Icon,
    Option,
    Options__Content__Holder,
    Options__Title,
    Options__Holder,
    Action__Option__Icon,
    Action__Option,
    Quick__Action,
    Welcome__Account__Status,
    Welcome__Account__Information__Option,
    Welcome__Account__Information,
    Welcome__Employment__Information,
    Welcome__Title,
    Welcome__Logo,
    Welcome__Title__Holder,
    Welcome__Section,
    Admin__Page__Holder
}