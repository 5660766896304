import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import Text from "../../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Image from "../../../lib/UI/Image";
import {rgba} from "polished";

const Announcements__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
`
const Announcements__Title = styled(Text)`
  font-size: 26px;
  font-weight: bold;
  margin-top: 40px;
  padding: 0 10px;
`
const Announcements__Icon = styled(FontAwesomeIcon)`
  margin-right: 15px;
  height: 30px;
  width: 30px;
`
const Announcements__Options__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-top: 20px;
  gap: 10px 0;
`
const Announcement__Option = styled(FlexBox)`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  flex-wrap: nowrap;
  cursor: pointer;
  transition: background-color 200ms;

  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.3)};

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.1)};
  }
`
const Announcement__Option__Image = styled(Image)`
  width: 300px;
  height: 200px;
  background-color: black;
  flex-shrink: 0;
  border-radius: 5px;
`
const Announcements__Option__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-left: 20px;
`
const Announcements__Option__Title = styled(Text)`
  font-size: 26px;
  font-weight: bold;
`
const Announcement__Description = styled(Text)`
  margin-top: 20px;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: calc(6em + 34px);
  -webkit-line-clamp: 6;
  overflow: hidden;
  line-height: 24px;
  -webkit-box-orient: vertical;
  font-size: 18px;
`


export const Styled = {
    Announcement__Description,
    Announcements__Option__Title,
    Announcements__Option__Content__Holder,
    Announcement__Option__Image,
    Announcement__Option,
    Announcements__Options__Holder,
    Announcements__Title,
    Announcements__Icon,
    Announcements__Content__Holder
}