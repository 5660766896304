import {Continent__Page, Main__First__Page} from "./Routes";
import MainPage from "../../../Pages/MainPage";
import ContinentPage from "../../../Pages/ContinentPage";
import {RegionPages} from "./Region";
import {CountryPages} from "./Country";
import {AdminPages} from "./Admin";
import {BusinessPages} from "./Business";
import {GeneralPages} from "./General";

const useRoutesList = () => {


    return [
        ...GeneralPages,
        ...RegionPages,
        ...CountryPages,
        ...AdminPages,
        ...BusinessPages,
        {
            path: Main__First__Page,
            element: <MainPage/>
        },
        {
            path: Continent__Page,
            element: <ContinentPage/>
        },

    ]
}

export default useRoutesList