import PageHolder from "../../lib/UI/PageHolder";
import PageTitle from "../../components/PageTitle";
import {useParams} from "react-router-dom";
import ImagesGallery from "../../components/ImagesGallery";
import SectionTabs from "../../components/Sections/SectionTabs";
import React, {createElement, useRef} from "react";
import Section from "../../components/Sections/Section";
import {useTranslation} from "react-i18next";
import Overview from "../../components/Sections/SectionTypes/Overview";
import ExcursionPlan from "../../components/Sections/SectionTypes/ExcursionPlan";
import Options from "../../components/Sections/SectionTypes/Options";
import AttractionBookingModule from "../../components/Sections/SectionTypes/BookingModule/AttractionBookingModule";

const ParticularAttractionContent = {
    backgroundImage: 'https://hispanico.pl/wp-content/uploads/2023/05/wulkan-etna-widok-pejzaz-dym-natura-przyroda-wlochy-europa.jpg',
    attractionType: 'Excursion',
    images: [
        'https://tuitam.org.pl/wp-content/uploads/2022/09/DSC_8570.jpg',
        'https://cdn.getyourguide.com/img/tour/055c70c5f5c63698.jpeg/97.jpg',
        'https://hispanico.pl/wp-content/uploads/2023/05/wulkan-etna-widok-pejzaz-dym-natura-przyroda-wlochy-europa.jpg',
        'https://ocdn.eu/pulscms-transforms/1/ycNk9kuTURBXy8zYjliZTc0Ni1mMzIzLTQ1NTEtYWQ5Zi1lNmFmYmYxNTMwYmMuanBlZ5OVAwDMWM0MAM0GwJMFzQSwzQKkkwmmZTNiZDA2Bt4AAaEwAQ/nocna-erupcja-etny-ogniste-potoki-na-osniezonym-stozku.jpeg',
    ]
}

const ParticularAttractionPage = () => {

    const {AttractionName} = useParams()

    const {
        backgroundImage,
        attractionType,
        images
    } = ParticularAttractionContent

    const sectionTabs: any = {
        'ExcursionDescription': {
            value: 'Embark on an unforgettable journey to Mount Etna, Europe\'s most active volcano. Discover the raw beauty of Sicily\'s rugged landscapes as you ascend to the summit. Witness billowing plumes of smoke and fiery lava flows against a backdrop of stunning panoramic views. Explore ancient lava caves, hike scenic trails, and savor authentic Sicilian cuisine in quaint mountain villages. A trip to Mount Etna promises thrilling adventures and awe-inspiring natural wonders at every turn.'
        },
        'ExcursionPlan': {
            steps: [
                {
                    time: '08:00 AM',
                    description: 'Begin your journey from our location.'
                },
                {
                    time: '09:30 AM',
                    description: 'We\'ll arrive at the base of Mount Etna and proceed to the visitor center.'
                },
                {
                    time: '10:00 AM',
                    description: 'Begin our journey into the lower crater areas of Mount Etna, exploring unique geological formations and volcanic landscapes.'
                },
                {
                    time: '11:30 AM',
                    description: 'Take a break to enjoy snacks and refreshments while soaking in the breathtaking views.'
                },
                {
                    time: '01:00 PM',
                    description: 'Enjoy a delicious lunch either prepared by our team or at a local restaurant (lunch options provided upon booking).'
                },
                {
                    time: '01:00 PM',
                    description: 'Enjoy a delicious lunch either prepared by our team or at a local restaurant (lunch options provided upon booking).'
                },
                {
                    time: '02:00 PM',
                    description: 'Join our expert guide for a guided hike to discover hidden gems and learn more about the volcano\'s natural wonders.'
                },
                {
                    time: '04:00 PM',
                    description: 'For the adventurous souls, consider an optional summit ascent led by our experienced guides (subject to weather conditions and safety assessments).'
                },
                {
                    time: '05:30 PM',
                    description: 'Begin our descent back to the base of Mount Etna, enjoying the panoramic views along the way.'
                },
                {
                    time: '06:30 PM',
                    description: 'Board our transportation for the return journey to the departure point.'
                },
                {
                    time: '08:00 PM',
                    description: 'Arrive back at our designated departure point, concluding our memorable 8-hour excursion to Mount Etna.'
                },
            ]
        },
        'ExcursionAdditionalOptions': {
            options: [
                '4x4 Excursion ( +90$ p.p ) ',
                'Memory PhotoShoot ( +80$ ) ',
                'Private Guide ( + 120$ )',

            ]
        },
        'ExcursionHowToBook': {
            name: AttractionName + ' • ' + attractionType,
            span: '8 Hours',
            bookingOptions: [
                {
                    image: 'https://firebasestorage.googleapis.com/v0/b/bookbrd.appspot.com/o/BookBrdLinkImage.png?alt=media&token=7d39f0d4-4f57-416c-9daa-88b8335a762f'
                },
                {
                    image: undefined,
                    name: 'Operator Site'
                }
            ],
            price: {
                amount: 80,
                currency: '$'
            }
        }
    }

    const {t} = useTranslation()

    const sectionsRef = useRef<any>({})

    const onTabSelected = (tab: string) => {
        sectionsRef.current[tab].scrollIntoView({behavior: 'smooth', block: 'nearest'})
    }

    const sections: any = {
        'ExcursionDescription': Overview,
        'ExcursionPlan': ExcursionPlan,
        'ExcursionAdditionalOptions': Options,
        'ExcursionHowToBook': AttractionBookingModule
    }


    return (
        <PageHolder
            backgroundImage={backgroundImage}
        >
            <PageTitle
                title={AttractionName + ' • ' + attractionType}
            />
            <ImagesGallery
                images={images}
            />
            <SectionTabs
                tabs={Object.keys(sectionTabs)}
                onClick={onTabSelected}
            />
            {Object.keys(sectionTabs).map((section: string) => (
                <Section
                    content={sections[section] ?
                        createElement(
                            sections[section] as any,
                            {...sectionTabs[section]},
                        ) : null
                    }
                    ref={sectionsRef}
                    sectionName={section}
                    title={t(`lbl_${section}`)}
                />
            ))}
        </PageHolder>
    )
}

export default ParticularAttractionPage