export const geoJSOn = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "name": "Umbria",
                image: "https://www.tripsavvy.com/thmb/LB4mV8qBDJ0r9_NpM6b1yIORTxE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/basilica-of-san-francesco-in-assisi--umbria--italy-921370774-5aba5929ae9ab80037aa6325.jpg"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            12.548280307110161,
                            43.475793889888934
                        ],
                        [
                            12.526924082548163,
                            43.49419612162495
                        ],
                        [
                            12.522771282326914,
                            43.49797775314585
                        ],
                        [
                            12.521773040823717,
                            43.50308078507092
                        ],
                        [
                            12.518181429820913,
                            43.50635091790852
                        ],
                        [
                            12.515127113258728,
                            43.51059940942531
                        ],
                        [
                            12.511810147319864,
                            43.51152248949555
                        ],
                        [
                            12.500691461325715,
                            43.519128353927954
                        ],
                        [
                            12.499320712421081,
                            43.52184352568668
                        ],
                        [
                            12.497577456113817,
                            43.52217251603852
                        ],
                        [
                            12.496755997337146,
                            43.52019502560867
                        ],
                        [
                            12.49369216150157,
                            43.52109718099618
                        ],
                        [
                            12.493754229969596,
                            43.52313697352503
                        ],
                        [
                            12.49001696707009,
                            43.52465203541365
                        ],
                        [
                            12.488214022114306,
                            43.523777612497526
                        ],
                        [
                            12.484003913061713,
                            43.527190329391374
                        ],
                        [
                            12.477584937598408,
                            43.525486548354536
                        ],
                        [
                            12.47876224504941,
                            43.523995735491155
                        ],
                        [
                            12.477436905884474,
                            43.52289620444779
                        ],
                        [
                            12.47219761769054,
                            43.520537760282394
                        ],
                        [
                            12.47547640994037,
                            43.51608562392386
                        ],
                        [
                            12.471032305926911,
                            43.51635748443414
                        ],
                        [
                            12.465272382020657,
                            43.51887534872611
                        ],
                        [
                            12.45958414245149,
                            43.51692543268635
                        ],
                        [
                            12.457948364351921,
                            43.51547941961405
                        ],
                        [
                            12.456971637809517,
                            43.518255366546356
                        ],
                        [
                            12.453716740338649,
                            43.52121818691887
                        ],
                        [
                            12.45080569585474,
                            43.52638330802699
                        ],
                        [
                            12.446313802283214,
                            43.529633128754824
                        ],
                        [
                            12.441676256815072,
                            43.53112897840265
                        ],
                        [
                            12.440506088818978,
                            43.53512817431971
                        ],
                        [
                            12.43748111593959,
                            43.536916879382034
                        ],
                        [
                            12.438161682457377,
                            43.53881245960602
                        ],
                        [
                            12.43273133262818,
                            43.53851468287647
                        ],
                        [
                            12.431515851488143,
                            43.53647713557132
                        ],
                        [
                            12.426107016619596,
                            43.53385434307046
                        ],
                        [
                            12.420542913986395,
                            43.53598369636725
                        ],
                        [
                            12.41575257716758,
                            43.533217191705006
                        ],
                        [
                            12.415492307609355,
                            43.530729327465906
                        ],
                        [
                            12.4110147895436,
                            43.5291451210895
                        ],
                        [
                            12.406245967685493,
                            43.52405292062147
                        ],
                        [
                            12.403055615096804,
                            43.52003911478167
                        ],
                        [
                            12.403726662341285,
                            43.518589724871134
                        ],
                        [
                            12.39940441204007,
                            43.51225144445186
                        ],
                        [
                            12.400354863985257,
                            43.51012815720168
                        ],
                        [
                            12.397286268512971,
                            43.50935743366395
                        ],
                        [
                            12.391280418704014,
                            43.50914961123317
                        ],
                        [
                            12.385604078226521,
                            43.51138201710805
                        ],
                        [
                            12.383227948363697,
                            43.51668998489356
                        ],
                        [
                            12.38768633128717,
                            43.52143648788012
                        ],
                        [
                            12.379204044714015,
                            43.52622237423816
                        ],
                        [
                            12.371731898735646,
                            43.53008854971978
                        ],
                        [
                            12.366931946047856,
                            43.53382811105453
                        ],
                        [
                            12.36205326616485,
                            43.52967470731264
                        ],
                        [
                            12.355381160598256,
                            43.5279917719931
                        ],
                        [
                            12.35354242177172,
                            43.52442496541681
                        ],
                        [
                            12.349482820847157,
                            43.52141204865546
                        ],
                        [
                            12.344642314652077,
                            43.52078691554735
                        ],
                        [
                            12.341189216090982,
                            43.52330114381925
                        ],
                        [
                            12.33783883609982,
                            43.52236782015967
                        ],
                        [
                            12.3356203538211,
                            43.52375823950558
                        ],
                        [
                            12.332470554738592,
                            43.52410972186709
                        ],
                        [
                            12.33006101082347,
                            43.527560520419684
                        ],
                        [
                            12.327840148725329,
                            43.528114559577006
                        ],
                        [
                            12.324114784918606,
                            43.53381057801934
                        ],
                        [
                            12.322861033493894,
                            43.53809523011279
                        ],
                        [
                            12.321626417212599,
                            43.539218706055465
                        ],
                        [
                            12.316396648291999,
                            43.54020538743765
                        ],
                        [
                            12.313815177883953,
                            43.5425543674132
                        ],
                        [
                            12.314395405648952,
                            43.548731941627494
                        ],
                        [
                            12.31746638093955,
                            43.55033812611657
                        ],
                        [
                            12.319384183967514,
                            43.55194580405188
                        ],
                        [
                            12.320795486379495,
                            43.553595161073844
                        ],
                        [
                            12.326724956960277,
                            43.55134354970619
                        ],
                        [
                            12.328642519578807,
                            43.55295138934295
                        ],
                        [
                            12.331398296298062,
                            43.5525394986054
                        ],
                        [
                            12.33404897462907,
                            43.55473745981084
                        ],
                        [
                            12.343691813329144,
                            43.55245994974112
                        ],
                        [
                            12.347565112255154,
                            43.55920240439002
                        ],
                        [
                            12.353988847354032,
                            43.562576832743446
                        ],
                        [
                            12.355770277350217,
                            43.5657745559285
                        ],
                        [
                            12.360364792895922,
                            43.56892713794622
                        ],
                        [
                            12.361257338670782,
                            43.57454358987283
                        ],
                        [
                            12.36892044968863,
                            43.578465563262256
                        ],
                        [
                            12.371688125499531,
                            43.58223157005639
                        ],
                        [
                            12.37366299694935,
                            43.584205302166794
                        ],
                        [
                            12.371306002228948,
                            43.586348460816055
                        ],
                        [
                            12.367960381874497,
                            43.587087032790805
                        ],
                        [
                            12.367043343981635,
                            43.59106176234107
                        ],
                        [
                            12.364996788195924,
                            43.593550583615865
                        ],
                        [
                            12.36103742942717,
                            43.596103281705524
                        ],
                        [
                            12.362957371863985,
                            43.598545221740295
                        ],
                        [
                            12.361589002777635,
                            43.6020920627783
                        ],
                        [
                            12.364602669236831,
                            43.603330226630305
                        ],
                        [
                            12.365170997911378,
                            43.605325739026426
                        ],
                        [
                            12.3674373181899,
                            43.60825816261206
                        ],
                        [
                            12.371525573529055,
                            43.61145001387794
                        ],
                        [
                            12.36809636974698,
                            43.612473559214806
                        ],
                        [
                            12.366911922840956,
                            43.61145690615538
                        ],
                        [
                            12.363456444461605,
                            43.61313227189359
                        ],
                        [
                            12.356941889882194,
                            43.61737840901074
                        ],
                        [
                            12.353959257656982,
                            43.61715883111239
                        ],
                        [
                            12.354207628123646,
                            43.61546800102863
                        ],
                        [
                            12.351645292857967,
                            43.61465713262123
                        ],
                        [
                            12.35045608631529,
                            43.61197033405918
                        ],
                        [
                            12.352614879944298,
                            43.6093802604525
                        ],
                        [
                            12.35030329496368,
                            43.60771340607769
                        ],
                        [
                            12.347239459128104,
                            43.6086142517799
                        ],
                        [
                            12.340937481145772,
                            43.608481079435364
                        ],
                        [
                            12.33613514863967,
                            43.61138067142784
                        ],
                        [
                            12.334248620255124,
                            43.61079279604323
                        ],
                        [
                            12.334069554307263,
                            43.60718766966352
                        ],
                        [
                            12.329876200578724,
                            43.60660319971211
                        ],
                        [
                            12.327676853441233,
                            43.6048342464548
                        ],
                        [
                            12.324037549477339,
                            43.601233933026975
                        ],
                        [
                            12.321363303723217,
                            43.603328005681846
                        ],
                        [
                            12.318476684328942,
                            43.601929824987025
                        ],
                        [
                            12.312756943375177,
                            43.60210145329549
                        ],
                        [
                            12.310762207745114,
                            43.59959348170136
                        ],
                        [
                            12.311388738548715,
                            43.597563507887514
                        ],
                        [
                            12.310022826186781,
                            43.59645231241561
                        ],
                        [
                            12.308672666851436,
                            43.59321714243234
                        ],
                        [
                            12.307689873966723,
                            43.59265011139604
                        ],
                        [
                            12.307006936502773,
                            43.590187571386224
                        ],
                        [
                            12.305957517399378,
                            43.58919421163864
                        ],
                        [
                            12.303774455456136,
                            43.58825780480697
                        ],
                        [
                            12.302311370499922,
                            43.58747823850766
                        ],
                        [
                            12.300499401420097,
                            43.586691286872394
                        ],
                        [
                            12.299581390265558,
                            43.58590298890917
                        ],
                        [
                            12.298589337500829,
                            43.585912368305145
                        ],
                        [
                            12.297422381551684,
                            43.58575617714632
                        ],
                        [
                            12.296192488362578,
                            43.58646872037181
                        ],
                        [
                            12.295373494291908,
                            43.58599614189524
                        ],
                        [
                            12.293041426883889,
                            43.58633128931811
                        ],
                        [
                            12.292169745808366,
                            43.586547277727135
                        ],
                        [
                            12.292903593898444,
                            43.587746462365885
                        ],
                        [
                            12.29325893373445,
                            43.5900200830537
                        ],
                        [
                            12.29460263735649,
                            43.59098930114669
                        ],
                        [
                            12.29458688432993,
                            43.593113333764876
                        ],
                        [
                            12.29050021418081,
                            43.59366424869066
                        ],
                        [
                            12.288699349470505,
                            43.59294842382451
                        ],
                        [
                            12.287523133641287,
                            43.5933686588545
                        ],
                        [
                            12.28788681111314,
                            43.59474196400231
                        ],
                        [
                            12.284507169091114,
                            43.59473122302762
                        ],
                        [
                            12.281213595293138,
                            43.59431767678015
                        ],
                        [
                            12.279521929792736,
                            43.59366486240202
                        ],
                        [
                            12.275934071476172,
                            43.59327543594452
                        ],
                        [
                            12.274884931625252,
                            43.59269709003499
                        ],
                        [
                            12.273087755893783,
                            43.59327617063079
                        ],
                        [
                            12.271934671049536,
                            43.594162214996516
                        ],
                        [
                            12.271192485323667,
                            43.593863287947784
                        ],
                        [
                            12.270561343296123,
                            43.59427480642472
                        ],
                        [
                            12.268705878981734,
                            43.59352748741608
                        ],
                        [
                            12.267976642114718,
                            43.59394705047134
                        ],
                        [
                            12.266557974637323,
                            43.59345173954458
                        ],
                        [
                            12.265924065876106,
                            43.592892085198116
                        ],
                        [
                            12.265144878062046,
                            43.59108507641278
                        ],
                        [
                            12.263984415260154,
                            43.589381235084545
                        ],
                        [
                            12.261617190252565,
                            43.588855718703826
                        ],
                        [
                            12.259198132374536,
                            43.5892699433538
                        ],
                        [
                            12.257508311363523,
                            43.58926457237803
                        ],
                        [
                            12.255352069384116,
                            43.59008900535795
                        ],
                        [
                            12.253161592050276,
                            43.59037654143199
                        ],
                        [
                            12.250972959205797,
                            43.59131155226902
                        ],
                        [
                            12.247703438637075,
                            43.59168755098179
                        ],
                        [
                            12.24406542375661,
                            43.59206120379923
                        ],
                        [
                            12.242600456877,
                            43.594447920529234
                        ],
                        [
                            12.240913402599688,
                            43.59541370063272
                        ],
                        [
                            12.238756238376084,
                            43.59591433868363
                        ],
                        [
                            12.237370884008016,
                            43.59563217236453
                        ],
                        [
                            12.235646827642512,
                            43.59508995839133
                        ],
                        [
                            12.234248524415534,
                            43.59408930522574
                        ],
                        [
                            12.232404164470637,
                            43.59341302925395
                        ],
                        [
                            12.230169269657921,
                            43.59341636893805
                        ],
                        [
                            12.22826197247224,
                            43.593608717143724
                        ],
                        [
                            12.226561047090911,
                            43.593532301438756
                        ],
                        [
                            12.225328387168219,
                            43.593273583130184
                        ],
                        [
                            12.22440849409034,
                            43.59565154181328
                        ],
                        [
                            12.221591485952871,
                            43.5979690799291
                        ],
                        [
                            12.220801339113052,
                            43.599841411396255
                        ],
                        [
                            12.219018034486453,
                            43.60146251322155
                        ],
                        [
                            12.217728893035428,
                            43.604338044978164
                        ],
                        [
                            12.216459193589799,
                            43.60638453925765
                        ],
                        [
                            12.215919653256236,
                            43.60833514746494
                        ],
                        [
                            12.214691604555782,
                            43.609694701702324
                        ],
                        [
                            12.213942003439115,
                            43.610619369083594
                        ],
                        [
                            12.210645662907467,
                            43.609235020173486
                        ],
                        [
                            12.209726729507821,
                            43.60812337248831
                        ],
                        [
                            12.207259565172137,
                            43.606958759418404
                        ],
                        [
                            12.204499960807254,
                            43.60646555897347
                        ],
                        [
                            12.200528969551158,
                            43.60359464155894
                        ],
                        [
                            12.198857248454317,
                            43.6035917562842
                        ],
                        [
                            12.194171976876817,
                            43.589189697710594
                        ],
                        [
                            12.190040926681803,
                            43.58564242281696
                        ],
                        [
                            12.177616160175205,
                            43.575498345423426
                        ],
                        [
                            12.168535065713684,
                            43.5679292527484
                        ],
                        [
                            12.164793470415873,
                            43.56484892700479
                        ],
                        [
                            12.158558123412035,
                            43.54973668703667
                        ],
                        [
                            12.15149123898243,
                            43.52995809907898
                        ],
                        [
                            12.101624826348768,
                            43.527746491016046
                        ],
                        [
                            12.093885253305473,
                            43.50180216643099
                        ],
                        [
                            12.129248510288619,
                            43.48408656824506
                        ],
                        [
                            12.146029619984006,
                            43.48128037611161
                        ],
                        [
                            12.143674336603652,
                            43.46933559672368
                        ],
                        [
                            12.126077563831018,
                            43.470067157552535
                        ],
                        [
                            12.099467211032561,
                            43.452435077605486
                        ],
                        [
                            12.081224346162344,
                            43.43407954798968
                        ],
                        [
                            12.079492180640273,
                            43.43243565833143
                        ],
                        [
                            12.057427435284865,
                            43.43764323181642
                        ],
                        [
                            12.046168359396376,
                            43.426957780093204
                        ],
                        [
                            12.031527339441908,
                            43.42298056587836
                        ],
                        [
                            12.029955751844938,
                            43.40184575481541
                        ],
                        [
                            12.049720014898071,
                            43.4039733192341
                        ],
                        [
                            12.054403366481893,
                            43.39929882087972
                        ],
                        [
                            12.069383438584822,
                            43.408610963135544
                        ],
                        [
                            12.078273530824703,
                            43.402518543583085
                        ],
                        [
                            12.072747300986606,
                            43.37651711130914
                        ],
                        [
                            12.079249957189631,
                            43.36614744036015
                        ],
                        [
                            12.09107734986054,
                            43.36785647619067
                        ],
                        [
                            12.101351236596457,
                            43.373805917457815
                        ],
                        [
                            12.126303048063392,
                            43.35869825686299
                        ],
                        [
                            12.137855693899837,
                            43.33968080769071
                        ],
                        [
                            12.138341623704093,
                            43.32067401221559
                        ],
                        [
                            12.127233806864183,
                            43.30619653234828
                        ],
                        [
                            12.139020098727258,
                            43.29341011548189
                        ],
                        [
                            12.15741426755261,
                            43.288313546278516
                        ],
                        [
                            12.168948461000724,
                            43.28181207066356
                        ],
                        [
                            12.17472697461639,
                            43.2825893858421
                        ],
                        [
                            12.18366730117583,
                            43.29420749555911
                        ],
                        [
                            12.180931494113992,
                            43.30034084368083
                        ],
                        [
                            12.190522524433959,
                            43.312975870331854
                        ],
                        [
                            12.19209429739351,
                            43.315173142645534
                        ],
                        [
                            12.228218231015461,
                            43.299130473659204
                        ],
                        [
                            12.215030022861356,
                            43.28878345952461
                        ],
                        [
                            12.202759170664308,
                            43.27898990716159
                        ],
                        [
                            12.180839331159405,
                            43.27297132577533
                        ],
                        [
                            12.147420984795133,
                            43.25224557737309
                        ],
                        [
                            12.11709163410572,
                            43.251391250289686
                        ],
                        [
                            12.107061417865367,
                            43.23954269390683
                        ],
                        [
                            12.088287970497191,
                            43.23400648562705
                        ],
                        [
                            12.044981934857901,
                            43.251745046437975
                        ],
                        [
                            12.037554579910989,
                            43.24676561209216
                        ],
                        [
                            12.044591204127528,
                            43.2327628226588
                        ],
                        [
                            12.036019776707178,
                            43.22041734115919
                        ],
                        [
                            12.037688376427127,
                            43.21468493799151
                        ],
                        [
                            12.02540802243331,
                            43.201522607779765
                        ],
                        [
                            12.0352858242357,
                            43.1992151475489
                        ],
                        [
                            12.028850127322073,
                            43.18743293522752
                        ],
                        [
                            12.005273229706575,
                            43.18015540523788
                        ],
                        [
                            11.992029723070914,
                            43.18386098007713
                        ],
                        [
                            11.973447083005794,
                            43.1663596486371
                        ],
                        [
                            11.93083737832265,
                            43.15233117858577
                        ],
                        [
                            11.923152703486664,
                            43.13578799510657
                        ],
                        [
                            11.921834363632513,
                            43.13178845297526
                        ],
                        [
                            11.910276968991383,
                            43.13029842014788
                        ],
                        [
                            11.924348666256293,
                            43.117143082316574
                        ],
                        [
                            11.92095463610704,
                            43.10746488692092
                        ],
                        [
                            11.930410529869505,
                            43.10347730752642
                        ],
                        [
                            11.935641467893305,
                            43.08889832219225
                        ],
                        [
                            11.922528447685977,
                            43.08262494298424
                        ],
                        [
                            11.943028433641189,
                            43.05488826436604
                        ],
                        [
                            11.957868919815695,
                            43.07064606260761
                        ],
                        [
                            11.981174288273564,
                            43.05267671349495
                        ],
                        [
                            11.98423001610638,
                            43.050725119872425
                        ],
                        [
                            11.944983045462493,
                            42.964060167335134
                        ],
                        [
                            11.955723845888798,
                            42.95334004346677
                        ],
                        [
                            11.932226684951871,
                            42.905190783149266
                        ],
                        [
                            11.959145824651841,
                            42.90220181865044
                        ],
                        [
                            11.957413757719934,
                            42.87092737076901
                        ],
                        [
                            11.957404166486356,
                            42.867520425939574
                        ],
                        [
                            11.926399533236577,
                            42.8691461693702
                        ],
                        [
                            11.916255725109494,
                            42.85029568636239
                        ],
                        [
                            11.890838073434253,
                            42.8429358340677
                        ],
                        [
                            11.891901603120573,
                            42.83811238368375
                        ],
                        [
                            11.905722109463511,
                            42.833934410515894
                        ],
                        [
                            11.929655094524804,
                            42.774597306516625
                        ],
                        [
                            11.978892997516425,
                            42.765575514316964
                        ],
                        [
                            11.967705036822025,
                            42.73824341799889
                        ],
                        [
                            11.92194183218649,
                            42.71282611886522
                        ],
                        [
                            12.02703477476527,
                            42.648820809730154
                        ],
                        [
                            12.105199166100817,
                            42.667198210567676
                        ],
                        [
                            12.120463418415454,
                            42.65225037828762
                        ],
                        [
                            12.16294472262274,
                            42.67986462053079
                        ],
                        [
                            12.245757266842844,
                            42.63554716327067
                        ],
                        [
                            12.233852228779341,
                            42.61525147702963
                        ],
                        [
                            12.246849959533648,
                            42.60067016214526
                        ],
                        [
                            12.237793659175537,
                            42.58545111967618
                        ],
                        [
                            12.240031407035332,
                            42.574803419636794
                        ],
                        [
                            12.27562354590404,
                            42.56031010765278
                        ],
                        [
                            12.27931463097022,
                            42.54230522953915
                        ],
                        [
                            12.267279527299365,
                            42.53626692161629
                        ],
                        [
                            12.271899280825664,
                            42.5259832120565
                        ],
                        [
                            12.289401086349187,
                            42.52052514493698
                        ],
                        [
                            12.291662812292799,
                            42.51843255880513
                        ],
                        [
                            12.274918630536632,
                            42.51436066392125
                        ],
                        [
                            12.279613569387294,
                            42.50661333412626
                        ],
                        [
                            12.28996128059282,
                            42.512351090448675
                        ],
                        [
                            12.309679869526462,
                            42.49580424035102
                        ],
                        [
                            12.290695478800359,
                            42.49380443537632
                        ],
                        [
                            12.298850770171953,
                            42.48859580627524
                        ],
                        [
                            12.31746578319698,
                            42.48902294692323
                        ],
                        [
                            12.325315918179541,
                            42.49515943307051
                        ],
                        [
                            12.346805498923146,
                            42.48664225813579
                        ],
                        [
                            12.351848440503801,
                            42.47282376743732
                        ],
                        [
                            12.362079391266718,
                            42.47697039973511
                        ],
                        [
                            12.367791827741371,
                            42.4816826766108
                        ],
                        [
                            12.374669686439915,
                            42.48148586350473
                        ],
                        [
                            12.374092912819407,
                            42.48606244338529
                        ],
                        [
                            12.378701966186725,
                            42.487753117405674
                        ],
                        [
                            12.386847661169952,
                            42.48914852692167
                        ],
                        [
                            12.38896388830787,
                            42.49293379701882
                        ],
                        [
                            12.385603574852013,
                            42.49604405754198
                        ],
                        [
                            12.394236011742294,
                            42.5007036341174
                        ],
                        [
                            12.397007676593631,
                            42.497925699334104
                        ],
                        [
                            12.40229354264551,
                            42.500691377035
                        ],
                        [
                            12.410853933068779,
                            42.499788294249726
                        ],
                        [
                            12.411039857617226,
                            42.49599993673169
                        ],
                        [
                            12.413759079739208,
                            42.49454674886226
                        ],
                        [
                            12.421630686094034,
                            42.49832293686782
                        ],
                        [
                            12.422171738362778,
                            42.49101468572289
                        ],
                        [
                            12.429259367977664,
                            42.485517594066266
                        ],
                        [
                            12.414867405641417,
                            42.47926588071678
                        ],
                        [
                            12.412870410341867,
                            42.467920988518216
                        ],
                        [
                            12.428361102191957,
                            42.4654950302191
                        ],
                        [
                            12.430693965090882,
                            42.45652377232301
                        ],
                        [
                            12.417248057675096,
                            42.457269210473385
                        ],
                        [
                            12.413477895173912,
                            42.454375020112764
                        ],
                        [
                            12.417031258168493,
                            42.450019464341125
                        ],
                        [
                            12.412689168441545,
                            42.44339670005169
                        ],
                        [
                            12.420196243488903,
                            42.43729391227666
                        ],
                        [
                            12.4198459621621,
                            42.43250796360607
                        ],
                        [
                            12.413926602641396,
                            42.42811233479901
                        ],
                        [
                            12.414360394454633,
                            42.422599128084954
                        ],
                        [
                            12.429298159053218,
                            42.42323956578048
                        ],
                        [
                            12.437562989475822,
                            42.427081627631964
                        ],
                        [
                            12.449092844282887,
                            42.42385187518465
                        ],
                        [
                            12.462700733479096,
                            42.43083559228009
                        ],
                        [
                            12.468658758621928,
                            42.4229723703437
                        ],
                        [
                            12.468451459102454,
                            42.41911778359744
                        ],
                        [
                            12.460668016828862,
                            42.416746113608355
                        ],
                        [
                            12.463773338028403,
                            42.412803185920865
                        ],
                        [
                            12.45803230519337,
                            42.40789920222156
                        ],
                        [
                            12.459817347378873,
                            42.403688227194124
                        ],
                        [
                            12.448718909388361,
                            42.400553943168205
                        ],
                        [
                            12.446328593622042,
                            42.39914778968961
                        ],
                        [
                            12.454607770424332,
                            42.39807780742916
                        ],
                        [
                            12.463065650385715,
                            42.40067720830257
                        ],
                        [
                            12.465775372521676,
                            42.39582160070441
                        ],
                        [
                            12.470200876336293,
                            42.40215902434559
                        ],
                        [
                            12.48152571022058,
                            42.405935525116206
                        ],
                        [
                            12.492228709465792,
                            42.39956841275932
                        ],
                        [
                            12.511191628819631,
                            42.40393463241904
                        ],
                        [
                            12.519008513848377,
                            42.39817998956454
                        ],
                        [
                            12.518496154341136,
                            42.39567204666989
                        ],
                        [
                            12.514280325042876,
                            42.39404048962578
                        ],
                        [
                            12.51280766063556,
                            42.37942341657967
                        ],
                        [
                            12.515972549554789,
                            42.376701720332164
                        ],
                        [
                            12.519313863036388,
                            42.36678215598428
                        ],
                        [
                            12.525595948140165,
                            42.36436632054645
                        ],
                        [
                            12.530062019700154,
                            42.36512703563176
                        ],
                        [
                            12.532774020432925,
                            42.37113830274234
                        ],
                        [
                            12.541527363994902,
                            42.379006339641336
                        ],
                        [
                            12.544022565219933,
                            42.377758173831836
                        ],
                        [
                            12.54900040236842,
                            42.383656025278015
                        ],
                        [
                            12.5584830977443,
                            42.38125669922519
                        ],
                        [
                            12.560260918540536,
                            42.384509838726984
                        ],
                        [
                            12.565620698691617,
                            42.38358921997056
                        ],
                        [
                            12.588761254596335,
                            42.396016055503
                        ],
                        [
                            12.598713502176821,
                            42.402211722315116
                        ],
                        [
                            12.60525532952937,
                            42.40232614418858
                        ],
                        [
                            12.604230514115955,
                            42.407323790442234
                        ],
                        [
                            12.613570131284519,
                            42.41400493695539
                        ],
                        [
                            12.613855998497911,
                            42.42588029699553
                        ],
                        [
                            12.609842718725446,
                            42.426404499185594
                        ],
                        [
                            12.61161331813014,
                            42.437114942432686
                        ],
                        [
                            12.61613173601944,
                            42.44655565694387
                        ],
                        [
                            12.619880427149468,
                            42.451811808332735
                        ],
                        [
                            12.618115124802983,
                            42.461044800005425
                        ],
                        [
                            12.612676680557797,
                            42.46395269843356
                        ],
                        [
                            12.620524440543619,
                            42.469242569894305
                        ],
                        [
                            12.636358288730122,
                            42.46905279636118
                        ],
                        [
                            12.641887272053225,
                            42.46840238646507
                        ],
                        [
                            12.644434916007668,
                            42.465830463099024
                        ],
                        [
                            12.643000347947918,
                            42.454813706088856
                        ],
                        [
                            12.651513142035498,
                            42.44692888635524
                        ],
                        [
                            12.651167610702316,
                            42.443842544469675
                        ],
                        [
                            12.669353678307715,
                            42.44147370478862
                        ],
                        [
                            12.691045264233821,
                            42.450147356630396
                        ],
                        [
                            12.703485356209399,
                            42.461189606446794
                        ],
                        [
                            12.71211243934269,
                            42.46405537814783
                        ],
                        [
                            12.723216181663304,
                            42.46101984190142
                        ],
                        [
                            12.739526571604046,
                            42.470604678711226
                        ],
                        [
                            12.71996768585521,
                            42.48403165912234
                        ],
                        [
                            12.71111163903231,
                            42.49967503787397
                        ],
                        [
                            12.710920964490413,
                            42.50176591945328
                        ],
                        [
                            12.72305846787495,
                            42.50499704980072
                        ],
                        [
                            12.725638265286449,
                            42.511194591303564
                        ],
                        [
                            12.747949623112277,
                            42.50820113485
                        ],
                        [
                            12.752358812493128,
                            42.51478228721666
                        ],
                        [
                            12.77231795090114,
                            42.51396554469159
                        ],
                        [
                            12.773507123092855,
                            42.51754378921461
                        ],
                        [
                            12.768023361108675,
                            42.52431848891558
                        ],
                        [
                            12.773788240311575,
                            42.527702973441535
                        ],
                        [
                            12.770961757734057,
                            42.530955515163015
                        ],
                        [
                            12.770797304556794,
                            42.53238328394906
                        ],
                        [
                            12.807419585629134,
                            42.53677546289296
                        ],
                        [
                            12.817403421729125,
                            42.534167895838095
                        ],
                        [
                            12.836890575575296,
                            42.54526734463002
                        ],
                        [
                            12.841301829409133,
                            42.546501677192424
                        ],
                        [
                            12.844998077809862,
                            42.553073149529894
                        ],
                        [
                            12.880453325510985,
                            42.5631719618886
                        ],
                        [
                            12.892078469389446,
                            42.56389870783366
                        ],
                        [
                            12.89357735516137,
                            42.57781117142795
                        ],
                        [
                            12.878603474832488,
                            42.599401252625654
                        ],
                        [
                            12.879332633846275,
                            42.60913317818219
                        ],
                        [
                            12.89695010526708,
                            42.61775157214478
                        ],
                        [
                            12.903701703537081,
                            42.6125760707931
                        ],
                        [
                            12.9164136055455,
                            42.610387234669446
                        ],
                        [
                            12.919006567238853,
                            42.603956901879684
                        ],
                        [
                            12.930939146100826,
                            42.60418675626977
                        ],
                        [
                            12.936081933945331,
                            42.615998000352505
                        ],
                        [
                            12.953660835427343,
                            42.61970714984369
                        ],
                        [
                            12.990592811786655,
                            42.61626026175034
                        ],
                        [
                            12.992651846201682,
                            42.61966604697781
                        ],
                        [
                            12.999069788122398,
                            42.615648229048105
                        ],
                        [
                            13.009910858860877,
                            42.61709867061427
                        ],
                        [
                            13.014355459049796,
                            42.62021155817169
                        ],
                        [
                            13.011757747362793,
                            42.624946307387376
                        ],
                        [
                            13.01709516516425,
                            42.63385791056152
                        ],
                        [
                            13.023174604342131,
                            42.639280657212566
                        ],
                        [
                            13.030247887576252,
                            42.63869163457596
                        ],
                        [
                            13.034151628296485,
                            42.62914320251389
                        ],
                        [
                            13.042600008271847,
                            42.62834543050715
                        ],
                        [
                            13.062124976846604,
                            42.62307713166578
                        ],
                        [
                            13.070499442721541,
                            42.62595652307701
                        ],
                        [
                            13.079052611753298,
                            42.63249159647452
                        ],
                        [
                            13.098520765625153,
                            42.63679949728612
                        ],
                        [
                            13.109035870409315,
                            42.64225486903686
                        ],
                        [
                            13.108821349499465,
                            42.64584854030758
                        ],
                        [
                            13.118463651659027,
                            42.65031497320052
                        ],
                        [
                            13.115930257684198,
                            42.6579586011741
                        ],
                        [
                            13.13014351686249,
                            42.66053847713991
                        ],
                        [
                            13.132855613995304,
                            42.65654956801475
                        ],
                        [
                            13.139392691354345,
                            42.65497043663498
                        ],
                        [
                            13.146809227323786,
                            42.646637566076095
                        ],
                        [
                            13.171451140398261,
                            42.66377906672892
                        ],
                        [
                            13.174048169788563,
                            42.665896726595236
                        ],
                        [
                            13.172472802119245,
                            42.674775470957485
                        ],
                        [
                            13.18183151566214,
                            42.678228901600846
                        ],
                        [
                            13.181621744745883,
                            42.683512851311946
                        ],
                        [
                            13.173261528851668,
                            42.68571315327719
                        ],
                        [
                            13.19183578133115,
                            42.711621011565825
                        ],
                        [
                            13.18700261066789,
                            42.72010626469705
                        ],
                        [
                            13.187800837387385,
                            42.73441795019559
                        ],
                        [
                            13.188877999131194,
                            42.73808681653827
                        ],
                        [
                            13.192676757993866,
                            42.741153389276604
                        ],
                        [
                            13.193019914329113,
                            42.743379697464746
                        ],
                        [
                            13.196928308644033,
                            42.745497435136684
                        ],
                        [
                            13.199374733621482,
                            42.75145816369795
                        ],
                        [
                            13.195771302894343,
                            42.75795723493138
                        ],
                        [
                            13.192997263046152,
                            42.759878468719165
                        ],
                        [
                            13.195361357448917,
                            42.76193614537323
                        ],
                        [
                            13.20036363488299,
                            42.7636805833653
                        ],
                        [
                            13.207720506733779,
                            42.76410228265908
                        ],
                        [
                            13.213149354591764,
                            42.76778341119979
                        ],
                        [
                            13.215656430800749,
                            42.77076803410972
                        ],
                        [
                            13.221174477451854,
                            42.772993402868906
                        ],
                        [
                            13.22428454864604,
                            42.77076292810176
                        ],
                        [
                            13.228416963856802,
                            42.772673304539666
                        ],
                        [
                            13.233948322176701,
                            42.77287101328213
                        ],
                        [
                            13.236917786560383,
                            42.77055830965483
                        ],
                        [
                            13.236598476593059,
                            42.76682838537664
                        ],
                        [
                            13.238474057856479,
                            42.7648881167876
                        ],
                        [
                            13.239053206472704,
                            42.76117710254567
                        ],
                        [
                            13.24321659304286,
                            42.76411831181855
                        ],
                        [
                            13.245840429693345,
                            42.76868992399994
                        ],
                        [
                            13.245149270628048,
                            42.77250370154536
                        ],
                        [
                            13.243864712965859,
                            42.77495803621878
                        ],
                        [
                            13.248533334051672,
                            42.77785661218405
                        ],
                        [
                            13.247701568175927,
                            42.7815876154541
                        ],
                        [
                            13.249958402124378,
                            42.785437343603775
                        ],
                        [
                            13.25067517225321,
                            42.7899833925224
                        ],
                        [
                            13.255198436536148,
                            42.791109693104374
                        ],
                        [
                            13.259461958570284,
                            42.78972285272465
                        ],
                        [
                            13.251108867666431,
                            42.794453404428026
                        ],
                        [
                            13.259514304898431,
                            42.7983548242654
                        ],
                        [
                            13.257502866819152,
                            42.801900987857834
                        ],
                        [
                            13.264657092742937,
                            42.81012918456574
                        ],
                        [
                            13.26240016239558,
                            42.81622877401804
                        ],
                        [
                            13.255982220474863,
                            42.82023362584371
                        ],
                        [
                            13.254568930986068,
                            42.82682511927504
                        ],
                        [
                            13.250155205755334,
                            42.834696919817816
                        ],
                        [
                            13.247552647674723,
                            42.84766994404143
                        ],
                        [
                            13.24674947816041,
                            42.85158193640714
                        ],
                        [
                            13.243074604730793,
                            42.85263718786922
                        ],
                        [
                            13.24145591426722,
                            42.85624140117403
                        ],
                        [
                            13.24362695913021,
                            42.85953096331869
                        ],
                        [
                            13.240264270678722,
                            42.86177919968455
                        ],
                        [
                            13.235114165042603,
                            42.86736364195917
                        ],
                        [
                            13.232997937904713,
                            42.86360111326968
                        ],
                        [
                            13.235009375985129,
                            42.86005848805911
                        ],
                        [
                            13.232480828405016,
                            42.859424342607554
                        ],
                        [
                            13.231989940268903,
                            42.85458864891021
                        ],
                        [
                            13.221534968607898,
                            42.850673293956845
                        ],
                        [
                            13.221947289050036,
                            42.847544022098475
                        ],
                        [
                            13.219752397819207,
                            42.845756531753665
                        ],
                        [
                            13.217228600231607,
                            42.84681014145582
                        ],
                        [
                            13.211935609190533,
                            42.841527840387954
                        ],
                        [
                            13.206795099942752,
                            42.84054749416714
                        ],
                        [
                            13.20407112782604,
                            42.84030458201971
                        ],
                        [
                            13.202054939753225,
                            42.842160357511545
                        ],
                        [
                            13.194016505224369,
                            42.83898168013434
                        ],
                        [
                            13.193280124820149,
                            42.83669764099119
                        ],
                        [
                            13.187744016507793,
                            42.83481195241927
                        ],
                        [
                            13.181490527764339,
                            42.837394857105636
                        ],
                        [
                            13.168341752641282,
                            42.83357723832813
                        ],
                        [
                            13.164552493766877,
                            42.833891796742705
                        ],
                        [
                            13.163086857948741,
                            42.831855740928916
                        ],
                        [
                            13.160112643570386,
                            42.832477906917205
                        ],
                        [
                            13.154111772086537,
                            42.83503993904253
                        ],
                        [
                            13.153706576634704,
                            42.84070196495736
                        ],
                        [
                            13.150422552275018,
                            42.84097455091191
                        ],
                        [
                            13.144485998504365,
                            42.846438559931414
                        ],
                        [
                            13.139602760107493,
                            42.857066428912816
                        ],
                        [
                            13.121847916519556,
                            42.872979424017444
                        ],
                        [
                            13.111871765210623,
                            42.88955536271348
                        ],
                        [
                            13.097729595550362,
                            42.894441584385476
                        ],
                        [
                            13.088835548778945,
                            42.89649123613751
                        ],
                        [
                            13.08851139241682,
                            42.90101639132587
                        ],
                        [
                            13.080925749675515,
                            42.899115197892485
                        ],
                        [
                            13.06848071490623,
                            42.90633187463587
                        ],
                        [
                            13.053976549321902,
                            42.920089099552
                        ],
                        [
                            13.04374322356108,
                            42.9151289527145
                        ],
                        [
                            13.038528896612945,
                            42.907878302754995
                        ],
                        [
                            13.0325550145688,
                            42.906472229706424
                        ],
                        [
                            13.026001312231216,
                            42.90214296016478
                        ],
                        [
                            13.022355035163002,
                            42.90338239044101
                        ],
                        [
                            13.010729891284512,
                            42.90265961181248
                        ],
                        [
                            13.005432053849802,
                            42.90562895880163
                        ],
                        [
                            13.0051412438427,
                            42.91202458458508
                        ],
                        [
                            13.004106928441047,
                            42.913609419168154
                        ],
                        [
                            13.001552159496242,
                            42.91363384140635
                        ],
                        [
                            12.999903070526699,
                            42.90627480677776
                        ],
                        [
                            12.992446159611944,
                            42.90023992045576
                        ],
                        [
                            12.999066651058541,
                            42.89837893110345
                        ],
                        [
                            13.002372146788218,
                            42.89576189764392
                        ],
                        [
                            13.002141000900878,
                            42.893438057884055
                        ],
                        [
                            12.9986067342806,
                            42.89457465364828
                        ],
                        [
                            12.99781316115346,
                            42.891922517194956
                        ],
                        [
                            13.0016238914001,
                            42.88926330633069
                        ],
                        [
                            12.99521079587305,
                            42.88501490089274
                        ],
                        [
                            12.99261318058609,
                            42.879793511540385
                        ],
                        [
                            12.991307247952847,
                            42.87465202602999
                        ],
                        [
                            12.989243463544312,
                            42.86957298734774
                        ],
                        [
                            12.986350288256745,
                            42.86906295050346
                        ],
                        [
                            12.980685448117754,
                            42.871314320948215
                        ],
                        [
                            12.980411800184413,
                            42.869632393894534
                        ],
                        [
                            12.9756406686366,
                            42.870215744373326
                        ],
                        [
                            12.973834155079999,
                            42.87674077912496
                        ],
                        [
                            12.97508289499143,
                            42.881511874797326
                        ],
                        [
                            12.97293085010162,
                            42.88497119640465
                        ],
                        [
                            12.975063798617072,
                            42.88470054485475
                        ],
                        [
                            12.972947475079138,
                            42.89087483944135
                        ],
                        [
                            12.969134369835729,
                            42.89269067420298
                        ],
                        [
                            12.970914565627595,
                            42.89676044804335
                        ],
                        [
                            12.970042232007529,
                            42.906016368093645
                        ],
                        [
                            12.97331903497573,
                            42.91314679387952
                        ],
                        [
                            12.975344723035704,
                            42.914665291659475
                        ],
                        [
                            12.972818550452416,
                            42.91487472208567
                        ],
                        [
                            12.976529048833783,
                            42.92646458835844
                        ],
                        [
                            12.971116825953288,
                            42.92869292562304
                        ],
                        [
                            12.948941324943291,
                            42.93006259545757
                        ],
                        [
                            12.951619979321066,
                            42.93414909983238
                        ],
                        [
                            12.950340171651305,
                            42.93828241929634
                        ],
                        [
                            12.944701552877007,
                            42.93987353313875
                        ],
                        [
                            12.948047519952155,
                            42.94165655908452
                        ],
                        [
                            12.940216384942687,
                            42.94230550666197
                        ],
                        [
                            12.941739213483686,
                            42.94470775863843
                        ],
                        [
                            12.93379844302197,
                            42.946302183396085
                        ],
                        [
                            12.927275111888264,
                            42.95346385469503
                        ],
                        [
                            12.921324308135866,
                            42.95386325954988
                        ],
                        [
                            12.91843825783863,
                            42.95588131511636
                        ],
                        [
                            12.916410194781918,
                            42.95352135894365
                        ],
                        [
                            12.894963852785736,
                            42.96456668775599
                        ],
                        [
                            12.89847417663799,
                            42.97485341958972
                        ],
                        [
                            12.906543582524051,
                            42.9790520527402
                        ],
                        [
                            12.905738038014022,
                            42.982113631132734
                        ],
                        [
                            12.907966275599847,
                            42.98576607170398
                        ],
                        [
                            12.90112888824666,
                            42.99035610812726
                        ],
                        [
                            12.898983968347238,
                            42.996335053784975
                        ],
                        [
                            12.896045475321785,
                            42.99966591926827
                        ],
                        [
                            12.892687536862695,
                            43.00359265803942
                        ],
                        [
                            12.887911558921246,
                            43.01240731290929
                        ],
                        [
                            12.897739880388713,
                            43.02472765028108
                        ],
                        [
                            12.904410439567897,
                            43.020715161922624
                        ],
                        [
                            12.905485226313772,
                            43.023525529214794
                        ],
                        [
                            12.906164317595028,
                            43.03534576331026
                        ],
                        [
                            12.898132911656432,
                            43.04461216577914
                        ],
                        [
                            12.893783600538995,
                            43.04543943621684
                        ],
                        [
                            12.889217297113817,
                            43.058904432510445
                        ],
                        [
                            12.897215098507274,
                            43.093844850490115
                        ],
                        [
                            12.893225665102648,
                            43.09286678298892
                        ],
                        [
                            12.885523165518663,
                            43.0992951132348
                        ],
                        [
                            12.886800501791527,
                            43.10423340395701
                        ],
                        [
                            12.883664209232649,
                            43.107107779782126
                        ],
                        [
                            12.88179565655949,
                            43.12145683208573
                        ],
                        [
                            12.863078133072548,
                            43.12449730196653
                        ],
                        [
                            12.859770262346018,
                            43.12626450144546
                        ],
                        [
                            12.84400795326323,
                            43.12196456732718
                        ],
                        [
                            12.839557211786541,
                            43.126161528664255
                        ],
                        [
                            12.83858958909488,
                            43.1314698330313
                        ],
                        [
                            12.834032882056903,
                            43.138381052578694
                        ],
                        [
                            12.831513834463976,
                            43.141109495946864
                        ],
                        [
                            12.829478550016773,
                            43.146131335454214
                        ],
                        [
                            12.84833420891627,
                            43.1522273492028
                        ],
                        [
                            12.854755886964426,
                            43.15581439322861
                        ],
                        [
                            12.85592596278184,
                            43.162529936386875
                        ],
                        [
                            12.862841917829059,
                            43.165877903715284
                        ],
                        [
                            12.862524886457265,
                            43.17290170400534
                        ],
                        [
                            12.86352813410062,
                            43.180188853747495
                        ],
                        [
                            12.865584793518877,
                            43.18272430174426
                        ],
                        [
                            12.864104811320004,
                            43.1855515561501
                        ],
                        [
                            12.86886159648705,
                            43.189822635599256
                        ],
                        [
                            12.864898728061348,
                            43.205134379828905
                        ],
                        [
                            12.862529787264975,
                            43.211297347614334
                        ],
                        [
                            12.850678247493818,
                            43.20994419371024
                        ],
                        [
                            12.837082136880298,
                            43.21712611313677
                        ],
                        [
                            12.83769216045576,
                            43.22434720148527
                        ],
                        [
                            12.831314689880486,
                            43.23270296998842
                        ],
                        [
                            12.82734425644901,
                            43.238436047468554
                        ],
                        [
                            12.829682129487168,
                            43.24113226958599
                        ],
                        [
                            12.82570932106006,
                            43.24602609485001
                        ],
                        [
                            12.83057802027605,
                            43.26006661897276
                        ],
                        [
                            12.824743976965152,
                            43.26203814395899
                        ],
                        [
                            12.825711503256713,
                            43.26661621483851
                        ],
                        [
                            12.814319880263412,
                            43.269045618844586
                        ],
                        [
                            12.81139810861444,
                            43.268354951967694
                        ],
                        [
                            12.807475367919835,
                            43.271100139280975
                        ],
                        [
                            12.803485934515237,
                            43.270124907347736
                        ],
                        [
                            12.804336700364217,
                            43.273128581857605
                        ],
                        [
                            12.788961505525776,
                            43.28326644451258
                        ],
                        [
                            12.79209057669317,
                            43.28775792660278
                        ],
                        [
                            12.799595180344767,
                            43.29077117324198
                        ],
                        [
                            12.802307994676312,
                            43.30239833295869
                        ],
                        [
                            12.799388232491879,
                            43.32520994023861
                        ],
                        [
                            12.792603287866854,
                            43.328467428829384
                        ],
                        [
                            12.794667072276553,
                            43.333508542083706
                        ],
                        [
                            12.791392547903968,
                            43.33712787580774
                        ],
                        [
                            12.781943198884164,
                            43.34134839683887
                        ],
                        [
                            12.782851157456093,
                            43.34471598052539
                        ],
                        [
                            12.776795468244927,
                            43.34772633390082
                        ],
                        [
                            12.772369868030268,
                            43.35134654713758
                        ],
                        [
                            12.771230667171011,
                            43.355533198946574
                        ],
                        [
                            12.770823096722467,
                            43.360310416533224
                        ],
                        [
                            12.769195382723836,
                            43.36053739033804
                        ],
                        [
                            12.75820410878768,
                            43.36553419256549
                        ],
                        [
                            12.759898515496303,
                            43.36902170959459
                        ],
                        [
                            12.755144008925924,
                            43.375457369778985
                        ],
                        [
                            12.747968215230998,
                            43.379488166110605
                        ],
                        [
                            12.753151570819796,
                            43.385662972328134
                        ],
                        [
                            12.74609016256963,
                            43.39042739184063
                        ],
                        [
                            12.753713901659609,
                            43.39584262671147
                        ],
                        [
                            12.762812872953674,
                            43.39676738210136
                        ],
                        [
                            12.774312412880874,
                            43.40242272739641
                        ],
                        [
                            12.781199675166533,
                            43.41542484841764
                        ],
                        [
                            12.773835581925397,
                            43.422348883151216
                        ],
                        [
                            12.767296129569615,
                            43.423072369294346
                        ],
                        [
                            12.765189306018755,
                            43.432536171744545
                        ],
                        [
                            12.774538519574492,
                            43.43260371446058
                        ],
                        [
                            12.769722070287713,
                            43.43699412847715
                        ],
                        [
                            12.766640595454987,
                            43.43938388648533
                        ],
                        [
                            12.76561815503834,
                            43.445133833815134
                        ],
                        [
                            12.776892921189983,
                            43.450987104223174
                        ],
                        [
                            12.772784255945993,
                            43.45745385184594
                        ],
                        [
                            12.766952587632943,
                            43.46025448962084
                        ],
                        [
                            12.754692960241528,
                            43.459736289743034
                        ],
                        [
                            12.750493755920616,
                            43.463981170738094
                        ],
                        [
                            12.747822226533287,
                            43.46243648970497
                        ],
                        [
                            12.746115848440638,
                            43.46461941458176
                        ],
                        [
                            12.739488232003737,
                            43.4639568977488
                        ],
                        [
                            12.734688504095544,
                            43.464351249944514
                        ],
                        [
                            12.732631844677343,
                            43.46182752828216
                        ],
                        [
                            12.726185402795238,
                            43.45576427678134
                        ],
                        [
                            12.72147868536075,
                            43.44937169932311
                        ],
                        [
                            12.714534133952156,
                            43.445855903781364
                        ],
                        [
                            12.70796390303829,
                            43.42586648532213
                        ],
                        [
                            12.6943225710869,
                            43.4269879749817
                        ],
                        [
                            12.685438442405882,
                            43.428778678314245
                        ],
                        [
                            12.679022875481962,
                            43.43357951470253
                        ],
                        [
                            12.66756693477538,
                            43.43312776777549
                        ],
                        [
                            12.664321006764283,
                            43.436924592458155
                        ],
                        [
                            12.654542847788889,
                            43.43410548786139
                        ],
                        [
                            12.653920852830481,
                            43.43257736052564
                        ],
                        [
                            12.646287517353358,
                            43.43366933674503
                        ],
                        [
                            12.636006218704324,
                            43.431770832908455
                        ],
                        [
                            12.635291309671175,
                            43.42718443345194
                        ],
                        [
                            12.630505928142753,
                            43.42274652370094
                        ],
                        [
                            12.6196529824214,
                            43.417134604350196
                        ],
                        [
                            12.618983294726178,
                            43.41858348771359
                        ],
                        [
                            12.622839246311685,
                            43.421983148942786
                        ],
                        [
                            12.620212938263194,
                            43.42647300044999
                        ],
                        [
                            12.615618134877764,
                            43.4298237152976
                        ],
                        [
                            12.613509032730178,
                            43.42860354474061
                        ],
                        [
                            12.609193067966714,
                            43.43128091860629
                        ],
                        [
                            12.610437057884525,
                            43.43433720016131
                        ],
                        [
                            12.60129989384069,
                            43.43973284971247
                        ],
                        [
                            12.598382872186363,
                            43.44071570643288
                        ],
                        [
                            12.592103162078246,
                            43.4439254697503
                        ],
                        [
                            12.585506516999573,
                            43.44428187690551
                        ],
                        [
                            12.582820641232587,
                            43.44756759832819
                        ],
                        [
                            12.578669129646329,
                            43.44883752892065
                        ],
                        [
                            12.571359854131288,
                            43.45528892008184
                        ],
                        [
                            12.557840071466586,
                            43.464765016997234
                        ],
                        [
                            12.557146537404577,
                            43.46770025329022
                        ],
                        [
                            12.553276239438276,
                            43.46913234340812
                        ],
                        [
                            12.548280307110161,
                            43.475793889888934
                        ]
                    ]
                ],
                "type": "Polygon"
            },
        },
        {
            "type": "Feature",
            "properties": {
                name: "Tuscany",
                image: 'https://vervewine.com/cdn/shop/articles/need-to-know-tuscany_1170x.jpg?v=1589561605'
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            10.018783175309409,
                            44.04530253401197
                        ],
                        [
                            10.03590131862208,
                            44.03557270958038
                        ],
                        [
                            10.05102898015437,
                            44.03127963201047
                        ],
                        [
                            10.063170919015192,
                            44.027988061925555
                        ],
                        [
                            10.070336653425926,
                            44.02483943253529
                        ],
                        [
                            10.093028145723878,
                            44.01109800163786
                        ],
                        [
                            10.10576722911901,
                            44.00021711035771
                        ],
                        [
                            10.112335818995433,
                            43.999787560544576
                        ],
                        [
                            10.132439684978749,
                            43.98331913989952
                        ],
                        [
                            10.149955924648225,
                            43.96985460188765
                        ],
                        [
                            10.185187452163802,
                            43.938186586718274
                        ],
                        [
                            10.214447534338206,
                            43.90478076675785
                        ],
                        [
                            10.224348328070533,
                            43.89232181328188
                        ],
                        [
                            10.240319582665677,
                            43.86375483282052
                        ],
                        [
                            10.239177183254952,
                            43.8575098463628
                        ],
                        [
                            10.279364619485563,
                            43.725509327433315
                        ],
                        [
                            10.283325439090646,
                            43.68438317128911
                        ],
                        [
                            10.269917926869994,
                            43.67493862091962
                        ],
                        [
                            10.285868407095677,
                            43.63826623825139
                        ],
                        [
                            10.293691432418939,
                            43.5878144569856
                        ],
                        [
                            10.294556444468839,
                            43.54285341536209
                        ],
                        [
                            10.320404612805845,
                            43.50116129090085
                        ],
                        [
                            10.332403740991793,
                            43.48171974618057
                        ],
                        [
                            10.330919434364006,
                            43.47447448867118
                        ],
                        [
                            10.342532329755642,
                            43.46461988036478
                        ],
                        [
                            10.355879001989734,
                            43.46303952734547
                        ],
                        [
                            10.378550726715247,
                            43.44867592069775
                        ],
                        [
                            10.392908934762403,
                            43.428992523342885
                        ],
                        [
                            10.400462174368073,
                            43.424471347609796
                        ],
                        [
                            10.403796399874324,
                            43.4111069549829
                        ],
                        [
                            10.4032445477917,
                            43.4014925462769
                        ],
                        [
                            10.422138870911112,
                            43.4029921087845
                        ],
                        [
                            10.456080729908393,
                            43.34158983310746
                        ],
                        [
                            10.456632953696044,
                            43.32563179445603
                        ],
                        [
                            10.46162825549058,
                            43.31466625105759
                        ],
                        [
                            10.48790061892538,
                            43.29933202931909
                        ],
                        [
                            10.506813498260925,
                            43.2888167924699
                        ],
                        [
                            10.525849008875326,
                            43.238386776501784
                        ],
                        [
                            10.539511540371365,
                            43.14498428449886
                        ],
                        [
                            10.5357000759426,
                            43.072952260699964
                        ],
                        [
                            10.521027070448014,
                            43.017480438104315
                        ],
                        [
                            10.511313469105147,
                            43.01241247232886
                        ],
                        [
                            10.509226769970837,
                            43.00560579049019
                        ],
                        [
                            10.51497414213378,
                            42.9989162497331
                        ],
                        [
                            10.50796548180486,
                            42.98857373830322
                        ],
                        [
                            10.493413151311046,
                            42.99391882867826
                        ],
                        [
                            10.483736575706956,
                            42.98925879851811
                        ],
                        [
                            10.48920159755798,
                            42.9752393759162
                        ],
                        [
                            10.484726908370192,
                            42.96494024272488
                        ],
                        [
                            10.499144949167231,
                            42.93501148386824
                        ],
                        [
                            10.494903271274069,
                            42.933136431866416
                        ],
                        [
                            10.508906781106845,
                            42.9326116840619
                        ],
                        [
                            10.529835413438889,
                            42.91844774254875
                        ],
                        [
                            10.544554088963054,
                            42.92149482871514
                        ],
                        [
                            10.561781638825437,
                            42.94518744442459
                        ],
                        [
                            10.562889664446573,
                            42.95068839516111
                        ],
                        [
                            10.665702651024048,
                            42.95186019683942
                        ],
                        [
                            10.70992729049891,
                            42.9423564661669
                        ],
                        [
                            10.75029381441891,
                            42.924208240786356
                        ],
                        [
                            10.779473718563537,
                            42.90473485880622
                        ],
                        [
                            10.785301967879462,
                            42.885076249329074
                        ],
                        [
                            10.772916478185095,
                            42.874563197868184
                        ],
                        [
                            10.770424245567,
                            42.8646138268023
                        ],
                        [
                            10.774120189106867,
                            42.83050042195191
                        ],
                        [
                            10.75885565298995,
                            42.80860879552438
                        ],
                        [
                            10.74002629412982,
                            42.80780072377186
                        ],
                        [
                            10.730807898375815,
                            42.80044184817356
                        ],
                        [
                            10.788638095362188,
                            42.77256426503047
                        ],
                        [
                            10.793836916531887,
                            42.77716753252051
                        ],
                        [
                            10.866702893248686,
                            42.76575531475672
                        ],
                        [
                            10.93483795367419,
                            42.74517638358674
                        ],
                        [
                            10.974955068346532,
                            42.720983744015484
                        ],
                        [
                            11.001107886528672,
                            42.68089804841256
                        ],
                        [
                            11.01312839095013,
                            42.6541956975274
                        ],
                        [
                            11.071896503442616,
                            42.63546031840224
                        ],
                        [
                            11.087591103595685,
                            42.625095924376524
                        ],
                        [
                            11.088502289279148,
                            42.61740745861269
                        ],
                        [
                            11.081238788569635,
                            42.61566084015436
                        ],
                        [
                            11.08937798858878,
                            42.60594966630015
                        ],
                        [
                            11.102179435013767,
                            42.60324605778368
                        ],
                        [
                            11.109129646179497,
                            42.58580544586681
                        ],
                        [
                            11.10591447900282,
                            42.58176962096141
                        ],
                        [
                            11.132317497552663,
                            42.55281957943325
                        ],
                        [
                            11.138384507151756,
                            42.56058316892762
                        ],
                        [
                            11.15600029711888,
                            42.56290950001667
                        ],
                        [
                            11.190281648380932,
                            42.52087197223676
                        ],
                        [
                            11.188994992401291,
                            42.482427032755425
                        ],
                        [
                            11.180592721167244,
                            42.45394664604055
                        ],
                        [
                            11.160971329741102,
                            42.43399490289883
                        ],
                        [
                            11.145929694563165,
                            42.435932225116545
                        ],
                        [
                            11.136440510318124,
                            42.43392007796186
                        ],
                        [
                            11.130575400834005,
                            42.43853524270315
                        ],
                        [
                            11.109336514490906,
                            42.44588468280807
                        ],
                        [
                            11.099476606502748,
                            42.440530491940535
                        ],
                        [
                            11.09304817394775,
                            42.42878661663556
                        ],
                        [
                            11.086795920885919,
                            42.42932272694355
                        ],
                        [
                            11.089062254815133,
                            42.42375983197468
                        ],
                        [
                            11.090543562157677,
                            42.410193436819384
                        ],
                        [
                            11.086649296413214,
                            42.405786717649136
                        ],
                        [
                            11.095040255003141,
                            42.401861977539
                        ],
                        [
                            11.097169330892342,
                            42.39402002389943
                        ],
                        [
                            11.109327369916372,
                            42.38863043889137
                        ],
                        [
                            11.115959952340575,
                            42.391057907272994
                        ],
                        [
                            11.131786068900482,
                            42.38351886115407
                        ],
                        [
                            11.130316760411034,
                            42.37902594771498
                        ],
                        [
                            11.141438964161836,
                            42.37786129067976
                        ],
                        [
                            11.152986693212569,
                            42.36869173489217
                        ],
                        [
                            11.153822198628745,
                            42.35998813735114
                        ],
                        [
                            11.16532114533564,
                            42.36846468938376
                        ],
                        [
                            11.177124760884794,
                            42.36742255301775
                        ],
                        [
                            11.19027761273955,
                            42.362828708046834
                        ],
                        [
                            11.186221644110617,
                            42.37833117957527
                        ],
                        [
                            11.210789848508284,
                            42.38947223653824
                        ],
                        [
                            11.212418352726559,
                            42.396924374998065
                        ],
                        [
                            11.210776282195553,
                            42.40782764503123
                        ],
                        [
                            11.27819271955562,
                            42.41777077741369
                        ],
                        [
                            11.279307858359829,
                            42.4106351515787
                        ],
                        [
                            11.289024874398308,
                            42.40615096294778
                        ],
                        [
                            11.296227341482847,
                            42.4081264064603
                        ],
                        [
                            11.449414972916202,
                            42.378028549226826
                        ],
                        [
                            11.452657474515632,
                            42.38889081592512
                        ],
                        [
                            11.44705669217879,
                            42.392920269162175
                        ],
                        [
                            11.45643041443293,
                            42.40319067575942
                        ],
                        [
                            11.481710425321296,
                            42.419904724077185
                        ],
                        [
                            11.482762923159129,
                            42.43758838274135
                        ],
                        [
                            11.49330470232593,
                            42.43886641899684
                        ],
                        [
                            11.496411032722307,
                            42.444136595673285
                        ],
                        [
                            11.61521523355242,
                            42.43669999839781
                        ],
                        [
                            11.619905155988107,
                            42.485471278876474
                        ],
                        [
                            11.555611166729932,
                            42.51860351458316
                        ],
                        [
                            11.588527400128783,
                            42.54811457748653
                        ],
                        [
                            11.586779863577107,
                            42.578758562128996
                        ],
                        [
                            11.58804123373551,
                            42.58244515664572
                        ],
                        [
                            11.624204422079345,
                            42.562688755567734
                        ],
                        [
                            11.658700644756038,
                            42.57579344154507
                        ],
                        [
                            11.723186826685634,
                            42.60533957603789
                        ],
                        [
                            11.758584021417988,
                            42.64140715455463
                        ],
                        [
                            11.759666001249457,
                            42.64528731543837
                        ],
                        [
                            11.802251151581714,
                            42.654045012411785
                        ],
                        [
                            11.782497625111603,
                            42.67887939674995
                        ],
                        [
                            11.781594706704198,
                            42.710978749276165
                        ],
                        [
                            11.80973086997696,
                            42.71277337603149
                        ],
                        [
                            11.817973305043665,
                            42.748030625323764
                        ],
                        [
                            11.784424160450556,
                            42.761032312964915
                        ],
                        [
                            11.745337712714075,
                            42.784670764985464
                        ],
                        [
                            11.770599423383771,
                            42.82168441436178
                        ],
                        [
                            11.811257141204038,
                            42.80229781639139
                        ],
                        [
                            11.81237905410319,
                            42.82653933081636
                        ],
                        [
                            11.856402839542767,
                            42.845930694761336
                        ],
                        [
                            11.890002305186755,
                            42.83738091673473
                        ],
                        [
                            11.889276397153196,
                            42.84433964130912
                        ],
                        [
                            11.913732893956421,
                            42.851330530843256
                        ],
                        [
                            11.925261906671182,
                            42.870215135403754
                        ],
                        [
                            11.955505983784008,
                            42.870862036718876
                        ],
                        [
                            11.957075771388844,
                            42.900932341885806
                        ],
                        [
                            11.93048753606945,
                            42.904038324049
                        ],
                        [
                            11.952365017843022,
                            42.954025437280336
                        ],
                        [
                            11.943345566510942,
                            42.96332686754246
                        ],
                        [
                            11.980316070557961,
                            43.051139418946946
                        ],
                        [
                            11.958357714055296,
                            43.06823795765598
                        ],
                        [
                            11.94305818514789,
                            43.05287275361397
                        ],
                        [
                            11.925970477342673,
                            43.07361258046461
                        ],
                        [
                            11.920732963285587,
                            43.08348185307082
                        ],
                        [
                            11.931952679627358,
                            43.08839504120084
                        ],
                        [
                            11.928617555289264,
                            43.102596523749355
                        ],
                        [
                            11.91944916631121,
                            43.106947484548925
                        ],
                        [
                            11.922314171972687,
                            43.117489044916226
                        ],
                        [
                            11.908985559277852,
                            43.1307135369407
                        ],
                        [
                            11.919930242493422,
                            43.13384992976023
                        ],
                        [
                            11.928379803497421,
                            43.15316394469923
                        ],
                        [
                            11.941628005039775,
                            43.15925030465556
                        ],
                        [
                            11.970542600850536,
                            43.16718345978643
                        ],
                        [
                            11.990437745329189,
                            43.184383527008606
                        ],
                        [
                            12.004064233313414,
                            43.181015861745436
                        ],
                        [
                            12.026492244916085,
                            43.186790319525386
                        ],
                        [
                            12.034491511319032,
                            43.19868829446685
                        ],
                        [
                            12.022480958844056,
                            43.20057631456814
                        ],
                        [
                            12.035752002550907,
                            43.21472678627188
                        ],
                        [
                            12.034009971560153,
                            43.219353764581456
                        ],
                        [
                            12.042284271088022,
                            43.23301556908896
                        ],
                        [
                            12.033276240838262,
                            43.24630691337066
                        ],
                        [
                            12.044908506870058,
                            43.25386140258706
                        ],
                        [
                            12.073590181797584,
                            43.24393553815938
                        ],
                        [
                            12.089233733901466,
                            43.23771583459563
                        ],
                        [
                            12.105988839592413,
                            43.24260831527798
                        ],
                        [
                            12.11506539633578,
                            43.25351047521633
                        ],
                        [
                            12.144633311714585,
                            43.25376103676729
                        ],
                        [
                            12.166545520311558,
                            43.2680828000849
                        ],
                        [
                            12.178853948075982,
                            43.27602675506904
                        ],
                        [
                            12.204387735219598,
                            43.2819841252016
                        ],
                        [
                            12.215217744037204,
                            43.29228699042514
                        ],
                        [
                            12.226574976942771,
                            43.29806753728266
                        ],
                        [
                            12.191918485150183,
                            43.31256203805859
                        ],
                        [
                            12.18270441184444,
                            43.30078636863729
                        ],
                        [
                            12.184847571442901,
                            43.294789276530594
                        ],
                        [
                            12.176034626743558,
                            43.28163356884852
                        ],
                        [
                            12.168608268757595,
                            43.2813498640069
                        ],
                        [
                            12.155932975632197,
                            43.28687271240895
                        ],
                        [
                            12.137722293157623,
                            43.29240337240921
                        ],
                        [
                            12.124932325634319,
                            43.305097946693394
                        ],
                        [
                            12.135911272096678,
                            43.320308062716464
                        ],
                        [
                            12.136908151284587,
                            43.33857345170466
                        ],
                        [
                            12.12561944487257,
                            43.356949943222475
                        ],
                        [
                            12.101495086606889,
                            43.371905305609175
                        ],
                        [
                            12.091490177167884,
                            43.36691494693676
                        ],
                        [
                            12.078929558441757,
                            43.3652652411802
                        ],
                        [
                            12.071399947138787,
                            43.37617032909003
                        ],
                        [
                            12.073600212148591,
                            43.39029660325602
                        ],
                        [
                            12.076740250936297,
                            43.40255448120266
                        ],
                        [
                            12.069462830596336,
                            43.40717548011227
                        ],
                        [
                            12.054449755897394,
                            43.39768316221512
                        ],
                        [
                            12.048387142460285,
                            43.40220462035205
                        ],
                        [
                            12.02907632748213,
                            43.4006640878807
                        ],
                        [
                            12.02789578382476,
                            43.41282827030781
                        ],
                        [
                            12.03089830604867,
                            43.42419930099618
                        ],
                        [
                            12.044948764805156,
                            43.42751204943039
                        ],
                        [
                            12.057131097089382,
                            43.43857247296549
                        ],
                        [
                            12.079799878571492,
                            43.43402884899166
                        ],
                        [
                            12.098079087539645,
                            43.45262616249664
                        ],
                        [
                            12.126427175087713,
                            43.47090915820945
                        ],
                        [
                            12.142357181400058,
                            43.4704933835194
                        ],
                        [
                            12.145222187060227,
                            43.48097211907506
                        ],
                        [
                            12.129028568705252,
                            43.4836413239797
                        ],
                        [
                            12.092355012794371,
                            43.50093460064036
                        ],
                        [
                            12.100399963525035,
                            43.528826543403426
                        ],
                        [
                            12.15063101735123,
                            43.531394270208295
                        ],
                        [
                            12.16449781163962,
                            43.56503551585854
                        ],
                        [
                            12.193458091779377,
                            43.58895515511662
                        ],
                        [
                            12.19855206048473,
                            43.60381539441707
                        ],
                        [
                            12.214504908960237,
                            43.61141669428031
                        ],
                        [
                            12.205370783228972,
                            43.62775269446999
                        ],
                        [
                            12.186082810414035,
                            43.63423102698536
                        ],
                        [
                            12.184890845675739,
                            43.64234259213305
                        ],
                        [
                            12.191916075053996,
                            43.64399300818749
                        ],
                        [
                            12.20449953594462,
                            43.65364564319327
                        ],
                        [
                            12.231236708910103,
                            43.655461683696245
                        ],
                        [
                            12.25031817509236,
                            43.67125533390157
                        ],
                        [
                            12.249676276605214,
                            43.68287606747481
                        ],
                        [
                            12.27328483186389,
                            43.67649417972237
                        ],
                        [
                            12.296262909718962,
                            43.68573555837531
                        ],
                        [
                            12.301970078877389,
                            43.69861296109116
                        ],
                        [
                            12.304984022183618,
                            43.71393129415682
                        ],
                        [
                            12.303001221312115,
                            43.72876535953756
                        ],
                        [
                            12.317418545429547,
                            43.71869423375179
                        ],
                        [
                            12.314267085560914,
                            43.702498895604634
                        ],
                        [
                            12.32018076135202,
                            43.693119378374774
                        ],
                        [
                            12.314336075631132,
                            43.679362234710254
                        ],
                        [
                            12.330930822593842,
                            43.67533403789136
                        ],
                        [
                            12.336076504417605,
                            43.68069675891775
                        ],
                        [
                            12.330346029517955,
                            43.70696451929203
                        ],
                        [
                            12.343995359668043,
                            43.71162861199474
                        ],
                        [
                            12.351948941741966,
                            43.70742027905891
                        ],
                        [
                            12.35936387864433,
                            43.703700465183715
                        ],
                        [
                            12.371259756720121,
                            43.708952989578336
                        ],
                        [
                            12.367901790217473,
                            43.715008474639006
                        ],
                        [
                            12.34062472849348,
                            43.73477498319565
                        ],
                        [
                            12.327032503754708,
                            43.750108823815765
                        ],
                        [
                            12.312741275117446,
                            43.75705408352175
                        ],
                        [
                            12.305716045739217,
                            43.755406776761106
                        ],
                        [
                            12.283597330509537,
                            43.76343564489733
                        ],
                        [
                            12.255232800951319,
                            43.75909005013554
                        ],
                        [
                            12.252654249498306,
                            43.75441315331818
                        ],
                        [
                            12.240632275942346,
                            43.75228560942941
                        ],
                        [
                            12.229584339409797,
                            43.760298398839154
                        ],
                        [
                            12.221607915171376,
                            43.75650728962057
                        ],
                        [
                            12.208783684399947,
                            43.75711149310237
                        ],
                        [
                            12.205517550132043,
                            43.74804679564056
                        ],
                        [
                            12.19835480418999,
                            43.745521648982475
                        ],
                        [
                            12.195890927136304,
                            43.73372258039325
                        ],
                        [
                            12.183479246053565,
                            43.73696024346012
                        ],
                        [
                            12.184441861995992,
                            43.743103995479885
                        ],
                        [
                            12.171629052306855,
                            43.747707186804575
                        ],
                        [
                            12.162219893449361,
                            43.76225143542251
                        ],
                        [
                            12.147355756396422,
                            43.757690608306376
                        ],
                        [
                            12.124377678541322,
                            43.74846032122883
                        ],
                        [
                            12.109272771607692,
                            43.75413924265422
                        ],
                        [
                            12.086432210316332,
                            43.74578600874949
                        ],
                        [
                            12.076427300877384,
                            43.740826519026996
                        ],
                        [
                            12.06535652218028,
                            43.740843021909456
                        ],
                        [
                            12.059992912641604,
                            43.753725086601236
                        ],
                        [
                            12.050962040227375,
                            43.75890689940189
                        ],
                        [
                            12.039066162151613,
                            43.75365875439633
                        ],
                        [
                            12.027204547322611,
                            43.76040496297736
                        ],
                        [
                            12.004398249276733,
                            43.764046687488275
                        ],
                        [
                            11.984949917734838,
                            43.761638030851856
                        ],
                        [
                            11.971472367395336,
                            43.76984817634303
                        ],
                        [
                            11.948138845261553,
                            43.777974397119266
                        ],
                        [
                            11.948849270240942,
                            43.790352120860774
                        ],
                        [
                            11.914032419721394,
                            43.795861519888234
                        ],
                        [
                            11.91138584329758,
                            43.81515146651361
                        ],
                        [
                            11.896785318288607,
                            43.80835340419574
                        ],
                        [
                            11.8737039871165,
                            43.81023893648637
                        ],
                        [
                            11.870621053740138,
                            43.81803727353571
                        ],
                        [
                            11.855630821205722,
                            43.81659834070493
                        ],
                        [
                            11.848995299352964,
                            43.8095937253959
                        ],
                        [
                            11.83926542304016,
                            43.80639296170992
                        ],
                        [
                            11.817559257499198,
                            43.81704469062433
                        ],
                        [
                            11.780243803102508,
                            43.84583134590156
                        ],
                        [
                            11.730069854687542,
                            43.86323534915704
                        ],
                        [
                            11.711343369207299,
                            43.87718143951494
                        ],
                        [
                            11.716328228726098,
                            43.920659714348716
                        ],
                        [
                            11.682485415231241,
                            43.936267626351224
                        ],
                        [
                            11.688711954299947,
                            43.95654327198858
                        ],
                        [
                            11.663246693649967,
                            43.97455836976792
                        ],
                        [
                            11.6501588508346,
                            43.97739536816292
                        ],
                        [
                            11.644256596125729,
                            43.99071288804859
                        ],
                        [
                            11.652381958008903,
                            43.99934506394905
                        ],
                        [
                            11.650376314972846,
                            44.00614599421644
                        ],
                        [
                            11.66658135441142,
                            44.00748144290941
                        ],
                        [
                            11.65702325790923,
                            44.017108327114386
                        ],
                        [
                            11.681342238148659,
                            44.02309152809923
                        ],
                        [
                            11.696196795550009,
                            44.04419476834852
                        ],
                        [
                            11.695279863936577,
                            44.053996937644854
                        ],
                        [
                            11.73507015289266,
                            44.08788862164096
                        ],
                        [
                            11.737258996821538,
                            44.09787287148461
                        ],
                        [
                            11.749567424585962,
                            44.10570769371893
                        ],
                        [
                            11.75136656098934,
                            44.12102034210716
                        ],
                        [
                            11.745441464115999,
                            44.12635946626733
                        ],
                        [
                            11.719907676972412,
                            44.12048541686218
                        ],
                        [
                            11.678867854288711,
                            44.1216123212173
                        ],
                        [
                            11.673848267945232,
                            44.11318721671617
                        ],
                        [
                            11.646664282045634,
                            44.09684360818437
                        ],
                        [
                            11.639684736995065,
                            44.11110527040083
                        ],
                        [
                            11.605280436165486,
                            44.119201376834894
                        ],
                        [
                            11.582577391436644,
                            44.11177148305305
                        ],
                        [
                            11.584903751926618,
                            44.12262375766531
                        ],
                        [
                            11.603274793129486,
                            44.125988553062626
                        ],
                        [
                            11.599527119101424,
                            44.1373316779723
                        ],
                        [
                            11.61790958138522,
                            44.144667873487464
                        ],
                        [
                            11.61471197360953,
                            44.15949353945669
                        ],
                        [
                            11.607159520143398,
                            44.1623134947196
                        ],
                        [
                            11.602690000052291,
                            44.157380449865
                        ],
                        [
                            11.591367030393513,
                            44.163595932359925
                        ],
                        [
                            11.572858472629491,
                            44.15936177781282
                        ],
                        [
                            11.56748344200858,
                            44.16818305121379
                        ],
                        [
                            11.551278402570006,
                            44.16685122578295
                        ],
                        [
                            11.553009012479833,
                            44.15832572433132
                        ],
                        [
                            11.544218909945016,
                            44.15330187532268
                        ],
                        [
                            11.531681133382051,
                            44.15961644215997
                        ],
                        [
                            11.51303505905426,
                            44.154510428361334
                        ],
                        [
                            11.51387157951524,
                            44.16371063358204
                        ],
                        [
                            11.50320192942587,
                            44.16237051404977
                        ],
                        [
                            11.483936798775431,
                            44.176731369065436
                        ],
                        [
                            11.444863389933943,
                            44.20055231961902
                        ],
                        [
                            11.449103561226536,
                            44.21961044547024
                        ],
                        [
                            11.42499062404309,
                            44.23831740908463
                        ],
                        [
                            11.388741502481167,
                            44.21524867635986
                        ],
                        [
                            11.388042498582678,
                            44.20692732372473
                        ],
                        [
                            11.378702329795374,
                            44.19842399243973
                        ],
                        [
                            11.341455865468902,
                            44.20409279478818
                        ],
                        [
                            11.32293588662128,
                            44.19589302547047
                        ],
                        [
                            11.325594849226718,
                            44.18156140871653
                        ],
                        [
                            11.281506819990113,
                            44.15557622205998
                        ],
                        [
                            11.237636254890447,
                            44.15825640675945
                        ],
                        [
                            11.197788396946407,
                            44.15134115935777
                        ],
                        [
                            11.19318136029284,
                            44.14553549097167
                        ],
                        [
                            11.217030685432348,
                            44.12902834448602
                        ],
                        [
                            11.259251051773361,
                            44.11588195438745
                        ],
                        [
                            11.26476359895804,
                            44.107925137744644
                        ],
                        [
                            11.2493608167336,
                            44.10387628430789
                        ],
                        [
                            11.24624362011042,
                            44.09971199528118
                        ],
                        [
                            11.21072776569369,
                            44.09685590771184
                        ],
                        [
                            11.173355205885684,
                            44.10563698286103
                        ],
                        [
                            11.140429324002895,
                            44.11140524024759
                        ],
                        [
                            11.101357938701426,
                            44.096488168221015
                        ],
                        [
                            11.0885108657655,
                            44.08913778493297
                        ],
                        [
                            11.052467787259502,
                            44.09074273143878
                        ],
                        [
                            11.024676166118212,
                            44.09786225882456
                        ],
                        [
                            11.00013925816387,
                            44.11001709037589
                        ],
                        [
                            11.002064490047445,
                            44.1222271464583
                        ],
                        [
                            11.015163753945075,
                            44.12337074744278
                        ],
                        [
                            11.011828629606953,
                            44.137329934390095
                        ],
                        [
                            10.994546299828016,
                            44.136967952124536
                        ],
                        [
                            10.99209384385523,
                            44.129219584050276
                        ],
                        [
                            10.970456668385737,
                            44.116846318817664
                        ],
                        [
                            10.969482631361075,
                            44.106766530124105
                        ],
                        [
                            10.957563911120786,
                            44.09359844838565
                        ],
                        [
                            10.9452440622741,
                            44.0817858220594
                        ],
                        [
                            10.926597987946366,
                            44.07667307781168
                        ],
                        [
                            10.919137366713557,
                            44.06446181848554
                        ],
                        [
                            10.89618213102301,
                            44.063234675923866
                        ],
                        [
                            10.906095208227242,
                            44.08320160783367
                        ],
                        [
                            10.871725170643174,
                            44.10322741364834
                        ],
                        [
                            10.848896030434105,
                            44.09889837467401
                        ],
                        [
                            10.84346302692984,
                            44.10948641439492
                        ],
                        [
                            10.83120028349316,
                            44.11754994523355
                        ],
                        [
                            10.817275920216872,
                            44.11117314383009
                        ],
                        [
                            10.75609971959841,
                            44.15235026322324
                        ],
                        [
                            10.71358147796667,
                            44.155803499486865
                        ],
                        [
                            10.643787418199224,
                            44.15794089418901
                        ],
                        [
                            10.62011033644697,
                            44.14044182925582
                        ],
                        [
                            10.620053231035655,
                            44.12057654513285
                        ],
                        [
                            10.587230602469901,
                            44.11529429596047
                        ],
                        [
                            10.57917376707897,
                            44.13052215235061
                        ],
                        [
                            10.524025916650913,
                            44.15530919460693
                        ],
                        [
                            10.51623269330392,
                            44.16829901530909
                        ],
                        [
                            10.525698957572757,
                            44.17370792138294
                        ],
                        [
                            10.510479376241165,
                            44.18641424167541
                        ],
                        [
                            10.4827105972644,
                            44.20145933845757
                        ],
                        [
                            10.489368961281684,
                            44.2163529209339
                        ],
                        [
                            10.462639718980824,
                            44.23105881636863
                        ],
                        [
                            10.435489996326652,
                            44.22664879029776
                        ],
                        [
                            10.412569023881531,
                            44.23732381128792
                        ],
                        [
                            10.409898640195138,
                            44.247676831232525
                        ],
                        [
                            10.377282054685338,
                            44.26706420140067
                        ],
                        [
                            10.353937111469293,
                            44.271159107437796
                        ],
                        [
                            10.332609232371539,
                            44.27244735451086
                        ],
                        [
                            10.317905454045615,
                            44.276722448562936
                        ],
                        [
                            10.296875450239924,
                            44.28767552946874
                        ],
                        [
                            10.25548767271789,
                            44.268024033049244
                        ],
                        [
                            10.234469089993098,
                            44.28294165779346
                        ],
                        [
                            10.220349641166422,
                            44.302579950835764
                        ],
                        [
                            10.206975344142563,
                            44.299700984480296
                        ],
                        [
                            10.200946993952215,
                            44.31603744409202
                        ],
                        [
                            10.191354634203208,
                            44.31373317588918
                        ],
                        [
                            10.162118857360554,
                            44.33502516068975
                        ],
                        [
                            10.166324765406387,
                            44.34216455593136
                        ],
                        [
                            10.141925374014505,
                            44.35513766985548
                        ],
                        [
                            10.11532571761279,
                            44.35421155534269
                        ],
                        [
                            10.098020545669328,
                            44.34593436532853
                        ],
                        [
                            10.056648120871557,
                            44.372154621656364
                        ],
                        [
                            10.042609083197277,
                            44.37285087148172
                        ],
                        [
                            9.98505446112543,
                            44.405642021525296
                        ],
                        [
                            10.003058636966017,
                            44.422202831398835
                        ],
                        [
                            10.004582740243166,
                            44.43569844274839
                        ],
                        [
                            10.00029642104488,
                            44.447464633107444
                        ],
                        [
                            9.977341185354305,
                            44.44624545724619
                        ],
                        [
                            9.969547962007368,
                            44.4591710604025
                        ],
                        [
                            9.959852348942775,
                            44.467857398824606
                        ],
                        [
                            9.934742532570084,
                            44.46856881088473
                        ],
                        [
                            9.920852432540556,
                            44.47408083261428
                        ],
                        [
                            9.912600975175906,
                            44.46860141280473
                        ],
                        [
                            9.902893941028992,
                            44.47333655768443
                        ],
                        [
                            9.870208829025671,
                            44.46895287475925
                        ],
                        [
                            9.82214377696377,
                            44.46844556104773
                        ],
                        [
                            9.812001350962305,
                            44.462679324639254
                        ],
                        [
                            9.818453671923606,
                            44.452936891477805
                        ],
                        [
                            9.796552884408868,
                            44.442849038872424
                        ],
                        [
                            9.785310325902572,
                            44.43014069372191
                        ],
                        [
                            9.767844795232179,
                            44.4130991433058
                        ],
                        [
                            9.754447656043794,
                            44.40231668083723
                        ],
                        [
                            9.762790945643133,
                            44.39285081192051
                        ],
                        [
                            9.747800713108717,
                            44.391425803380656
                        ],
                        [
                            9.744259545713277,
                            44.38072130486259
                        ],
                        [
                            9.716857632097486,
                            44.3824985561543
                        ],
                        [
                            9.69170048645799,
                            44.37032283481497
                        ],
                        [
                            9.681420543892784,
                            44.36367843531741
                        ],
                        [
                            9.70815771685821,
                            44.365472865025
                        ],
                        [
                            9.714071392649316,
                            44.35619768830671
                        ],
                        [
                            9.729382806215682,
                            44.32856009185764
                        ],
                        [
                            9.75111181392117,
                            44.32592033608705
                        ],
                        [
                            9.76457794317841,
                            44.313826330880374
                        ],
                        [
                            9.762801648939586,
                            44.30648666865616
                        ],
                        [
                            9.779923619991564,
                            44.29805527295002
                        ],
                        [
                            9.803234299960792,
                            44.28207490245717
                        ],
                        [
                            9.815668823206721,
                            44.28679245152054
                        ],
                        [
                            9.820516629739728,
                            44.28243599401566
                        ],
                        [
                            9.833203343947389,
                            44.28096741645297
                        ],
                        [
                            9.850187798435542,
                            44.271662514831064
                        ],
                        [
                            9.858107117262705,
                            44.25560101058716
                        ],
                        [
                            9.863344631321155,
                            44.245922597650434
                        ],
                        [
                            9.856835204948538,
                            44.235873033782184
                        ],
                        [
                            9.875435594947334,
                            44.22510738903475
                        ],
                        [
                            9.85521973301465,
                            44.19058780810002
                        ],
                        [
                            9.866267669547256,
                            44.18263286645572
                        ],
                        [
                            9.87278851700205,
                            44.196660257127036
                        ],
                        [
                            9.890632334115708,
                            44.20447378982709
                        ],
                        [
                            9.897130339406033,
                            44.210561180763875
                        ],
                        [
                            9.907524956369116,
                            44.21015871532464
                        ],
                        [
                            9.91357614872399,
                            44.20172987176704
                        ],
                        [
                            9.930308412250497,
                            44.198607845644716
                        ],
                        [
                            9.921930859404313,
                            44.19620037777602
                        ],
                        [
                            9.920968243463193,
                            44.190103313554204
                        ],
                        [
                            9.914882787861586,
                            44.18662718339783
                        ],
                        [
                            9.899457163473926,
                            44.17464408561179
                        ],
                        [
                            9.893486382271561,
                            44.164097312526025
                        ],
                        [
                            9.921048654614395,
                            44.17112608432967
                        ],
                        [
                            9.928188558391867,
                            44.165692363490905
                        ],
                        [
                            9.939233523759711,
                            44.168436087075804
                        ],
                        [
                            9.946575361529277,
                            44.1686673215699
                        ],
                        [
                            9.94979147061585,
                            44.16614462080804
                        ],
                        [
                            9.954730175205132,
                            44.16465248699992
                        ],
                        [
                            9.960013022396943,
                            44.16809082844824
                        ],
                        [
                            9.96484197817923,
                            44.16759144066835
                        ],
                        [
                            9.967653869844563,
                            44.169903484736565
                        ],
                        [
                            9.973838905208353,
                            44.167538017988456
                        ],
                        [
                            9.979946616478617,
                            44.16175872412626
                        ],
                        [
                            9.97549567850396,
                            44.15276622166947
                        ],
                        [
                            9.980782573302083,
                            44.143245534581574
                        ],
                        [
                            9.974202142730014,
                            44.13844117987901
                        ],
                        [
                            9.98224668958082,
                            44.132454223503025
                        ],
                        [
                            9.9822574749935,
                            44.12623691031398
                        ],
                        [
                            9.987161115597404,
                            44.12135393631931
                        ],
                        [
                            9.99415478601659,
                            44.119103335927946
                        ],
                        [
                            9.994237901455454,
                            44.10814035845709
                        ],
                        [
                            9.993720035145003,
                            44.10588508279534
                        ],
                        [
                            9.990080293322633,
                            44.10282349193119
                        ],
                        [
                            9.987867343788476,
                            44.10295909425304
                        ],
                        [
                            9.981840577530974,
                            44.099958672115406
                        ],
                        [
                            9.993180422560208,
                            44.092765062001774
                        ],
                        [
                            10.002073482560542,
                            44.108420998597765
                        ],
                        [
                            10.018782608070918,
                            44.11902428884136
                        ],
                        [
                            10.036771179802383,
                            44.11806554996488
                        ],
                        [
                            10.047985949360186,
                            44.115087037157224
                        ],
                        [
                            10.050284520902974,
                            44.11018309897495
                        ],
                        [
                            10.057790394374326,
                            44.108555013437
                        ],
                        [
                            10.059548893110911,
                            44.10363940691603
                        ],
                        [
                            10.069896266200486,
                            44.09811207116648
                        ],
                        [
                            10.066993765993832,
                            44.094461504403554
                        ],
                        [
                            10.070039204826351,
                            44.09342010576141
                        ],
                        [
                            10.06587609749361,
                            44.08567421445905
                        ],
                        [
                            10.060985201768474,
                            44.08306912328452
                        ],
                        [
                            10.06258387558942,
                            44.0796146814183
                        ],
                        [
                            10.053856690727859,
                            44.076999942559695
                        ],
                        [
                            10.047447416440006,
                            44.07055029563673
                        ],
                        [
                            10.044567888465387,
                            44.07088546767234
                        ],
                        [
                            10.034780386473841,
                            44.063687496098154
                        ],
                        [
                            10.032034148259754,
                            44.06486232787153
                        ],
                        [
                            10.018783175309409,
                            44.04530253401197
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                name: 'lazio',
                image: 'https://www.italia.it/content/dam/tdh/en/interests/lazio/roma/roma-la-citta-eterna/media/20210410125258-enit-lazio-roma-tevere.jpg'

            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            11.45619338944843,
                            42.377426392992476
                        ],
                        [
                            11.604865942320814,
                            42.315419270563865
                        ],
                        [
                            11.694699032085275,
                            42.23997293241334
                        ],
                        [
                            11.746395432799911,
                            42.173428800087265
                        ],
                        [
                            11.740508459768002,
                            42.15416086159871
                        ],
                        [
                            11.753992503396763,
                            42.13489292311015
                        ],
                        [
                            11.774256402856821,
                            42.11490539505972
                        ],
                        [
                            11.794520302316881,
                            42.10353950217596
                        ],
                        [
                            11.826331143738976,
                            42.04701975375901
                        ],
                        [
                            11.842645171833851,
                            42.03365020426466
                        ],
                        [
                            11.890183607838411,
                            42.04033673728162
                        ],
                        [
                            11.910492906502647,
                            42.04381070144804
                        ],
                        [
                            11.946733921466688,
                            42.026999988899114
                        ],
                        [
                            11.968181199866981,
                            42.01525963621387
                        ],
                        [
                            11.9782486809092,
                            41.998446293449135
                        ],
                        [
                            11.998359496618095,
                            41.99423928814134
                        ],
                        [
                            12.019442886901338,
                            41.986634277574495
                        ],
                        [
                            12.038905319561549,
                            41.972866260312856
                        ],
                        [
                            12.052657997149854,
                            41.95655584961664
                        ],
                        [
                            12.08595202438527,
                            41.94246171420993
                        ],
                        [
                            12.096468166026863,
                            41.93475987634734
                        ],
                        [
                            12.126982734612824,
                            41.927058038484766
                        ],
                        [
                            12.156681002906236,
                            41.902230696196256
                        ],
                        [
                            12.202745254862549,
                            41.83470855684884
                        ],
                        [
                            12.223444231030564,
                            41.786034076946756
                        ],
                        [
                            12.222127970063838,
                            41.7689055715454
                        ],
                        [
                            12.231477536801027,
                            41.74183299740824
                        ],
                        [
                            12.328837149586887,
                            41.710079568518175
                        ],
                        [
                            12.440195661233595,
                            41.643983419288205
                        ],
                        [
                            12.561151628263957,
                            41.529144763792985
                        ],
                        [
                            12.622093951800077,
                            41.448446354368585
                        ],
                        [
                            12.673269820572806,
                            41.458308829572005
                        ],
                        [
                            12.712060756529302,
                            41.444345687800634
                        ],
                        [
                            12.760453875442682,
                            41.41238679400966
                        ],
                        [
                            12.780749548072325,
                            41.421611059299074
                        ],
                        [
                            12.81184767653163,
                            41.417333879407636
                        ],
                        [
                            12.85734907942873,
                            41.41395609734567
                        ],
                        [
                            12.9220548482446,
                            41.38357065378379
                        ],
                        [
                            13.002855334645615,
                            41.31420541787148
                        ],
                        [
                            13.026652733990087,
                            41.27068501572428
                        ],
                        [
                            13.038324591340515,
                            41.24670716590283
                        ],
                        [
                            13.03508682716798,
                            41.24154082158044
                        ],
                        [
                            13.040273214717518,
                            41.23656961521273
                        ],
                        [
                            13.043191179055167,
                            41.22989253720721
                        ],
                        [
                            13.066776205698286,
                            41.22295275135406
                        ],
                        [
                            13.090373360855637,
                            41.22568462477875
                        ],
                        [
                            13.112652621645525,
                            41.250695870181886
                        ],
                        [
                            13.19465741964024,
                            41.28778924998884
                        ],
                        [
                            13.25480509431349,
                            41.28367895356081
                        ],
                        [
                            13.274925083862218,
                            41.299573509363526
                        ],
                        [
                            13.331874310223425,
                            41.296771381188165
                        ],
                        [
                            13.395505831622106,
                            41.27582713767751
                        ],
                        [
                            13.436298331438977,
                            41.25899384079416
                        ],
                        [
                            13.466141598690966,
                            41.24141224360005
                        ],
                        [
                            13.50842717567529,
                            41.226450556836184
                        ],
                        [
                            13.524593040274484,
                            41.22495945895319
                        ],
                        [
                            13.548104436644362,
                            41.2103620782224
                        ],
                        [
                            13.568320905447672,
                            41.20942816634138
                        ],
                        [
                            13.588537374250983,
                            41.20849425446036
                        ],
                        [
                            13.572062050739053,
                            41.21549896913698
                        ],
                        [
                            13.567998454981712,
                            41.23344644753165
                        ],
                        [
                            13.583497734300291,
                            41.248707051908056
                        ],
                        [
                            13.609613264356103,
                            41.25571714680139
                        ],
                        [
                            13.619255728006577,
                            41.26125406877234
                        ],
                        [
                            13.640438079932416,
                            41.26203382869562
                        ],
                        [
                            13.665715230923709,
                            41.25413809078459
                        ],
                        [
                            13.668116808754892,
                            41.24865086273118
                        ],
                        [
                            13.678335730257169,
                            41.24680207235125
                        ],
                        [
                            13.684332297375654,
                            41.243420943908106
                        ],
                        [
                            13.698518462625163,
                            41.248996495406786
                        ],
                        [
                            13.704183271036396,
                            41.25482933275667
                        ],
                        [
                            13.729816260945562,
                            41.24465964965081
                        ],
                        [
                            13.762522085603115,
                            41.22301344618125
                        ],
                        [
                            13.771031370330736,
                            41.237476690992736
                        ],
                        [
                            13.770739037520862,
                            41.24165261809487
                        ],
                        [
                            13.776775030536834,
                            41.24442896954308
                        ],
                        [
                            13.786307480103648,
                            41.24133876543394
                        ],
                        [
                            13.789770814274647,
                            41.245251788433166
                        ],
                        [
                            13.801925606252984,
                            41.245528933833825
                        ],
                        [
                            13.801796001036376,
                            41.24888491488493
                        ],
                        [
                            13.807120607509425,
                            41.24510093193486
                        ],
                        [
                            13.814306486284528,
                            41.2494337924157
                        ],
                        [
                            13.816596535061247,
                            41.24334337804969
                        ],
                        [
                            13.824842655203998,
                            41.25236706974801
                        ],
                        [
                            13.829244906120962,
                            41.249811971965514
                        ],
                        [
                            13.834763920420452,
                            41.253134185552284
                        ],
                        [
                            13.830539516139964,
                            41.26173715420657
                        ],
                        [
                            13.838239181566848,
                            41.259483182248495
                        ],
                        [
                            13.840680181054509,
                            41.26304620702206
                        ],
                        [
                            13.833863133623966,
                            41.26630665554485
                        ],
                        [
                            13.82747446678437,
                            41.26526323743419
                        ],
                        [
                            13.825916085294725,
                            41.26664031998288
                        ],
                        [
                            13.83210628654999,
                            41.272933543911556
                        ],
                        [
                            13.82594334026027,
                            41.27668525615678
                        ],
                        [
                            13.828233393330356,
                            41.28194950617599
                        ],
                        [
                            13.832447663681002,
                            41.28310720260694
                        ],
                        [
                            13.83505183891507,
                            41.28063506495048
                        ],
                        [
                            13.841278728160752,
                            41.28239772671837
                        ],
                        [
                            13.844285427173055,
                            41.28779013844934
                        ],
                        [
                            13.84894075979382,
                            41.286528240156585
                        ],
                        [
                            13.854065069378457,
                            41.29196172974
                        ],
                        [
                            13.861182531063292,
                            41.28673559378541
                        ],
                        [
                            13.870175900607428,
                            41.29419526748764
                        ],
                        [
                            13.87561750589051,
                            41.290702754601334
                        ],
                        [
                            13.883872972960525,
                            41.29179108008029
                        ],
                        [
                            13.89054564277459,
                            41.29856095784465
                        ],
                        [
                            13.889011215707889,
                            41.30304062828655
                        ],
                        [
                            13.894149458455253,
                            41.30935771870622
                        ],
                        [
                            13.893402672792586,
                            41.31440653944615
                        ],
                        [
                            13.88592653554285,
                            41.32162153783297
                        ],
                        [
                            13.875300272778299,
                            41.3328023656143
                        ],
                        [
                            13.872951615732902,
                            41.33683088579866
                        ],
                        [
                            13.87328442942576,
                            41.33839262062469
                        ],
                        [
                            13.877746191583135,
                            41.3390038044446
                        ],
                        [
                            13.87696278823204,
                            41.344853944080356
                        ],
                        [
                            13.872411529346696,
                            41.34832793332273
                        ],
                        [
                            13.874590592250795,
                            41.35037082902153
                        ],
                        [
                            13.872590482163798,
                            41.35224082341542
                        ],
                        [
                            13.87209751429102,
                            41.35478963150915
                        ],
                        [
                            13.870508721659478,
                            41.35762456249711
                        ],
                        [
                            13.873841700483808,
                            41.3582484404467
                        ],
                        [
                            13.877505153328407,
                            41.35994614958858
                        ],
                        [
                            13.877325393526789,
                            41.36124792966363
                        ],
                        [
                            13.86846778636443,
                            41.36238263769032
                        ],
                        [
                            13.86916728381724,
                            41.36397563560644
                        ],
                        [
                            13.871754419000244,
                            41.36617580005889
                        ],
                        [
                            13.871105603788465,
                            41.37181638776612
                        ],
                        [
                            13.874501726569232,
                            41.378064069303576
                        ],
                        [
                            13.881984613291879,
                            41.382665328486375
                        ],
                        [
                            13.885943418596373,
                            41.38400803171908
                        ],
                        [
                            13.886164435038491,
                            41.38511915628297
                        ],
                        [
                            13.884480533087842,
                            41.386560103657075
                        ],
                        [
                            13.880675568965053,
                            41.3883660328183
                        ],
                        [
                            13.879073460270474,
                            41.39175805053459
                        ],
                        [
                            13.87468724007751,
                            41.395919572830856
                        ],
                        [
                            13.877610051906316,
                            41.39779637195059
                        ],
                        [
                            13.882877378681979,
                            41.39747476222742
                        ],
                        [
                            13.881542925317605,
                            41.399799390489505
                        ],
                        [
                            13.882976919744678,
                            41.401370946203414
                        ],
                        [
                            13.884379586757584,
                            41.401216587972456
                        ],
                        [
                            13.88777104951896,
                            41.39936900093599
                        ],
                        [
                            13.888839219860968,
                            41.40105974955644
                        ],
                        [
                            13.880964752229104,
                            41.40490780853397
                        ],
                        [
                            13.878837904648778,
                            41.40094847243604
                        ],
                        [
                            13.863291390978755,
                            41.40847219703674
                        ],
                        [
                            13.86133487502152,
                            41.41817933206026
                        ],
                        [
                            13.87295492903059,
                            41.42252626015789
                        ],
                        [
                            13.875360006850766,
                            41.42674328077152
                        ],
                        [
                            13.880307989864683,
                            41.425534301414416
                        ],
                        [
                            13.887038167853865,
                            41.42735512736269
                        ],
                        [
                            13.888360287337601,
                            41.42545744519795
                        ],
                        [
                            13.891404811511215,
                            41.42706113320475
                        ],
                        [
                            13.891907563808768,
                            41.424982160607684
                        ],
                        [
                            13.90092268907094,
                            41.42622217931218
                        ],
                        [
                            13.903290173338245,
                            41.423085444140526
                        ],
                        [
                            13.903598707932959,
                            41.424726647620616
                        ],
                        [
                            13.906939736469393,
                            41.42851951252412
                        ],
                        [
                            13.911238329113216,
                            41.42921199486713
                        ],
                        [
                            13.912004131272514,
                            41.43391580378601
                        ],
                        [
                            13.916940684776833,
                            41.43413305946093
                        ],
                        [
                            13.92204686292488,
                            41.43763105632145
                        ],
                        [
                            13.928173280198983,
                            41.437990073845484
                        ],
                        [
                            13.944684792489518,
                            41.45098430962221
                        ],
                        [
                            13.957877724954528,
                            41.45837456813669
                        ],
                        [
                            13.961196304780051,
                            41.46397854539893
                        ],
                        [
                            13.97762852457543,
                            41.46302584991086
                        ],
                        [
                            13.98115553717826,
                            41.47038388331748
                        ],
                        [
                            13.993066218587042,
                            41.48221572468169
                        ],
                        [
                            13.972906567207591,
                            41.4929077407226
                        ],
                        [
                            13.97828111483806,
                            41.50098063057016
                        ],
                        [
                            13.995021062186915,
                            41.51316274461456
                        ],
                        [
                            14.011956964703012,
                            41.51522004490516
                        ],
                        [
                            14.027717136214175,
                            41.524906980130275
                        ],
                        [
                            14.01450139462274,
                            41.52979792419312
                        ],
                        [
                            14.006380487388885,
                            41.538796234465735
                        ],
                        [
                            14.00855277589013,
                            41.53855679012067
                        ],
                        [
                            14.013860347073399,
                            41.542717505749735
                        ],
                        [
                            14.020948296421892,
                            41.54282541749186
                        ],
                        [
                            14.02331654009112,
                            41.54705919087186
                        ],
                        [
                            14.022941411414456,
                            41.55099965252023
                        ],
                        [
                            14.026387408505919,
                            41.55501345154115
                        ],
                        [
                            14.02493452640702,
                            41.559173887548596
                        ],
                        [
                            14.018803214683011,
                            41.56327894443159
                        ],
                        [
                            14.013651678796721,
                            41.56459820512358
                        ],
                        [
                            14.002956696689715,
                            41.56694355210371
                        ],
                        [
                            14.003823069468254,
                            41.57207457990657
                        ],
                        [
                            13.996851235544971,
                            41.57779200211363
                        ],
                        [
                            13.997315697245647,
                            41.63129889797155
                        ],
                        [
                            13.986986219058114,
                            41.64620217572148
                        ],
                        [
                            13.983124581387827,
                            41.66179590533898
                        ],
                        [
                            13.938442217378205,
                            41.68815108943624
                        ],
                        [
                            13.925984841327441,
                            41.703170583090156
                        ],
                        [
                            13.918609339969187,
                            41.71922484572049
                        ],
                        [
                            13.903858337252792,
                            41.71822472146383
                        ],
                        [
                            13.894683830586505,
                            41.73484862985852
                        ],
                        [
                            13.882275403661652,
                            41.728025838498716
                        ],
                        [
                            13.856007318485492,
                            41.730167256371914
                        ],
                        [
                            13.851053219161725,
                            41.737828823749005
                        ],
                        [
                            13.822999689420271,
                            41.73928494970424
                        ],
                        [
                            13.813553479382083,
                            41.75357347643564
                        ],
                        [
                            13.797627150609378,
                            41.747877910872006
                        ],
                        [
                            13.791864571221915,
                            41.74356106951535
                        ],
                        [
                            13.772242333583062,
                            41.74992954710756
                        ],
                        [
                            13.767545730763839,
                            41.74874389144557
                        ],
                        [
                            13.76212350372323,
                            41.74900188380123
                        ],
                        [
                            13.761800939565475,
                            41.75586913823791
                        ],
                        [
                            13.750110262616644,
                            41.760120088296254
                        ],
                        [
                            13.748255760598102,
                            41.773854545298235
                        ],
                        [
                            13.74296744803501,
                            41.78038738662703
                        ],
                        [
                            13.732828255083088,
                            41.78606019117339
                        ],
                        [
                            13.72897190826714,
                            41.791101429921866
                        ],
                        [
                            13.729311587413093,
                            41.79927112151906
                        ],
                        [
                            13.717293195596913,
                            41.79689174939023
                        ],
                        [
                            13.707034985460474,
                            41.78707609380625
                        ],
                        [
                            13.69883983693181,
                            41.789754571888025
                        ],
                        [
                            13.688004415887086,
                            41.79265123727741
                        ],
                        [
                            13.685969903231692,
                            41.79948490638829
                        ],
                        [
                            13.677245125385266,
                            41.80713421889784
                        ],
                        [
                            13.649234479625555,
                            41.812810590437394
                        ],
                        [
                            13.636296526701692,
                            41.803618695114174
                        ],
                        [
                            13.641855458372625,
                            41.79180462246947
                        ],
                        [
                            13.631376726263657,
                            41.7813641200753
                        ],
                        [
                            13.614443994667567,
                            41.77748634692743
                        ],
                        [
                            13.603460893291235,
                            41.76929360924084
                        ],
                        [
                            13.585015053895896,
                            41.76516083945545
                        ],
                        [
                            13.573939373048301,
                            41.754271072353966
                        ],
                        [
                            13.559453942428885,
                            41.76315840500132
                        ],
                        [
                            13.54320833012967,
                            41.762854782377076
                        ],
                        [
                            13.518931286698047,
                            41.77275141884927
                        ],
                        [
                            13.522153468619212,
                            41.78038837739581
                        ],
                        [
                            13.511582742907734,
                            41.78822211518811
                        ],
                        [
                            13.510693016424295,
                            41.798460468264224
                        ],
                        [
                            13.50334929045635,
                            41.80213750264035
                        ],
                        [
                            13.482775294971514,
                            41.80328503817634
                        ],
                        [
                            13.47976457369397,
                            41.80689255659158
                        ],
                        [
                            13.466115677201875,
                            41.80929657321255
                        ],
                        [
                            13.456572971130417,
                            41.82001530733351
                        ],
                        [
                            13.413809955020202,
                            41.83719239284379
                        ],
                        [
                            13.397844801861151,
                            41.83420939814644
                        ],
                        [
                            13.390545816540712,
                            41.81831160050714
                        ],
                        [
                            13.376670026551512,
                            41.81719108790094
                        ],
                        [
                            13.368814715936896,
                            41.84956458573128
                        ],
                        [
                            13.36633142193607,
                            41.86521192513966
                        ],
                        [
                            13.357348502057807,
                            41.869564440686005
                        ],
                        [
                            13.376350082482833,
                            41.885614042820265
                        ],
                        [
                            13.385241133765948,
                            41.904889590588155
                        ],
                        [
                            13.365469842377099,
                            41.92408894871378
                        ],
                        [
                            13.35185762475707,
                            41.91982554888391
                        ],
                        [
                            13.335302077130983,
                            41.926559402607005
                        ],
                        [
                            13.324857761359779,
                            41.943357440448054
                        ],
                        [
                            13.304108719433877,
                            41.955783910983605
                        ],
                        [
                            13.287692761427255,
                            41.948874337193445
                        ],
                        [
                            13.265775466297551,
                            41.96690625337235
                        ],
                        [
                            13.253733547753619,
                            41.966525859741694
                        ],
                        [
                            13.237602059339682,
                            41.973852658310825
                        ],
                        [
                            13.23013673876129,
                            41.98225326972097
                        ],
                        [
                            13.21515622576278,
                            41.986943543094974
                        ],
                        [
                            13.194398267541633,
                            41.98626578553498
                        ],
                        [
                            13.1844730191126,
                            41.992029671885724
                        ],
                        [
                            13.161548518931816,
                            42.00101409164641
                        ],
                        [
                            13.143679283321207,
                            42.0137534013871
                        ],
                        [
                            13.12725440901708,
                            42.01790715683319
                        ],
                        [
                            13.100904286286266,
                            42.02782497405857
                        ],
                        [
                            13.094233586349777,
                            42.020705427736004
                        ],
                        [
                            13.100562138165039,
                            42.007147557647926
                        ],
                        [
                            13.068444041311537,
                            42.01115298515671
                        ],
                        [
                            13.054328675552165,
                            42.01580891402102
                        ],
                        [
                            13.049067084566413,
                            42.02312237139884
                        ],
                        [
                            13.046945809737476,
                            42.034889407480186
                        ],
                        [
                            13.026553604538718,
                            42.04983621031427
                        ],
                        [
                            13.028714579014974,
                            42.06160311111681
                        ],
                        [
                            13.023238875562527,
                            42.06335687219758
                        ],
                        [
                            13.018502640826895,
                            42.0748300495019
                        ],
                        [
                            13.021188971555747,
                            42.087574663172944
                        ],
                        [
                            13.023304335710268,
                            42.09682138576011
                        ],
                        [
                            13.032842265326881,
                            42.10733910532579
                        ],
                        [
                            13.029650428171554,
                            42.120749464765616
                        ],
                        [
                            13.034417445936413,
                            42.12103367847763
                        ],
                        [
                            13.04273760444593,
                            42.115703023004514
                        ],
                        [
                            13.047399757936514,
                            42.11782177133845
                        ],
                        [
                            13.048045317542883,
                            42.12223233720326
                        ],
                        [
                            13.055670296340473,
                            42.123605250780386
                        ],
                        [
                            13.059510407825448,
                            42.12268639627351
                        ],
                        [
                            13.068139535094037,
                            42.12944439810631
                        ],
                        [
                            13.07344724819167,
                            42.131905791291885
                        ],
                        [
                            13.076746664345889,
                            42.13929346275529
                        ],
                        [
                            13.083097147007951,
                            42.14075252563364
                        ],
                        [
                            13.086048973307747,
                            42.14427347456608
                        ],
                        [
                            13.087455955805082,
                            42.147794452822865
                        ],
                        [
                            13.085927735079768,
                            42.15555325210371
                        ],
                        [
                            13.090929282542666,
                            42.155700317971665
                        ],
                        [
                            13.092694853905193,
                            42.15744664865986
                        ],
                        [
                            13.094460425267695,
                            42.15999254945244
                        ],
                        [
                            13.092990020529767,
                            42.16253845024502
                        ],
                        [
                            13.092515568380264,
                            42.168979313089594
                        ],
                        [
                            13.08663416292531,
                            42.17301566819129
                        ],
                        [
                            13.087075618047827,
                            42.17776814034117
                        ],
                        [
                            13.092705537303988,
                            42.17707359200605
                        ],
                        [
                            13.09829440785288,
                            42.17825353816248
                        ],
                        [
                            13.102131349727687,
                            42.176663779464725
                        ],
                        [
                            13.107088605141769,
                            42.1768898996862
                        ],
                        [
                            13.113914584476746,
                            42.175211812148135
                        ],
                        [
                            13.122860906619035,
                            42.17763670038099
                        ],
                        [
                            13.127057272480876,
                            42.17443767757487
                        ],
                        [
                            13.129735300158218,
                            42.174095032227314
                        ],
                        [
                            13.13450737928698,
                            42.169687777673886
                        ],
                        [
                            13.14073939897885,
                            42.1683535918435
                        ],
                        [
                            13.146854623425785,
                            42.16312387314193
                        ],
                        [
                            13.15346622766321,
                            42.16107568540902
                        ],
                        [
                            13.156807565040125,
                            42.15816173351192
                        ],
                        [
                            13.160270792398121,
                            42.15758475207494
                        ],
                        [
                            13.162332476814566,
                            42.15614195909114
                        ],
                        [
                            13.167857388589006,
                            42.154122184670356
                        ],
                        [
                            13.170381159251319,
                            42.15103507315953
                        ],
                        [
                            13.177245516170846,
                            42.152189686549846
                        ],
                        [
                            13.180966374298926,
                            42.154923008929586
                        ],
                        [
                            13.18391688762111,
                            42.15893927911242
                        ],
                        [
                            13.194574043138342,
                            42.15859570174147
                        ],
                        [
                            13.198741394702287,
                            42.15344091152603
                        ],
                        [
                            13.206964873736908,
                            42.14798541904615
                        ],
                        [
                            13.216212205544895,
                            42.14459153925995
                        ],
                        [
                            13.222762531997695,
                            42.1434209123951
                        ],
                        [
                            13.225459537352885,
                            42.141197659473754
                        ],
                        [
                            13.23278020861298,
                            42.13622479631566
                        ],
                        [
                            13.237870009762734,
                            42.12959761792541
                        ],
                        [
                            13.249046770577163,
                            42.12716057994665
                        ],
                        [
                            13.257715473162598,
                            42.13154937497011
                        ],
                        [
                            13.259887496243596,
                            42.13427677842006
                        ],
                        [
                            13.26149149070831,
                            42.13414962653806
                        ],
                        [
                            13.26286168835513,
                            42.13523611986917
                        ],
                        [
                            13.264543615094238,
                            42.13580247997298
                        ],
                        [
                            13.265056557735914,
                            42.13688896068756
                        ],
                        [
                            13.26870627437331,
                            42.137383093129515
                        ],
                        [
                            13.27131045542415,
                            42.137399000171854
                        ],
                        [
                            13.272355991010706,
                            42.137877225571465
                        ],
                        [
                            13.274141883193543,
                            42.137141867254414
                        ],
                        [
                            13.275484577380968,
                            42.13794044508033
                        ],
                        [
                            13.275424490650337,
                            42.13908575851027
                        ],
                        [
                            13.274291197637702,
                            42.139469350584676
                        ],
                        [
                            13.276226404264696,
                            42.141061475597894
                        ],
                        [
                            13.28020139924568,
                            42.132108552316424
                        ],
                        [
                            13.290724570858728,
                            42.13638376166091
                        ],
                        [
                            13.303867942286153,
                            42.1363943231533
                        ],
                        [
                            13.308656743529184,
                            42.145278394250596
                        ],
                        [
                            13.318638926237448,
                            42.15432988399915
                        ],
                        [
                            13.323707547682993,
                            42.15233454412471
                        ],
                        [
                            13.329001968322169,
                            42.154858914651214
                        ],
                        [
                            13.322655870036396,
                            42.16107922549422
                        ],
                        [
                            13.334035008496084,
                            42.16755049964914
                        ],
                        [
                            13.344331166520544,
                            42.164398609114386
                        ],
                        [
                            13.353949926961786,
                            42.166100276868086
                        ],
                        [
                            13.342591657027425,
                            42.17695105220302
                        ],
                        [
                            13.354255204849728,
                            42.174516228706715
                        ],
                        [
                            13.361545307460062,
                            42.17873420749169
                        ],
                        [
                            13.380498957892701,
                            42.18051736278035
                        ],
                        [
                            13.372769728919506,
                            42.18827389442631
                        ],
                        [
                            13.362805309267987,
                            42.189679116152035
                        ],
                        [
                            13.35897400607787,
                            42.192769318184716
                        ],
                        [
                            13.352840889616466,
                            42.191084337877754
                        ],
                        [
                            13.340508034103793,
                            42.19875475437147
                        ],
                        [
                            13.331858079821497,
                            42.20813039296233
                        ],
                        [
                            13.341449995701533,
                            42.21782303401285
                        ],
                        [
                            13.33561976267432,
                            42.22223096216525
                        ],
                        [
                            13.32679717239724,
                            42.22322985336817
                        ],
                        [
                            13.320736758044365,
                            42.22900148923313
                        ],
                        [
                            13.293042380727183,
                            42.2321453199818
                        ],
                        [
                            13.244413829733055,
                            42.27097681954495
                        ],
                        [
                            13.234483381280484,
                            42.27514823421504
                        ],
                        [
                            13.228646421004605,
                            42.28907898493674
                        ],
                        [
                            13.225083620825544,
                            42.3191549982119
                        ],
                        [
                            13.176947282725484,
                            42.344184885164026
                        ],
                        [
                            13.152722418199534,
                            42.35577014318106
                        ],
                        [
                            13.165688702032387,
                            42.36799691626095
                        ],
                        [
                            13.18877031989032,
                            42.378980942200855
                        ],
                        [
                            13.192399238153671,
                            42.38756997277262
                        ],
                        [
                            13.187499137434855,
                            42.40115704662317
                        ],
                        [
                            13.158909521996058,
                            42.41079403428423
                        ],
                        [
                            13.144098902103579,
                            42.42581692255047
                        ],
                        [
                            13.128072488486232,
                            42.43934259103804
                        ],
                        [
                            13.116909249766989,
                            42.44449364924581
                        ],
                        [
                            13.138321922284089,
                            42.4625143073537
                        ],
                        [
                            13.154805251167975,
                            42.46321774457661
                        ],
                        [
                            13.1780610155458,
                            42.47979529591984
                        ],
                        [
                            13.169891919595397,
                            42.48143585714589
                        ],
                        [
                            13.179115349563656,
                            42.512717298894316
                        ],
                        [
                            13.16669054241434,
                            42.51207789288765
                        ],
                        [
                            13.155662395724445,
                            42.52585129786229
                        ],
                        [
                            13.157000165033079,
                            42.53874100020546
                        ],
                        [
                            13.16388881465667,
                            42.53861519629965
                        ],
                        [
                            13.17776076657469,
                            42.54775095502629
                        ],
                        [
                            13.172683149623136,
                            42.56756444124749
                        ],
                        [
                            13.192396255819428,
                            42.57786149964765
                        ],
                        [
                            13.191857785360522,
                            42.5876443289072
                        ],
                        [
                            13.21461207687212,
                            42.585481311072996
                        ],
                        [
                            13.229011150209217,
                            42.58144596191346
                        ],
                        [
                            13.232883371309901,
                            42.57501995124656
                        ],
                        [
                            13.245110810585143,
                            42.57260668501721
                        ],
                        [
                            13.252855252786569,
                            42.58222512049589
                        ],
                        [
                            13.283238221761653,
                            42.58460916892492
                        ],
                        [
                            13.291824969412756,
                            42.57040808130051
                        ],
                        [
                            13.334195860116772,
                            42.580015309653916
                        ],
                        [
                            13.338794621928338,
                            42.574391829829864
                        ],
                        [
                            13.355018035113229,
                            42.57625865527569
                        ],
                        [
                            13.380925995085365,
                            42.58266673931567
                        ],
                        [
                            13.380315219111829,
                            42.58452746792077
                        ],
                        [
                            13.394961798065378,
                            42.59013271458757
                        ],
                        [
                            13.388266243639737,
                            42.597206244242756
                        ],
                        [
                            13.389562637033775,
                            42.60909324540826
                        ],
                        [
                            13.39651466808509,
                            42.61682364405986
                        ],
                        [
                            13.392255212416632,
                            42.62105657456213
                        ],
                        [
                            13.406885815230247,
                            42.629033799423425
                        ],
                        [
                            13.409165225957986,
                            42.64182197561882
                        ],
                        [
                            13.398044556828197,
                            42.644874557673575
                        ],
                        [
                            13.386923887698405,
                            42.64792713972834
                        ],
                        [
                            13.380162462833306,
                            42.65097949682757
                        ],
                        [
                            13.371947956546123,
                            42.64655045199325
                        ],
                        [
                            13.356608755039016,
                            42.66414335730066
                        ],
                        [
                            13.347081879217122,
                            42.66784449841106
                        ],
                        [
                            13.35563667458618,
                            42.69447535242802
                        ],
                        [
                            13.338036004364419,
                            42.70508509885623
                        ],
                        [
                            13.32921146957993,
                            42.71146809206357
                        ],
                        [
                            13.317832369138703,
                            42.72348212150546
                        ],
                        [
                            13.305905861768647,
                            42.72892699098283
                        ],
                        [
                            13.291424788741908,
                            42.73142299645289
                        ],
                        [
                            13.28714937308106,
                            42.73967175772609
                        ],
                        [
                            13.265356935764409,
                            42.73987943391107
                        ],
                        [
                            13.25571129059125,
                            42.72126219970852
                        ],
                        [
                            13.247643341054482,
                            42.72532072506621
                        ],
                        [
                            13.222423307812303,
                            42.727502594642665
                        ],
                        [
                            13.199575371253111,
                            42.734911748832516
                        ],
                        [
                            13.189135325033353,
                            42.73374298957681
                        ],
                        [
                            13.18800119656807,
                            42.72037386701529
                        ],
                        [
                            13.192706075323471,
                            42.71129446319233
                        ],
                        [
                            13.173872456227015,
                            42.68625750724763
                        ],
                        [
                            13.181972668334677,
                            42.683932499257224
                        ],
                        [
                            13.182774121419538,
                            42.67812007516313
                        ],
                        [
                            13.173896664012844,
                            42.67478919334516
                        ],
                        [
                            13.17487253128873,
                            42.66528700208339
                        ],
                        [
                            13.146534415891686,
                            42.64601336348446
                        ],
                        [
                            13.137538011909324,
                            42.65465242185509
                        ],
                        [
                            13.132492402989286,
                            42.65561673301131
                        ],
                        [
                            13.129969598529236,
                            42.65958785489727
                        ],
                        [
                            13.1170301818625,
                            42.65727920422421
                        ],
                        [
                            13.118954046404994,
                            42.649951631585765
                        ],
                        [
                            13.109490453371176,
                            42.645099706934595
                        ],
                        [
                            13.10988018501994,
                            42.64212683758397
                        ],
                        [
                            13.099035677820984,
                            42.63647855925397
                        ],
                        [
                            13.07943265979404,
                            42.63136722857569
                        ],
                        [
                            13.071246349590156,
                            42.62570896886376
                        ],
                        [
                            13.061553489206855,
                            42.62237319935885
                        ],
                        [
                            13.041932455452335,
                            42.627726372240765
                        ],
                        [
                            13.033814861899629,
                            42.62870424830386
                        ],
                        [
                            13.030217771485095,
                            42.63791167885454
                        ],
                        [
                            13.023956064784949,
                            42.63900489445585
                        ],
                        [
                            13.017439364784487,
                            42.6332110478287
                        ],
                        [
                            13.012795895700108,
                            42.62521853495859
                        ],
                        [
                            13.015091224772267,
                            42.620316017476064
                        ],
                        [
                            13.01100621721199,
                            42.61650542023567
                        ],
                        [
                            12.99957568408422,
                            42.61471316455576
                        ],
                        [
                            12.992783102677873,
                            42.618234078443805
                        ],
                        [
                            12.991541441660987,
                            42.615464393043936
                        ],
                        [
                            12.973837475781831,
                            42.616910905272704
                        ],
                        [
                            12.954748390819837,
                            42.61892399973152
                        ],
                        [
                            12.93675176117621,
                            42.615491441852576
                        ],
                        [
                            12.931447097246384,
                            42.60358080773162
                        ],
                        [
                            12.91848332321151,
                            42.60340232027111
                        ],
                        [
                            12.916002891325132,
                            42.609429933899364
                        ],
                        [
                            12.903672723729333,
                            42.61205918532319
                        ],
                        [
                            12.897059891775433,
                            42.61679894003712
                        ],
                        [
                            12.88044342199067,
                            42.60919337288888
                        ],
                        [
                            12.879448017432903,
                            42.599037988902865
                        ],
                        [
                            12.89464833143997,
                            42.578093668595415
                        ],
                        [
                            12.89353502067969,
                            42.563268781380266
                        ],
                        [
                            12.881247048033195,
                            42.56258592085727
                        ],
                        [
                            12.865573228737762,
                            42.558048925297186
                        ],
                        [
                            12.846051856430035,
                            42.55181126403213
                        ],
                        [
                            12.8422285004089,
                            42.54580035325186
                        ],
                        [
                            12.839008654373314,
                            42.544948817058994
                        ],
                        [
                            12.821937617498353,
                            42.53615988265134
                        ],
                        [
                            12.817652555117332,
                            42.5333234707978
                        ],
                        [
                            12.808288722758839,
                            42.53581711614756
                        ],
                        [
                            12.785689308041729,
                            42.533094027532925
                        ],
                        [
                            12.772097976812992,
                            42.531184388927485
                        ],
                        [
                            12.774688135465944,
                            42.52725666331185
                        ],
                        [
                            12.769436495176857,
                            42.52452137091777
                        ],
                        [
                            12.773893748815748,
                            42.517932695068595
                        ],
                        [
                            12.773223835163463,
                            42.51310383743914
                        ],
                        [
                            12.752724252484718,
                            42.51307481979896
                        ],
                        [
                            12.74844916510241,
                            42.50755496801177
                        ],
                        [
                            12.726146995558572,
                            42.510116816487866
                        ],
                        [
                            12.724206912719279,
                            42.504607452063766
                        ],
                        [
                            12.712806881949648,
                            42.50093339107566
                        ],
                        [
                            12.72142489457304,
                            42.484297839786976
                        ],
                        [
                            12.740670660775638,
                            42.47083624295753
                        ],
                        [
                            12.722909484076625,
                            42.46006304025585
                        ],
                        [
                            12.712784165736387,
                            42.46293885384013
                        ],
                        [
                            12.704983100587054,
                            42.46088777594612
                        ],
                        [
                            12.691373674083186,
                            42.44902496409922
                        ],
                        [
                            12.67029615158662,
                            42.44054645113268
                        ],
                        [
                            12.650214413082946,
                            42.44309039382364
                        ],
                        [
                            12.650795192427408,
                            42.446183710410395
                        ],
                        [
                            12.64262141816225,
                            42.45397646571875
                        ],
                        [
                            12.643015558997085,
                            42.46522073134264
                        ],
                        [
                            12.635993195466877,
                            42.468456843415424
                        ],
                        [
                            12.622026281779311,
                            42.468973565482514
                        ],
                        [
                            12.61354914894602,
                            42.46408999974169
                        ],
                        [
                            12.618490900744312,
                            42.46153613437477
                        ],
                        [
                            12.620300358589432,
                            42.45133861168138
                        ],
                        [
                            12.616209012241711,
                            42.445520531873605
                        ],
                        [
                            12.611876720204762,
                            42.43579123336585
                        ],
                        [
                            12.610676722120985,
                            42.42695106400552
                        ],
                        [
                            12.61425385098234,
                            42.42588364746929
                        ],
                        [
                            12.614457740202347,
                            42.41414445442626
                        ],
                        [
                            12.605227691094385,
                            42.40738588823707
                        ],
                        [
                            12.606284193662475,
                            42.402230440985015
                        ],
                        [
                            12.59914854281611,
                            42.40152393578302
                        ],
                        [
                            12.579242770468767,
                            42.38996367458414
                        ],
                        [
                            12.574244943889305,
                            42.387273803150414
                        ],
                        [
                            12.574547922461376,
                            42.38636356593457
                        ],
                        [
                            12.572262531340467,
                            42.38525495578316
                        ],
                        [
                            12.569137423232029,
                            42.382681861610536
                        ],
                        [
                            12.568669903543098,
                            42.384298547654616
                        ],
                        [
                            12.566289190031856,
                            42.383260627096746
                        ],
                        [
                            12.560563980256212,
                            42.38332044844928
                        ],
                        [
                            12.558992333939203,
                            42.380770604387195
                        ],
                        [
                            12.554885258550204,
                            42.381010284075806
                        ],
                        [
                            12.549080564658631,
                            42.382557323332634
                        ],
                        [
                            12.546868058733125,
                            42.380846100248036
                        ],
                        [
                            12.544176539264805,
                            42.37665820139435
                        ],
                        [
                            12.542228415309069,
                            42.378213106704095
                        ],
                        [
                            12.537629888778557,
                            42.37425021928296
                        ],
                        [
                            12.533210632596955,
                            42.369434335780284
                        ],
                        [
                            12.531118599516503,
                            42.36478698342741
                        ],
                        [
                            12.526083358924607,
                            42.36396012332512
                        ],
                        [
                            12.519120552822809,
                            42.366337749585114
                        ],
                        [
                            12.515314659545531,
                            42.37607730614506
                        ],
                        [
                            12.512809348683765,
                            42.379077991694
                        ],
                        [
                            12.512954440398762,
                            42.38296861541818
                        ],
                        [
                            12.513485569516874,
                            42.39413129798516
                        ],
                        [
                            12.517077757482788,
                            42.39554530154982
                        ],
                        [
                            12.518019542873986,
                            42.397849007427396
                        ],
                        [
                            12.511426019826274,
                            42.40297208169611
                        ],
                        [
                            12.491664945609925,
                            42.398944357244545
                        ],
                        [
                            12.480902652443088,
                            42.40488430955811
                        ],
                        [
                            12.469977613962225,
                            42.400675223281716
                        ],
                        [
                            12.46628271242891,
                            42.39489181847701
                        ],
                        [
                            12.46290919593238,
                            42.399788683360015
                        ],
                        [
                            12.455519392864272,
                            42.39747813799958
                        ],
                        [
                            12.445237124458567,
                            42.398252992213585
                        ],
                        [
                            12.447330232790158,
                            42.4007515982418
                        ],
                        [
                            12.458743507208162,
                            42.40348753192756
                        ],
                        [
                            12.457301380128447,
                            42.4081220776703
                        ],
                        [
                            12.462525939780722,
                            42.41269363496214
                        ],
                        [
                            12.459715873496908,
                            42.41702791127523
                        ],
                        [
                            12.467445536749452,
                            42.41966629557858
                        ],
                        [
                            12.467783344139699,
                            42.423016447997526
                        ],
                        [
                            12.462385487914919,
                            42.42953722010017
                        ],
                        [
                            12.44975646834632,
                            42.42336601832218
                        ],
                        [
                            12.437770218854247,
                            42.42620965054046
                        ],
                        [
                            12.429384894185514,
                            42.42276284115968
                        ],
                        [
                            12.413929098691497,
                            42.42216292831442
                        ],
                        [
                            12.41333736118192,
                            42.428148470564565
                        ],
                        [
                            12.418894011220406,
                            42.43183904445001
                        ],
                        [
                            12.419629885694453,
                            42.437190082117965
                        ],
                        [
                            12.415705677126688,
                            42.440524935477214
                        ],
                        [
                            12.411781468558921,
                            42.44385978883647
                        ],
                        [
                            12.415289288983743,
                            42.45036621857231
                        ],
                        [
                            12.41236940865691,
                            42.454026938875884
                        ],
                        [
                            12.416855091687744,
                            42.45763571120405
                        ],
                        [
                            12.429382254401629,
                            42.45730617262022
                        ],
                        [
                            12.427768475467392,
                            42.46456400023904
                        ],
                        [
                            12.412006901136849,
                            42.46639474454334
                        ],
                        [
                            12.411350423567793,
                            42.47154476044916
                        ],
                        [
                            12.413170972544808,
                            42.47917716405702
                        ],
                        [
                            12.427846923020935,
                            42.486098565883715
                        ],
                        [
                            12.420990075986019,
                            42.491005719464624
                        ],
                        [
                            12.420239544663048,
                            42.497572250737704
                        ],
                        [
                            12.413959119471293,
                            42.49342690620366
                        ],
                        [
                            12.410336829320013,
                            42.49526435729693
                        ],
                        [
                            12.409811224391992,
                            42.498789631440786
                        ],
                        [
                            12.402799336142213,
                            42.50007807157943
                        ],
                        [
                            12.39672231171671,
                            42.49716794270746
                        ],
                        [
                            12.39416587706064,
                            42.499622765704075
                        ],
                        [
                            12.386942036539052,
                            42.49646636131271
                        ],
                        [
                            12.390400587103741,
                            42.49322942074212
                        ],
                        [
                            12.38795185689817,
                            42.48900417109516
                        ],
                        [
                            12.383574816467089,
                            42.48762297167109
                        ],
                        [
                            12.378740623943436,
                            42.486914757648
                        ],
                        [
                            12.375359372569552,
                            42.48537556012893
                        ],
                        [
                            12.375757749626537,
                            42.481169376997535
                        ],
                        [
                            12.367118849623996,
                            42.47989597056524
                        ],
                        [
                            12.364084939772987,
                            42.47688894894697
                        ],
                        [
                            12.350766708722146,
                            42.47198559215872
                        ],
                        [
                            12.345898086686343,
                            42.48568919048279
                        ],
                        [
                            12.324420275876783,
                            42.49365314370465
                        ],
                        [
                            12.31779250678744,
                            42.48833598312817
                        ],
                        [
                            12.298064321019194,
                            42.48792143948848
                        ],
                        [
                            12.287893676200106,
                            42.4945640157501
                        ],
                        [
                            12.308206272198673,
                            42.496261302271165
                        ],
                        [
                            12.29306377217652,
                            42.50835846725152
                        ],
                        [
                            12.28960709711762,
                            42.51100974015786
                        ],
                        [
                            12.278922051399272,
                            42.505490540674074
                        ],
                        [
                            12.274119743326938,
                            42.51493573652342
                        ],
                        [
                            12.289781456618746,
                            42.51941765126439
                        ],
                        [
                            12.27172217006105,
                            42.524101598869805
                        ],
                        [
                            12.264122693179596,
                            42.53599982070318
                        ],
                        [
                            12.276807585424596,
                            42.54360423523298
                        ],
                        [
                            12.274293523903445,
                            42.55779491690028
                        ],
                        [
                            12.257524834123245,
                            42.5645851565936
                        ],
                        [
                            12.238663162989939,
                            42.573898475548035
                        ],
                        [
                            12.234693950100468,
                            42.58498794399336
                        ],
                        [
                            12.242898803588524,
                            42.60184571512697
                        ],
                        [
                            12.231034055884066,
                            42.61451970933146
                        ],
                        [
                            12.242656665881043,
                            42.63447442365529
                        ],
                        [
                            12.159361294239432,
                            42.67578884134784
                        ],
                        [
                            12.120619260917039,
                            42.650148640615754
                        ],
                        [
                            12.105122447588712,
                            42.664394502045184
                        ],
                        [
                            12.027638380945064,
                            42.64729907670022
                        ],
                        [
                            11.942405907636726,
                            42.69857125342426
                        ],
                        [
                            11.919160687643739,
                            42.712806017815694
                        ],
                        [
                            11.96565112762974,
                            42.7384203680451
                        ],
                        [
                            11.977273737626717,
                            42.764024141764764
                        ],
                        [
                            11.928846195974046,
                            42.77397830840769
                        ],
                        [
                            11.901726772648686,
                            42.8336697132776
                        ],
                        [
                            11.85717343432944,
                            42.84361268119301
                        ],
                        [
                            11.820368502673716,
                            42.8251458959227
                        ],
                        [
                            11.81455719767473,
                            42.79814605309434
                        ],
                        [
                            11.773878062687743,
                            42.81946269799303
                        ],
                        [
                            11.748695741028087,
                            42.78677417209249
                        ],
                        [
                            11.785500672683725,
                            42.76260198737455
                        ],
                        [
                            11.820368502673716,
                            42.748378647976125
                        ],
                        [
                            11.812620096009056,
                            42.711382688260244
                        ],
                        [
                            11.785500672683725,
                            42.711382688260244
                        ],
                        [
                            11.785500672683725,
                            42.68006118006576
                        ],
                        [
                            11.806808791011122,
                            42.65299807398881
                        ],
                        [
                            11.760318351025091,
                            42.64159955724821
                        ],
                        [
                            11.725450521035071,
                            42.60453995368894
                        ],
                        [
                            11.688645589379377,
                            42.5860018797714
                        ],
                        [
                            11.622784132732733,
                            42.56032467389977
                        ],
                        [
                            11.589853404408359,
                            42.57887038300373
                        ],
                        [
                            11.589853404408359,
                            42.547482106749754
                        ],
                        [
                            11.558859777751763,
                            42.51893360736105
                        ],
                        [
                            11.591790506075057,
                            42.500370085095994
                        ],
                        [
                            11.622784132732733,
                            42.48608670115797
                        ],
                        [
                            11.61697282773369,
                            42.43606917043448
                        ],
                        [
                            11.498809626102059,
                            42.443216978197256
                        ],
                        [
                            11.494935422770766,
                            42.41462085504824
                        ],
                        [
                            11.462004694446364,
                            42.40460913002585
                        ],
                        [
                            11.45619338944843,
                            42.377426392992476
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Marche",
                image: 'https://www.italiapozaszlakiem.com/wp-content/uploads/2023/02/Cupramontana-Marche.jpeg'
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            12.751669851599274,
                            43.96838031704655
                        ],
                        [
                            12.750581479269357,
                            43.96823747097835
                        ],
                        [
                            12.752079123333175,
                            43.96658404508514
                        ],
                        [
                            12.754695242533217,
                            43.96557188138598
                        ],
                        [
                            12.756005054463685,
                            43.96213581517122
                        ],
                        [
                            12.75423514316688,
                            43.96088165201718
                        ],
                        [
                            12.75240319365031,
                            43.9592329665648
                        ],
                        [
                            12.750205884714916,
                            43.957614007656616
                        ],
                        [
                            12.750219694223574,
                            43.95534864752858
                        ],
                        [
                            12.747531231956799,
                            43.9523710300858
                        ],
                        [
                            12.748561837043837,
                            43.95068862331894
                        ],
                        [
                            12.746323169295493,
                            43.9488063879634
                        ],
                        [
                            12.747111766494584,
                            43.946256785292405
                        ],
                        [
                            12.743513340629846,
                            43.94455184623487
                        ],
                        [
                            12.74291707279096,
                            43.94193604746471
                        ],
                        [
                            12.735098121392241,
                            43.93397995556779
                        ],
                        [
                            12.733126568454452,
                            43.9332079867375
                        ],
                        [
                            12.73350058495754,
                            43.92911346120809
                        ],
                        [
                            12.727676564991441,
                            43.92457479931187
                        ],
                        [
                            12.728453864776696,
                            43.91951389181045
                        ],
                        [
                            12.730216906176082,
                            43.91837057424749
                        ],
                        [
                            12.730282449055835,
                            43.91290067567101
                        ],
                        [
                            12.727254459200623,
                            43.91188136105711
                        ],
                        [
                            12.729651762013503,
                            43.90535421728316
                        ],
                        [
                            12.732929691933151,
                            43.901487515688046
                        ],
                        [
                            12.735004711615886,
                            43.89585214791728
                        ],
                        [
                            12.727821669395155,
                            43.890171487478
                        ],
                        [
                            12.726410265084468,
                            43.890885889726746
                        ],
                        [
                            12.726110448546308,
                            43.885443273679954
                        ],
                        [
                            12.722279396786746,
                            43.88048832841787
                        ],
                        [
                            12.727222123496574,
                            43.87661753602583
                        ],
                        [
                            12.733945136062204,
                            43.870533816402656
                        ],
                        [
                            12.7307103521415,
                            43.86819678738908
                        ],
                        [
                            12.72510938908519,
                            43.8619841588918
                        ],
                        [
                            12.719360165522318,
                            43.860719164774196
                        ],
                        [
                            12.70844328284474,
                            43.85879967883062
                        ],
                        [
                            12.700565658464626,
                            43.858708924853175
                        ],
                        [
                            12.699419634023144,
                            43.855533856118456
                        ],
                        [
                            12.69774796733472,
                            43.85313535138158
                        ],
                        [
                            12.682704475479937,
                            43.85336270057769
                        ],
                        [
                            12.681380923752954,
                            43.85200309406133
                        ],
                        [
                            12.682713132564203,
                            43.8492925083855
                        ],
                        [
                            12.680005708365286,
                            43.84677815758354
                        ],
                        [
                            12.680078121144163,
                            43.84370314016621
                        ],
                        [
                            12.678118180614092,
                            43.83959978008235
                        ],
                        [
                            12.680113917619678,
                            43.83463235114351
                        ],
                        [
                            12.68006053648412,
                            43.83016552908967
                        ],
                        [
                            12.682881732274865,
                            43.82986840662096
                        ],
                        [
                            12.683528016011905,
                            43.82868109067334
                        ],
                        [
                            12.680927423794373,
                            43.82802586591259
                        ],
                        [
                            12.675927881000376,
                            43.82623174258205
                        ],
                        [
                            12.674400970157564,
                            43.82475464087932
                        ],
                        [
                            12.671372980302351,
                            43.82373381773817
                        ],
                        [
                            12.667435026849205,
                            43.823988690235694
                        ],
                        [
                            12.65969178375559,
                            43.828291820954576
                        ],
                        [
                            12.655845205013463,
                            43.82500197391525
                        ],
                        [
                            12.64504874273652,
                            43.82696781397257
                        ],
                        [
                            12.636981568749121,
                            43.824489795774156
                        ],
                        [
                            12.630374114960432,
                            43.821291739108545
                        ],
                        [
                            12.623409889126805,
                            43.82112632663407
                        ],
                        [
                            12.623371965263516,
                            43.82206386979337
                        ],
                        [
                            12.619932035040932,
                            43.826082438813046
                        ],
                        [
                            12.614798041249145,
                            43.826968902141004
                        ],
                        [
                            12.611725187919234,
                            43.831557782958924
                        ],
                        [
                            12.611830302426768,
                            43.83281715073977
                        ],
                        [
                            12.610106902366368,
                            43.833625211525174
                        ],
                        [
                            12.610766925899895,
                            43.837241503210976
                        ],
                        [
                            12.613021120630748,
                            43.837457813827626
                        ],
                        [
                            12.614146465665272,
                            43.84050208349504
                        ],
                        [
                            12.618942649343381,
                            43.84217893516836
                        ],
                        [
                            12.619699200010473,
                            43.84405210674814
                        ],
                        [
                            12.614530717304007,
                            43.84707612136165
                        ],
                        [
                            12.605591503582986,
                            43.84493701422582
                        ],
                        [
                            12.603166651464932,
                            43.848937891323175
                        ],
                        [
                            12.600881390533885,
                            43.85055528270988
                        ],
                        [
                            12.601195016579737,
                            43.85373185501237
                        ],
                        [
                            12.603880043897874,
                            43.85551395524098
                        ],
                        [
                            12.60115358805561,
                            43.86053928735993
                        ],
                        [
                            12.603228538026173,
                            43.86197077308154
                        ],
                        [
                            12.602542612950998,
                            43.86349415929203
                        ],
                        [
                            12.59387394898917,
                            43.8636680720592
                        ],
                        [
                            12.592031624913488,
                            43.865485884975556
                        ],
                        [
                            12.589432750170744,
                            43.865431201114774
                        ],
                        [
                            12.59222804441896,
                            43.87027184897937
                        ],
                        [
                            12.592693296209632,
                            43.87676931169287
                        ],
                        [
                            12.591723011867657,
                            43.878248839669965
                        ],
                        [
                            12.589896214774484,
                            43.878397899602106
                        ],
                        [
                            12.591028429708217,
                            43.88383992148334
                        ],
                        [
                            12.589449785493429,
                            43.88556893199353
                        ],
                        [
                            12.583030163837975,
                            43.88415910682238
                        ],
                        [
                            12.579866005510183,
                            43.88521736952325
                        ],
                        [
                            12.576312373407944,
                            43.88497389914613
                        ],
                        [
                            12.57251402337701,
                            43.88435033948784
                        ],
                        [
                            12.564771111038226,
                            43.88120777164761
                        ],
                        [
                            12.562199925023151,
                            43.869547478926194
                        ],
                        [
                            12.554744545857233,
                            43.86802172076432
                        ],
                        [
                            12.550027644856243,
                            43.86567216057762
                        ],
                        [
                            12.54629133304104,
                            43.865443519548876
                        ],
                        [
                            12.54202619633341,
                            43.86348201626353
                        ],
                        [
                            12.529602748662057,
                            43.87196774264939
                        ],
                        [
                            12.529528999046022,
                            43.87509804333186
                        ],
                        [
                            12.522494140150911,
                            43.87349871475121
                        ],
                        [
                            12.519562925278677,
                            43.87523210819592
                        ],
                        [
                            12.517593527793707,
                            43.88069010751431
                        ],
                        [
                            12.508505891402649,
                            43.87808072443562
                        ],
                        [
                            12.499478347409905,
                            43.87345379330753
                        ],
                        [
                            12.494428849078133,
                            43.87950728166365
                        ],
                        [
                            12.487204541727777,
                            43.88583159922729
                        ],
                        [
                            12.48802095406728,
                            43.891029296335546
                        ],
                        [
                            12.486662557388172,
                            43.89649793544086
                        ],
                        [
                            12.470512133553996,
                            43.89609148981384
                        ],
                        [
                            12.464612222085378,
                            43.895068855720496
                        ],
                        [
                            12.461686580719288,
                            43.89305159805119
                        ],
                        [
                            12.46434496061903,
                            43.89170674525394
                        ],
                        [
                            12.461672051073037,
                            43.88797666969285
                        ],
                        [
                            12.454732153027663,
                            43.88365437359019
                        ],
                        [
                            12.457555791997748,
                            43.88003942043426
                        ],
                        [
                            12.453023963638628,
                            43.87693041968504
                        ],
                        [
                            12.449233281197934,
                            43.87272645437821
                        ],
                        [
                            12.444161988397354,
                            43.87116563799074
                        ],
                        [
                            12.442390542332703,
                            43.87234454620051
                        ],
                        [
                            12.437637561344275,
                            43.87198016356547
                        ],
                        [
                            12.432544375782072,
                            43.872771913187705
                        ],
                        [
                            12.429506947298108,
                            43.871703348256204
                        ],
                        [
                            12.426017527122355,
                            43.87274034904874
                        ],
                        [
                            12.420588413667076,
                            43.87229611298298
                        ],
                        [
                            12.4078335570033,
                            43.87662789015823
                        ],
                        [
                            12.406341578736146,
                            43.875433285323425
                        ],
                        [
                            12.40465518381589,
                            43.8763230962185
                        ],
                        [
                            12.403474252537052,
                            43.87378671912313
                        ],
                        [
                            12.401037025267954,
                            43.872387102081774
                        ],
                        [
                            12.403435408523848,
                            43.870216698880654
                        ],
                        [
                            12.39721476363519,
                            43.86715097373346
                        ],
                        [
                            12.39754043856135,
                            43.86091749684192
                        ],
                        [
                            12.393640390609278,
                            43.85850835534893
                        ],
                        [
                            12.39059337398507,
                            43.84408311239133
                        ],
                        [
                            12.394751095265974,
                            43.836499378263426
                        ],
                        [
                            12.39396626211854,
                            43.83232938781478
                        ],
                        [
                            12.398097247424573,
                            43.82540508055092
                        ],
                        [
                            12.390865773543254,
                            43.819219799564195
                        ],
                        [
                            12.387215171887561,
                            43.815292652716664
                        ],
                        [
                            12.380000649260495,
                            43.81503486635498
                        ],
                        [
                            12.369486279896279,
                            43.8120346683765
                        ],
                        [
                            12.366055174887492,
                            43.813444439161685
                        ],
                        [
                            12.363056688709548,
                            43.80597076317375
                        ],
                        [
                            12.357528561002823,
                            43.804822071968374
                        ],
                        [
                            12.356233420998109,
                            43.80238612553876
                        ],
                        [
                            12.34949762603759,
                            43.79935900364492
                        ],
                        [
                            12.345208744776556,
                            43.80112158590612
                        ],
                        [
                            12.344496756442368,
                            43.802403603038755
                        ],
                        [
                            12.337428021731853,
                            43.80307525751306
                        ],
                        [
                            12.329294358236666,
                            43.80030995015994
                        ],
                        [
                            12.321552479207838,
                            43.80583773646103
                        ],
                        [
                            12.311584839107525,
                            43.80382794480644
                        ],
                        [
                            12.304528310728358,
                            43.79875590679654
                        ],
                        [
                            12.297969882876544,
                            43.79769125931006
                        ],
                        [
                            12.284218825158064,
                            43.79401312645899
                        ],
                        [
                            12.283288204092656,
                            43.7889104658538
                        ],
                        [
                            12.289982733431998,
                            43.787515920669904
                        ],
                        [
                            12.287470338034723,
                            43.76982736057104
                        ],
                        [
                            12.285239635457117,
                            43.77057485840487
                        ],
                        [
                            12.28453501023688,
                            43.7644155207754
                        ],
                        [
                            12.305437530492583,
                            43.75630048320983
                        ],
                        [
                            12.313944771516475,
                            43.75814864434503
                        ],
                        [
                            12.328538860435572,
                            43.75036307117236
                        ],
                        [
                            12.342207485203375,
                            43.73520421927458
                        ],
                        [
                            12.369345376575808,
                            43.71526282137356
                        ],
                        [
                            12.37141167885477,
                            43.70832748175118
                        ],
                        [
                            12.3595438543189,
                            43.70250950816924
                        ],
                        [
                            12.344196197746129,
                            43.711120160927635
                        ],
                        [
                            12.331042919635934,
                            43.70625628861441
                        ],
                        [
                            12.337122914697176,
                            43.680105509231424
                        ],
                        [
                            12.330751638677413,
                            43.67440082215592
                        ],
                        [
                            12.313559691228079,
                            43.678732876009605
                        ],
                        [
                            12.319641714728363,
                            43.693421198175656
                        ],
                        [
                            12.313340763620943,
                            43.70233008690718
                        ],
                        [
                            12.316565147353742,
                            43.71875481655778
                        ],
                        [
                            12.30405081618153,
                            43.72713136119589
                        ],
                        [
                            12.30650276967998,
                            43.7136386622324
                        ],
                        [
                            12.296850216350464,
                            43.68503727532203
                        ],
                        [
                            12.273663718150544,
                            43.675238134787406
                        ],
                        [
                            12.25038228579271,
                            43.682332624266735
                        ],
                        [
                            12.251094275495575,
                            43.67104800363401
                        ],
                        [
                            12.232211286494532,
                            43.65457144525121
                        ],
                        [
                            12.204468547274445,
                            43.65316304358737
                        ],
                        [
                            12.193390497393437,
                            43.643216327318584
                        ],
                        [
                            12.185886820539736,
                            43.64194316623011
                        ],
                        [
                            12.18658185762115,
                            43.634711885035784
                        ],
                        [
                            12.206427341754278,
                            43.627326807759204
                        ],
                        [
                            12.214804333060556,
                            43.61114472644178
                        ],
                        [
                            12.226205832844244,
                            43.593963781524906
                        ],
                        [
                            12.23362203694819,
                            43.594678326271236
                        ],
                        [
                            12.238722487332865,
                            43.596432905311275
                        ],
                        [
                            12.242355273818617,
                            43.59547382176311
                        ],
                        [
                            12.244350294119272,
                            43.59238172308173
                        ],
                        [
                            12.251419028829673,
                            43.591707704391695
                        ],
                        [
                            12.257768510380743,
                            43.58977000666178
                        ],
                        [
                            12.263379267622696,
                            43.58978235556046
                        ],
                        [
                            12.265734321977789,
                            43.594143588820685
                        ],
                        [
                            12.27160507586359,
                            43.59498483273413
                        ],
                        [
                            12.27566553967145,
                            43.593423847358025
                        ],
                        [
                            12.28062262374607,
                            43.59509574954458
                        ],
                        [
                            12.288009670265922,
                            43.59562373897876
                        ],
                        [
                            12.288289138063533,
                            43.59323912313485
                        ],
                        [
                            12.29041047512979,
                            43.59429330493634
                        ],
                        [
                            12.295447767130298,
                            43.593974793704575
                        ],
                        [
                            12.296184069834112,
                            43.591174765653136
                        ],
                        [
                            12.293574318670892,
                            43.58949924660266
                        ],
                        [
                            12.292850222298313,
                            43.5865353140957
                        ],
                        [
                            12.29663121830859,
                            43.58735905288145
                        ],
                        [
                            12.298660239409344,
                            43.58615340195823
                        ],
                        [
                            12.30582613329986,
                            43.58943946933917
                        ],
                        [
                            12.306550229672467,
                            43.592403258841216
                        ],
                        [
                            12.308402268520979,
                            43.59368902049215
                        ],
                        [
                            12.309184680061861,
                            43.597025677510544
                        ],
                        [
                            12.310905075780795,
                            43.5980181993385
                        ],
                        [
                            12.309420362337448,
                            43.59936791267435
                        ],
                        [
                            12.31186485442899,
                            43.603323647536996
                        ],
                        [
                            12.31841843906625,
                            43.6026919797317
                        ],
                        [
                            12.321572497885427,
                            43.60451141921817
                        ],
                        [
                            12.323912565973075,
                            43.60195824051493
                        ],
                        [
                            12.328794285334965,
                            43.60731966511398
                        ],
                        [
                            12.332888749941532,
                            43.60764519062707
                        ],
                        [
                            12.333214326576154,
                            43.61139628388017
                        ],
                        [
                            12.336336806203207,
                            43.6121792140396
                        ],
                        [
                            12.341021785595643,
                            43.60877304775195
                        ],
                        [
                            12.346805066730383,
                            43.609054588172086
                        ],
                        [
                            12.350525325967169,
                            43.60865521369385
                        ],
                        [
                            12.351616362887057,
                            43.60979357212841
                        ],
                        [
                            12.34919124365635,
                            43.6126367084355
                        ],
                        [
                            12.351718365283034,
                            43.615451587828574
                        ],
                        [
                            12.35340960341918,
                            43.616257327780005
                        ],
                        [
                            12.354245486911111,
                            43.618266335463005
                        ],
                        [
                            12.357737328693446,
                            43.61807460381948
                        ],
                        [
                            12.366222775249838,
                            43.61227965050679
                        ],
                        [
                            12.36877421124612,
                            43.61358156344923
                        ],
                        [
                            12.372909405694116,
                            43.61185660452216
                        ],
                        [
                            12.367835127714898,
                            43.60805560979276
                        ],
                        [
                            12.366249447495335,
                            43.60364400144735
                        ],
                        [
                            12.362802604813453,
                            43.601655636211774
                        ],
                        [
                            12.36392515813057,
                            43.59880596558477
                        ],
                        [
                            12.361312910636883,
                            43.5964245707743
                        ],
                        [
                            12.36587814800697,
                            43.594103563379406
                        ],
                        [
                            12.368573905657456,
                            43.59128678931546
                        ],
                        [
                            12.368661574444047,
                            43.58754799528657
                        ],
                        [
                            12.372150172203703,
                            43.586937452400036
                        ],
                        [
                            12.37509213290403,
                            43.584262412601305
                        ],
                        [
                            12.370255740716118,
                            43.57768154892119
                        ],
                        [
                            12.362477387828989,
                            43.573775590556295
                        ],
                        [
                            12.361772216505301,
                            43.567828551454966
                        ],
                        [
                            12.356652024818345,
                            43.565164715839984
                        ],
                        [
                            12.354774227843478,
                            43.56174784309397
                        ],
                        [
                            12.348176817864982,
                            43.55823095916833
                        ],
                        [
                            12.344325237872255,
                            43.55221583371576
                        ],
                        [
                            12.33454798140096,
                            43.5541543520273
                        ],
                        [
                            12.332870473768281,
                            43.55201919248782
                        ],
                        [
                            12.32957800672105,
                            43.55245165554055
                        ],
                        [
                            12.32726203461138,
                            43.55053116668111
                        ],
                        [
                            12.321123592907924,
                            43.55282057849362
                        ],
                        [
                            12.314964358061872,
                            43.547806038122246
                        ],
                        [
                            12.316370710877862,
                            43.54103367949401
                        ],
                        [
                            12.323393820105991,
                            43.53867122418123
                        ],
                        [
                            12.32808432271301,
                            43.52854264296562
                        ],
                        [
                            12.331426862095526,
                            43.52843071568424
                        ],
                        [
                            12.332595329119101,
                            43.52443688977246
                        ],
                        [
                            12.336038013172214,
                            43.524966408016695
                        ],
                        [
                            12.339622596974806,
                            43.52353525159356
                        ],
                        [
                            12.342084627461531,
                            43.524957362072115
                        ],
                        [
                            12.345418849587702,
                            43.521922521500045
                        ],
                        [
                            12.34935393973845,
                            43.52273649169905
                        ],
                        [
                            12.352358448657952,
                            43.52476376964265
                        ],
                        [
                            12.354532521016836,
                            43.52864564703012
                        ],
                        [
                            12.361864381514096,
                            43.531129585025894
                        ],
                        [
                            12.367115882907598,
                            43.535118692732766
                        ],
                        [
                            12.389741780413772,
                            43.52221799994447
                        ],
                        [
                            12.385005532896372,
                            43.51627190358815
                        ],
                        [
                            12.386566261346928,
                            43.51192016553412
                        ],
                        [
                            12.391181571051277,
                            43.50991670698741
                        ],
                        [
                            12.398655331297988,
                            43.51044032058812
                        ],
                        [
                            12.401384357974848,
                            43.51931318199465
                        ],
                        [
                            12.409721823807473,
                            43.52967331619516
                        ],
                        [
                            12.414887929200916,
                            43.53119815929813
                        ],
                        [
                            12.414599971074324,
                            43.533657750762785
                        ],
                        [
                            12.421001250339259,
                            43.53735454779914
                        ],
                        [
                            12.426697358281587,
                            43.53510084862651
                        ],
                        [
                            12.430882810108699,
                            43.53751795295341
                        ],
                        [
                            12.432364187029009,
                            43.53983209796874
                        ],
                        [
                            12.437968467557141,
                            43.53985935146579
                        ],
                        [
                            12.441202545012771,
                            43.53618393364235
                        ],
                        [
                            12.442763273462248,
                            43.53183363178738
                        ],
                        [
                            12.44664413265005,
                            43.53086552901283
                        ],
                        [
                            12.452089878914904,
                            43.52700803858281
                        ],
                        [
                            12.45355046269492,
                            43.52201559713117
                        ],
                        [
                            12.459780731812685,
                            43.517443393851266
                        ],
                        [
                            12.46524311259131,
                            43.51943152458105
                        ],
                        [
                            12.473593223105127,
                            43.51695929012911
                        ],
                        [
                            12.472278697704496,
                            43.521452882602034
                        ],
                        [
                            12.478087426201284,
                            43.52422455502861
                        ],
                        [
                            12.476422394452584,
                            43.52647263016863
                        ],
                        [
                            12.486133244762726,
                            43.52715420647027
                        ],
                        [
                            12.501573722040007,
                            43.52283887712892
                        ],
                        [
                            12.503100834119437,
                            43.51915729434788
                        ],
                        [
                            12.51899164075212,
                            43.51099366514575
                        ],
                        [
                            12.52419943036179,
                            43.5039601834886
                        ],
                        [
                            12.52441483970182,
                            43.49860640645119
                        ],
                        [
                            12.554039039968131,
                            43.4697890355298
                        ],
                        [
                            12.558966331121525,
                            43.46885833696436
                        ],
                        [
                            12.557964029274501,
                            43.46591665991062
                        ],
                        [
                            12.578989507783973,
                            43.44972398881458
                        ],
                        [
                            12.58367718820773,
                            43.44841811280463
                        ],
                        [
                            12.586053115928564,
                            43.44506626716591
                        ],
                        [
                            12.593724159053693,
                            43.444304272217266
                        ],
                        [
                            12.601506599387307,
                            43.440770894955875
                        ],
                        [
                            12.61170882636921,
                            43.4353282969563
                        ],
                        [
                            12.61038592246183,
                            43.43149044927179
                        ],
                        [
                            12.613004824175448,
                            43.42969644604787
                        ],
                        [
                            12.61741912472948,
                            43.4301229202583
                        ],
                        [
                            12.624516502013194,
                            43.42335574975138
                        ],
                        [
                            12.620446482872012,
                            43.41816400091682
                        ],
                        [
                            12.62908605870416,
                            43.42245374872144
                        ],
                        [
                            12.634148289904033,
                            43.42703729321926
                        ],
                        [
                            12.635197813941716,
                            43.432608134681715
                        ],
                        [
                            12.642716964193028,
                            43.43435799152775
                        ],
                        [
                            12.652774024827352,
                            43.433794382097915
                        ],
                        [
                            12.655760750790847,
                            43.43552212546447
                        ],
                        [
                            12.665668904577018,
                            43.437682831810406
                        ],
                        [
                            12.668122460365339,
                            43.433666250788406
                        ],
                        [
                            12.68088614056822,
                            43.43419566947563
                        ],
                        [
                            12.686478315194734,
                            43.4293945931268
                        ],
                        [
                            12.706906311997471,
                            43.42679424728533
                        ],
                        [
                            12.712130389345816,
                            43.44635986625934
                        ],
                        [
                            12.720608952726309,
                            43.45001723296326
                        ],
                        [
                            12.725478795388057,
                            43.45685007789183
                        ],
                        [
                            12.734965416930322,
                            43.46540659961468
                        ],
                        [
                            12.745501699023976,
                            43.46559281055167
                        ],
                        [
                            12.747921485672265,
                            43.46368469097911
                        ],
                        [
                            12.750908211636727,
                            43.465411580737765
                        ],
                        [
                            12.756102156134574,
                            43.46038271412459
                        ],
                        [
                            12.767634013549127,
                            43.46114460560443
                        ],
                        [
                            12.774420878560903,
                            43.4570365133722
                        ],
                        [
                            12.778099530612366,
                            43.45042178753329
                        ],
                        [
                            12.766753335211632,
                            43.445041202978615
                        ],
                        [
                            12.76839626581588,
                            43.43964112082861
                        ],
                        [
                            12.7749739259759,
                            43.43186529439174
                        ],
                        [
                            12.76662454632708,
                            43.43176231797668
                        ],
                        [
                            12.768828401597403,
                            43.42381878083248
                        ],
                        [
                            12.775186630603542,
                            43.42276972655483
                        ],
                        [
                            12.782597883423165,
                            43.414528406267095
                        ],
                        [
                            12.775682941402948,
                            43.40154141995191
                        ],
                        [
                            12.76446985120458,
                            43.39590127936961
                        ],
                        [
                            12.754368931642773,
                            43.39502086415351
                        ],
                        [
                            12.7473189130624,
                            43.39046676793896
                        ],
                        [
                            12.755097990133834,
                            43.385745625615755
                        ],
                        [
                            12.750110023738301,
                            43.379309483990426
                        ],
                        [
                            12.756302906820167,
                            43.37603478169768
                        ],
                        [
                            12.761571116123378,
                            43.36914870459336
                        ],
                        [
                            12.759215504492971,
                            43.36565505092611
                        ],
                        [
                            12.772360598510943,
                            43.36049034345274
                        ],
                        [
                            12.773487887129761,
                            43.351440504614544
                        ],
                        [
                            12.784763317500989,
                            43.345901529668225
                        ],
                        [
                            12.78284306840149,
                            43.341711937357786
                        ],
                        [
                            12.792890039247425,
                            43.33759084678073
                        ],
                        [
                            12.796376302759768,
                            43.33321905530903
                        ],
                        [
                            12.793581965334454,
                            43.3292323439307
                        ],
                        [
                            12.80152302506707,
                            43.32554740346231
                        ],
                        [
                            12.804479618181034,
                            43.3026321784356
                        ],
                        [
                            12.801614477733835,
                            43.29037016297707
                        ],
                        [
                            12.793255008296683,
                            43.286707347982286
                        ],
                        [
                            12.79058215786813,
                            43.28349884985974
                        ],
                        [
                            12.805005999002077,
                            43.274248024825255
                        ],
                        [
                            12.804719166081867,
                            43.27123792245723
                        ],
                        [
                            12.808458493375326,
                            43.27198495216547
                        ],
                        [
                            12.811870492082335,
                            43.2694613157816
                        ],
                        [
                            12.814536615986498,
                            43.27029682546868
                        ],
                        [
                            12.827013463269111,
                            43.26781739966117
                        ],
                        [
                            12.826004434896817,
                            43.262491923521
                        ],
                        [
                            12.832440291970443,
                            43.26077404289441
                        ],
                        [
                            12.827030552609216,
                            43.24578260608723
                        ],
                        [
                            12.831471895778066,
                            43.24172135983352
                        ],
                        [
                            12.828043080756458,
                            43.23830887991676
                        ],
                        [
                            12.838824094873274,
                            43.224838938234825
                        ],
                        [
                            12.838206570104404,
                            43.217365094206116
                        ],
                        [
                            12.851348300860167,
                            43.2109997476274
                        ],
                        [
                            12.863595627200539,
                            43.211705745697344
                        ],
                        [
                            12.870693140998895,
                            43.190915414775844
                        ],
                        [
                            12.864993068940635,
                            43.18570622821835
                        ],
                        [
                            12.867250872927713,
                            43.18274590206596
                        ],
                        [
                            12.86441940309922,
                            43.179677122973345
                        ],
                        [
                            12.864419539612925,
                            43.165672543438376
                        ],
                        [
                            12.857271804558962,
                            43.16206325120268
                        ],
                        [
                            12.856897253782847,
                            43.15614752322605
                        ],
                        [
                            12.848973146876602,
                            43.15177986620628
                        ],
                        [
                            12.830229288220494,
                            43.14539766295323
                        ],
                        [
                            12.835537993189035,
                            43.138746492139575
                        ],
                        [
                            12.839265772709126,
                            43.13241342930766
                        ],
                        [
                            12.839647186524218,
                            43.126696810930554
                        ],
                        [
                            12.844645379219912,
                            43.122713851927585
                        ],
                        [
                            12.859207821527264,
                            43.126667355045754
                        ],
                        [
                            12.863814510620415,
                            43.12483233063972
                        ],
                        [
                            12.880823782189907,
                            43.12236918027264
                        ],
                        [
                            12.882578685366042,
                            43.12433978820974
                        ],
                        [
                            12.883763422476534,
                            43.107447239535105
                        ],
                        [
                            12.887651279380748,
                            43.10448066555614
                        ],
                        [
                            12.88584915401401,
                            43.099867858815486
                        ],
                        [
                            12.893543876491748,
                            43.093411370106224
                        ],
                        [
                            12.898677009234802,
                            43.094971376158725
                        ],
                        [
                            12.889389775783286,
                            43.05842336681425
                        ],
                        [
                            12.893925699846989,
                            43.04560450348271
                        ],
                        [
                            12.898616743533836,
                            43.0454812088912
                        ],
                        [
                            12.9074952674018,
                            43.03480441340065
                        ],
                        [
                            12.905662872670916,
                            43.0194613889507
                        ],
                        [
                            12.89768468053606,
                            43.02409500171751
                        ],
                        [
                            12.888667190664705,
                            43.01265575809035
                        ],
                        [
                            12.899834723604215,
                            42.99702587672272
                        ],
                        [
                            12.90192381840481,
                            42.99062517708674
                        ],
                        [
                            12.909348524275003,
                            42.987095036164845
                        ],
                        [
                            12.906432699853724,
                            42.982300564448394
                        ],
                        [
                            12.908052662982442,
                            42.97872053399385
                        ],
                        [
                            12.898896733288751,
                            42.97457478781169
                        ],
                        [
                            12.896085579549748,
                            42.96460074178202
                        ],
                        [
                            12.91613566231419,
                            42.95485243981702
                        ],
                        [
                            12.918687025746777,
                            42.95729278686116
                        ],
                        [
                            12.922217148187514,
                            42.95434858482173
                        ],
                        [
                            12.926472829342543,
                            42.95492412421032
                        ],
                        [
                            12.934997781217731,
                            42.94679232701583
                        ],
                        [
                            12.9431446825391,
                            42.945586828800884
                        ],
                        [
                            12.941227796701753,
                            42.942562981950545
                        ],
                        [
                            12.949853919483331,
                            42.94211339135444
                        ],
                        [
                            12.94607073326145,
                            42.939907145564376
                        ],
                        [
                            12.951959873815383,
                            42.93893264986997
                        ],
                        [
                            12.95294199608665,
                            42.93473979999581
                        ],
                        [
                            12.950107162995948,
                            42.93046510178428
                        ],
                        [
                            12.972131443575705,
                            42.92996607218956
                        ],
                        [
                            12.978047583524756,
                            42.92722073740114
                        ],
                        [
                            12.973562517941218,
                            42.91578572881866
                        ],
                        [
                            12.976900251843801,
                            42.91511099846343
                        ],
                        [
                            12.974031649613067,
                            42.912960639269414
                        ],
                        [
                            12.97098761166967,
                            42.904986393109425
                        ],
                        [
                            12.97339058200535,
                            42.89709025860276
                        ],
                        [
                            12.969681660589686,
                            42.89301816154037
                        ],
                        [
                            12.97432884534814,
                            42.89143823618775
                        ],
                        [
                            12.97693429401022,
                            42.88485098170278
                        ],
                        [
                            12.97383280757515,
                            42.88471001406191
                        ],
                        [
                            12.976647461090067,
                            42.88182168877884
                        ],
                        [
                            12.974247990530301,
                            42.87684342372802
                        ],
                        [
                            12.975942218465292,
                            42.87139236655352
                        ],
                        [
                            12.979597191165055,
                            42.87042529048074
                        ],
                        [
                            12.980201262882474,
                            42.8731632960164
                        ],
                        [
                            12.98635365029898,
                            42.86959920174263
                        ],
                        [
                            12.988979278537812,
                            42.87017799802524
                        ],
                        [
                            12.993990787771281,
                            42.88502911406948
                        ],
                        [
                            13.001000310686408,
                            42.88935891188959
                        ],
                        [
                            12.996474612923862,
                            42.89172516854171
                        ],
                        [
                            12.997679393097428,
                            42.89600501846755
                        ],
                        [
                            13.00145248953092,
                            42.89463037779356
                        ],
                        [
                            13.000939498931558,
                            42.89600009067979
                        ],
                        [
                            12.998718827346664,
                            42.89804223390263
                        ],
                        [
                            12.990652917355959,
                            42.89977277860578
                        ],
                        [
                            12.998661378855019,
                            42.90587645905961
                        ],
                        [
                            13.000395965941578,
                            42.914755206923815
                        ],
                        [
                            13.005761982888089,
                            42.9143103396365
                        ],
                        [
                            13.007527112365267,
                            42.90580481755177
                        ],
                        [
                            13.012609659655595,
                            42.903525626958185
                        ],
                        [
                            13.022116597287436,
                            42.905258581276314
                        ],
                        [
                            13.026365551917195,
                            42.90344659192917
                        ],
                        [
                            13.03234236710827,
                            42.908126101822376
                        ],
                        [
                            13.038309092510957,
                            42.90922364541996
                        ],
                        [
                            13.044373625557881,
                            42.916814270290956
                        ],
                        [
                            13.05349242284936,
                            42.92189561222668
                        ],
                        [
                            13.05579408576395,
                            42.92037819980638
                        ],
                        [
                            13.0695906102045,
                            42.90649729408142
                        ],
                        [
                            13.081102373502915,
                            42.90009633732862
                        ],
                        [
                            13.08917837328201,
                            42.90194804365504
                        ],
                        [
                            13.089880389157315,
                            42.897112342433616
                        ],
                        [
                            13.113278295610854,
                            42.890261233193655
                        ],
                        [
                            13.122812412371132,
                            42.873408120905054
                        ],
                        [
                            13.140773504455524,
                            42.857176916623615
                        ],
                        [
                            13.145434278778254,
                            42.84629807184652
                        ],
                        [
                            13.151384187868189,
                            42.841420917084434
                        ],
                        [
                            13.15472528503301,
                            42.84194059210182
                        ],
                        [
                            13.155501565713678,
                            42.835234202786864
                        ],
                        [
                            13.16237278531915,
                            42.83280391935804
                        ],
                        [
                            13.164168184160616,
                            42.83504616149875
                        ],
                        [
                            13.168380006387224,
                            42.834165139218186
                        ],
                        [
                            13.181523451421867,
                            42.8382699039648
                        ],
                        [
                            13.188155060297703,
                            42.83546109403784
                        ],
                        [
                            13.192613219779247,
                            42.837349367775914
                        ],
                        [
                            13.19349403463039,
                            42.83953452339571
                        ],
                        [
                            13.202012123465721,
                            42.84307815920266
                        ],
                        [
                            13.205623237237063,
                            42.8406531164739
                        ],
                        [
                            13.211630458306018,
                            42.84201416343879
                        ],
                        [
                            13.21617969890653,
                            42.848012197063525
                        ],
                        [
                            13.2195950608766,
                            42.84666617751387
                        ],
                        [
                            13.221029361992407,
                            42.84774252235263
                        ],
                        [
                            13.22028348718871,
                            42.85112481748459
                        ],
                        [
                            13.231427204261905,
                            42.85524672475955
                        ],
                        [
                            13.231599276711535,
                            42.85988038488398
                        ],
                        [
                            13.234025789886033,
                            42.86034300218367
                        ],
                        [
                            13.231929968560138,
                            42.86436775287825
                        ],
                        [
                            13.23539927924611,
                            42.8680632951623
                        ],
                        [
                            13.245513788372136,
                            42.85965533836645
                        ],
                        [
                            13.242243592750498,
                            42.856075571124876
                        ],
                        [
                            13.244025538541393,
                            42.8535372212865
                        ],
                        [
                            13.247758139308672,
                            42.851899374698775
                        ],
                        [
                            13.251366026331567,
                            42.83419925803696
                        ],
                        [
                            13.25552053658015,
                            42.82707869835269
                        ],
                        [
                            13.257012286186693,
                            42.820311289927474
                        ],
                        [
                            13.263245664934828,
                            42.817268961656026
                        ],
                        [
                            13.26600973299594,
                            42.810527719968775
                        ],
                        [
                            13.25866488794955,
                            42.80219961031597
                        ],
                        [
                            13.260760709275417,
                            42.798170809126475
                        ],
                        [
                            13.252758974301685,
                            42.79444877360885
                        ],
                        [
                            13.260538051372151,
                            42.78968146258677
                        ],
                        [
                            13.255053910690975,
                            42.79053586945474
                        ],
                        [
                            13.251631822195606,
                            42.789490714420936
                        ],
                        [
                            13.251662228073457,
                            42.786164485200175
                        ],
                        [
                            13.24878689931748,
                            42.78161693071169
                        ],
                        [
                            13.250008632317503,
                            42.777792386045434
                        ],
                        [
                            13.245941976438445,
                            42.77374214553808
                        ],
                        [
                            13.247399956905923,
                            42.76909955764418
                        ],
                        [
                            13.244841866948093,
                            42.76425838841607
                        ],
                        [
                            13.23842968922608,
                            42.760269196056896
                        ],
                        [
                            13.237687177684506,
                            42.76485298630351
                        ],
                        [
                            13.234751037173709,
                            42.76695892484261
                        ],
                        [
                            13.235476595888002,
                            42.77048933772093
                        ],
                        [
                            13.233374048037774,
                            42.77212687275474
                        ],
                        [
                            13.22884162374993,
                            42.772104553002606
                        ],
                        [
                            13.225692915124313,
                            42.76930755107679
                        ],
                        [
                            13.221248208692373,
                            42.772203607092194
                        ],
                        [
                            13.217188279339524,
                            42.770546222673545
                        ],
                        [
                            13.210489268696364,
                            42.76352234012785
                        ],
                        [
                            13.200071110336438,
                            42.76292516004082
                        ],
                        [
                            13.194219145404418,
                            42.76021940891832
                        ],
                        [
                            13.196679427718976,
                            42.758551826324236
                        ],
                        [
                            13.200853191450165,
                            42.75097641582073
                        ],
                        [
                            13.194505188745183,
                            42.74152632465646
                        ],
                        [
                            13.189915452478402,
                            42.7352552075061
                        ],
                        [
                            13.201724053025714,
                            42.735470925982156
                        ],
                        [
                            13.223012685102816,
                            42.727845749841094
                        ],
                        [
                            13.247006849924787,
                            42.72688273192182
                        ],
                        [
                            13.255025537724947,
                            42.72248955924306
                        ],
                        [
                            13.264579561034566,
                            42.74099007852547
                        ],
                        [
                            13.28771754066895,
                            42.74066302471189
                        ],
                        [
                            13.292027307053473,
                            42.73218812741712
                        ],
                        [
                            13.306849676179098,
                            42.72962492696985
                        ],
                        [
                            13.318828978979752,
                            42.7235836540718
                        ],
                        [
                            13.330047097372926,
                            42.71173655904124
                        ],
                        [
                            13.356161849697088,
                            42.695452467651506
                        ],
                        [
                            13.358389247806286,
                            42.695799735534706
                        ],
                        [
                            13.36315455629844,
                            42.69380549098054
                        ],
                        [
                            13.368719688310307,
                            42.693475616077706
                        ],
                        [
                            13.372580502599703,
                            42.69501848040042
                        ],
                        [
                            13.389016108328747,
                            42.68646050691791
                        ],
                        [
                            13.397611825232474,
                            42.68934065649404
                        ],
                        [
                            13.401361242313357,
                            42.69368910820208
                        ],
                        [
                            13.412940321918455,
                            42.69711957657327
                        ],
                        [
                            13.427954943070404,
                            42.7063880016475
                        ],
                        [
                            13.436520390609985,
                            42.698486353227935
                        ],
                        [
                            13.438835506575998,
                            42.70175529820395
                        ],
                        [
                            13.441110126876708,
                            42.70476118751466
                        ],
                        [
                            13.437735260571912,
                            42.70637317290681
                        ],
                        [
                            13.438265067483997,
                            42.71098823284032
                        ],
                        [
                            13.439085070580319,
                            42.71983796974072
                        ],
                        [
                            13.441400051059134,
                            42.726931302264745
                        ],
                        [
                            13.451875521397056,
                            42.73377839408428
                        ],
                        [
                            13.461338600101385,
                            42.73405607713684
                        ],
                        [
                            13.4634107420747,
                            42.73574665168326
                        ],
                        [
                            13.465590917993296,
                            42.73343637968699
                        ],
                        [
                            13.480177039652347,
                            42.73169129203586
                        ],
                        [
                            13.48694022531248,
                            42.73325801075791
                        ],
                        [
                            13.49343003110286,
                            42.73657720041655
                        ],
                        [
                            13.493912615824456,
                            42.73853291804102
                        ],
                        [
                            13.49753381938416,
                            42.73969543038518
                        ],
                        [
                            13.500135768270411,
                            42.74599830716372
                        ],
                        [
                            13.504870670884713,
                            42.74733416907148
                        ],
                        [
                            13.50440153921383,
                            42.75016683929013
                        ],
                        [
                            13.501185292308008,
                            42.751631434499956
                        ],
                        [
                            13.503615168744716,
                            42.75329179456344
                        ],
                        [
                            13.50178407140504,
                            42.76142965565322
                        ],
                        [
                            13.50177048184085,
                            42.77074042230842
                        ],
                        [
                            13.506076748449516,
                            42.77516987654138
                        ],
                        [
                            13.509866661196668,
                            42.7797748812296
                        ],
                        [
                            13.50828383047022,
                            42.782432677723165
                        ],
                        [
                            13.512104149094256,
                            42.78371078121708
                        ],
                        [
                            13.516727654500102,
                            42.787847057686776
                        ],
                        [
                            13.516177531498556,
                            42.7901528335974
                        ],
                        [
                            13.529670133677882,
                            42.79541302728967
                        ],
                        [
                            13.531570066688772,
                            42.80655341328472
                        ],
                        [
                            13.533760332395815,
                            42.807833486126555
                        ],
                        [
                            13.532339484331459,
                            42.811539812771315
                        ],
                        [
                            13.53422267497291,
                            42.81669900926798
                        ],
                        [
                            13.545116691529046,
                            42.81685750173551
                        ],
                        [
                            13.552322102759092,
                            42.806026042114866
                        ],
                        [
                            13.556689181122579,
                            42.80380258809268
                        ],
                        [
                            13.558720827430506,
                            42.80522880235188
                        ],
                        [
                            13.563644755320865,
                            42.803091986315366
                        ],
                        [
                            13.567664189009292,
                            42.80448603869323
                        ],
                        [
                            13.571666806384087,
                            42.79990024647586
                        ],
                        [
                            13.580222027622511,
                            42.802512661495285
                        ],
                        [
                            13.580822736077778,
                            42.80405774932797
                        ],
                        [
                            13.584133427365629,
                            42.80790297813576
                        ],
                        [
                            13.58937795731623,
                            42.80666998880011
                        ],
                        [
                            13.594187124736834,
                            42.80613767980512
                        ],
                        [
                            13.601183185842473,
                            42.80568957042581
                        ],
                        [
                            13.608553806483144,
                            42.811191038531746
                        ],
                        [
                            13.612883752443423,
                            42.8099011658484
                        ],
                        [
                            13.618060744114956,
                            42.81292658612628
                        ],
                        [
                            13.620574975144365,
                            42.81630585291822
                        ],
                        [
                            13.62494878003315,
                            42.816474214671246
                        ],
                        [
                            13.62816502693886,
                            42.815011152653454
                        ],
                        [
                            13.627240353160289,
                            42.81136691617414
                        ],
                        [
                            13.629015572425828,
                            42.806434987410995
                        ],
                        [
                            13.635448066237359,
                            42.80350835376706
                        ],
                        [
                            13.638870154732729,
                            42.804553272072326
                        ],
                        [
                            13.640574472455143,
                            42.80268383759267
                        ],
                        [
                            13.642923357561216,
                            42.80381790302488
                        ],
                        [
                            13.654735321370708,
                            42.805229311826736
                        ],
                        [
                            13.663965515869705,
                            42.80751963557341
                        ],
                        [
                            13.665642790977387,
                            42.81017130614933
                        ],
                        [
                            13.677228597108638,
                            42.815986798212776
                        ],
                        [
                            13.681322295601518,
                            42.81551398028117
                        ],
                        [
                            13.68383316336974,
                            42.8176974408922
                        ],
                        [
                            13.687900398913683,
                            42.81794934260208
                        ],
                        [
                            13.700080186974049,
                            42.82291755565825
                        ],
                        [
                            13.700542455606978,
                            42.83178101267245
                        ],
                        [
                            13.706995128994265,
                            42.836027925704286
                        ],
                        [
                            13.710990883331021,
                            42.84313512433627
                        ],
                        [
                            13.705672088574175,
                            42.84623309513583
                        ],
                        [
                            13.706873505484651,
                            42.849321047546226
                        ],
                        [
                            13.715108124662777,
                            42.851028302114315
                        ],
                        [
                            13.72267449710563,
                            42.85544707892885
                        ],
                        [
                            13.731462602548447,
                            42.8560458318984
                        ],
                        [
                            13.736865751898108,
                            42.854667835269765
                        ],
                        [
                            13.7470470978744,
                            42.856484308015155
                        ],
                        [
                            13.75313531027308,
                            42.858369473831715
                        ],
                        [
                            13.760175239066001,
                            42.8593788499939
                        ],
                        [
                            13.765895627213752,
                            42.85770900776831
                        ],
                        [
                            13.773971626992846,
                            42.85956198659426
                        ],
                        [
                            13.800669998432141,
                            42.86704010234621
                        ],
                        [
                            13.811499839971276,
                            42.8726474284839
                        ],
                        [
                            13.812859876280868,
                            42.87558814223496
                        ],
                        [
                            13.822069196582419,
                            42.8795561832311
                        ],
                        [
                            13.83396215172246,
                            42.88149013409571
                        ],
                        [
                            13.841285550172614,
                            42.88433401640833
                        ],
                        [
                            13.845217266004255,
                            42.88281321479528
                        ],
                        [
                            13.849078080293623,
                            42.88435136695344
                        ],
                        [
                            13.853887247714255,
                            42.88381972690391
                        ],
                        [
                            13.863117442213252,
                            42.88610713879544
                        ],
                        [
                            13.876485194134489,
                            42.88937860947729
                        ],
                        [
                            13.875982293322522,
                            42.89433112347163
                        ],
                        [
                            13.880912947739063,
                            42.89458581099669
                        ],
                        [
                            13.88074423855258,
                            42.891149022760516
                        ],
                        [
                            13.893666538153553,
                            42.89153728355501
                        ],
                        [
                            13.902262255058218,
                            42.894407906249455
                        ],
                        [
                            13.914672159073433,
                            42.894699818748734
                        ],
                        [
                            13.890751861734344,
                            42.940769509183355
                        ],
                        [
                            13.873229191387907,
                            42.98703831805324
                        ],
                        [
                            13.86333163832768,
                            43.01853137402293
                        ],
                        [
                            13.850840892616702,
                            43.063510547650395
                        ],
                        [
                            13.845921450492995,
                            43.094745513335056
                        ],
                        [
                            13.83708060456911,
                            43.11088436297851
                        ],
                        [
                            13.821933553053043,
                            43.13664026257314
                        ],
                        [
                            13.811814923500265,
                            43.16153296772205
                        ],
                        [
                            13.802212404139198,
                            43.174446768497006
                        ],
                        [
                            13.789702964293667,
                            43.20346333242088
                        ],
                        [
                            13.777896230901746,
                            43.235290982022775
                        ],
                        [
                            13.765219465097061,
                            43.252693932847706
                        ],
                        [
                            13.746562269954154,
                            43.2879407342119
                        ],
                        [
                            13.734814602935899,
                            43.30993078869085
                        ],
                        [
                            13.724209644854426,
                            43.31637050582631
                        ],
                        [
                            13.707574766050897,
                            43.33999971468626
                        ],
                        [
                            13.699264504700238,
                            43.359482574466824
                        ],
                        [
                            13.68792715789229,
                            43.38323432613538
                        ],
                        [
                            13.68140336383496,
                            43.399777186701755
                        ],
                        [
                            13.671617115574662,
                            43.421311710686325
                        ],
                        [
                            13.668862609290528,
                            43.42987204390991
                        ],
                        [
                            13.664336545431041,
                            43.4363740061589
                        ],
                        [
                            13.65883008092706,
                            43.44420077078189
                        ],
                        [
                            13.648086360009358,
                            43.463432303738756
                        ],
                        [
                            13.64056992193187,
                            43.47697214361628
                        ],
                        [
                            13.629439058956962,
                            43.49185834941346
                        ],
                        [
                            13.622973794227875,
                            43.50515730665131
                        ],
                        [
                            13.625450506929582,
                            43.5108849542093
                        ],
                        [
                            13.623415947214426,
                            43.518130139816236
                        ],
                        [
                            13.624413488585418,
                            43.52283032586399
                        ],
                        [
                            13.621160211615148,
                            43.5290265763181
                        ],
                        [
                            13.621378839433646,
                            43.53465041269271
                        ],
                        [
                            13.626428160727983,
                            43.53911243721814
                        ],
                        [
                            13.628207064326887,
                            43.54374687526638
                        ],
                        [
                            13.627322460183137,
                            43.548217100578256
                        ],
                        [
                            13.625098756140545,
                            43.55002467758604
                        ],
                        [
                            13.61925328117701,
                            43.55059985883136
                        ],
                        [
                            13.617174545918346,
                            43.55249106164652
                        ],
                        [
                            13.61535136738712,
                            43.55264063723121
                        ],
                        [
                            13.614837805259583,
                            43.55526365367905
                        ],
                        [
                            13.61044691548517,
                            43.55839579009776
                        ],
                        [
                            13.605672740317402,
                            43.559074170768554
                        ],
                        [
                            13.603390069291379,
                            43.560503948767376
                        ],
                        [
                            13.600785528532185,
                            43.56071759964732
                        ],
                        [
                            13.596512572723412,
                            43.56460435504684
                        ],
                        [
                            13.590123694218363,
                            43.564103369480904
                        ],
                        [
                            13.582645518400852,
                            43.56519178207981
                        ],
                        [
                            13.573093199429366,
                            43.57048769608164
                        ],
                        [
                            13.567359324517696,
                            43.5778446103476
                        ],
                        [
                            13.566620366204518,
                            43.58779842263331
                        ],
                        [
                            13.558539631539901,
                            43.59621604020734
                        ],
                        [
                            13.554922661685964,
                            43.59754121651801
                        ],
                        [
                            13.553713569445819,
                            43.60238772583966
                        ],
                        [
                            13.546281876837298,
                            43.6074710920372
                        ],
                        [
                            13.541419220493822,
                            43.60921456744464
                        ],
                        [
                            13.538089248649982,
                            43.61027860604179
                        ],
                        [
                            13.53718752115364,
                            43.613595917499595
                        ],
                        [
                            13.531138167740238,
                            43.61962877678158
                        ],
                        [
                            13.525690397152403,
                            43.621250614264596
                        ],
                        [
                            13.511938998544338,
                            43.62554116268032
                        ],
                        [
                            13.51187181303132,
                            43.6272075256137
                        ],
                        [
                            13.507133303181604,
                            43.63044285450982
                        ],
                        [
                            13.502364284401494,
                            43.63138717848861
                        ],
                        [
                            13.494849197177928,
                            43.62231603364364
                        ],
                        [
                            13.482032228104146,
                            43.60952355814166
                        ],
                        [
                            13.480810980044168,
                            43.60644236589107
                        ],
                        [
                            13.449078594260953,
                            43.61173306396569
                        ],
                        [
                            13.427075459725273,
                            43.61870211019183
                        ],
                        [
                            13.38899852642416,
                            43.63582056062347
                        ],
                        [
                            13.38907585413736,
                            43.637712020368326
                        ],
                        [
                            13.374945848392485,
                            43.64377187671161
                        ],
                        [
                            13.371921212853493,
                            43.64259645356714
                        ],
                        [
                            13.359108135991278,
                            43.647994529749525
                        ],
                        [
                            13.355126840414016,
                            43.64768816111905
                        ],
                        [
                            13.343184299602939,
                            43.65301152769632
                        ],
                        [
                            13.302280148089665,
                            43.67090491174076
                        ],
                        [
                            13.251924287457001,
                            43.69853205716933
                        ],
                        [
                            13.221339729052602,
                            43.71951595960002
                        ],
                        [
                            13.222214922165563,
                            43.72370836411963
                        ],
                        [
                            13.217413366016046,
                            43.72165336458778
                        ],
                        [
                            13.19201704707072,
                            43.73738962953712
                        ],
                        [
                            13.171412261996295,
                            43.75162500122917
                        ],
                        [
                            13.15649656148969,
                            43.75963153627339
                        ],
                        [
                            13.132186300496613,
                            43.77401959812062
                        ],
                        [
                            13.113399169495352,
                            43.78659906854776
                        ],
                        [
                            13.097846344332197,
                            43.79820187979669
                        ],
                        [
                            13.074829542429626,
                            43.815852288686415
                        ],
                        [
                            13.06234004037617,
                            43.82538630222328
                        ],
                        [
                            13.04984447045561,
                            43.832791258633364
                        ],
                        [
                            13.041199964300688,
                            43.83688612061377
                        ],
                        [
                            13.022139140406182,
                            43.84908448925694
                        ],
                        [
                            13.022989909908858,
                            43.853112231466696
                        ],
                        [
                            13.016378813444163,
                            43.855542799885
                        ],
                        [
                            13.014329176234725,
                            43.85153429739455
                        ],
                        [
                            13.002232580182294,
                            43.85590965751632
                        ],
                        [
                            12.96985073443605,
                            43.877221322383576
                        ],
                        [
                            12.947126054569111,
                            43.894179688134216
                        ],
                        [
                            12.936714770002794,
                            43.90093374507447
                        ],
                        [
                            12.907139775738443,
                            43.92270873045757
                        ],
                        [
                            12.907196736719214,
                            43.92585190378722
                        ],
                        [
                            12.90132045935556,
                            43.925307987635364
                        ],
                        [
                            12.900689502352265,
                            43.92268377421803
                        ],
                        [
                            12.892562031239123,
                            43.923110357892256
                        ],
                        [
                            12.880137703711654,
                            43.93026131016228
                        ],
                        [
                            12.866414409351364,
                            43.93632077332538
                        ],
                        [
                            12.850888941017047,
                            43.94134174272946
                        ],
                        [
                            12.830751453623009,
                            43.95034768284654
                        ],
                        [
                            12.818419882705115,
                            43.95556050540304
                        ],
                        [
                            12.810055906499741,
                            43.96044957699294
                        ],
                        [
                            12.806489832496737,
                            43.96100311047215
                        ],
                        [
                            12.80448901105163,
                            43.962217956741114
                        ],
                        [
                            12.796962124441905,
                            43.96546067966284
                        ],
                        [
                            12.786937670901068,
                            43.964436875373934
                        ],
                        [
                            12.781649805318523,
                            43.96565653288985
                        ],
                        [
                            12.778760890837589,
                            43.96470869310798
                        ],
                        [
                            12.772673374888058,
                            43.96665082974789
                        ],
                        [
                            12.765694374850284,
                            43.965247443349966
                        ],
                        [
                            12.756982151279942,
                            43.969191360873396
                        ],
                        [
                            12.751669851599274,
                            43.96838031704655
                        ]
                    ]
                ],
                "type": "Polygon"
            },
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Campania",
                image: 'https://www.wine-searcher.com/images/region/campania-3925-1-2.jpg'
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            13.822281052184167,
                            41.17739827514072
                        ],
                        [
                            13.889070746544665,
                            41.10020673658828
                        ],
                        [
                            13.910628555866614,
                            41.061782004474594
                        ],
                        [
                            13.923242959792567,
                            41.02551818361197
                        ],
                        [
                            13.957435451061428,
                            40.99614918172229
                        ],
                        [
                            13.973430307216171,
                            40.976448748056626
                        ],
                        [
                            13.989283515963297,
                            40.96446450162913
                        ],
                        [
                            14.026103969843899,
                            40.91429181211885
                        ],
                        [
                            14.049336837207353,
                            40.84881823522585
                        ],
                        [
                            14.04149899277504,
                            40.797543632144
                        ],
                        [
                            14.047120712526747,
                            40.78916917908628
                        ],
                        [
                            14.065498733850461,
                            40.787741898213525
                        ],
                        [
                            14.081922338370422,
                            40.7856400857106
                        ],
                        [
                            14.089700482798385,
                            40.77740988445662
                        ],
                        [
                            14.08798597781104,
                            40.79370885434541
                        ],
                        [
                            14.082011738013026,
                            40.80036432833887
                        ],
                        [
                            14.080495592497911,
                            40.806091536116405
                        ],
                        [
                            14.085077536225837,
                            40.81045444470824
                        ],
                        [
                            14.07288090539253,
                            40.817644812279326
                        ],
                        [
                            14.073578286753728,
                            40.820945173098025
                        ],
                        [
                            14.077687294551371,
                            40.82227003793708
                        ],
                        [
                            14.078180947447956,
                            40.82544799159186
                        ],
                        [
                            14.09137320432357,
                            40.83055670751029
                        ],
                        [
                            14.108443592385271,
                            40.83120294613521
                        ],
                        [
                            14.118984041636077,
                            40.8262561526947
                        ],
                        [
                            14.118344321397348,
                            40.82127223445893
                        ],
                        [
                            14.133642187275434,
                            40.81995754184375
                        ],
                        [
                            14.140495603092031,
                            40.81694440075384
                        ],
                        [
                            14.156661717710207,
                            40.81686440419552
                        ],
                        [
                            14.164020260920754,
                            40.81393256503536
                        ],
                        [
                            14.174017818661774,
                            40.80007812918984
                        ],
                        [
                            14.167627607881775,
                            40.79714251484203
                        ],
                        [
                            14.161282159649037,
                            40.79693982763854
                        ],
                        [
                            14.160628648573548,
                            40.793512989261885
                        ],
                        [
                            14.164318129363593,
                            40.792345402005935
                        ],
                        [
                            14.167786938170687,
                            40.79550482867334
                        ],
                        [
                            14.174738286522686,
                            40.79865881562405
                        ],
                        [
                            14.187352278671048,
                            40.79181873899063
                        ],
                        [
                            14.208459773370642,
                            40.80036604886253
                        ],
                        [
                            14.20647197362288,
                            40.80559644778138
                        ],
                        [
                            14.207548497922222,
                            40.81320090533153
                        ],
                        [
                            14.214249010447512,
                            40.81959099932892
                        ],
                        [
                            14.222431240883651,
                            40.82424321285172
                        ],
                        [
                            14.220110466785183,
                            40.82636158778067
                        ],
                        [
                            14.230237649183753,
                            40.831725363365024
                        ],
                        [
                            14.24306512280827,
                            40.83056150326442
                        ],
                        [
                            14.249673486144399,
                            40.8262513052549
                        ],
                        [
                            14.260092275759275,
                            40.840944476767575
                        ],
                        [
                            14.26830477229771,
                            40.84378811858139
                        ],
                        [
                            14.27568491234739,
                            40.843402136773605
                        ],
                        [
                            14.284377328469283,
                            40.84143980168489
                        ],
                        [
                            14.321803572668554,
                            40.82101511131957
                        ],
                        [
                            14.352538806406145,
                            40.792083614496406
                        ],
                        [
                            14.396151505893089,
                            40.761202208740464
                        ],
                        [
                            14.448900967365379,
                            40.74794814682487
                        ],
                        [
                            14.482322590154535,
                            40.707364360761666
                        ],
                        [
                            14.473844664865126,
                            40.69279154522279
                        ],
                        [
                            14.439889749737194,
                            40.678470874986985
                        ],
                        [
                            14.430800300926846,
                            40.65929213988544
                        ],
                        [
                            14.414616591577875,
                            40.65669432999461
                        ],
                        [
                            14.407365484445915,
                            40.63930707402508
                        ],
                        [
                            14.375301509691296,
                            40.628464506935934
                        ],
                        [
                            14.353049962555055,
                            40.63384210643207
                        ],
                        [
                            14.344033151318158,
                            40.62295597950201
                        ],
                        [
                            14.327679096011225,
                            40.589431336087095
                        ],
                        [
                            14.32515237089569,
                            40.57008593205833
                        ],
                        [
                            14.368253163918645,
                            40.58575303174682
                        ],
                        [
                            14.408113276003403,
                            40.59868060648909
                        ],
                        [
                            14.425417357890048,
                            40.61328379838142
                        ],
                        [
                            14.464517570899032,
                            40.61880394457853
                        ],
                        [
                            14.4867343812357,
                            40.62841223013132
                        ],
                        [
                            14.523550197580773,
                            40.61435723077538
                        ],
                        [
                            14.530759738165244,
                            40.60578954786455
                        ],
                        [
                            14.546799081685743,
                            40.61500851805977
                        ],
                        [
                            14.579238633590982,
                            40.61419107007859
                        ],
                        [
                            14.59067650568017,
                            40.62585002122786
                        ],
                        [
                            14.61204863588561,
                            40.63366564933231
                        ],
                        [
                            14.624808758046441,
                            40.64780035666628
                        ],
                        [
                            14.686138743987096,
                            40.63350137088444
                        ],
                        [
                            14.693364675390491,
                            40.63835278490201
                        ],
                        [
                            14.713764803408194,
                            40.66139158666568
                        ],
                        [
                            14.750880816810241,
                            40.678831141626745
                        ],
                        [
                            14.807972900990901,
                            40.66063844790893
                        ],
                        [
                            14.862692749855517,
                            40.60347440307068
                        ],
                        [
                            14.91810155839114,
                            40.54091808104011
                        ],
                        [
                            15.005010727824255,
                            40.38811090653766
                        ],
                        [
                            14.999654015980795,
                            40.353168205016786
                        ],
                        [
                            14.982376228778122,
                            40.34966354171521
                        ],
                        [
                            14.967369672925422,
                            40.33526274243819
                        ],
                        [
                            14.945075307465814,
                            40.33224536753676
                        ],
                        [
                            14.951345498848667,
                            40.29296713212086
                        ],
                        [
                            14.947818915028947,
                            40.274098641225706
                        ],
                        [
                            14.903084605849259,
                            40.250384663481356
                        ],
                        [
                            14.931565413284908,
                            40.22942533375973
                        ],
                        [
                            14.943682589307295,
                            40.23563198864744
                        ],
                        [
                            14.961307428385997,
                            40.2308976416386
                        ],
                        [
                            14.971256678490871,
                            40.218908550936575
                        ],
                        [
                            14.988537828314449,
                            40.22179253123062
                        ],
                        [
                            15.013671611971326,
                            40.200927882804486
                        ],
                        [
                            15.03961342715107,
                            40.17279169343631
                        ],
                        [
                            15.066790195221362,
                            40.16253964003868
                        ],
                        [
                            15.085282777320668,
                            40.17420879945671
                        ],
                        [
                            15.100182327393346,
                            40.173353818612824
                        ],
                        [
                            15.122358507192956,
                            40.177564845917495
                        ],
                        [
                            15.144477196246616,
                            40.16502671981041
                        ],
                        [
                            15.183897125566979,
                            40.12288864044834
                        ],
                        [
                            15.207351098259094,
                            40.124410512625396
                        ],
                        [
                            15.229337836513611,
                            40.100368793142465
                        ],
                        [
                            15.243544684916042,
                            40.09028892446764
                        ],
                        [
                            15.263858787028711,
                            40.0823388320132
                        ],
                        [
                            15.277276841492007,
                            40.06572019196686
                        ],
                        [
                            15.2863413476381,
                            40.04156228346465
                        ],
                        [
                            15.28257663667096,
                            40.02928560407459
                        ],
                        [
                            15.270536670157194,
                            40.02907068165183
                        ],
                        [
                            15.274662461104338,
                            40.023537886222336
                        ],
                        [
                            15.291000560213723,
                            40.02202257988684
                        ],
                        [
                            15.293531647282027,
                            40.02818499256535
                        ],
                        [
                            15.318443980878493,
                            40.03254219304279
                        ],
                        [
                            15.341619377451678,
                            40.005558503110365
                        ],
                        [
                            15.380190798113938,
                            39.99967670751515
                        ],
                        [
                            15.421168472135452,
                            39.9904802454478
                        ],
                        [
                            15.45276802116496,
                            40.02526151743841
                        ],
                        [
                            15.4682784849123,
                            40.03785412864794
                        ],
                        [
                            15.485000606319268,
                            40.03907829980719
                        ],
                        [
                            15.49512611747437,
                            40.0547471138171
                        ],
                        [
                            15.519478777744023,
                            40.071163355747395
                        ],
                        [
                            15.563688911189374,
                            40.0757100979028
                        ],
                        [
                            15.575249299363266,
                            40.07715596584573
                        ],
                        [
                            15.606366561031638,
                            40.07337215048514
                        ],
                        [
                            15.617075779146965,
                            40.06841529992698
                        ],
                        [
                            15.62615316910148,
                            40.07392363402049
                        ],
                        [
                            15.633379978405483,
                            40.07004557969136
                        ],
                        [
                            15.626942575883277,
                            40.06162336695057
                        ],
                        [
                            15.627813135748656,
                            40.055750730430944
                        ],
                        [
                            15.642778040901922,
                            40.04116654624028
                        ],
                        [
                            15.645879278807449,
                            40.04198175115675
                        ],
                        [
                            15.647619406658514,
                            40.04410500322079
                        ],
                        [
                            15.656211040090504,
                            40.04612342686747
                        ],
                        [
                            15.667390992667546,
                            40.050561534147725
                        ],
                        [
                            15.671825000654565,
                            40.05385128885676
                        ],
                        [
                            15.676583712383042,
                            40.05471836038097
                        ],
                        [
                            15.676396733178784,
                            40.063362246666244
                        ],
                        [
                            15.670031121663495,
                            40.07272223168698
                        ],
                        [
                            15.66202915171181,
                            40.07402079368728
                        ],
                        [
                            15.652338383367592,
                            40.077261458059304
                        ],
                        [
                            15.653085421800247,
                            40.079271790329514
                        ],
                        [
                            15.657314899456146,
                            40.08059730399643
                        ],
                        [
                            15.662390774155357,
                            40.078684327513365
                        ],
                        [
                            15.66952991170053,
                            40.08051326151701
                        ],
                        [
                            15.67831103933727,
                            40.08398409642167
                        ],
                        [
                            15.6805241480309,
                            40.08719114849077
                        ],
                        [
                            15.683174566975602,
                            40.08810062471278
                        ],
                        [
                            15.693362020577663,
                            40.10259703776599
                        ],
                        [
                            15.706007883586892,
                            40.1165836916732
                        ],
                        [
                            15.702987209874195,
                            40.12014962127333
                        ],
                        [
                            15.70662153313274,
                            40.12835858465231
                        ],
                        [
                            15.706374090048033,
                            40.13470322477063
                        ],
                        [
                            15.710254008584844,
                            40.13558292880461
                        ],
                        [
                            15.70765457273573,
                            40.14683398714646
                        ],
                        [
                            15.70260559578148,
                            40.151657055058436
                        ],
                        [
                            15.701086109930039,
                            40.15835205831347
                        ],
                        [
                            15.716827640465723,
                            40.169388206654034
                        ],
                        [
                            15.715874036083225,
                            40.17492252253409
                        ],
                        [
                            15.711963600442765,
                            40.176990262890946
                        ],
                        [
                            15.72981071182511,
                            40.18522347180061
                        ],
                        [
                            15.72800776319831,
                            40.19073398812738
                        ],
                        [
                            15.73284910251057,
                            40.19537701616567
                        ],
                        [
                            15.729717418535964,
                            40.197992409108764
                        ],
                        [
                            15.739489560586232,
                            40.20262614503394
                        ],
                        [
                            15.746668131993307,
                            40.199134568278794
                        ],
                        [
                            15.751888140690824,
                            40.20053064100194
                        ],
                        [
                            15.75206673229349,
                            40.20434450663139
                        ],
                        [
                            15.765971107806116,
                            40.21073727338691
                        ],
                        [
                            15.768814364764149,
                            40.21497499270648
                        ],
                        [
                            15.77406817154635,
                            40.216698522330816
                        ],
                        [
                            15.780267886804623,
                            40.21648223058938
                        ],
                        [
                            15.787073163109152,
                            40.217911344392164
                        ],
                        [
                            15.791033433069977,
                            40.21699336016843
                        ],
                        [
                            15.79661817489864,
                            40.22403310097954
                        ],
                        [
                            15.791225334596326,
                            40.22868764782643
                        ],
                        [
                            15.787548926323195,
                            40.23548457425286
                        ],
                        [
                            15.789298489147395,
                            40.24298236999938
                        ],
                        [
                            15.795597753599175,
                            40.24480494537322
                        ],
                        [
                            15.805027406993293,
                            40.25129884496569
                        ],
                        [
                            15.79943737351391,
                            40.25712932979668
                        ],
                        [
                            15.800349437168768,
                            40.26876237627609
                        ],
                        [
                            15.806802516230839,
                            40.27193724473456
                        ],
                        [
                            15.806152500291045,
                            40.273205249492676
                        ],
                        [
                            15.802677874115265,
                            40.278364317118275
                        ],
                        [
                            15.799027665179603,
                            40.27940228531155
                        ],
                        [
                            15.79316165050458,
                            40.289217000607394
                        ],
                        [
                            15.784080801172925,
                            40.289336542446435
                        ],
                        [
                            15.778163481728942,
                            40.292302325913354
                        ],
                        [
                            15.768853970900746,
                            40.28978061761577
                        ],
                        [
                            15.765446434486245,
                            40.29777949152836
                        ],
                        [
                            15.756198506922651,
                            40.29918744875452
                        ],
                        [
                            15.75049376964815,
                            40.297874609622504
                        ],
                        [
                            15.743824312479763,
                            40.300751402108176
                        ],
                        [
                            15.727630069403899,
                            40.31464615906563
                        ],
                        [
                            15.731172615853211,
                            40.3215906076567
                        ],
                        [
                            15.72107194472564,
                            40.32377376304143
                        ],
                        [
                            15.714513820047383,
                            40.332901367017236
                        ],
                        [
                            15.700219046837391,
                            40.35656663747443
                        ],
                        [
                            15.7049459564845,
                            40.34084124849393
                        ],
                        [
                            15.70621389591865,
                            40.34933678038906
                        ],
                        [
                            15.703577934007871,
                            40.350923021324945
                        ],
                        [
                            15.70499434859709,
                            40.356915897236114
                        ],
                        [
                            15.70220991153124,
                            40.36100479415596
                        ],
                        [
                            15.709483037770902,
                            40.36804124641864
                        ],
                        [
                            15.710564535778193,
                            40.37724157323498
                        ],
                        [
                            15.705274406269096,
                            40.37792343651201
                        ],
                        [
                            15.703368540785323,
                            40.38287632264759
                        ],
                        [
                            15.691216130782479,
                            40.38689601020309
                        ],
                        [
                            15.677065874216376,
                            40.3960438418877
                        ],
                        [
                            15.672003037512784,
                            40.39723170368859
                        ],
                        [
                            15.663250982837525,
                            40.39201460919245
                        ],
                        [
                            15.647990446463409,
                            40.414047698194935
                        ],
                        [
                            15.641388187482814,
                            40.41404716661903
                        ],
                        [
                            15.633879120890775,
                            40.42171857312728
                        ],
                        [
                            15.620947851770087,
                            40.427881441830564
                        ],
                        [
                            15.605646402716873,
                            40.43347797973885
                        ],
                        [
                            15.595470347368568,
                            40.44044434412806
                        ],
                        [
                            15.587207279073667,
                            40.44284744699176
                        ],
                        [
                            15.591720949595436,
                            40.44532017390114
                        ],
                        [
                            15.587197591127676,
                            40.449305665318995
                        ],
                        [
                            15.586459429637419,
                            40.45458423245937
                        ],
                        [
                            15.580018547170589,
                            40.45868762171101
                        ],
                        [
                            15.576309756836821,
                            40.46198996989055
                        ],
                        [
                            15.578997913798261,
                            40.46486683900791
                        ],
                        [
                            15.57811675590132,
                            40.46887036365664
                        ],
                        [
                            15.572238751776197,
                            40.46964163584815
                        ],
                        [
                            15.567525062687807,
                            40.475994339137515
                        ],
                        [
                            15.555531796089689,
                            40.47758275798595
                        ],
                        [
                            15.549190326370194,
                            40.48281216889599
                        ],
                        [
                            15.538072092664954,
                            40.48865535734921
                        ],
                        [
                            15.540372758012987,
                            40.49225651042322
                        ],
                        [
                            15.53541347261979,
                            40.49970858328281
                        ],
                        [
                            15.544185060622723,
                            40.50521055307403
                        ],
                        [
                            15.54998028057692,
                            40.50935952591553
                        ],
                        [
                            15.546529540786818,
                            40.513813394949956
                        ],
                        [
                            15.539702445391882,
                            40.51559116723443
                        ],
                        [
                            15.535746743879177,
                            40.521505356517636
                        ],
                        [
                            15.540403177864086,
                            40.52387224778417
                        ],
                        [
                            15.53926755130804,
                            40.52876233531926
                        ],
                        [
                            15.545775370199511,
                            40.5299690309319
                        ],
                        [
                            15.536951173967008,
                            40.535391221880076
                        ],
                        [
                            15.538436411869746,
                            40.54019338129457
                        ],
                        [
                            15.53653684196846,
                            40.54736820483532
                        ],
                        [
                            15.532241204082627,
                            40.54869095480885
                        ],
                        [
                            15.530655541054815,
                            40.55631824056814
                        ],
                        [
                            15.526755373704958,
                            40.557432797126566
                        ],
                        [
                            15.535638957455628,
                            40.56416404656259
                        ],
                        [
                            15.539034578972196,
                            40.56834288512003
                        ],
                        [
                            15.53320736357005,
                            40.572380418473
                        ],
                        [
                            15.52108039186056,
                            40.57057924753657
                        ],
                        [
                            15.513830176349256,
                            40.5742998355598
                        ],
                        [
                            15.515147237312316,
                            40.57998983235636
                        ],
                        [
                            15.509615702710686,
                            40.58558365231467
                        ],
                        [
                            15.504634706145751,
                            40.587847923144764
                        ],
                        [
                            15.500966995647353,
                            40.58533648737615
                        ],
                        [
                            15.48989183046465,
                            40.5878229644518
                        ],
                        [
                            15.488502761619891,
                            40.59208425722229
                        ],
                        [
                            15.484120692000605,
                            40.59376419601128
                        ],
                        [
                            15.472067989942957,
                            40.59386623301702
                        ],
                        [
                            15.462005353837004,
                            40.604218064310004
                        ],
                        [
                            15.450423251683812,
                            40.60827367669053
                        ],
                        [
                            15.453615349988278,
                            40.61284606276804
                        ],
                        [
                            15.453474534166592,
                            40.61724734856286
                        ],
                        [
                            15.456669758643528,
                            40.617960769805194
                        ],
                        [
                            15.45743391149562,
                            40.62306400694984
                        ],
                        [
                            15.464534607206662,
                            40.62742030224001
                        ],
                        [
                            15.465254891747078,
                            40.6309819648546
                        ],
                        [
                            15.467140933164842,
                            40.63838792613687
                        ],
                        [
                            15.482911025663173,
                            40.643316881024376
                        ],
                        [
                            15.489288249395425,
                            40.64539691359059
                        ],
                        [
                            15.500328219147931,
                            40.654976764108184
                        ],
                        [
                            15.50292842671033,
                            40.66381625718446
                        ],
                        [
                            15.50011522802999,
                            40.66555770234358
                        ],
                        [
                            15.491138057338429,
                            40.66451067934479
                        ],
                        [
                            15.486453449549108,
                            40.67269208747199
                        ],
                        [
                            15.47090023990063,
                            40.67330348820571
                        ],
                        [
                            15.456073343411479,
                            40.6795953428409
                        ],
                        [
                            15.449878684558549,
                            40.68852387945745
                        ],
                        [
                            15.445581326000134,
                            40.686831129972035
                        ],
                        [
                            15.44177811671237,
                            40.68954608046774
                        ],
                        [
                            15.42938867716217,
                            40.692002461136255
                        ],
                        [
                            15.423840086246003,
                            40.69715243437827
                        ],
                        [
                            15.422814533268786,
                            40.70170522508893
                        ],
                        [
                            15.415411042232051,
                            40.70488108766148
                        ],
                        [
                            15.41629321793059,
                            40.70754523285044
                        ],
                        [
                            15.409771902592393,
                            40.713385240611956
                        ],
                        [
                            15.404594289103798,
                            40.716092061012176
                        ],
                        [
                            15.392596849219077,
                            40.71723044241703
                        ],
                        [
                            15.381490780808416,
                            40.725074633037316
                        ],
                        [
                            15.38036843970441,
                            40.727090401016426
                        ],
                        [
                            15.383422514780833,
                            40.72920447047055
                        ],
                        [
                            15.38345612917438,
                            40.73503717026571
                        ],
                        [
                            15.381605243718344,
                            40.737926172667585
                        ],
                        [
                            15.381497634188893,
                            40.742039956595605
                        ],
                        [
                            15.379784300328136,
                            40.75112021375055
                        ],
                        [
                            15.380401589868491,
                            40.7549741963593
                        ],
                        [
                            15.383551553655321,
                            40.75690848033143
                        ],
                        [
                            15.383548070104226,
                            40.76334225748184
                        ],
                        [
                            15.390246161888273,
                            40.768666148185005
                        ],
                        [
                            15.386473085515437,
                            40.770022117004274
                        ],
                        [
                            15.384193860717811,
                            40.776393880639446
                        ],
                        [
                            15.381201874521835,
                            40.77803511385024
                        ],
                        [
                            15.380618561363876,
                            40.7812433278107
                        ],
                        [
                            15.384316964486278,
                            40.78445714411721
                        ],
                        [
                            15.384771594023242,
                            40.79100621926676
                        ],
                        [
                            15.388777862187023,
                            40.79491096082992
                        ],
                        [
                            15.385805923837129,
                            40.797156586853774
                        ],
                        [
                            15.378004779963522,
                            40.79433445906881
                        ],
                        [
                            15.366272710926143,
                            40.79764275863882
                        ],
                        [
                            15.366998084038983,
                            40.80080734945955
                        ],
                        [
                            15.373476806114516,
                            40.80520949424856
                        ],
                        [
                            15.378999417435882,
                            40.806010877158954
                        ],
                        [
                            15.380650809050993,
                            40.81145735612634
                        ],
                        [
                            15.376419695144008,
                            40.81279424174253
                        ],
                        [
                            15.3663482890294,
                            40.81243807215449
                        ],
                        [
                            15.356245037688899,
                            40.82145456589669
                        ],
                        [
                            15.358267946330209,
                            40.82382642435718
                        ],
                        [
                            15.353099949182674,
                            40.824476608990366
                        ],
                        [
                            15.34720428515087,
                            40.828615901443655
                        ],
                        [
                            15.340186994658428,
                            40.82879250287324
                        ],
                        [
                            15.33497473064195,
                            40.8344356346653
                        ],
                        [
                            15.361003394503928,
                            40.83914964330522
                        ],
                        [
                            15.373650424405017,
                            40.834778752679625
                        ],
                        [
                            15.377615283141107,
                            40.83576631175571
                        ],
                        [
                            15.374994040438011,
                            40.85961754929886
                        ],
                        [
                            15.37303818793737,
                            40.861209595905244
                        ],
                        [
                            15.379008238364374,
                            40.86734361438897
                        ],
                        [
                            15.375196505004611,
                            40.86773991674852
                        ],
                        [
                            15.369678877060899,
                            40.871376518595405
                        ],
                        [
                            15.362504963332894,
                            40.8717865330798
                        ],
                        [
                            15.35618419132361,
                            40.87057705283182
                        ],
                        [
                            15.35279802061913,
                            40.87620535636755
                        ],
                        [
                            15.354427963324284,
                            40.88266918767623
                        ],
                        [
                            15.356261909956432,
                            40.883231329322754
                        ],
                        [
                            15.358219150290648,
                            40.88199009801848
                        ],
                        [
                            15.360763545657974,
                            40.882184014333816
                        ],
                        [
                            15.36085616555356,
                            40.88368566526207
                        ],
                        [
                            15.363133226308143,
                            40.88443945115746
                        ],
                        [
                            15.365441922257581,
                            40.884063500842295
                        ],
                        [
                            15.367427782829402,
                            40.88490837789459
                        ],
                        [
                            15.370209541200751,
                            40.88447076826709
                        ],
                        [
                            15.372198790817714,
                            40.88578074054621
                        ],
                        [
                            15.374906489461178,
                            40.885888518518925
                        ],
                        [
                            15.376252926652112,
                            40.885431931584364
                        ],
                        [
                            15.376480182774799,
                            40.883974328845895
                        ],
                        [
                            15.375982784469755,
                            40.882853360426495
                        ],
                        [
                            15.378425427491791,
                            40.88082771960937
                        ],
                        [
                            15.38090089816425,
                            40.88040486580881
                        ],
                        [
                            15.38320136975084,
                            40.876670069055834
                        ],
                        [
                            15.387964938979218,
                            40.87559604416535
                        ],
                        [
                            15.390326038608858,
                            40.8758453287567
                        ],
                        [
                            15.392954518431536,
                            40.877330776213654
                        ],
                        [
                            15.393605223895818,
                            40.87866528327248
                        ],
                        [
                            15.394945496663617,
                            40.87911040856922
                        ],
                        [
                            15.397042603120884,
                            40.87814043602822
                        ],
                        [
                            15.397653435960642,
                            40.87704665419226
                        ],
                        [
                            15.399385286637761,
                            40.87666835113815
                        ],
                        [
                            15.401907942532372,
                            40.87730243415443
                        ],
                        [
                            15.400581268055957,
                            40.87916114946445
                        ],
                        [
                            15.401152706162506,
                            40.880878684807556
                        ],
                        [
                            15.40276585039544,
                            40.88166379664214
                        ],
                        [
                            15.407130611559921,
                            40.879976860380324
                        ],
                        [
                            15.41303851887929,
                            40.88137276567049
                        ],
                        [
                            15.416624464985333,
                            40.881243931691
                        ],
                        [
                            15.419738598300349,
                            40.879483581950225
                        ],
                        [
                            15.421730530465144,
                            40.879244302961396
                        ],
                        [
                            15.424119673150646,
                            40.879940499289845
                        ],
                        [
                            15.426207408044295,
                            40.87897259109246
                        ],
                        [
                            15.429825981289868,
                            40.87955967498259
                        ],
                        [
                            15.430801602514837,
                            40.8786318337139
                        ],
                        [
                            15.429821632925364,
                            40.87765282274294
                        ],
                        [
                            15.42988901067297,
                            40.876764400893876
                        ],
                        [
                            15.430740091450907,
                            40.876488174727754
                        ],
                        [
                            15.43280589483808,
                            40.87688254164876
                        ],
                        [
                            15.437166324496687,
                            40.87566240252116
                        ],
                        [
                            15.44019481541279,
                            40.87548750936424
                        ],
                        [
                            15.441595072472019,
                            40.876761721910896
                        ],
                        [
                            15.443662872250005,
                            40.87718631471721
                        ],
                        [
                            15.446247892877029,
                            40.87569631455873
                        ],
                        [
                            15.448318156886724,
                            40.87306163136529
                        ],
                        [
                            15.450425211619322,
                            40.872780939597845
                        ],
                        [
                            15.4534193476908,
                            40.868095924725075
                        ],
                        [
                            15.45706697687454,
                            40.86728593391234
                        ],
                        [
                            15.461295674001,
                            40.86724423022907
                        ],
                        [
                            15.462666360048505,
                            40.86825009977254
                        ],
                        [
                            15.463762592044848,
                            40.868526263626215
                        ],
                        [
                            15.464887810912604,
                            40.870092731482984
                        ],
                        [
                            15.46690722927557,
                            40.871034481599565
                        ],
                        [
                            15.46779772938137,
                            40.87210602413695
                        ],
                        [
                            15.472222914065616,
                            40.87305157150578
                        ],
                        [
                            15.47308564634072,
                            40.874915792653596
                        ],
                        [
                            15.469527456853564,
                            40.878907021744574
                        ],
                        [
                            15.467783584030375,
                            40.88001102704672
                        ],
                        [
                            15.468540583265895,
                            40.88098393254881
                        ],
                        [
                            15.467997171581384,
                            40.88210596967514
                        ],
                        [
                            15.468685647773643,
                            40.882630918274984
                        ],
                        [
                            15.468582104012587,
                            40.88342546899081
                        ],
                        [
                            15.469492560650284,
                            40.88508320230779
                        ],
                        [
                            15.467393582842508,
                            40.88684598988192
                        ],
                        [
                            15.467897647951833,
                            40.88753029134022
                        ],
                        [
                            15.467331321057348,
                            40.888863134546696
                        ],
                        [
                            15.467877384681572,
                            40.88992441571579
                        ],
                        [
                            15.483819593522893,
                            40.89468301851596
                        ],
                        [
                            15.487245058777827,
                            40.89627439132378
                        ],
                        [
                            15.500228339196859,
                            40.89978942687206
                        ],
                        [
                            15.50446124762074,
                            40.903485778034735
                        ],
                        [
                            15.510817073587518,
                            40.90528962799332
                        ],
                        [
                            15.515248863298465,
                            40.90374135028347
                        ],
                        [
                            15.517679944409355,
                            40.90459933483631
                        ],
                        [
                            15.52125933833544,
                            40.90786100273711
                        ],
                        [
                            15.525018563395163,
                            40.90728327072266
                        ],
                        [
                            15.52724569803922,
                            40.9073277489792
                        ],
                        [
                            15.529154339512164,
                            40.910933503373215
                        ],
                        [
                            15.52825120645938,
                            40.91451772494041
                        ],
                        [
                            15.532248010836975,
                            40.91859020708597
                        ],
                        [
                            15.534848806365705,
                            40.91968531065263
                        ],
                        [
                            15.533984202098495,
                            40.92281557788681
                        ],
                        [
                            15.53678240384434,
                            40.92632951896878
                        ],
                        [
                            15.538430550295391,
                            40.93100075586618
                        ],
                        [
                            15.539455736420615,
                            40.931980676542345
                        ],
                        [
                            15.539384453123887,
                            40.93308555786942
                        ],
                        [
                            15.540377321163584,
                            40.93430490688992
                        ],
                        [
                            15.540253070470943,
                            40.93542911058768
                        ],
                        [
                            15.539432759269555,
                            40.936263908343804
                        ],
                        [
                            15.537989464289216,
                            40.93680435846337
                        ],
                        [
                            15.538465537023392,
                            40.93823090340756
                        ],
                        [
                            15.54405245863862,
                            40.94060685992572
                        ],
                        [
                            15.543366102299355,
                            40.94288097068883
                        ],
                        [
                            15.54184207678238,
                            40.94492912855763
                        ],
                        [
                            15.543289687113013,
                            40.947119299839315
                        ],
                        [
                            15.54475279600003,
                            40.94815652894347
                        ],
                        [
                            15.547297478201271,
                            40.953085843174456
                        ],
                        [
                            15.551821196040521,
                            40.955001776886164
                        ],
                        [
                            15.55376392336193,
                            40.95665502003912
                        ],
                        [
                            15.554265706301837,
                            40.95877669525551
                        ],
                        [
                            15.55910249655759,
                            40.96081889834803
                        ],
                        [
                            15.564778759931734,
                            40.966716226123054
                        ],
                        [
                            15.566964869083577,
                            40.96905445022791
                        ],
                        [
                            15.566546539175924,
                            40.9712610919962
                        ],
                        [
                            15.568314318420116,
                            40.97580595786933
                        ],
                        [
                            15.569920008757006,
                            40.98066362333901
                        ],
                        [
                            15.567856182244764,
                            40.98484994384104
                        ],
                        [
                            15.568557952108945,
                            40.986512009286095
                        ],
                        [
                            15.568691408352478,
                            40.99198059063548
                        ],
                        [
                            15.572170071357117,
                            40.996024125039156
                        ],
                        [
                            15.57221222401345,
                            40.998721285378736
                        ],
                        [
                            15.57027070950122,
                            41.0011761348149
                        ],
                        [
                            15.567786006522766,
                            41.00321527824164
                        ],
                        [
                            15.566848792268297,
                            41.004854707253855
                        ],
                        [
                            15.566701410728417,
                            41.00886148958523
                        ],
                        [
                            15.565750143665653,
                            41.01014312651466
                        ],
                        [
                            15.566645229678155,
                            41.012349420228006
                        ],
                        [
                            15.56305862390307,
                            41.01544085073439
                        ],
                        [
                            15.559726992013172,
                            41.01589528265003
                        ],
                        [
                            15.559657666109022,
                            41.020208229585336
                        ],
                        [
                            15.55830757105594,
                            41.02288619890288
                        ],
                        [
                            15.558323586204644,
                            41.023693476183354
                        ],
                        [
                            15.560882536463588,
                            41.02591784916173
                        ],
                        [
                            15.561195284436742,
                            41.02768776313013
                        ],
                        [
                            15.562891661747933,
                            41.03011065153754
                        ],
                        [
                            15.5632106088948,
                            41.030969991631544
                        ],
                        [
                            15.561221251543692,
                            41.032407102410716
                        ],
                        [
                            15.55882373745522,
                            41.03289367500186
                        ],
                        [
                            15.557676143501972,
                            41.0349371157834
                        ],
                        [
                            15.55499573891718,
                            41.03752886862108
                        ],
                        [
                            15.552001946703239,
                            41.03777558027756
                        ],
                        [
                            15.551374667768652,
                            41.038697598078315
                        ],
                        [
                            15.550402873410288,
                            41.042965921328666
                        ],
                        [
                            15.54938410764366,
                            41.044152868504256
                        ],
                        [
                            15.549901931400719,
                            41.04567888672048
                        ],
                        [
                            15.548976179522327,
                            41.04767127952359
                        ],
                        [
                            15.549222695505094,
                            41.05258045009943
                        ],
                        [
                            15.546461196781298,
                            41.05246548031184
                        ],
                        [
                            15.543062137160891,
                            41.05577396675299
                        ],
                        [
                            15.54190832396292,
                            41.05551433103274
                        ],
                        [
                            15.530114475128446,
                            41.05768393362963
                        ],
                        [
                            15.521838030777662,
                            41.061847511563876
                        ],
                        [
                            15.513367434830814,
                            41.06283792273085
                        ],
                        [
                            15.502699673856725,
                            41.06531445793966
                        ],
                        [
                            15.495155433026014,
                            41.06959275575295
                        ],
                        [
                            15.488321129918496,
                            41.06986155477253
                        ],
                        [
                            15.48236009468712,
                            41.071408992445996
                        ],
                        [
                            15.478626857678833,
                            41.0743884222959
                        ],
                        [
                            15.478509656724468,
                            41.0785913428752
                        ],
                        [
                            15.469234551981213,
                            41.07787805827757
                        ],
                        [
                            15.465510818953575,
                            41.0776298660483
                        ],
                        [
                            15.463114926112029,
                            41.07641163771659
                        ],
                        [
                            15.45827075937962,
                            41.07589219551143
                        ],
                        [
                            15.451863090277545,
                            41.076553538245356
                        ],
                        [
                            15.448158941239221,
                            41.077677541052566
                        ],
                        [
                            15.445530831864941,
                            41.078342457496596
                        ],
                        [
                            15.443011251920783,
                            41.079980775399875
                        ],
                        [
                            15.44181957520342,
                            41.08331639668721
                        ],
                        [
                            15.431469736999887,
                            41.088818429576676
                        ],
                        [
                            15.429383760441208,
                            41.09262833081224
                        ],
                        [
                            15.427543144553576,
                            41.092788710886026
                        ],
                        [
                            15.425880199749717,
                            41.09602561388398
                        ],
                        [
                            15.423802925408602,
                            41.09731177569011
                        ],
                        [
                            15.419786011807927,
                            41.09526224382665
                        ],
                        [
                            15.412442172685285,
                            41.09505096071946
                        ],
                        [
                            15.412632154818562,
                            41.09838610679924
                        ],
                        [
                            15.406086556002109,
                            41.101700876232265
                        ],
                        [
                            15.40079675275775,
                            41.105819798983035
                        ],
                        [
                            15.39687195760185,
                            41.10684687488198
                        ],
                        [
                            15.392697652869975,
                            41.10056455569866
                        ],
                        [
                            15.389825766384426,
                            41.100994447196484
                        ],
                        [
                            15.386573617548061,
                            41.097310573593845
                        ],
                        [
                            15.383934623729058,
                            41.092862809879584
                        ],
                        [
                            15.382534717778778,
                            41.092860917349526
                        ],
                        [
                            15.3790952007524,
                            41.090901750739626
                        ],
                        [
                            15.377962730646487,
                            41.08932142129916
                        ],
                        [
                            15.375655683726023,
                            41.088942584129725
                        ],
                        [
                            15.372543603579388,
                            41.08987567026668
                        ],
                        [
                            15.369314852070907,
                            41.08851874431397
                        ],
                        [
                            15.37093420957129,
                            41.086924515689724
                        ],
                        [
                            15.370733420244967,
                            41.0862403843182
                        ],
                        [
                            15.369929326749252,
                            41.08584474742486
                        ],
                        [
                            15.368644284840261,
                            41.085932681663714
                        ],
                        [
                            15.367758405345434,
                            41.084977265985714
                        ],
                        [
                            15.366499319885357,
                            41.08478900794711
                        ],
                        [
                            15.365902392803415,
                            41.085081524634084
                        ],
                        [
                            15.364013718644127,
                            41.08527739547635
                        ],
                        [
                            15.363626194951149,
                            41.085703564065355
                        ],
                        [
                            15.36176138367107,
                            41.086643872613394
                        ],
                        [
                            15.36041561097452,
                            41.08730417033871
                        ],
                        [
                            15.359964795068121,
                            41.087808877694805
                        ],
                        [
                            15.359328956668499,
                            41.08809777877377
                        ],
                        [
                            15.358473973798016,
                            41.087975176735966
                        ],
                        [
                            15.357647539653566,
                            41.0886553819809
                        ],
                        [
                            15.356960578780953,
                            41.08972527283123
                        ],
                        [
                            15.355935678006997,
                            41.08991137483643
                        ],
                        [
                            15.355842319371302,
                            41.09110819616865
                        ],
                        [
                            15.355182753221538,
                            41.091164636129754
                        ],
                        [
                            15.354885803917735,
                            41.091754369762455
                        ],
                        [
                            15.35422172935526,
                            41.09216033365458
                        ],
                        [
                            15.353557654792784,
                            41.09256629754671
                        ],
                        [
                            15.353220529299119,
                            41.09292237793783
                        ],
                        [
                            15.35266996481576,
                            41.09297727742441
                        ],
                        [
                            15.352725386448856,
                            41.09341947853467
                        ],
                        [
                            15.351594057912987,
                            41.094841185081265
                        ],
                        [
                            15.348377053695451,
                            41.09519173190643
                        ],
                        [
                            15.346163467572856,
                            41.09521702295676
                        ],
                        [
                            15.345482459011247,
                            41.09567619856261
                        ],
                        [
                            15.34493995083209,
                            41.095869871102195
                        ],
                        [
                            15.343456102372539,
                            41.09550217761179
                        ],
                        [
                            15.342896945500348,
                            41.09522457099489
                        ],
                        [
                            15.34052444561097,
                            41.095301431242284
                        ],
                        [
                            15.338084323265585,
                            41.095059775831075
                        ],
                        [
                            15.336832458651688,
                            41.095645993819
                        ],
                        [
                            15.335149521260833,
                            41.09563004589586
                        ],
                        [
                            15.333744819296697,
                            41.09623138087153
                        ],
                        [
                            15.331803041234764,
                            41.09631647084126
                        ],
                        [
                            15.330817342978477,
                            41.0970147810074
                        ],
                        [
                            15.329935367918047,
                            41.097175908225566
                        ],
                        [
                            15.328822616152792,
                            41.09839917090443
                        ],
                        [
                            15.328059862996287,
                            41.09841474064865
                        ],
                        [
                            15.327115995992909,
                            41.09770326118763
                        ],
                        [
                            15.326640443147317,
                            41.09880053385121
                        ],
                        [
                            15.325803487874719,
                            41.09951059865075
                        ],
                        [
                            15.323620802217775,
                            41.09991226826034
                        ],
                        [
                            15.321631696699123,
                            41.0997596848504
                        ],
                        [
                            15.319780077542877,
                            41.100057629520535
                        ],
                        [
                            15.318349496304506,
                            41.10035352993637
                        ],
                        [
                            15.317436943183107,
                            41.10140347216881
                        ],
                        [
                            15.317200960450512,
                            41.10215736739193
                        ],
                        [
                            15.3148946042933,
                            41.10322572916462
                        ],
                        [
                            15.314489149266205,
                            41.10381429118418
                        ],
                        [
                            15.305141652642648,
                            41.097753026073704
                        ],
                        [
                            15.303801558844832,
                            41.098758685585615
                        ],
                        [
                            15.301270146371014,
                            41.096104055299975
                        ],
                        [
                            15.296746278003795,
                            41.094643317772864
                        ],
                        [
                            15.29789619385653,
                            41.092936919363915
                        ],
                        [
                            15.292121209611764,
                            41.091766915311865
                        ],
                        [
                            15.289032271364245,
                            41.09704218127983
                        ],
                        [
                            15.285572304543985,
                            41.09666582136194
                        ],
                        [
                            15.281090571899789,
                            41.099088880030834
                        ],
                        [
                            15.277696577867935,
                            41.100627580903904
                        ],
                        [
                            15.276143118217725,
                            41.10052430669736
                        ],
                        [
                            15.272554182359578,
                            41.10376080130774
                        ],
                        [
                            15.266816855515025,
                            41.10672845554648
                        ],
                        [
                            15.264708860340273,
                            41.10509995299921
                        ],
                        [
                            15.262052642845529,
                            41.10710101732517
                        ],
                        [
                            15.263822134093855,
                            41.113238718819545
                        ],
                        [
                            15.263139397564288,
                            41.11474779083443
                        ],
                        [
                            15.256909227268444,
                            41.11758484987193
                        ],
                        [
                            15.255204192604406,
                            41.11695468096002
                        ],
                        [
                            15.2532026123872,
                            41.122293155962346
                        ],
                        [
                            15.248487088949847,
                            41.12740210198392
                        ],
                        [
                            15.240816599956267,
                            41.13363997373399
                        ],
                        [
                            15.241279251575044,
                            41.13595565800373
                        ],
                        [
                            15.238974270108262,
                            41.13881539245348
                        ],
                        [
                            15.23630863349152,
                            41.141045132643455
                        ],
                        [
                            15.235149783579892,
                            41.13893532280687
                        ],
                        [
                            15.233092022705975,
                            41.13825991172633
                        ],
                        [
                            15.229743434498175,
                            41.14033728419578
                        ],
                        [
                            15.230671777670267,
                            41.14212221630189
                        ],
                        [
                            15.228159266873515,
                            41.14358108710873
                        ],
                        [
                            15.22648895734036,
                            41.14306026006308
                        ],
                        [
                            15.22650092930052,
                            41.14220259608611
                        ],
                        [
                            15.224818647807204,
                            41.14253943301744
                        ],
                        [
                            15.221437968992424,
                            41.142007314288236
                        ],
                        [
                            15.215671669597032,
                            41.14510259394189
                        ],
                        [
                            15.212721221720713,
                            41.148526023716826
                        ],
                        [
                            15.210836617995813,
                            41.148131856450206
                        ],
                        [
                            15.208971428340597,
                            41.15262685827058
                        ],
                        [
                            15.20868902245146,
                            41.15665886197836
                        ],
                        [
                            15.210175047679655,
                            41.15934366313006
                        ],
                        [
                            15.21045983008972,
                            41.161332172735555
                        ],
                        [
                            15.212521223582403,
                            41.161103463979366
                        ],
                        [
                            15.210632805261438,
                            41.16355241167169
                        ],
                        [
                            15.209321008690402,
                            41.16770288115012
                        ],
                        [
                            15.211478596003825,
                            41.167943989663684
                        ],
                        [
                            15.211478998397627,
                            41.16862818939849
                        ],
                        [
                            15.212787669701417,
                            41.16962152234103
                        ],
                        [
                            15.216379645195477,
                            41.170730912800835
                        ],
                        [
                            15.21659278964356,
                            41.17186171010802
                        ],
                        [
                            15.218181116123365,
                            41.17244803509689
                        ],
                        [
                            15.219973643913454,
                            41.17421144566477
                        ],
                        [
                            15.219941776209716,
                            41.174756368374275
                        ],
                        [
                            15.221488544696042,
                            41.17472475437066
                        ],
                        [
                            15.221244008831825,
                            41.17937609262299
                        ],
                        [
                            15.26254635248192,
                            41.19792610777949
                        ],
                        [
                            15.262629068512764,
                            41.200091921796485
                        ],
                        [
                            15.260542051182934,
                            41.204080375000494
                        ],
                        [
                            15.257039082017673,
                            41.20610091984571
                        ],
                        [
                            15.25285972832334,
                            41.2144954165703
                        ],
                        [
                            15.250331097263874,
                            41.21658738154322
                        ],
                        [
                            15.24670393586206,
                            41.22138979987649
                        ],
                        [
                            15.24708343599493,
                            41.223908355216345
                        ],
                        [
                            15.244953560922628,
                            41.22516454219442
                        ],
                        [
                            15.24479376118867,
                            41.22662550229908
                        ],
                        [
                            15.24684920446152,
                            41.230321029109575
                        ],
                        [
                            15.245827550391859,
                            41.23274339137872
                        ],
                        [
                            15.246296137332749,
                            41.236611944766395
                        ],
                        [
                            15.255561223222958,
                            41.23913873313825
                        ],
                        [
                            15.260338988793126,
                            41.23679928088361
                        ],
                        [
                            15.26743586276665,
                            41.237637076454746
                        ],
                        [
                            15.269426627195614,
                            41.23977593786154
                        ],
                        [
                            15.27216596994553,
                            41.239228809849294
                        ],
                        [
                            15.275239963455679,
                            41.241717799244384
                        ],
                        [
                            15.280347783445354,
                            41.24069268684968
                        ],
                        [
                            15.276215348285948,
                            41.24428014137446
                        ],
                        [
                            15.275997717156155,
                            41.2476076575788
                        ],
                        [
                            15.274101701492203,
                            41.25011672718385
                        ],
                        [
                            15.259788404697067,
                            41.260564364103026
                        ],
                        [
                            15.25547615438375,
                            41.266520743384106
                        ],
                        [
                            15.251251072234645,
                            41.26863841705746
                        ],
                        [
                            15.247708905302083,
                            41.27111051345315
                        ],
                        [
                            15.243330991732869,
                            41.270465705140424
                        ],
                        [
                            15.236361320877783,
                            41.27250258099416
                        ],
                        [
                            15.22971675270804,
                            41.26806328595758
                        ],
                        [
                            15.229524555565039,
                            41.26628332625404
                        ],
                        [
                            15.219679605191601,
                            41.26528585265818
                        ],
                        [
                            15.213745557215553,
                            41.27638655621687
                        ],
                        [
                            15.205825565045643,
                            41.276151114250396
                        ],
                        [
                            15.198529310904668,
                            41.280056575261696
                        ],
                        [
                            15.201385251082051,
                            41.28638297321838
                        ],
                        [
                            15.18868285754794,
                            41.28330347446661
                        ],
                        [
                            15.186266773680895,
                            41.28619712370359
                        ],
                        [
                            15.180138853567513,
                            41.28582844386404
                        ],
                        [
                            15.174127589782417,
                            41.283332655926074
                        ],
                        [
                            15.15170996283494,
                            41.28307411568365
                        ],
                        [
                            15.150068221863252,
                            41.28008080450762
                        ],
                        [
                            15.143217724325119,
                            41.284279130904864
                        ],
                        [
                            15.137020777089429,
                            41.286457673460106
                        ],
                        [
                            15.140858606488933,
                            41.291073395611846
                        ],
                        [
                            15.136994410890004,
                            41.2977921827715
                        ],
                        [
                            15.127407756346225,
                            41.303526605809495
                        ],
                        [
                            15.131305522233614,
                            41.31687757370085
                        ],
                        [
                            15.112891810154535,
                            41.31842976223648
                        ],
                        [
                            15.098021422612817,
                            41.32806314315719
                        ],
                        [
                            15.085574758129866,
                            41.32832279190811
                        ],
                        [
                            15.072170726821982,
                            41.333029054818596
                        ],
                        [
                            15.068147876907641,
                            41.342700369137575
                        ],
                        [
                            15.068520965545096,
                            41.35014602742344
                        ],
                        [
                            15.066371673732018,
                            41.35607308275337
                        ],
                        [
                            15.062070807608547,
                            41.356810048842036
                        ],
                        [
                            15.059713172122805,
                            41.37054554263988
                        ],
                        [
                            15.064088156136936,
                            41.374444205771894
                        ],
                        [
                            15.071398703393813,
                            41.3764596496543
                        ],
                        [
                            15.080350881278534,
                            41.387527250710875
                        ],
                        [
                            15.09237699716278,
                            41.38858705130577
                        ],
                        [
                            15.099027836510476,
                            41.390398020059436
                        ],
                        [
                            15.094318660362143,
                            41.3969891067326
                        ],
                        [
                            15.087482925740215,
                            41.403001511530704
                        ],
                        [
                            15.088157375882169,
                            41.41072207808964
                        ],
                        [
                            15.09273314083598,
                            41.41777796382759
                        ],
                        [
                            15.10417558354607,
                            41.42025274812533
                        ],
                        [
                            15.10036293466034,
                            41.43196777162639
                        ],
                        [
                            15.091320820595591,
                            41.433265586058006
                        ],
                        [
                            15.083962849369723,
                            41.431322158785775
                        ],
                        [
                            15.0755583691201,
                            41.432917633375325
                        ],
                        [
                            15.072921607674232,
                            41.435249555829486
                        ],
                        [
                            15.068915397986544,
                            41.434810812635654
                        ],
                        [
                            15.065802324746407,
                            41.43901887754082
                        ],
                        [
                            15.055614851382398,
                            41.44285569270151
                        ],
                        [
                            15.046367197209923,
                            41.442293998029044
                        ],
                        [
                            15.043321230691038,
                            41.448044974756726
                        ],
                        [
                            15.04507829002055,
                            41.45497492382749
                        ],
                        [
                            15.032666760092756,
                            41.451403449909286
                        ],
                        [
                            15.030995796525405,
                            41.4663936199741
                        ],
                        [
                            15.024529006764368,
                            41.476126440400066
                        ],
                        [
                            15.021815371263159,
                            41.478678576242004
                        ],
                        [
                            15.01278129317106,
                            41.48163004222576
                        ],
                        [
                            15.008192902811857,
                            41.48625293821942
                        ],
                        [
                            15.00282682932999,
                            41.484522851458394
                        ],
                        [
                            14.99629247017603,
                            41.47988705069562
                        ],
                        [
                            14.9920912977679,
                            41.47983359549562
                        ],
                        [
                            14.984806332275895,
                            41.478675834663704
                        ],
                        [
                            14.983597294050043,
                            41.47550025591917
                        ],
                        [
                            14.985950925762552,
                            41.472978529297365
                        ],
                        [
                            14.982619232756548,
                            41.46902648685554
                        ],
                        [
                            14.977096918255114,
                            41.46867526779991
                        ],
                        [
                            14.973890867509795,
                            41.46676148824477
                        ],
                        [
                            14.961577267287765,
                            41.465507658948326
                        ],
                        [
                            14.952353102197662,
                            41.45754641214373
                        ],
                        [
                            14.927611406210218,
                            41.45185729393853
                        ],
                        [
                            14.923332113292815,
                            41.45249364786676
                        ],
                        [
                            14.920126062546558,
                            41.45057939072649
                        ],
                        [
                            14.912487862460665,
                            41.45191066924153
                        ],
                        [
                            14.907960669622014,
                            41.44969855286115
                        ],
                        [
                            14.894552255112558,
                            41.44782998108741
                        ],
                        [
                            14.889301670097353,
                            41.44444920540039
                        ],
                        [
                            14.885382380968593,
                            41.44505508448566
                        ],
                        [
                            14.881694068771282,
                            41.44236156701311
                        ],
                        [
                            14.881364663742431,
                            41.438972894549465
                        ],
                        [
                            14.875481736394846,
                            41.43618053186219
                        ],
                        [
                            14.875994596605636,
                            41.43354013944324
                        ],
                        [
                            14.871987033173554,
                            41.431146610191774
                        ],
                        [
                            14.86761269675614,
                            41.42632365504798
                        ],
                        [
                            14.860691119651108,
                            41.42636436247838
                        ],
                        [
                            14.854404660419306,
                            41.424574101075564
                        ],
                        [
                            14.84156369199252,
                            41.42726528284862
                        ],
                        [
                            14.836414919671995,
                            41.43180293161882
                        ],
                        [
                            14.82565894314314,
                            41.434219273892296
                        ],
                        [
                            14.82118942584708,
                            41.43842545502713
                        ],
                        [
                            14.812909338693288,
                            41.43912811590391
                        ],
                        [
                            14.806649956244172,
                            41.44717554909323
                        ],
                        [
                            14.792820340425521,
                            41.45217504366906
                        ],
                        [
                            14.782774217681833,
                            41.4508411307919
                        ],
                        [
                            14.771665143939714,
                            41.441261424826195
                        ],
                        [
                            14.77352636040726,
                            41.43426992025013
                        ],
                        [
                            14.763932037734492,
                            41.422645382408206
                        ],
                        [
                            14.766645673234791,
                            41.42009104127882
                        ],
                        [
                            14.766078522078743,
                            41.417541675918045
                        ],
                        [
                            14.759710557738401,
                            41.41521121822066
                        ],
                        [
                            14.751529065338076,
                            41.408112328475084
                        ],
                        [
                            14.739204046795663,
                            41.40738120213413
                        ],
                        [
                            14.728590772895984,
                            41.4034964519679
                        ],
                        [
                            14.718500513001118,
                            41.400661062077205
                        ],
                        [
                            14.712380448581968,
                            41.401180722944645
                        ],
                        [
                            14.709306809292059,
                            41.40376637469814
                        ],
                        [
                            14.705587898335011,
                            41.40449236141387
                        ],
                        [
                            14.703719912671744,
                            41.409026524582146
                        ],
                        [
                            14.699603629969573,
                            41.410743292342175
                        ],
                        [
                            14.693439428399216,
                            41.40976260622364
                        ],
                        [
                            14.687322748578339,
                            41.41151245620006
                        ],
                        [
                            14.676227213227634,
                            41.40684848600935
                        ],
                        [
                            14.662610441839348,
                            41.40560351175458
                        ],
                        [
                            14.662162163746473,
                            41.40263325050597
                        ],
                        [
                            14.667113942493557,
                            41.39920431869578
                        ],
                        [
                            14.66229471258157,
                            41.39263883625807
                        ],
                        [
                            14.657743690177881,
                            41.39630760064901
                        ],
                        [
                            14.653586654920929,
                            41.39775465483754
                        ],
                        [
                            14.642929243870071,
                            41.39236866118699
                        ],
                        [
                            14.633760994107064,
                            41.38598836900218
                        ],
                        [
                            14.629940270454341,
                            41.37878956074209
                        ],
                        [
                            14.616066654863204,
                            41.36779227781341
                        ],
                        [
                            14.609946590444991,
                            41.368312201429205
                        ],
                        [
                            14.60461111572289,
                            41.363155756266195
                        ],
                        [
                            14.598660830715602,
                            41.36721869895521
                        ],
                        [
                            14.592343772723979,
                            41.368849901048776
                        ],
                        [
                            14.58479046234305,
                            41.371954193395396
                        ],
                        [
                            14.58975916407988,
                            41.374678672222245
                        ],
                        [
                            14.585056332631922,
                            41.38155685142641
                        ],
                        [
                            14.582013292101891,
                            41.38072099525945
                        ],
                        [
                            14.576236171102948,
                            41.389555351127626
                        ],
                        [
                            14.566998330242683,
                            41.39116049851569
                        ],
                        [
                            14.56455303962943,
                            41.38945337023114
                        ],
                        [
                            14.561061721006439,
                            41.38564657798497
                        ],
                        [
                            14.557658676686827,
                            41.38484135024197
                        ],
                        [
                            14.552720573710758,
                            41.37869490190363
                        ],
                        [
                            14.548804669179361,
                            41.38053224299213
                        ],
                        [
                            14.535844532763718,
                            41.38163303716868
                        ],
                        [
                            14.52545194462283,
                            41.385251428897504
                        ],
                        [
                            14.514553161885516,
                            41.3834253628429
                        ],
                        [
                            14.510705224071245,
                            41.380879637803645
                        ],
                        [
                            14.50506742110315,
                            41.38217922909536
                        ],
                        [
                            14.500441662787466,
                            41.38776982835964
                        ],
                        [
                            14.48940380298032,
                            41.389527888236046
                        ],
                        [
                            14.485382563777137,
                            41.39670763208392
                        ],
                        [
                            14.485868209825497,
                            41.39871772494297
                        ],
                        [
                            14.482876075643247,
                            41.40184354334514
                        ],
                        [
                            14.484003608760588,
                            41.404482686077245
                        ],
                        [
                            14.479486569715021,
                            41.405960124165205
                        ],
                        [
                            14.478015955796877,
                            41.4095033412905
                        ],
                        [
                            14.481500505224147,
                            41.410848257356264
                        ],
                        [
                            14.482668790896184,
                            41.41375701859849
                        ],
                        [
                            14.47780190185506,
                            41.418955240034705
                        ],
                        [
                            14.471763342543511,
                            41.420014672194014
                        ],
                        [
                            14.466200275488234,
                            41.41939322804379
                        ],
                        [
                            14.456819731998877,
                            41.42729794207088
                        ],
                        [
                            14.442653941955655,
                            41.4264498822227
                        ],
                        [
                            14.428457553151702,
                            41.42902173692627
                        ],
                        [
                            14.42810431855878,
                            41.431512081421886
                        ],
                        [
                            14.421121459882414,
                            41.432204844750146
                        ],
                        [
                            14.417300598850034,
                            41.43949953653967
                        ],
                        [
                            14.411982047118642,
                            41.4404975341958
                        ],
                        [
                            14.40228915896887,
                            41.43667333026556
                        ],
                        [
                            14.390500692822656,
                            41.4419104149772
                        ],
                        [
                            14.380383218765388,
                            41.44372556927922
                        ],
                        [
                            14.358789760600985,
                            41.448017595914706
                        ],
                        [
                            14.342881627154384,
                            41.45373992508476
                        ],
                        [
                            14.329316971625275,
                            41.453251125428096
                        ],
                        [
                            14.321033806952585,
                            41.45650439582886
                        ],
                        [
                            14.31160912631205,
                            41.462905552366436
                        ],
                        [
                            14.299382535865988,
                            41.46886071456615
                        ],
                        [
                            14.290300936025318,
                            41.46908461278497
                        ],
                        [
                            14.28514539450822,
                            41.471161144183355
                        ],
                        [
                            14.28995108602976,
                            41.472802520341986
                        ],
                        [
                            14.292522018901877,
                            41.47654567573383
                        ],
                        [
                            14.282295825574494,
                            41.48246651954457
                        ],
                        [
                            14.280465207867707,
                            41.48603608951629
                        ],
                        [
                            14.26802131626124,
                            41.48572563281709
                        ],
                        [
                            14.2646148873433,
                            41.48369277501624
                        ],
                        [
                            14.256772924488303,
                            41.483674923361576
                        ],
                        [
                            14.248054713034634,
                            41.485097108519284
                        ],
                        [
                            14.244631223748229,
                            41.491396259484816
                        ],
                        [
                            14.2417336913187,
                            41.493144096595614
                        ],
                        [
                            14.242304227074015,
                            41.4969191351926
                        ],
                        [
                            14.225822173274622,
                            41.497633837344125
                        ],
                        [
                            14.218214571948522,
                            41.49554791096136
                        ],
                        [
                            14.211819393446689,
                            41.49786524110226
                        ],
                        [
                            14.206898213459425,
                            41.49787284603906
                        ],
                        [
                            14.20518308421839,
                            41.499793311681515
                        ],
                        [
                            14.201817523172934,
                            41.498030528687906
                        ],
                        [
                            14.19241667209647,
                            41.49855448009791
                        ],
                        [
                            14.190328000674526,
                            41.49559100682495
                        ],
                        [
                            14.186371343589258,
                            41.497155405421694
                        ],
                        [
                            14.183807179912833,
                            41.49587078977822
                        ],
                        [
                            14.177449369366457,
                            41.49722912636554
                        ],
                        [
                            14.174497987737084,
                            41.500619824903964
                        ],
                        [
                            14.169522516805586,
                            41.49544335532795
                        ],
                        [
                            14.166228191756005,
                            41.49053360163731
                        ],
                        [
                            14.168819569594831,
                            41.48717290275155
                        ],
                        [
                            14.166170516212503,
                            41.48411985696964
                        ],
                        [
                            14.166235098330418,
                            41.478514625527936
                        ],
                        [
                            14.161755445100937,
                            41.479031135537014
                        ],
                        [
                            14.151522482577889,
                            41.482493988782835
                        ],
                        [
                            14.148496365036607,
                            41.48780374435543
                        ],
                        [
                            14.145062721957146,
                            41.49041647265918
                        ],
                        [
                            14.136113533571006,
                            41.49513538378423
                        ],
                        [
                            14.125544259443757,
                            41.507227520841724
                        ],
                        [
                            14.120018560344931,
                            41.50564805462605
                        ],
                        [
                            14.118751846575577,
                            41.496061235356905
                        ],
                        [
                            14.11318539492197,
                            41.49421184830001
                        ],
                        [
                            14.1105770940934,
                            41.491428795399344
                        ],
                        [
                            14.114044720531922,
                            41.48662817216797
                        ],
                        [
                            14.104760212975975,
                            41.48380344377105
                        ],
                        [
                            14.103992683648244,
                            41.48113699465404
                        ],
                        [
                            14.100270388092781,
                            41.480633187587074
                        ],
                        [
                            14.09440851476856,
                            41.47320820131776
                        ],
                        [
                            14.092798720200193,
                            41.46979314033803
                        ],
                        [
                            14.085632627771304,
                            41.468215297374456
                        ],
                        [
                            14.087325428066833,
                            41.46529449779209
                        ],
                        [
                            14.082122364800256,
                            41.46464294883609
                        ],
                        [
                            14.083596363315763,
                            41.46233203950453
                        ],
                        [
                            14.087196401209724,
                            41.46202664496542
                        ],
                        [
                            14.08666323341086,
                            41.457290000241585
                        ],
                        [
                            14.08021714856119,
                            41.45565076798357
                        ],
                        [
                            14.07854967844932,
                            41.44581724739729
                        ],
                        [
                            14.082221067656917,
                            41.44236258355525
                        ],
                        [
                            14.091676347058211,
                            41.432539839492364
                        ],
                        [
                            14.093347339147158,
                            41.429117561520286
                        ],
                        [
                            14.096468500187456,
                            41.429262723450904
                        ],
                        [
                            14.099864775311431,
                            41.42760755666632
                        ],
                        [
                            14.099457249772314,
                            41.42490823939153
                        ],
                        [
                            14.10649093074477,
                            41.42198728780855
                        ],
                        [
                            14.108555909981362,
                            41.41634358595974
                        ],
                        [
                            14.105383842594222,
                            41.41223781635475
                        ],
                        [
                            14.105689555437635,
                            41.407016107495906
                        ],
                        [
                            14.10403562371718,
                            41.402097118256165
                        ],
                        [
                            14.108348899993643,
                            41.39926938686227
                        ],
                        [
                            14.110057260039042,
                            41.39488485962343
                        ],
                        [
                            14.114560760693251,
                            41.388484611102285
                        ],
                        [
                            14.11167396118276,
                            41.386267793894035
                        ],
                        [
                            14.108916188530316,
                            41.38732268050788
                        ],
                        [
                            14.104478460549075,
                            41.38863599656321
                        ],
                        [
                            14.104366356681652,
                            41.39151771861157
                        ],
                        [
                            14.100239920185373,
                            41.38954305516188
                        ],
                        [
                            14.095084378668275,
                            41.39162213234073
                        ],
                        [
                            14.09101900231397,
                            41.397301090786215
                        ],
                        [
                            14.085985718459199,
                            41.400190045110804
                        ],
                        [
                            14.081220779491503,
                            41.39881687800036
                        ],
                        [
                            14.074577701068392,
                            41.398286939688575
                        ],
                        [
                            14.070529385082523,
                            41.39562205251252
                        ],
                        [
                            14.061692300564687,
                            41.3974665320232
                        ],
                        [
                            14.053126945532284,
                            41.39627926940267
                        ],
                        [
                            14.049197502610411,
                            41.393193911594636
                        ],
                        [
                            14.04299254848624,
                            41.391942886822335
                        ],
                        [
                            14.038203779954358,
                            41.39645243312961
                        ],
                        [
                            14.038414311920064,
                            41.40026367022364
                        ],
                        [
                            14.03422329330499,
                            41.40390143735803
                        ],
                        [
                            14.029624749151822,
                            41.40483884939485
                        ],
                        [
                            14.027828114803157,
                            41.40622204351203
                        ],
                        [
                            14.031122439852709,
                            41.411138182112325
                        ],
                        [
                            14.026656307154042,
                            41.416576024623765
                        ],
                        [
                            14.020821510612507,
                            41.41898531942317
                        ],
                        [
                            14.017554262346636,
                            41.42391064363156
                        ],
                        [
                            14.010870431368772,
                            41.42311096573641
                        ],
                        [
                            14.006472265387288,
                            41.42416779223964
                        ],
                        [
                            14.001449135325629,
                            41.43074531469398
                        ],
                        [
                            14.005419330802198,
                            41.43409878912368
                        ],
                        [
                            14.009032907086635,
                            41.438712388045644
                        ],
                        [
                            14.009773222252903,
                            41.44602928450166
                        ],
                        [
                            14.006869362373948,
                            41.452151504303345
                        ],
                        [
                            14.000711929999255,
                            41.453630394703566
                        ],
                        [
                            13.992037855696765,
                            41.45655245906792
                        ],
                        [
                            13.98691629753867,
                            41.45644043693687
                        ],
                        [
                            13.983081898115842,
                            41.45881517483389
                        ],
                        [
                            13.977510434052476,
                            41.46189051020937
                        ],
                        [
                            13.96250914796434,
                            41.46275322215038
                        ],
                        [
                            13.959574826704369,
                            41.45781044988345
                        ],
                        [
                            13.945918460627155,
                            41.45036935679508
                        ],
                        [
                            13.929276918589636,
                            41.4367489974465
                        ],
                        [
                            13.922674592719432,
                            41.43648925829859
                        ],
                        [
                            13.91832015445641,
                            41.433120381574355
                        ],
                        [
                            13.91375897825867,
                            41.43309744096993
                        ],
                        [
                            13.912984679734308,
                            41.427969075877
                        ],
                        [
                            13.907340107571429,
                            41.42680785121081
                        ],
                        [
                            13.904286913247375,
                            41.42228254299022
                        ],
                        [
                            13.900693644550074,
                            41.42504815897786
                        ],
                        [
                            13.892247162581668,
                            41.42344120768766
                        ],
                        [
                            13.892053553605649,
                            41.425781521265776
                        ],
                        [
                            13.888569004178379,
                            41.424436914399905
                        ],
                        [
                            13.887213878726925,
                            41.42632899976894
                        ],
                        [
                            13.881966678309112,
                            41.42417712598666
                        ],
                        [
                            13.876529253512643,
                            41.42559554065605
                        ],
                        [
                            13.874678328218778,
                            41.42178832743497
                        ],
                        [
                            13.863063163460623,
                            41.41730592063519
                        ],
                        [
                            13.864958363286291,
                            41.40812125105694
                        ],
                        [
                            13.87854346378208,
                            41.401498175245365
                        ],
                        [
                            13.882235160576727,
                            41.40542376543459
                        ],
                        [
                            13.890545846491335,
                            41.401299534369
                        ],
                        [
                            13.888457175069362,
                            41.398331615255614
                        ],
                        [
                            13.884622775646477,
                            41.40070847955846
                        ],
                        [
                            13.883298248954816,
                            41.39917993922407
                        ],
                        [
                            13.884371491126984,
                            41.39662721423278
                        ],
                        [
                            13.878210674154928,
                            41.39687685407583
                        ],
                        [
                            13.87596576171336,
                            41.39528962076918
                        ],
                        [
                            13.880876787906658,
                            41.391590226253015
                        ],
                        [
                            13.882028150588098,
                            41.3883466965448
                        ],
                        [
                            13.88582518205547,
                            41.38693000778753
                        ],
                        [
                            13.887299180570977,
                            41.38461633096833
                        ],
                        [
                            13.886334657668925,
                            41.38305683551843
                        ],
                        [
                            13.882890860795442,
                            41.38198146054444
                        ],
                        [
                            13.875796119681127,
                            41.37724913268778
                        ],
                        [
                            13.872129345285174,
                            41.37115925427753
                        ],
                        [
                            13.873474316943572,
                            41.36557230732154
                        ],
                        [
                            13.869864125256555,
                            41.3621847086184
                        ],
                        [
                            13.878585721308582,
                            41.36199102819961
                        ],
                        [
                            13.878938955901532,
                            41.35949801860633
                        ],
                        [
                            13.872329860836572,
                            41.35677544453915
                        ],
                        [
                            13.87383784271023,
                            41.35226822792433
                        ],
                        [
                            13.876751856383123,
                            41.34983094900599
                        ],
                        [
                            13.874065435044372,
                            41.34773264677929
                        ],
                        [
                            13.8790579663453,
                            41.34457109130574
                        ],
                        [
                            13.879159916419638,
                            41.337992469325826
                        ],
                        [
                            13.87403497366327,
                            41.336648587338374
                        ],
                        [
                            13.895139538559619,
                            41.31459226334238
                        ],
                        [
                            13.896284132046333,
                            41.30888055763512
                        ],
                        [
                            13.890585268937315,
                            41.30251771861688
                        ],
                        [
                            13.891903805985635,
                            41.297479406051536
                        ],
                        [
                            13.884483044439861,
                            41.29057700974576
                        ],
                        [
                            13.87599919451597,
                            41.28992879435171
                        ],
                        [
                            13.871166288832939,
                            41.29294244957745
                        ],
                        [
                            13.862023628850466,
                            41.285501086501455
                        ],
                        [
                            13.854755586348176,
                            41.2905028476128
                        ],
                        [
                            13.849820867969498,
                            41.28558013440127
                        ],
                        [
                            13.845263076370088,
                            41.28678976270578
                        ],
                        [
                            13.842406875619446,
                            41.28114195767088
                        ],
                        [
                            13.835600786981104,
                            41.279528906525655
                        ],
                        [
                            13.832408274626204,
                            41.282540498729844
                        ],
                        [
                            13.829228535537908,
                            41.281375710442006
                        ],
                        [
                            13.826854596239173,
                            41.276508474872
                        ],
                        [
                            13.833406015761568,
                            41.27279978440396
                        ],
                        [
                            13.826987145074327,
                            41.26649468849496
                        ],
                        [
                            13.828063771843802,
                            41.26516978809852
                        ],
                        [
                            13.834829107929266,
                            41.26651259985374
                        ],
                        [
                            13.842059782476099,
                            41.26247146415491
                        ],
                        [
                            13.839648475220969,
                            41.2585653407352
                        ],
                        [
                            13.832611409650127,
                            41.26026053273543
                        ],
                        [
                            13.836510391191837,
                            41.25225378112509
                        ],
                        [
                            13.829578660292867,
                            41.24859471044067
                        ],
                        [
                            13.825785013423797,
                            41.25124770148449
                        ],
                        [
                            13.816717089353261,
                            41.24187609176184
                        ],
                        [
                            13.814013607646046,
                            41.24813755281994
                        ],
                        [
                            13.808440386796747,
                            41.24381430899072
                        ],
                        [
                            13.803328982431765,
                            41.24740208433411
                        ],
                        [
                            13.803199955574627,
                            41.244123273872304
                        ],
                        [
                            13.79099719469366,
                            41.244202371878686
                        ],
                        [
                            13.787465123515943,
                            41.24011638343336
                        ],
                        [
                            13.77719140843908,
                            41.24332128249867
                        ],
                        [
                            13.77242308487402,
                            41.24071133823577
                        ],
                        [
                            13.772372178527121,
                            41.23674007765078
                        ],
                        [
                            13.765271166794292,
                            41.22103957507724
                        ],
                        [
                            13.822281052184167,
                            41.17739827514072
                        ]
                    ]
                ],
                "type": "Polygon"
            },
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Abruzzo",
                image: 'https://italiasweetitalia.com/wp-content/uploads/revslider/the-magical-village-of-scanno1.jpg'
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            14.120134989656862,
                            42.554277924169895
                        ],
                        [
                            14.10689496155959,
                            42.56523957361256
                        ],
                        [
                            14.0789640618747,
                            42.596590485453504
                        ],
                        [
                            14.060972492277841,
                            42.61985294800246
                        ],
                        [
                            14.037950235654193,
                            42.65624261640471
                        ],
                        [
                            14.009061786081418,
                            42.68897364837548
                        ],
                        [
                            13.981960581610991,
                            42.73764012148885
                        ],
                        [
                            13.942931625791317,
                            42.80686110612885
                        ],
                        [
                            13.929579605264905,
                            42.854645627689905
                        ],
                        [
                            13.919223341992733,
                            42.893929122271174
                        ],
                        [
                            13.904320473025706,
                            42.893525225256866
                        ],
                        [
                            13.894934265014939,
                            42.89066041354931
                        ],
                        [
                            13.881812306555588,
                            42.889883263300305
                        ],
                        [
                            13.879423591156268,
                            42.89427050858271
                        ],
                        [
                            13.876650493219302,
                            42.894015993162384
                        ],
                        [
                            13.876787749861847,
                            42.88871412784232
                        ],
                        [
                            13.853807535534514,
                            42.88272456474991
                        ],
                        [
                            13.849804987829089,
                            42.88362890887677
                        ],
                        [
                            13.845611665670475,
                            42.88127726262914
                        ],
                        [
                            13.841109218145704,
                            42.88404258143066
                        ],
                        [
                            13.835031723534627,
                            42.88076480462456
                        ],
                        [
                            13.82289978954853,
                            42.87904513961044
                        ],
                        [
                            13.813786687813405,
                            42.87557233025714
                        ],
                        [
                            13.812922456424559,
                            42.872626208237676
                        ],
                        [
                            13.800878750692704,
                            42.86586259271064
                        ],
                        [
                            13.766060411885917,
                            42.85641929253953
                        ],
                        [
                            13.75932095683055,
                            42.85828097519817
                        ],
                        [
                            13.74887572266664,
                            42.85567723997781
                        ],
                        [
                            13.735862353336273,
                            42.853905089046464
                        ],
                        [
                            13.723978257069888,
                            42.85478604330055
                        ],
                        [
                            13.717110285330477,
                            42.8510357137834
                        ],
                        [
                            13.708299356504511,
                            42.848979713909586
                        ],
                        [
                            13.706775763936463,
                            42.84659406082599
                        ],
                        [
                            13.712415522294947,
                            42.84283956100154
                        ],
                        [
                            13.70902688945614,
                            42.83665655362378
                        ],
                        [
                            13.70240985478344,
                            42.83185888034984
                        ],
                        [
                            13.702079667780083,
                            42.8227500094379
                        ],
                        [
                            13.688762194589174,
                            42.81707567420574
                        ],
                        [
                            13.684433003734028,
                            42.81681962192067
                        ],
                        [
                            13.682058284830418,
                            42.81486765584748
                        ],
                        [
                            13.67743634382387,
                            42.81515519386318
                        ],
                        [
                            13.667154052202534,
                            42.809763670268325
                        ],
                        [
                            13.664250460730699,
                            42.80627539684349
                        ],
                        [
                            13.641636727008745,
                            42.80219195932716
                        ],
                        [
                            13.637916199170348,
                            42.80399588747724
                        ],
                        [
                            13.635289100555923,
                            42.80285199603273
                        ],
                        [
                            13.627792135427086,
                            42.805938997153675
                        ],
                        [
                            13.625620757362412,
                            42.810393169288574
                        ],
                        [
                            13.62682780743529,
                            42.81476828801419
                        ],
                        [
                            13.621604030539288,
                            42.8158792993529
                        ],
                        [
                            13.619674396260223,
                            42.81298931324014
                        ],
                        [
                            13.614282029297328,
                            42.80940121949294
                        ],
                        [
                            13.609608822667838,
                            42.810609050438075
                        ],
                        [
                            13.602605678035701,
                            42.80529473154951
                        ],
                        [
                            13.58875497311368,
                            42.80621226513759
                        ],
                        [
                            13.584785228053988,
                            42.80737418053039
                        ],
                        [
                            13.58030905508383,
                            42.80155633112233
                        ],
                        [
                            13.57563724552918,
                            42.80033984774872
                        ],
                        [
                            13.57121229367445,
                            42.79937201286421
                        ],
                        [
                            13.567268826262932,
                            42.80363319638826
                        ],
                        [
                            13.563213458469733,
                            42.80235100475096
                        ],
                        [
                            13.559010895911939,
                            42.8043307799953
                        ],
                        [
                            13.55662910316525,
                            42.80285078853257
                        ],
                        [
                            13.551459228103198,
                            42.80546309626715
                        ],
                        [
                            13.544252220979985,
                            42.81652230151292
                        ],
                        [
                            13.534845698122098,
                            42.816137108952915
                        ],
                        [
                            13.533204603417204,
                            42.8119337563968
                        ],
                        [
                            13.534961585031652,
                            42.807907276097325
                        ],
                        [
                            13.53214711036452,
                            42.80597739354221
                        ],
                        [
                            13.530795889584994,
                            42.79508001973929
                        ],
                        [
                            13.517102025421849,
                            42.78973011208788
                        ],
                        [
                            13.516946534866833,
                            42.787166978834875
                        ],
                        [
                            13.512486982234606,
                            42.78315907142087
                        ],
                        [
                            13.50890127248394,
                            42.78233595089904
                        ],
                        [
                            13.510546209404481,
                            42.779663207477
                        ],
                        [
                            13.502102079268326,
                            42.77031830485788
                        ],
                        [
                            13.504147412770976,
                            42.75394821148436
                        ],
                        [
                            13.501523641740846,
                            42.75143920657857
                        ],
                        [
                            13.504652836294241,
                            42.75038468471365
                        ],
                        [
                            13.50567421441383,
                            42.74678991409692
                        ],
                        [
                            13.500600239109815,
                            42.74558189418863
                        ],
                        [
                            13.49785159980803,
                            42.73965317466454
                        ],
                        [
                            13.494609270944238,
                            42.73852371682855
                        ],
                        [
                            13.494277503277914,
                            42.73673465245514
                        ],
                        [
                            13.487266621754344,
                            42.73287379819723
                        ],
                        [
                            13.480935261860509,
                            42.73107032385212
                        ],
                        [
                            13.465913148856146,
                            42.73286881748453
                        ],
                        [
                            13.463546341970101,
                            42.73492691617912
                        ],
                        [
                            13.461146852862328,
                            42.73326200544818
                        ],
                        [
                            13.452330176570001,
                            42.73343083024304
                        ],
                        [
                            13.441800235226452,
                            42.72636428953942
                        ],
                        [
                            13.437839717621813,
                            42.706718586107144
                        ],
                        [
                            13.441559413932055,
                            42.70514979221764
                        ],
                        [
                            13.437235257757777,
                            42.69787209560596
                        ],
                        [
                            13.428292541548473,
                            42.706068725938366
                        ],
                        [
                            13.414262213637983,
                            42.696445521861904
                        ],
                        [
                            13.40159929535369,
                            42.69319784530185
                        ],
                        [
                            13.398075292901183,
                            42.68835436351745
                        ],
                        [
                            13.388547801559724,
                            42.68541698493138
                        ],
                        [
                            13.372610004205768,
                            42.69452930217463
                        ],
                        [
                            13.369722609659675,
                            42.693086088964236
                        ],
                        [
                            13.36361539561537,
                            42.69299020164132
                        ],
                        [
                            13.358537797535206,
                            42.695120577954185
                        ],
                        [
                            13.356436229289812,
                            42.69486995427903
                        ],
                        [
                            13.353594257526908,
                            42.68446212345904
                        ],
                        [
                            13.347401819232601,
                            42.66807360987042
                        ],
                        [
                            13.358161542359511,
                            42.664532779722435
                        ],
                        [
                            13.37229800430913,
                            42.64723020447431
                        ],
                        [
                            13.379687045028305,
                            42.651622491956715
                        ],
                        [
                            13.409330265847558,
                            42.642284487208315
                        ],
                        [
                            13.40748358248365,
                            42.628665283176446
                        ],
                        [
                            13.393443360270396,
                            42.62090860381048
                        ],
                        [
                            13.397710249665636,
                            42.616583665482324
                        ],
                        [
                            13.3902328887105,
                            42.608736863010336
                        ],
                        [
                            13.389045319262095,
                            42.597253542898954
                        ],
                        [
                            13.39636228579667,
                            42.58962556392669
                        ],
                        [
                            13.38119550907058,
                            42.58430872158175
                        ],
                        [
                            13.381482952564625,
                            42.581866388781634
                        ],
                        [
                            13.354873185196709,
                            42.575473144284615
                        ],
                        [
                            13.339194887844627,
                            42.57350338297991
                        ],
                        [
                            13.333930679390505,
                            42.57975918835291
                        ],
                        [
                            13.317913270221311,
                            42.57561577307092
                        ],
                        [
                            13.290827190787013,
                            42.56937725528303
                        ],
                        [
                            13.282612559757041,
                            42.58405294890471
                        ],
                        [
                            13.253782259647181,
                            42.58145801988937
                        ],
                        [
                            13.24500215265337,
                            42.572036805540336
                        ],
                        [
                            13.232521187781401,
                            42.57434359057882
                        ],
                        [
                            13.228085836657868,
                            42.581136759692896
                        ],
                        [
                            13.215110673882748,
                            42.584715129771126
                        ],
                        [
                            13.192929715516016,
                            42.58693945546858
                        ],
                        [
                            13.19331160776116,
                            42.57743956412841
                        ],
                        [
                            13.173281780383263,
                            42.56727221857324
                        ],
                        [
                            13.179088922328958,
                            42.54734843226316
                        ],
                        [
                            13.164065393347556,
                            42.537476416880224
                        ],
                        [
                            13.157622079966728,
                            42.53788342072221
                        ],
                        [
                            13.156579130323507,
                            42.52629264436908
                        ],
                        [
                            13.16736723690849,
                            42.51271567613375
                        ],
                        [
                            13.180563729529723,
                            42.5134894876561
                        ],
                        [
                            13.178411137082009,
                            42.5059923498404
                        ],
                        [
                            13.17083202709017,
                            42.481689293266015
                        ],
                        [
                            13.178851082406027,
                            42.48012980308826
                        ],
                        [
                            13.155859358124275,
                            42.46301443575172
                        ],
                        [
                            13.13862856780176,
                            42.462102923077424
                        ],
                        [
                            13.117793880440018,
                            42.44453105627389
                        ],
                        [
                            13.128471831027554,
                            42.44021457280476
                        ],
                        [
                            13.14555643979108,
                            42.425913322171255
                        ],
                        [
                            13.159594989013243,
                            42.411136329761916
                        ],
                        [
                            13.18842999534192,
                            42.401704864333965
                        ],
                        [
                            13.193624429859398,
                            42.38795159812307
                        ],
                        [
                            13.190000799562966,
                            42.37899065202927
                        ],
                        [
                            13.166019149389513,
                            42.36757949054362
                        ],
                        [
                            13.154425805828907,
                            42.35594112620805
                        ],
                        [
                            13.1977276592784,
                            42.33417639383711
                        ],
                        [
                            13.226153360669912,
                            42.3189403936675
                        ],
                        [
                            13.229513234847758,
                            42.28918058821819
                        ],
                        [
                            13.234974897585147,
                            42.2756126117209
                        ],
                        [
                            13.245215524258924,
                            42.271147011334364
                        ],
                        [
                            13.293494668344522,
                            42.23240826021856
                        ],
                        [
                            13.320968213513169,
                            42.22960164882443
                        ],
                        [
                            13.326843669155437,
                            42.22403038796182
                        ],
                        [
                            13.336724766663963,
                            42.22273128175161
                        ],
                        [
                            13.341839131548625,
                            42.218457855585854
                        ],
                        [
                            13.342839077141889,
                            42.21694964883963
                        ],
                        [
                            13.332832442604513,
                            42.208059842159
                        ],
                        [
                            13.340206541560804,
                            42.199702374283675
                        ],
                        [
                            13.352580989491855,
                            42.19179065055238
                        ],
                        [
                            13.359316179936542,
                            42.1936625762184
                        ],
                        [
                            13.363599490613325,
                            42.19011592044177
                        ],
                        [
                            13.374013869865706,
                            42.18876799703932
                        ],
                        [
                            13.375223179358642,
                            42.186008362891386
                        ],
                        [
                            13.38012281792237,
                            42.18444563787196
                        ],
                        [
                            13.381409175810035,
                            42.17974017846652
                        ],
                        [
                            13.362391036709738,
                            42.17835168977231
                        ],
                        [
                            13.355545274544966,
                            42.17407691865489
                        ],
                        [
                            13.344218609428566,
                            42.17604997635027
                        ],
                        [
                            13.355417203876215,
                            42.16601462565691
                        ],
                        [
                            13.343810967337419,
                            42.163573329757696
                        ],
                        [
                            13.334291599440263,
                            42.166873918578204
                        ],
                        [
                            13.323904838763298,
                            42.16126839322541
                        ],
                        [
                            13.330161580018967,
                            42.15451681729613
                        ],
                        [
                            13.324655548309522,
                            42.15178866101576
                        ],
                        [
                            13.3190357178988,
                            42.153782578490905
                        ],
                        [
                            13.309549008433322,
                            42.14554614408058
                        ],
                        [
                            13.304125373483714,
                            42.135247346230244
                        ],
                        [
                            13.29129506848227,
                            42.13575828843577
                        ],
                        [
                            13.280805869846802,
                            42.13149202376263
                        ],
                        [
                            13.275833051774555,
                            42.14048717632855
                        ],
                        [
                            13.27454524693482,
                            42.13952009612993
                        ],
                        [
                            13.275542332419178,
                            42.1392019884631
                        ],
                        [
                            13.27563023655614,
                            42.13793117823684
                        ],
                        [
                            13.2741871408235,
                            42.137026329575065
                        ],
                        [
                            13.272328201355464,
                            42.137623737925566
                        ],
                        [
                            13.265227490161436,
                            42.13671655376808
                        ],
                        [
                            13.265425644166896,
                            42.13555641320673
                        ],
                        [
                            13.261840677956684,
                            42.133825184764476
                        ],
                        [
                            13.260172671853525,
                            42.13407053031548
                        ],
                        [
                            13.258540022530696,
                            42.13123904290606
                        ],
                        [
                            13.24950500690096,
                            42.12674488399841
                        ],
                        [
                            13.236881876388367,
                            42.129564430875575
                        ],
                        [
                            13.231972137167645,
                            42.13632209133681
                        ],
                        [
                            13.222260292738923,
                            42.14316529359999
                        ],
                        [
                            13.207899367087037,
                            42.14706137271031
                        ],
                        [
                            13.198189918740582,
                            42.153258154655106
                        ],
                        [
                            13.194765057466071,
                            42.158099682367066
                        ],
                        [
                            13.184559646893035,
                            42.15879957393318
                        ],
                        [
                            13.181127847191505,
                            42.15417623484922
                        ],
                        [
                            13.177571041172342,
                            42.15180614262496
                        ],
                        [
                            13.16998352739202,
                            42.150389150262384
                        ],
                        [
                            13.167640243248997,
                            42.15395983510996
                        ],
                        [
                            13.16073842902233,
                            42.15635244977349
                        ],
                        [
                            13.160128683078284,
                            42.15765084400272
                        ],
                        [
                            13.15674288376269,
                            42.15772232314663
                        ],
                        [
                            13.153424357115398,
                            42.16093977737435
                        ],
                        [
                            13.146180418361496,
                            42.16272069960448
                        ],
                        [
                            13.141004499943847,
                            42.16787245333978
                        ],
                        [
                            13.134827663285904,
                            42.169024520108366
                        ],
                        [
                            13.12967816317908,
                            42.173542764808275
                        ],
                        [
                            13.126433411399518,
                            42.17395241684969
                        ],
                        [
                            13.122930288606256,
                            42.177178192837545
                        ],
                        [
                            13.113762529934576,
                            42.174605888818064
                        ],
                        [
                            13.106719980402517,
                            42.1766570076083
                        ],
                        [
                            13.101838587914898,
                            42.176051207409586
                        ],
                        [
                            13.09800438925032,
                            42.17804091235499
                        ],
                        [
                            13.092677437095688,
                            42.176763228819794
                        ],
                        [
                            13.087843381798677,
                            42.17734487282942
                        ],
                        [
                            13.087195933976176,
                            42.17318921252502
                        ],
                        [
                            13.093068877968705,
                            42.16925495581816
                        ],
                        [
                            13.093622703634253,
                            42.16237265855278
                        ],
                        [
                            13.094972943855055,
                            42.16012226351475
                        ],
                        [
                            13.091847970158199,
                            42.15571513290422
                        ],
                        [
                            13.086722942708178,
                            42.1555635866672
                        ],
                        [
                            13.088303442620571,
                            42.147754308339074
                        ],
                        [
                            13.083918963012508,
                            42.14060782896826
                        ],
                        [
                            13.07761172025846,
                            42.13897729658086
                        ],
                        [
                            13.074048306104288,
                            42.13170658217652
                        ],
                        [
                            13.068859402475226,
                            42.12922591175882
                        ],
                        [
                            13.060504613796923,
                            42.12281695906813
                        ],
                        [
                            13.05564001404332,
                            42.12312324919844
                        ],
                        [
                            13.048331595995535,
                            42.12186737146345
                        ],
                        [
                            13.04837098013175,
                            42.11801173451093
                        ],
                        [
                            13.043734823898319,
                            42.11536339615145
                        ],
                        [
                            13.039389393311836,
                            42.11612744997632
                        ],
                        [
                            13.03478467050246,
                            42.12047620528446
                        ],
                        [
                            13.030405271708872,
                            42.12005162221544
                        ],
                        [
                            13.033522995436755,
                            42.10741089945856
                        ],
                        [
                            13.023991020194245,
                            42.096636327027596
                        ],
                        [
                            13.019405741920707,
                            42.07522883959899
                        ],
                        [
                            13.023675061882784,
                            42.06369510260754
                        ],
                        [
                            13.030275125499742,
                            42.062013156845325
                        ],
                        [
                            13.02734608105655,
                            42.05025803818663
                        ],
                        [
                            13.047353935444136,
                            42.035854346036075
                        ],
                        [
                            13.049686229028765,
                            42.02387879816831
                        ],
                        [
                            13.055252984081761,
                            42.01638935485386
                        ],
                        [
                            13.068574036130542,
                            42.01154026644568
                        ],
                        [
                            13.09965152420287,
                            42.00776608546039
                        ],
                        [
                            13.092985841148362,
                            42.02053094962986
                        ],
                        [
                            13.101153676351458,
                            42.02871006718621
                        ],
                        [
                            13.127374143266906,
                            42.0182498845368
                        ],
                        [
                            13.144010962638504,
                            42.01453394069438
                        ],
                        [
                            13.161879820511842,
                            42.00158628949961
                        ],
                        [
                            13.184656973381124,
                            41.992729936059476
                        ],
                        [
                            13.194767469219414,
                            41.98675274187633
                        ],
                        [
                            13.214991240764078,
                            41.987889260018704
                        ],
                        [
                            13.230455316503992,
                            41.982765793742345
                        ],
                        [
                            13.238298814906585,
                            41.97412541459582
                        ],
                        [
                            13.253348701544411,
                            41.96733914027706
                        ],
                        [
                            13.26517998899621,
                            41.967861965916796
                        ],
                        [
                            13.28792878602141,
                            41.94937707851645
                        ],
                        [
                            13.303776196365561,
                            41.956412943119545
                        ],
                        [
                            13.32511133421238,
                            41.94423432450506
                        ],
                        [
                            13.335948916220474,
                            41.927314148706756
                        ],
                        [
                            13.352086182100408,
                            41.920051924725215
                        ],
                        [
                            13.365938053528566,
                            41.924932230848185
                        ],
                        [
                            13.386230744073174,
                            41.904739586981634
                        ],
                        [
                            13.377512432817724,
                            41.885601570899354
                        ],
                        [
                            13.358074667705125,
                            41.869635535820805
                        ],
                        [
                            13.366678299050562,
                            41.8655725668969
                        ],
                        [
                            13.376915039454872,
                            41.817651653460445
                        ],
                        [
                            13.390048236296707,
                            41.8186195018819
                        ],
                        [
                            13.397339576319695,
                            41.83444782805617
                        ],
                        [
                            13.414126410900337,
                            41.83781273949745
                        ],
                        [
                            13.45697759876225,
                            41.820411399655086
                        ],
                        [
                            13.465750285319103,
                            41.8099396617219
                        ],
                        [
                            13.480650317373488,
                            41.80759353810839
                        ],
                        [
                            13.482971746276718,
                            41.80384616953285
                        ],
                        [
                            13.503311943444714,
                            41.80306008377255
                        ],
                        [
                            13.511184261063221,
                            41.79911428650868
                        ],
                        [
                            13.512598965088529,
                            41.78842980486701
                        ],
                        [
                            13.522987490146818,
                            41.78049608225629
                        ],
                        [
                            13.519782241213932,
                            41.77265656652071
                        ],
                        [
                            13.543090173579884,
                            41.7633404633645
                        ],
                        [
                            13.559422763102873,
                            41.763918434672604
                        ],
                        [
                            13.573814211532834,
                            41.7549276404452
                        ],
                        [
                            13.584136620268708,
                            41.76551546113774
                        ],
                        [
                            13.603075790795828,
                            41.76987893686848
                        ],
                        [
                            13.61438538135505,
                            41.77919364834119
                        ],
                        [
                            13.631263886017564,
                            41.78210012474605
                        ],
                        [
                            13.641095436967731,
                            41.791515108510794
                        ],
                        [
                            13.635446543754142,
                            41.80344413123157
                        ],
                        [
                            13.64939519053263,
                            41.81324769143055
                        ],
                        [
                            13.677434253992516,
                            41.808110802032616
                        ],
                        [
                            13.686848748089261,
                            41.80011090362129
                        ],
                        [
                            13.688679067651861,
                            41.79294786236463
                        ],
                        [
                            13.706550440238885,
                            41.78750284235363
                        ],
                        [
                            13.71663652614049,
                            41.79729826363004
                        ],
                        [
                            13.728847233359602,
                            41.799843751371014
                        ],
                        [
                            13.729984309599644,
                            41.791394762694125
                        ],
                        [
                            13.733432305644243,
                            41.78681944995594
                        ],
                        [
                            13.743424796905344,
                            41.780909338231
                        ],
                        [
                            13.749086577000753,
                            41.77422519315314
                        ],
                        [
                            13.75071564449027,
                            41.76035868452607
                        ],
                        [
                            13.762233314680685,
                            41.75616702722476
                        ],
                        [
                            13.762047060541164,
                            41.74958489407206
                        ],
                        [
                            13.76776038864648,
                            41.74913807059184
                        ],
                        [
                            13.772098722564351,
                            41.75067656029702
                        ],
                        [
                            13.792043164579866,
                            41.74381819366292
                        ],
                        [
                            13.797277349227357,
                            41.748257101297014
                        ],
                        [
                            13.812856112520393,
                            41.75358519603549
                        ],
                        [
                            13.814161910319001,
                            41.75348603768023
                        ],
                        [
                            13.823442902630148,
                            41.739454322947125
                        ],
                        [
                            13.851201373129385,
                            41.73830434308667
                        ],
                        [
                            13.85664156040528,
                            41.73041778667405
                        ],
                        [
                            13.882139067526879,
                            41.728368085935074
                        ],
                        [
                            13.894793201698064,
                            41.735545927104596
                        ],
                        [
                            13.904326456572903,
                            41.718630889583665
                        ],
                        [
                            13.919135236518514,
                            41.71944861070722
                        ],
                        [
                            13.92624527391851,
                            41.703273489162626
                        ],
                        [
                            13.938482621679128,
                            41.68844105148875
                        ],
                        [
                            13.940345994657008,
                            41.6881826922947
                        ],
                        [
                            13.944076466839391,
                            41.68936246909365
                        ],
                        [
                            13.947366025449071,
                            41.6924810751232
                        ],
                        [
                            13.9484185937755,
                            41.69340607443641
                        ],
                        [
                            13.95533005663907,
                            41.69580752750133
                        ],
                        [
                            13.9675346611196,
                            41.69540251808198
                        ],
                        [
                            13.981159993560823,
                            41.69554695308028
                        ],
                        [
                            13.987604720178638,
                            41.693872055126576
                        ],
                        [
                            13.99340602390717,
                            41.69400744920597
                        ],
                        [
                            13.994973826177642,
                            41.69277637728035
                        ],
                        [
                            14.000436513005923,
                            41.690735832580344
                        ],
                        [
                            14.013136788087536,
                            41.6838482551064
                        ],
                        [
                            14.019168924895553,
                            41.682548520427076
                        ],
                        [
                            14.022282249181075,
                            41.68723026573312
                        ],
                        [
                            14.020529482145974,
                            41.688471396057025
                        ],
                        [
                            14.025234226056114,
                            41.6948133748007
                        ],
                        [
                            14.023634240960803,
                            41.69823714351273
                        ],
                        [
                            14.027210645026997,
                            41.702964803350795
                        ],
                        [
                            14.037706953623825,
                            41.701740840227245
                        ],
                        [
                            14.052934131907875,
                            41.70177922846261
                        ],
                        [
                            14.052826512305018,
                            41.718926238700675
                        ],
                        [
                            14.067414023384202,
                            41.7329526706462
                        ],
                        [
                            14.06962779262409,
                            41.73735507315243
                        ],
                        [
                            14.072017332011683,
                            41.73664063460984
                        ],
                        [
                            14.077732256485746,
                            41.736715206222584
                        ],
                        [
                            14.078423980033222,
                            41.738589599968236
                        ],
                        [
                            14.080405581799056,
                            41.737615961550944
                        ],
                        [
                            14.085839075643321,
                            41.73957525345881
                        ],
                        [
                            14.095046753654287,
                            41.737939232820395
                        ],
                        [
                            14.09480065356081,
                            41.731658306516465
                        ],
                        [
                            14.094488932762204,
                            41.72976896055616
                        ],
                        [
                            14.097057190655704,
                            41.729819311192955
                        ],
                        [
                            14.101213552838516,
                            41.72058124739675
                        ],
                        [
                            14.106214080201653,
                            41.715389712347616
                        ],
                        [
                            14.105377510368697,
                            41.71226672168254
                        ],
                        [
                            14.10727472978246,
                            41.7073472979543
                        ],
                        [
                            14.110722781560128,
                            41.70924190570747
                        ],
                        [
                            14.113528988094629,
                            41.71294462768668
                        ],
                        [
                            14.118825346206194,
                            41.71677493740532
                        ],
                        [
                            14.12079675752733,
                            41.72060951741801
                        ],
                        [
                            14.120780788438879,
                            41.72372374667478
                        ],
                        [
                            14.123843481292795,
                            41.72629111328101
                        ],
                        [
                            14.122393615549754,
                            41.73098654765312
                        ],
                        [
                            14.120543695085559,
                            41.733086642854204
                        ],
                        [
                            14.117102941789739,
                            41.733798191930276
                        ],
                        [
                            14.1151624331942,
                            41.73675408639241
                        ],
                        [
                            14.117213224210332,
                            41.738823777974886
                        ],
                        [
                            14.120251337859628,
                            41.742103514134385
                        ],
                        [
                            14.120313099154828,
                            41.74513396627674
                        ],
                        [
                            14.122543364125448,
                            41.74640478053507
                        ],
                        [
                            14.12590582595493,
                            41.74513297156457
                        ],
                        [
                            14.131939063013562,
                            41.743974174405935
                        ],
                        [
                            14.13513724523446,
                            41.74207628274431
                        ],
                        [
                            14.13797134143806,
                            41.743592126888416
                        ],
                        [
                            14.140851281594252,
                            41.74630799543522
                        ],
                        [
                            14.143358352398451,
                            41.74579612443756
                        ],
                        [
                            14.146374558789375,
                            41.746383642125096
                        ],
                        [
                            14.15175022816905,
                            41.74597360508881
                        ],
                        [
                            14.155399639418931,
                            41.74786687467139
                        ],
                        [
                            14.158704990558391,
                            41.74680900110653
                        ],
                        [
                            14.162537693852215,
                            41.75026790262186
                        ],
                        [
                            14.160681647445728,
                            41.7539093668191
                        ],
                        [
                            14.16369779887006,
                            41.75956876444903
                        ],
                        [
                            14.166154975036534,
                            41.76032982753068
                        ],
                        [
                            14.169016255553004,
                            41.764595712937194
                        ],
                        [
                            14.171611230465004,
                            41.76570320138981
                        ],
                        [
                            14.174956020838636,
                            41.76506817249779
                        ],
                        [
                            14.175849306420758,
                            41.76211534139142
                        ],
                        [
                            14.175099589620885,
                            41.75891476990708
                        ],
                        [
                            14.175755367108167,
                            41.755555866622416
                        ],
                        [
                            14.1763672991583,
                            41.75100213730991
                        ],
                        [
                            14.179187075685746,
                            41.749128906576914
                        ],
                        [
                            14.184534612125333,
                            41.74857407956071
                        ],
                        [
                            14.190107178633298,
                            41.74828691219008
                        ],
                        [
                            14.194362173096778,
                            41.74808436598974
                        ],
                        [
                            14.193750941140536,
                            41.75017080710944
                        ],
                        [
                            14.194248248972372,
                            41.75359952128355
                        ],
                        [
                            14.1945898407389,
                            41.757322815626395
                        ],
                        [
                            14.196735703107834,
                            41.7610047865592
                        ],
                        [
                            14.19958495588591,
                            41.761744109896114
                        ],
                        [
                            14.201037920428917,
                            41.763562982949054
                        ],
                        [
                            14.20230233797082,
                            41.76539463056125
                        ],
                        [
                            14.201863612634924,
                            41.76733421121591
                        ],
                        [
                            14.198927509332975,
                            41.76992672585632
                        ],
                        [
                            14.194786360963121,
                            41.7728070341513
                        ],
                        [
                            14.191361228037323,
                            41.77365868892926
                        ],
                        [
                            14.18699217639724,
                            41.77456906327345
                        ],
                        [
                            14.18165756593092,
                            41.77369888218715
                        ],
                        [
                            14.176425113138237,
                            41.77367232600841
                        ],
                        [
                            14.173077760407153,
                            41.77678247931951
                        ],
                        [
                            14.170463344861986,
                            41.78048405513451
                        ],
                        [
                            14.168845218727512,
                            41.78624938610412
                        ],
                        [
                            14.170295522223995,
                            41.78977390582915
                        ],
                        [
                            14.169744679283383,
                            41.79491303805361
                        ],
                        [
                            14.165615970016745,
                            41.79871214205045
                        ],
                        [
                            14.164418376008712,
                            41.81014273265666
                        ],
                        [
                            14.161805408033189,
                            41.812140380187856
                        ],
                        [
                            14.160570941819856,
                            41.814615770488615
                        ],
                        [
                            14.158716511312377,
                            41.81578993336822
                        ],
                        [
                            14.157172345914688,
                            41.812995289585245
                        ],
                        [
                            14.1546527918062,
                            41.812448515180705
                        ],
                        [
                            14.154537170225979,
                            41.81562827539008
                        ],
                        [
                            14.152113436519528,
                            41.81930610741372
                        ],
                        [
                            14.151394766331691,
                            41.82301983770918
                        ],
                        [
                            14.147021646288067,
                            41.827031824864946
                        ],
                        [
                            14.15082537376503,
                            41.83061056046185
                        ],
                        [
                            14.155800583805643,
                            41.8336265062965
                        ],
                        [
                            14.154775081095067,
                            41.8354572295095
                        ],
                        [
                            14.155795406533514,
                            41.837799789668196
                        ],
                        [
                            14.155185744685241,
                            41.8408090408162
                        ],
                        [
                            14.158745879641486,
                            41.8404506304868
                        ],
                        [
                            14.161890350425068,
                            41.838913883201826
                        ],
                        [
                            14.165560099123724,
                            41.84201893007361
                        ],
                        [
                            14.173939982136744,
                            41.84392008393297
                        ],
                        [
                            14.182975142711655,
                            41.84748497740512
                        ],
                        [
                            14.186293485101743,
                            41.84840870917051
                        ],
                        [
                            14.187607889969087,
                            41.84663336217511
                        ],
                        [
                            14.186996464106073,
                            41.844554405133806
                        ],
                        [
                            14.188145823731503,
                            41.84215502790241
                        ],
                        [
                            14.194878711915209,
                            41.84145343608634
                        ],
                        [
                            14.196674671737725,
                            41.8431060196215
                        ],
                        [
                            14.198045556186031,
                            41.842667809584725
                        ],
                        [
                            14.19878434579843,
                            41.84417510097731
                        ],
                        [
                            14.194179228855376,
                            41.84700274934178
                        ],
                        [
                            14.19553616994628,
                            41.848119367626026
                        ],
                        [
                            14.199646119107939,
                            41.84927834510293
                        ],
                        [
                            14.200841718882259,
                            41.85146639767791
                        ],
                        [
                            14.202708879585629,
                            41.85120995572538
                        ],
                        [
                            14.201748672459928,
                            41.84943120459866
                        ],
                        [
                            14.20271259907102,
                            41.84873556114519
                        ],
                        [
                            14.204285925094212,
                            41.850900651846246
                        ],
                        [
                            14.206303966841546,
                            41.851129872105815
                        ],
                        [
                            14.207556285570234,
                            41.85296261984706
                        ],
                        [
                            14.212510725399994,
                            41.85478144225527
                        ],
                        [
                            14.215816000217586,
                            41.85295031033829
                        ],
                        [
                            14.220588215725002,
                            41.85974202176649
                        ],
                        [
                            14.220328727801302,
                            41.86211273208377
                        ],
                        [
                            14.22208866217673,
                            41.864257792547335
                        ],
                        [
                            14.22388427873176,
                            41.86421560281053
                        ],
                        [
                            14.223871841494741,
                            41.86576786608859
                        ],
                        [
                            14.227795549970153,
                            41.86940326177847
                        ],
                        [
                            14.227084153381583,
                            41.870647694878755
                        ],
                        [
                            14.224699675403343,
                            41.87136100192993
                        ],
                        [
                            14.225574968202665,
                            41.873212829494776
                        ],
                        [
                            14.225185944834323,
                            41.87479067343426
                        ],
                        [
                            14.227769714471407,
                            41.87498356303294
                        ],
                        [
                            14.230676178238951,
                            41.87798364580087
                        ],
                        [
                            14.238848796874265,
                            41.87882243778148
                        ],
                        [
                            14.246445557769732,
                            41.882814026621816
                        ],
                        [
                            14.247263519586,
                            41.88578433717677
                        ],
                        [
                            14.251340692991533,
                            41.88678797671912
                        ],
                        [
                            14.253352659488655,
                            41.89206992870075
                        ],
                        [
                            14.259304645542471,
                            41.89204417191522
                        ],
                        [
                            14.263365267360996,
                            41.89614934300727
                        ],
                        [
                            14.269101346603435,
                            41.896632475523006
                        ],
                        [
                            14.268699913151778,
                            41.89975691802749
                        ],
                        [
                            14.279782957603004,
                            41.90957356295519
                        ],
                        [
                            14.284180555413542,
                            41.910642484873165
                        ],
                        [
                            14.285431144815703,
                            41.90830445775521
                        ],
                        [
                            14.29096741266818,
                            41.90358105382225
                        ],
                        [
                            14.291166048100365,
                            41.902015405014
                        ],
                        [
                            14.298178158850419,
                            41.90016419002231
                        ],
                        [
                            14.302454682064251,
                            41.896859628772006
                        ],
                        [
                            14.302520839123986,
                            41.89417312137843
                        ],
                        [
                            14.304467769583653,
                            41.892921111348954
                        ],
                        [
                            14.314295946567313,
                            41.893077488822684
                        ],
                        [
                            14.323446188491772,
                            41.89621250618475
                        ],
                        [
                            14.327733936518626,
                            41.88733988104215
                        ],
                        [
                            14.33437952444416,
                            41.87823101398851
                        ],
                        [
                            14.343068333465482,
                            41.86948784250308
                        ],
                        [
                            14.342973544055695,
                            41.86279963234901
                        ],
                        [
                            14.35144196257798,
                            41.86237629603656
                        ],
                        [
                            14.358390568585463,
                            41.86031518330353
                        ],
                        [
                            14.364957862387541,
                            41.869443927502715
                        ],
                        [
                            14.361302749237467,
                            41.87402323473489
                        ],
                        [
                            14.376207789179944,
                            41.87990032107811
                        ],
                        [
                            14.378229206143402,
                            41.866976025339255
                        ],
                        [
                            14.383462915650824,
                            41.865893931633025
                        ],
                        [
                            14.390040636439437,
                            41.86770626583515
                        ],
                        [
                            14.394436512110076,
                            41.870903090052565
                        ],
                        [
                            14.401321215641104,
                            41.86809922658409
                        ],
                        [
                            14.404819072309522,
                            41.86501541513411
                        ],
                        [
                            14.40978692206815,
                            41.86444687835473
                        ],
                        [
                            14.410096139279176,
                            41.861582958775955
                        ],
                        [
                            14.415155181786929,
                            41.85902483237132
                        ],
                        [
                            14.418014466371261,
                            41.856231834127186
                        ],
                        [
                            14.426643840798107,
                            41.852700671923714
                        ],
                        [
                            14.426468146159777,
                            41.84823349381685
                        ],
                        [
                            14.431133063277798,
                            41.84221658084846
                        ],
                        [
                            14.429634995357333,
                            41.84081994363345
                        ],
                        [
                            14.431779713778525,
                            41.84055901019684
                        ],
                        [
                            14.44194694622604,
                            41.84165283711195
                        ],
                        [
                            14.446022786883788,
                            41.83778590119323
                        ],
                        [
                            14.445643126362597,
                            41.83594452899803
                        ],
                        [
                            14.448768596212233,
                            41.82965186095012
                        ],
                        [
                            14.44709736546946,
                            41.82677575491803
                        ],
                        [
                            14.451550853208516,
                            41.820400275527504
                        ],
                        [
                            14.449917412546455,
                            41.81205973677979
                        ],
                        [
                            14.455281947217117,
                            41.80460964222058
                        ],
                        [
                            14.45298157309901,
                            41.80202401723898
                        ],
                        [
                            14.455408968471431,
                            41.7925541008448
                        ],
                        [
                            14.466736211532435,
                            41.77767172336638
                        ],
                        [
                            14.460551557109824,
                            41.77472587856548
                        ],
                        [
                            14.456628391736103,
                            41.768164355691766
                        ],
                        [
                            14.454314345729188,
                            41.76397974389897
                        ],
                        [
                            14.457098462413455,
                            41.76206266123239
                        ],
                        [
                            14.462568603104984,
                            41.76318990021721
                        ],
                        [
                            14.470429472443413,
                            41.76329336513035
                        ],
                        [
                            14.481205930380526,
                            41.761097921315496
                        ],
                        [
                            14.485558215291434,
                            41.75969590346773
                        ],
                        [
                            14.49280810476995,
                            41.763064748315855
                        ],
                        [
                            14.495915836000563,
                            41.76819748732564
                        ],
                        [
                            14.499358281837942,
                            41.773311812688206
                        ],
                        [
                            14.503346808455632,
                            41.77427353996899
                        ],
                        [
                            14.507606900248732,
                            41.777844724508185
                        ],
                        [
                            14.516104763723632,
                            41.78039797466147
                        ],
                        [
                            14.51994531768409,
                            41.784622820389075
                        ],
                        [
                            14.52963500317577,
                            41.78722914476148
                        ],
                        [
                            14.53419964034643,
                            41.7932800972674
                        ],
                        [
                            14.533053959424706,
                            41.797834825748396
                        ],
                        [
                            14.544378806664582,
                            41.809942543059435
                        ],
                        [
                            14.55244171185015,
                            41.817038217392565
                        ],
                        [
                            14.563693388034778,
                            41.81969568976854
                        ],
                        [
                            14.568132758700216,
                            41.81879039868363
                        ],
                        [
                            14.578149262244153,
                            41.82957522425596
                        ],
                        [
                            14.578757178707207,
                            41.8333937711306
                        ],
                        [
                            14.583395850706182,
                            41.83583724858758
                        ],
                        [
                            14.584509448299116,
                            41.841121111068134
                        ],
                        [
                            14.59067656088962,
                            41.84658717429781
                        ],
                        [
                            14.598516621428985,
                            41.85195338846893
                        ],
                        [
                            14.598915259650852,
                            41.86116145171758
                        ],
                        [
                            14.611488349393483,
                            41.880073050612054
                        ],
                        [
                            14.614263802974932,
                            41.88961059976273
                        ],
                        [
                            14.626781337990195,
                            41.896304274649424
                        ],
                        [
                            14.641700209651248,
                            41.90036326133435
                        ],
                        [
                            14.642891633763554,
                            41.9047299316569
                        ],
                        [
                            14.649971052296138,
                            41.903661400122104
                        ],
                        [
                            14.65508501573865,
                            41.91168000123213
                        ],
                        [
                            14.664678299484052,
                            41.916311329808025
                        ],
                        [
                            14.668583715742756,
                            41.93552617581892
                        ],
                        [
                            14.665134603049864,
                            41.94313174412079
                        ],
                        [
                            14.691247049884964,
                            41.96910631633233
                        ],
                        [
                            14.700069247620888,
                            41.97279476189317
                        ],
                        [
                            14.699327326073387,
                            41.97508489157062
                        ],
                        [
                            14.724880878086196,
                            42.00091623109708
                        ],
                        [
                            14.733870860248317,
                            42.007021790498925
                        ],
                        [
                            14.746241161258098,
                            42.00811509747368
                        ],
                        [
                            14.755951733752141,
                            42.01510522283991
                        ],
                        [
                            14.76452468271475,
                            42.01656626082874
                        ],
                        [
                            14.770466975579296,
                            42.02170395048685
                        ],
                        [
                            14.782179942340719,
                            42.03225832901373
                        ],
                        [
                            14.78251507645254,
                            42.035216983974834
                        ],
                        [
                            14.776625529231126,
                            42.03632921274166
                        ],
                        [
                            14.772633764352605,
                            42.040923157631624
                        ],
                        [
                            14.760972377527423,
                            42.04115189340439
                        ],
                        [
                            14.76545007987545,
                            42.04832301314892
                        ],
                        [
                            14.763737141551275,
                            42.050913061287105
                        ],
                        [
                            14.77069425772416,
                            42.057815358245335
                        ],
                        [
                            14.771153938135768,
                            42.0633824118558
                        ],
                        [
                            14.779519281058896,
                            42.06934009675169
                        ],
                        [
                            14.756896342979587,
                            42.07781239680284
                        ],
                        [
                            14.7299108379394,
                            42.09338212041456
                        ],
                        [
                            14.717338913878962,
                            42.10803774960473
                        ],
                        [
                            14.720839822640272,
                            42.115267491584376
                        ],
                        [
                            14.718478443489914,
                            42.11951154532281
                        ],
                        [
                            14.722382201738213,
                            42.125854919857204
                        ],
                        [
                            14.719179078956842,
                            42.13301233952885
                        ],
                        [
                            14.71882798235211,
                            42.13875380545238
                        ],
                        [
                            14.716433121728045,
                            42.150097414170176
                        ],
                        [
                            14.720481935017574,
                            42.15769671826337
                        ],
                        [
                            14.717402955509925,
                            42.16747601773338
                        ],
                        [
                            14.71578647655889,
                            42.17243436143224
                        ],
                        [
                            14.705937008113551,
                            42.17426984312567
                        ],
                        [
                            14.69886034372891,
                            42.17427977065239
                        ],
                        [
                            14.665311460513408,
                            42.185534814043024
                        ],
                        [
                            14.638147516466404,
                            42.19365316611419
                        ],
                        [
                            14.599622497223606,
                            42.20716375191935
                        ],
                        [
                            14.552531797092144,
                            42.22963294127908
                        ],
                        [
                            14.5235536990007,
                            42.243940455144184
                        ],
                        [
                            14.501815006850535,
                            42.257044543870165
                        ],
                        [
                            14.500335429812367,
                            42.266177891722094
                        ],
                        [
                            14.50193816185535,
                            42.27153357249429
                        ],
                        [
                            14.493685093141494,
                            42.27687014227129
                        ],
                        [
                            14.480427690522447,
                            42.28821152812452
                        ],
                        [
                            14.450451030330356,
                            42.30608473003991
                        ],
                        [
                            14.430255253461809,
                            42.32106505567276
                        ],
                        [
                            14.423866930581482,
                            42.33097570460549
                        ],
                        [
                            14.425467303309148,
                            42.33470579135951
                        ],
                        [
                            14.418247675501249,
                            42.33886334809412
                        ],
                        [
                            14.408741318603973,
                            42.349341728502225
                        ],
                        [
                            14.409502446270835,
                            42.35894096211561
                        ],
                        [
                            14.40050851168482,
                            42.36221569457655
                        ],
                        [
                            14.394163425665369,
                            42.36965664452029
                        ],
                        [
                            14.384579820738338,
                            42.37506777408046
                        ],
                        [
                            14.37643660146739,
                            42.378409810204744
                        ],
                        [
                            14.369360436398296,
                            42.38081984253742
                        ],
                        [
                            14.334771991724978,
                            42.39797054198081
                        ],
                        [
                            14.310807633548592,
                            42.41056521309153
                        ],
                        [
                            14.285300572319017,
                            42.4255883638034
                        ],
                        [
                            14.258764058582841,
                            42.440167851580156
                        ],
                        [
                            14.233052751310368,
                            42.46066107613595
                        ],
                        [
                            14.228163247936692,
                            42.46762243232956
                        ],
                        [
                            14.21718458187513,
                            42.472366499352546
                        ],
                        [
                            14.18835977693871,
                            42.491886606811704
                        ],
                        [
                            14.163874115675867,
                            42.51463809523801
                        ],
                        [
                            14.149572194181076,
                            42.52846300256243
                        ],
                        [
                            14.120134989656862,
                            42.554277924169895
                        ]
                    ]
                ],
                "type": "Polygon"
            },
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Molise",
                image: 'https://www.italiarail.com/sites/default/files/inline-images/molise_0.jpg'
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            14.796427535549185,
                            42.063674548882744
                        ],
                        [
                            14.780830258346327,
                            42.06854302598356
                        ],
                        [
                            14.772940480738896,
                            42.06198208891078
                        ],
                        [
                            14.773703001385968,
                            42.05848870949325
                        ],
                        [
                            14.76475052463573,
                            42.05099619860982
                        ],
                        [
                            14.76739676846239,
                            42.047103110473586
                        ],
                        [
                            14.763927860089467,
                            42.04200796551808
                        ],
                        [
                            14.773985499484041,
                            42.042250603317285
                        ],
                        [
                            14.778210227571691,
                            42.03696914201561
                        ],
                        [
                            14.783719674783327,
                            42.036199334780605
                        ],
                        [
                            14.783006632866062,
                            42.02993126063376
                        ],
                        [
                            14.772666828775698,
                            42.022112438988444
                        ],
                        [
                            14.767267193356446,
                            42.01528409494949
                        ],
                        [
                            14.75709508171829,
                            42.01391279354863
                        ],
                        [
                            14.747867369100277,
                            42.005472245840934
                        ],
                        [
                            14.734477371082136,
                            42.00422335841904
                        ],
                        [
                            14.724373500647715,
                            41.998942472639214
                        ],
                        [
                            14.70008122567944,
                            41.974431923478534
                        ],
                        [
                            14.7020890235124,
                            41.9717232129216
                        ],
                        [
                            14.691561918702178,
                            41.96750010280502
                        ],
                        [
                            14.668203589151574,
                            41.94190255121521
                        ],
                        [
                            14.671781693222073,
                            41.93433807784734
                        ],
                        [
                            14.666759500171423,
                            41.91450708771501
                        ],
                        [
                            14.654948797910663,
                            41.90949607259421
                        ],
                        [
                            14.64948027718222,
                            41.9023692141985
                        ],
                        [
                            14.643754022834656,
                            41.90301627656342
                        ],
                        [
                            14.640156915626989,
                            41.89864852990743
                        ],
                        [
                            14.61522020425889,
                            41.88831804637377
                        ],
                        [
                            14.614178207874346,
                            41.87921303787144
                        ],
                        [
                            14.600804480688907,
                            41.8607534673375
                        ],
                        [
                            14.601902338903557,
                            41.851907876234975
                        ],
                        [
                            14.58627967424431,
                            41.840817397998904
                        ],
                        [
                            14.584990223854106,
                            41.83472181323111
                        ],
                        [
                            14.580434743255864,
                            41.8321389400966
                        ],
                        [
                            14.579312826218086,
                            41.827472575107606
                        ],
                        [
                            14.569792480009852,
                            41.81703234302651
                        ],
                        [
                            14.563263461901698,
                            41.81902773976827
                        ],
                        [
                            14.55218358235797,
                            41.81500645229764
                        ],
                        [
                            14.534610521867108,
                            41.79795027395261
                        ],
                        [
                            14.53469261224609,
                            41.79193569567599
                        ],
                        [
                            14.533128351015336,
                            41.78498530474582
                        ],
                        [
                            14.520173063267322,
                            41.783789933840865
                        ],
                        [
                            14.518828754329121,
                            41.778980232825006
                        ],
                        [
                            14.510155633608662,
                            41.77780454265681
                        ],
                        [
                            14.507409254452,
                            41.77423853683513
                        ],
                        [
                            14.49980552634733,
                            41.770552537665395
                        ],
                        [
                            14.494154280721517,
                            41.76200185009509
                        ],
                        [
                            14.486035590833808,
                            41.758787406435545
                        ],
                        [
                            14.466095884526112,
                            41.761933983802095
                        ],
                        [
                            14.456242070062387,
                            41.76026996231687
                        ],
                        [
                            14.452891247812317,
                            41.76636913692903
                        ],
                        [
                            14.457200417670578,
                            41.773559440250864
                        ],
                        [
                            14.46543792354646,
                            41.77791834070254
                        ],
                        [
                            14.448647848742752,
                            41.79959011318081
                        ],
                        [
                            14.453579565580696,
                            41.80530777383919
                        ],
                        [
                            14.44778726580671,
                            41.81041911814688
                        ],
                        [
                            14.450563502034129,
                            41.82087947023581
                        ],
                        [
                            14.445186619947151,
                            41.82625304486456
                        ],
                        [
                            14.447578197364294,
                            41.830129007031445
                        ],
                        [
                            14.443688246378827,
                            41.83828713193202
                        ],
                        [
                            14.441228810493925,
                            41.8404563634069
                        ],
                        [
                            14.42721767608603,
                            41.839898819749436
                        ],
                        [
                            14.428736546073281,
                            41.842967897287764
                        ],
                        [
                            14.424830044421753,
                            41.847672924192295
                        ],
                        [
                            14.425394540531386,
                            41.85253010889522
                        ],
                        [
                            14.418146131140986,
                            41.85514180653189
                        ],
                        [
                            14.41554464509997,
                            41.85775066160872
                        ],
                        [
                            14.408287880400593,
                            41.86194473997378
                        ],
                        [
                            14.408903315808487,
                            41.863921305769765
                        ],
                        [
                            14.403831265385207,
                            41.86524428328241
                        ],
                        [
                            14.395516759946986,
                            41.86961531134128
                        ],
                        [
                            14.386340912877614,
                            41.86413378958676
                        ],
                        [
                            14.377094126343138,
                            41.866293595873515
                        ],
                        [
                            14.374786059670072,
                            41.87810590551908
                        ],
                        [
                            14.363766453335131,
                            41.87331418373907
                        ],
                        [
                            14.366455322764494,
                            41.86810722166095
                        ],
                        [
                            14.358025240246747,
                            41.85884534945521
                        ],
                        [
                            14.341805007548516,
                            41.86244710268679
                        ],
                        [
                            14.340915048427462,
                            41.86983889847633
                        ],
                        [
                            14.32772219749458,
                            41.88461496478709
                        ],
                        [
                            14.32185761424816,
                            41.89418936754606
                        ],
                        [
                            14.313373885257647,
                            41.89096876737264
                        ],
                        [
                            14.305318412903148,
                            41.89146484176649
                        ],
                        [
                            14.3014379241107,
                            41.89299860589503
                        ],
                        [
                            14.300436136522734,
                            41.89608171697003
                        ],
                        [
                            14.296750086767815,
                            41.89932986109764
                        ],
                        [
                            14.289420356514768,
                            41.90107448326373
                        ],
                        [
                            14.28795571758431,
                            41.90519314121164
                        ],
                        [
                            14.282242655900689,
                            41.90928402342706
                        ],
                        [
                            14.27711569126123,
                            41.90657587905906
                        ],
                        [
                            14.27057217996716,
                            41.901344626426464
                        ],
                        [
                            14.270062381335379,
                            41.89516712425919
                        ],
                        [
                            14.264441497566054,
                            41.89507332792655
                        ],
                        [
                            14.26283311099749,
                            41.891129971823915
                        ],
                        [
                            14.255614752950038,
                            41.89055675708178
                        ],
                        [
                            14.255160325946065,
                            41.886545860610994
                        ],
                        [
                            14.249111650041556,
                            41.884317397533096
                        ],
                        [
                            14.244614445779973,
                            41.88041062030007
                        ],
                        [
                            14.238442884162907,
                            41.8770401420291
                        ],
                        [
                            14.231511486953309,
                            41.87717448016025
                        ],
                        [
                            14.227022736499833,
                            41.87327235664986
                        ],
                        [
                            14.229128467826314,
                            41.87141605115272
                        ],
                        [
                            14.229190314836785,
                            41.86853312061862
                        ],
                        [
                            14.225638225798406,
                            41.86331116764438
                        ],
                        [
                            14.221951525103634,
                            41.86151030052602
                        ],
                        [
                            14.222667953815318,
                            41.85930037750313
                        ],
                        [
                            14.217115547506182,
                            41.85126041373138
                        ],
                        [
                            14.2111996186118,
                            41.853639059096395
                        ],
                        [
                            14.207694086181675,
                            41.85168610781113
                        ],
                        [
                            14.204619839525321,
                            41.84840924112669
                        ],
                        [
                            14.200815871486157,
                            41.84892830723345
                        ],
                        [
                            14.196099039633367,
                            41.846473820118376
                        ],
                        [
                            14.20028145254912,
                            41.842470309149235
                        ],
                        [
                            14.196124815511212,
                            41.839839485181244
                        ],
                        [
                            14.187151448394275,
                            41.842403644049085
                        ],
                        [
                            14.184040755006805,
                            41.8473496879173
                        ],
                        [
                            14.167811522516445,
                            41.84185122666341
                        ],
                        [
                            14.160542220220037,
                            41.838837694556446
                        ],
                        [
                            14.156318263038742,
                            41.84067895390564
                        ],
                        [
                            14.15699264839796,
                            41.83432970227312
                        ],
                        [
                            14.148871375829941,
                            41.82729144498907
                        ],
                        [
                            14.152369909693192,
                            41.822312287951604
                        ],
                        [
                            14.15631442259965,
                            41.814416224804546
                        ],
                        [
                            14.158520543956882,
                            41.8168857301018
                        ],
                        [
                            14.162403671170836,
                            41.81535254498928
                        ],
                        [
                            14.166557184218647,
                            41.811056579762635
                        ],
                        [
                            14.167878781836919,
                            41.80389619245332
                        ],
                        [
                            14.169360770137502,
                            41.79817271765347
                        ],
                        [
                            14.173336846188306,
                            41.794032700955256
                        ],
                        [
                            14.172803488121048,
                            41.787566691117235
                        ],
                        [
                            14.172094803516188,
                            41.781258189033
                        ],
                        [
                            14.177408927418156,
                            41.7753061373912
                        ],
                        [
                            14.182985572696396,
                            41.775110276425124
                        ],
                        [
                            14.18816672992952,
                            41.776526546222584
                        ],
                        [
                            14.19617421207036,
                            41.77387262633178
                        ],
                        [
                            14.204112029655647,
                            41.76717940383725
                        ],
                        [
                            14.201509065945515,
                            41.761276680449015
                        ],
                        [
                            14.19827656706397,
                            41.76003218327631
                        ],
                        [
                            14.195312039786046,
                            41.75603086385644
                        ],
                        [
                            14.195654006104633,
                            41.746872117737325
                        ],
                        [
                            14.175144181955233,
                            41.74885707949298
                        ],
                        [
                            14.173771999628855,
                            41.75544348526995
                        ],
                        [
                            14.174060259573452,
                            41.76467709059477
                        ],
                        [
                            14.170431592531372,
                            41.76345303121684
                        ],
                        [
                            14.168221759380117,
                            41.75939657322647
                        ],
                        [
                            14.165320480444137,
                            41.75942973810467
                        ],
                        [
                            14.162211754223534,
                            41.754274728081356
                        ],
                        [
                            14.164540890287299,
                            41.75095388964297
                        ],
                        [
                            14.16018179084287,
                            41.745013153870076
                        ],
                        [
                            14.156009167315034,
                            41.74743588051345
                        ],
                        [
                            14.153968588151628,
                            41.74481904246582
                        ],
                        [
                            14.1486240893083,
                            41.74543741421655
                        ],
                        [
                            14.140671595345452,
                            41.74520660989208
                        ],
                        [
                            14.135336667533295,
                            41.740776149643864
                        ],
                        [
                            14.131377043230202,
                            41.74332835611176
                        ],
                        [
                            14.12299118428632,
                            41.746009270677206
                        ],
                        [
                            14.120768927063438,
                            41.74513714562963
                        ],
                        [
                            14.120731135834262,
                            41.741391224454674
                        ],
                        [
                            14.115789779953047,
                            41.736941302462355
                        ],
                        [
                            14.118037852140674,
                            41.73464055020756
                        ],
                        [
                            14.122156188920115,
                            41.73352035906191
                        ],
                        [
                            14.125561510172759,
                            41.72610492337242
                        ],
                        [
                            14.121964135257059,
                            41.72330456001035
                        ],
                        [
                            14.120901114361175,
                            41.71732357319371
                        ],
                        [
                            14.113696052662391,
                            41.71164679506174
                        ],
                        [
                            14.11155034417618,
                            41.708164402605064
                        ],
                        [
                            14.106142042555689,
                            41.706618789944315
                        ],
                        [
                            14.104187022407757,
                            41.71150898858494
                        ],
                        [
                            14.105979425390188,
                            41.71530222797125
                        ],
                        [
                            14.095923138241147,
                            41.728933819438936
                        ],
                        [
                            14.092219689464457,
                            41.72872595783221
                        ],
                        [
                            14.09362467199088,
                            41.7376098794189
                        ],
                        [
                            14.086381706475493,
                            41.73862881945547
                        ],
                        [
                            14.078924298167777,
                            41.73604456760248
                        ],
                        [
                            14.070228777201152,
                            41.736139949410045
                        ],
                        [
                            14.067402063000031,
                            41.731686276468736
                        ],
                        [
                            14.053462113856028,
                            41.71780607796671
                        ],
                        [
                            14.054266288226728,
                            41.70101826632316
                        ],
                        [
                            14.027771233070126,
                            41.70208289832922
                        ],
                        [
                            14.024914855522638,
                            41.69894587487775
                        ],
                        [
                            14.025513233762894,
                            41.69428146615215
                        ],
                        [
                            14.021575803669634,
                            41.688577659567414
                        ],
                        [
                            14.023894855711234,
                            41.68684403884842
                        ],
                        [
                            14.020024373362531,
                            41.68171902366345
                        ],
                        [
                            14.013350000580004,
                            41.6825576112048
                        ],
                        [
                            13.991974857458729,
                            41.6935489710134
                        ],
                        [
                            13.987384849349922,
                            41.692233381263804
                        ],
                        [
                            13.981536946805136,
                            41.69519107937262
                        ],
                        [
                            13.95528404618122,
                            41.69503692984324
                        ],
                        [
                            13.949191404327195,
                            41.69222371082415
                        ],
                        [
                            13.945127381769709,
                            41.68856698070266
                        ],
                        [
                            13.940616952526625,
                            41.68782844198833
                        ],
                        [
                            13.985330686667112,
                            41.66184480409649
                        ],
                        [
                            13.98857398193536,
                            41.64604665389629
                        ],
                        [
                            13.998860811320071,
                            41.63245896256001
                        ],
                        [
                            13.998253771957877,
                            41.57835883415473
                        ],
                        [
                            14.005901521808397,
                            41.57165787748025
                        ],
                        [
                            14.00503800998186,
                            41.567352748290716
                        ],
                        [
                            14.019568757906882,
                            41.56430716560189
                        ],
                        [
                            14.026267802861128,
                            41.560275610823425
                        ],
                        [
                            14.027879484098037,
                            41.555676894998925
                        ],
                        [
                            14.024644818824243,
                            41.550934582206565
                        ],
                        [
                            14.025649702148286,
                            41.547825889206536
                        ],
                        [
                            14.02193665008167,
                            41.54224517149345
                        ],
                        [
                            14.013723113007387,
                            41.54129060159366
                        ],
                        [
                            14.009026385426722,
                            41.53722439318298
                        ],
                        [
                            14.01686438249476,
                            41.5296455074141
                        ],
                        [
                            14.029449763990982,
                            41.524820966604636
                        ],
                        [
                            14.011446064898166,
                            41.513893926032864
                        ],
                        [
                            13.99501956607699,
                            41.511715834373604
                        ],
                        [
                            13.978652519520296,
                            41.49941742261018
                        ],
                        [
                            13.974489234075918,
                            41.49302777193671
                        ],
                        [
                            13.994867699754195,
                            41.48251677432236
                        ],
                        [
                            13.980999555549232,
                            41.468365793490676
                        ],
                        [
                            13.980273191731783,
                            41.46177475541555
                        ],
                        [
                            13.988082425627567,
                            41.45767732156071
                        ],
                        [
                            13.993951640523477,
                            41.456874998606935
                        ],
                        [
                            14.008576203683077,
                            41.4517688265027
                        ],
                        [
                            14.010994279611992,
                            41.4458446117483
                        ],
                        [
                            14.010339476894046,
                            41.43824156182211
                        ],
                        [
                            14.003513728481408,
                            41.43045878811418
                        ],
                        [
                            14.007584313050899,
                            41.425587797500015
                        ],
                        [
                            14.012101271520521,
                            41.42472282388789
                        ],
                        [
                            14.017956763961678,
                            41.425501775440665
                        ],
                        [
                            14.024016481481794,
                            41.41949312519259
                        ],
                        [
                            14.028822346189429,
                            41.417744023685316
                        ],
                        [
                            14.033036023502632,
                            41.41242946592317
                        ],
                        [
                            14.029797932178838,
                            41.405866135788386
                        ],
                        [
                            14.035256329855116,
                            41.40479414920816
                        ],
                        [
                            14.040122244818406,
                            41.400158595432885
                        ],
                        [
                            14.03932136979978,
                            41.3964655383933
                        ],
                        [
                            14.043901150025022,
                            41.39271276614545
                        ],
                        [
                            14.048823173853549,
                            41.39369015625485
                        ],
                        [
                            14.053831134222094,
                            41.397111822137674
                        ],
                        [
                            14.062418523636495,
                            41.39833193484196
                        ],
                        [
                            14.07129829950594,
                            41.397030569068875
                        ],
                        [
                            14.076784858455682,
                            41.39968694056401
                        ],
                        [
                            14.087664408645452,
                            41.40069780079898
                        ],
                        [
                            14.09216263832704,
                            41.39795928098627
                        ],
                        [
                            14.09567092205046,
                            41.39327340052182
                        ],
                        [
                            14.10055139586521,
                            41.390509123002516
                        ],
                        [
                            14.10553743811792,
                            41.39204948279814
                        ],
                        [
                            14.105802021144683,
                            41.38930101456643
                        ],
                        [
                            14.110988847494639,
                            41.387521908224585
                        ],
                        [
                            14.113269862870908,
                            41.38895288309644
                        ],
                        [
                            14.10749616000274,
                            41.39910994865576
                        ],
                        [
                            14.101816059736791,
                            41.401639385277946
                        ],
                        [
                            14.105030815685694,
                            41.40790189694454
                        ],
                        [
                            14.10478600929477,
                            41.41252234319825
                        ],
                        [
                            14.107568017684429,
                            41.41665911342008
                        ],
                        [
                            14.105443557308547,
                            41.42169206557614
                        ],
                        [
                            14.097115467024594,
                            41.42467983163644
                        ],
                        [
                            14.099176581090546,
                            41.427569943663855
                        ],
                        [
                            14.096357348962556,
                            41.42818441956868
                        ],
                        [
                            14.091627108449927,
                            41.428922496414316
                        ],
                        [
                            14.09027887553691,
                            41.432321297117056
                        ],
                        [
                            14.07637811765261,
                            41.44560196671594
                        ],
                        [
                            14.078955880615041,
                            41.45653102065563
                        ],
                        [
                            14.086130249803972,
                            41.45817067349003
                        ],
                        [
                            14.085920684980238,
                            41.461486608447416
                        ],
                        [
                            14.082702027749832,
                            41.461838181072295
                        ],
                        [
                            14.079622766815476,
                            41.4651959314871
                        ],
                        [
                            14.085930979400928,
                            41.46679971361476
                        ],
                        [
                            14.08318642644872,
                            41.467982031557455
                        ],
                        [
                            14.09125748967358,
                            41.46990450603249
                        ],
                        [
                            14.092302542559025,
                            41.47242386865963
                        ],
                        [
                            14.097264349096235,
                            41.48087472063807
                        ],
                        [
                            14.10390192506901,
                            41.48188814643646
                        ],
                        [
                            14.104011994207099,
                            41.48461431246622
                        ],
                        [
                            14.111670342856968,
                            41.48786667947209
                        ],
                        [
                            14.111303927067667,
                            41.49321604305439
                        ],
                        [
                            14.117919132438288,
                            41.497403581480825
                        ],
                        [
                            14.12014773711536,
                            41.507059522391756
                        ],
                        [
                            14.126530789351534,
                            41.507376891331205
                        ],
                        [
                            14.136985640515007,
                            41.49590486648847
                        ],
                        [
                            14.149561664729362,
                            41.48789946728755
                        ],
                        [
                            14.152662437643045,
                            41.48294649990058
                        ],
                        [
                            14.165265828241843,
                            41.48026799575072
                        ],
                        [
                            14.164778824632862,
                            41.48447913321297
                        ],
                        [
                            14.167463954772614,
                            41.48776817721162
                        ],
                        [
                            14.165478298315179,
                            41.492364917245055
                        ],
                        [
                            14.17430593768185,
                            41.50219346949544
                        ],
                        [
                            14.179006441316432,
                            41.497710226060434
                        ],
                        [
                            14.184897573346376,
                            41.49690387316207
                        ],
                        [
                            14.187042807218376,
                            41.498789043697144
                        ],
                        [
                            14.189848368496683,
                            41.496301354600064
                        ],
                        [
                            14.19247622465133,
                            41.49902260445157
                        ],
                        [
                            14.201263614525232,
                            41.49879189017307
                        ],
                        [
                            14.205725220021662,
                            41.50111500316507
                        ],
                        [
                            14.208462023875683,
                            41.49806032271425
                        ],
                        [
                            14.212597048086309,
                            41.499104870060194
                        ],
                        [
                            14.219172300105612,
                            41.49610200241969
                        ],
                        [
                            14.22789246534515,
                            41.49874178564227
                        ],
                        [
                            14.244252576496024,
                            41.497446885329566
                        ],
                        [
                            14.2432906540094,
                            41.49389449460028
                        ],
                        [
                            14.246472903977065,
                            41.491387219769024
                        ],
                        [
                            14.249480937843998,
                            41.485432727033555
                        ],
                        [
                            14.264258846636295,
                            41.48394673314357
                        ],
                        [
                            14.268724346660804,
                            41.48627149928183
                        ],
                        [
                            14.281625085605185,
                            41.486779518470456
                        ],
                        [
                            14.28400220104129,
                            41.48215480583306
                        ],
                        [
                            14.294114819463374,
                            41.47633136996754
                        ],
                        [
                            14.2920412596022,
                            41.473137106513946
                        ],
                        [
                            14.286663521893388,
                            41.471303333591976
                        ],
                        [
                            14.29163658596292,
                            41.46940741941114
                        ],
                        [
                            14.299766172115255,
                            41.470059855992446
                        ],
                        [
                            14.313251715451003,
                            41.46344681723156
                        ],
                        [
                            14.321080341115703,
                            41.45776052016893
                        ],
                        [
                            14.330275810437598,
                            41.454010399696784
                        ],
                        [
                            14.345758282738302,
                            41.45388585307049
                        ],
                        [
                            14.360756340389543,
                            41.449063616985654
                        ],
                        [
                            14.391978816498806,
                            41.44338279599941
                        ],
                        [
                            14.402127287690803,
                            41.43784088085579
                        ],
                        [
                            14.412759424744195,
                            41.44195677962301
                        ],
                        [
                            14.41906219839828,
                            41.439831982485174
                        ],
                        [
                            14.421917339184233,
                            41.43256768373291
                        ],
                        [
                            14.429381743868191,
                            41.432250067792836
                        ],
                        [
                            14.42812603477904,
                            41.42957959618954
                        ],
                        [
                            14.442410028539598,
                            41.42720915830918
                        ],
                        [
                            14.456179297718734,
                            41.4288143137849
                        ],
                        [
                            14.466673805149924,
                            41.419498305235464
                        ],
                        [
                            14.471018029558877,
                            41.420673789797206
                        ],
                        [
                            14.477813371319428,
                            41.41967505154966
                        ],
                        [
                            14.483668862633635,
                            41.41353246865614
                        ],
                        [
                            14.482389086431738,
                            41.41057833951842
                        ],
                        [
                            14.478773589154457,
                            41.40907013743538
                        ],
                        [
                            14.48063536072965,
                            41.40678800352515
                        ],
                        [
                            14.485813343495977,
                            41.405021097446365
                        ],
                        [
                            14.483596758283937,
                            41.40227137388476
                        ],
                        [
                            14.487125847494639,
                            41.39945196546947
                        ],
                        [
                            14.48637068505991,
                            41.39603445330533
                        ],
                        [
                            14.490380276554248,
                            41.390586866173265
                        ],
                        [
                            14.501449237512986,
                            41.38830388742349
                        ],
                        [
                            14.505633522100197,
                            41.38269972863077
                        ],
                        [
                            14.510463819586562,
                            41.38124063875907
                        ],
                        [
                            14.514798448500159,
                            41.38399942166885
                        ],
                        [
                            14.525499721873246,
                            41.38603518920908
                        ],
                        [
                            14.535868801218783,
                            41.382792537870074
                        ],
                        [
                            14.548216791819726,
                            41.38188087542795
                        ],
                        [
                            14.55318483657777,
                            41.37998314132173
                        ],
                        [
                            14.556499768263535,
                            41.38584555002487
                        ],
                        [
                            14.560987996895761,
                            41.386579016959445
                        ],
                        [
                            14.566562303674857,
                            41.39201706055667
                        ],
                        [
                            14.578018039682291,
                            41.389717881428226
                        ],
                        [
                            14.583131654424847,
                            41.382024939024035
                        ],
                        [
                            14.586451740879255,
                            41.38254200113755
                        ],
                        [
                            14.59229599899038,
                            41.3745141710188
                        ],
                        [
                            14.58754944877171,
                            41.371484845317525
                        ],
                        [
                            14.600060445568687,
                            41.36824633882381
                        ],
                        [
                            14.604737542255663,
                            41.36535155471876
                        ],
                        [
                            14.610236437053203,
                            41.36992501462029
                        ],
                        [
                            14.61625142520424,
                            41.368681764183435
                        ],
                        [
                            14.627808437677572,
                            41.37829971471484
                        ],
                        [
                            14.629981055210862,
                            41.384211626212306
                        ],
                        [
                            14.639809765980885,
                            41.39180493700664
                        ],
                        [
                            14.655621657267432,
                            41.39787595141141
                        ],
                        [
                            14.662599438195684,
                            41.393251580651395
                        ],
                        [
                            14.66534146519362,
                            41.39899643610221
                        ],
                        [
                            14.660411525196992,
                            41.40305963946287
                        ],
                        [
                            14.661344980799612,
                            41.40632189641968
                        ],
                        [
                            14.676859785638158,
                            41.40766108895613
                        ],
                        [
                            14.686245276515734,
                            41.41256622357619
                        ],
                        [
                            14.694460963536727,
                            41.410318057789624
                        ],
                        [
                            14.699685360208889,
                            41.41230548811683
                        ],
                        [
                            14.705165591065992,
                            41.409649064117644
                        ],
                        [
                            14.706566069545659,
                            41.404933602308034
                        ],
                        [
                            14.709864764167747,
                            41.40516085253128
                        ],
                        [
                            14.713719767508508,
                            41.40173766239596
                        ],
                        [
                            14.718453709607218,
                            41.40100686586729
                        ],
                        [
                            14.739061446229528,
                            41.4082795851659
                        ],
                        [
                            14.751541276818045,
                            41.40849361263952
                        ],
                        [
                            14.759307225715162,
                            41.41635747347732
                        ],
                        [
                            14.765275903873686,
                            41.4182860040882
                        ],
                        [
                            14.765384411292018,
                            41.42101872527675
                        ],
                        [
                            14.762428963030914,
                            41.422070673746674
                        ],
                        [
                            14.763761443743618,
                            41.42371696196011
                        ],
                        [
                            14.772126387866336,
                            41.435165498376676
                        ],
                        [
                            14.770866557752754,
                            41.441784756352064
                        ],
                        [
                            14.784292434536923,
                            41.452433095129294
                        ],
                        [
                            14.793415059105683,
                            41.45343540333519
                        ],
                        [
                            14.808059887604031,
                            41.447031681039135
                        ],
                        [
                            14.813781735450362,
                            41.439756206434254
                        ],
                        [
                            14.821646723097814,
                            41.43968462229412
                        ],
                        [
                            14.826478548242562,
                            41.434768049073995
                        ],
                        [
                            14.837003416649992,
                            41.43265294007682
                        ],
                        [
                            14.841868005324756,
                            41.42802075265337
                        ],
                        [
                            14.85427704782063,
                            41.425494302918366
                        ],
                        [
                            14.861061915266077,
                            41.42765064464746
                        ],
                        [
                            14.867352722526704,
                            41.4271020943377
                        ],
                        [
                            14.870513975088187,
                            41.431222642455566
                        ],
                        [
                            14.874590207125777,
                            41.43386214483053
                        ],
                        [
                            14.874351714903355,
                            41.43689309606421
                        ],
                        [
                            14.880907945737562,
                            41.438908295592086
                        ],
                        [
                            14.881003583945613,
                            41.44321210555091
                        ],
                        [
                            14.884990926887696,
                            41.44625884564249
                        ],
                        [
                            14.890297726388042,
                            41.445627564629575
                        ],
                        [
                            14.89546528677937,
                            41.44888737351897
                        ],
                        [
                            14.907761318153149,
                            41.45053766623141
                        ],
                        [
                            14.912426681505053,
                            41.452682544362915
                        ],
                        [
                            14.920353559210582,
                            41.45130731126878
                        ],
                        [
                            14.924498713320162,
                            41.45391901716954
                        ],
                        [
                            14.927395483171011,
                            41.45229238815409
                        ],
                        [
                            14.952027863072118,
                            41.457969794082686
                        ],
                        [
                            14.960753183131345,
                            41.46634588658259
                        ],
                        [
                            14.973600223563949,
                            41.46732623639457
                        ],
                        [
                            14.976896055456507,
                            41.46925078879818
                        ],
                        [
                            14.98219154202144,
                            41.46940433863983
                        ],
                        [
                            14.98488575908425,
                            41.47276744692229
                        ],
                        [
                            14.982369141787956,
                            41.47481991102808
                        ],
                        [
                            14.983609057379937,
                            41.47893675946696
                        ],
                        [
                            14.995624185974634,
                            41.479984124558655
                        ],
                        [
                            15.000529601869516,
                            41.48428697756529
                        ],
                        [
                            15.007677691503659,
                            41.48688271271229
                        ],
                        [
                            14.99742481679175,
                            41.4959747875709
                        ],
                        [
                            14.986758891979122,
                            41.50086548671115
                        ],
                        [
                            14.979646529172129,
                            41.505474994142475
                        ],
                        [
                            14.972927287591205,
                            41.51199732806495
                        ],
                        [
                            14.968256968728042,
                            41.516702814537865
                        ],
                        [
                            14.964286934345665,
                            41.519939229402354
                        ],
                        [
                            14.955548608971782,
                            41.52090247430243
                        ],
                        [
                            14.952840504725543,
                            41.5230203322387
                        ],
                        [
                            14.950355869887062,
                            41.52098571436471
                        ],
                        [
                            14.945123425004311,
                            41.52223505484983
                        ],
                        [
                            14.943203963139354,
                            41.52365404053518
                        ],
                        [
                            14.939734843793673,
                            41.52362365721032
                        ],
                        [
                            14.936944322244017,
                            41.5280728167115
                        ],
                        [
                            14.937998389557606,
                            41.53589186668739
                        ],
                        [
                            14.934762670131363,
                            41.54101879692911
                        ],
                        [
                            14.937763126085741,
                            41.54457578334555
                        ],
                        [
                            14.936447057398482,
                            41.54828534241332
                        ],
                        [
                            14.940054055167707,
                            41.554136586977904
                        ],
                        [
                            14.94605065711474,
                            41.56268832851765
                        ],
                        [
                            14.943500659726055,
                            41.56777795999727
                        ],
                        [
                            14.95054773042574,
                            41.57281855847134
                        ],
                        [
                            14.945921369620663,
                            41.576351671211654
                        ],
                        [
                            14.946126036192197,
                            41.5812487371264
                        ],
                        [
                            14.94914153309972,
                            41.58648145185305
                        ],
                        [
                            14.946548761215581,
                            41.58962422809441
                        ],
                        [
                            14.95027987499327,
                            41.591968364537166
                        ],
                        [
                            14.945108576481829,
                            41.59993634858412
                        ],
                        [
                            14.947784229279165,
                            41.60830595972661
                        ],
                        [
                            14.952735545713182,
                            41.60759856733293
                        ],
                        [
                            14.952593942808676,
                            41.609420610787254
                        ],
                        [
                            14.94622254927944,
                            41.611384474522765
                        ],
                        [
                            14.938949011676783,
                            41.61301740543868
                        ],
                        [
                            14.934532778363376,
                            41.61692616825903
                        ],
                        [
                            14.93526627867854,
                            41.62192958749489
                        ],
                        [
                            14.940275841589852,
                            41.624847895713685
                        ],
                        [
                            14.946122192249476,
                            41.62901080433815
                        ],
                        [
                            14.95082544711164,
                            41.63532454207606
                        ],
                        [
                            14.954464845604292,
                            41.638336681806805
                        ],
                        [
                            14.956542446584393,
                            41.644434336892886
                        ],
                        [
                            14.960384841323503,
                            41.6461389190454
                        ],
                        [
                            14.966478063735593,
                            41.641599144613906
                        ],
                        [
                            14.972313785931362,
                            41.6406628280146
                        ],
                        [
                            14.97787589654817,
                            41.63940269986878
                        ],
                        [
                            14.986084551448016,
                            41.63861970536712
                        ],
                        [
                            14.989663310714661,
                            41.636580556741166
                        ],
                        [
                            14.98980081183015,
                            41.629978049927644
                        ],
                        [
                            14.999046189032072,
                            41.626023323341315
                        ],
                        [
                            14.998643003317966,
                            41.62863489643192
                        ],
                        [
                            15.002963795763492,
                            41.63016800034987
                        ],
                        [
                            15.005327367414566,
                            41.62807648633367
                        ],
                        [
                            15.005215836359923,
                            41.62394593045161
                        ],
                        [
                            15.011859813656969,
                            41.62144958393867
                        ],
                        [
                            15.019020345172493,
                            41.62357521443869
                        ],
                        [
                            15.022129652576496,
                            41.621952296228955
                        ],
                        [
                            15.025841330806202,
                            41.62261899248648
                        ],
                        [
                            15.0231325536476,
                            41.6278392342557
                        ],
                        [
                            15.024322374629378,
                            41.63384628466889
                        ],
                        [
                            15.03168011982413,
                            41.63609514921336
                        ],
                        [
                            15.03099241037529,
                            41.639247602409114
                        ],
                        [
                            15.036674057449517,
                            41.63733062190656
                        ],
                        [
                            15.044942602666282,
                            41.63680286074967
                        ],
                        [
                            15.042019196268797,
                            41.64476548351749
                        ],
                        [
                            15.046320357296025,
                            41.64884431700233
                        ],
                        [
                            15.046941973220584,
                            41.654487170082206
                        ],
                        [
                            15.054031460621644,
                            41.65920092009793
                        ],
                        [
                            15.06296363131537,
                            41.658628549943785
                        ],
                        [
                            15.06915044039954,
                            41.65823209855213
                        ],
                        [
                            15.070981321434232,
                            41.66535859675324
                        ],
                        [
                            15.077760763078572,
                            41.66557286333423
                        ],
                        [
                            15.080843456385963,
                            41.669906842126295
                        ],
                        [
                            15.085534023221015,
                            41.66999665737583
                        ],
                        [
                            15.090671995940482,
                            41.6725191138965
                        ],
                        [
                            15.086755040389448,
                            41.676265484821926
                        ],
                        [
                            15.089828619066964,
                            41.68203135892949
                        ],
                        [
                            15.093374934290011,
                            41.68115894126311
                        ],
                        [
                            15.094463254122815,
                            41.67538767223567
                        ],
                        [
                            15.097707272429489,
                            41.673367630129576
                        ],
                        [
                            15.102072423674116,
                            41.672053620829445
                        ],
                        [
                            15.104675450528703,
                            41.675128451167836
                        ],
                        [
                            15.10412356580946,
                            41.68231559608935
                        ],
                        [
                            15.10909614685761,
                            41.68664342215905
                        ],
                        [
                            15.114028235287009,
                            41.69213950275986
                        ],
                        [
                            15.121858543621528,
                            41.69564460555213
                        ],
                        [
                            15.12618454854757,
                            41.69717811521488
                        ],
                        [
                            15.13405341816528,
                            41.697839505331075
                        ],
                        [
                            15.140923635201545,
                            41.701929610054975
                        ],
                        [
                            15.147181615224099,
                            41.70515566527462
                        ],
                        [
                            15.15740688374217,
                            41.706577899872315
                        ],
                        [
                            15.161705627022343,
                            41.709284720407
                        ],
                        [
                            15.158044107535176,
                            41.71223774590371
                        ],
                        [
                            15.153890934161126,
                            41.71081655043895
                        ],
                        [
                            15.145863997571553,
                            41.71197280198592
                        ],
                        [
                            15.144126331270371,
                            41.71039937288165
                        ],
                        [
                            15.139950704913616,
                            41.714937421931296
                        ],
                        [
                            15.135071565096524,
                            41.71472814923459
                        ],
                        [
                            15.130810281877785,
                            41.71849381804819
                        ],
                        [
                            15.121222134160973,
                            41.720184957773654
                        ],
                        [
                            15.115610898306414,
                            41.728813135379
                        ],
                        [
                            15.101773006173147,
                            41.74301425081961
                        ],
                        [
                            15.103168980734068,
                            41.74912337604434
                        ],
                        [
                            15.111582540354874,
                            41.748190326416534
                        ],
                        [
                            15.117414279508466,
                            41.74897549534134
                        ],
                        [
                            15.114127346095955,
                            41.752161332414914
                        ],
                        [
                            15.111452828247764,
                            41.753109918662034
                        ],
                        [
                            15.104632767772443,
                            41.75885039427109
                        ],
                        [
                            15.103436261491026,
                            41.763581968756114
                        ],
                        [
                            15.0972399248148,
                            41.76566140806682
                        ],
                        [
                            15.099443894465935,
                            41.772762404104725
                        ],
                        [
                            15.101493804933597,
                            41.781935980768566
                        ],
                        [
                            15.111792418719006,
                            41.787189154729504
                        ],
                        [
                            15.114965139503283,
                            41.78917333431929
                        ],
                        [
                            15.11604795643126,
                            41.79413032605473
                        ],
                        [
                            15.115064514156785,
                            41.79922304373994
                        ],
                        [
                            15.11718941404851,
                            41.79960137405956
                        ],
                        [
                            15.1166791207485,
                            41.80427825355292
                        ],
                        [
                            15.11388144535826,
                            41.805619980404316
                        ],
                        [
                            15.114549472631381,
                            41.81151265665926
                        ],
                        [
                            15.11700758542591,
                            41.81329071700128
                        ],
                        [
                            15.11145943311044,
                            41.82100575743044
                        ],
                        [
                            15.108660660768379,
                            41.82234703219001
                        ],
                        [
                            15.10630959541777,
                            41.826113043687684
                        ],
                        [
                            15.104260787836239,
                            41.83102254277691
                        ],
                        [
                            15.101404096637282,
                            41.83185037437934
                        ],
                        [
                            15.099754733591652,
                            41.83415126123796
                        ],
                        [
                            15.101406535880017,
                            41.83495046912148
                        ],
                        [
                            15.101524676786596,
                            41.83907757565254
                        ],
                        [
                            15.103650703154074,
                            41.839459602258444
                        ],
                        [
                            15.102042940359638,
                            41.84059547286918
                        ],
                        [
                            15.103856124612236,
                            41.84435835880933
                        ],
                        [
                            15.103759715011591,
                            41.84775262899046
                        ],
                        [
                            15.106297604412589,
                            41.85029276234121
                        ],
                        [
                            15.108934524790016,
                            41.84909190030734
                        ],
                        [
                            15.114800105397961,
                            41.850387278152766
                        ],
                        [
                            15.115993121588986,
                            41.85327376095728
                        ],
                        [
                            15.1198963117495,
                            41.85405297583327
                        ],
                        [
                            15.118148660716429,
                            41.857001315193315
                        ],
                        [
                            15.122227050424499,
                            41.85931730290977
                        ],
                        [
                            15.122961344133074,
                            41.86120437095906
                        ],
                        [
                            15.125489387955326,
                            41.862073749716785
                        ],
                        [
                            15.125061530928718,
                            41.86442299739804
                        ],
                        [
                            15.124691681808457,
                            41.86728486539718
                        ],
                        [
                            15.12496948622217,
                            41.87126717304437
                        ],
                        [
                            15.127037883494694,
                            41.87113497473189
                        ],
                        [
                            15.129513947909658,
                            41.874591118135356
                        ],
                        [
                            15.130776968461646,
                            41.87347773298205
                        ],
                        [
                            15.136172388254522,
                            41.876619978127536
                        ],
                        [
                            15.138218998135443,
                            41.879331291400746
                        ],
                        [
                            15.14223230869601,
                            41.879463559978234
                        ],
                        [
                            15.141401461444929,
                            41.881325266567444
                        ],
                        [
                            15.136902253014966,
                            41.88303235970176
                        ],
                        [
                            15.138692016468383,
                            41.88653703816067
                        ],
                        [
                            15.136081586413297,
                            41.891096900385804
                        ],
                        [
                            15.131790686506065,
                            41.89770259000315
                        ],
                        [
                            15.132103941774233,
                            41.905054612441205
                        ],
                        [
                            15.133665353087395,
                            41.909614425430334
                        ],
                        [
                            15.135473305233205,
                            41.91480792979502
                        ],
                        [
                            15.134838674531636,
                            41.92300508277725
                        ],
                        [
                            15.138032264401886,
                            41.92712318824164
                        ],
                        [
                            15.130027167573559,
                            41.929078776861616
                        ],
                        [
                            15.086576076773326,
                            41.93952888497347
                        ],
                        [
                            15.071040615335619,
                            41.94957038846388
                        ],
                        [
                            15.054308557424406,
                            41.96330917546476
                        ],
                        [
                            15.039092004944393,
                            41.9707542698454
                        ],
                        [
                            15.033988968504815,
                            41.975906567224115
                        ],
                        [
                            15.026756500260916,
                            41.98081045952702
                        ],
                        [
                            15.00301774426643,
                            41.99077179248593
                        ],
                        [
                            15.000269649408352,
                            42.00378480210895
                        ],
                        [
                            15.00541972937387,
                            42.005879189777716
                        ],
                        [
                            14.966456074223203,
                            42.007721703505524
                        ],
                        [
                            14.918058194057664,
                            42.01694337172381
                        ],
                        [
                            14.872407994140104,
                            42.02974520956437
                        ],
                        [
                            14.821697961749976,
                            42.049013766637984
                        ],
                        [
                            14.796427535549185,
                            42.063674548882744
                        ]
                    ]
                ],
                "type": "Polygon"
            },
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Puglia",
                image: 'https://lp-cms-production.imgix.net/2024-02/GettyImages-1223038396-RFC.jpg'
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            15.13909722906439,
                            41.926047532181
                        ],
                        [
                            15.13522844136481,
                            41.922829198691176
                        ],
                        [
                            15.136266136483911,
                            41.914331306459225
                        ],
                        [
                            15.132655680738047,
                            41.90546816106804
                        ],
                        [
                            15.13246498000808,
                            41.89741246657563
                        ],
                        [
                            15.138136658744614,
                            41.88848655905639
                        ],
                        [
                            15.13924675346826,
                            41.8862365771822
                        ],
                        [
                            15.137243787687863,
                            41.88314385057368
                        ],
                        [
                            15.141367125990705,
                            41.88160526531743
                        ],
                        [
                            15.143041996544838,
                            41.87907605345391
                        ],
                        [
                            15.138552461959357,
                            41.87888324677132
                        ],
                        [
                            15.136581880144163,
                            41.87647006648551
                        ],
                        [
                            15.131105837731752,
                            41.87349833925461
                        ],
                        [
                            15.129745066943183,
                            41.874188005654794
                        ],
                        [
                            15.127536028101837,
                            41.87095589608758
                        ],
                        [
                            15.125277679093333,
                            41.8709346023696
                        ],
                        [
                            15.12494768282987,
                            41.86804801974382
                        ],
                        [
                            15.125951265784094,
                            41.86177542568815
                        ],
                        [
                            15.123189919495662,
                            41.860994216117135
                        ],
                        [
                            15.122472874138126,
                            41.85904210581221
                        ],
                        [
                            15.118570316660977,
                            41.85697191700942
                        ],
                        [
                            15.120377843398398,
                            41.853958267161225
                        ],
                        [
                            15.11619796116202,
                            41.8529827170275
                        ],
                        [
                            15.115029231837354,
                            41.85033098337719
                        ],
                        [
                            15.109116109066457,
                            41.84886165149869
                        ],
                        [
                            15.1065401084374,
                            41.84995671059286
                        ],
                        [
                            15.10405273107924,
                            41.8476014778809
                        ],
                        [
                            15.104175938290219,
                            41.84437088804202
                        ],
                        [
                            15.102358087899368,
                            41.84075314182711
                        ],
                        [
                            15.104215492298572,
                            41.83928045928003
                        ],
                        [
                            15.101741526739422,
                            41.83890901084677
                        ],
                        [
                            15.101682015133168,
                            41.83666622191194
                        ],
                        [
                            15.10184932453739,
                            41.83475435483882
                        ],
                        [
                            15.10024474536851,
                            41.8340513374349
                        ],
                        [
                            15.101599222070202,
                            41.83192790247372
                        ],
                        [
                            15.104686435704648,
                            41.831008421890225
                        ],
                        [
                            15.10640922523956,
                            41.826363360082524
                        ],
                        [
                            15.108946772050619,
                            41.822560170526785
                        ],
                        [
                            15.111462548761104,
                            41.82124206856237
                        ],
                        [
                            15.117275983134245,
                            41.8130914907847
                        ],
                        [
                            15.114762111045524,
                            41.81157823570947
                        ],
                        [
                            15.114176643342326,
                            41.80569218329042
                        ],
                        [
                            15.117094260936245,
                            41.80436003593288
                        ],
                        [
                            15.117489912877897,
                            41.799273909425764
                        ],
                        [
                            15.115493669057578,
                            41.79891222695707
                        ],
                        [
                            15.116467856166086,
                            41.79424114326612
                        ],
                        [
                            15.115445799454275,
                            41.78920837710647
                        ],
                        [
                            15.10968077169872,
                            41.78562945414029
                        ],
                        [
                            15.10192350669951,
                            41.781978153331764
                        ],
                        [
                            15.097959770620726,
                            41.765913229293496
                        ],
                        [
                            15.103636776351276,
                            41.76341402084836
                        ],
                        [
                            15.104914748142676,
                            41.75902371524023
                        ],
                        [
                            15.111783636183128,
                            41.75308911979906
                        ],
                        [
                            15.114318884366003,
                            41.75239102544487
                        ],
                        [
                            15.117871588275955,
                            41.748651545971725
                        ],
                        [
                            15.111457758793922,
                            41.74789937224457
                        ],
                        [
                            15.103680512333185,
                            41.74874353360991
                        ],
                        [
                            15.102407915786795,
                            41.74299890242045
                        ],
                        [
                            15.116205088506774,
                            41.72891635465457
                        ],
                        [
                            15.12159454099697,
                            41.72051785064122
                        ],
                        [
                            15.130966468184127,
                            41.71874284141643
                        ],
                        [
                            15.135129183204125,
                            41.71499077640143
                        ],
                        [
                            15.140403233764493,
                            41.71502550466913
                        ],
                        [
                            15.144199933635761,
                            41.71062212941866
                        ],
                        [
                            15.14576834415055,
                            41.712527231042344
                        ],
                        [
                            15.153492846522697,
                            41.71109416028126
                        ],
                        [
                            15.158110987029069,
                            41.712687066997624
                        ],
                        [
                            15.16177728627067,
                            41.70906411623085
                        ],
                        [
                            15.157575818038802,
                            41.70622786755258
                        ],
                        [
                            15.147408485775511,
                            41.70446339319851
                        ],
                        [
                            15.134451583723745,
                            41.69792123395275
                        ],
                        [
                            15.12538178185358,
                            41.69644955781419
                        ],
                        [
                            15.114517628563068,
                            41.69168361115315
                        ],
                        [
                            15.104874961590387,
                            41.6824839255724
                        ],
                        [
                            15.10558708918777,
                            41.67513898864727
                        ],
                        [
                            15.101418123882787,
                            41.671783655114595
                        ],
                        [
                            15.094125134633455,
                            41.674874136562394
                        ],
                        [
                            15.092830881938406,
                            41.68073438836256
                        ],
                        [
                            15.090296555812799,
                            41.68139604437799
                        ],
                        [
                            15.087600038798023,
                            41.67601360287054
                        ],
                        [
                            15.090849842896732,
                            41.67244364061256
                        ],
                        [
                            15.086105473398359,
                            41.66955398167818
                        ],
                        [
                            15.08133432607125,
                            41.66949360874693
                        ],
                        [
                            15.077793571933897,
                            41.66511709107726
                        ],
                        [
                            15.071587839872898,
                            41.664611265600016
                        ],
                        [
                            15.069832019667611,
                            41.65793222944174
                        ],
                        [
                            15.0548507307943,
                            41.65879325063719
                        ],
                        [
                            15.047773070803908,
                            41.654399931712014
                        ],
                        [
                            15.04738004945104,
                            41.64857017199192
                        ],
                        [
                            15.042806222899179,
                            41.64445153144922
                        ],
                        [
                            15.045256264256764,
                            41.636195139049924
                        ],
                        [
                            15.036025511213527,
                            41.63673075733088
                        ],
                        [
                            15.031928380656222,
                            41.63862869965978
                        ],
                        [
                            15.03245166778521,
                            41.635772300125424
                        ],
                        [
                            15.024755911326022,
                            41.63342893803948
                        ],
                        [
                            15.023811584460113,
                            41.62759807765589
                        ],
                        [
                            15.026678576791474,
                            41.62229563252538
                        ],
                        [
                            15.021937761629339,
                            41.6212705064358
                        ],
                        [
                            15.018632196598967,
                            41.62311893161858
                        ],
                        [
                            15.012288595589265,
                            41.62068271814452
                        ],
                        [
                            15.005370856703113,
                            41.623329523890305
                        ],
                        [
                            15.004992469360017,
                            41.6276540928165
                        ],
                        [
                            15.003155244749955,
                            41.629636109605904
                        ],
                        [
                            14.999238104313024,
                            41.62829030743525
                        ],
                        [
                            14.998236564028655,
                            41.62548181936553
                        ],
                        [
                            14.989266787874243,
                            41.629655560240806
                        ],
                        [
                            14.989002337904447,
                            41.63570283048439
                        ],
                        [
                            14.98553004894854,
                            41.63786139281029
                        ],
                        [
                            14.979156955015007,
                            41.6386648617503
                        ],
                        [
                            14.967678592591106,
                            41.64083328613599
                        ],
                        [
                            14.960302391246179,
                            41.64523917669959
                        ],
                        [
                            14.95715818622482,
                            41.64388774316251
                        ],
                        [
                            14.954679512554453,
                            41.63776001376528
                        ],
                        [
                            14.95058874919416,
                            41.634769246265634
                        ],
                        [
                            14.947020012065332,
                            41.62879635768185
                        ],
                        [
                            14.935794913817286,
                            41.62175821171471
                        ],
                        [
                            14.935237466360686,
                            41.61698473397291
                        ],
                        [
                            14.939930705202784,
                            41.61346027821246
                        ],
                        [
                            14.953062479254793,
                            41.60971613419086
                        ],
                        [
                            14.953070067940986,
                            41.60724834211487
                        ],
                        [
                            14.94843954626149,
                            41.60686547549299
                        ],
                        [
                            14.945998390676504,
                            41.6000515046086
                        ],
                        [
                            14.950648080689199,
                            41.59173650198452
                        ],
                        [
                            14.946781387422417,
                            41.58934510356991
                        ],
                        [
                            14.949699082053176,
                            41.58626787995715
                        ],
                        [
                            14.946394844682366,
                            41.58167346556172
                        ],
                        [
                            14.946465734494902,
                            41.576607492802644
                        ],
                        [
                            14.951598372351938,
                            41.572678194768585
                        ],
                        [
                            14.944955011083252,
                            41.56731647817607
                        ],
                        [
                            14.947916148720523,
                            41.56204876736099
                        ],
                        [
                            14.937426121044652,
                            41.548345145195796
                        ],
                        [
                            14.93910445399699,
                            41.543628121389276
                        ],
                        [
                            14.935541250825409,
                            41.54071492101298
                        ],
                        [
                            14.939135379249365,
                            41.53581996497353
                        ],
                        [
                            14.937723380588578,
                            41.52778026215463
                        ],
                        [
                            14.940161643869853,
                            41.52383563969903
                        ],
                        [
                            14.943423922670231,
                            41.5243050160029
                        ],
                        [
                            14.945342439919553,
                            41.52293958915476
                        ],
                        [
                            14.949847699113652,
                            41.52178480469287
                        ],
                        [
                            14.952956833416959,
                            41.52437979505117
                        ],
                        [
                            14.955852432428934,
                            41.521633684601625
                        ],
                        [
                            14.966496341586135,
                            41.519759090326396
                        ],
                        [
                            14.978962848059727,
                            41.50628939006306
                        ],
                        [
                            14.988223511214088,
                            41.500645532592706
                        ],
                        [
                            14.99768064915153,
                            41.49679746573338
                        ],
                        [
                            15.012888790751209,
                            41.48187524731482
                        ],
                        [
                            15.022822384025243,
                            41.47919375402566
                        ],
                        [
                            15.031473386293877,
                            41.46636862812131
                        ],
                        [
                            15.033349161253163,
                            41.45220672544775
                        ],
                        [
                            15.046044886813213,
                            41.45521770514834
                        ],
                        [
                            15.043958821910454,
                            41.4479334325346
                        ],
                        [
                            15.046668014161925,
                            41.44263278665129
                        ],
                        [
                            15.05522637770747,
                            41.4436815480346
                        ],
                        [
                            15.066147330931976,
                            41.43927167183594
                        ],
                        [
                            15.069179983671521,
                            41.435440666067606
                        ],
                        [
                            15.073083761401463,
                            41.43573097221156
                        ],
                        [
                            15.075994992460044,
                            41.43319252156267
                        ],
                        [
                            15.084593326921489,
                            41.43161238843187
                        ],
                        [
                            15.09123595273602,
                            41.43381788325737
                        ],
                        [
                            15.100664913013421,
                            41.43255359229909
                        ],
                        [
                            15.104854174228524,
                            41.41991048838088
                        ],
                        [
                            15.093257602442787,
                            41.41734073633276
                        ],
                        [
                            15.088823015721978,
                            41.41074429320987
                        ],
                        [
                            15.087870712046442,
                            41.4031436131229
                        ],
                        [
                            15.095171592253992,
                            41.39753214723345
                        ],
                        [
                            15.099883951055304,
                            41.38990383161786
                        ],
                        [
                            15.092533119000162,
                            41.38782136717364
                        ],
                        [
                            15.080998094616326,
                            41.38693722164742
                        ],
                        [
                            15.071776681745007,
                            41.37584309487055
                        ],
                        [
                            15.064293797773571,
                            41.37406124115569
                        ],
                        [
                            15.060364104146995,
                            41.37049191208424
                        ],
                        [
                            15.062785630947978,
                            41.35717945465575
                        ],
                        [
                            15.067747421123315,
                            41.35641717764175
                        ],
                        [
                            15.069400076813487,
                            41.34969339876237
                        ],
                        [
                            15.068848614872085,
                            41.34264692673957
                        ],
                        [
                            15.072756566685001,
                            41.33323374156788
                        ],
                        [
                            15.085794575828963,
                            41.328259735756994
                        ],
                        [
                            15.098460354198847,
                            41.328636709114036
                        ],
                        [
                            15.104321365629357,
                            41.32551484284718
                        ],
                        [
                            15.113122089314892,
                            41.319189364076735
                        ],
                        [
                            15.132603011778873,
                            41.31771555305076
                        ],
                        [
                            15.128256219326598,
                            41.30397249956547
                        ],
                        [
                            15.13705759096526,
                            41.29867379916405
                        ],
                        [
                            15.14204975276112,
                            41.29062178659137
                        ],
                        [
                            15.137937397637703,
                            41.28652713489364
                        ],
                        [
                            15.144559611804858,
                            41.28458841479019
                        ],
                        [
                            15.149764251223825,
                            41.28064876447688
                        ],
                        [
                            15.151923275690507,
                            41.283450979308746
                        ],
                        [
                            15.173914465509865,
                            41.283781717824084
                        ],
                        [
                            15.180610789416193,
                            41.28679135406722
                        ],
                        [
                            15.187034905888211,
                            41.286902449815635
                        ],
                        [
                            15.188913067661225,
                            41.284044636354096
                        ],
                        [
                            15.201531450669338,
                            41.28739482143443
                        ],
                        [
                            15.200120615435715,
                            41.279766692269234
                        ],
                        [
                            15.205794820351977,
                            41.27697220324741
                        ],
                        [
                            15.214347184583982,
                            41.276841618109316
                        ],
                        [
                            15.220382963120306,
                            41.266020556316796
                        ],
                        [
                            15.22857594496853,
                            41.26679702344134
                        ],
                        [
                            15.22912415495,
                            41.26881496246767
                        ],
                        [
                            15.236719720669372,
                            41.27339780937926
                        ],
                        [
                            15.242306246874708,
                            41.2707575330146
                        ],
                        [
                            15.248840668372871,
                            41.27120966515589
                        ],
                        [
                            15.256488591290335,
                            41.26671327911191
                        ],
                        [
                            15.260843443679555,
                            41.26096646309288
                        ],
                        [
                            15.276629724810448,
                            41.248328242749224
                        ],
                        [
                            15.276972766599,
                            41.24428264806184
                        ],
                        [
                            15.280702686540252,
                            41.24012518410032
                        ],
                        [
                            15.275582660238104,
                            41.24098969368481
                        ],
                        [
                            15.272560675275892,
                            41.23887497845416
                        ],
                        [
                            15.269758935084756,
                            41.23896645919946
                        ],
                        [
                            15.26799437272308,
                            41.237468695272696
                        ],
                        [
                            15.261012953760872,
                            41.23621613185433
                        ],
                        [
                            15.25565219398474,
                            41.238696906985155
                        ],
                        [
                            15.246977784297636,
                            41.236139521512925
                        ],
                        [
                            15.246200142543906,
                            41.232580886319916
                        ],
                        [
                            15.247641859016177,
                            41.2308135918054
                        ],
                        [
                            15.245713004613636,
                            41.22549489043823
                        ],
                        [
                            15.248052420014929,
                            41.22432213634728
                        ],
                        [
                            15.24782029294543,
                            41.22128141679599
                        ],
                        [
                            15.253586762706083,
                            41.21540780167341
                        ],
                        [
                            15.25747701290296,
                            41.206279573743515
                        ],
                        [
                            15.260890196789884,
                            41.20492355398191
                        ],
                        [
                            15.26325238488917,
                            41.197854640432524
                        ],
                        [
                            15.222122246373539,
                            41.178973165154716
                        ],
                        [
                            15.22226070396301,
                            41.17389434150248
                        ],
                        [
                            15.220413911975387,
                            41.17424763554574
                        ],
                        [
                            15.217260197654245,
                            41.17155733042543
                        ],
                        [
                            15.216973886041423,
                            41.17031928333579
                        ],
                        [
                            15.213269063265425,
                            41.16934451609687
                        ],
                        [
                            15.209674724297528,
                            41.16693735866468
                        ],
                        [
                            15.213270126687686,
                            41.16107071827018
                        ],
                        [
                            15.210954448712274,
                            41.16086923806863
                        ],
                        [
                            15.208998456546187,
                            41.15660373450301
                        ],
                        [
                            15.211018779942902,
                            41.14870081291204
                        ],
                        [
                            15.213275525567163,
                            41.14899749168835
                        ],
                        [
                            15.215973997221985,
                            41.14537482698942
                        ],
                        [
                            15.2217239491752,
                            41.142466349667785
                        ],
                        [
                            15.223949319061399,
                            41.14298718459134
                        ],
                        [
                            15.22588200961654,
                            41.14273815910596
                        ],
                        [
                            15.226174688947598,
                            41.1435080195149
                        ],
                        [
                            15.227877215985437,
                            41.14411900041864
                        ],
                        [
                            15.231051764784212,
                            41.142344111688566
                        ],
                        [
                            15.230677881301178,
                            41.14022197814728
                        ],
                        [
                            15.233366858381235,
                            41.13892056237998
                        ],
                        [
                            15.236753832000579,
                            41.1414250540502
                        ],
                        [
                            15.242145250795307,
                            41.13527415320861
                        ],
                        [
                            15.24135671952518,
                            41.13363958947393
                        ],
                        [
                            15.247536669590033,
                            41.12912325236702
                        ],
                        [
                            15.254793966990654,
                            41.12152020089722
                        ],
                        [
                            15.255567773767787,
                            41.11745720213768
                        ],
                        [
                            15.25753527015642,
                            41.11783961269745
                        ],
                        [
                            15.264447875559814,
                            41.11506448093612
                        ],
                        [
                            15.2628024090031,
                            41.10745735499026
                        ],
                        [
                            15.26468818509725,
                            41.10605252043263
                        ],
                        [
                            15.267335411139923,
                            41.107590475460626
                        ],
                        [
                            15.27652840406003,
                            41.10128602829352
                        ],
                        [
                            15.285372103533392,
                            41.09736580475011
                        ],
                        [
                            15.289826595776812,
                            41.09767585615151
                        ],
                        [
                            15.292580568421362,
                            41.092357779455284
                        ],
                        [
                            15.296940168569801,
                            41.09332980611477
                        ],
                        [
                            15.296379484444842,
                            41.09506045907926
                        ],
                        [
                            15.30117172419438,
                            41.09643774966899
                        ],
                        [
                            15.30317681442353,
                            41.09962407637835
                        ],
                        [
                            15.30543024588663,
                            41.09834053453616
                        ],
                        [
                            15.31514676456332,
                            41.10421433372167
                        ],
                        [
                            15.318872855644674,
                            41.10037611767267
                        ],
                        [
                            15.324049698967025,
                            41.10032628759825
                        ],
                        [
                            15.329666459469827,
                            41.09864331385851
                        ],
                        [
                            15.332335344860777,
                            41.096852363193506
                        ],
                        [
                            15.336884388004975,
                            41.09605087778053
                        ],
                        [
                            15.344719276976114,
                            41.09630428650037
                        ],
                        [
                            15.352409812645396,
                            41.09543391896129
                        ],
                        [
                            15.357379338965597,
                            41.08996864199126
                        ],
                        [
                            15.363976602329604,
                            41.085944697279594
                        ],
                        [
                            15.36629799814374,
                            41.085102888687956
                        ],
                        [
                            15.368889874576032,
                            41.086674642818295
                        ],
                        [
                            15.370310900214967,
                            41.08641608037492
                        ],
                        [
                            15.368777110262721,
                            41.08883664773663
                        ],
                        [
                            15.372528997356142,
                            41.09024836551586
                        ],
                        [
                            15.376030409101443,
                            41.08901478646102
                        ],
                        [
                            15.384131950924733,
                            41.09468168551328
                        ],
                        [
                            15.388308470645104,
                            41.102153215430384
                        ],
                        [
                            15.391746831934995,
                            41.10142366850951
                        ],
                        [
                            15.399203457178736,
                            41.10768982339245
                        ],
                        [
                            15.41346542963752,
                            41.09909934138253
                        ],
                        [
                            15.414047549028215,
                            41.095327703996084
                        ],
                        [
                            15.419134518505693,
                            41.095690925493074
                        ],
                        [
                            15.425677202948801,
                            41.098624530286926
                        ],
                        [
                            15.432461699281607,
                            41.08858261852952
                        ],
                        [
                            15.442479913413063,
                            41.08388307251852
                        ],
                        [
                            15.444637535584398,
                            41.080328965025615
                        ],
                        [
                            15.455903486595288,
                            41.0762408008807
                        ],
                        [
                            15.46610342626332,
                            41.078432725663674
                        ],
                        [
                            15.479303124712969,
                            41.07986016896434
                        ],
                        [
                            15.479642273450409,
                            41.07470351045821
                        ],
                        [
                            15.484684203083454,
                            41.070929330845054
                        ],
                        [
                            15.495433366015448,
                            41.0707909667278
                        ],
                        [
                            15.502818289233211,
                            41.06553513942353
                        ],
                        [
                            15.522309429515433,
                            41.06306370187505
                        ],
                        [
                            15.530480372297344,
                            41.05831220666438
                        ],
                        [
                            15.542792536014165,
                            41.05618649365823
                        ],
                        [
                            15.539486910629169,
                            41.06166148435284
                        ],
                        [
                            15.542472758192535,
                            41.06604213088337
                        ],
                        [
                            15.539434288925069,
                            41.07203928727999
                        ],
                        [
                            15.553375114977545,
                            41.077223295610374
                        ],
                        [
                            15.563193522617103,
                            41.09012312212384
                        ],
                        [
                            15.58305535802743,
                            41.09834698478829
                        ],
                        [
                            15.593090138237393,
                            41.09655164179294
                        ],
                        [
                            15.599704389828226,
                            41.099832097990635
                        ],
                        [
                            15.604715879555236,
                            41.09531056377162
                        ],
                        [
                            15.619328219543698,
                            41.09988150155468
                        ],
                        [
                            15.637169093924026,
                            41.0922293454852
                        ],
                        [
                            15.651876353105923,
                            41.089310770179694
                        ],
                        [
                            15.667982474140512,
                            41.093243203437694
                        ],
                        [
                            15.66704441080411,
                            41.08751455642667
                        ],
                        [
                            15.698210490309073,
                            41.08719601972389
                        ],
                        [
                            15.725248709394549,
                            41.091065132986216
                        ],
                        [
                            15.729561563166776,
                            41.099042972737564
                        ],
                        [
                            15.73924731772219,
                            41.10020231834361
                        ],
                        [
                            15.754144304955535,
                            41.09708706459192
                        ],
                        [
                            15.7539927456326,
                            41.09178169539229
                        ],
                        [
                            15.759066348770705,
                            41.09295036823224
                        ],
                        [
                            15.759699813591075,
                            41.08693252933922
                        ],
                        [
                            15.764197117334248,
                            41.08791113472866
                        ],
                        [
                            15.76788826955141,
                            41.0831182923486
                        ],
                        [
                            15.774858121642733,
                            41.08710536543375
                        ],
                        [
                            15.777792670142588,
                            41.08686257721614
                        ],
                        [
                            15.78053366042695,
                            41.08943811237352
                        ],
                        [
                            15.784146648122352,
                            41.08985703138306
                        ],
                        [
                            15.789094071514644,
                            41.090841484826214
                        ],
                        [
                            15.788454554772521,
                            41.087656705240946
                        ],
                        [
                            15.798790267689208,
                            41.09195465979381
                        ],
                        [
                            15.798410225347737,
                            41.095327198607066
                        ],
                        [
                            15.802119228193172,
                            41.09680208317441
                        ],
                        [
                            15.80036479813731,
                            41.09960890825868
                        ],
                        [
                            15.803059363210044,
                            41.10348614882548
                        ],
                        [
                            15.799752606891026,
                            41.10430406877544
                        ],
                        [
                            15.797342952084243,
                            41.11146386140388
                        ],
                        [
                            15.805606704210572,
                            41.11090336736448
                        ],
                        [
                            15.81024070680016,
                            41.116140537430184
                        ],
                        [
                            15.81668958415812,
                            41.118478875443344
                        ],
                        [
                            15.819368412915752,
                            41.117407034452754
                        ],
                        [
                            15.823138461516077,
                            41.1208172134565
                        ],
                        [
                            15.825191295778138,
                            41.11745186031111
                        ],
                        [
                            15.829854547700577,
                            41.118181133137455
                        ],
                        [
                            15.82996856633425,
                            41.127859718748226
                        ],
                        [
                            15.836787751627895,
                            41.13137311236541
                        ],
                        [
                            15.8400700935444,
                            41.12916608697456
                        ],
                        [
                            15.839473154264997,
                            41.12373366632238
                        ],
                        [
                            15.846089225806942,
                            41.124620752183645
                        ],
                        [
                            15.850166799048152,
                            41.13412051561564
                        ],
                        [
                            15.864599483335798,
                            41.130832286435236
                        ],
                        [
                            15.870166811205419,
                            41.14036429036119
                        ],
                        [
                            15.874937287823927,
                            41.13719848358585
                        ],
                        [
                            15.878846025244037,
                            41.13967565063901
                        ],
                        [
                            15.893009870369239,
                            41.12290482788286
                        ],
                        [
                            15.896521946932516,
                            41.12624112277321
                        ],
                        [
                            15.896670462790729,
                            41.123938529220865
                        ],
                        [
                            15.901977582506408,
                            41.12610093696817
                        ],
                        [
                            15.920053974022874,
                            41.11189110438108
                        ],
                        [
                            15.926208019571535,
                            41.11315703647273
                        ],
                        [
                            15.93238502426302,
                            41.10882203660384
                        ],
                        [
                            15.937336875148564,
                            41.1137306547339
                        ],
                        [
                            15.947434146600514,
                            41.11055651324778
                        ],
                        [
                            15.955427081159508,
                            41.11135721621882
                        ],
                        [
                            15.96135916414535,
                            41.09951876041052
                        ],
                        [
                            15.955758981797665,
                            41.09272351678983
                        ],
                        [
                            15.977018853426607,
                            41.09422811295551
                        ],
                        [
                            15.983548567443854,
                            41.08841919502174
                        ],
                        [
                            15.984241425956622,
                            41.08508283616786
                        ],
                        [
                            15.979916799311539,
                            41.08061277935707
                        ],
                        [
                            15.988905619919045,
                            41.06785173942587
                        ],
                        [
                            15.998114393071866,
                            41.06066676192246
                        ],
                        [
                            15.996115302341316,
                            41.05751084578796
                        ],
                        [
                            15.999587190236362,
                            41.05328497918805
                        ],
                        [
                            16.007316305247688,
                            41.05920457866154
                        ],
                        [
                            16.012510308494484,
                            41.055690386328266
                        ],
                        [
                            16.021076071357236,
                            41.05134400370711
                        ],
                        [
                            16.026233229107827,
                            41.0515898914103
                        ],
                        [
                            16.02822828753115,
                            41.04651757215433
                        ],
                        [
                            16.031386893418723,
                            41.04203390024417
                        ],
                        [
                            16.033999831622438,
                            41.04164044918865
                        ],
                        [
                            16.03804803905276,
                            41.03804424397194
                        ],
                        [
                            16.03980610969252,
                            41.03361691511964
                        ],
                        [
                            16.040292418108272,
                            41.02785638964954
                        ],
                        [
                            16.03185488404357,
                            41.02735720942706
                        ],
                        [
                            16.02766448543578,
                            41.02457952067651
                        ],
                        [
                            16.026825380147795,
                            41.014742106962075
                        ],
                        [
                            16.0274402004419,
                            41.01148789017536
                        ],
                        [
                            16.031221520252657,
                            41.00635257730213
                        ],
                        [
                            16.031531149281726,
                            40.99808866288791
                        ],
                        [
                            16.029291847221398,
                            40.99704557797091
                        ],
                        [
                            16.024797123350588,
                            40.98765630855855
                        ],
                        [
                            16.01260945153647,
                            40.979818517781595
                        ],
                        [
                            16.008569433919945,
                            40.97255863408017
                        ],
                        [
                            16.003142618479483,
                            40.97015031310882
                        ],
                        [
                            15.98425990239207,
                            40.97015703924806
                        ],
                        [
                            15.972743272533101,
                            40.97150531351514
                        ],
                        [
                            15.97536215556005,
                            40.965758386684584
                        ],
                        [
                            15.976697235947807,
                            40.95841371871441
                        ],
                        [
                            15.982807569102441,
                            40.95488785996244
                        ],
                        [
                            16.015952809479856,
                            40.946757523486156
                        ],
                        [
                            16.050042261340568,
                            40.93637024535298
                        ],
                        [
                            16.05837065418516,
                            40.92969291634128
                        ],
                        [
                            16.065655956124527,
                            40.928606637463254
                        ],
                        [
                            16.07148340342893,
                            40.92607217033115
                        ],
                        [
                            16.082858868137794,
                            40.92431730998744
                        ],
                        [
                            16.093447564842215,
                            40.920508825706776
                        ],
                        [
                            16.094750588489433,
                            40.91810719814795
                        ],
                        [
                            16.10081568716101,
                            40.916659054531465
                        ],
                        [
                            16.108826402290017,
                            40.905829729825854
                        ],
                        [
                            16.11674137389845,
                            40.901964883933154
                        ],
                        [
                            16.120817050669597,
                            40.897783695858834
                        ],
                        [
                            16.12361690989622,
                            40.902090882467846
                        ],
                        [
                            16.1265327279027,
                            40.901933721101216
                        ],
                        [
                            16.131184826468598,
                            40.90483438051926
                        ],
                        [
                            16.135003832569943,
                            40.90709375334409
                        ],
                        [
                            16.134198876560173,
                            40.91211048842038
                        ],
                        [
                            16.135851645985063,
                            40.91722863207225
                        ],
                        [
                            16.14148890627924,
                            40.919848557349795
                        ],
                        [
                            16.146206935373428,
                            40.924217321977736
                        ],
                        [
                            16.154421600874326,
                            40.9223781601295
                        ],
                        [
                            16.159841435148614,
                            40.9247561350209
                        ],
                        [
                            16.162078578841438,
                            40.92874655591858
                        ],
                        [
                            16.16930910191404,
                            40.928079077917644
                        ],
                        [
                            16.18530711124246,
                            40.92160902607438
                        ],
                        [
                            16.200727673602298,
                            40.9189928331246
                        ],
                        [
                            16.213829795504303,
                            40.90903708410818
                        ],
                        [
                            16.225356271409055,
                            40.88420059190916
                        ],
                        [
                            16.23086121404014,
                            40.853623518984364
                        ],
                        [
                            16.241550858866876,
                            40.84724043606959
                        ],
                        [
                            16.24395389605143,
                            40.83734856439803
                        ],
                        [
                            16.25077950434043,
                            40.83543591618614
                        ],
                        [
                            16.256179753319486,
                            40.82400339528394
                        ],
                        [
                            16.26867635655347,
                            40.81578950678766
                        ],
                        [
                            16.27909625176477,
                            40.80110492772515
                        ],
                        [
                            16.317491555405233,
                            40.7834108121749
                        ],
                        [
                            16.349075417856316,
                            40.74923787075289
                        ],
                        [
                            16.382235700482866,
                            40.73532417020266
                        ],
                        [
                            16.393152681779533,
                            40.71737622645483
                        ],
                        [
                            16.405400096751222,
                            40.703142432473655
                        ],
                        [
                            16.426364183053803,
                            40.700997610262
                        ],
                        [
                            16.43394902149329,
                            40.70901359046202
                        ],
                        [
                            16.44533260937377,
                            40.71195731179263
                        ],
                        [
                            16.478126762608042,
                            40.73609283798564
                        ],
                        [
                            16.50171565137477,
                            40.762356064356865
                        ],
                        [
                            16.512076532339208,
                            40.754326198385634
                        ],
                        [
                            16.50778875666746,
                            40.753020427244294
                        ],
                        [
                            16.512476252183973,
                            40.74830908536282
                        ],
                        [
                            16.51647925276872,
                            40.74950997439902
                        ],
                        [
                            16.522261779724452,
                            40.746226737376674
                        ],
                        [
                            16.524170676423665,
                            40.741771432848736
                        ],
                        [
                            16.51628421610262,
                            40.739103284454465
                        ],
                        [
                            16.518396281294446,
                            40.734749782420394
                        ],
                        [
                            16.525494519489335,
                            40.735069510547106
                        ],
                        [
                            16.53912089465453,
                            40.72456498570681
                        ],
                        [
                            16.543844213409088,
                            40.72530761511864
                        ],
                        [
                            16.534928874197615,
                            40.73301653720755
                        ],
                        [
                            16.540538577167247,
                            40.74154873891444
                        ],
                        [
                            16.530686904250473,
                            40.74478801123274
                        ],
                        [
                            16.537106233491624,
                            40.75364261854266
                        ],
                        [
                            16.542191006317545,
                            40.75307018100213
                        ],
                        [
                            16.545728024976285,
                            40.75227791945475
                        ],
                        [
                            16.546927764113526,
                            40.7535817863628
                        ],
                        [
                            16.544504506967236,
                            40.756779852816834
                        ],
                        [
                            16.55231021302823,
                            40.756404485662095
                        ],
                        [
                            16.551429168217105,
                            40.75838117876367
                        ],
                        [
                            16.55389255166933,
                            40.75890512623077
                        ],
                        [
                            16.558616612663272,
                            40.75497819203426
                        ],
                        [
                            16.5624863724499,
                            40.747558786097464
                        ],
                        [
                            16.561043030322566,
                            40.74482745103455
                        ],
                        [
                            16.563383068825765,
                            40.740093486287456
                        ],
                        [
                            16.567519293264017,
                            40.73758777505519
                        ],
                        [
                            16.57009903823753,
                            40.74420728032676
                        ],
                        [
                            16.572562587440153,
                            40.737201516239004
                        ],
                        [
                            16.578045921072007,
                            40.74257058963423
                        ],
                        [
                            16.56686387111722,
                            40.75366655171754
                        ],
                        [
                            16.568893298823795,
                            40.75923816021722
                        ],
                        [
                            16.574101827341643,
                            40.75951367976188
                        ],
                        [
                            16.575303504257814,
                            40.76376992873409
                        ],
                        [
                            16.579345763805016,
                            40.76383145010877
                        ],
                        [
                            16.582116394145512,
                            40.76251004503602
                        ],
                        [
                            16.586867215873845,
                            40.76329405813463
                        ],
                        [
                            16.589554257018563,
                            40.75545971571006
                        ],
                        [
                            16.593953839804605,
                            40.75265881116913
                        ],
                        [
                            16.600717346085855,
                            40.75542740184072
                        ],
                        [
                            16.608062096803025,
                            40.75496691385338
                        ],
                        [
                            16.608123129792816,
                            40.751959664338145
                        ],
                        [
                            16.62590022492879,
                            40.754408648172735
                        ],
                        [
                            16.629623786471313,
                            40.754569227439504
                        ],
                        [
                            16.627670898091516,
                            40.75211755597075
                        ],
                        [
                            16.629474608016316,
                            40.750235995435986
                        ],
                        [
                            16.636405764005957,
                            40.75813448321706
                        ],
                        [
                            16.647089018368355,
                            40.75424611088728
                        ],
                        [
                            16.635611681899938,
                            40.74732160124434
                        ],
                        [
                            16.63836528967235,
                            40.743412850066086
                        ],
                        [
                            16.642775376803034,
                            40.74611954998352
                        ],
                        [
                            16.654342994990152,
                            40.743333539363064
                        ],
                        [
                            16.64901838420731,
                            40.73812718062827
                        ],
                        [
                            16.652405573821877,
                            40.73606074372009
                        ],
                        [
                            16.658435782536053,
                            40.73801071384999
                        ],
                        [
                            16.66223889761237,
                            40.73729035620494
                        ],
                        [
                            16.663547772201312,
                            40.749223038818904
                        ],
                        [
                            16.67394617031239,
                            40.73816308459183
                        ],
                        [
                            16.7253368021601,
                            40.71329327298742
                        ],
                        [
                            16.70882806914076,
                            40.707235734376525
                        ],
                        [
                            16.70393959684266,
                            40.71158674429548
                        ],
                        [
                            16.697091240375865,
                            40.70227435266766
                        ],
                        [
                            16.70988354376292,
                            40.69241450275997
                        ],
                        [
                            16.707529559518775,
                            40.69165124488592
                        ],
                        [
                            16.712319799024538,
                            40.688976140518406
                        ],
                        [
                            16.728597531244823,
                            40.688528336371135
                        ],
                        [
                            16.735998129673153,
                            40.51193040207825
                        ],
                        [
                            16.737448232207498,
                            40.504158368183994
                        ],
                        [
                            16.732052501417634,
                            40.49656412518827
                        ],
                        [
                            16.728611761605993,
                            40.49257441816021
                        ],
                        [
                            16.71950402415044,
                            40.48160645747333
                        ],
                        [
                            16.72067503326207,
                            40.47913216882383
                        ],
                        [
                            16.72354369025848,
                            40.47798727765034
                        ],
                        [
                            16.72474155002834,
                            40.476565159988155
                        ],
                        [
                            16.727776271155594,
                            40.47667963058401
                        ],
                        [
                            16.7270598114024,
                            40.47472500852601
                        ],
                        [
                            16.7296821727547,
                            40.47503658298578
                        ],
                        [
                            16.732030661317836,
                            40.47438364123272
                        ],
                        [
                            16.73475467760425,
                            40.47416055918134
                        ],
                        [
                            16.73453053089699,
                            40.473326828136265
                        ],
                        [
                            16.732940668248194,
                            40.47271542945117
                        ],
                        [
                            16.732600565516023,
                            40.47170922880528
                        ],
                        [
                            16.733648776832048,
                            40.47042392651854
                        ],
                        [
                            16.735745250695828,
                            40.46785315585201
                        ],
                        [
                            16.7375672206286,
                            40.467066306340804
                        ],
                        [
                            16.74124992262378,
                            40.4674024211176
                        ],
                        [
                            16.742466081992184,
                            40.466811299385625
                        ],
                        [
                            16.742181185200696,
                            40.46569925000538
                        ],
                        [
                            16.743721876362237,
                            40.46446166311428
                        ],
                        [
                            16.74734821945927,
                            40.464682394161784
                        ],
                        [
                            16.75024152696012,
                            40.465437978708614
                        ],
                        [
                            16.75167176860321,
                            40.467372018631636
                        ],
                        [
                            16.753583667280033,
                            40.47101642789036
                        ],
                        [
                            16.75461900914066,
                            40.46828945623403
                        ],
                        [
                            16.757106495540718,
                            40.468149323546776
                        ],
                        [
                            16.75585844650793,
                            40.46709011126414
                        ],
                        [
                            16.755850715108146,
                            40.464357515888096
                        ],
                        [
                            16.754475522763222,
                            40.463314425640434
                        ],
                        [
                            16.75947305050449,
                            40.463221083436316
                        ],
                        [
                            16.760312854289218,
                            40.46216514413554
                        ],
                        [
                            16.764470578245756,
                            40.4631277412322
                        ],
                        [
                            16.766152773637536,
                            40.46231998447969
                        ],
                        [
                            16.76876453585738,
                            40.46234561799124
                        ],
                        [
                            16.771464596183172,
                            40.46383482284004
                        ],
                        [
                            16.771854771605824,
                            40.46225585417109
                        ],
                        [
                            16.77493352069775,
                            40.462992985689986
                        ],
                        [
                            16.77725788899054,
                            40.46318206904664
                        ],
                        [
                            16.78014055513986,
                            40.460807030236424
                        ],
                        [
                            16.780639795695897,
                            40.45890467367036
                        ],
                        [
                            16.782926792437287,
                            40.459101411536324
                        ],
                        [
                            16.781742085128066,
                            40.46207712568955
                        ],
                        [
                            16.783110099322222,
                            40.46456937209769
                        ],
                        [
                            16.786847667554447,
                            40.46129345518702
                        ],
                        [
                            16.790564951391765,
                            40.461259706291614
                        ],
                        [
                            16.792702390029582,
                            40.46209232748157
                        ],
                        [
                            16.79508482498867,
                            40.46075630754953
                        ],
                        [
                            16.798126235812823,
                            40.460080163935736
                        ],
                        [
                            16.79934924784221,
                            40.45811644100739
                        ],
                        [
                            16.80173093956855,
                            40.457978372545526
                        ],
                        [
                            16.80295298132811,
                            40.45721265308241
                        ],
                        [
                            16.805859602782053,
                            40.453313068141355
                        ],
                        [
                            16.805710742877665,
                            40.4504344448187
                        ],
                        [
                            16.808279883226604,
                            40.44933881805407
                        ],
                        [
                            16.807446699868883,
                            40.44797456475217
                        ],
                        [
                            16.809219450852314,
                            40.44788462992705
                        ],
                        [
                            16.81032378269498,
                            40.44459879611492
                        ],
                        [
                            16.809115257687317,
                            40.443153385467824
                        ],
                        [
                            16.808015959280553,
                            40.44421480189956
                        ],
                        [
                            16.807010601626672,
                            40.442931535264556
                        ],
                        [
                            16.808757764322706,
                            40.44217054501249
                        ],
                        [
                            16.809135381565568,
                            40.43843432114219
                        ],
                        [
                            16.808106769253868,
                            40.43739566483457
                        ],
                        [
                            16.809719495456676,
                            40.43708346958333
                        ],
                        [
                            16.809432467342063,
                            40.43599574975711
                        ],
                        [
                            16.809393635714578,
                            40.43324083882224
                        ],
                        [
                            16.811902866814766,
                            40.43285962315012
                        ],
                        [
                            16.816347876375517,
                            40.429450545079945
                        ],
                        [
                            16.817412839003026,
                            40.42468320614617
                        ],
                        [
                            16.821297194382474,
                            40.42475166771304
                        ],
                        [
                            16.823676541046225,
                            40.42535475896421
                        ],
                        [
                            16.825355835210043,
                            40.424355118365014
                        ],
                        [
                            16.82550995954214,
                            40.421440371498704
                        ],
                        [
                            16.8238411561472,
                            40.41990091594385
                        ],
                        [
                            16.824248233384232,
                            40.4190502426015
                        ],
                        [
                            16.82600421290461,
                            40.41852627519344
                        ],
                        [
                            16.828114741170317,
                            40.41639855244757
                        ],
                        [
                            16.830990420877118,
                            40.41581542279769
                        ],
                        [
                            16.83240856005036,
                            40.41493533684911
                        ],
                        [
                            16.833512803970613,
                            40.41269531538493
                        ],
                        [
                            16.835487692061832,
                            40.41045553547903
                        ],
                        [
                            16.83729669882668,
                            40.41071109943277
                        ],
                        [
                            16.8376721507176,
                            40.41225889246819
                        ],
                        [
                            16.840990175373488,
                            40.412984106610494
                        ],
                        [
                            16.845317783311472,
                            40.41138427084225
                        ],
                        [
                            16.84763110641645,
                            40.40923720170716
                        ],
                        [
                            16.84643905471546,
                            40.40764844857023
                        ],
                        [
                            16.846239880704996,
                            40.40634576739823
                        ],
                        [
                            16.84689043353721,
                            40.40571508175691
                        ],
                        [
                            16.84840934089759,
                            40.40499563646128
                        ],
                        [
                            16.848643203391493,
                            40.403122910183576
                        ],
                        [
                            16.849784317094276,
                            40.4017152512189
                        ],
                        [
                            16.850636591813412,
                            40.401205711423394
                        ],
                        [
                            16.852471237115225,
                            40.4012065393444
                        ],
                        [
                            16.853691878287666,
                            40.40188002276334
                        ],
                        [
                            16.853658613014808,
                            40.40294113232906
                        ],
                        [
                            16.85239571040119,
                            40.40534175100676
                        ],
                        [
                            16.852788052876384,
                            40.40606084005431
                        ],
                        [
                            16.853472029623696,
                            40.40637712802221
                        ],
                        [
                            16.85540239727087,
                            40.40649070847621
                        ],
                        [
                            16.856593671381468,
                            40.406308767021216
                        ],
                        [
                            16.857818164738532,
                            40.405676219793236
                        ],
                        [
                            16.858553387345047,
                            40.4049077371842
                        ],
                        [
                            16.858993283431598,
                            40.40377402138665
                        ],
                        [
                            16.8582252647737,
                            40.401870561106364
                        ],
                        [
                            16.85875708294287,
                            40.40102974567179
                        ],
                        [
                            16.86718135597991,
                            40.39782424824005
                        ],
                        [
                            16.868870951785084,
                            40.4005162358922
                        ],
                        [
                            16.89913692119724,
                            40.433173896036294
                        ],
                        [
                            16.959678773332282,
                            40.47932821412741
                        ],
                        [
                            17.009719032651965,
                            40.50430605642417
                        ],
                        [
                            17.06509879180964,
                            40.52055094122787
                        ],
                        [
                            17.149109744510405,
                            40.44520429216125
                        ],
                        [
                            17.25574162083341,
                            40.389908896548036
                        ],
                        [
                            17.40116706912663,
                            40.3321643691781
                        ],
                        [
                            17.43710942401421,
                            40.33183696944032
                        ],
                        [
                            17.462851333919687,
                            40.31717877929624
                        ],
                        [
                            17.495884109691303,
                            40.31164506944573
                        ],
                        [
                            17.508864328475212,
                            40.29540842217923
                        ],
                        [
                            17.634590913432515,
                            40.3030554552338
                        ],
                        [
                            17.692896855810382,
                            40.303235993334496
                        ],
                        [
                            17.751401724398967,
                            40.29619245843175
                        ],
                        [
                            17.8157475630332,
                            40.28891307782526
                        ],
                        [
                            17.869955811407486,
                            40.272981874369094
                        ],
                        [
                            17.896034590469185,
                            40.24981441445662
                        ],
                        [
                            17.904186441296392,
                            40.24034681694536
                        ],
                        [
                            17.925006337229497,
                            40.22006256106114
                        ],
                        [
                            17.919562709110433,
                            40.215305734692684
                        ],
                        [
                            17.91933573743863,
                            40.19252547479553
                        ],
                        [
                            17.926246303031427,
                            40.188503319579866
                        ],
                        [
                            17.92567589905264,
                            40.18211762015117
                        ],
                        [
                            17.93803935383948,
                            40.17026030434914
                        ],
                        [
                            17.942931575349647,
                            40.1694479397676
                        ],
                        [
                            17.950425829380944,
                            40.16469484160052
                        ],
                        [
                            17.954813402303934,
                            40.15792327974738
                        ],
                        [
                            17.960525858214282,
                            40.157360337479666
                        ],
                        [
                            17.959453400321788,
                            40.15239544561658
                        ],
                        [
                            17.964093398339642,
                            40.146867611485774
                        ],
                        [
                            17.974375009564874,
                            40.14742794730082
                        ],
                        [
                            17.978372633906556,
                            40.139222344952344
                        ],
                        [
                            17.990792572031808,
                            40.128663673702164
                        ],
                        [
                            18.009212355217027,
                            40.10896534895646
                        ],
                        [
                            18.012213427456032,
                            40.099325808498804
                        ],
                        [
                            18.007241413812153,
                            40.07814240550891
                        ],
                        [
                            17.99222421759285,
                            40.062163640773406
                        ],
                        [
                            17.976755792684486,
                            40.058361168381964
                        ],
                        [
                            17.97432155982972,
                            40.05274885228559
                        ],
                        [
                            17.98559305377057,
                            40.05382896699908
                        ],
                        [
                            18.00752884867731,
                            40.045686076095336
                        ],
                        [
                            18.01586870420644,
                            40.03114818415142
                        ],
                        [
                            18.01797322016506,
                            40.007481738123545
                        ],
                        [
                            18.004026361540156,
                            39.995782673711375
                        ],
                        [
                            17.989911331117156,
                            40.0016327150511
                        ],
                        [
                            18.010074909600206,
                            39.970313628703934
                        ],
                        [
                            18.02480994840147,
                            39.963024818939914
                        ],
                        [
                            18.031320226329342,
                            39.95146961736529
                        ],
                        [
                            18.045105383816946,
                            39.93113973741612
                        ],
                        [
                            18.066727323085814,
                            39.92063341869897
                        ],
                        [
                            18.088465725183767,
                            39.89915434685838
                        ],
                        [
                            18.110659315105636,
                            39.88899723881758
                        ],
                        [
                            18.115010508149567,
                            39.888524936781835
                        ],
                        [
                            18.11974326464275,
                            39.88205030679856
                        ],
                        [
                            18.153736571606714,
                            39.8611744166125
                        ],
                        [
                            18.163349143732006,
                            39.85897134301172
                        ],
                        [
                            18.17001061152459,
                            39.85117497332217
                        ],
                        [
                            18.183970865424584,
                            39.85068698695474
                        ],
                        [
                            18.20604836137204,
                            39.836722768051914
                        ],
                        [
                            18.22381089657324,
                            39.83345993079212
                        ],
                        [
                            18.268360387773782,
                            39.83438173469008
                        ],
                        [
                            18.307402357964747,
                            39.81660380572322
                        ],
                        [
                            18.310504176047772,
                            39.80945901875427
                        ],
                        [
                            18.322317129023276,
                            39.80909597145299
                        ],
                        [
                            18.330734393966907,
                            39.7962492123018
                        ],
                        [
                            18.345588023396573,
                            39.7905261376998
                        ],
                        [
                            18.370742348003404,
                            39.79406166194289
                        ],
                        [
                            18.389163734498283,
                            39.81948985264901
                        ],
                        [
                            18.386439262981668,
                            39.841802055423415
                        ],
                        [
                            18.39243841353425,
                            39.85663122213876
                        ],
                        [
                            18.3981051687218,
                            39.8778296825902
                        ],
                        [
                            18.39563602591042,
                            39.897796275870235
                        ],
                        [
                            18.392351437520148,
                            39.915052302345174
                        ],
                        [
                            18.39988290649913,
                            39.947976137848
                        ],
                        [
                            18.40867182664732,
                            39.97657041468537
                        ],
                        [
                            18.42255693313092,
                            39.999737373641494
                        ],
                        [
                            18.429096009198673,
                            40.00286395059737
                        ],
                        [
                            18.434069718428105,
                            40.0199314150166
                        ],
                        [
                            18.445353636159325,
                            40.03086646186978
                        ],
                        [
                            18.458567621654453,
                            40.03574830059276
                        ],
                        [
                            18.475768804818046,
                            40.046121467333194
                        ],
                        [
                            18.480150091900924,
                            40.05990958367093
                        ],
                        [
                            18.485488451619375,
                            40.07761395210554
                        ],
                        [
                            18.496183816461922,
                            40.089878192899874
                        ],
                        [
                            18.520215345270316,
                            40.10461861697789
                        ],
                        [
                            18.506353994891725,
                            40.12877413309866
                        ],
                        [
                            18.516728122516554,
                            40.134005910148204
                        ],
                        [
                            18.51540523982436,
                            40.13606365953831
                        ],
                        [
                            18.510965483375855,
                            40.13793108333155
                        ],
                        [
                            18.507188426663248,
                            40.14350018145538
                        ],
                        [
                            18.491866440328806,
                            40.1496247768072
                        ],
                        [
                            18.48324011645053,
                            40.16990030713603
                        ],
                        [
                            18.464536684300185,
                            40.19240843168342
                        ],
                        [
                            18.459299495998295,
                            40.20845474113014
                        ],
                        [
                            18.465813385744696,
                            40.22750186124637
                        ],
                        [
                            18.449951699803933,
                            40.2452778149532
                        ],
                        [
                            18.441005538954556,
                            40.26573540853792
                        ],
                        [
                            18.430398739415324,
                            40.27273494644166
                        ],
                        [
                            18.43253492125558,
                            40.282424444026134
                        ],
                        [
                            18.417592442863594,
                            40.29462717108032
                        ],
                        [
                            18.3848782665861,
                            40.316849401845644
                        ],
                        [
                            18.36232435842902,
                            40.34193434394916
                        ],
                        [
                            18.34689046352787,
                            40.35199092199352
                        ],
                        [
                            18.33040921473193,
                            40.35955450702443
                        ],
                        [
                            18.303963495587908,
                            40.38684287926702
                        ],
                        [
                            18.307331905876225,
                            40.39079501357415
                        ],
                        [
                            18.27954987966092,
                            40.40854094306849
                        ],
                        [
                            18.24838159182363,
                            40.43749001953432
                        ],
                        [
                            18.207753440405526,
                            40.46508180566806
                        ],
                        [
                            18.183899862224706,
                            40.46899284115145
                        ],
                        [
                            18.167754234550046,
                            40.48225409530815
                        ],
                        [
                            18.12805280833615,
                            40.49056586087872
                        ],
                        [
                            18.117902671174306,
                            40.506129039137534
                        ],
                        [
                            18.09236324281065,
                            40.515841714540926
                        ],
                        [
                            18.07772230882344,
                            40.53286924773897
                        ],
                        [
                            18.075675706601544,
                            40.5377068997804
                        ],
                        [
                            18.036553172298454,
                            40.56455920031638
                        ],
                        [
                            18.035042844710148,
                            40.57405308878211
                        ],
                        [
                            18.042289559348063,
                            40.5808166750827
                        ],
                        [
                            18.040544477569256,
                            40.5905282883173
                        ],
                        [
                            18.04606197945222,
                            40.59800156929879
                        ],
                        [
                            18.016721225316218,
                            40.62265625537958
                        ],
                        [
                            18.015585987567476,
                            40.63782525921661
                        ],
                        [
                            18.02192301240575,
                            40.64114046699157
                        ],
                        [
                            17.99368361765403,
                            40.64806812770453
                        ],
                        [
                            17.97462270416966,
                            40.648909581323124
                        ],
                        [
                            17.967750707864923,
                            40.661505420225836
                        ],
                        [
                            17.951061832419157,
                            40.66493246423889
                        ],
                        [
                            17.94433557676092,
                            40.6765365936449
                        ],
                        [
                            17.93679213206454,
                            40.685449276391864
                        ],
                        [
                            17.931996505238118,
                            40.679483729846226
                        ],
                        [
                            17.888153483150802,
                            40.68278497751419
                        ],
                        [
                            17.85321087645326,
                            40.686991903226755
                        ],
                        [
                            17.79920859659225,
                            40.71049373771075
                        ],
                        [
                            17.803869305997353,
                            40.71491557523785
                        ],
                        [
                            17.779421202477238,
                            40.719219603770796
                        ],
                        [
                            17.73927380252715,
                            40.743191716481135
                        ],
                        [
                            17.691197911771525,
                            40.764356309171774
                        ],
                        [
                            17.57926075402807,
                            40.793885357126044
                        ],
                        [
                            17.469794225631148,
                            40.835099999250076
                        ],
                        [
                            17.471028683632966,
                            40.84167794316545
                        ],
                        [
                            17.41737294684532,
                            40.86973910298178
                        ],
                        [
                            17.38993401132626,
                            40.892593897821
                        ],
                        [
                            17.348328183790045,
                            40.90682224091756
                        ],
                        [
                            17.331947913457753,
                            40.93126655087423
                        ],
                        [
                            17.308782137374152,
                            40.95751619126966
                        ],
                        [
                            17.28496849944014,
                            40.96649387597515
                        ],
                        [
                            17.252788615557563,
                            40.984583065507195
                        ],
                        [
                            17.206989608459196,
                            41.00120076988142
                        ],
                        [
                            17.16771000561343,
                            41.0316018282748
                        ],
                        [
                            17.09363345684602,
                            41.062438088174474
                        ],
                        [
                            17.04068020929418,
                            41.07981300197375
                        ],
                        [
                            16.90926057278324,
                            41.11458513066927
                        ],
                        [
                            16.87042621925491,
                            41.14239676640267
                        ],
                        [
                            16.83665302535877,
                            41.13888430625889
                        ],
                        [
                            16.780059281950372,
                            41.15959475038582
                        ],
                        [
                            16.75536663733905,
                            41.165836870531706
                        ],
                        [
                            16.673817254767243,
                            41.1889167979532
                        ],
                        [
                            16.607694018803443,
                            41.20220729520619
                        ],
                        [
                            16.544132518296237,
                            41.23149050579394
                        ],
                        [
                            16.47711901743577,
                            41.25520924110958
                        ],
                        [
                            16.413109429026207,
                            41.28143070898628
                        ],
                        [
                            16.34541491971376,
                            41.30921260352574
                        ],
                        [
                            16.294199027864863,
                            41.320998152247824
                        ],
                        [
                            16.207854511105865,
                            41.358525383315424
                        ],
                        [
                            16.13319777128737,
                            41.38555051465846
                        ],
                        [
                            16.07112013256466,
                            41.40875992936722
                        ],
                        [
                            16.016002150450305,
                            41.4283825769524
                        ],
                        [
                            15.96601141648489,
                            41.45366348043572
                        ],
                        [
                            15.928736329273505,
                            41.48621328962159
                        ],
                        [
                            15.906001882092767,
                            41.524942361480726
                        ],
                        [
                            15.89175438573642,
                            41.574740059554046
                        ],
                        [
                            15.89956601352958,
                            41.611397760873786
                        ],
                        [
                            15.923197578888875,
                            41.62972946065736
                        ],
                        [
                            15.957268429711007,
                            41.646579381473316
                        ],
                        [
                            16.004233434586524,
                            41.66477097650909
                        ],
                        [
                            16.049150213598352,
                            41.68695832044938
                        ],
                        [
                            16.0727504658918,
                            41.70674015925101
                        ],
                        [
                            16.11682482738138,
                            41.73121473659327
                        ],
                        [
                            16.16166446315114,
                            41.754372052586206
                        ],
                        [
                            16.19517519487414,
                            41.782514455036875
                        ],
                        [
                            16.198637162907943,
                            41.816923347555985
                        ],
                        [
                            16.176308954322707,
                            41.85262799577106
                        ],
                        [
                            16.179139723758908,
                            41.88344481455576
                        ],
                        [
                            16.162096353321573,
                            41.8908877410137
                        ],
                        [
                            16.14905635001142,
                            41.910375622211035
                        ],
                        [
                            16.118355716435616,
                            41.91848983891859
                        ],
                        [
                            16.0925973430966,
                            41.93781426554597
                        ],
                        [
                            16.060655873579066,
                            41.945643834928916
                        ],
                        [
                            15.99579784813082,
                            41.94625912023372
                        ],
                        [
                            15.921322216663327,
                            41.93327635160796
                        ],
                        [
                            15.80698681814772,
                            41.923008812630684
                        ],
                        [
                            15.691380795794089,
                            41.9141310943547
                        ],
                        [
                            15.66624211956696,
                            41.920332278656986
                        ],
                        [
                            15.625843168920397,
                            41.92825920870342
                        ],
                        [
                            15.574045468077486,
                            41.91956745562035
                        ],
                        [
                            15.52266653031856,
                            41.91151788202711
                        ],
                        [
                            15.455198392974268,
                            41.90365025968819
                        ],
                        [
                            15.416426037728144,
                            41.90139869513297
                        ],
                        [
                            15.38698972183775,
                            41.90070379105205
                        ],
                        [
                            15.352262555150958,
                            41.90555166141672
                        ],
                        [
                            15.34215627008146,
                            41.91698644853696
                        ],
                        [
                            15.309321191975783,
                            41.91977647914473
                        ],
                        [
                            15.25902227469075,
                            41.92074706253683
                        ],
                        [
                            15.188160612163557,
                            41.920436851982856
                        ],
                        [
                            15.13909722906439,
                            41.926047532181
                        ]
                    ]
                ],
                "type": "Polygon"
            },
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Basilicata",
                image: 'https://i.guim.co.uk/img/media/0a801e8faa27a6e30719f00f827d696193c5529e/0_192_5616_3370/master/5616.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=64b4b88cce08388e939b584a5b999c2d'
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            16.855419226780413,
                            40.376803902524074
                        ],
                        [
                            16.856262290187686,
                            40.38179487412174
                        ],
                        [
                            16.860464846704275,
                            40.388076328289685
                        ],
                        [
                            16.8674454771984,
                            40.39770184578319
                        ],
                        [
                            16.866453132950227,
                            40.39802728567531
                        ],
                        [
                            16.861925236367995,
                            40.399690869393766
                        ],
                        [
                            16.858589340328308,
                            40.40127611226697
                        ],
                        [
                            16.857761456141674,
                            40.40230689891635
                        ],
                        [
                            16.8588550591046,
                            40.404450413506055
                        ],
                        [
                            16.856737870036312,
                            40.40621676142345
                        ],
                        [
                            16.85347890447113,
                            40.406167640819405
                        ],
                        [
                            16.853045851611256,
                            40.40456749934745
                        ],
                        [
                            16.85361718907606,
                            40.401209850884015
                        ],
                        [
                            16.85058036801152,
                            40.400822334784095
                        ],
                        [
                            16.847750761554067,
                            40.40308928747697
                        ],
                        [
                            16.84822525246406,
                            40.4050762102145
                        ],
                        [
                            16.845529618723276,
                            40.406227834844515
                        ],
                        [
                            16.847150283409093,
                            40.409313581382605
                        ],
                        [
                            16.84232412912732,
                            40.41210071257791
                        ],
                        [
                            16.838158456679423,
                            40.412304395707054
                        ],
                        [
                            16.837544527733144,
                            40.41058608113033
                        ],
                        [
                            16.835418076968665,
                            40.410547071985235
                        ],
                        [
                            16.83122747183276,
                            40.41517562621027
                        ],
                        [
                            16.82853621547423,
                            40.41561556666122
                        ],
                        [
                            16.82317622457515,
                            40.419737616785056
                        ],
                        [
                            16.825106081767927,
                            40.42168604879012
                        ],
                        [
                            16.824403147246358,
                            40.42459002604525
                        ],
                        [
                            16.817258261892135,
                            40.42422059662928
                        ],
                        [
                            16.816259149689927,
                            40.42909163584022
                        ],
                        [
                            16.81274472788803,
                            40.432116817834554
                        ],
                        [
                            16.80898863320016,
                            40.43294896178594
                        ],
                        [
                            16.806275806863454,
                            40.440924988493805
                        ],
                        [
                            16.806991427071495,
                            40.44354458566826
                        ],
                        [
                            16.809788307750694,
                            40.44485893373718
                        ],
                        [
                            16.808748689823176,
                            40.44707417152469
                        ],
                        [
                            16.8045798610315,
                            40.45040369362769
                        ],
                        [
                            16.805493829176186,
                            40.453275010482
                        ],
                        [
                            16.802711575145736,
                            40.456776398445186
                        ],
                        [
                            16.798733229870066,
                            40.4579438489277
                        ],
                        [
                            16.7930080604321,
                            40.46111184972932
                        ],
                        [
                            16.78905712273854,
                            40.45976804544253
                        ],
                        [
                            16.785358002427444,
                            40.46281431183246
                        ],
                        [
                            16.783012613682217,
                            40.462641945072704
                        ],
                        [
                            16.78370445765313,
                            40.4581648270557
                        ],
                        [
                            16.779336264491462,
                            40.45910372092163
                        ],
                        [
                            16.776523655517707,
                            40.46235031700809
                        ],
                        [
                            16.768461972889725,
                            40.461771963793865
                        ],
                        [
                            16.75939798704735,
                            40.46210668838711
                        ],
                        [
                            16.753282175628954,
                            40.46263832368649
                        ],
                        [
                            16.754277961896662,
                            40.4638738837931
                        ],
                        [
                            16.755101770783085,
                            40.46739898673056
                        ],
                        [
                            16.753572258763086,
                            40.46906110812225
                        ],
                        [
                            16.752639677520477,
                            40.4676248544734
                        ],
                        [
                            16.75047562139514,
                            40.46360289490673
                        ],
                        [
                            16.742695561907652,
                            40.464503206699675
                        ],
                        [
                            16.740438483376494,
                            40.4665361207102
                        ],
                        [
                            16.737075316646127,
                            40.4663012599452
                        ],
                        [
                            16.732067164584237,
                            40.470528096182534
                        ],
                        [
                            16.732421963074955,
                            40.47375597310267
                        ],
                        [
                            16.727144217308933,
                            40.47396877646224
                        ],
                        [
                            16.726238843697075,
                            40.47506778012482
                        ],
                        [
                            16.720532219077114,
                            40.4783633146009
                        ],
                        [
                            16.71804924065006,
                            40.48144993623757
                        ],
                        [
                            16.73644894043028,
                            40.50414173493297
                        ],
                        [
                            16.734039348812132,
                            40.51438855144812
                        ],
                        [
                            16.733539032745313,
                            40.54340171108802
                        ],
                        [
                            16.732233630523382,
                            40.58249298388799
                        ],
                        [
                            16.73034054658666,
                            40.62249625517836
                        ],
                        [
                            16.728666940040313,
                            40.665964709051934
                        ],
                        [
                            16.72795083741744,
                            40.68773688046514
                        ],
                        [
                            16.71180509674045,
                            40.68832762057332
                        ],
                        [
                            16.7065357563836,
                            40.69178257054023
                        ],
                        [
                            16.70854949810041,
                            40.69295065487887
                        ],
                        [
                            16.69603220545676,
                            40.70156572353699
                        ],
                        [
                            16.703095454650253,
                            40.712859830603406
                        ],
                        [
                            16.709916306067896,
                            40.7077357624336
                        ],
                        [
                            16.724048342431416,
                            40.713455386073804
                        ],
                        [
                            16.673598838180283,
                            40.73758045414098
                        ],
                        [
                            16.664290463116885,
                            40.74806454631917
                        ],
                        [
                            16.663074249763753,
                            40.73649943907637
                        ],
                        [
                            16.658990713977317,
                            40.73760592722962
                        ],
                        [
                            16.65288002437967,
                            40.73514061118871
                        ],
                        [
                            16.648293742803503,
                            40.737775726173965
                        ],
                        [
                            16.65391202729967,
                            40.74312121821782
                        ],
                        [
                            16.64387065174705,
                            40.745724179874145
                        ],
                        [
                            16.637954276608724,
                            40.74292956109923
                        ],
                        [
                            16.635166486314432,
                            40.74713458581911
                        ],
                        [
                            16.64642109265509,
                            40.754397125086996
                        ],
                        [
                            16.6368104059473,
                            40.75774706867503
                        ],
                        [
                            16.629358066152264,
                            40.75049749804711
                        ],
                        [
                            16.62725171058878,
                            40.75238655868401
                        ],
                        [
                            16.628291974676813,
                            40.75431296911063
                        ],
                        [
                            16.607667314301352,
                            40.75129987717935
                        ],
                        [
                            16.607696653767874,
                            40.75454486482096
                        ],
                        [
                            16.601293445537436,
                            40.75502572013869
                        ],
                        [
                            16.594299365705353,
                            40.75178213184327
                        ],
                        [
                            16.589054759035065,
                            40.75478281702297
                        ],
                        [
                            16.58619235839592,
                            40.76288489824984
                        ],
                        [
                            16.581938997854508,
                            40.76173133759514
                        ],
                        [
                            16.579278653640102,
                            40.76326568453328
                        ],
                        [
                            16.57576368082752,
                            40.763297661592844
                        ],
                        [
                            16.575038790892336,
                            40.75912919534733
                        ],
                        [
                            16.569534379114486,
                            40.75896736563337
                        ],
                        [
                            16.567337566572434,
                            40.75386404719018
                        ],
                        [
                            16.57884211203333,
                            40.74282343684877
                        ],
                        [
                            16.572121423102658,
                            40.737002321714414
                        ],
                        [
                            16.570131091071687,
                            40.7429261534831
                        ],
                        [
                            16.567746254127883,
                            40.73721887162523
                        ],
                        [
                            16.563142605853756,
                            40.73992284599183
                        ],
                        [
                            16.56030599415388,
                            40.74439289381863
                        ],
                        [
                            16.562146614680586,
                            40.747593576458485
                        ],
                        [
                            16.558406519376774,
                            40.754803066506526
                        ],
                        [
                            16.55368232546701,
                            40.7587539321261
                        ],
                        [
                            16.55231458337667,
                            40.75812421154521
                        ],
                        [
                            16.552697804249732,
                            40.75621031412237
                        ],
                        [
                            16.545552520825737,
                            40.756341098385604
                        ],
                        [
                            16.547745168150584,
                            40.7536618715238
                        ],
                        [
                            16.545795775336785,
                            40.75170438801504
                        ],
                        [
                            16.537347507328462,
                            40.753157307278514
                        ],
                        [
                            16.531843837455057,
                            40.74486991484525
                        ],
                        [
                            16.541528815692686,
                            40.742293958262906
                        ],
                        [
                            16.535735639738704,
                            40.733003784201884
                        ],
                        [
                            16.54485719680133,
                            40.72482044995951
                        ],
                        [
                            16.53889310545867,
                            40.723790138650315
                        ],
                        [
                            16.52488295518927,
                            40.7342337813223
                        ],
                        [
                            16.517673535789243,
                            40.7341883786776
                        ],
                        [
                            16.51588306020355,
                            40.739616185913775
                        ],
                        [
                            16.52317048212811,
                            40.74186402471551
                        ],
                        [
                            16.521794959332766,
                            40.74565045274642
                        ],
                        [
                            16.51624204023358,
                            40.7489290279035
                        ],
                        [
                            16.511713338923556,
                            40.748313346565
                        ],
                        [
                            16.50723071976543,
                            40.753467095838346
                        ],
                        [
                            16.51130473106096,
                            40.75463319747402
                        ],
                        [
                            16.501976859798333,
                            40.76172184277098
                        ],
                        [
                            16.497557075594898,
                            40.75624656483748
                        ],
                        [
                            16.479347293116433,
                            40.735948633914205
                        ],
                        [
                            16.473765637075275,
                            40.73184367297884
                        ],
                        [
                            16.446200905000012,
                            40.71097899891717
                        ],
                        [
                            16.434712726050492,
                            40.708635936928175
                        ],
                        [
                            16.426919274275804,
                            40.69992317120327
                        ],
                        [
                            16.40473477836926,
                            40.702887417592876
                        ],
                        [
                            16.39129810690534,
                            40.7179320541272
                        ],
                        [
                            16.381636314516186,
                            40.73513304942017
                        ],
                        [
                            16.348548832982175,
                            40.74861825754857
                        ],
                        [
                            16.31686098460966,
                            40.78311551602377
                        ],
                        [
                            16.27866595248301,
                            40.80036752201738
                        ],
                        [
                            16.267850960691618,
                            40.81557104996537
                        ],
                        [
                            16.255488381664975,
                            40.823667891842945
                        ],
                        [
                            16.24985370897369,
                            40.8352044024424
                        ],
                        [
                            16.243181955843568,
                            40.836806867372104
                        ],
                        [
                            16.240683972706393,
                            40.84736968184046
                        ],
                        [
                            16.230007560114558,
                            40.85354964418684
                        ],
                        [
                            16.224542644409865,
                            40.88440448019429
                        ],
                        [
                            16.213403869264084,
                            40.908880049421384
                        ],
                        [
                            16.200188066391433,
                            40.91872492545582
                        ],
                        [
                            16.18542668872712,
                            40.92079954602346
                        ],
                        [
                            16.16902709832499,
                            40.927819282854585
                        ],
                        [
                            16.162887323121737,
                            40.928216949217216
                        ],
                        [
                            16.160539643101828,
                            40.92437163126988
                        ],
                        [
                            16.154136890724345,
                            40.92198441075618
                        ],
                        [
                            16.14614816406433,
                            40.92372688766878
                        ],
                        [
                            16.142305635318337,
                            40.91949364543868
                        ],
                        [
                            16.13650223890062,
                            40.91688245542625
                        ],
                        [
                            16.134966637106146,
                            40.91244595472827
                        ],
                        [
                            16.13595136092306,
                            40.90721522615419
                        ],
                        [
                            16.126943718294854,
                            40.901622239031184
                        ],
                        [
                            16.124256454455463,
                            40.90147031020942
                        ],
                        [
                            16.120713644062022,
                            40.89683549851085
                        ],
                        [
                            16.115869946150326,
                            40.901878961350405
                        ],
                        [
                            16.10721830246851,
                            40.90593398905486
                        ],
                        [
                            16.099883880698115,
                            40.91632364508388
                        ],
                        [
                            16.09401733347954,
                            40.917602814809555
                        ],
                        [
                            16.092941369073287,
                            40.92026599476711
                        ],
                        [
                            16.082616693755853,
                            40.92383509205976
                        ],
                        [
                            16.07079412501014,
                            40.92535528132984
                        ],
                        [
                            16.065545604864667,
                            40.928212355238884
                        ],
                        [
                            16.057918848747875,
                            40.928819005764126
                        ],
                        [
                            16.050009637721217,
                            40.935787323679364
                        ],
                        [
                            15.982611445271687,
                            40.95414688974569
                        ],
                        [
                            15.975146431020875,
                            40.95870245876387
                        ],
                        [
                            15.972861655100957,
                            40.97191022024114
                        ],
                        [
                            16.00315295056086,
                            40.97079636602561
                        ],
                        [
                            16.00835818690328,
                            40.97290136520911
                        ],
                        [
                            16.011936569177294,
                            40.98027671231418
                        ],
                        [
                            16.02424723669725,
                            40.98791576988731
                        ],
                        [
                            16.02875137235202,
                            40.99740028178027
                        ],
                        [
                            16.031188174423477,
                            40.99846391563142
                        ],
                        [
                            16.030621729220286,
                            41.00630337402805
                        ],
                        [
                            16.026637545424677,
                            41.01127384585294
                        ],
                        [
                            16.027464245442076,
                            41.02516195190432
                        ],
                        [
                            16.032298776461772,
                            41.02786542961323
                        ],
                        [
                            16.04012457644197,
                            41.02818705563706
                        ],
                        [
                            16.037123522283594,
                            41.038045553256325
                        ],
                        [
                            16.033945775387593,
                            41.04102392251477
                        ],
                        [
                            16.030786705401397,
                            41.04187347883601
                        ],
                        [
                            16.025559154320035,
                            41.050894407083774
                        ],
                        [
                            16.020996703920645,
                            41.05074606423656
                        ],
                        [
                            16.00742797632182,
                            41.058781522919304
                        ],
                        [
                            15.999847093742858,
                            41.05221063173664
                        ],
                        [
                            15.998808402300313,
                            41.05285743879841
                        ],
                        [
                            15.995376226348952,
                            41.05732402616417
                        ],
                        [
                            15.997535313371088,
                            41.06058879742818
                        ],
                        [
                            15.98807840712098,
                            41.06776682437723
                        ],
                        [
                            15.979149131719765,
                            41.080509010428756
                        ],
                        [
                            15.983614315759667,
                            41.085377715675605
                        ],
                        [
                            15.982750184285123,
                            41.088399195157365
                        ],
                        [
                            15.976945999603316,
                            41.09372477478678
                        ],
                        [
                            15.954989806139622,
                            41.09246478837096
                        ],
                        [
                            15.960816535099696,
                            41.099935804965384
                        ],
                        [
                            15.954945113362811,
                            41.110953227916156
                        ],
                        [
                            15.947621456983597,
                            41.11004137369264
                        ],
                        [
                            15.9377387256296,
                            41.11320035118149
                        ],
                        [
                            15.93274276535331,
                            41.1079124675631
                        ],
                        [
                            15.925827271579323,
                            41.112811147889545
                        ],
                        [
                            15.920046046147633,
                            41.11105934616921
                        ],
                        [
                            15.901963273480447,
                            41.125412114952724
                        ],
                        [
                            15.896398745230812,
                            41.12345057686747
                        ],
                        [
                            15.89623447536303,
                            41.12500946333543
                        ],
                        [
                            15.892991917089972,
                            41.12225357632903
                        ],
                        [
                            15.878674552235452,
                            41.13911395419038
                        ],
                        [
                            15.87498744494522,
                            41.13689757021467
                        ],
                        [
                            15.870519374157482,
                            41.13989397850543
                        ],
                        [
                            15.865269156138282,
                            41.13016074901199
                        ],
                        [
                            15.850825916295264,
                            41.13353180385249
                        ],
                        [
                            15.846663511632443,
                            41.12421223572511
                        ],
                        [
                            15.83916206487848,
                            41.12326593034885
                        ],
                        [
                            15.839382520321436,
                            41.129007441859386
                        ],
                        [
                            15.83684037362056,
                            41.13084998599854
                        ],
                        [
                            15.830514330320256,
                            41.12730474455617
                        ],
                        [
                            15.83065413147213,
                            41.11786604660773
                        ],
                        [
                            15.824969828941477,
                            41.116874959895426
                        ],
                        [
                            15.82219345727836,
                            41.11963288705459
                        ],
                        [
                            15.819561282830279,
                            41.116770107280416
                        ],
                        [
                            15.816338739218935,
                            41.11781464993513
                        ],
                        [
                            15.810229347983295,
                            41.11564152043857
                        ],
                        [
                            15.80600286413059,
                            41.11032172339324
                        ],
                        [
                            15.797996201454652,
                            41.11108045600574
                        ],
                        [
                            15.80035750433322,
                            41.10458075443688
                        ],
                        [
                            15.804620262815774,
                            41.103470583575586
                        ],
                        [
                            15.800984252549625,
                            41.099489540280075
                        ],
                        [
                            15.803283393389535,
                            41.09701364398279
                        ],
                        [
                            15.799171736184206,
                            41.09488015219881
                        ],
                        [
                            15.799149075205975,
                            41.09164804799224
                        ],
                        [
                            15.787568308244857,
                            41.087155369777975
                        ],
                        [
                            15.78805553265994,
                            41.08996505156409
                        ],
                        [
                            15.781024361537533,
                            41.08918798876968
                        ],
                        [
                            15.777818353845959,
                            41.086554793384465
                        ],
                        [
                            15.774836330427888,
                            41.08668170008909
                        ],
                        [
                            15.767440623329946,
                            41.082859160307805
                        ],
                        [
                            15.764243254381824,
                            41.08727505261862
                        ],
                        [
                            15.758806104655093,
                            41.0865877669095
                        ],
                        [
                            15.758532285795468,
                            41.0923707143011
                        ],
                        [
                            15.75314605501839,
                            41.0913541659701
                        ],
                        [
                            15.75335396497917,
                            41.09684871013718
                        ],
                        [
                            15.73954627148865,
                            41.099798597119474
                        ],
                        [
                            15.730632419660765,
                            41.09868049084287
                        ],
                        [
                            15.725630386058981,
                            41.09026918033291
                        ],
                        [
                            15.698939412039891,
                            41.086307591707225
                        ],
                        [
                            15.690149941940405,
                            41.086684939996445
                        ],
                        [
                            15.666014690536343,
                            41.087525464301734
                        ],
                        [
                            15.6670255605558,
                            41.09256927923576
                        ],
                        [
                            15.652043434570885,
                            41.088682632212226
                        ],
                        [
                            15.63664705812377,
                            41.09141481257387
                        ],
                        [
                            15.619297836481678,
                            41.09942778560884
                        ],
                        [
                            15.603448981905728,
                            41.09515538045005
                        ],
                        [
                            15.599383686388705,
                            41.09934361827749
                        ],
                        [
                            15.593322007973484,
                            41.0959674816431
                        ],
                        [
                            15.58355222607301,
                            41.09786697769249
                        ],
                        [
                            15.563528787887918,
                            41.08995489985611
                        ],
                        [
                            15.554281241535278,
                            41.07707144765041
                        ],
                        [
                            15.540185610949209,
                            41.07173518590801
                        ],
                        [
                            15.543182842756323,
                            41.06622462388782
                        ],
                        [
                            15.53965357472569,
                            41.061442329658064
                        ],
                        [
                            15.546622245841064,
                            41.053098187986876
                        ],
                        [
                            15.549951110437405,
                            41.05248733572415
                        ],
                        [
                            15.552385563505283,
                            41.03795145340325
                        ],
                        [
                            15.555222715131976,
                            41.03797386131461
                        ],
                        [
                            15.55948620181286,
                            41.033030460443456
                        ],
                        [
                            15.561115408684287,
                            41.03299577303309
                        ],
                        [
                            15.563987833319231,
                            41.03099877365523
                        ],
                        [
                            15.561934601746032,
                            41.02800430835936
                        ],
                        [
                            15.561391852434355,
                            41.02621824261479
                        ],
                        [
                            15.558708063104234,
                            41.02294183049173
                        ],
                        [
                            15.560467746988706,
                            41.020236021927275
                        ],
                        [
                            15.56034939083247,
                            41.016090627620116
                        ],
                        [
                            15.563490718732169,
                            41.01578358273599
                        ],
                        [
                            15.56693344050339,
                            41.012708253352116
                        ],
                        [
                            15.566057773144735,
                            41.01021869537411
                        ],
                        [
                            15.56745149998514,
                            41.008831395820266
                        ],
                        [
                            15.567513782815183,
                            41.00394220164068
                        ],
                        [
                            15.570044438423537,
                            41.00196545180361
                        ],
                        [
                            15.573327153345389,
                            40.99746921961892
                        ],
                        [
                            15.569747596545398,
                            40.99228974587004
                        ],
                        [
                            15.568365861207127,
                            40.98444898509695
                        ],
                        [
                            15.570763999716803,
                            40.981313868659925
                        ],
                        [
                            15.566913678976505,
                            40.97130911595022
                        ],
                        [
                            15.567862966596692,
                            40.96904681953953
                        ],
                        [
                            15.565393753157736,
                            40.966608107362816
                        ],
                        [
                            15.560610129716395,
                            40.96149030399266
                        ],
                        [
                            15.554712449375046,
                            40.958661206463205
                        ],
                        [
                            15.553502853436811,
                            40.955482823642996
                        ],
                        [
                            15.548169667904261,
                            40.95301618630549
                        ],
                        [
                            15.543943154020297,
                            40.94730328723955
                        ],
                        [
                            15.54245449784571,
                            40.94474726478583
                        ],
                        [
                            15.544599840420204,
                            40.94083371993216
                        ],
                        [
                            15.538799162922544,
                            40.938189562792786
                        ],
                        [
                            15.538171656312471,
                            40.93718561111862
                        ],
                        [
                            15.539978361971322,
                            40.93642348908156
                        ],
                        [
                            15.54134265988472,
                            40.93477646854436
                        ],
                        [
                            15.538589902045999,
                            40.930128333447755
                        ],
                        [
                            15.537661052726463,
                            40.926412794885806
                        ],
                        [
                            15.534395318128134,
                            40.92252059589944
                        ],
                        [
                            15.535756091432631,
                            40.91931744005478
                        ],
                        [
                            15.532559867558831,
                            40.918348553051345
                        ],
                        [
                            15.528972075133368,
                            40.914361828591694
                        ],
                        [
                            15.530224512188255,
                            40.91096124341328
                        ],
                        [
                            15.527718477571767,
                            40.907079688371084
                        ],
                        [
                            15.521614321985055,
                            40.907134707962086
                        ],
                        [
                            15.515594372823443,
                            40.903284127419425
                        ],
                        [
                            15.510791258601103,
                            40.90488445700066
                        ],
                        [
                            15.504844193237659,
                            40.90311321369725
                        ],
                        [
                            15.500853943468229,
                            40.8995993720886
                        ],
                        [
                            15.493217235426812,
                            40.89751011074631
                        ],
                        [
                            15.467771277786596,
                            40.88927236984003
                        ],
                        [
                            15.470145667049053,
                            40.88516114896274
                        ],
                        [
                            15.46935335872601,
                            40.880627486343116
                        ],
                        [
                            15.473279574328586,
                            40.87497330609196
                        ],
                        [
                            15.472357756638104,
                            40.872946598992286
                        ],
                        [
                            15.468580874720686,
                            40.87233721742675
                        ],
                        [
                            15.462905558544406,
                            40.86787985438045
                        ],
                        [
                            15.460893683375701,
                            40.8668366758009
                        ],
                        [
                            15.453295141127256,
                            40.86809685659321
                        ],
                        [
                            15.44979866743111,
                            40.872931786887364
                        ],
                        [
                            15.448013797096706,
                            40.87310896608852
                        ],
                        [
                            15.445913816278761,
                            40.87584113934395
                        ],
                        [
                            15.443235639758086,
                            40.877244177085686
                        ],
                        [
                            15.44196958728159,
                            40.876673308579
                        ],
                        [
                            15.4400137203138,
                            40.87530103143337
                        ],
                        [
                            15.433233597509997,
                            40.876830559055264
                        ],
                        [
                            15.429721056321739,
                            40.87685577066918
                        ],
                        [
                            15.4295866516876,
                            40.877968311150454
                        ],
                        [
                            15.430459744380698,
                            40.87888811036697
                        ],
                        [
                            15.429757483468137,
                            40.87951671322713
                        ],
                        [
                            15.426118262104893,
                            40.87838018089519
                        ],
                        [
                            15.423430998846413,
                            40.87965216964173
                        ],
                        [
                            15.42016410963734,
                            40.87875271120268
                        ],
                        [
                            15.416015084199444,
                            40.88108610912454
                        ],
                        [
                            15.407167167532663,
                            40.87973751102777
                        ],
                        [
                            15.403068716002451,
                            40.88155407580976
                        ],
                        [
                            15.401601291964681,
                            40.88073143384071
                        ],
                        [
                            15.400837989601598,
                            40.879279282887495
                        ],
                        [
                            15.402251298565034,
                            40.877302551137774
                        ],
                        [
                            15.399419100644678,
                            40.876565244835575
                        ],
                        [
                            15.397385635717256,
                            40.876821001543306
                        ],
                        [
                            15.396349002312348,
                            40.87831777994214
                        ],
                        [
                            15.395304476989764,
                            40.87896832809099
                        ],
                        [
                            15.394010180396975,
                            40.87813626344885
                        ],
                        [
                            15.393049736444965,
                            40.876437557736324
                        ],
                        [
                            15.388340400694233,
                            40.874971554123874
                        ],
                        [
                            15.382911432802644,
                            40.87628309773777
                        ],
                        [
                            15.38068965373779,
                            40.8802585535463
                        ],
                        [
                            15.378788771394028,
                            40.88011528558599
                        ],
                        [
                            15.375460622362084,
                            40.88337800378389
                        ],
                        [
                            15.375529639393136,
                            40.88558673098902
                        ],
                        [
                            15.372268262202397,
                            40.885525946295246
                        ],
                        [
                            15.369810867084851,
                            40.8841799262174
                        ],
                        [
                            15.367550462130339,
                            40.88464373369678
                        ],
                        [
                            15.365335142450135,
                            40.88393407667698
                        ],
                        [
                            15.363062223205674,
                            40.884268564481886
                        ],
                        [
                            15.361555833493753,
                            40.88377586403129
                        ],
                        [
                            15.361453260555834,
                            40.88202722140153
                        ],
                        [
                            15.357869123183264,
                            40.88127312217358
                        ],
                        [
                            15.3559986142335,
                            40.88294736546072
                        ],
                        [
                            15.354411514380786,
                            40.881680836809295
                        ],
                        [
                            15.35340169928429,
                            40.87622509187301
                        ],
                        [
                            15.35686855799409,
                            40.8711442784803
                        ],
                        [
                            15.363127640049925,
                            40.87218202765817
                        ],
                        [
                            15.370103324784651,
                            40.871878072966155
                        ],
                        [
                            15.37500633098793,
                            40.86819637017999
                        ],
                        [
                            15.380050387601813,
                            40.86782083090387
                        ],
                        [
                            15.374402911531746,
                            40.86091396696858
                        ],
                        [
                            15.376108103792888,
                            40.859244903632344
                        ],
                        [
                            15.378966068904646,
                            40.835544262176256
                        ],
                        [
                            15.373216399008015,
                            40.834132788359824
                        ],
                        [
                            15.360750367544483,
                            40.838744347710616
                        ],
                        [
                            15.335821241557909,
                            40.83407782056659
                        ],
                        [
                            15.340444966191455,
                            40.829369049176734
                        ],
                        [
                            15.346856174852348,
                            40.82929909348417
                        ],
                        [
                            15.353984420580389,
                            40.82475847621839
                        ],
                        [
                            15.359540088375354,
                            40.824609680441824
                        ],
                        [
                            15.3571414868737,
                            40.8215043089281
                        ],
                        [
                            15.36688174518838,
                            40.812635544438194
                        ],
                        [
                            15.376665427409904,
                            40.81338829638062
                        ],
                        [
                            15.381143319545743,
                            40.81193600440787
                        ],
                        [
                            15.379499528347878,
                            40.80577015949717
                        ],
                        [
                            15.373438353719195,
                            40.804776293342144
                        ],
                        [
                            15.367468515020363,
                            40.80071442743309
                        ],
                        [
                            15.36730425277554,
                            40.79772753271797
                        ],
                        [
                            15.378512044929607,
                            40.79480840186977
                        ],
                        [
                            15.385431753704665,
                            40.79802565708218
                        ],
                        [
                            15.389825340345453,
                            40.79494499218751
                        ],
                        [
                            15.38518020050563,
                            40.790543374664026
                        ],
                        [
                            15.38551202786195,
                            40.78439981263901
                        ],
                        [
                            15.381271958525986,
                            40.78122086075936
                        ],
                        [
                            15.381944182714392,
                            40.778056155687665
                        ],
                        [
                            15.385495077680815,
                            40.776723166017774
                        ],
                        [
                            15.386915431325377,
                            40.77032683439515
                        ],
                        [
                            15.391465084254293,
                            40.76898093989266
                        ],
                        [
                            15.384280073356479,
                            40.7633675182544
                        ],
                        [
                            15.384936495472033,
                            40.75753825391058
                        ],
                        [
                            15.380796337458776,
                            40.75474166937289
                        ],
                        [
                            15.382599688983277,
                            40.74210164204638
                        ],
                        [
                            15.382249829867447,
                            40.738039797515995
                        ],
                        [
                            15.384257655255482,
                            40.73609761016678
                        ],
                        [
                            15.384330663674149,
                            40.72883980171929
                        ],
                        [
                            15.381537083180206,
                            40.72683590720251
                        ],
                        [
                            15.393147707575167,
                            40.71746075759049
                        ],
                        [
                            15.404535192367462,
                            40.71662567619893
                        ],
                        [
                            15.410140014218143,
                            40.714031954681246
                        ],
                        [
                            15.417111130546687,
                            40.70815202769592
                        ],
                        [
                            15.416330384830843,
                            40.704782333050986
                        ],
                        [
                            15.423099164141263,
                            40.702545319969374
                        ],
                        [
                            15.424263077848394,
                            40.69708133451829
                        ],
                        [
                            15.429504118083116,
                            40.69237696138231
                        ],
                        [
                            15.441730874313379,
                            40.68989601567492
                        ],
                        [
                            15.445915004219898,
                            40.687446493308556
                        ],
                        [
                            15.450096731166411,
                            40.68916523143159
                        ],
                        [
                            15.456521999630922,
                            40.679786501181724
                        ],
                        [
                            15.471229192400898,
                            40.67357301017927
                        ],
                        [
                            15.486704824484463,
                            40.67312232640202
                        ],
                        [
                            15.491938757655333,
                            40.665043533202606
                        ],
                        [
                            15.499924087750173,
                            40.66615002404717
                        ],
                        [
                            15.503712599694722,
                            40.66414830658198
                        ],
                        [
                            15.500980478365534,
                            40.65480082579319
                        ],
                        [
                            15.489982367648025,
                            40.64522248942103
                        ],
                        [
                            15.467602197793155,
                            40.637891402391716
                        ],
                        [
                            15.465388843822325,
                            40.627235499817004
                        ],
                        [
                            15.457600913394828,
                            40.622786479800936
                        ],
                        [
                            15.457846238083619,
                            40.61790122452075
                        ],
                        [
                            15.454022361094786,
                            40.616909885484745
                        ],
                        [
                            15.454424336979315,
                            40.612823063481756
                        ],
                        [
                            15.451557437505016,
                            40.60830517298095
                        ],
                        [
                            15.462449031919022,
                            40.60467975111516
                        ],
                        [
                            15.47287711137713,
                            40.59414239033674
                        ],
                        [
                            15.48504973207136,
                            40.594267023690435
                        ],
                        [
                            15.488745519615463,
                            40.592694413596064
                        ],
                        [
                            15.490669651830189,
                            40.58805102273604
                        ],
                        [
                            15.50094527014943,
                            40.58570221843101
                        ],
                        [
                            15.504403723210515,
                            40.58843905836912
                        ],
                        [
                            15.510209881904274,
                            40.58555943953198
                        ],
                        [
                            15.515586684498743,
                            40.58002281750129
                        ],
                        [
                            15.514449460735591,
                            40.574144319845715
                        ],
                        [
                            15.521198548984017,
                            40.57088145498133
                        ],
                        [
                            15.533727976119536,
                            40.572614194241794
                        ],
                        [
                            15.53992449911783,
                            40.56868393774164
                        ],
                        [
                            15.536125751910731,
                            40.56377756608444
                        ],
                        [
                            15.527743373276223,
                            40.55751465201959
                        ],
                        [
                            15.531348925543483,
                            40.55637462197549
                        ],
                        [
                            15.53298344749706,
                            40.54894778645598
                        ],
                        [
                            15.53771233460094,
                            40.54714578512724
                        ],
                        [
                            15.539657970718395,
                            40.54002563098928
                        ],
                        [
                            15.537722784476301,
                            40.53535350401452
                        ],
                        [
                            15.546446167687833,
                            40.52975151409174
                        ],
                        [
                            15.540023373940357,
                            40.5279539235234
                        ],
                        [
                            15.541113893753248,
                            40.52371210294646
                        ],
                        [
                            15.53654543596781,
                            40.520843537426174
                        ],
                        [
                            15.539835060765455,
                            40.516255683580425
                        ],
                        [
                            15.547054719252657,
                            40.51405236236033
                        ],
                        [
                            15.550832518147416,
                            40.50936675165502
                        ],
                        [
                            15.536476757314801,
                            40.499556878572974
                        ],
                        [
                            15.541428852500644,
                            40.49219146733017
                        ],
                        [
                            15.53889809803502,
                            40.48877655703137
                        ],
                        [
                            15.549745806686786,
                            40.48295930234244
                        ],
                        [
                            15.55572583237398,
                            40.47803936111532
                        ],
                        [
                            15.568428591985452,
                            40.47606004455508
                        ],
                        [
                            15.572497120686478,
                            40.47017759089951
                        ],
                        [
                            15.579041585156688,
                            40.469072058870154
                        ],
                        [
                            15.579946996031907,
                            40.465102475136746
                        ],
                        [
                            15.57717072991528,
                            40.46211714349215
                        ],
                        [
                            15.587529812808896,
                            40.45476962268182
                        ],
                        [
                            15.587776358454505,
                            40.449297744382875
                        ],
                        [
                            15.59235513053332,
                            40.44537447598361
                        ],
                        [
                            15.588746942754284,
                            40.442926183250194
                        ],
                        [
                            15.595922883851955,
                            40.44076363484939
                        ],
                        [
                            15.605624336060913,
                            40.434000481950164
                        ],
                        [
                            15.63373895313083,
                            40.42300456007504
                        ],
                        [
                            15.641652984773913,
                            40.415022737460305
                        ],
                        [
                            15.648459866760987,
                            40.41507935593691
                        ],
                        [
                            15.663728387882458,
                            40.39278656587433
                        ],
                        [
                            15.672635242465333,
                            40.3977308203107
                        ],
                        [
                            15.678056544617192,
                            40.396472628721796
                        ],
                        [
                            15.691677843403596,
                            40.3872537001416
                        ],
                        [
                            15.703901081539016,
                            40.38337766966714
                        ],
                        [
                            15.70637641502256,
                            40.37833225263168
                        ],
                        [
                            15.711626412117255,
                            40.37746612261091
                        ],
                        [
                            15.710570108863749,
                            40.3681033236671
                        ],
                        [
                            15.703144090141421,
                            40.36079518550616
                        ],
                        [
                            15.70629094160438,
                            40.35736868933469
                        ],
                        [
                            15.704243171962844,
                            40.35087787307796
                        ],
                        [
                            15.707301132902558,
                            40.34987942692189
                        ],
                        [
                            15.70651972864141,
                            40.3465688315913
                        ],
                        [
                            15.721390777352468,
                            40.32422778489217
                        ],
                        [
                            15.732138545894259,
                            40.322014704169845
                        ],
                        [
                            15.728490106083626,
                            40.31476863883975
                        ],
                        [
                            15.744177086694947,
                            40.30084050580109
                        ],
                        [
                            15.750716239945461,
                            40.29833091994689
                        ],
                        [
                            15.756685819551706,
                            40.299720536403214
                        ],
                        [
                            15.765833267998772,
                            40.29833595256727
                        ],
                        [
                            15.769453681462352,
                            40.29028807484707
                        ],
                        [
                            15.778264169667182,
                            40.29282853885971
                        ],
                        [
                            15.784456279870142,
                            40.28980619304585
                        ],
                        [
                            15.794063057666051,
                            40.28905827047498
                        ],
                        [
                            15.799235874464074,
                            40.279691868863154
                        ],
                        [
                            15.80306418464508,
                            40.278698618665004
                        ],
                        [
                            15.807306804764579,
                            40.27181540633151
                        ],
                        [
                            15.800771046034498,
                            40.268576349806125
                        ],
                        [
                            15.799693081140475,
                            40.257087001667514
                        ],
                        [
                            15.805705797393045,
                            40.251418163611305
                        ],
                        [
                            15.79608891025805,
                            40.244418872268625
                        ],
                        [
                            15.789880020582672,
                            40.24249719603836
                        ],
                        [
                            15.788004880315555,
                            40.235355995459884
                        ],
                        [
                            15.79197665481675,
                            40.22846454356551
                        ],
                        [
                            15.79720711385761,
                            40.224090495073085
                        ],
                        [
                            15.791090221314022,
                            40.216431892656374
                        ],
                        [
                            15.786852045468208,
                            40.217559207459004
                        ],
                        [
                            15.779944638176005,
                            40.21605239689197
                        ],
                        [
                            15.774205992163502,
                            40.21619674233776
                        ],
                        [
                            15.769192934236543,
                            40.214832373459984
                        ],
                        [
                            15.766690079759712,
                            40.21060487429139
                        ],
                        [
                            15.752554964828278,
                            40.2040812444348
                        ],
                        [
                            15.752647653720459,
                            40.200356771180424
                        ],
                        [
                            15.746809714147133,
                            40.19866956829853
                        ],
                        [
                            15.73957267130234,
                            40.20214179537487
                        ],
                        [
                            15.730658981246052,
                            40.19781617788394
                        ],
                        [
                            15.733642495331083,
                            40.195471710655795
                        ],
                        [
                            15.72878887035256,
                            40.190208231436316
                        ],
                        [
                            15.730783620827793,
                            40.185229727589814
                        ],
                        [
                            15.72156429461387,
                            40.181220170317204
                        ],
                        [
                            15.712792648699718,
                            40.176993756856064
                        ],
                        [
                            15.716704032588268,
                            40.174753016484914
                        ],
                        [
                            15.71781826230847,
                            40.169482171999675
                        ],
                        [
                            15.701667955156609,
                            40.15815515016186
                        ],
                        [
                            15.70326750341178,
                            40.15177161615014
                        ],
                        [
                            15.708393345098273,
                            40.14654580991308
                        ],
                        [
                            15.711013970132626,
                            40.135019055884385
                        ],
                        [
                            15.70699840021527,
                            40.13419918656976
                        ],
                        [
                            15.707568773480148,
                            40.12841293508848
                        ],
                        [
                            15.703551542060495,
                            40.12016841208512
                        ],
                        [
                            15.706870697783557,
                            40.11633618139737
                        ],
                        [
                            15.694195426580848,
                            40.10258394073054
                        ],
                        [
                            15.683906507439985,
                            40.08783294384119
                        ],
                        [
                            15.680894993099315,
                            40.086674210834275
                        ],
                        [
                            15.678649584233511,
                            40.083530513988734
                        ],
                        [
                            15.662487550992779,
                            40.078112185940824
                        ],
                        [
                            15.657162820458979,
                            40.08022797838066
                        ],
                        [
                            15.653716057237489,
                            40.07888007053543
                        ],
                        [
                            15.653147887306432,
                            40.07751376918651
                        ],
                        [
                            15.662004841512243,
                            40.074311980934255
                        ],
                        [
                            15.670854393838738,
                            40.07288847751323
                        ],
                        [
                            15.677231650682302,
                            40.0633746651892
                        ],
                        [
                            15.67701121573964,
                            40.05413488038431
                        ],
                        [
                            15.67217216196363,
                            40.053571043516484
                        ],
                        [
                            15.667877072167016,
                            40.0501666173067
                        ],
                        [
                            15.656543008009692,
                            40.045766732373465
                        ],
                        [
                            15.647854814538505,
                            40.043895812172735
                        ],
                        [
                            15.646380086805209,
                            40.04172764060522
                        ],
                        [
                            15.644860716717233,
                            40.04127284242122
                        ],
                        [
                            15.647951280940731,
                            40.03917973115077
                        ],
                        [
                            15.65890178096663,
                            40.038875690091544
                        ],
                        [
                            15.664146543438676,
                            40.039296063253715
                        ],
                        [
                            15.667396476743704,
                            40.03472633080716
                        ],
                        [
                            15.671623017363316,
                            40.03353460340708
                        ],
                        [
                            15.669760748983435,
                            40.03146703202236
                        ],
                        [
                            15.671975672963839,
                            40.02905338502103
                        ],
                        [
                            15.67616327494298,
                            40.03023146039291
                        ],
                        [
                            15.677553431893017,
                            40.028565002061214
                        ],
                        [
                            15.676403447428072,
                            40.02148971483328
                        ],
                        [
                            15.674966868460587,
                            40.01509386628243
                        ],
                        [
                            15.6771045826751,
                            40.010920942405534
                        ],
                        [
                            15.68363999564994,
                            40.00215927580609
                        ],
                        [
                            15.690235741580693,
                            39.99951976280272
                        ],
                        [
                            15.696884994541392,
                            39.99896417151342
                        ],
                        [
                            15.699390563188643,
                            39.99699617354503
                        ],
                        [
                            15.698111882362213,
                            39.993085842097315
                        ],
                        [
                            15.704638242043927,
                            39.9891937492969
                        ],
                        [
                            15.70933978034742,
                            39.98925465657345
                        ],
                        [
                            15.71172502889226,
                            39.98748439314846
                        ],
                        [
                            15.711500215161266,
                            39.98336258689292
                        ],
                        [
                            15.720014408768833,
                            39.97760513164508
                        ],
                        [
                            15.72329271344762,
                            39.9691254267282
                        ],
                        [
                            15.727196634780881,
                            39.968336041249216
                        ],
                        [
                            15.735230347740497,
                            39.95829347678287
                        ],
                        [
                            15.736477389439983,
                            39.94222254807542
                        ],
                        [
                            15.745772296867557,
                            39.932999290750246
                        ],
                        [
                            15.750251652205748,
                            39.93522276890036
                        ],
                        [
                            15.7562627141333,
                            39.92401793267962
                        ],
                        [
                            15.766302257376822,
                            39.930994209661264
                        ],
                        [
                            15.773380374778043,
                            39.96182175046849
                        ],
                        [
                            15.794918024786739,
                            39.9726927921013
                        ],
                        [
                            15.820705163751228,
                            40.0022069805369
                        ],
                        [
                            15.849829719706065,
                            40.0062510190956
                        ],
                        [
                            15.869725787493621,
                            39.98660739955264
                        ],
                        [
                            15.874884712811252,
                            39.996324381090886
                        ],
                        [
                            15.891386096420433,
                            39.99576292463257
                        ],
                        [
                            15.897249756006659,
                            39.98190633108615
                        ],
                        [
                            15.920690313893715,
                            39.99855649339651
                        ],
                        [
                            15.931485222808533,
                            40.00105976453571
                        ],
                        [
                            15.979456263127275,
                            39.98376524486159
                        ],
                        [
                            16.00491605648824,
                            39.98852729239459
                        ],
                        [
                            16.013391884141924,
                            39.995852146033144
                        ],
                        [
                            16.030698973921403,
                            39.993030482818995
                        ],
                        [
                            16.031860572852395,
                            39.980172783272565
                        ],
                        [
                            16.022584409269825,
                            39.97732490563391
                        ],
                        [
                            16.006669296275703,
                            39.95746882872223
                        ],
                        [
                            16.023000013844182,
                            39.93407818169652
                        ],
                        [
                            16.019452230540793,
                            39.92498437323721
                        ],
                        [
                            16.04051710262692,
                            39.912818722461566
                        ],
                        [
                            16.045432820361185,
                            39.89925084611548
                        ],
                        [
                            16.04979823523749,
                            39.895285916863884
                        ],
                        [
                            16.055309104919644,
                            39.89884981091399
                        ],
                        [
                            16.063994584496356,
                            39.8963185513054
                        ],
                        [
                            16.081057998172383,
                            39.896497648385036
                        ],
                        [
                            16.09703936659423,
                            39.90597831303225
                        ],
                        [
                            16.131896362348783,
                            39.90688850758929
                        ],
                        [
                            16.13771061718103,
                            39.90160819367375
                        ],
                        [
                            16.150886411474147,
                            39.914231073663416
                        ],
                        [
                            16.165850605494853,
                            39.91669174071123
                        ],
                        [
                            16.193551879350622,
                            39.90384906321546
                        ],
                        [
                            16.214334513366197,
                            39.897830801496184
                        ],
                        [
                            16.215612668071515,
                            39.9281381088112
                        ],
                        [
                            16.230432858004775,
                            39.92373931314907
                        ],
                        [
                            16.248942794485345,
                            39.929857508415495
                        ],
                        [
                            16.269954813346146,
                            39.9353288917072
                        ],
                        [
                            16.281818696396414,
                            39.92989858588601
                        ],
                        [
                            16.31212028405605,
                            39.932316481287785
                        ],
                        [
                            16.34033787451321,
                            39.90669590724778
                        ],
                        [
                            16.351951255395534,
                            39.898823800945024
                        ],
                        [
                            16.357675472681365,
                            39.91878405509155
                        ],
                        [
                            16.338119403712312,
                            39.93600279482118
                        ],
                        [
                            16.343296059714447,
                            39.94522676367933
                        ],
                        [
                            16.35703881162712,
                            39.953651876330355
                        ],
                        [
                            16.359894178384195,
                            39.96500131666164
                        ],
                        [
                            16.37636724455689,
                            39.98923362012016
                        ],
                        [
                            16.397479182167984,
                            40.01738754127999
                        ],
                        [
                            16.393342302213085,
                            40.04805778449219
                        ],
                        [
                            16.413519685327373,
                            40.083032185814346
                        ],
                        [
                            16.414063388578768,
                            40.10813621830778
                        ],
                        [
                            16.407940062392697,
                            40.122216244947396
                        ],
                        [
                            16.419340943360282,
                            40.126839795788015
                        ],
                        [
                            16.422154070885114,
                            40.14351910060225
                        ],
                        [
                            16.434534770585174,
                            40.12803973431545
                        ],
                        [
                            16.45113529079319,
                            40.135995983441376
                        ],
                        [
                            16.46855770130702,
                            40.134110180297824
                        ],
                        [
                            16.49465208385638,
                            40.12484019527625
                        ],
                        [
                            16.502352221070055,
                            40.13116559920866
                        ],
                        [
                            16.518380693851782,
                            40.128895648653696
                        ],
                        [
                            16.52519817339791,
                            40.12112741168255
                        ],
                        [
                            16.530984022523512,
                            40.11855110354807
                        ],
                        [
                            16.542320916079518,
                            40.12586227348052
                        ],
                        [
                            16.562289920772628,
                            40.130171579688636
                        ],
                        [
                            16.571152636908835,
                            40.126415489076834
                        ],
                        [
                            16.58569445559337,
                            40.13352847921499
                        ],
                        [
                            16.608900306037043,
                            40.12907773466853
                        ],
                        [
                            16.621183823603104,
                            40.13292186566781
                        ],
                        [
                            16.628552682264285,
                            40.129994144083525
                        ],
                        [
                            16.628540917986413,
                            40.12412411344556
                        ],
                        [
                            16.63784189108773,
                            40.12495781213039
                        ],
                        [
                            16.648353117927087,
                            40.118258007980415
                        ],
                        [
                            16.668654619078552,
                            40.13368369830448
                        ],
                        [
                            16.699194865691993,
                            40.16529948996731
                        ],
                        [
                            16.729854066258213,
                            40.199153433444735
                        ],
                        [
                            16.736793523553246,
                            40.216223122860015
                        ],
                        [
                            16.748816382406886,
                            40.243657880778215
                        ],
                        [
                            16.77053596496563,
                            40.27732224295852
                        ],
                        [
                            16.793307746110344,
                            40.310092300360424
                        ],
                        [
                            16.82505924274946,
                            40.34231207260436
                        ],
                        [
                            16.855419226780413,
                            40.376803902524074
                        ]
                    ]
                ],
                "type": "Polygon"
            },
        },
        {
            "type": "Feature",
            "properties": {
                "name": "Calabria",
                image: 'https://lp-cms-production.imgix.net/2019-06/503844741_full.jpg'
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            16.64330492790333,
                            40.115936004920286
                        ],
                        [
                            16.64700008975484,
                            40.117805225989514
                        ],
                        [
                            16.63799851580984,
                            40.12386884374308
                        ],
                        [
                            16.627770995267156,
                            40.12322725117474
                        ],
                        [
                            16.627226014089928,
                            40.12945092114535
                        ],
                        [
                            16.620308786931105,
                            40.13180499269839
                        ],
                        [
                            16.608818809689154,
                            40.1281507093189
                        ],
                        [
                            16.585930005343783,
                            40.13249379105466
                        ],
                        [
                            16.572115507854136,
                            40.125156661609395
                        ],
                        [
                            16.56174643589003,
                            40.12904901261521
                        ],
                        [
                            16.542808410284238,
                            40.12502628960459
                        ],
                        [
                            16.531174189813328,
                            40.11712865755041
                        ],
                        [
                            16.524320155561327,
                            40.12019587962237
                        ],
                        [
                            16.51760886226583,
                            40.12862434028864
                        ],
                        [
                            16.50294501848518,
                            40.130399034691465
                        ],
                        [
                            16.495118612958407,
                            40.123386937617596
                        ],
                        [
                            16.46875467171006,
                            40.13357383148684
                        ],
                        [
                            16.450556177553494,
                            40.13462492609341
                        ],
                        [
                            16.43435481875909,
                            40.12684852205197
                        ],
                        [
                            16.42281292798333,
                            40.142249013732425
                        ],
                        [
                            16.420779673680187,
                            40.12613943143046
                        ],
                        [
                            16.40875907271044,
                            40.12211858050523
                        ],
                        [
                            16.415594850064224,
                            40.107831949799305
                        ],
                        [
                            16.41475045012359,
                            40.08224593587917
                        ],
                        [
                            16.394399744660376,
                            40.04794048958766
                        ],
                        [
                            16.39935404857701,
                            40.01701642979111
                        ],
                        [
                            16.361175400686477,
                            39.96454856714567
                        ],
                        [
                            16.358845785753118,
                            39.95358996227293
                        ],
                        [
                            16.343352684363026,
                            39.94389827596834
                        ],
                        [
                            16.33921295101814,
                            39.936388748712545
                        ],
                        [
                            16.359323350207717,
                            39.919179249215006
                        ],
                        [
                            16.351857672649345,
                            39.897693429954586
                        ],
                        [
                            16.341122823589274,
                            39.905238500194855
                        ],
                        [
                            16.31138528777717,
                            39.93119308785927
                        ],
                        [
                            16.281732479524862,
                            39.929034170313855
                        ],
                        [
                            16.269522784098484,
                            39.934614296848935
                        ],
                        [
                            16.23065467890595,
                            39.92230296583904
                        ],
                        [
                            16.21679708793593,
                            39.92703437918706
                        ],
                        [
                            16.215441685313806,
                            39.89675402973651
                        ],
                        [
                            16.194024263287105,
                            39.90258706188095
                        ],
                        [
                            16.165372044256145,
                            39.9160510683852
                        ],
                        [
                            16.151598903920586,
                            39.91333449893608
                        ],
                        [
                            16.138161946866404,
                            39.89986745884596
                        ],
                        [
                            16.13135786356642,
                            39.906046818682455
                        ],
                        [
                            16.096953809559096,
                            39.90458084116935
                        ],
                        [
                            16.079893729673245,
                            39.8949108111737
                        ],
                        [
                            16.06415846127655,
                            39.89533290993347
                        ],
                        [
                            16.055381484353774,
                            39.8978138625601
                        ],
                        [
                            16.04994726119611,
                            39.89386513282872
                        ],
                        [
                            16.043778245114027,
                            39.89868469072749
                        ],
                        [
                            16.039428396537375,
                            39.9123680315227
                        ],
                        [
                            16.01782794167292,
                            39.92416654526829
                        ],
                        [
                            16.021627542264838,
                            39.93426314973365
                        ],
                        [
                            16.004880296545082,
                            39.957283685454485
                        ],
                        [
                            16.02127277774747,
                            39.97791934534948
                        ],
                        [
                            16.03054228803302,
                            39.98102028916779
                        ],
                        [
                            16.029352857785995,
                            39.99257176206348
                        ],
                        [
                            16.014396977105065,
                            39.994959403633004
                        ],
                        [
                            16.00507669053806,
                            39.987419685447264
                        ],
                        [
                            15.979478700354775,
                            39.982375790057006
                        ],
                        [
                            15.93149740028224,
                            40.00006129152089
                        ],
                        [
                            15.921066325341286,
                            39.99774598459794
                        ],
                        [
                            15.897086138059706,
                            39.98078708021663
                        ],
                        [
                            15.890255997439311,
                            39.995110366347035
                        ],
                        [
                            15.875762510117681,
                            39.99567394296784
                        ],
                        [
                            15.87004320540791,
                            39.985153096776486
                        ],
                        [
                            15.848873828842159,
                            40.005399335317094
                        ],
                        [
                            15.821627024897822,
                            40.001629349671475
                        ],
                        [
                            15.79645146734316,
                            39.97207514758216
                        ],
                        [
                            15.774324821829083,
                            39.961472962321125
                        ],
                        [
                            15.7673610744894,
                            39.93087548491309
                        ],
                        [
                            15.757162629987704,
                            39.92315087894576
                        ],
                        [
                            15.778842524669386,
                            39.89404281083674
                        ],
                        [
                            15.783617883622298,
                            39.876082696600974
                        ],
                        [
                            15.772229941661635,
                            39.8756986704349
                        ],
                        [
                            15.770165330115873,
                            39.87234366007394
                        ],
                        [
                            15.784162964258485,
                            39.87244571415161
                        ],
                        [
                            15.790969790278723,
                            39.86338126663114
                        ],
                        [
                            15.789155449152616,
                            39.849334272930406
                        ],
                        [
                            15.782313266521953,
                            39.84928015156555
                        ],
                        [
                            15.768451103199737,
                            39.83620473562513
                        ],
                        [
                            15.77111094497397,
                            39.83194818167314
                        ],
                        [
                            15.778256730632876,
                            39.831756633100696
                        ],
                        [
                            15.774196151596698,
                            39.827304925099725
                        ],
                        [
                            15.77826213309504,
                            39.820194461391594
                        ],
                        [
                            15.785589065146752,
                            39.81878450622989
                        ],
                        [
                            15.79301662519174,
                            39.783687367303116
                        ],
                        [
                            15.809477245891173,
                            39.72891091686367
                        ],
                        [
                            15.806761470444542,
                            39.71496057546264
                        ],
                        [
                            15.816186262629572,
                            39.67822669478278
                        ],
                        [
                            15.831011836033866,
                            39.66971836693176
                        ],
                        [
                            15.83782744101697,
                            39.64482543649959
                        ],
                        [
                            15.85703203642339,
                            39.603269155274035
                        ],
                        [
                            15.866118398972844,
                            39.576071429113824
                        ],
                        [
                            15.881002917312145,
                            39.544387481635965
                        ],
                        [
                            15.893754474256923,
                            39.53877718037336
                        ],
                        [
                            15.906403950989557,
                            39.53798808181995
                        ],
                        [
                            15.915314573190273,
                            39.528276723732915
                        ],
                        [
                            15.925764849819814,
                            39.527139273718035
                        ],
                        [
                            15.962408715730191,
                            39.47606805662133
                        ],
                        [
                            15.994462463177229,
                            39.44598267766537
                        ],
                        [
                            15.997460013355493,
                            39.439240490221266
                        ],
                        [
                            16.019024636963053,
                            39.38764278141332
                        ],
                        [
                            16.035572889109574,
                            39.35356186241739
                        ],
                        [
                            16.046340140832598,
                            39.3066201577777
                        ],
                        [
                            16.051446873305593,
                            39.299037524638834
                        ],
                        [
                            16.05976961286686,
                            39.210592171108175
                        ],
                        [
                            16.062679532310966,
                            39.1909919492351
                        ],
                        [
                            16.069058268632034,
                            39.13235919361966
                        ],
                        [
                            16.090067026032585,
                            39.06702521877921
                        ],
                        [
                            16.097439484393846,
                            39.045647790583985
                        ],
                        [
                            16.101078623795132,
                            39.028776142818316
                        ],
                        [
                            16.113745645307034,
                            39.0147398831902
                        ],
                        [
                            16.132142920214505,
                            38.9946338721945
                        ],
                        [
                            16.14579506098781,
                            38.97383579582811
                        ],
                        [
                            16.15265312409619,
                            38.95769912220564
                        ],
                        [
                            16.15951302942861,
                            38.94899481844848
                        ],
                        [
                            16.171266818309192,
                            38.94826294212592
                        ],
                        [
                            16.180190896574004,
                            38.941374388556
                        ],
                        [
                            16.199530579836477,
                            38.93127347057495
                        ],
                        [
                            16.207011241125457,
                            38.93263050731443
                        ],
                        [
                            16.219230681838923,
                            38.92723285923256
                        ],
                        [
                            16.21938180554841,
                            38.911393685963844
                        ],
                        [
                            16.22096337695922,
                            38.87434450406883
                        ],
                        [
                            16.218923897050672,
                            38.82565142347275
                        ],
                        [
                            16.209430934985136,
                            38.798257768350055
                        ],
                        [
                            16.19948024096044,
                            38.77467281261772
                        ],
                        [
                            16.18774332152006,
                            38.75308706797267
                        ],
                        [
                            16.178034114645214,
                            38.74269173839369
                        ],
                        [
                            16.159630000112514,
                            38.73699794117232
                        ],
                        [
                            16.1503898656714,
                            38.72710843352519
                        ],
                        [
                            16.130686815367767,
                            38.71572717347442
                        ],
                        [
                            16.122732523909036,
                            38.71722051034055
                        ],
                        [
                            16.125875145013367,
                            38.72309966930081
                        ],
                        [
                            16.107180588642336,
                            38.71191633314578
                        ],
                        [
                            16.097191312550393,
                            38.71317899256306
                        ],
                        [
                            16.066018395993552,
                            38.721291306714505
                        ],
                        [
                            16.05462858563044,
                            38.72519068956663
                        ],
                        [
                            16.039908473211256,
                            38.72680000800412
                        ],
                        [
                            16.020647508785515,
                            38.72670461820252
                        ],
                        [
                            16.003615002507644,
                            38.72244811761439
                        ],
                        [
                            15.986507776173681,
                            38.72441047739099
                        ],
                        [
                            15.9760056487317,
                            38.71524208703758
                        ],
                        [
                            15.96701005084563,
                            38.70577770122071
                        ],
                        [
                            15.94711638030779,
                            38.69108409084899
                        ],
                        [
                            15.938271081480394,
                            38.69153518958893
                        ],
                        [
                            15.918668285400514,
                            38.68465300891012
                        ],
                        [
                            15.897274048218577,
                            38.679995111623214
                        ],
                        [
                            15.880200664606036,
                            38.67254928528138
                        ],
                        [
                            15.872899934783192,
                            38.67289798271369
                        ],
                        [
                            15.86736172288201,
                            38.66789179011923
                        ],
                        [
                            15.851804607398435,
                            38.660983412069584
                        ],
                        [
                            15.845289093905393,
                            38.65398525066527
                        ],
                        [
                            15.838910500910657,
                            38.648201186022845
                        ],
                        [
                            15.829408983263875,
                            38.62915393807205
                        ],
                        [
                            15.82835310572608,
                            38.61929415735861
                        ],
                        [
                            15.838957220072842,
                            38.61265909078773
                        ],
                        [
                            15.845507519888372,
                            38.61321502969852
                        ],
                        [
                            15.851391005437222,
                            38.60724291515808
                        ],
                        [
                            15.859667246835727,
                            38.6019686812553
                        ],
                        [
                            15.868467569958796,
                            38.592767062960434
                        ],
                        [
                            15.88240726657807,
                            38.583840335926425
                        ],
                        [
                            15.88810954969216,
                            38.57909295649981
                        ],
                        [
                            15.89312673521772,
                            38.57815927505513
                        ],
                        [
                            15.900365978926118,
                            38.565894184038854
                        ],
                        [
                            15.91090949759979,
                            38.55851882369248
                        ],
                        [
                            15.923664582627794,
                            38.55148180158562
                        ],
                        [
                            15.92843163444553,
                            38.55104629472382
                        ],
                        [
                            15.931374313636297,
                            38.548056510091214
                        ],
                        [
                            15.93114107406609,
                            38.53564492024336
                        ],
                        [
                            15.922066346346725,
                            38.513544502765455
                        ],
                        [
                            15.913954171557691,
                            38.48715895821843
                        ],
                        [
                            15.904554100349799,
                            38.46462148925136
                        ],
                        [
                            15.891772113899378,
                            38.440084902456874
                        ],
                        [
                            15.885793204657716,
                            38.43227728053995
                        ],
                        [
                            15.870469231957543,
                            38.411822875058164
                        ],
                        [
                            15.86232906883302,
                            38.39124399148835
                        ],
                        [
                            15.86043484091283,
                            38.38230080714732
                        ],
                        [
                            15.8502041310999,
                            38.37549371284703
                        ],
                        [
                            15.849685577226978,
                            38.370634676739144
                        ],
                        [
                            15.836843057294885,
                            38.36420670822679
                        ],
                        [
                            15.83429365314646,
                            38.35208248001655
                        ],
                        [
                            15.830886184724704,
                            38.33193256842537
                        ],
                        [
                            15.821412434556521,
                            38.309600098084246
                        ],
                        [
                            15.806625457045158,
                            38.28956570117654
                        ],
                        [
                            15.77990212106593,
                            38.27244402669868
                        ],
                        [
                            15.748635673563086,
                            38.25660361715967
                        ],
                        [
                            15.731042282366985,
                            38.25281293736563
                        ],
                        [
                            15.707328868071585,
                            38.2512638751148
                        ],
                        [
                            15.703461853527727,
                            38.25298414750384
                        ],
                        [
                            15.699426778627327,
                            38.251648182819
                        ],
                        [
                            15.692830202848114,
                            38.251090455844746
                        ],
                        [
                            15.687499432676475,
                            38.247755426603646
                        ],
                        [
                            15.67932358627749,
                            38.247644813744955
                        ],
                        [
                            15.674998788063704,
                            38.24225606888919
                        ],
                        [
                            15.658349361253698,
                            38.23679262848509
                        ],
                        [
                            15.637234494165853,
                            38.23150211317929
                        ],
                        [
                            15.63356964961261,
                            38.221797674980024
                        ],
                        [
                            15.632627835846478,
                            38.200842897382074
                        ],
                        [
                            15.636107878770758,
                            38.186584369415726
                        ],
                        [
                            15.641848283878772,
                            38.1804364744244
                        ],
                        [
                            15.640048206144172,
                            38.17083110149028
                        ],
                        [
                            15.645863564996972,
                            38.16541840412336
                        ],
                        [
                            15.652851066507282,
                            38.15784015967219
                        ],
                        [
                            15.655889152584678,
                            38.151253370486586
                        ],
                        [
                            15.65114448993927,
                            38.14332856802835
                        ],
                        [
                            15.65636638490966,
                            38.141148681355105
                        ],
                        [
                            15.65761197588941,
                            38.13677020463575
                        ],
                        [
                            15.652611768512458,
                            38.12936334232012
                        ],
                        [
                            15.651846305811034,
                            38.12357760862943
                        ],
                        [
                            15.648056626724554,
                            38.11579715626624
                        ],
                        [
                            15.631270119400654,
                            38.10293394301834
                        ],
                        [
                            15.635072701186061,
                            38.07550852848573
                        ],
                        [
                            15.652436391715298,
                            38.066030833472865
                        ],
                        [
                            15.648253318855723,
                            38.05303752859214
                        ],
                        [
                            15.657092861529236,
                            38.03904748485817
                        ],
                        [
                            15.650298487057682,
                            38.02717903561094
                        ],
                        [
                            15.633949416790728,
                            38.018639917299794
                        ],
                        [
                            15.653613850038738,
                            37.982792086711896
                        ],
                        [
                            15.682261502868414,
                            37.95285338506649
                        ],
                        [
                            15.698541635325086,
                            37.948600180987526
                        ],
                        [
                            15.707046620567354,
                            37.93798906491834
                        ],
                        [
                            15.732258450127773,
                            37.92700657998901
                        ],
                        [
                            15.745119287608105,
                            37.926767527329176
                        ],
                        [
                            15.761395076424236,
                            37.91611888692904
                        ],
                        [
                            15.787816973431603,
                            37.91645932176492
                        ],
                        [
                            15.806194888318231,
                            37.92089344680987
                        ],
                        [
                            15.828503134654113,
                            37.919558093037196
                        ],
                        [
                            15.85520072709869,
                            37.922727965078096
                        ],
                        [
                            15.885861168782668,
                            37.927625197787506
                        ],
                        [
                            15.922135700438588,
                            37.931159664459074
                        ],
                        [
                            15.95860330195245,
                            37.92065871216387
                        ],
                        [
                            15.994566028521604,
                            37.918272429215065
                        ],
                        [
                            15.996608055548023,
                            37.91556418648233
                        ],
                        [
                            16.012132133861996,
                            37.92164358065661
                        ],
                        [
                            16.036431628494256,
                            37.925191726131374
                        ],
                        [
                            16.04258190798086,
                            37.92159284307296
                        ],
                        [
                            16.06154142623086,
                            37.92562437368454
                        ],
                        [
                            16.070677911478583,
                            37.93288509638863
                        ],
                        [
                            16.081637234346204,
                            37.94287420286868
                        ],
                        [
                            16.10663837404016,
                            37.96943011742444
                        ],
                        [
                            16.125381711523147,
                            38.00166039656628
                        ],
                        [
                            16.143420097324196,
                            38.03008174037001
                        ],
                        [
                            16.140931001931477,
                            38.04173727474438
                        ],
                        [
                            16.14903275074053,
                            38.056613688549504
                        ],
                        [
                            16.153397217375897,
                            38.08828280458604
                        ],
                        [
                            16.160808216783693,
                            38.130558440262945
                        ],
                        [
                            16.167550949402624,
                            38.14000210558214
                        ],
                        [
                            16.184521172045777,
                            38.15196377982346
                        ],
                        [
                            16.20594241726471,
                            38.17345614421913
                        ],
                        [
                            16.24741046061439,
                            38.214775410029944
                        ],
                        [
                            16.264018324614625,
                            38.2311550163937
                        ],
                        [
                            16.28217069562254,
                            38.2557696886314
                        ],
                        [
                            16.29066909415144,
                            38.26249801977369
                        ],
                        [
                            16.300757231908676,
                            38.268747909724254
                        ],
                        [
                            16.31889084035251,
                            38.29091481584581
                        ],
                        [
                            16.332967600565865,
                            38.30071904387509
                        ],
                        [
                            16.371334292233257,
                            38.30964461293084
                        ],
                        [
                            16.41858356525512,
                            38.324911945377124
                        ],
                        [
                            16.453646531231783,
                            38.334704360302965
                        ],
                        [
                            16.482844722378218,
                            38.35215112369639
                        ],
                        [
                            16.49430421770873,
                            38.359766713834176
                        ],
                        [
                            16.534355616891617,
                            38.39268363708365
                        ],
                        [
                            16.572174659703705,
                            38.428884018942085
                        ],
                        [
                            16.5808423965577,
                            38.44870015893608
                        ],
                        [
                            16.580767431981144,
                            38.48264005047315
                        ],
                        [
                            16.57335252460132,
                            38.52505526297912
                        ],
                        [
                            16.571666886128327,
                            38.56964164646445
                        ],
                        [
                            16.560631436451388,
                            38.616593638589165
                        ],
                        [
                            16.55627060603561,
                            38.66545263043207
                        ],
                        [
                            16.557056248697165,
                            38.68132181752293
                        ],
                        [
                            16.55496037651517,
                            38.69064070353414
                        ],
                        [
                            16.54765843063683,
                            38.69053356359666
                        ],
                        [
                            16.533746818193777,
                            38.70391446013505
                        ],
                        [
                            16.536454503231795,
                            38.719080586551144
                        ],
                        [
                            16.545715890240047,
                            38.728746551691614
                        ],
                        [
                            16.56878647771518,
                            38.75377130943832
                        ],
                        [
                            16.570949972578376,
                            38.76079441333502
                        ],
                        [
                            16.56461513948588,
                            38.76558066299671
                        ],
                        [
                            16.58180526096561,
                            38.783817083960855
                        ],
                        [
                            16.59829626484816,
                            38.79913853830041
                        ],
                        [
                            16.605016405300574,
                            38.81272263108832
                        ],
                        [
                            16.62731284319085,
                            38.82652270520228
                        ],
                        [
                            16.635346597345773,
                            38.8259980394667
                        ],
                        [
                            16.698839049578567,
                            38.85832407877942
                        ],
                        [
                            16.785150662420875,
                            38.90012010500965
                        ],
                        [
                            16.901303479569123,
                            38.93092500822331
                        ],
                        [
                            16.942647910378554,
                            38.936738397808796
                        ],
                        [
                            16.968936006554316,
                            38.93859430453787
                        ],
                        [
                            16.977399226775475,
                            38.93972999543075
                        ],
                        [
                            16.98997682210424,
                            38.93576938038413
                        ],
                        [
                            16.998670311670935,
                            38.93012991670818
                        ],
                        [
                            17.00038311501956,
                            38.92434540582468
                        ],
                        [
                            17.00320503171409,
                            38.92174631040342
                        ],
                        [
                            17.006197104622203,
                            38.91636141381926
                        ],
                        [
                            17.01231429266582,
                            38.91125228719832
                        ],
                        [
                            17.01992872871719,
                            38.910023247787365
                        ],
                        [
                            17.022854781005975,
                            38.905486465770394
                        ],
                        [
                            17.024276400175353,
                            38.90864882700458
                        ],
                        [
                            17.034244335948273,
                            38.916316763352256
                        ],
                        [
                            17.050601082933497,
                            38.91970718379801
                        ],
                        [
                            17.076163622656026,
                            38.91306185362808
                        ],
                        [
                            17.084359991228553,
                            38.913971790243835
                        ],
                        [
                            17.09330128420129,
                            38.905999486762425
                        ],
                        [
                            17.090354920110627,
                            38.89760928554975
                        ],
                        [
                            17.09199243332904,
                            38.89266514283787
                        ],
                        [
                            17.095914458080586,
                            38.89433697903942
                        ],
                        [
                            17.098748664506928,
                            38.9030972458462
                        ],
                        [
                            17.111965229812938,
                            38.91184493933909
                        ],
                        [
                            17.123479014246442,
                            38.920925262576475
                        ],
                        [
                            17.149364060370715,
                            38.943405965033094
                        ],
                        [
                            17.17015661951686,
                            38.9570000798665
                        ],
                        [
                            17.16058587902731,
                            38.96633900633341
                        ],
                        [
                            17.15988030898933,
                            38.97412741423145
                        ],
                        [
                            17.162973769450076,
                            38.99010618300292
                        ],
                        [
                            17.17669073699409,
                            39.009277608546086
                        ],
                        [
                            17.190676433067836,
                            39.02063879803484
                        ],
                        [
                            17.19721779509814,
                            39.02068619677266
                        ],
                        [
                            17.20461819170154,
                            39.0228494501614
                        ],
                        [
                            17.205665574562374,
                            39.02954194028851
                        ],
                        [
                            17.198271182747163,
                            39.02752143011213
                        ],
                        [
                            17.176233488398026,
                            39.0316691367
                        ],
                        [
                            17.149844688663364,
                            39.04405936891331
                        ],
                        [
                            17.13485204533825,
                            39.05784324990853
                        ],
                        [
                            17.13056629066932,
                            39.07548231101916
                        ],
                        [
                            17.136453872020354,
                            39.082163008042556
                        ],
                        [
                            17.12467489928906,
                            39.09246082189884
                        ],
                        [
                            17.113516477462753,
                            39.090099664693724
                        ],
                        [
                            17.107500101334693,
                            39.111657667782765
                        ],
                        [
                            17.11567494118512,
                            39.140485571830766
                        ],
                        [
                            17.131551903539503,
                            39.17348247501786
                        ],
                        [
                            17.147417551183935,
                            39.20569225978605
                        ],
                        [
                            17.133156348721627,
                            39.219036846433056
                        ],
                        [
                            17.120630621733085,
                            39.23506518727427
                        ],
                        [
                            17.106444033306843,
                            39.248859571233
                        ],
                        [
                            17.103771770150615,
                            39.26259212928099
                        ],
                        [
                            17.109740741665746,
                            39.28813263630343
                        ],
                        [
                            17.110579113577984,
                            39.316601115227144
                        ],
                        [
                            17.1280953082603,
                            39.34882808967933
                        ],
                        [
                            17.136191648591307,
                            39.37479685396062
                        ],
                        [
                            17.15124484877623,
                            39.391586685336335
                        ],
                        [
                            17.156095629143294,
                            39.40113796884222
                        ],
                        [
                            17.15090064161737,
                            39.39955397262983
                        ],
                        [
                            17.12459482973739,
                            39.403576420729536
                        ],
                        [
                            17.097309129032112,
                            39.41379078498014
                        ],
                        [
                            17.06962946428831,
                            39.42858699017083
                        ],
                        [
                            17.044079537787468,
                            39.446660466155606
                        ],
                        [
                            17.034657868833705,
                            39.46551666758589
                        ],
                        [
                            17.026260618088543,
                            39.48107484747874
                        ],
                        [
                            17.013207145883,
                            39.48722048237451
                        ],
                        [
                            16.980224835863737,
                            39.490908752706304
                        ],
                        [
                            16.943907689137973,
                            39.501495713262784
                        ],
                        [
                            16.940893781802288,
                            39.50661055047257
                        ],
                        [
                            16.909807799910396,
                            39.51874431302741
                        ],
                        [
                            16.902593532072274,
                            39.52533247547393
                        ],
                        [
                            16.885357974499016,
                            39.53076449024201
                        ],
                        [
                            16.85865069600959,
                            39.54571860282792
                        ],
                        [
                            16.824410241007314,
                            39.56833700893006
                        ],
                        [
                            16.817171685033486,
                            39.58784916309699
                        ],
                        [
                            16.786954058156255,
                            39.607567048148496
                        ],
                        [
                            16.780998094479912,
                            39.61561368494117
                        ],
                        [
                            16.758604679232207,
                            39.62427524179276
                        ],
                        [
                            16.734983112533058,
                            39.615644227527014
                        ],
                        [
                            16.691252079345077,
                            39.62164492443395
                        ],
                        [
                            16.653085652951148,
                            39.619394383878614
                        ],
                        [
                            16.60765796256547,
                            39.62348640779166
                        ],
                        [
                            16.572771783989083,
                            39.63824844841102
                        ],
                        [
                            16.55446674670665,
                            39.648910909497886
                        ],
                        [
                            16.52962499358449,
                            39.66657886240938
                        ],
                        [
                            16.525642296759713,
                            39.6967676643236
                        ],
                        [
                            16.53023297419017,
                            39.72408398599228
                        ],
                        [
                            16.51741768857417,
                            39.73054151867376
                        ],
                        [
                            16.495042807895544,
                            39.7446619990549
                        ],
                        [
                            16.485771054072075,
                            39.77497942863633
                        ],
                        [
                            16.493362425543438,
                            39.81324558427875
                        ],
                        [
                            16.520371714336676,
                            39.84730496402534
                        ],
                        [
                            16.535440121546912,
                            39.86782110394364
                        ],
                        [
                            16.570877869570324,
                            39.90388856561225
                        ],
                        [
                            16.58745699573413,
                            39.91549521505348
                        ],
                        [
                            16.602984254991867,
                            39.9299356702212
                        ],
                        [
                            16.61988442489553,
                            39.952313786107226
                        ],
                        [
                            16.62604316050445,
                            39.95454261851603
                        ],
                        [
                            16.626756462636905,
                            39.96805367493579
                        ],
                        [
                            16.612993420063418,
                            39.98390180535017
                        ],
                        [
                            16.60995988560782,
                            40.00994926788567
                        ],
                        [
                            16.60460171784544,
                            40.02621047455517
                        ],
                        [
                            16.598724466195648,
                            40.043468674586336
                        ],
                        [
                            16.604578371009183,
                            40.05581976587973
                        ],
                        [
                            16.602592347873212,
                            40.07321754967981
                        ],
                        [
                            16.613061050641022,
                            40.0892520753076
                        ],
                        [
                            16.626972473432915,
                            40.103789968131764
                        ],
                        [
                            16.64330492790333,
                            40.115936004920286
                        ]
                    ]
                ],
                "type": "Polygon"
            },

        },
        {
            "type": "Feature",
            "properties": {
                "name": "Sicilia",
                image: 'https://content.api.news/v3/images/bin/f82dfbbabbe5522db96a14d65e91c2e6'

            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            15.225581971436299,
                            38.26763921739203
                        ],
                        [
                            15.23670572386581,
                            38.267154018517914
                        ],
                        [
                            15.236087737620494,
                            38.25575091195921
                        ],
                        [
                            15.239486661972848,
                            38.244588691649625
                        ],
                        [
                            15.235778744496344,
                            38.22614300673783
                        ],
                        [
                            15.222492040206618,
                            38.200408317232075
                        ],
                        [
                            15.20889634279277,
                            38.18025121776668
                        ],
                        [
                            15.194682659133605,
                            38.1710208046583
                        ],
                        [
                            15.181086961719728,
                            38.15644408789177
                        ],
                        [
                            15.161311401846547,
                            38.1481826547365
                        ],
                        [
                            15.142462821341297,
                            38.13943423498944
                        ],
                        [
                            15.130721082664905,
                            38.13700393222433
                        ],
                        [
                            15.114035454021376,
                            38.127281911783484
                        ],
                        [
                            15.085299093580318,
                            38.11901717626793
                        ],
                        [
                            15.072321382411786,
                            38.12387889869751
                        ],
                        [
                            15.06892245805949,
                            38.12752497807719
                        ],
                        [
                            15.058107698752593,
                            38.128983358841595
                        ],
                        [
                            15.054090788153417,
                            38.140892377796746
                        ],
                        [
                            15.046365960077765,
                            38.14672465762911
                        ],
                        [
                            15.04605696695512,
                            38.151341548498834
                        ],
                        [
                            15.035551200770897,
                            38.151098561527675
                        ],
                        [
                            15.021468436439335,
                            38.150864918204064
                        ],
                        [
                            15.003546835303638,
                            38.14892098706818
                        ],
                        [
                            14.991496103504602,
                            38.150864918204064
                        ],
                        [
                            14.974192488614221,
                            38.150621929644956
                        ],
                        [
                            14.95719786684802,
                            38.16325626127653
                        ],
                        [
                            14.944529148803667,
                            38.17758871734458
                        ],
                        [
                            14.929777832860367,
                            38.183174875752
                        ],
                        [
                            14.915564149199668,
                            38.19313229475489
                        ],
                        [
                            14.903822410524754,
                            38.1836606350881
                        ],
                        [
                            14.893007651219506,
                            38.17273026665009
                        ],
                        [
                            14.872614105099444,
                            38.16787179884551
                        ],
                        [
                            14.856855455823819,
                            38.16835766019864
                        ],
                        [
                            14.835225937211646,
                            38.16252711022062
                        ],
                        [
                            14.805838091582643,
                            38.15523830259457
                        ],
                        [
                            14.80120319473815,
                            38.15572424814317
                        ],
                        [
                            14.785135552339938,
                            38.151350621636055
                        ],
                        [
                            14.769685896188605,
                            38.15499532860633
                        ],
                        [
                            14.760725095620757,
                            38.15693909784807
                        ],
                        [
                            14.748674363821692,
                            38.16544247909425
                        ],
                        [
                            14.701398415998142,
                            38.12097098100065
                        ],
                        [
                            14.663392261864999,
                            38.089119547558624
                        ],
                        [
                            14.65659441315887,
                            38.087417171752804
                        ],
                        [
                            14.632183956438098,
                            38.0701479769846
                        ],
                        [
                            14.619206245271101,
                            38.07209400560984
                        ],
                        [
                            14.58645297422936,
                            38.05895730772076
                        ],
                        [
                            14.559261579403142,
                            38.059930477342135
                        ],
                        [
                            14.533163372579793,
                            38.04508586186421
                        ],
                        [
                            14.511533853967563,
                            38.03924546575482
                        ],
                        [
                            14.48588742475468,
                            38.03608172336442
                        ],
                        [
                            14.47043776860329,
                            38.03145754633522
                        ],
                        [
                            14.468583809865862,
                            38.03437811316019
                        ],
                        [
                            14.448808249991146,
                            38.03364798237132
                        ],
                        [
                            14.441083421915465,
                            38.03924546575482
                        ],
                        [
                            14.42625175200942,
                            38.04021889745576
                        ],
                        [
                            14.396279419076194,
                            38.02293856131453
                        ],
                        [
                            14.364153624906692,
                            38.01391968305748
                        ],
                        [
                            14.351793899985012,
                            38.01732781533994
                        ],
                        [
                            14.33912518194063,
                            38.018058108782554
                        ],
                        [
                            14.31595069771356,
                            38.008076801882055
                        ],
                        [
                            14.28319742667182,
                            38.006372540482204
                        ],
                        [
                            14.27145568799699,
                            38.01221555748856
                        ],
                        [
                            14.253225093737086,
                            38.00710294307612
                        ],
                        [
                            14.218308870835216,
                            38.01172865719201
                        ],
                        [
                            14.209667869632995,
                            38.015031995904536
                        ],
                        [
                            14.193291234112081,
                            38.015762312223416
                        ],
                        [
                            14.178768557328738,
                            38.020630901711144
                        ],
                        [
                            14.168880777391365,
                            38.01746635533371
                        ],
                        [
                            14.147251258779136,
                            38.02939354772323
                        ],
                        [
                            14.14385233442681,
                            38.02306507516778
                        ],
                        [
                            14.105537187169546,
                            38.01430167230933
                        ],
                        [
                            14.087924579158027,
                            38.020630901711144
                        ],
                        [
                            14.063205129314667,
                            38.01746635533371
                        ],
                        [
                            14.037249706980617,
                            38.03353109960412
                        ],
                        [
                            14.03045000836164,
                            38.03981468429953
                        ],
                        [
                            14.019635249056279,
                            38.040301397984194
                        ],
                        [
                            14.018708269686755,
                            38.036894334259244
                        ],
                        [
                            14.002022641043254,
                            38.03080989765476
                        ],
                        [
                            13.997696737319899,
                            38.03421724442188
                        ],
                        [
                            13.992134861105853,
                            38.03080989765476
                        ],
                        [
                            13.97668520495452,
                            38.028375981496055
                        ],
                        [
                            13.972977287477988,
                            38.03373049030208
                        ],
                        [
                            13.967415411262493,
                            38.03275697235836
                        ],
                        [
                            13.966179438770297,
                            38.02934955766332
                        ],
                        [
                            13.953510720725916,
                            38.028375981496055
                        ],
                        [
                            13.95165676198846,
                            38.031296671182645
                        ],
                        [
                            13.94671287201976,
                            38.03007973129829
                        ],
                        [
                            13.94671287201976,
                            38.027402392390485
                        ],
                        [
                            13.913650607855374,
                            38.01182320718709
                        ],
                        [
                            13.862245728402144,
                            37.990328347096224
                        ],
                        [
                            13.831964402344795,
                            37.9815610308369
                        ],
                        [
                            13.823621588022235,
                            37.982048131445794
                        ],
                        [
                            13.805699986886566,
                            37.977176979862946
                        ],
                        [
                            13.771710743352656,
                            37.97230550496688
                        ],
                        [
                            13.74328337603427,
                            37.96913887291423
                        ],
                        [
                            13.738030492942954,
                            37.97401055795612
                        ],
                        [
                            13.711976235351699,
                            37.977150502737416
                        ],
                        [
                            13.706414359137653,
                            37.97885544318389
                        ],
                        [
                            13.702706441661178,
                            37.991032437379104
                        ],
                        [
                            13.67273410872636,
                            37.99395461527692
                        ],
                        [
                            13.657284452575084,
                            37.996146172306325
                        ],
                        [
                            13.640289830808854,
                            38.00442479678995
                        ],
                        [
                            13.617733332827129,
                            38.01270248667751
                        ],
                        [
                            13.597648779829768,
                            38.026577693352124
                        ],
                        [
                            13.564586515665354,
                            38.038503402600696
                        ],
                        [
                            13.548518873267142,
                            38.051887089849885
                        ],
                        [
                            13.538982511168825,
                            38.05852985220997
                        ],
                        [
                            13.534656607447033,
                            38.07020710169999
                        ],
                        [
                            13.543617408014825,
                            38.07604502753807
                        ],
                        [
                            13.536201573061817,
                            38.082612137125324
                        ],
                        [
                            13.541145463030517,
                            38.09112417916879
                        ],
                        [
                            13.533420634954837,
                            38.10109416786861
                        ],
                        [
                            13.53805553179933,
                            38.10960405766849
                        ],
                        [
                            13.537128552431426,
                            38.112764621411344
                        ],
                        [
                            13.530639696847828,
                            38.110576553385954
                        ],
                        [
                            13.508392191988804,
                            38.118842244220986
                        ],
                        [
                            13.5046842745123,
                            38.10741589496604
                        ],
                        [
                            13.482127776530518,
                            38.099148911023775
                        ],
                        [
                            13.4503014848583,
                            38.09428554235643
                        ],
                        [
                            13.428671966246128,
                            38.0957445869397
                        ],
                        [
                            13.406115468264403,
                            38.099148911023775
                        ],
                        [
                            13.378138908561311,
                            38.11065837716433
                        ],
                        [
                            13.362380259285743,
                            38.13350827344692
                        ],
                        [
                            13.37289219819607,
                            38.13888881369516
                        ],
                        [
                            13.373819177565593,
                            38.1571136405623
                        ],
                        [
                            13.364858376997688,
                            38.176062629994135
                        ],
                        [
                            13.365702876242636,
                            38.18273481113951
                        ],
                        [
                            13.357360061920133,
                            38.19172088883602
                        ],
                        [
                            13.345618323245247,
                            38.19099232923929
                        ],
                        [
                            13.337275508922687,
                            38.19779193538659
                        ],
                        [
                            13.329859673969679,
                            38.19827759724592
                        ],
                        [
                            13.32615175649471,
                            38.20677615541544
                        ],
                        [
                            13.331713632708727,
                            38.21187481411437
                        ],
                        [
                            13.32306182526355,
                            38.21333150814053
                        ],
                        [
                            13.31811793529485,
                            38.22522675128707
                        ],
                        [
                            13.31101109346605,
                            38.223042056503346
                        ],
                        [
                            13.2971064029295,
                            38.21745864954042
                        ],
                        [
                            13.289999561099137,
                            38.210903668565265
                        ],
                        [
                            13.276094870562616,
                            38.20046303552661
                        ],
                        [
                            13.273931918700953,
                            38.19706343652362
                        ],
                        [
                            13.269606014979075,
                            38.20289122334904
                        ],
                        [
                            13.26249917314874,
                            38.199977388244065
                        ],
                        [
                            13.246740523874678,
                            38.19900608396105
                        ],
                        [
                            13.24314297590999,
                            38.20318420487183
                        ],
                        [
                            13.242524989664673,
                            38.19079965014839
                        ],
                        [
                            13.224294395406247,
                            38.178412989221016
                        ],
                        [
                            13.19092313811771,
                            38.16869648741053
                        ],
                        [
                            13.16249665097601,
                            38.170396988301604
                        ],
                        [
                            13.160951685359635,
                            38.184970915149194
                        ],
                        [
                            13.150754912299618,
                            38.186671016741
                        ],
                        [
                            13.144575049838807,
                            38.18375653257797
                        ],
                        [
                            13.116765668767215,
                            38.19201393486159
                        ],
                        [
                            13.091737225801154,
                            38.183027893318126
                        ],
                        [
                            13.078141528387306,
                            38.171368674107015
                        ],
                        [
                            13.08648434270981,
                            38.16310893185846
                        ],
                        [
                            13.078759514634157,
                            38.15363336920356
                        ],
                        [
                            13.07443361091083,
                            38.15266144700385
                        ],
                        [
                            13.071961665926466,
                            38.148287636825245
                        ],
                        [
                            13.07227065904911,
                            38.140511326710765
                        ],
                        [
                            13.063309858481233,
                            38.14391356437619
                        ],
                        [
                            13.053113085421245,
                            38.138324090150775
                        ],
                        [
                            13.06794475532729,
                            38.12203926842511
                        ],
                        [
                            13.073506631541306,
                            38.10915467168991
                        ],
                        [
                            13.070416700311625,
                            38.10137419199458
                        ],
                        [
                            13.078759514634157,
                            38.08726996070226
                        ],
                        [
                            13.052495099175957,
                            38.07194676370787
                        ],
                        [
                            13.037045443024567,
                            38.07048724415523
                        ],
                        [
                            13.02962960807153,
                            38.063189209570965
                        ],
                        [
                            13.002313907513752,
                            38.0519097504324
                        ],
                        [
                            12.997370017545023,
                            38.04655657077055
                        ],
                        [
                            12.952257021583108,
                            38.031711616184
                        ],
                        [
                            12.895711280067502,
                            38.023679484848515
                        ],
                        [
                            12.884587527637933,
                            38.029034336917846
                        ],
                        [
                            12.879025651423945,
                            38.029034336917846
                        ],
                        [
                            12.870064850856096,
                            38.039256149486306
                        ],
                        [
                            12.867901898994404,
                            38.05142311391171
                        ],
                        [
                            12.859559084671872,
                            38.051666432576184
                        ],
                        [
                            12.857396132811772,
                            38.047529905279816
                        ],
                        [
                            12.851216270350875,
                            38.05434288450655
                        ],
                        [
                            12.839429587941169,
                            38.05750552264604
                        ],
                        [
                            12.810384234375931,
                            38.08061526319878
                        ],
                        [
                            12.789063708886374,
                            38.11076857121884
                        ],
                        [
                            12.789681695133226,
                            38.13629188563277
                        ],
                        [
                            12.779484922073237,
                            38.150143662331914
                        ],
                        [
                            12.772069087120258,
                            38.150143662331914
                        ],
                        [
                            12.765889224659361,
                            38.161806276497515
                        ],
                        [
                            12.772378080242845,
                            38.17030909010634
                        ],
                        [
                            12.7671251971515,
                            38.18051115726507
                        ],
                        [
                            12.73931581607846,
                            38.17516739545019
                        ],
                        [
                            12.730850942668667,
                            38.18005420291266
                        ],
                        [
                            12.734558860145171,
                            38.18952633148629
                        ],
                        [
                            12.728378997684331,
                            38.18879774993735
                        ],
                        [
                            12.716637259009502,
                            38.180297093389186
                        ],
                        [
                            12.717873231501613,
                            38.17130960652602
                        ],
                        [
                            12.728996983929676,
                            38.154546197287175
                        ],
                        [
                            12.737648791374852,
                            38.139237109818794
                        ],
                        [
                            12.728378997684331,
                            38.13194593901051
                        ],
                        [
                            12.728378997684331,
                            38.12489711481956
                        ],
                        [
                            12.71014840342599,
                            38.10836616836221
                        ],
                        [
                            12.693771767905076,
                            38.10447599010007
                        ],
                        [
                            12.683883987967732,
                            38.10860929762515
                        ],
                        [
                            12.680794056736545,
                            38.11420104733702
                        ],
                        [
                            12.663799434970372,
                            38.11225613946877
                        ],
                        [
                            12.655456620647811,
                            38.102287673795615
                        ],
                        [
                            12.66349044184767,
                            38.09523598652953
                        ],
                        [
                            12.658855545001671,
                            38.08939959281909
                        ],
                        [
                            12.647113806326814,
                            38.084535575402526
                        ],
                        [
                            12.640074359391235,
                            38.07526290239309
                        ],
                        [
                            12.605158136489337,
                            38.06407301556581
                        ],
                        [
                            12.578584727908492,
                            38.0699114309468
                        ],
                        [
                            12.560663126771203,
                            38.06407301556581
                        ],
                        [
                            12.555410243679859,
                            38.05020491175392
                        ],
                        [
                            12.533053692123275,
                            38.03393804487516
                        ],
                        [
                            12.516059070357016,
                            38.01957738386898
                        ],
                        [
                            12.498446462344049,
                            38.016899661180645
                        ],
                        [
                            12.498755455466693,
                            38.01446528292888
                        ],
                        [
                            12.509570214773532,
                            38.009352825421445
                        ],
                        [
                            12.507275810341525,
                            37.9951558774884
                        ],
                        [
                            12.495534071666668,
                            37.983466658882634
                        ],
                        [
                            12.493989106051856,
                            37.97932628068345
                        ],
                        [
                            12.499859975388489,
                            37.96617294164497
                        ],
                        [
                            12.493371119805005,
                            37.95179900670526
                        ],
                        [
                            12.481011394883268,
                            37.93230443245051
                        ],
                        [
                            12.466797711224103,
                            37.913779797285
                        ],
                        [
                            12.453632145452445,
                            37.90305188494696
                        ],
                        [
                            12.443126379269756,
                            37.89768783768179
                        ],
                        [
                            12.431075647472227,
                            37.89646868150935
                        ],
                        [
                            12.429839674980116,
                            37.889153320433266
                        ],
                        [
                            12.435710544316748,
                            37.88305663093769
                        ],
                        [
                            12.43787349617844,
                            37.871837403453924
                        ],
                        [
                            12.444362351761953,
                            37.849637811144035
                        ],
                        [
                            12.447761276115756,
                            37.84426987312483
                        ],
                        [
                            12.467845829113202,
                            37.83816946924894
                        ],
                        [
                            12.469699787850658,
                            37.82767559433833
                        ],
                        [
                            12.46073898728281,
                            37.815227435723116
                        ],
                        [
                            12.449615234853297,
                            37.808392259865784
                        ],
                        [
                            12.433238599332412,
                            37.80595097228455
                        ],
                        [
                            12.424685271577374,
                            37.8020025231845
                        ],
                        [
                            12.433646072145251,
                            37.79272439838816
                        ],
                        [
                            12.4475507626818,
                            37.7846661341253
                        ],
                        [
                            12.457129549496472,
                            37.770500994826136
                        ],
                        [
                            12.459910487603452,
                            37.758531779339876
                        ],
                        [
                            12.470107260663497,
                            37.746560626930844
                        ],
                        [
                            12.472270212525217,
                            37.72994439037821
                        ],
                        [
                            12.467944308801805,
                            37.71772419015758
                        ],
                        [
                            12.474011510050502,
                            37.708515785130274
                        ],
                        [
                            12.470303592575561,
                            37.702893117359096
                        ],
                        [
                            12.486371234972268,
                            37.690912968067096
                        ],
                        [
                            12.502747870493067,
                            37.67379511122188
                        ],
                        [
                            12.520978464751437,
                            37.65911951870399
                        ],
                        [
                            12.535737572044837,
                            37.6567874671466
                        ],
                        [
                            12.54840629008919,
                            37.662169023704166
                        ],
                        [
                            12.56570990497957,
                            37.66192441596027
                        ],
                        [
                            12.582704526745715,
                            37.649937649170425
                        ],
                        [
                            12.614839811542197,
                            37.63697019241306
                        ],
                        [
                            12.622255646495205,
                            37.61372097308859
                        ],
                        [
                            12.63745297148489,
                            37.59315725807346
                        ],
                        [
                            12.65259363451355,
                            37.57528218866557
                        ],
                        [
                            12.67484113937266,
                            37.55862757814428
                        ],
                        [
                            12.724898025303219,
                            37.56964942832552
                        ],
                        [
                            12.763336769672861,
                            37.579016909281094
                        ],
                        [
                            12.814011641850385,
                            37.5814656798041
                        ],
                        [
                            12.849545850999078,
                            37.5814656798041
                        ],
                        [
                            12.865304500273055,
                            37.580975932142735
                        ],
                        [
                            12.903001661283554,
                            37.57558849524332
                        ],
                        [
                            12.918481650478384,
                            37.57757846947429
                        ],
                        [
                            12.952779887134994,
                            37.56998662238354
                        ],
                        [
                            12.9781173232237,
                            37.549411276182184
                        ],
                        [
                            13.000055834960136,
                            37.527850058066164
                        ],
                        [
                            13.016123477356786,
                            37.50995931688331
                        ],
                        [
                            13.01327568029123,
                            37.50064136694472
                        ],
                        [
                            13.02254547398175,
                            37.49353200853177
                        ],
                        [
                            13.027489363950451,
                            37.49794479342681
                        ],
                        [
                            13.039849088872131,
                            37.49524812253041
                        ],
                        [
                            13.050663848179028,
                            37.50382810100615
                        ],
                        [
                            13.086507050450422,
                            37.506279342344556
                        ],
                        [
                            13.114316431523434,
                            37.49769964555129
                        ],
                        [
                            13.133165012028655,
                            37.49255135425733
                        ],
                        [
                            13.140331764127154,
                            37.496474461108434
                        ],
                        [
                            13.156708399648068,
                            37.496474461108434
                        ],
                        [
                            13.17833791826024,
                            37.48789363768894
                        ],
                        [
                            13.187298718828146,
                            37.479557036726746
                        ],
                        [
                            13.197804485010806,
                            37.46435260509928
                        ],
                        [
                            13.208310251195002,
                            37.46459786240658
                        ],
                        [
                            13.233338694159528,
                            37.441785491249064
                        ],
                        [
                            13.238591577252379,
                            37.43246230519455
                        ],
                        [
                            13.247899939636426,
                            37.42795673973605
                        ],
                        [
                            13.263040602663551,
                            37.41519604573182
                        ],
                        [
                            13.273855361970476,
                            37.38868611824067
                        ],
                        [
                            13.284052135030464,
                            37.39261410667591
                        ],
                        [
                            13.301664743043546,
                            37.38549447609037
                        ],
                        [
                            13.31927735105657,
                            37.367324079191604
                        ],
                        [
                            13.321883683109661,
                            37.36341633857725
                        ],
                        [
                            13.34011427736803,
                            37.35899561652178
                        ],
                        [
                            13.350002057305375,
                            37.359732421615035
                        ],
                        [
                            13.364833727211419,
                            37.34818831078533
                        ],
                        [
                            13.377811438378473,
                            37.346468823235995
                        ],
                        [
                            13.389553177053273,
                            37.33516835402375
                        ],
                        [
                            13.405620819451542,
                            37.33271150528233
                        ],
                        [
                            13.422924434341923,
                            37.314036829052895
                        ],
                        [
                            13.428486310555854,
                            37.30469775075524
                        ],
                        [
                            13.446407911691608,
                            37.300519366488274
                        ],
                        [
                            13.450115829168112,
                            37.29216190151895
                        ],
                        [
                            13.465565485319445,
                            37.29486588885442
                        ],
                        [
                            13.482869100209882,
                            37.286507795762745
                        ],
                        [
                            13.495537818254206,
                            37.28921198630897
                        ],
                        [
                            13.502953653207243,
                            37.29412844740186
                        ],
                        [
                            13.516346129943258,
                            37.28983983711413
                        ],
                        [
                            13.532413613541166,
                            37.284431437497645
                        ],
                        [
                            13.55342514590805,
                            37.288119047718084
                        ],
                        [
                            13.57011077455158,
                            37.274842805216764
                        ],
                        [
                            13.580616540734269,
                            37.26451521979814
                        ],
                        [
                            13.577217616381944,
                            37.26058052894297
                        ],
                        [
                            13.59235827941066,
                            37.26033460393947
                        ],
                        [
                            13.60842592180731,
                            37.24828329515435
                        ],
                        [
                            13.621403632974364,
                            37.23376997765517
                        ],
                        [
                            13.629437454174251,
                            37.235984054176626
                        ],
                        [
                            13.645196103448228,
                            37.22515906195872
                        ],
                        [
                            13.659138708117467,
                            37.20661984792277
                        ],
                        [
                            13.662846625594,
                            37.19357538470361
                        ],
                        [
                            13.682004199221865,
                            37.18939083412489
                        ],
                        [
                            13.700234793480206,
                            37.17806676988424
                        ],
                        [
                            13.711976532155063,
                            37.171419245820175
                        ],
                        [
                            13.726302862701942,
                            37.169932478073974
                        ],
                        [
                            13.737735608254184,
                            37.15959051914648
                        ],
                        [
                            13.7525672781602,
                            37.147523111941965
                        ],
                        [
                            13.779449679863774,
                            37.145306440042916
                        ],
                        [
                            13.811584964658635,
                            37.145060139154495
                        ],
                        [
                            13.821762904743593,
                            37.145764899058946
                        ],
                        [
                            13.836594574648075,
                            37.13936085411757
                        ],
                        [
                            13.852044230799493,
                            37.127782935491936
                        ],
                        [
                            13.861005031368848,
                            37.111521591319715
                        ],
                        [
                            13.885415488088057,
                            37.0996929654287
                        ],
                        [
                            13.907045006700315,
                            37.095256754492226
                        ],
                        [
                            13.91971372474461,
                            37.098707163223665
                        ],
                        [
                            13.930528484049972,
                            37.098214257310886
                        ],
                        [
                            13.948759078309848,
                            37.0996929654287
                        ],
                        [
                            13.971532848442962,
                            37.10776325850115
                        ],
                        [
                            13.993780353302071,
                            37.10948819770455
                        ],
                        [
                            14.025915638096933,
                            37.10825610228237
                        ],
                        [
                            14.05063508794035,
                            37.10825610228237
                        ],
                        [
                            14.06948366844557,
                            37.11195232841341
                        ],
                        [
                            14.113360691915318,
                            37.10455969575139
                        ],
                        [
                            14.13190027929781,
                            37.09839861732863
                        ],
                        [
                            14.1476589285719,
                            37.09839861732863
                        ],
                        [
                            14.210684552611696,
                            37.076464934439045
                        ],
                        [
                            14.227988167502104,
                            37.06635675671208
                        ],
                        [
                            14.244055809900317,
                            37.065863640408494
                        ],
                        [
                            14.27989901217174,
                            37.04687622528658
                        ],
                        [
                            14.32710356746415,
                            37.01184374493529
                        ],
                        [
                            14.370671597811224,
                            36.97087479381473
                        ],
                        [
                            14.408033290002265,
                            36.918868856237836
                        ],
                        [
                            14.429353815491794,
                            36.889218781090264
                        ],
                        [
                            14.440168574798662,
                            36.87167039519541
                        ],
                        [
                            14.45345527908836,
                            36.84150741413191
                        ],
                        [
                            14.458431004507986,
                            36.81961905592773
                        ],
                        [
                            14.493965213656708,
                            36.787455879184975
                        ],
                        [
                            14.515285739144701,
                            36.78819825860366
                        ],
                        [
                            14.531971367789794,
                            36.78027917399187
                        ],
                        [
                            14.550510955170807,
                            36.78399134679654
                        ],
                        [
                            14.594078985519417,
                            36.77458682647361
                        ],
                        [
                            14.623124339083091,
                            36.76518115227336
                        ],
                        [
                            14.63909090285378,
                            36.762163978140435
                        ],
                        [
                            14.651759620898162,
                            36.749043094912906
                        ],
                        [
                            14.669681222033887,
                            36.74409123502953
                        ],
                        [
                            14.686675843800003,
                            36.72576657525245
                        ],
                        [
                            14.686675843800003,
                            36.7222992557146
                        ],
                        [
                            14.703670465567797,
                            36.71635491518266
                        ],
                        [
                            14.716648176734822,
                            36.720813213707544
                        ],
                        [
                            14.732715819133006,
                            36.72056553724474
                        ],
                        [
                            14.749092454653862,
                            36.720813213707544
                        ],
                        [
                            14.756817282729514,
                            36.71462106254404
                        ],
                        [
                            14.75743526897486,
                            36.709666982246915
                        ],
                        [
                            14.77968277383394,
                            36.71090553226766
                        ],
                        [
                            14.781227739448724,
                            36.70421712499268
                        ],
                        [
                            14.802857258060982,
                            36.70892384265241
                        ],
                        [
                            14.814290003613138,
                            36.7178410434408
                        ],
                        [
                            14.824795769795855,
                            36.71561184027179
                        ],
                        [
                            14.843775199865178,
                            36.72375139631174
                        ],
                        [
                            14.862314787246163,
                            36.731181034579464
                        ],
                        [
                            14.8864162508427,
                            36.73068574772532
                        ],
                        [
                            14.912062680054078,
                            36.72548504286898
                        ],
                        [
                            14.933692198666336,
                            36.71111936164685
                        ],
                        [
                            14.95624869664806,
                            36.69377789301058
                        ],
                        [
                            14.990237940181913,
                            36.697246499739165
                        ],
                        [
                            15.006731712192703,
                            36.703921886098854
                        ],
                        [
                            15.029597203297072,
                            36.70020583847477
                        ],
                        [
                            15.036704045127465,
                            36.69079104711794
                        ],
                        [
                            15.049063770049145,
                            36.68410088950775
                        ],
                        [
                            15.05431665314049,
                            36.66873610282779
                        ],
                        [
                            15.056353595968858,
                            36.66075051791951
                        ],
                        [
                            15.065623389659379,
                            36.66174198994118
                        ],
                        [
                            15.0748931833499,
                            36.6562887357922
                        ],
                        [
                            15.079528080195928,
                            36.646372738617714
                        ],
                        [
                            15.092814784485597,
                            36.654553528463964
                        ],
                        [
                            15.102393571300297,
                            36.66075051791951
                        ],
                        [
                            15.107028468146297,
                            36.66496418581531
                        ],
                        [
                            15.113826316852482,
                            36.67636471883219
                        ],
                        [
                            15.124950069281965,
                            36.67388648577101
                        ],
                        [
                            15.128966979881142,
                            36.66719485778653
                        ],
                        [
                            15.136073821711534,
                            36.67438213876932
                        ],
                        [
                            15.135455835464683,
                            36.68776356270942
                        ],
                        [
                            15.132674897357703,
                            36.69544443944295
                        ],
                        [
                            15.12464107615935,
                            36.70064717806595
                        ],
                        [
                            15.121860138050806,
                            36.71352863435503
                        ],
                        [
                            15.121242151805461,
                            36.721949957500755
                        ],
                        [
                            15.122478124297658,
                            36.73383731253615
                        ],
                        [
                            15.118152220574302,
                            36.738542215514045
                        ],
                        [
                            15.103629543792437,
                            36.76057713237381
                        ],
                        [
                            15.096522701962101,
                            36.77988342891314
                        ],
                        [
                            15.098685332080407,
                            36.792002257536595
                        ],
                        [
                            15.09683137334136,
                            36.80041497049592
                        ],
                        [
                            15.107337139525583,
                            36.810063709322776
                        ],
                        [
                            15.111354050124731,
                            36.82020594429157
                        ],
                        [
                            15.107646132648227,
                            36.836282343100834
                        ],
                        [
                            15.111663043247376,
                            36.853591609895474
                        ],
                        [
                            15.130984215939293,
                            36.86759318226011
                        ],
                        [
                            15.136855085277489,
                            36.86685157279628
                        ],
                        [
                            15.139636023384469,
                            36.875750411245065
                        ],
                        [
                            15.141180988999253,
                            36.890332376907566
                        ],
                        [
                            15.155703665782681,
                            36.91948795310323
                        ],
                        [
                            15.169608356319202,
                            36.92319337221912
                        ],
                        [
                            15.184054599436735,
                            36.94227819931049
                        ],
                        [
                            15.209701028648169,
                            36.95388443962106
                        ],
                        [
                            15.205993111171637,
                            36.96129175350124
                        ],
                        [
                            15.212172973632477,
                            36.96598267961383
                        ],
                        [
                            15.221751760447205,
                            36.961785548807896
                        ],
                        [
                            15.234173826930544,
                            36.97163023684196
                        ],
                        [
                            15.260129249264594,
                            36.973605130626524
                        ],
                        [
                            15.26816307046289,
                            36.98693432324332
                        ],
                        [
                            15.268472063587097,
                            37.0027288609778
                        ],
                        [
                            15.278977829769815,
                            37.00865096686658
                        ],
                        [
                            15.284539705983804,
                            37.00568997159138
                        ],
                        [
                            15.303388286488968,
                            37.01629967079589
                        ],
                        [
                            15.315439018288004,
                            37.003469149444285
                        ],
                        [
                            15.336759543776054,
                            37.001248262420205
                        ],
                        [
                            15.337686523145521,
                            37.0118585815876
                        ],
                        [
                            15.322545860116833,
                            37.02296081796898
                        ],
                        [
                            15.316674990780257,
                            37.0372679741744
                        ],
                        [
                            15.301534327751511,
                            37.04368065205334
                        ],
                        [
                            15.291646547814196,
                            37.033568105945136
                        ],
                        [
                            15.279595816015075,
                            37.033568105945136
                        ],
                        [
                            15.277123871030767,
                            37.04417391253217
                        ],
                        [
                            15.270017029201966,
                            37.051325829296914
                        ],
                        [
                            15.278024848508494,
                            37.06667679575396
                        ],
                        [
                            15.28945759406065,
                            37.06421119258141
                        ],
                        [
                            15.294710477153501,
                            37.05484116964058
                        ],
                        [
                            15.300272353367575,
                            37.0602660608091
                        ],
                        [
                            15.292238532169193,
                            37.0681561191881
                        ],
                        [
                            15.30058134649019,
                            37.08122222305968
                        ],
                        [
                            15.295462685020084,
                            37.10540066658044
                        ],
                        [
                            15.280940008236712,
                            37.11156117550715
                        ],
                        [
                            15.263327400225194,
                            37.10712565959159
                        ],
                        [
                            15.237371977889637,
                            37.11550363823078
                        ],
                        [
                            15.222540307983564,
                            37.12708343533488
                        ],
                        [
                            15.220995342368752,
                            37.14580446533958
                        ],
                        [
                            15.229647149813985,
                            37.15196168631705
                        ],
                        [
                            15.242933854103654,
                            37.14629706146589
                        ],
                        [
                            15.23613600539744,
                            37.15959594408365
                        ],
                        [
                            15.226866211706948,
                            37.15984219762895
                        ],
                        [
                            15.226866211706948,
                            37.153931891151245
                        ],
                        [
                            15.21883239050706,
                            37.15097656464896
                        ],
                        [
                            15.201528775618243,
                            37.15811840596808
                        ],
                        [
                            15.202455754987739,
                            37.17215385205151
                        ],
                        [
                            15.202090109815487,
                            37.18014210790281
                        ],
                        [
                            15.187876426156265,
                            37.19048125422259
                        ],
                        [
                            15.184168508679761,
                            37.21681506346107
                        ],
                        [
                            15.189112398648462,
                            37.21755326213851
                        ],
                        [
                            15.194983267985094,
                            37.22444276810664
                        ],
                        [
                            15.189421391771106,
                            37.236497889251496
                        ],
                        [
                            15.213831848490315,
                            37.246583346225236
                        ],
                        [
                            15.21908473158166,
                            37.22050598460862
                        ],
                        [
                            15.22680955965734,
                            37.21632292699651
                        ],
                        [
                            15.229281504641648,
                            37.22149019974725
                        ],
                        [
                            15.224955600919856,
                            37.224688810252786
                        ],
                        [
                            15.22711855278152,
                            37.240925817033954
                        ],
                        [
                            15.237933312086824,
                            37.24166377970889
                        ],
                        [
                            15.246276126409384,
                            37.233545792894745
                        ],
                        [
                            15.255545920099905,
                            37.23452983785799
                        ],
                        [
                            15.25585491322255,
                            37.24190976566197
                        ],
                        [
                            15.24383095661426,
                            37.24899550093089
                        ],
                        [
                            15.244139949738468,
                            37.258095561018365
                        ],
                        [
                            15.220038486141874,
                            37.274817129165
                        ],
                        [
                            15.20675178185067,
                            37.29497643410035
                        ],
                        [
                            15.198717960652345,
                            37.29522224595057
                        ],
                        [
                            15.19346507756103,
                            37.2821931110885
                        ],
                        [
                            15.174616497055865,
                            37.29399317866836
                        ],
                        [
                            15.165346703363753,
                            37.28907670873548
                        ],
                        [
                            15.136610342922722,
                            37.30996948694829
                        ],
                        [
                            15.104475058126297,
                            37.31095253352346
                        ],
                        [
                            15.092750337790761,
                            37.33907814253109
                        ],
                        [
                            15.090896379053248,
                            37.38353089799797
                        ],
                        [
                            15.091725163467856,
                            37.415607107996436
                        ],
                        [
                            15.084000335392176,
                            37.47521981928965
                        ],
                        [
                            15.092961135960024,
                            37.489931694345046
                        ],
                        [
                            15.106865826496573,
                            37.512974473843215
                        ],
                        [
                            15.120152481675461,
                            37.53280311394708
                        ],
                        [
                            15.146725890256363,
                            37.545788731834435
                        ],
                        [
                            15.149506828363315,
                            37.559506927388966
                        ],
                        [
                            15.160939573915499,
                            37.55999681624202
                        ],
                        [
                            15.17020936760602,
                            37.57591645044721
                        ],
                        [
                            15.177625202559028,
                            37.57616134132091
                        ],
                        [
                            15.172681312590385,
                            37.61386492259895
                        ],
                        [
                            15.177316209436384,
                            37.638337536217406
                        ],
                        [
                            15.199512553743887,
                            37.656661902598415
                        ],
                        [
                            15.19549564314471,
                            37.66546789783686
                        ],
                        [
                            15.210945299296071,
                            37.69872556288804
                        ],
                        [
                            15.219906099863891,
                            37.71143778809561
                        ],
                        [
                            15.206928388696895,
                            37.73465641502176
                        ],
                        [
                            15.211872162568909,
                            37.75571226751339
                        ],
                        [
                            15.250496302947312,
                            37.8035793311021
                        ],
                        [
                            15.273670787174382,
                            37.82115536474892
                        ],
                        [
                            15.26718193159084,
                            37.831162069701094
                        ],
                        [
                            15.28850245708037,
                            37.85068344050228
                        ],
                        [
                            15.29777225077089,
                            37.846291582558436
                        ],
                        [
                            15.303025133862235,
                            37.858002622422674
                        ],
                        [
                            15.29777225077089,
                            37.860198235296096
                        ],
                        [
                            15.312912913799636,
                            37.88556501693931
                        ],
                        [
                            15.361924122244488,
                            37.93540612556859
                        ],
                        [
                            15.434537506156829,
                            38.01018523546625
                        ],
                        [
                            15.445352265463697,
                            38.030389305230244
                        ],
                        [
                            15.475496678464054,
                            38.05220148851225
                        ],
                        [
                            15.493418279599695,
                            38.074339939267844
                        ],
                        [
                            15.50794095638156,
                            38.10376640440944
                        ],
                        [
                            15.53575033745463,
                            38.151164288413554
                        ],
                        [
                            15.571805544916174,
                            38.1924719988983
                        ],
                        [
                            15.559136826871793,
                            38.1885863035709
                        ],
                        [
                            15.561608771856186,
                            38.20315659211997
                        ],
                        [
                            15.569333599931866,
                            38.22889030992644
                        ],
                        [
                            15.592508084160414,
                            38.25097520328839
                        ],
                        [
                            15.649980805044066,
                            38.26674601959243
                        ],
                        [
                            15.651834763781522,
                            38.271597889734664
                        ],
                        [
                            15.625570348324857,
                            38.27353854703679
                        ],
                        [
                            15.604558815957944,
                            38.27523657963354
                        ],
                        [
                            15.571805544916174,
                            38.28615155534729
                        ],
                        [
                            15.552029985042964,
                            38.30045981490514
                        ],
                        [
                            15.528855500814444,
                            38.29851987725121
                        ],
                        [
                            15.513405844663026,
                            38.29342729411519
                        ],
                        [
                            15.471691773053465,
                            38.266503417577525
                        ],
                        [
                            15.444809371349919,
                            38.249033943162374
                        ],
                        [
                            15.401241341002844,
                            38.23374470780618
                        ],
                        [
                            15.353038413809742,
                            38.2165101288102
                        ],
                        [
                            15.330561667486506,
                            38.21533412399779
                        ],
                        [
                            15.300589334551802,
                            38.20853615615303
                        ],
                        [
                            15.264128146035063,
                            38.20877895165293
                        ],
                        [
                            15.240335675561198,
                            38.21606244003377
                        ],
                        [
                            15.249605469251662,
                            38.23232626545345
                        ],
                        [
                            15.254549359220334,
                            38.24761579907343
                        ],
                        [
                            15.242498627422833,
                            38.26969500410851
                        ],
                        [
                            15.230138902501153,
                            38.271635712260235
                        ],
                        [
                            15.225581971436299,
                            38.26763921739203
                        ]
                    ]
                ],
                "type": "Polygon"
            },
        }
    ]
}
