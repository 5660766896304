import styled from "styled-components";
import FlexBox from "../../../../lib/UI/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {rgba} from "polished";
import Text from "../../../../lib/UI/Text";
import Image from "../../../../lib/UI/Image";

const Hotel__Rooms__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  height: auto;
`
const Room__Option = styled.a`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  height: auto;
  flex-wrap: nowrap;
  cursor: pointer;
  border-radius: 15px;
  transition: background-color 200ms;

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.05)};
  }
`

const HOTEL__IMAGES__PLACEHOLDER = styled(Image)`
  width: 40vw;
  border-radius: 5px;
  height: 25vw;
  background-color: grey;
  flex-shrink: 0;
  @media (min-width: 1100px) {
    width: 440px;
    height: 275px;
  }
`
const Room__Information__Content = styled(FlexBox)`
  flex-direction: column-reverse;
  color: ${({theme}) => theme.color};
  margin-left: 10px;
  gap: 10px 0;
  width: 100%;
`
const Room__Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 22px;
`
const Room__Detail__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
`
const Room__Detail__Icon = styled(FontAwesomeIcon)`
  height: 19px;
  width: 19px;
  margin-top: 1px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Room__Detail = styled(Text)`
  margin-left: 8px;
  font-size: 18px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Details__Holder = styled(FlexBox)`
  flex-direction: column;
  gap: 4px;
`

const Room__Facilities__Holder = styled(FlexBox)`
  flex-direction: initial;
  gap: 8px;
`
const Room__Facility = styled(FlexBox)`
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.color};
  padding: 6px;
  border-radius: 5px;
`
const Room__Facility__Icon = styled(FontAwesomeIcon)`
  width: 17px;
  height: 17px;
  color: ${({theme}) => theme.color};
`
const Room__Facility__ToolTip = styled(Text)`
  font-weight: bold;
  padding: 4px;
`

export const Styled = {
    Room__Facility__ToolTip,
    Room__Facility__Icon,
    Room__Facility,
    Room__Facilities__Holder,
    Details__Holder,
    Room__Detail,
    Room__Detail__Icon,
    Room__Detail__Holder,
    Room__Title,
    Room__Information__Content,
    HOTEL__IMAGES__PLACEHOLDER,
    Room__Option,
    Hotel__Rooms__Holder
}