import {Styled} from "../../../../Pages/CountryPage/CountryPage.styles"
import InterActiveMap from "../../../InterActiveMap";
import React from "react";
import Places from "../Places";

type MapWithOptions = {
    options: any[]
    mapStyle: string
    initialState: any
    geoJson: any
    isLoading?: boolean
    customOptionsTitle?: string
}

const MapWithOptions = (
    {
        isLoading = false,
        customOptionsTitle = undefined,
        options,
        mapStyle,
        initialState,
        geoJson
    }: MapWithOptions
) => {


    return !isLoading ? (
        <Styled.Region__Holder>
            <Styled.Country__Map>
                <InterActiveMap
                    mapStyle={mapStyle}
                    initialState={initialState}
                    geoJson={geoJson}
                />
            </Styled.Country__Map>
            <Styled.Country__Separator/>
            <Styled.Places__Holder>
                <Places
                    customOptionsTitle={customOptionsTitle}
                    hideFilter={true}
                    options={options}
                />
            </Styled.Places__Holder>
        </Styled.Region__Holder>
    ) : (
        <Styled.Region__Holder>
            <Styled.Country__Map__Loader
                style={{
                    maxWidth: 'unset',
                    maxHeight: 'unsets'
                }}
            />
            <Styled.Places__Holder>
                {Array.from({length: 10}).map((_, index: number) => (
                    <Styled.Place__Option__PlaceHolder
                        key={'place_holder_for_places' + index}
                    />
                ))}
            </Styled.Places__Holder>
        </Styled.Region__Holder>
    )
}


export default MapWithOptions