import {Styled} from "./DiscoverWorld.styles";
import {useTranslation} from "react-i18next";

const AIAgentPanel = () => {

    const {t} = useTranslation()

    return (
        <Styled.Where__To__Go__Agent__Panel>
            <Styled.Where__To__Go__TextArea
                style={{
                    minHeight: '150px'
                }}
                placeholder={t('lbl_AI_agent_place_holder')}
            />
            <Styled.Send__Message__Button
                label={t('lbl_send_query_to_ai_agent')}
            />

        </Styled.Where__To__Go__Agent__Panel>
    )
}
export default AIAgentPanel