import styled from "styled-components";
import FlexBox from "../../../../lib/UI/FlexBox";
import Text from "../../../../lib/UI/Text";
import Image from "../../../../lib/UI/Image";

const Top__Five__Destinations__Holder = styled(FlexBox)`
  flex-direction: column;
  gap: 25px 0;
`
const Top__Five__Option__Holder = styled(FlexBox)`
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  height: auto;
  flex-direction: column;
  border-radius: 5px;

`
const Option__Title = styled(Text)`
  font-size: 26px;
`
const Option__Content__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  padding-top: 10px;
  flex-direction: initial;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const Option__Image = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;

`
const Option__MultiMedia__Holder = styled(FlexBox)`
  width: calc(100vw - 40px);
  aspect-ratio: 1 / 1.1;
  overflow: hidden;
  flex-direction: column;
  border-radius: 5px;
  flex-shrink: 0;
  max-width: 350px;
  margin: 0 auto;

  @media (max-width: 800px) {
    aspect-ratio: 1.5 / 1;
    max-width: 600px;
  }
  @media (min-width: 1100px) {
    width: 1060px;
  }
`
const Description__Holder = styled(FlexBox)`
  flex-direction: column;
  gap: 20px;
  margin-top: 0;
  padding-left: 20px;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: initial;
    padding-left: 0;
    margin-top: 20px;
  }
`
const Sub__Option__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Sub__Option__Title = styled(Text)`
  font-weight: bold;
  font-size: 19px;
  margin-bottom: 10px;
`
const Sub__Option__List = styled(Text)`
  margin-left: 5px;
  font-size: 19px;
`
const Top__Five__Option__Skeleton = styled(FlexBox)`
  width: 100%;
  flex-direction: column;
  height: auto;
  flex-wrap: nowrap;
`
const Skeleton__Content__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
`


export const Styled = {
    Skeleton__Content__Holder,
    Top__Five__Option__Skeleton,
    Sub__Option__List,
    Sub__Option__Title,
    Sub__Option__Holder,
    Description__Holder,
    Option__MultiMedia__Holder,
    Option__Image,
    Option__Content__Holder,
    Option__Title,
    Top__Five__Option__Holder,
    Top__Five__Destinations__Holder
}