import PageHolder from "../../../lib/UI/PageHolder";
import {Styled} from "./Announcements.styles";
import {faCommentExclamation} from "@fortawesome/pro-solid-svg-icons";
import {useTranslation} from "react-i18next";

const Announcements = () => {

    const {t} = useTranslation()

    const announcementOptions: any = [
        {
            image: window.location.origin + '/wor.svg',
            title: 'New Version V.1.21',
            description: 'Introducing the latest version of "World of Regions"! Dive into an enhanced experience with new interactive maps, real-time regional updates, and a customizable user interface. Discover detailed demographic data, economic trends, and cultural insights like never before. Stay ahead with smart notifications and improved analytics. Upgrade now to explore the world with unmatched precision and depth!'
        },
        {
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAsuRF8fuRyxcYCe2hSbZfJSKUIUvnQJzB6A&s',
            title: 'ITB Berlin 2025',
            description: 'Introducing the latest version of "World of Regions"! Dive into an enhanced experience with new interactive maps, real-time regional updates, and a customizable user interface. Discover detailed demographic data, economic trends, and cultural insights like never before. Stay ahead with smart notifications and improved analytics. Upgrade now to explore the world with unmatched precision and depth!'
        },
        {
            image: 'https://pbs.twimg.com/profile_images/1729104377795760128/xu8nAwmg_400x400.png',
            title: 'ATM Dubai',
            description: 'Exciting News! "World of Regions" will be attending ATM Dubai this year. Join us to explore our latest features, meet our team, and discover how our app can revolutionize your understanding of global regions. We look forward to connecting with industry leaders and enthusiasts. See you in Dubai!'
        },
    ]

    return (
        <PageHolder>
            <Styled.Announcements__Content__Holder>
                <Styled.Announcements__Title>
                    <Styled.Announcements__Icon
                        icon={faCommentExclamation}
                    />
                    {t('lbl_announcements')}
                </Styled.Announcements__Title>
                <Styled.Announcements__Options__Holder>
                    {announcementOptions.map((item: any) => (
                        <Styled.Announcement__Option>
                            <Styled.Announcement__Option__Image
                                src={item?.image}
                            />
                            <Styled.Announcements__Option__Content__Holder>
                                <Styled.Announcements__Option__Title>
                                    {item?.title}
                                </Styled.Announcements__Option__Title>
                                <Styled.Announcement__Description>
                                    {item.description}
                                </Styled.Announcement__Description>
                            </Styled.Announcements__Option__Content__Holder>
                        </Styled.Announcement__Option>
                    ))}
                </Styled.Announcements__Options__Holder>
            </Styled.Announcements__Content__Holder>
        </PageHolder>
    )

}

export default Announcements