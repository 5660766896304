import axios, {AxiosRequestConfig, CancelTokenSource} from 'axios';

const retryDelayMs = 100;
const defaultServiceTimeout = 10 * 1000;

export interface IDictionary<T> {
    [Key: string]: T;
}

const AXIOS_CONFIG: AxiosRequestConfig = {
    responseType: 'json',
    timeout: defaultServiceTimeout,
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
};

const axiosinsntace = axios.create(AXIOS_CONFIG);

type cancelFunc = () => void

type sendRequestProps = {
    baseurl: string
    action: string | null
    postData: any
    onSuccess: (data: any) => void
    onError: (e: any) => void
    onCancel?: () => void
    retryCount: number
    getTokenUrl: string
    cancelTokenSource: CancelTokenSource | undefined
    serviceTimeout?: number | undefined
};

const tokens: { [index: string]: string } = {};

const getTokenActive: {
    [index: string]: { onSuccess: () => void, onFail: (error: any) => void }[]
} = {};

const getToken = (tokenUrl: string, onSuccess: () => void, onFail: (error: any) => void) => {
    if (getTokenActive[tokenUrl] === undefined) {
        getTokenActive[tokenUrl] = [];
    }
    const currentList = getTokenActive[tokenUrl];

    currentList.push({onSuccess, onFail});
    if (currentList.length > 1) {
        return;
    }
    axiosinsntace.post(tokenUrl).then((value: any) => {
        getTokenActive[tokenUrl] = [];
        const token = value?.data?.token;
        if (token) {
            tokens[tokenUrl] = token;
            currentList.forEach(((value1) => value1.onSuccess()));
        } else {
            const e = new Error('error getting token');
            currentList.forEach(((value1) => value1.onFail(e)));
        }
    }).catch((error) => {
        if (Number(error?.response?.status) === 401) {
            window.location.href = '/logon.html';
            return;
        }

        getTokenActive[tokenUrl] = [];
        currentList.forEach(((value1) => value1.onFail(error)));
    });
};


// simple sendRequest to silently handle retry
const sendRequest = ({
                         baseurl,
                         action,
                         postData,
                         onSuccess,
                         onError,
                         retryCount,
                         onCancel,
                         getTokenUrl,
                         cancelTokenSource,
                         serviceTimeout,
                     }: sendRequestProps): cancelFunc => {
    const cts = cancelTokenSource ?? axios.CancelToken.source();
    const svcTimeout = serviceTimeout ? serviceTimeout * 1000 : defaultServiceTimeout;
    // because HTTPrequest timeout is for response only, we create timeout to simulate (connection+response) Timeout
    const timeout = setTimeout(() => cts.cancel('to'), (svcTimeout + 5000));
    const headers: any = {};
    if (getTokenUrl) {
        headers['X-TOKEN'] = getTokenUrl;
    }
    const url = action ? `${baseurl}/${action}` : `${baseurl}`;
    axiosinsntace.request({
        url, data: postData, method: postData ? 'POST' : 'GET', timeout: svcTimeout, headers, cancelToken: cts.token,
    }).then((value) => {
        clearTimeout(timeout);
        // we do not send "value", because we want to hide used protocol implementation in case of switching to websockets
        onSuccess(value.data);
    }).catch((error: any) => {
        if (Number(error?.response?.status) === 401) { // Session Timeout (handled)
            return;
        }

        if (error?.message === 'Request aborted') { // Request aborted (handled)
            return;
        }
        if (!axios.isCancel(error)) {
            clearTimeout(timeout);
        } else if (error?.message === 'caller') { // Handled
            onCancel?.();
            return;
        }

        if (Number(error?.response?.status) === 412) {
            getToken(getTokenUrl, () => {
                sendRequest({
                    baseurl, action, postData, onSuccess, onError, retryCount, getTokenUrl, cancelTokenSource,
                });
            }, (error) => {
                if (retryCount > 0) {
                    setTimeout(() => sendRequest({
                            baseurl,
                            action,
                            postData,
                            onSuccess,
                            onError,
                            retryCount: retryCount - 1,
                            getTokenUrl,
                            cancelTokenSource,
                        }),
                        retryDelayMs);
                } else {
                    onError(error);
                }
            });
            return;
        }

        if (retryCount > 0) {
            setTimeout(() => sendRequest({
                    baseurl,
                    action,
                    postData,
                    onSuccess,
                    onError,
                    retryCount: retryCount - 1,
                    getTokenUrl,
                    cancelTokenSource,
                }),
                retryDelayMs);
        } else {
            onError(error);
        }
    });
    return () => cts.cancel('caller');
};


// TODO: retryCount to calling places
export const promiseRequest = <T = any>(
    baseurl: string, postData?: IDictionary<any> | null,
    retryCount = 0, cancelTokenSource: CancelTokenSource | undefined = undefined, serviceTimeout: number | undefined = undefined,
) => new Promise<T>((resolve, reject) => {
    const rqParams = {
        baseurl,
        action: null,
        postData,
        onSuccess: (data: any) => {
            resolve(data);
        },
        onError: (e: any) => {
            reject(e);
        },
        retryCount,
        cancelTokenSource,
        onCancel: () => {
            reject();
        },
        getTokenUrl: typeof sessionStorage.getItem('token') === 'string' ? sessionStorage.getItem('token') as string : '',
        serviceTimeout
    }
    sendRequest(rqParams);
});


export default sendRequest;
