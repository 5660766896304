import {Styled} from "./TopFiveDestinations.styles"
import SkeletonBox from "../../../../lib/UI/SkeletonBox/SkeletonBox";

type TopFiveDestinationsProps = {
    options: any[]
    isLoading?: boolean
}

export enum TopFiveType {
    plain = 'plain',
    list = 'List'
}

function getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const TopFiveDestinations = (
    {
        options,
        isLoading
    }: TopFiveDestinationsProps
) => {


    return (
        <Styled.Top__Five__Destinations__Holder>
            {isLoading ? (
                <>
                    {Array.from({length: 5}).map((_: any) => (
                        <Styled.Top__Five__Option__Skeleton>
                            <SkeletonBox
                                style={{
                                    minHeight: '26px',
                                    width: '100%',
                                    maxWidth: `${getRandomNumber(15, 30)}%`
                                }}
                            />
                            <Styled.Option__Content__Holder>
                                <Styled.Option__MultiMedia__Holder>
                                    <SkeletonBox
                                        style={{
                                            maxWidth: 'unset',
                                            maxHeight: 'unset',
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                </Styled.Option__MultiMedia__Holder>
                                <Styled.Description__Holder>
                                    {Array.from({length: 2}).map((_: any) => (
                                        <Styled.Sub__Option__Holder>
                                            <SkeletonBox
                                                style={{
                                                    minHeight: '22px',
                                                    width: '100%',
                                                    maxWidth: `${getRandomNumber(25, 45)}%`,
                                                    marginBottom: '20px'
                                                }}
                                            />
                                            {Array.from({length: 5}).map((_: any) => (
                                                <SkeletonBox
                                                    style={{
                                                        margin: '6px 0 ',
                                                        minHeight: '16px',
                                                        width: '100%',
                                                        maxWidth: `${getRandomNumber(25, 65)}%`
                                                    }}
                                                />
                                            ))}
                                        </Styled.Sub__Option__Holder>
                                    ))}
                                </Styled.Description__Holder>
                            </Styled.Option__Content__Holder>
                        </Styled.Top__Five__Option__Skeleton>
                    ))}

                </>
            ) : (
                <>
                    {Object.keys(options ?? {})?.map((option: any) => {

                        const {
                            title,
                            priority,
                            multimedia,
                            subOptions
                        } = options[option]


                        return (
                            <Styled.Top__Five__Option__Holder>
                                <Styled.Option__Title>
                                    {priority + 1}{'. '}{title}
                                </Styled.Option__Title>
                                <Styled.Option__Content__Holder>
                                    <Styled.Option__MultiMedia__Holder>
                                        <Styled.Option__Image
                                            src={multimedia}
                                        />
                                    </Styled.Option__MultiMedia__Holder>
                                    <Styled.Description__Holder>
                                        {Object.keys(subOptions)?.map((subOption: any) => {
                                            return (
                                                <Styled.Sub__Option__Holder>
                                                    <Styled.Sub__Option__Title>
                                                        {subOptions[subOption].title}
                                                    </Styled.Sub__Option__Title>
                                                    {subOptions[subOption]?.list?.map((listOption: any) => (
                                                        <Styled.Sub__Option__List>
                                                            {listOption}
                                                        </Styled.Sub__Option__List>
                                                    ))}
                                                </Styled.Sub__Option__Holder>
                                            )
                                        })}
                                    </Styled.Description__Holder>
                                </Styled.Option__Content__Holder>
                            </Styled.Top__Five__Option__Holder>
                        )

                    })}
                </>
            )}
        </Styled.Top__Five__Destinations__Holder>
    )

}

export default TopFiveDestinations