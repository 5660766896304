import PageHolder from "../../lib/UI/PageHolder";
import {Styled} from "./TravelTips.styles";
import {useTranslation} from "react-i18next";
import SectionTabs, {tabSizes} from "../../components/Sections/SectionTabs";
import {useNavigate, useParams} from "react-router-dom";
import {Travel__Tips__No__Param} from "../../lib/Const/Routes/Routes";
import General from "./General";
import PerCountry from "./PerCountry";
import {useState} from "react";


export enum travelTipsTypes {
    general = 'General',
    perCountry = 'Per-Country'
}

const TravelTips = () => {

    const [state, setState] = useState<string[]>([])

    const {t} = useTranslation()

    const {type} = useParams()

    const navigate = useNavigate()

    const onTravelTabsChange = (tab: string) => {
        navigate(Travel__Tips__No__Param + `/${tab}`)
    }


    return (
        <PageHolder>
            <Styled.Travel__Tips__Content__Holder>
                <Styled.Travel__Tips__Title>
                    {t('lbl_travel_tips_title')}
                </Styled.Travel__Tips__Title>
                <Styled.Travel__Tips__Subtitle>
                    {t('lbl_travel_tips_subtitle')}
                </Styled.Travel__Tips__Subtitle>
                <SectionTabs
                    size={tabSizes.large}
                    selected={type}
                    tabs={[travelTipsTypes.general, travelTipsTypes.perCountry]}
                    onClick={onTravelTabsChange}
                />
                {type === travelTipsTypes.general && (
                    <General/>
                )}
                {type === travelTipsTypes.perCountry && (
                    <PerCountry/>
                )}

            </Styled.Travel__Tips__Content__Holder>
        </PageHolder>
    )
}

export default TravelTips