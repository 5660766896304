import {Styled} from "./Navigation.styles"
import {navigationOptionsIconsList} from "../../lib/Hooks/useNavigation";
import {faQuestion} from "@fortawesome/pro-light-svg-icons";

type DropDownProps = {
    list: any[]
}

const NavigationToolTipContents = (
    {
        list
    }: DropDownProps) => {

    return (
        <Styled.Navigation__Drop__Down>
            {list?.sort((optionA: any, optionB: any) => optionA?.priority - optionB?.priority)?.map((option: any) => (
                <Styled.Navigation__Drop__Down__Option
                    key={'navigation_option_' + option?.path}
                    href={window.location.origin + option?.path}
                >
                    <Styled.Navigation__Drop__Down__Icon
                        icon={navigationOptionsIconsList[option.fieldName] ?? faQuestion}
                    />
                    {option.title}
                </Styled.Navigation__Drop__Down__Option>
            ))}
        </Styled.Navigation__Drop__Down>
    )
}

export default NavigationToolTipContents