import {Styled} from "./Navigation.styles"
import {useNavigate} from "react-router-dom";
import Tooltip from "../../lib/UI/Tooltip";
import NavigationToolTipContents from "./NavigationToolTipContent";
import useNavigation from "../../lib/Hooks/useNavigation";
import {faBars} from "@fortawesome/pro-solid-svg-icons";
import {Main__First__Page} from "../../lib/Const/Routes/Routes";

const BusinessNavigation = () => {

    const navigate = useNavigate()

    const {
        businessNavigationOptions,
        toggleNavigationDrawer
    } = useNavigation()


    const onBarsOnClickHandler = () => {
        toggleNavigationDrawer(true)
    }

    const onBusinessLogoClick = () => {
        navigate(Main__First__Page)
    }


    const wideScreenContent = (
        <Styled.Navigation__Wide__Screen__Holder>
            {businessNavigationOptions.map((option: any) => (
                <Tooltip
                    trigger={'onclick, mouseenter'}
                    key={`navigation_bar_option_${option.label}`}
                    interactive={true}
                    placement={'bottom-start'}
                    disabled={!option?.list}
                    content={<NavigationToolTipContents list={option?.list}/>}
                >
                    <Styled.Navigation__Wide__Screen__Option
                        onClick={option?.onClick}
                    >
                        {option.label}
                    </Styled.Navigation__Wide__Screen__Option>
                </Tooltip>
            ))}
        </Styled.Navigation__Wide__Screen__Holder>
    )

    const smallScreenContent = (
        <Styled.Navigation__Small__Screen__Holder>
            <Styled.Menu__Bars__Icon
                icon={faBars}
                onClick={onBarsOnClickHandler}
            />
        </Styled.Navigation__Small__Screen__Holder>
    )

    return (
        <Styled.Business__Navigation__Holder>
            <Styled.Business__Logo__Holder
                onClick={onBusinessLogoClick}
            >
                <Styled.Business__Navigation__Icon
                    src={window.location.origin + '/globe.svg'}
                />
                <Styled.Business__Navigation__Title>
                    <span
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        WOR{' '}
                    </span>
                    {'for'}
                    <br/>
                    {'Business'}
                </Styled.Business__Navigation__Title>
            </Styled.Business__Logo__Holder>
            {wideScreenContent}
            {smallScreenContent}
        </Styled.Business__Navigation__Holder>
    )
}

export default BusinessNavigation