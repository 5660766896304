import {faFlower, faLeafOak, faSnowflake, faSun} from "@fortawesome/pro-light-svg-icons";

enum seasons {
    summer = 'Summer',
    fall = 'Fall',
    spring = 'Spring',
    winter = 'Winter'
}

const seasonsConfig: any = {
    [seasons.fall]: {
        type: seasons.fall,
        color: '#ff6e07',
        backgroundImage: 'https://www.usatoday.com/gcdn/media/2020/09/11/USATODAY/usatsports/ghows_gallery-WL-822009996-7ffc2013.jpg?crop=1440,810,x0,y495&width=1440&height=810&format=pjpg&auto=webp',
        icon: faLeafOak
    },
    [seasons.summer]: {
        type: seasons.summer,
        color: '#e0c210',
        backgroundImage: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/245364380.jpg?k=f26fb54a95e27f1f4377c51d772167f016115a1dc05b27ac8596b1643af606e5&o=&hp=1',
        icon: faSun
    },
    [seasons.spring]: {
        type: seasons.spring,
        color: '#62ff07',
        backgroundImage: 'https://cdn.mos.cms.futurecdn.net/tXr5UjKDsDBrYBQM9znb2c-1200-80.jpg',
        icon: faFlower
    },
    [seasons.winter]: {
        type: seasons.winter,
        color: '#2ce5de',
        backgroundImage: 'https://cdn.mos.cms.futurecdn.net/8Zw7hWD5ZaquyftsRbEmof-1200-80.jpg',
        icon: faSnowflake
    }
}

export {
    seasons,
    seasonsConfig
}