import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import {rgba} from "polished";

const Section__Tabs__Holder = styled(FlexBox)`
  flex-direction: initial;
  padding: 10px 0;
  flex-wrap: nowrap;
  overflow: auto;
`
const Section__Tab = styled(FlexBox)<{ fontSize: string, isSelected: boolean, isInteractive: boolean }>`
  padding: 10px 20px;
  margin: 0;
  font-size: ${({fontSize}) => fontSize};
  font-weight: bold;
  height: auto;
  text-align: center;
  cursor: pointer;
  border-right: 1px solid ${({theme}) => theme.color};
  transition: color 200ms, transform 200ms;
  transform: ${({isInteractive, isSelected}) => isInteractive && isSelected ? 'scale(1.1)' : 'unset'};
  color: ${({theme, isSelected}) => isSelected ? theme.color : rgba(theme.color, 0.7)};

  &:hover {
    color: ${({theme, isInteractive}) => isInteractive ? theme.color : rgba(theme.color, 0.7)};
  }

  &:last-of-type {
    border-right: none;
  }
`
export const Styled = {
    Section__Tab,
    Section__Tabs__Holder
}