import {Styled} from "./TravelTips.styles"
import TravelTips from "../../components/Sections/SectionTypes/TravelTips";
import {sectionTypeEnum} from "../../components/Sections/SectionTypes/TravelTips/DescriptionContentPart";
import {useTranslation} from "react-i18next";
import {faChevronDown, faChevronUp} from "@fortawesome/pro-solid-svg-icons";
import {useState} from "react";
import BoxFilters from "../../components/BoxFilters";
import {faBuilding, faCloudSun} from "@fortawesome/pro-light-svg-icons";
import {includes} from "lodash-es";

const General = () => {

    const [filters, setFilters] = useState({isOpen: false, selected: []})

    const {t} = useTranslation()

    const values = [
        {
            image: 'https://i.guim.co.uk/img/media/e994bb7ab8b900b70a4ac07026f85ba6ac06e25e/0_248_7360_4415/master/7360.jpg?width=1200&quality=85&auto=format&fit=max&s=775e8347a5a62a6d5bef19bd670bc3ba',
            title: 'Hiking',
            options: [
                {
                    type: sectionTypeEnum.plainText,
                    title: t('lbl_about_place_travel_tip'),
                    options: 'Italy\'s mountains offer a stunning backdrop to its landscapes, boasting a diverse range of peaks, valleys, and alpine scenery. The Italian Alps dominate the northern border, featuring iconic summits such as the majestic Matterhorn and Monte Rosa. In the central regions, the Apennine Range stretches across the peninsula, offering scenic hiking trails and picturesque villages nestled among rolling hills. These mountains are not just for adventurers; they also host unique flora and fauna, including alpine flowers, ibex, and chamois. Italy\'s mountainous terrain provides both recreational opportunities and a rich natural heritage to explore and appreciate.'
                },
                {
                    type: sectionTypeEnum.list,
                    title: t('lbl_most_known_locations_travel_tips'),
                    options: [
                        'Dolomites',
                        'Monte Bianco',
                        'Gran Paradiso',
                        'Adamello-Presanella',
                        'Sella Group'
                    ]
                },
                {
                    type: sectionTypeEnum.plainText,
                    title: t('lbl_flora_fauna_travel_tip'),
                    options: 'Italy\'s mountainous regions boast diverse flora and fauna. Alpine flora, such as edelweiss and alpine gentian, dot the rugged landscapes, while forests of beech, chestnut, and pine thrive at lower elevations. Fauna includes the elusive ibex, chamois, and red deer, alongside smaller mammals like foxes and marmots. Birds like golden eagles and alpine choughs soar overhead, while streams teem with trout and other aquatic life. Italy\'s mountain ecosystems are not only rich in biodiversity but also contribute to the country\'s cultural and ecological heritage, attracting nature enthusiasts and conservation efforts alike.'
                },
                {
                    type: sectionTypeEnum.list,
                    title: t('lbl_what_to_take_travel_tip'),
                    options: [
                        'Appropriate Clothing:',
                        'Navigation Tools',
                        'First Aid Kit',
                        'Food and Water',
                        'Camera',
                        'Communication Device'
                    ]
                },

            ]
        },
        {
            image: 'https://upload.wikimedia.org/wikipedia/commons/8/84/Ski_Famille_-_Family_Ski_Holidays.jpg',
            title: 'Skiing',
            options: [
                {
                    type: sectionTypeEnum.plainText,
                    title: t('lbl_about_place_travel_tip'),
                    options: 'Skiing in Italy\'s mountains offers exhilarating slopes amid stunning scenery. From the Dolomites\' dramatic peaks to the iconic resorts of Aosta Valley and the Italian Alps, there\'s terrain for all levels. Enjoy après-ski with hearty cuisine and explore off-slope activities like snowshoeing and ice climbing. Whether seeking family fun or challenging runs, Italy\'s resorts promise unforgettable winter adventures.'
                },
                {
                    type: sectionTypeEnum.list,
                    title: t('lbl_most_known_locations_travel_tips'),
                    options: [
                        'Cortina d\'Ampezzo',
                        'Courmayeur',
                        'Livigno',
                        'Val Gardena',
                        'Madonna di Campiglio',
                        'Sestriere'
                    ]
                },
                {
                    type: sectionTypeEnum.multipleList,
                    title: t('lbl_dos_donts_travel_tips'),
                    options: [
                        {
                            title: 'Dos',
                            options: [
                                'Check weather and avalanche conditions before heading out.',
                                'Respect mountain etiquette and yield to downhill skiers.',
                                'Stay hydrated and protect against sunburn with sunscreen and sunglasses',
                                'Adhere to resort rules and safety guidelines, including wearing helmets.'
                            ]
                        },
                        {
                            title: 'Don\'ts',
                            options: [
                                'Don\'t venture off-piste without proper equipment and knowledge.',
                                'Don\'t underestimate the altitude; acclimate gradually to avoid altitude sickness.',
                                'Don\'t litter or disturb wildlife; respect the mountain environment.',
                                'Don\'t ski under the influence of alcohol or drugs.',
                                'Don\'t ignore signs or closures; they\'re there for your safety.'
                            ]
                        },
                    ]
                },
                {
                    type: sectionTypeEnum.list,
                    title: t('lbl_what_to_take_travel_tip'),
                    options: [
                        'Appropriate Clothing:',
                        'Navigation Tools',
                        'First Aid Kit',
                        'Food and Water',
                        'Camera',
                        'Communication Device'
                    ]
                },

            ]
        },
        {
            image: 'https://hips.hearstapps.com/hmg-prod/images/canal-grande-with-basilica-di-santa-maria-della-royalty-free-image-1568738188.jpg?crop=1.00xw:0.918xh;0,0.0816xh&resize=980:*',
            title: 'City Breaks',
            options: [
                {
                    type: sectionTypeEnum.plainText,
                    title: t('lbl_about_place_travel_tip'),
                    options: 'Italy\'s mountains offer a stunning backdrop to its landscapes, boasting a diverse range of peaks, valleys, and alpine scenery. The Italian Alps dominate the northern border, featuring iconic summits such as the majestic Matterhorn and Monte Rosa. In the central regions, the Apennine Range stretches across the peninsula, offering scenic hiking trails and picturesque villages nestled among rolling hills. These mountains are not just for adventurers; they also host unique flora and fauna, including alpine flowers, ibex, and chamois. Italy\'s mountainous terrain provides both recreational opportunities and a rich natural heritage to explore and appreciate.'
                },
                {
                    type: sectionTypeEnum.list,
                    title: t('lbl_most_known_locations_travel_tips'),
                    options: [
                        'Dolomites',
                        'Monte Bianco',
                        'Gran Paradiso',
                        'Adamello-Presanella',
                        'Sella Group'
                    ]
                },
                {
                    type: sectionTypeEnum.plainText,
                    title: t('lbl_flora_fauna_travel_tip'),
                    options: 'Italy\'s mountainous regions boast diverse flora and fauna. Alpine flora, such as edelweiss and alpine gentian, dot the rugged landscapes, while forests of beech, chestnut, and pine thrive at lower elevations. Fauna includes the elusive ibex, chamois, and red deer, alongside smaller mammals like foxes and marmots. Birds like golden eagles and alpine choughs soar overhead, while streams teem with trout and other aquatic life. Italy\'s mountain ecosystems are not only rich in biodiversity but also contribute to the country\'s cultural and ecological heritage, attracting nature enthusiasts and conservation efforts alike.'
                },
                {
                    type: sectionTypeEnum.list,
                    title: t('lbl_what_to_take_travel_tip'),
                    options: [
                        'Appropriate Clothing:',
                        'Navigation Tools',
                        'First Aid Kit',
                        'Food and Water',
                        'Camera',
                        'Communication Device'
                    ]
                },

            ]
        },
        {
            image: 'https://www.travelandleisure.com/thmb/PtkzpF17oxHfxuTbsnUx7oJY20A=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/lake-tahoe-california-USLAKES0920-59df9ea26eaf4bbba7620eb604f0af3c.jpg',
            title: 'Lakes',
            options: [
                {
                    type: sectionTypeEnum.plainText,
                    title: t('lbl_about_place_travel_tip'),
                    options: 'Italy boasts an enchanting array of lakes, each with its own allure. Lake Como, nestled against the Alps, captivates with its serene beauty and luxurious villas. Garda, Italy\'s largest lake, entices visitors with its sparkling waters and charming lakeside towns. Maggiore enchants with its elegant promenades and picturesque islands. Trasimeno, surrounded by rolling hills, offers a tranquil retreat in the heart of Umbria. From the romantic vistas of Orta to the hidden gems of Iseo, Italy\'s lakes invite exploration, promising unforgettable experiences amidst stunning natural landscapes and cultural riches.'
                },
                {
                    type: sectionTypeEnum.list,
                    title: t('lbl_most_known_locations_travel_tips'),
                    options: [
                        'Lake Como',
                        'Lake Garda',
                        'Lake Maggiore',
                        'Lake Orta',
                        'Lake Iseo'
                    ]
                },
                {
                    type: sectionTypeEnum.plainText,
                    title: t('lbl_flora_fauna_travel_tip'),
                    options: 'The Italian lakes boast diverse flora and fauna, adding to their natural charm. Lush vegetation lines their shores, including cypress trees, olive groves, and colorful flowers. Wildlife thrives in these habitats, with species such as herons, ducks, and swans gracing the lakeshores. In the surrounding forests and mountains, one can find deer, foxes, and a variety of bird species. The lakes also support aquatic life, including fish like trout and pike, contributing to the rich ecological tapestry of these enchanting landscapes.'
                },
                {
                    type: sectionTypeEnum.list,
                    title: t('lbl_what_to_take_travel_tip'),
                    options: [
                        'SPF cream',
                        'Light Jacket or Sweater',
                        'Comfortable Footwear',
                        'Swimwear',
                        'Camera',
                        'Water Bottle'
                    ]
                },

            ]
        },
        {
            image: 'https://files.holidaycottages.co.uk/blogs%2F1653382626498-porthcurno_cornwall_alina.png',
            title: 'Sea Sides',
            options: [
                {
                    type: sectionTypeEnum.plainText,
                    title: t('lbl_about_place_travel_tip'),
                    options: 'Italy\'s coastline is a tapestry of beauty, offering stunning beaches, charming villages, and rich cultural heritage. From the rugged cliffs of the Amalfi Coast to the sandy shores of Sardinia, the sea is an integral part of Italian life. Visitors can explore historic ports like Cinque Terre, relax on the glamorous beaches of the Amalfi Coast, or dive into the crystal-clear waters of Sicily. Each region boasts its own distinct character, cuisine, and attractions, making Italy\'s seaside a must-see destination for travelers seeking sun, sand, and la dolce vita.'
                },
                {
                    type: sectionTypeEnum.list,
                    title: t('lbl_most_known_locations_travel_tips'),
                    options: [
                        'Dolomites',
                        'Monte Bianco',
                        'Gran Paradiso',
                        'Adamello-Presanella',
                        'Sella Group'
                    ]
                },
                {
                    type: sectionTypeEnum.plainText,
                    title: t('lbl_flora_fauna_travel_tip'),
                    options: 'The Italian lakes boast diverse flora and fauna, adding to their natural charm. Lush vegetation lines their shores, including cypress trees, olive groves, and colorful flowers. Wildlife thrives in these habitats, with species such as herons, ducks, and swans gracing the lakeshores. In the surrounding forests and mountains, one can find deer, foxes, and a variety of bird species. The lakes also support aquatic life, including fish like trout and pike, contributing to the rich ecological tapestry of these enchanting landscapes.'
                },
                {
                    type: sectionTypeEnum.list,
                    title: t('lbl_what_to_take_travel_tip'),
                    options: [
                        'SPF cream',
                        'Light Jacket or Sweater',
                        'Comfortable Footwear',
                        'Swimwear',
                        'Camera',
                        'Water Bottle'
                    ]
                },

            ]
        }
    ]

    const onFiltersToggle = () => {
        setFilters((state: any) => ({
            ...state,
            isOpen: !state.isOpen
        }))
    }

    const onFiltersOptionClick = (newOption: string) => {
        setFilters((state: any) => ({
            ...state,
            selected: includes(state.selected, newOption) ? state.selected.filter((item: string) => item !== newOption) : [...state.selected, newOption]
        }))
    }

    const filterOptions: any = [
        {
            title: 'Filters',
            priority: true,
            options: [
                {
                    type: 'general_tip_filter_indoor',
                    icon: faBuilding,
                    color: '#007e15',
                },
                {
                    type: 'general_tip_filter_outdoor',
                    icon: faCloudSun,
                    color: '#373741'
                },
            ]
        },
    ]

    return (
        <Styled.Travel__Tips__General__Content>
            <Styled.Travel__Tips__General_Filters__Holder>
                {filters.isOpen && (
                    <Styled.Travel__Tips__Filter__Box__Holder>
                        <BoxFilters
                            selectedOptions={filters.selected}
                            onOptionClick={onFiltersOptionClick}
                            options={filterOptions}
                            showAllOptions={true}
                        />
                    </Styled.Travel__Tips__Filter__Box__Holder>
                )}
                <Styled.Travel__Tips__Show__Filters__Label
                    onClick={onFiltersToggle}
                >
                    {filters?.isOpen ? t('lbl_hide_filters') : t('lbl_show_filters')}
                    <Styled.Travel__Tips__Show__Filters__Icon
                        icon={filters?.isOpen ? faChevronUp : faChevronDown}
                    />
                </Styled.Travel__Tips__Show__Filters__Label>
            </Styled.Travel__Tips__General_Filters__Holder>
            <TravelTips
                values={values}
            />
        </Styled.Travel__Tips__General__Content>
    )
}

export default General