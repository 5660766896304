import {Styled} from "./DiscoverWorld.styles"
import {useTranslation} from "react-i18next";
import {isEmpty} from "lodash-es";

type RecommendedDestinationsProps = {
    value: any
}

const RecommendedDestinations = (
    {
        value
    }: RecommendedDestinationsProps) => {

    const {t} = useTranslation()

    const content = !isEmpty(value.list) ? (
        <Styled.Recommended__Destinations__Sections__Holder>
            {value?.list?.map((section: any) => {

                const {
                    title,
                    list
                } = section

                return (
                    <Styled.Recommended__Destinations__Section
                        key={'recommended_destinations_section' + title}
                    >
                        <Styled.Recommended__Destinations__Section__Title>
                            {title}
                        </Styled.Recommended__Destinations__Section__Title>
                        <Styled.Recommended__Destinations__Section__Options>
                            {list?.map((option: any) => {

                                const {title: optionTitle, image} = option

                                return (
                                    <Styled.Recommended__Destinations__Option
                                        key={'recommended_destination_option' + title + optionTitle}
                                    >
                                        <Styled.Recommended__Destinations__Image
                                            src={image ?? ''}
                                        />
                                        <Styled.Recommended__Destinations__Option__Title>
                                            {optionTitle}
                                        </Styled.Recommended__Destinations__Option__Title>
                                    </Styled.Recommended__Destinations__Option>
                                )
                            })}

                        </Styled.Recommended__Destinations__Section__Options>
                    </Styled.Recommended__Destinations__Section>
                )
            })}
        </Styled.Recommended__Destinations__Sections__Holder>
    ) : (
        <Styled.Recommended__Destinations__Empty__List>
            {t('lbl_recommended_destinations_empty_list')}
        </Styled.Recommended__Destinations__Empty__List>
    )

    return (
        <Styled.Recommended__Destinations__Holder>
            {content}
        </Styled.Recommended__Destinations__Holder>
    )
}

export default RecommendedDestinations