import {Styled} from "./Options.styles"

type OptionsProps = {
    options: string[]
}

const Options = (
    {
        options
    }: OptionsProps
) => {

    return (
        <Styled.Options__Content__Holder>
            {options.map((option: string) => (
                <>
                <span
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    {'• '}
                </span>
                    {option}
                    <br/>
                </>
            ))}
        </Styled.Options__Content__Holder>
    )
}


export default Options