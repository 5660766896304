import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import Text from "../../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {rgba} from "polished";

const Advertisement__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
`
const Advertisement__Header = styled(FlexBox)`
  width: calc(100% - 20px);
  padding: 0 10px;
  gap: 20px 10px;
  margin-top: 40px;
`
const Advertisement__Title = styled(Text)`
  font-size: 26px;
  font-weight: bold;
`
const Advertisement__Icon = styled(FontAwesomeIcon)`
  margin-right: 15px;
  height: 30px;
  width: 30px;
`
const MainPage__Advertisement__Holder = styled(FlexBox)`
  margin-top: 40px;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  flex-direction: column;
  border-radius: 5px;
`
const Main__Page__Advertisement__Visualiser = styled(FlexBox)`
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  padding: 10px;
  max-height: 70vh;
  overflow: auto;
  border: 1px solid ${({theme}) => theme.color};
`

const Advertisement__Section__Title = styled(Text)`
  font-size: 26px;
`
const Advertisement__Section__Content__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
`
const Advertisement__Selected__Section__Details = styled(FlexBox)`
  flex-direction: column;
  min-width: 250px;
  width: 30%;
  padding: 10px;
  padding-right: 0px;
  border-left: 1px solid ${({theme}) => theme.color};
  margin-left: 10px;
`
const Main__Page__First__Article__Visualiser = styled(FlexBox)<{ isActive: boolean }>`
  width: 100%;
  aspect-ratio: 2 / 1;
  cursor: pointer;
  text-align: center;
  background-color: ${({theme, isActive}) => isActive ? theme.brdExclusiveColor : rgba(theme.color, 0.3)};
  transition: background-color 200ms;

  &:hover {
    background-color: ${({theme, isActive}) => isActive ? theme.brdExclusiveColor : rgba(theme.color, 0.6)};
  }
`
const Advertisement__Main___Page__Visual__Holder = styled(FlexBox)`
  width: 80%;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 10px;
`
const Views__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  border-radius: 15px;
  margin: 10px 0;
  margin-left: auto;
  border: 1px solid ${({theme}) => theme.color};
`
const View__Icon = styled(FontAwesomeIcon)<{ isSelected: boolean }>`
  height: 14px;
  width: 14px;
  padding: 8px 10px;
  cursor: pointer;
  color: ${({theme, isSelected}) => rgba(theme.color, isSelected ? 1 : 0.3)};
  border-right: 1px solid ${({theme}) => theme.color};

  &:hover {
    color: ${({theme, isSelected}) => rgba(theme.color, isSelected ? 1 : 0.4)};
  }

  &:last-of-type {
    border-right: none;
  }
`
const First__Article__Title = styled(Text)`
  font-size: 36px;
  font-weight: bold;
  margin: auto;
`
const Selected__Option__Title = styled(Text)`
  font-size: 24px;
  font-weight: bold;
`
const Advertisement__Selected__Section__Header = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
`

const Advertisement__Selected__Section__Clear__Icon = styled(FontAwesomeIcon)`
  height: 24px;
  width: 24px;
  color: ${({theme}) => theme.color};
  margin-left: auto;
  margin-top: 3px;
  padding-left: 10px;
  cursor: pointer;
`
const Main__Page__Desktop__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  width: 100%;
  flex-wrap: nowrap;
  overflow: auto;

`
const Main__Page__Desktop__Separated__Section = styled(FlexBox)`
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid ${({theme}) => theme.color};
  flex-wrap: nowrap;
`
const Main__Page__Desktop__Separated__Bigger__Area = styled(FlexBox)`
  width: 70%;

`
const Main__Page__Desktop__B__Option = styled(FlexBox)<{ isActive: boolean }>`
  width: calc(50% - 5px);
  aspect-ratio: 2 /1;
  background-color: ${({theme, isActive}) => isActive ? theme.brdExclusiveColor : rgba(theme.color, 0.3)};
  transition: background-color 200ms;
  cursor: pointer;
`

const Main__Page__Desktop__B__Section__Holder = styled(FlexBox)<{ isActive: boolean }>`
  width: 100%;
  gap: 10px;

  &:hover ${Main__Page__Desktop__B__Option} {
    background-color: ${({theme, isActive}) => isActive ? theme.brdExclusiveColor : rgba(theme.color, 0.6)};
  }
`
const Second__Article__Title = styled(Text)`
  font-size: 20px;
  font-weight: bold;
  margin: auto;
`
const Main__Page__Desktop__D__Option = styled(FlexBox)<{ isActive: boolean }>`
  width: calc(100%);
  margin: 10px 0;
  aspect-ratio: 4 /1;
  background-color: ${({theme, isActive}) => isActive ? theme.brdExclusiveColor : rgba(theme.color, 0.3)};
  transition: background-color 200ms;
  cursor: pointer;
`
const Main__Page__Desktop__C__Option = styled(FlexBox)<{ isActive: boolean }>`
  width: calc(100%);
  aspect-ratio: 2 /1;
  background-color: ${({theme, isActive}) => isActive ? theme.brdExclusiveColor : rgba(theme.color, 0.3)};
  transition: background-color 200ms;
  cursor: pointer;
`

const Main__Page__Desktop__Separated__Smaller__Area = styled(FlexBox)<{ isActive: boolean }>`
  width: 30%;
  margin-left: 10px;
  padding-left: 10px;
  flex-direction: column;
  gap: 10px;
  border-left: 1px solid ${({theme}) => theme.color};

  &:hover ${Main__Page__Desktop__C__Option} {
    background-color: ${({theme, isActive}) => isActive ? theme.brdExclusiveColor : rgba(theme.color, 0.6)};
  }
`
const Advertisement__Selected__Section__Content = styled(FlexBox)`
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  align-content: space-between;
  justify-content: space-between;
`
const Advertisement__Selected__Section__Section__Description = styled(Text)`
  margin-top: 15px;
  line-height: 24px;
  font-size: 20px;
`
const Advertisement__Selected__Section__Pricing = styled(Text)`

`
const Advertisement__Selected__Pricing__Title = styled(Text)`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: bold;
`
const Advertisement__Price__Type__Title = styled(Text)`
  font-weight: bold;
  margin: 10px 0;
`
const Advertisement__Selected__Analytics = styled(FlexBox)`
  font-size: 20px;
  width: 100%;
  flex-direction: column;
`
const Advertisement__Analytics__Title = styled(Text)`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: bold;
`
const Advertisement__Selected__Action__Buttons = styled(FlexBox)`
  margin-top: auto;
  align-content: flex-end;
  justify-content: flex-end;
`
const Close__Able__Section__Icon = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.color};
  font-size: 26px;

`
const Close__Able__Section__Header = styled(FlexBox)<{ isOpen: boolean }>`
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${({theme, isOpen}) => isOpen ? 'transparent' : theme.color};
  cursor: pointer;
  transition: 200ms;

  &:hover {
    scale: 1.01;
  }
`


export const Styled = {
    Close__Able__Section__Header,
    Close__Able__Section__Icon,
    Advertisement__Selected__Action__Buttons,
    Advertisement__Analytics__Title,
    Advertisement__Selected__Analytics,
    Advertisement__Price__Type__Title,
    Advertisement__Selected__Pricing__Title,
    Advertisement__Selected__Section__Pricing,
    Advertisement__Selected__Section__Section__Description,
    Advertisement__Selected__Section__Content,
    Main__Page__Desktop__C__Option,
    Main__Page__Desktop__Separated__Smaller__Area,
    Main__Page__Desktop__D__Option,
    Second__Article__Title,
    Main__Page__Desktop__B__Option,
    Main__Page__Desktop__B__Section__Holder,
    Main__Page__Desktop__Separated__Bigger__Area,
    Main__Page__Desktop__Separated__Section,
    Main__Page__Desktop__Holder,
    Advertisement__Selected__Section__Clear__Icon,
    Advertisement__Selected__Section__Header,
    Selected__Option__Title,
    First__Article__Title,
    View__Icon,
    Views__Holder,
    Advertisement__Main___Page__Visual__Holder,
    Main__Page__First__Article__Visualiser,
    Advertisement__Selected__Section__Details,
    Advertisement__Section__Content__Holder,
    Advertisement__Section__Title,
    Main__Page__Advertisement__Visualiser,
    MainPage__Advertisement__Holder,
    Advertisement__Header,
    Advertisement__Title,
    Advertisement__Icon,
    Advertisement__Holder
}