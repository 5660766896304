import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import Text from "../../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {rgba} from "polished";
import Image from "../../../lib/UI/Image";

const My__Products__Holder = styled(FlexBox)`
  flex-direction: column;
`
const My__Products__Header = styled(FlexBox)`
  width: calc(100% - 20px);
  padding: 0 10px;
  gap: 20px 10px;
  margin-top: 40px;
`
const My__Products__Title = styled(Text)`
  font-size: 26px;
  font-weight: bold;
`
const My__Products__Icon = styled(FontAwesomeIcon)`
  margin-right: 15px;
  height: 30px;
  width: 30px;
`
const My__Products__Action__Buttons = styled(FlexBox)`
  margin-left: auto;
  gap: 10px;
`
const My__Products__Transfer__Product = styled(Text)`
  cursor: pointer;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => rgba(theme.color, 0.3)};
`
const My__Products__New__Product = styled(Text)`
  cursor: pointer;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.color};
  color: ${({theme}) => theme.backgroundColor};

`
const My__Products__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  @media (max-width: 600px) {
    margin-top: 40px;
  }
`
const My__Product = styled(FlexBox)`
  flex-wrap: nowrap;
  padding: 15px 20px;
  cursor: pointer;
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.3)};
  transition: background-color 200ms;


  &:first-of-type {
    border-top: 1px solid ${({theme}) => rgba(theme.color, 0.3)};
  }

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.1)}
  }
`
const My__Product__Image = styled(Image)`
  height: 70px;
  width: 120px;
  object-fit: cover;

  @media (max-width: 600px) {
    height: 150px;
    flex-shrink: 0;
    width: 200px;
  }
  @media (max-width: 400px) {

    width: 100%;
  }

`
const My__Product__Title = styled(Text)`
  margin: auto 0;
  margin-left: 15px;
  font-weight: bold;
  width: 40%;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  font-size: 18px;

  @media (max-width: 600px) {
    margin: 0;
    width: 100%;
    max-width: unset;
    margin-bottom: 20px;
  }
`
const My__Products__Top__Titles = styled(FlexBox)`
  flex-wrap: nowrap;
  margin-top: 40px;
  height: 19px;
  padding: 5px 20px;
  position: relative;
  @media (max-width: 600px) {
    display: none;
  }
`
const My__Products__Top__Name = styled(Text)`
  position: absolute;
  left: 155px;
`
const My__Product__Status = styled(Text)<{ backgroundColor: string }>`
  font-weight: bold;
  margin: auto 0;
  padding: 4px 8px;
  border-radius: 15px;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  background-color: ${({backgroundColor}) => backgroundColor};
  @media (max-width: 600px) {
    margin-left: 10px;
  }
`
const My__Product__Status__Holder = styled(FlexBox)`
  width: 25%;
  flex-shrink: 1;
  @media (max-width: 600px) {
    width: auto;
  }
`
const My__Products__Top__Status = styled(Text)`
  position: absolute;
  left: calc(140px + 40%);
`
const My__Products__Top__Type = styled(Text)`
  position: absolute;
  right: 20px;
`

const My__Product_Type = styled(Text)`
  margin: auto 0;
  font-weight: bold;
  margin-left: auto;

  @media (max-width: 600px) {
    margin: 0;

    margin-top: 10px;
  }
`
const My__Product__Wide__Content = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
  display: flex;
  @media (max-width: 600px) {
    display: none;
  }
`
const My__Product__Small__Content = styled(FlexBox)`
  display: none;
  width: 100%;
  flex-wrap: nowrap;
  @media (max-width: 600px) {
    display: flex;
  }
  @media (max-width: 400px) {
    flex-direction: column;
  }
`
const My__Product__Small__Details__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-left: 15px;
  @media (max-width: 400px) {
    margin-left: unset;
    margin-top: 15px;
  }
`
const My__Product__Type = styled(Text)`
  margin: auto 0;
`

export const Styled = {
    My__Product__Type,
    My__Product__Small__Details__Holder,
    My__Product__Small__Content,
    My__Product__Wide__Content,
    My__Products__Top__Type,
    My__Product_Type,
    My__Product__Status__Holder,
    My__Products__Top__Status,
    My__Product__Status,
    My__Products__Top__Name,
    My__Products__Top__Titles,
    My__Product__Title,
    My__Product__Image,
    My__Product,
    My__Products__Content__Holder,
    My__Products__New__Product,
    My__Products__Transfer__Product,
    My__Products__Action__Buttons,
    My__Products__Icon,
    My__Products__Title,
    My__Products__Header,
    My__Products__Holder
}