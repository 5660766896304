import {hotelOption} from "../../lib/Types";
import {Styled} from "./RegionPage.styles";
import {faMapPin, faStar} from "@fortawesome/pro-solid-svg-icons";

type HotelBoxProps = {
    option: hotelOption
    linkPrefix?: string
}

const HotelBox = (
    {
        option,
        linkPrefix
    }: HotelBoxProps
) => {


    const {
        name,
        image,
        rating,
        location,
        description
    } = option

    return (
        <Styled.Option__Box
            href={linkPrefix + '/' + name.split(' ').join('_')}
        >
            <Styled.Option__Image
                src={image}
            />
            <Styled.Option__Content__Holder>
                <Styled.Title__Holder>
                    <Styled.Option__Name>
                        {name}
                    </Styled.Option__Name>
                    <Styled.Hotel__Rating__Stars__Holder>
                        {Array.from({length: rating}).map((_, index: number) => (
                            <Styled.Hotel__Rating__Star
                                key={'hotel_rating_star' + name + index}
                                icon={faStar}
                            />
                        ))}
                    </Styled.Hotel__Rating__Stars__Holder>
                </Styled.Title__Holder>
                <Styled.Hotel__Location__Holder>
                    <Styled.Hotel__Location__Icon
                        icon={faMapPin}
                    />
                    <Styled.Hotel__Location>
                        {location}
                    </Styled.Hotel__Location>
                </Styled.Hotel__Location__Holder>
                <Styled.Hotel__Description>
                    {description}
                </Styled.Hotel__Description>
            </Styled.Option__Content__Holder>
        </Styled.Option__Box>
    )
}

export default HotelBox