import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import Text from "../../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Setting__Sections__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  gap: 10px;
  padding: 0 10px;
  margin-top: 20px;
`
const Settings__Title = styled(Text)`
  font-size: 26px;
  font-weight: bold;
  margin-top: 40px;
  padding: 0 10px;
`
const Settings__Icon = styled(FontAwesomeIcon)`
  margin-right: 15px;
  height: 30px;
  width: 30px;
`

export const Styled = {
    Settings__Icon,
    Settings__Title,
    Setting__Sections__Content__Holder
}