import styled, {css} from "styled-components";
import FlexBox from "../../../../lib/UI/FlexBox";
import Image from "../../../../lib/UI/Image";
import Text from "../../../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {rgba} from "polished";

const Travel__Tips__Holder = styled(FlexBox)`
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;
  margin: 0 auto;
  gap: 10px;

`
const Travel__Tips__Option = styled(FlexBox)<{ isOpen: boolean }>`
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  width: calc((100% - 20px) / 3);
  position: relative;
  aspect-ratio: 1 / 1.5;

  @media (max-width: 650px) {
    width: calc((100% - 10px) / 2);
  }

  ${({isOpen}) => isOpen && css`
    width: 100% !important;
    aspect-ratio: unset;
    border: 1px solid ${({theme}) => theme.color};
  `}


`
const Travel__Tips__Closed__Option__Image = styled(Image)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 200ms;
  filter: grayscale(0);

  &:hover {
    transform: scale(1.1);
    filter: grayscale(0.45);
  }
`
const Travel__Tips__Option__Title = styled(Text)`
  font-weight: bold;
  position: absolute;
  bottom: 10px;
  left: 10px;
  max-width: 100%;
  font-size: 36px;
  z-index: 10;

  @media (max-width: 800px) {
    font-size: 26px;
  }
`
const Travel__Tips__Open__Option__Header = styled(FlexBox)`
  box-sizing: border-box;
  padding-bottom: 20px;
  width: 100%;
`
const Travel__Tips__Open__Content__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
`
const Travel__Tips__Close__Icon = styled(FontAwesomeIcon)`
  height: 25px;
  width: 25px;
  color: ${({theme}) => theme.color};
  margin: auto 0;
  margin-left: auto;
`
const Travel__Tips__Open__Option__Header__Title = styled(Text)`
  font-size: 32px;
  font-weight: bold;
`
const Travel__Tips__Open__Image__Holder = styled(FlexBox)`
  width: calc((100% - 30px) / 3);
  position: relative;
  overflow: hidden;
  aspect-ratio: 1 / 1.5;
  margin: auto 0;
  border-radius: 5px;
  flex-shrink: 0;
  @media (max-width: 650px) {
    width: calc((100% - 10px) / 2);
  }
`

const Travel__Tips__Open__Content__Image = styled(Image)`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;

`
const Travel__Tips__Open__Content__Description = styled(FlexBox)`
  flex-grow: 1;
  padding: 20px;
  z-index: 10;
  max-width: calc(100% - 20px);
  flex-direction: column;
`
const Travel__Tips__Description__Section__Title = styled(Text)<{ index: number }>`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
  margin-top: ${({index}) => index === 0 ? '5px' : '15px'}
`
const Travel__Tips__Description__Content = styled(Text)`

`
const What__To__Take__Option = styled(Text)<{ index: number }>`
  margin-left: 10px;
  font-size: 18px;
  line-height: 23px;
  ${({index}) => index === 0 && css`
    margin-top: 5px;
  `}
`
const How__To__Plan = styled(Text)`
  margin-top: 5px;
  margin-left: 10px;
  line-height: 23px;
  font-size: 18px;
`
const Travel__Tips__Open__Image__Black__Cover = styled(FlexBox)`
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.7)};
  position: absolute;
  width: 100%;
  height: 100%;
`
const Multiple__List__Title = styled(Text)`
  margin-left: 10px;
  font-size: 18px;
  line-height: 23px;
`
const Multiple__List__Holder = styled(FlexBox)`
  margin-bottom: 10px;
  flex-direction: column;
  flex-wrap: nowrap;
`

export const Styled = {
    Multiple__List__Holder,
    Multiple__List__Title,
    Travel__Tips__Open__Image__Black__Cover,
    How__To__Plan,
    Travel__Tips__Open__Image__Holder,
    What__To__Take__Option,
    Travel__Tips__Description__Content,
    Travel__Tips__Description__Section__Title,
    Travel__Tips__Open__Content__Description,
    Travel__Tips__Open__Content__Image,
    Travel__Tips__Open__Option__Header__Title,
    Travel__Tips__Open__Content__Holder,
    Travel__Tips__Close__Icon,
    Travel__Tips__Open__Option__Header,
    Travel__Tips__Option__Title,
    Travel__Tips__Closed__Option__Image,
    Travel__Tips__Option,
    Travel__Tips__Holder
}