import {attractionOption} from "../../lib/Types";
import {Styled} from "./RegionPage.styles";
import {faClock, faDollarSign} from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "../../lib/UI/Tooltip";
import {useTranslation} from "react-i18next";

type AttractionBoxProps = {
    option: attractionOption
    linkPrefix?: string
}

const AttractionBox = (
    {
        option,
        linkPrefix
    }: AttractionBoxProps
) => {

    const {t} = useTranslation()

    const {
        image,
        name,
        priceWise,
        description,
        span
    } = option

    const toolTipValue: any = {
        0: t('lbl_offer_good_price'),
        1: t('lbl_offer_above_average'),
        2: t('lbl_offer_expensive'),
    }

    const toolTipPriceWise = (
        <Styled.Price__Wise__ToolTip>
            <Styled.Price__Wise__ToolTip__Title>
                {t('lbl_price_wise_tooltip')}
            </Styled.Price__Wise__ToolTip__Title>
            {Array.from({length: 3}).map((_: any, index: number) => {
                return (
                    <Styled.Price__Wise__Option
                        key={name + '_' + index + '_' + 'stars'}
                    >
                        <Styled.Price__Wise__Star__Holder>
                            {Array.from({length: index + 1}).map((_, index: number) => (
                                <Styled.Attraction__Dollar
                                    key={'attraction_tooltip_star' + name + index}
                                    icon={faDollarSign}
                                />
                            ))}
                        </Styled.Price__Wise__Star__Holder>
                        <Styled.Price__Wise__Option__Value
                            isSelected={priceWise === index + 1}
                        >
                            {' - '}{toolTipValue[index]}
                        </Styled.Price__Wise__Option__Value>
                    </Styled.Price__Wise__Option>
                )
            })}
        </Styled.Price__Wise__ToolTip>
    )

    return (
        <Styled.Option__Box
            href={linkPrefix + `/${name.split(' ').join('_')}`}
        >
            <Styled.Option__Image
                src={image}
            />
            <Styled.Option__Content__Holder>
                <Styled.Title__Holder>
                    <Styled.Option__Name>
                        {name}
                    </Styled.Option__Name>
                    <Tooltip
                        content={toolTipPriceWise}
                    >
                        <Styled.Hotel__Rating__Stars__Holder>
                            {Array.from({length: priceWise}).map((_, index: number) => (
                                <Styled.Attraction__Dollar
                                    key={'attraction_star' + name + index}
                                    icon={faDollarSign}
                                />
                            ))}
                        </Styled.Hotel__Rating__Stars__Holder>
                    </Tooltip>
                </Styled.Title__Holder>
                <Styled.Hotel__Location__Holder>
                    <Styled.Hotel__Location__Icon
                        icon={faClock}
                    />
                    <Styled.Hotel__Location>
                        {span}
                    </Styled.Hotel__Location>
                </Styled.Hotel__Location__Holder>
                <Styled.Hotel__Description>
                    {description}
                </Styled.Hotel__Description>
            </Styled.Option__Content__Holder>

        </Styled.Option__Box>
    )
}

export default AttractionBox