import styled from "styled-components";
import Text from "../../../../lib/UI/Text";

const Options__Content__Holder = styled(Text)`
  width: 100%;
  line-height: 25px;
`

export const Styled = {
    Options__Content__Holder
}