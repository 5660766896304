import {Styled} from "../Billing.styles"
import {useTranslation} from "react-i18next";
import {faCaretDown} from "@fortawesome/pro-solid-svg-icons";

const FirstSection = () => {

    const {t} = useTranslation()

    const invoicesList = [
        {
            title: 'Invoice_advertisement_3_may_2024',
            date: '03.05.2024'
        },
        {
            title: 'Invoice_subscription_1_may_2024',
            date: '01.05.2024'
        },
        {
            title: 'pay-in-fund-20-april-2024',
            date: '20.04.2024'
        },
        {
            title: 'pay-in-fund-10-april-2024',
            date: '10.04.2024'
        },
    ]

    const subAccounts = [
        {
            title: t('lbl_advertisement_fund'),
            amount: '5500 $'
        },
        {
            title: 'Personal: May Budget 2024',
            amount: '1200 $'
        },
        {
            title: 'Personal: June Budget 2024',
            amount: '1200 $'
        },
        {
            title: t('lbl_subscription_backup_fund'),
            amount: '500 $'
        },
    ]

    const addMoreFundsClickHandler = () => {

    }

    const addNewSubAccount = () => {


    }

    return (
        <Styled.Billing__First__Section>
            <Styled.Billing__Account__Content__Holder>
                <Styled.Billing__Account__Amount__Status>
                    <Styled.Billing__First__Section__Title>
                        {t('lbl_your_billing_status')}
                    </Styled.Billing__First__Section__Title>
                    <Styled.Billing__Account__Total__Amount__Holder>
                        <Styled.Billing__Account__Amount__Label>
                            {t('lbl_total_balance')}{':'}
                        </Styled.Billing__Account__Amount__Label>
                        <Styled.Billing__Account__Amount>
                            7200 $
                        </Styled.Billing__Account__Amount>
                    </Styled.Billing__Account__Total__Amount__Holder>
                    <Styled.Billing__Account__Sub__Accounts__Holder>
                        {subAccounts.map((account: any) => {
                            const {
                                title,
                                amount
                            } = account
                            return (
                                <Styled.Billing__Sub__Account__Option
                                    key={'lbl_sub_account' + title}
                                >
                                    <Styled.Billing__Sub__Account__Title>
                                        {title}
                                    </Styled.Billing__Sub__Account__Title>
                                    <Styled.Billing__Sub__Account__Amount>
                                        {amount}
                                    </Styled.Billing__Sub__Account__Amount>
                                </Styled.Billing__Sub__Account__Option>
                            )
                        })}
                    </Styled.Billing__Account__Sub__Accounts__Holder>
                    <Styled.Billing__Account__Action__Buttons__Holder>
                        <Styled.Billing__Account__Action__Button
                            onClick={addNewSubAccount}
                            label={t('lbl_add_new_sub_account')}
                        />
                        <Styled.Billing__Account__Action__Button
                            onClick={addMoreFundsClickHandler}
                            label={t('lbl_add_more_funds')}
                        />
                    </Styled.Billing__Account__Action__Buttons__Holder>
                </Styled.Billing__Account__Amount__Status>
                <Styled.Billing__Account__Invoices__Holder>
                    <Styled.Billing__First__Section__Title>
                        {t('lbl_billing_your_invoices')}
                    </Styled.Billing__First__Section__Title>
                    <Styled.Billing__Account__Titles__Holder>
                        <Styled.Billing__Account__Invoice__Name>
                            {t('lbl_invoice_name')}
                            <Styled.List__Titles__Icon
                                icon={faCaretDown}
                            />
                        </Styled.Billing__Account__Invoice__Name>
                        <Styled.Billing__Account__Invoice__Date__Title>
                            {t('lbl_invoice_date')}
                            <Styled.List__Titles__Icon
                                icon={faCaretDown}
                            />
                        </Styled.Billing__Account__Invoice__Date__Title>
                    </Styled.Billing__Account__Titles__Holder>
                    <Styled.Billing__Account__Invoices__List>
                        {invoicesList.map((option: any, index: number) => {

                                const {
                                    title,
                                    date
                                } = option

                                return (
                                    <Styled.Billing__Account__Invoice__Option
                                        key={'invoice_key_' + index}
                                    >
                                        <Styled.Billing__Account__Invoice__Title>
                                            {title}
                                        </Styled.Billing__Account__Invoice__Title>
                                        <Styled.Billing__Account__Invoice__Date>
                                            {date}
                                        </Styled.Billing__Account__Invoice__Date>
                                    </Styled.Billing__Account__Invoice__Option>
                                )
                            }
                        )}
                    </Styled.Billing__Account__Invoices__List>
                    <Styled.Billing__Account__Invoices__Buttons>
                        <Styled.Billing__Account__Action__Button
                            onClick={addNewSubAccount}
                            label={t('lbl_show_more_invoices')}
                        />
                    </Styled.Billing__Account__Invoices__Buttons>
                </Styled.Billing__Account__Invoices__Holder>
            </Styled.Billing__Account__Content__Holder>
        </Styled.Billing__First__Section>
    )
}

export default FirstSection