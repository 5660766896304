import {Styled} from "./MainPage.styles"
import BottomNonPromotableArticles from "./BottomNonPromotableArticles";
import Hotels from "./Hotels";

const NonSeparatedPart = () => {


    return (
        <Styled.Non__Separated__Section__Holder>
            <Hotels/>
            <BottomNonPromotableArticles/>
        </Styled.Non__Separated__Section__Holder>
    )
}

export default NonSeparatedPart