import {Styled} from "../../AdminPage.styles"
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {Admin__Page__Context} from "../../../../lib/Context/ContextList";
import Text from "../../../../lib/UI/Text";
import {statusOptions} from "../../../../lib/Const/AdminOptions";

const WelcomeSection = () => {

    const {t} = useTranslation()

    const [state] = useContext<any>(Admin__Page__Context)

    const {
        userName,
        company,
        accountStatus
    } = state


    return (
        <Styled.Welcome__Section>
            <Styled.Welcome__Title__Holder>
                <Styled.Welcome__Logo
                    src={window.location.origin + '/globe.svg'}
                />
                <Styled.Welcome__Title>
                    {t('lbl_Welcome')}
                </Styled.Welcome__Title>
            </Styled.Welcome__Title__Holder>
            <Styled.Welcome__Employment__Information>
                {t('lbl_you_are_working_in')}
                {' '}
                <span
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    {company}
                </span>
            </Styled.Welcome__Employment__Information>
            <Styled.Welcome__Account__Information>
                <Styled.Welcome__Account__Information__Option>
                    <Text>
                        {t('lbl_logged_in_as')}{':'}
                        <span
                            style={{
                                fontWeight: 'bold',
                                marginLeft: '10px'
                            }}
                        >
                        {userName}
                    </span>
                    </Text>
                </Styled.Welcome__Account__Information__Option>
                <Styled.Welcome__Account__Information__Option>
                    <Text>
                        {t('lbl_account_status')}{':'}
                    </Text>
                    <Styled.Welcome__Account__Status
                        backgroundColor={statusOptions[accountStatus]?.backgroundColor}
                    >
                        {t(`lbl_${accountStatus}`)}
                    </Styled.Welcome__Account__Status>
                </Styled.Welcome__Account__Information__Option>
            </Styled.Welcome__Account__Information>
        </Styled.Welcome__Section>
    )
}

export default WelcomeSection