import {Styled} from "./DiscoverWorld.styles"
import {
    faCactus,
    faChampagneGlass,
    faChildReaching,
    faCity,
    faFarm,
    faFishFins,
    faFlower,
    faGlobeAfrica,
    faGlobeAmericas,
    faGlobeAsia,
    faGlobeEurope,
    faGlobeOceania,
    faHandsHoldingDollar,
    faHeart,
    faLandmark,
    faLeaf,
    faLeafOak,
    faMoneyBill,
    faMoneyBills,
    faMountains,
    faPersonCircleQuestion,
    faPersonHiking,
    faSackDollar,
    faSnowflake,
    faSpa,
    faSun,
    faTemperatureHalf,
    faTemperatureQuarter,
    faTemperatureSnow,
    faTemperatureSun,
    faToriiGate,
    faTrees,
    faVolcano,
    faWater,
    faWheelchair
} from "@fortawesome/pro-light-svg-icons";
import {useTranslation} from "react-i18next";
import {faChevronDown, faChevronUp} from "@fortawesome/pro-solid-svg-icons";
import {whereToTypes} from "./index";
import {includes} from "lodash-es";
import BoxFilters from "../../components/BoxFilters";


type ReadyPanelProps = {
    state: any,
    setState: any
}

const ReadyPanel = ({state, setState}: ReadyPanelProps) => {

    const {t} = useTranslation()

    const onShowMore = () => {
        setState((state: any) => ({
            ...state,
            [whereToTypes.Panel]: {
                ...state[whereToTypes.Panel],
                showMore: !state[whereToTypes.Panel].showMore
            }
        }))
    }

    const onOptionClick = (newOption: string) => {
        setState((state: any) => ({
            ...state,
            [whereToTypes.Panel]: {
                ...state[whereToTypes.Panel],
                selected: includes(state[whereToTypes.Panel].selected, newOption) ? state[whereToTypes.Panel].selected.filter((item: string) => item !== newOption) : [...state[whereToTypes.Panel].selected, newOption]
            }
        }))
    }


    const readyPanelOptions = [
        {
            title: 'Time Of Year',
            priority: true,
            options: [
                {
                    type: 'time_of_year_winter',
                    icon: faSnowflake,
                    color: '#2ce5de',
                },
                {
                    type: 'time_of_year_spring',
                    icon: faFlower,
                    color: '#62ff07'
                },
                {
                    type: 'time_of_year_summer',
                    icon: faSun,
                    color: '#ffdd07',
                },
                {
                    type: 'time_of_year_fall',
                    icon: faLeafOak,
                    color: '#ff6e07',
                }
            ]
        },
        {
            title: 'Climate',
            priority: true,
            options: [
                {
                    type: 'weather_cold',
                    icon: faTemperatureSnow,
                    color: '#00affd',
                },
                {
                    type: 'weather_optimal',
                    icon: faTemperatureQuarter,
                    color: '#ffdf17',
                },
                {
                    type: 'weather_warm',
                    icon: faTemperatureHalf,
                    color: '#ff7100'
                },
                {
                    type: 'weather_hot',
                    icon: faTemperatureSun,
                    color: '#ff2929'
                },
            ]
        },
        {
            title: 'Preferred Environment',
            priority: true,
            options: [
                {
                    type: 'environment_forest',
                    icon: faTrees,
                    color: '#0ab700'
                },
                {
                    type: 'environment_desert',
                    icon: faCactus,
                    color: '#ffb309'
                },
                {
                    type: 'environment_coastal_areas',
                    icon: faWater,
                    color: '#1991bb'
                },
                {
                    type: 'environment_countryside',
                    icon: faFarm,
                    color: '#df5bff'
                },
                {
                    type: 'environment_mountains',
                    icon: faMountains,
                    color: '#74a462'
                },
                {
                    type: 'environment_urban',
                    icon: faCity,
                    color: '#9b9b9b'
                },
                {
                    type: 'environment_volcanic',
                    icon: faVolcano,
                    color: '#ff7400'
                },
                {
                    type: 'environment_coral_reefs',
                    icon: faFishFins,
                    color: '#4affe4'
                },

            ]
        },
        {
            title: 'Travel budget',
            priority: false,
            options: [
                {
                    type: 'budget_low',
                    icon: faHandsHoldingDollar,
                    color: '#c1ff9c'
                },
                {
                    type: 'budget_mid',
                    icon: faMoneyBill,
                    color: '#a2ff6a'
                },
                {
                    type: 'budget_high',
                    icon: faMoneyBills,
                    color: '#7bff2d'
                },
                {
                    type: 'budget_luxury',
                    icon: faSackDollar,
                    color: '#ffdb00'
                },
            ]
        },
        {
            title: 'Continent',
            priority: false,
            options: [
                {
                    type: 'continent_europe',
                    icon: faGlobeEurope,
                    color: '#1991bb'
                },
                {
                    type: 'continent_asia',
                    icon: faGlobeAsia,
                    color: '#1991bb'
                },
                {
                    type: 'continent_america',
                    icon: faGlobeAmericas,
                    color: '#1991bb'
                },
                {
                    type: 'continent_africa',
                    icon: faGlobeAfrica,
                    color: '#1991bb'
                },
                {
                    type: 'continent_oceania',
                    icon: faGlobeOceania,
                    color: '#1991bb'
                },
            ]
        },
        {
            title: 'Activities',
            priority: false,
            options: [
                {
                    type: 'activities_adventure',
                    icon: faPersonHiking,
                    color: '#ff9200'
                },
                {
                    type: 'activities_relax',
                    icon: faSpa,
                    color: '#66ffc0'
                },
                {
                    type: 'activities_culture',
                    icon: faLandmark,
                    color: '#afafaf'
                },
                {
                    type: 'activities_nature',
                    icon: faLeaf,
                    color: '#30ff00'
                },
            ]
        },
        {
            title: 'Other',
            priority: false,
            options: [
                {
                    type: 'other_no_tourist',
                    icon: faPersonCircleQuestion,
                    color: '#30ff00'
                },
                {
                    type: 'other_romantic',
                    icon: faHeart,
                    color: '#30ff00'
                },
                {
                    type: 'other_attraction_for_kids',
                    icon: faChildReaching,
                    color: '#30ff00'
                },
                {
                    type: 'other_accessible_disabled_people',
                    icon: faWheelchair,
                    color: '#30ff00'
                },
                {
                    type: 'other_unesco',
                    icon: faToriiGate,
                    color: '#30ff00'
                },
                {
                    type: 'other_night_life',
                    icon: faChampagneGlass,
                    color: '#30ff00'
                }
            ]
        },
    ]

    return (
        <Styled.Recommended__Destinations__Ready__Panel>
            <BoxFilters
                selectedOptions={state.selected}
                onOptionClick={onOptionClick}
                options={readyPanelOptions}
                showAllOptions={state.showMore}
            />
            <Styled.Ready__Panel__Show__All_Options
                onClick={onShowMore}
            >
                <Styled.Ready__Panel__Show__All__Separator__Line/>
                <Styled.Ready__Panel__Show__All_Separator__Title>
                    <Styled.Ready__Panel__Show__All_Separator__Icon
                        icon={state.showMore ? faChevronUp : faChevronDown}
                    />
                    {state.showMore ? t('lbl_hide_options_panel') : t('lbl_show_all_options_panel')}
                    <Styled.Ready__Panel__Show__All_Separator__Icon
                        icon={state.showMore ? faChevronUp : faChevronDown}
                    />
                </Styled.Ready__Panel__Show__All_Separator__Title>
                <Styled.Ready__Panel__Show__All__Separator__Line/>
            </Styled.Ready__Panel__Show__All_Options>
        </Styled.Recommended__Destinations__Ready__Panel>
    )
}

export default ReadyPanel