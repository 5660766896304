import {Pie, PieChart, ResponsiveContainer} from "recharts";


type ChartPie = {
    data: any[]
}

const ChartPie = (
    {
        data
    }: ChartPie
) => {


    return (
        <ResponsiveContainer
            width={'100%'}
            height={'100%'}
        >
            <PieChart
            >
                <Pie
                    outerRadius={'95%'}
                    animationDuration={0}
                    data={data}
                    strokeWidth={0}
                    dataKey="value"
                    nameKey="name"
                />
            </PieChart>

        </ResponsiveContainer>
    )
}

export default ChartPie