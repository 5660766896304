import {Styled} from "./RegionAttraction.styles"
import PageHolder from "../../lib/UI/PageHolder";
import PageTitle from "../../components/PageTitle";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {attractionOptions} from "../../components/Sections/SectionTypes/RegionMap/RegionMap";
import AttractionBox from "../RegionPage/AttractionBox";
import {attractionOption} from "../../lib/Types";
import React, {useState} from "react";
import Filters from "./Filters";

const RegionAttractionsPage = () => {

    const [attractionsState, setAttractionsState] = useState<any>({
        isOpenFilters: false
    })

    const {countryName, RegionName} = useParams()

    const {t} = useTranslation()

    const regionalAttractionsPage = {
        image: 'https://content.api.news/v3/images/bin/f82dfbbabbe5522db96a14d65e91c2e6'
    }

    const toggleFilters = (event: any) => {
        event.preventDefault()
        setAttractionsState((state: any) => ({
            ...state,
            isOpenFilters: !state.isOpenFilters
        }))

    }

    const onApplyFilters = () => {

    }

    const onClearFilters = () => {


    }

    return (
        <PageHolder
            backgroundImage={regionalAttractionsPage.image}
        >
            <PageTitle
                title={`${t('lbl_attractions')} ${t('lbl_in')} ${RegionName}`}
            />
            <Styled.Filter__Button
                label={attractionsState.isOpenFilters ? t('lbl_close_filters') : t("lbl_open_filters")}
                onClick={toggleFilters}
            />
            <Filters
                onClear={onClearFilters}
                onApply={onApplyFilters}
                isOpen={attractionsState.isOpenFilters}
            />
            <Styled.Region__Options__Content__Holder>
                {attractionOptions.map((option) => (
                    <AttractionBox
                        key={'attraction_page_' + option.name}
                        linkPrefix={window.location.origin + window.location.pathname}
                        option={option as attractionOption}
                    />
                ))}
            </Styled.Region__Options__Content__Holder>
        </PageHolder>
    )
}


export default RegionAttractionsPage