import {Styled} from "./PageHolder.styles";
import React from "react";


type PageHolderProps = {
    children: any
    backgroundImage?: string
}

const PageHolder = (
    {
        children,
        backgroundImage
    }: PageHolderProps) => {

    return (
        <>
            <Styled.Left__Background
                src={backgroundImage}
            />
            <Styled.PageHolder>
                {children}
            </Styled.PageHolder>
            <Styled.Right__Background
                src={backgroundImage}
            />
        </>
    );

}

export default PageHolder