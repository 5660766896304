import {Styled} from "./Navigation.styles"
import {useTranslation} from "react-i18next";
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons";
import useUserConfig from "../../lib/Hooks/useUserConfig";


export const languagesOptions: any = {
    'en': {
        title: 'English',
        flag: 'https://upload.wikimedia.org/wikipedia/commons/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg'
    },
    'it': {
        title: 'Italian',
        flag: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtIEv1Kcge3c61k9yvvt-3OytKAu37r1OwtQ&s'
    },
    'de': {
        title: 'German',
        flag: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRekViuIBXKjk_3oGn1-TyI2AjhEFN6Z7KIIw&s'
    },
    'pl': {
        title: 'Polish',
        flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Flag_of_Poland_%28normative%29.svg/2560px-Flag_of_Poland_%28normative%29.svg.png'
    }
}

type LanguagePickerProps = {
    isDrawer?: boolean
}

const LanguagePicker = (
    {
        isDrawer
    }: LanguagePickerProps
) => {

    const {t} = useTranslation()

    const {selectedLanguage, onLanguageChangeHandler} = useUserConfig()


    return (
        <Styled.Language__Content__Holder>
            <Styled.Language__Picker__Holder
                isDrawer={!!isDrawer}
            >
                {Object.keys(languagesOptions).map((language: string) => {
                    return (
                        <Styled.Language__Option
                            onClick={() => onLanguageChangeHandler(language)}
                        >
                            <Styled.Language__Image
                                src={languagesOptions[language].flag}
                            />
                            <Styled.Language__Title
                                isSelected={selectedLanguage === language}
                            >
                                {languagesOptions[language].title}
                            </Styled.Language__Title>
                        </Styled.Language__Option>

                    )
                })}
            </Styled.Language__Picker__Holder>
            {isDrawer && (
                <Styled.Language__Show__More>
                    {t('lbl_show_more')}
                    <Styled.Language__Icon
                        icon={faChevronDown}
                    />
                </Styled.Language__Show__More>
            )}
        </Styled.Language__Content__Holder>
    )
}

export default LanguagePicker