import {Styled} from "../BecomeSupplier.styles"
import {useTranslation} from "react-i18next";

const HowToBecomeSupplier = () => {

    const {t} = useTranslation()

    const stepsList = [
        'lbl_how_to_become_supplier_create_account',
        'lbl_how_to_become_supplier_validate_account',
        'lbl_how_to_become_supplier_add_products',
        'lbl_how_to_become_supplier_detail_product',
        'lbl_how_to_become_supplier_advertise_product',
        'lbl_how_to_become_supplier_analyze_product',
    ]

    return (
        <Styled.Become__Supplier__Become__Supplier__Holder>
            <Styled.Become__Supplier__Become__Supplier>
                {t('lbl_become_supplier_how_to_become_how')}{':'}
            </Styled.Become__Supplier__Become__Supplier>
            {stepsList.map((option: string, optionIndex: number) => (
                <Styled.Become__Supplier__How__Become__Option>
                    {t(option).split(':').map((item: string, index: number) => index === 0 ?
                        <span style={{fontWeight: 'bold'}}>
                                <span
                                    style={{
                                        marginRight: '20px'
                                    }}>
                                    {optionIndex + 1}{'.'}
                                </span>
                            {item}{':'}
                            </span> : item)}
                </Styled.Become__Supplier__How__Become__Option>
            ))}
        </Styled.Become__Supplier__Become__Supplier__Holder>
    )
}

export default HowToBecomeSupplier
