import styled from "styled-components";
import FlexBox from "../../../../lib/UI/FlexBox";
import Text from "../../../../lib/UI/Text";
import {rgba} from "polished";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Image from "../../../../lib/UI/Image";

const Attractions__Booking__Module__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  padding-bottom: 15px;
`
const Attractions__Title = styled(Text)`
  font-weight: bold;
  font-size: 26px;
`
const Attractions__Duration = styled(Text)`
  font-size: 16px;
  margin-top: 4px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Attractions__Clock__Icon = styled(FontAwesomeIcon)`
  margin-right: 4px;
`
const Attractions__Top__Layer__Content__Holder = styled(FlexBox)`
  flex-direction: initial;
`
const Attractions__Title__Duration__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Attractions__Price__Value = styled(Text)`
  font-size: 26px;
  font-weight: bold;
  margin-top: auto;
  margin-left: auto;
`
const Attractions__Booking__Options__Title = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  padding: 15px 0;
`
const Booking__Options__Holder = styled(FlexBox)`
  flex-direction: initial;
  width: 100%;
  gap: 10px;
`
const Booking__Option = styled.a`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: calc(25vw - 12.5px);
  @media (min-width: 1100px) {
    width: 262.5px;
  }
  @media (max-width: 800px) {
    width: calc(33.33vw - 14px)
  }
  @media (max-width: 650px) {
    width: calc(50vw - 15px)
  }
  @media (max-width: 450px) {
    width: calc(100vw - 20px)
  }
  transition: box-shadow 200ms;

  &:hover {
    box-shadow: ${({theme}) => `0px 0px 16px 0px ${rgba(theme.color, 0.6)}`};
  }
`

const Booking__Option__Image = styled(Image)<{ isGrey: boolean }>`
  width: 100%;
  height: 150px;
  background-color: transparent;
  object-fit: cover;
  border-radius: 2px;
  box-shadow: ${({theme}) => `0px 0px 1px 0px ${rgba(theme.color, 0.6)}`};
`
const Booking__Option__Title = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  text-align: left;
  text-shadow: ${({theme}) => `2px 2px ${rgba(theme.backgroundColor, 0.6)}`};;
`
const Booking__Module__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  height: auto;
`
const Title__Holder = styled(FlexBox)`

`
const Rating__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  margin-left: 5px;
`

const Rating__Star = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  color: ${({theme}) => theme.ratingStarColor};
`
const Hotel__Location = styled(Text)`
  margin-top: 4px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Left__Side__Header__Content__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Header = styled(FlexBox)`

`
const Right__Side__Header__Content__Holder = styled(FlexBox)`
  margin-left: auto;
  flex-direction: column;
`
const Hotel__Price = styled(Text)`

`

export const Styled = {
    Right__Side__Header__Content__Holder,
    Header,
    Left__Side__Header__Content__Holder,
    Hotel__Location,
    Rating__Star,
    Rating__Holder,
    Title__Holder,
    Booking__Module__Holder,
    Booking__Option__Title,
    Booking__Option__Image,
    Booking__Option,
    Booking__Options__Holder,
    Attractions__Booking__Options__Title,
    Attractions__Price__Value,
    Attractions__Title__Duration__Holder,
    Attractions__Top__Layer__Content__Holder,
    Attractions__Clock__Icon,
    Attractions__Duration,
    Attractions__Title,
    Attractions__Booking__Module__Content__Holder
}