import {Styled} from "../Footer.styles"
import {useTranslation} from "react-i18next";

const HoldingInformation = () => {

    const {t} = useTranslation()

    return (
        <Styled.Holding__Holder>
            <Styled.WOR__Logo
                src={window.location.origin + '/wor.svg'}
            />
            <Styled.Part__Of__Label>
                {t('lbl_part_of')}
            </Styled.Part__Of__Label>
        </Styled.Holding__Holder>
    )
}

export default HoldingInformation