import {Styled} from "./HelpCenter.styles"
import {useTranslation} from "react-i18next";
import Select from "../../../lib/UI/SelectBookBrd/Select";
import TextArea from "../../../lib/UI/TextArea";
import CheckBox from "../../../lib/UI/CheckBox";

const SupportCenter = () => {


    const {t} = useTranslation()

    const onSubmitChangeHandler = () => {


    }

    return (
        <Styled.Help__Center__Support>
            <Styled.Support__First__Information>
                {t('lbl_business_support_center')}
            </Styled.Support__First__Information>
            <Styled.Support__Form__Holder>
                <Styled.Support__Report__Type>
                    <Styled.Support__Field__Title>
                        {t('lbl_support_report_type')}
                    </Styled.Support__Field__Title>
                    <Select
                        placeholder={t('lbl_support_report_type')}
                    />
                </Styled.Support__Report__Type>
                <Styled.Support__Report__Message>
                    <Styled.Support__Field__Title>
                        {t('lbl_support_report_message')}
                    </Styled.Support__Field__Title>
                    <TextArea
                        placeholder={t('lbl_support_report_message')}
                    />
                </Styled.Support__Report__Message>
                <Styled.Support__Report__Checkboxes>
                    <CheckBox
                        title={t('lbl_support_report_checkbox')}
                    />
                </Styled.Support__Report__Checkboxes>
                <Styled.Support__Report__Buttons>
                    <Styled.Support__Report__Submit
                        onClick={onSubmitChangeHandler}
                        label={t('lbl_support_submit_report')}
                    />
                </Styled.Support__Report__Buttons>
            </Styled.Support__Form__Holder>
        </Styled.Help__Center__Support>
    )
}

export default SupportCenter