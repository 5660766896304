import {Discover__World, Travel__Tips} from "./Routes";
import DiscoverWorld from "../../../Pages/DiscoverWorld";
import TravelTips from "../../../Pages/TravelTips";

const GeneralPages: any = [
    {
        path: Discover__World,
        element: <DiscoverWorld/>
    },
    {
        path: Travel__Tips,
        element: <TravelTips/>
    }
]

export {
    GeneralPages
}