import {Styled} from "./BookingModule.styles"
import {faStar} from "@fortawesome/pro-solid-svg-icons";
import {useTranslation} from "react-i18next";
import BookingOptions from "./BookingOptions";

type HotelBookingModuleProps = {
    name: string
    rating: number
    location: string
    amount?: number
    currency: string
    bookingOptions: any
}

const HotelBookingModule = (
    {
        name,
        rating,
        location,
        amount,
        currency,
        bookingOptions
    }: HotelBookingModuleProps
) => {

    const {t} = useTranslation()

    return (
        <Styled.Booking__Module__Holder>
            <Styled.Header>
                <Styled.Left__Side__Header__Content__Holder>
                    <Styled.Title__Holder>
                        <Styled.Attractions__Title>
                            {name}
                        </Styled.Attractions__Title>
                        <Styled.Rating__Holder>
                            {Array.from({length: rating}).map((_: any, index: number) => ((
                                    <Styled.Rating__Star
                                        icon={faStar}
                                    />
                                ))
                            )}
                        </Styled.Rating__Holder>
                    </Styled.Title__Holder>
                    <Styled.Hotel__Location>
                        {location}
                    </Styled.Hotel__Location>
                </Styled.Left__Side__Header__Content__Holder>
                <Styled.Right__Side__Header__Content__Holder>
                    <Styled.Attractions__Title>
                        {t('lbl_hotel_average', {amount, currency})}
                    </Styled.Attractions__Title>
                    <Styled.Hotel__Location>
                        {t('lbl_hotel_price_disclaimer')}
                    </Styled.Hotel__Location>
                </Styled.Right__Side__Header__Content__Holder>
            </Styled.Header>
            <Styled.Attractions__Booking__Options__Title>
                {t('lbl_booking_options')}
            </Styled.Attractions__Booking__Options__Title>
            <BookingOptions
                bookingOptions={bookingOptions}
            />
        </Styled.Booking__Module__Holder>
    )
}

export default HotelBookingModule