import {Styled} from "../../Pages/DiscoverWorld/DiscoverWorld.styles";
import {includes} from "lodash-es";
import {faQuestion} from "@fortawesome/pro-light-svg-icons";
import {useTranslation} from "react-i18next";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface BoxFilterOption {
    type: string,
    icon: IconProp,
    color: string
}

interface BoxFilterSection {
    title: string,
    priority: boolean,
    options: BoxFilterOption[]
}

type BoxFiltersProps = {
    showAllOptions?: boolean
    selectedOptions: string[]
    onOptionClick: (selectedOption: string) => void
    options: BoxFilterSection[]
}

const defaultProps = {
    showAllOptions: false
}

const BoxFilters = (
    {
        showAllOptions,
        onOptionClick,
        selectedOptions,
        options
    }: BoxFiltersProps) => {

    const {t} = useTranslation()

    const filterList = (item: any) => {

        if (item?.priority) {
            return true
        } else {
            return showAllOptions
        }
    }

    return (
        <>
            {options.filter((item: any) => filterList(item)).map((section: BoxFilterSection) => (
                <Styled.Ready__Panel__Section>
                    <Styled.Ready__Panel__Section__Title>
                        {section.title}
                    </Styled.Ready__Panel__Section__Title>
                    <Styled.Ready__Panel__Section__Options>
                        {section.options.map((option: BoxFilterOption) => {

                            const isSelected = includes(selectedOptions, option.type)


                            return (
                                <Styled.Ready__Panel__Section__Option
                                    isSelected={isSelected}
                                    customColor={option.color}
                                    onClick={() => onOptionClick(option.type)}
                                >
                                    <Styled.Ready__Panel__Section__Icon
                                        isSelected={isSelected}
                                        customColor={option.color}
                                        icon={option?.icon ?? faQuestion}
                                    />
                                    <Styled.Ready__Panel__Section__Option__Title>
                                        {t('lbl_' + option.type)}
                                    </Styled.Ready__Panel__Section__Option__Title>
                                </Styled.Ready__Panel__Section__Option>
                            )
                        })}
                    </Styled.Ready__Panel__Section__Options>
                </Styled.Ready__Panel__Section>
            ))}
        </>
    )
}

BoxFilters.defaultProps = defaultProps

export default BoxFilters