import {Styled} from "./Billing.styles"
import PageHolder from "../../../lib/UI/PageHolder";
import {faCreditCard} from "@fortawesome/pro-solid-svg-icons";
import {useTranslation} from "react-i18next";
import FirstSection from "./Sections/FirstSection";
import SecondSection from "./Sections/SecondSection";

const Billing = () => {

    const {t} = useTranslation()

    return (
        <PageHolder>
            <Styled.Billing__Holder>
                <Styled.Billing__Header>
                    <Styled.Billing__Title>
                        <Styled.Billing__Icon
                            icon={faCreditCard}
                        />
                        {t('lbl_billing')}
                    </Styled.Billing__Title>
                </Styled.Billing__Header>
                <FirstSection/>
                <SecondSection/>
            </Styled.Billing__Holder>
        </PageHolder>
    )
}

export default Billing