import {useContext} from "react";
import {Main__Page__Context} from "../../lib/Context/ContextList";

const useMainPage = () => {

    const mainPage = useContext<any>(Main__Page__Context)

    return ({
        hotels: mainPage?.hotels,
        advertisementBox: mainPage?.advertisementBox,
        bookBrdAdvertOffers: mainPage?.sideContent?.bookBrdAdvertOffers,
        secondArticles: mainPage?.secondArticles,
        firstArticles: mainPage?.firstArticles,
        grandSlider: mainPage?.grandSlider,
        bottomArticles: mainPage?.bottomArticles
    })
}
export default useMainPage