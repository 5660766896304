import Text from "../../../lib/UI/Text";
import {useTranslation} from "react-i18next";

const ExistingSuppliers = () => {

    const {t} = useTranslation()

    return (
        <Text
            style={{
                lineHeight: '24px',
                fontSize: '16px'
            }}
        >
            {t('lbl_become_supplier_our_clients')}
        </Text>
    )
}

export default ExistingSuppliers