enum countrySections {
    SiteData = "SiteData",
    Description = 'Description',
    TopFive = 'TopFive',
    NerdsData = 'NerdsData',
    TravelRequirements = 'TravelRequirements',
    RegionList = 'RegionList',
    Landscapes = "Landscapes",
    PlanTrip = "PlanTrip",
    Articles = "Articles"
}

enum continentSections {
    SiteData = "SiteData",
    CountryList = "CountryList"
}

const publicConfig = "/Config/PublicSiteConfig"
const mainPageMethod = "/FetchData/MainPage"

const countryConfig: any = {
    [countrySections.SiteData]: {
        route: '/Country/General',
    },
    [countrySections.Description]: {
        route: '/Country/Description'
    },
    [countrySections.RegionList]: {
        route: '/Country/RegionList'
    },
    [countrySections.TopFive]: {
        route: '/Country/TopFive'
    },
    [countrySections.Landscapes]: {
        route: '/Country/Landscapes',
        customTitle: 'lbl_Landscapes'
    },
    [countrySections.PlanTrip]: {
        route: '/General/CountryTravelTips',
    },
    [countrySections.TravelRequirements]: {
        route: '/Country/TravelRequirements'
    },
    [countrySections.NerdsData]: {
        route: '/Country/NerdsData'
    },
    [countrySections.Articles]: {
        route: '/Articles/CountryArticles'
    },
}
const continentConfig = {
    [continentSections.CountryList]: {
        route: "/Continent/CountryList"
    },
    [continentSections.SiteData]: {
        route: "/Continent/General"
    }
}

export {
    publicConfig,
    countryConfig,
    continentConfig,
    mainPageMethod,
    countrySections,
    continentSections,

}