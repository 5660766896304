import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Text from "../../lib/UI/Text";
import {rgba} from "polished";
import Image from "../../lib/UI/Image";

const Footer__Holder = styled(FlexBox)`
  width: 100%;
  flex-direction: column;
  border-top: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
`
const Site__Map__Holder = styled(FlexBox)`
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
`
const Footer__Map__Site__Title = styled(Text)`
  text-align: center;
  margin: 20px auto;
  font-weight: bold;
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`
const Links__Holder = styled(FlexBox)`
  width: calc(100% - 20px);
  padding: 15px 10px;
  gap: 20px 0;
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
`
const Links__Section = styled(FlexBox)`
  flex-direction: column;
  width: calc(100% / 4);
  @media (max-width: 900px) {
    width: calc(100% / 2);
  }
  @media (max-width: 450px) {
    width: calc(100% / 1);
    text-align: center;
  }
`
const Links__Section__Title = styled(Text)`
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 8px;
`
const Link = styled.a`
  color: ${({theme}) => theme.color};
  margin-bottom: 3px;
  cursor: pointer;
  text-decoration: none;
  font-size: 17px;
  max-width: max-content;

  &:hover {
    text-decoration: underline;
  }
`
const Map__Site__Map = styled(FlexBox)`
  gap: 10px;

`
const Map__Site__Route = styled(Text)`
  font-size: 18px;
`
const Holding__Holder = styled(FlexBox)`
  padding: 10px 0;
  margin: 0 auto;
`
const WOR__Logo = styled(Image)`
  background-color: transparent;
  height: 60px;
`
const Part__Of__Label = styled(Text)`
  margin: auto 5px;
`


export const Styled = {
    Part__Of__Label,
    WOR__Logo,
    Holding__Holder,
    Map__Site__Route,
    Map__Site__Map,
    Link,
    Links__Section__Title,
    Links__Section,
    Links__Holder,
    Footer__Map__Site__Title,
    Site__Map__Holder,
    Footer__Holder
}