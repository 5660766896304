import {Styled} from "./BookingModule.styles";

type BookingOptionsProps = {
    bookingOptions: any[]
}

const BookingOptions = (
    {
        bookingOptions
    }: BookingOptionsProps
) => {

    return (
        <Styled.Booking__Options__Holder>
            {bookingOptions && bookingOptions?.map((option: any, index: number) => (
                <Styled.Booking__Option
                    key={'booking_option' + index + option.name}
                >
                    <Styled.Booking__Option__Image
                        isGrey={!!option.name}
                        src={option?.image}
                    />
                    {option?.name && (
                        <Styled.Booking__Option__Title>
                            {option.name}
                        </Styled.Booking__Option__Title>
                    )}
                </Styled.Booking__Option>
            ))}
        </Styled.Booking__Options__Holder>
    )

}
export default BookingOptions