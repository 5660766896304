import {Styled} from "../BecomeSupplier.styles"
import TextInput from "../../../lib/UI/TextInput";
import {useTranslation} from "react-i18next";
import TextArea from "../../../lib/UI/TextArea";
import CheckBox from "../../../lib/UI/CheckBox";

const QuestionsForm = () => {

    const {t} = useTranslation()

    return (
        <Styled.Become__Supplier__Question__Form__Holder>
            <Styled.Question__Form__Title>
                {t('lbl_become_supplier_question_title')}
            </Styled.Question__Form__Title>
            <Styled.Question__Form__Text__Input>
                <TextInput
                    placeholder={t('lbl_email_placeholder')}
                    showIcon={false}
                />
            </Styled.Question__Form__Text__Input>
            <Styled.Question__Form__Text__Input>
                <TextInput
                    placeholder={t('lbl_how_should_we_call_you_placeholder')}
                    showIcon={false}
                />
            </Styled.Question__Form__Text__Input>
            <Styled.Question__Form__Text__Area>
                <TextArea
                    placeholder={t('lbl_become_supplier_question')}
                />
            </Styled.Question__Form__Text__Area>
            <CheckBox
                title={t('lbl_support_report_checkbox')}
            />
            <Styled.Question__Form__Submit__Button
                label={t('lbl_submit_question')}
            />
        </Styled.Become__Supplier__Question__Form__Holder>
    )
}
export default QuestionsForm