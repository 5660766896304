import {Styled} from "./HelpCenter.styles"
import PageHolder from "../../../lib/UI/PageHolder";
import {useTranslation} from "react-i18next";
import {faCircleInfo, faCloud, faMessagesQuestion} from "@fortawesome/pro-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {
    Admin__Help__Center__Online__Guide,
    Admin__Help__Center__Support__Contact
} from "../../../lib/Const/Routes/Routes";
import OnlineGuide from "./OnlineGuide";
import SupportCenter from "./SupportCenter";

export enum HelpCenterType {
    default = 'default',
    onlineGuide = 'guide',
    contactSupport = 'support'
}

type HelpCenterProps = {
    type: HelpCenterType
}

const HelpCenter = (
    {
        type
    }: HelpCenterProps
) => {

    const navigate = useNavigate()

    const {t} = useTranslation()


    if (type === HelpCenterType.onlineGuide) {
        return (
            <PageHolder>
                <Styled.Contact__Title>
                    <Styled.Contact__Icon
                        icon={faCloud}
                    />
                    {t('lbl_admin_online_guide')}
                </Styled.Contact__Title>
                <OnlineGuide/>
            </PageHolder>
        )
    }

    if (type === HelpCenterType.contactSupport) {
        return (
            <PageHolder>
                <Styled.Contact__Title>
                    <Styled.Contact__Icon
                        icon={faMessagesQuestion}
                    />
                    {t('lbl_admin_support_center')}
                </Styled.Contact__Title>
                <SupportCenter/>
            </PageHolder>
        )
    }


    return (
        <PageHolder>
            <Styled.Contact__Title>
                <Styled.Contact__Icon
                    icon={faCircleInfo}
                />
                Help Center
            </Styled.Contact__Title>
            <Styled.Contact__Page__Main__Holder>
                <Styled.Contact__Page__Main__Half__Option
                    onClick={() => navigate(Admin__Help__Center__Online__Guide)}
                >
                    <Styled.Contact__Page__Option__Icon
                        icon={faCloud}
                    />
                    <Styled.Contact__Page__Option__Title>
                        {t('lbl_admin_online_guide')}
                    </Styled.Contact__Page__Option__Title>
                </Styled.Contact__Page__Main__Half__Option>
                <Styled.Contact__Page__Main__Half__Option
                    onClick={() => navigate(Admin__Help__Center__Support__Contact)}
                >
                    <Styled.Contact__Page__Option__Icon
                        icon={faMessagesQuestion}
                    />
                    <Styled.Contact__Page__Option__Title>
                        {t('lbl_admin_support_center')}
                    </Styled.Contact__Page__Option__Title>
                </Styled.Contact__Page__Main__Half__Option>
            </Styled.Contact__Page__Main__Holder>
        </PageHolder>
    )
}
export default HelpCenter