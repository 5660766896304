//Fixed Routes

const Main__First__Page = '/'
const Discover__World__No__Param = `/Discover/Where-To-Go`
const Discover__World = `${Discover__World__No__Param}/:type`
const Travel__Tips__No__Param = `/Discover/Travel-Tips`
const Travel__Tips = `${Travel__Tips__No__Param}/:type`
const All__Articles = '/Discover/All-Articles'

// Changing Routes
const Continent__Page = `/:continent`
const Country__Page = `${Continent__Page}/:countryData`
const Region__Page = `${Country__Page}/:RegionName`

// Region Attractions
const Region__Attractions = `${Region__Page}/Attractions`
const Region__Particular__Attractions = `${Region__Attractions}/:AttractionName`

// Region Hotels
const Region__Hotels = `${Region__Page}/Hotels`
const Region__Particular__Hotel = `${Region__Hotels}/:HotelName`

// Region Articles
const Region__Articles = `${Region__Page}/Articles`
const Region__Particular__Article = `${Region__Articles}/:ArticleName`


// Country Articles
const Country__Articles = `${Country__Page}/Articles`
const Country__Particular__Article = `${Country__Articles}/:ArticleName`


//Business
const Business__Become__Supplier = `/Business/Become-Supplier`
const Business_FAQ = `Business/FAQ`

// Admin
const Admin__Page = '/Business/AdminPanel'
const Admin__My__Products = `${Admin__Page}/My-Products`
const Admin__Analytics = `${Admin__Page}/Analytics`
const Admin__Billing = `${Admin__Page}/Billing`
const Admin__Advertisement = `${Admin__Page}/Advertisement`
const Admin__Help__Center = `${Admin__Page}/Help-Center`
const Admin__Help__Center__Online__Guide = `${Admin__Help__Center}/Online-Guide`
const Admin__Help__Center__Support__Contact = `${Admin__Help__Center}/Support-Center`
const Admin__Announcements = `${Admin__Page}/Announcements`
const Admin__Settings = `${Admin__Page}/Settings`

export {
    Travel__Tips__No__Param,
    All__Articles,
    Travel__Tips,
    Discover__World__No__Param,
    Business_FAQ,
    Business__Become__Supplier,
    Admin__Settings,
    Admin__Help__Center__Support__Contact,
    Admin__Announcements,
    Admin__Help__Center__Online__Guide,
    Admin__Help__Center,
    Admin__Advertisement,
    Admin__Billing,
    Admin__Analytics,
    Admin__Page,
    Admin__My__Products,
    Region__Articles,
    Region__Particular__Hotel,
    Region__Attractions,
    Region__Hotels,
    Region__Particular__Attractions,
    Country__Articles,
    Country__Particular__Article,
    Discover__World,
    Main__First__Page,
    Continent__Page,
    Country__Page,
    Region__Page
}