import useCustomEventListener from "../../lib/Hooks/useCustomEventListener";
import {useState} from "react";
import {Styled} from "./PageLoader.styles";
import {AppConfigStorage} from "../../lib/Context/Providers/AppConfigProvider";

export const PAGE_LOADER_EVENT = 'Page_Loading'

export enum Page__Loader__options {
    end = "end",
    start = "start",
    off = "off"
}

const getDefaultState = (): Page__Loader__options => {

    const configStorage = JSON.parse(sessionStorage.getItem(AppConfigStorage) ?? '{}')

    if (configStorage?.publicSiteConfig?.isPublicConfigLoaded) {
        return Page__Loader__options.off
    }

    return Page__Loader__options.start
}

const PageLoader = () => {

    const [loaderState, setLoaderState] = useState<Page__Loader__options>(getDefaultState())

    useCustomEventListener(PAGE_LOADER_EVENT, (event: any) => {
        setLoaderState(event?.detail?.type)
        if (event?.detail?.type == Page__Loader__options.end) {
            setTimeout(() => {
                setLoaderState(Page__Loader__options.off)
            }, 2000)
        }
    })

    return loaderState !== Page__Loader__options.off ? (
        <Styled.Page__Holder
            animationtype={loaderState}
        >
            <Styled.Loader__WOR__Logo
                animationtype={loaderState}
                src={window.location.origin + '/wor.svg'}
            />

        </Styled.Page__Holder>
    ) : null
}

export default PageLoader