import {Styled} from "../BecomeSupplier.styles"
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const JoinUsNow = () => {

    const {t} = useTranslation()

    const navigate = useNavigate()

    const onJoinClickHandler = () => {
        navigate('/')
    }

    return (
        <Styled.Become__Supplier__Join__Now__Holder>
            <Styled.Become__Supplier__Join__Now__Title>
                {t('lbl_become_supplier_join_now_title')}
            </Styled.Become__Supplier__Join__Now__Title>
            <Styled.Become__Supplier__Join__Now__Button
                label={t('lbl_become_supplier_join_now')}
                onClick={onJoinClickHandler}
            />
        </Styled.Become__Supplier__Join__Now__Holder>
    )
}

export default JoinUsNow