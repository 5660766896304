import styled from "styled-components";
import Text from "../../lib/UI/Text";
import FlexBox from "../../lib/UI/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Business__FAQ__Title = styled(Text)`
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 30px;
  font-size: 30px;
  text-align: center;
  font-weight: bold
`
const Business__FAQ__Description = styled(Text)`
  margin: 0 auto;
  font-size: 19px;
  text-align: center;
`
const Business__FAQ__Questions__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-top: 40px;
`
const Business__FAQ__Option = styled(FlexBox)`
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 10px;
  margin-top: 5px;
  border-bottom: 1px solid ${({theme}) => theme.color};
`
const Business__FAQ__Question__Label = styled(Text)`
  font-size: 20px;
  user-select: none;
  font-weight: bold;
`
const Business__FAQ__Top__Part = styled(FlexBox)`
  cursor: pointer;

`
const Business__FAQ__Option__Icon = styled(FontAwesomeIcon)`
  height: 22px;
  width: 22px;
  margin-left: auto;
  color: ${({theme}) => theme.color};
`
const Business__FAQ__Answer__Holder = styled(FlexBox)<{ isOpen: boolean }>`
  margin-top: ${({isOpen}) => isOpen ? '10px' : 'unset'};
  padding-top: ${({isOpen}) => isOpen ? '20px' : 'unset'};
  padding-bottom: ${({isOpen}) => isOpen ? '10px' : 'unset'};
  transition: height 200ms, padding-bottom 200ms, padding-top 200ms;
  overflow: hidden;
  height: ${({isOpen}) => isOpen ? 'auto' : '0px'};
  flex-direction: column;
`
const Business__FAQ__Answer = styled(Text)`
  font-size: 18px;
  line-height: 24px;
`

export const Styled = {
    Business__FAQ__Answer,
    Business__FAQ__Answer__Holder,
    Business__FAQ__Option__Icon,
    Business__FAQ__Top__Part,
    Business__FAQ__Question__Label,
    Business__FAQ__Option,
    Business__FAQ__Questions__Holder,
    Business__FAQ__Description,
    Business__FAQ__Title
}