import React from "react";
import {AdminPageContext, MainPageContext} from "./DefaultContextValues";


const Main__Page__Context = React.createContext(MainPageContext)
const Admin__Page__Context = React.createContext(AdminPageContext)


export {
    Main__Page__Context,
    Admin__Page__Context
}


export type ContextProviderProps = {
    children: React.ReactElement
}