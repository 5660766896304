import {
    faGlobeAfrica,
    faGlobeAmericas,
    faGlobeAsia,
    faGlobeEurope,
    faGlobeOceania,
    faLightbulb,
    faMagnifyingGlass,
    faNewspaper
} from "@fortawesome/pro-light-svg-icons";
import {useNavigate} from "react-router-dom";
import {Admin__Advertisement, Admin__Billing, Admin__Page} from "../Const/Routes/Routes";
import {typeChange} from "../../components/Navigation/SeasonLanguageModal";
import useSessionPageConfig from "./useSessionPageConfig";


export const navigationOptionsIconsList: any = {
    continent_EU: faGlobeEurope,
    continent_AS: faGlobeAsia,
    continent_AF: faGlobeAfrica,
    continent_OC: faGlobeOceania,
    continent_AM: faGlobeAmericas,
    discover_whereToGo: faMagnifyingGlass,
    discover_travelTips: faLightbulb,
    discover_lastArticles: faNewspaper

}

const useNavigation = () => {

    const {publicSiteConfig, setState, state} = useSessionPageConfig()

    const navigate = useNavigate()

    const toggleNavigationDrawer = (bool: boolean) => {
        setState((state: any) => ({
            ...state,
            isOpenDrawer: bool
        }))
    }

    const toggleChangeSeason = (type: typeChange | undefined) => {
        setState((state: any) => ({
            ...state,
            isOpenWideScreenDrawer: type
        }))
    }


    const businessNavigationOptions: any = [
        {
            label: 'Main Panel',
            onClick: () => navigate(Admin__Page)
        },
        {
            label: 'Italy Page',
            onClick: () => navigate(Admin__Page)

        },
        {
            label: 'Advertisement',
            onClick: () => navigate(Admin__Advertisement)

        },
        {
            label: 'Billing',
            onClick: () => navigate(Admin__Billing)
        }
    ]

    return ({
        toggleNavigationDrawer,
        toggleChangeSeason,
        navigationOptions: publicSiteConfig?.navigationOptions,
        businessNavigationOptions,
        isOpenDrawer: state.isOpenDrawer,
        isOpenWideScreenDrawer: state?.isOpenWideScreenDrawer
    })
}
export default useNavigation