import {Styled} from "./TravelTips.styles"

type CountryOptionProps = {
    link: string
    countryImage: string,
    countryFlag: string,
    countryName: string
}

const CountryOption = (
    {
        link,
        countryImage,
        countryFlag,
        countryName
    }: CountryOptionProps) => {

    return (
        <Styled.Travel__Tips__Country__Option
            href={link ?? ''}
        >
            <Styled.Travel__Tips__Country__Option__Image__Holder>
                <Styled.Travel__Tips__Country__Image
                    src={countryImage}
                />
                <Styled.Travel__Tips__Country__Flag
                    src={countryFlag}
                />
            </Styled.Travel__Tips__Country__Option__Image__Holder>
            <Styled.Travel__Tips__Country__Title>
                {countryName}
            </Styled.Travel__Tips__Country__Title>
        </Styled.Travel__Tips__Country__Option>
    )
}

export default CountryOption