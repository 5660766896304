import {Styled} from "./HelpCenter.styles"

const OnlineGuide = () => {

    const sections = [
        {
            title: "Admin",
            list: [
                {
                    title: 'Adding user to organization'
                },
                {
                    title: 'Changing employees permissions'
                },
                {
                    title: 'How to add new product'
                },
                {
                    title: 'How to transfer product'
                },
            ]
        },
        {
            title: "Advertisement",
            list: [
                {
                    title: 'First Advertisement'
                },
                {
                    title: 'Tracking my previous Advertisement'
                },
                {
                    title: 'How to plan my Advertisement'
                },
            ]
        },
        {
            title: 'Billing',
            list: [
                {
                    title: 'How to Add funds'
                },
                {
                    title: 'How to get Invoice'
                },
                {
                    title: 'How to Change Payment Method'
                },
                {
                    title: 'How to create Budgets'
                },
                {
                    title: 'Track your spending\'s'
                },
            ]
        }

    ]

    return (
        <Styled.Online__Guide__Holder>
            {sections.map((option: any) => {

                return (
                    <Styled.Online__Guide__Section>
                        <Styled.Online__Guide__Section__Title>
                            {option.title}
                        </Styled.Online__Guide__Section__Title>
                        <Styled.Online__Guide__Section__Videos__Holder>
                            {option?.list?.map((video: any) => {

                                return (
                                    <Styled.Online__Guide__Video__Option__Holder>
                                        <Styled.Online__Guide__Video__Thumb__Image
                                            src={video?.thumb}
                                        />
                                        <Styled.Online__Guide__Video__Title>
                                            {video?.title}
                                        </Styled.Online__Guide__Video__Title>
                                    </Styled.Online__Guide__Video__Option__Holder>
                                )
                            })}
                        </Styled.Online__Guide__Section__Videos__Holder>
                    </Styled.Online__Guide__Section>
                )
            })}
        </Styled.Online__Guide__Holder>
    )
}

export default OnlineGuide