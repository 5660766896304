import {ContextProviderProps} from "../ContextList";
import useStorage from "../../Hooks/useStorage";
import {useEffect} from "react";
import FlexBox from "../../UI/FlexBox";
import {promiseRequest} from "../../Requests/RequestBuilder";
import {WOR_CONTENT_SERVICE_BASE_URL} from "../../Requests/requestsSetup";
import {publicConfig} from "../../Requests/RequestConfigs";
import {useDebouncedCallback} from "use-debounce";
import moment from "moment";
import {AppConfig} from "../../Types/publicPageConfig";
import {Page__Loader__options, PAGE_LOADER_EVENT} from "../../../components/PageLoader";

export const AppConfigStorage = 'session_page_config'


export const AppConfigDefaultState: AppConfig = {
    publicSiteConfig: {
        isPublicConfigLoaded: false,
        isLoading: true
    }
}

const isFetchConfig = (isDebug: boolean, lastUpdate: any, currentTime: any) => {

    if (isDebug) {
        return true
    }

    return moment(lastUpdate).diff(moment(), 'hours') >= 2
}


const AppConfigProvider = (
    {
        children
    }: ContextProviderProps) => {


    const [state, setState] = useStorage<AppConfig>('sessionStorage', AppConfigStorage, AppConfigDefaultState)


    const isDebug = true

    const fetchPublicConfig = async () => {
        try {
            const startLoader = new CustomEvent(PAGE_LOADER_EVENT, {
                detail: {
                    type: Page__Loader__options.start,
                },
            });
            window.dispatchEvent(startLoader);
            const query: any = {
                language: 'en',
            };
            const result = await promiseRequest(`${WOR_CONTENT_SERVICE_BASE_URL}${publicConfig}?query=${JSON.stringify(query)}`)
            setState((state: any) => ({
                ...state,
                publicSiteConfig: {
                    ...state.publicSiteConfig,
                    ...result,
                    isLoading: false,
                    changeTime: moment(),
                    isPublicConfigLoaded: true
                }
            }))
        } catch (e) {
            console.log({e, test: `${WOR_CONTENT_SERVICE_BASE_URL}${publicConfig}`})
        } finally {
            const endLoader = new CustomEvent(PAGE_LOADER_EVENT, {
                detail: {
                    type: Page__Loader__options.end
                    ,
                },
            });
            window.dispatchEvent(endLoader);
        }
    }
    const debouncedFetch = useDebouncedCallback(() => {
        fetchPublicConfig()
    }, 50)


    useEffect(() => {
        if (isFetchConfig(isDebug, state?.publicSiteConfig?.changeTime, moment())) {
            debouncedFetch()
        }
    }, [])

    return (
        <FlexBox>
            {children}
        </FlexBox>
    )
}

export default AppConfigProvider