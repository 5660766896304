import styled from "styled-components";
import Image from "../Image";

const WOR__Loading__Logo = styled(Image)`
  @keyframes opacityChange {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }

  background-color: transparent;
  object-fit: contain;
  min-width: 200px;
  user-select: none;
  animation: 2s linear infinite opacityChange;

`

export const Styled = {
    WOR__Loading__Logo
}