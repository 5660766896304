import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import {useContext} from "react";
import {ThemeContext} from "styled-components";

type ChartLineProps = {
    optionsConfig: any
    hideLegend?: boolean
    data: any[]
}

const ChartLine = (
    {
        data,
        optionsConfig,
        hideLegend,
    }: ChartLineProps
) => {

    const {color, backgroundColor} = useContext<any>(ThemeContext)

    return (
        <ResponsiveContainer
            height={"100%"}
            minWidth={'100%'}
            minHeight={'100%'}
            width={'100%'}>
            <LineChart
                data={data}
            >
                <CartesianGrid
                    vertical={false}
                    stroke={'rgba(255,255,255,0.2)'}
                />
                <XAxis
                    stroke={color}
                    dataKey="name"
                />
                <YAxis
                    scale={'auto'}
                    orientation={'right'}
                    stroke={color}
                    tickLine={false}
                    axisLine={false}
                    color={'white'}
                />
                {!hideLegend && (
                    <Legend
                        align={"left"}
                    />
                )}
                <Tooltip
                    contentStyle={{
                        borderRadius: '5px',
                        border: 'none',
                        boxShadow: `0px 0px 2px 0px ${color}`,
                        backgroundColor: backgroundColor,

                    }}
                    labelStyle={{
                        color: color,
                    }}
                />
                {Object.keys(optionsConfig).map((name: string) => {

                    const {
                        color,
                        type,
                        width
                    } = optionsConfig[name]


                    return (
                        <Line
                            strokeDasharray={type === 'scatter' ? '4 4' : ''}
                            animationDuration={200}
                            type={"linear"}
                            dataKey={name}
                            dot={false}
                            strokeWidth={width ?? 3}
                            stroke={color}
                        />
                    )
                })}

            </LineChart>
        </ResponsiveContainer>
    )
}

export default ChartLine