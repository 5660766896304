import FlexBox from "../../lib/UI/FlexBox";
import Image from "../../lib/UI/Image";
import React from "react";


type logoProps = {
    onClick?: () => void,
    style?: React.CSSProperties,
}

const WorldOfRegionsLogo = (
    {
        onClick,
        style
    }: logoProps
) => {

    return (
        <FlexBox
            onClick={onClick}
            style={{
                height: '50px',
                width: '135px',
                flexWrap: 'nowrap',
                ...style
            }}
        >
            <Image
                src={window.location.origin + '/wor.svg'}
                style={{
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    objectFit: 'cover',
                    width: '135px',
                    height: '50px',
                    ...style,
                }}
            />
        </FlexBox>
    )
}
export default WorldOfRegionsLogo