import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import Image from "../../../lib/UI/Image";
import Text from "../../../lib/UI/Text";
import {rgba} from "polished";

const Article__Title = styled.h2`
  color: ${({theme}) => theme.color};
  font-size: 26px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  transition: text-decoration 200ms;
`
const Article__Box = styled.a`
  flex-wrap: nowrap;
  position: relative;
  display: flex;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
  transition: 200ms background-color;

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.05)};
  }

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 0 ;
    padding-bottom: 10px;
  }
`


const Article__Description = styled(Text)`
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 1em;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 18px;
  -webkit-box-orient: vertical;
  font-size: 18px;
  color: ${({theme}) => rgba(theme.color, 0.6)};

  @media (max-width: 600px) {
    margin-top: 15px;
  }
`
const Article__Image = styled(Image)`
  flex-shrink: 0;
  width: 33vw;
  height: 25vw;
  border-radius: 5px;
  object-fit: cover;
  @media (max-width: 800px) {
    width: 43vw;
    height: 33vw;
  }
  @media (max-width: 600px) {
    width: calc(100vw - 20px);
    height: calc(80vw);
  }
  @media (min-width: 1100px) {
    width: calc(1100px / 3);
    height: 275px;
  }
`
const Article__Content__Holder = styled(FlexBox)`
  flex-direction: column-reverse;
  flex-flow: column-reverse;
  margin-left: 10px;
  @media (max-width: 600px) {
    margin-left: 5px;
    margin-top: 15px;
  }
`
const Article__Content = styled.h4`
  margin: 0;
  font-weight: normal;
  padding: 0;
  color: ${({theme}) => theme.color};
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: calc(8em + 32px);
  -webkit-line-clamp: 8;
  overflow: hidden;
  line-height: 22px;
  -webkit-box-orient: vertical;
  font-size: 18px;
`
const Article__Date = styled(Text)`
  margin-top: 4px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Article__Addition__Time = styled(Text)`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: ${({theme}) => rgba(theme.color, 0.1)};
  padding: 4px 8px;
  border-radius: 15px;
  font-size: 14px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
  @media (max-width: 600px) {
    color: ${({theme}) => rgba(theme.color, 0.8)};
    background-color: ${({theme}) => rgba(theme.backgroundColor, 0.4)};
  }
`

export const Styled = {
    Article__Addition__Time,
    Article__Description,
    Article__Date,
    Article__Content,
    Article__Title,
    Article__Content__Holder,
    Article__Box,
    Article__Image
}