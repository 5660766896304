import {Styled} from "./PageTitle.styles"
import {faStar} from "@fortawesome/pro-solid-svg-icons";
import SkeletonBox from "../../lib/UI/SkeletonBox/SkeletonBox";

type PageTitleProps = {
    title: string
    flag?: string
    rating?: number
    isLoading?: boolean
}

const PageTitle = (
    {
        title,
        flag,
        rating,
        isLoading
    }: PageTitleProps
) => {

    return (
        <Styled.Page__Title>
            {isLoading ? (
                <Styled.Page__Title__Skeleton__Holder>
                    <SkeletonBox
                        style={{
                            width: '100%',
                            height: '100%',
                            maxWidth: 'unset',
                            maxHeight: 'unset'
                        }}
                    />
                </Styled.Page__Title__Skeleton__Holder>
            ) : (
                <>
                    {title}
                </>
            )}
            {flag && (
                <Styled.Page__Title__Flag
                    forceLoader={isLoading}
                    src={flag}
                />
            )}
            {rating && (
                <>
                    <Styled.Rating__Holder>
                        {Array.from({length: rating}).map((_: any, index: number) => (
                            <Styled.Rating__Star
                                icon={faStar}
                            />
                        ))}
                    </Styled.Rating__Holder>
                </>
            )}
        </Styled.Page__Title>
    )
}
export default PageTitle