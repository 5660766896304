import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import Text from "../../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {rgba} from "polished";
import Button from "../../../lib/UI/Button";

const Billing__Holder = styled(FlexBox)`
  flex-direction: column;
  padding-bottom: 20px;
`
const Billing__Header = styled(FlexBox)`
  width: calc(100% - 20px);
  padding: 0 10px;
  gap: 20px 10px;
  margin-top: 40px;
`
const Billing__Title = styled(Text)`
  font-size: 26px;
  font-weight: bold;
`
const Billing__Icon = styled(FontAwesomeIcon)`
  margin-right: 15px;
  height: 30px;
  width: 30px;
`
const Billing__First__Section = styled(FlexBox)`
  margin: 0 10px;
  margin-top: 20px;
  flex-direction: column;
  flex-wrap: nowrap;
  box-sizing: border-box;
  border-radius: 5px;
  width: calc(100% - 20px);
`
const Billing__First__Section__Title = styled(Text)`
  font-weight: bold;
`
const Billing__Account__Amount__Status = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
  flex-grow: 1;
  padding-right: 20px;
  min-width: 350px;
  border-right: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
  @media (max-width: 800px) {
    min-width: 350px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.color};
  }
  @media (max-width: 390px) {
    min-width: unset;
  }
`

const Billing__Account__Content__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  height: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`
const Billing__Account__Amount = styled(Text)`
  font-size: 26px;
  text-align: right;
  margin-top: auto;
  font-weight: bold;
`
const Billing__Account__Total__Amount__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  padding: 15px 0;
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
`
const Billing__Account__Amount__Label = styled(Text)`
  margin-right: auto;
  margin-top: auto;
  font-size: 20px;
`
const Billing__Account__Invoices__Holder = styled(FlexBox)`
  flex-direction: column;
  max-width: 450px;
  overflow: hidden;
  flex-grow: 1;
  padding: 0 10px;
  @media (max-width: 800px) {
    margin-top: 20px;
    min-width: 350px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.color};
  }
  @media (max-width: 390px) {
    min-width: unset;
  }
`
const Billing__Account__Invoices__List = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 100%;
  max-height: 589px;
  overflow: auto;
`
const Billing__Account__Sub__Accounts__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  max-height: 589px;
  overflow: auto;
  overflow-x: hidden;
`
const Billing__Sub__Account__Option = styled(FlexBox)`
  flex-wrap: nowrap;
  padding: 15px 0;
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.3)};

  &:last-of-type {
    @media (max-width: 800px) {
      border-bottom: none;
    }
  }
`
const Billing__Sub__Account__Title = styled(Text)`
  margin-right: auto;
  color: ${({theme}) => rgba(theme.color, 0.8)};
`
const Billing__Sub__Account__Amount = styled(Text)`
  font-weight: bold;
  color: ${({theme}) => rgba(theme.color, 0.8)};
`
const Billing__Account__Invoice__Option = styled(FlexBox)`
  flex-wrap: nowrap;
  padding: 15px 10px;
  max-width: 100%;
  overflow: hidden;
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.3)};
`
const Billing__Account__Invoice__Title = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: available;
  margin-right: auto;
  padding-right: 10px;
  flex-shrink: 1;
  text-overflow: ellipsis;
`
const Billing__Account__Invoice__Date = styled(Text)`
`
const List__Titles__Icon = styled(FontAwesomeIcon)`
  margin-left: 4px;
`
const Billing__Account__Titles__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
  padding: 10px;
  margin-top: 22px;
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
`
const Billing__Account__Invoice__Name = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => rgba(theme.color, 0.6)};
  margin-right: auto;
`
const Billing__Account__Invoice__Date__Title = styled(Text)`
  margin-right: 20px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Billing__Account__Action__Buttons__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  gap: 10px;
  padding: 10px 0;
  margin-top: 10px;
  align-content: flex-end;
  justify-items: flex-end;
  justify-self: flex-end;
  justify-content: flex-end;
`
const Billing__Account__Action__Button = styled(Button)`
  min-width: 125px;
  padding: 0 10px;
`
const Billing__Account__Invoices__Buttons = styled(FlexBox)`
  padding: 10px 0;
  margin-top: 10px;
  width: 100%;
  justify-content: flex-end;
`
const Second__Section = styled(FlexBox)`
  margin: 0 10px;
  margin-top: 20px;
  width: calc(100% - 20px);
`
const Billing__Last__Transactions__Holder = styled(FlexBox)`
  flex-grow: 1;
  overflow: hidden;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 700px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.color};
`
const Last__Transaction__Title = styled(Text)`
  font-weight: bold;
`

const Billing__last__Transactions__Titles = styled(FlexBox)`
  padding: 10px;
  margin-top: 20px;
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
`
const Billing__Last__Transaction__Name__Title = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => rgba(theme.color, 0.6)};
  margin-right: auto;
`
const Billing__Last__Transaction__Name__Amount = styled(Text)`
  color: ${({theme}) => rgba(theme.color, 0.6)};
  overflow: hidden;
  text-overflow: ellipsis;
`
const Billing__Last__Transactions__List__Holder = styled(FlexBox)`
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
  flex-wrap: nowrap;
`

const Billing__Last__Transaction__Hover__Options__Holder = styled(FlexBox)`
  position: absolute;
  opacity: 0;
  gap: 10px;
  padding: 0 10px;
  justify-content: flex-end;
  height: 100%;
  background-color: ${({theme}) => theme.backgroundColor};
  top: 0px;
  right: 0px;
`

const Billing__Last__Transaction__Option = styled(FlexBox)`
  width: 100%;
  overflow: hidden;
  max-width: 100%;
  box-sizing: border-box;
  padding: 15px 10px;
  position: relative;
  flex-wrap: nowrap;
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.3)};

  &:hover ${Billing__Last__Transaction__Hover__Options__Holder} {
    transition: 200ms;
    transition-delay: 100ms;
    opacity: 1;
  }

  &:last-of-type {
    border-bottom: none;
  }
`
const Billing__Last__Transaction__Option__Title = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: available;
  text-overflow: ellipsis;
`
const Billing__Last__Transaction__Option__Amount = styled(Text)<{ isNegative: boolean }>`
  padding-left: 10px;
  margin-left: auto;
  white-space: nowrap;
  color: ${({isNegative, theme}) => isNegative ? theme.errorMessageIconColor : theme.lowerPriceColor};
`
const Billing__Last__Transaction__Hover__Option__Icon = styled(FontAwesomeIcon)`
  border-radius: 50%;
  padding: 8px;
  margin: auto 0;
  height: 14px;
  width: 14px;
  cursor: pointer;
  border: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
  color: ${({theme}) => rgba(theme.color, 0.6)};
  transition: color 200ms;

  &:hover {
    border-color: ${({theme}) => theme.color};
    color: ${({theme}) => theme.color};
  }
`
const Billing__Last__Transaction__Show__All = styled(Button)`
  margin: 10px 0;
  margin-left: auto;
  min-width: 125px;
`
export const Styled = {
    Billing__Last__Transaction__Show__All,
    Billing__Last__Transaction__Hover__Option__Icon,
    Billing__Last__Transaction__Hover__Options__Holder,
    Billing__Last__Transaction__Option__Amount,
    Billing__Last__Transaction__Option__Title,
    Billing__Last__Transaction__Option,
    Billing__Last__Transactions__List__Holder,
    Billing__Last__Transaction__Name__Amount,
    Billing__Last__Transaction__Name__Title,
    Billing__last__Transactions__Titles,
    Last__Transaction__Title,
    Billing__Last__Transactions__Holder,
    Second__Section,
    Billing__Account__Invoices__Buttons,
    Billing__Account__Action__Button,
    Billing__Account__Action__Buttons__Holder,
    Billing__Account__Invoice__Date__Title,
    Billing__Account__Invoice__Name,
    Billing__Account__Titles__Holder,
    List__Titles__Icon,
    Billing__Account__Invoice__Date,
    Billing__Account__Invoice__Title,
    Billing__Account__Invoice__Option,
    Billing__Sub__Account__Amount,
    Billing__Sub__Account__Title,
    Billing__Sub__Account__Option,
    Billing__Account__Sub__Accounts__Holder,
    Billing__Account__Invoices__List,
    Billing__Account__Invoices__Holder,
    Billing__Account__Amount__Label,
    Billing__Account__Total__Amount__Holder,
    Billing__Account__Amount,
    Billing__Account__Content__Holder,
    Billing__Account__Amount__Status,
    Billing__First__Section__Title,
    Billing__First__Section,
    Billing__Header,
    Billing__Title,
    Billing__Icon,
    Billing__Holder
}