import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import {rgba} from "polished";
import Text from "../../../lib/UI/Text";

const Bottom__Articles__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  height: auto;
`
const Section__Title = styled.h2`
  font-size: 2.5vw;
  color: ${({theme}) => theme.color};
  font-weight: bold;
  padding: 0 10px;
  margin: 5px 0;
  @media (min-width: 1100px) {
    font-size: 27px;
  }
  @media (max-width: 800px) {
    font-size: 20px;
  }
  @media (max-width: 400px) {
    font-size: 20px;
  }
`
const Bottom__Articles__Row = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
  height: auto;
`
const Bottom__Article = styled.a`
  flex-direction: column;
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  width: calc(100% / 5);
  height: 110px;
  padding: 10px 5px;
  border-right: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
  flex-shrink: 0;
  @media (max-width: 1000px) {
    width: calc(100% / 4);
  }
  @media (max-width: 700px) {
    width: calc(100% / 3);
  }
  @media (max-width: 600px) {
    width: calc(100% / 2);
  }


  &:last-of-type {
    border-right: none;
  }


  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.1)};
  }


`
const Bottom__Article__Location = styled(Text)`
  padding: 0 5px;
  padding-top: 5px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Article__Title = styled.h3`
  margin: 0;
  padding: 0 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 10px;
  flex-shrink: 0;
  color: ${({theme}) => theme.color};
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 3em;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-height: 19px;
  -webkit-box-orient: vertical;
  font-size: 18px;
`

export const Styled = {
    Article__Title,
    Bottom__Article__Location,
    Bottom__Article,
    Bottom__Articles__Row,
    Section__Title,
    Bottom__Articles__Holder
}