import {
    Admin__Advertisement,
    Admin__Analytics,
    Admin__Announcements,
    Admin__Billing,
    Admin__Help__Center,
    Admin__Help__Center__Online__Guide,
    Admin__Help__Center__Support__Contact,
    Admin__My__Products,
    Admin__Page,
    Admin__Settings
} from "./Routes";
import AdminPage from "../../../Pages/AdminPage";
import MyProducts from "../../../Pages/AdminSubPages/MyProducts";
import Analytics from "../../../Pages/AdminSubPages/Analytics";
import Billing from "../../../Pages/AdminSubPages/Billing";
import Advertisement from "../../../Pages/AdminSubPages/Advertisement";
import HelpCenter, {HelpCenterType} from "../../../Pages/AdminSubPages/HelpCenter";
import Announcements from "../../../Pages/AdminSubPages/Announcements";
import Settings from "../../../Pages/AdminSubPages/Settings";


const AdminPages = [

    {
        path: Admin__Page,
        element: <AdminPage/>
    },
    {
        path: Admin__My__Products,
        element: <MyProducts/>
    },
    {
        path: Admin__Analytics,
        element: <Analytics/>
    },
    {
        path: Admin__Billing,
        element: <Billing/>
    },
    {
        path: Admin__Advertisement,
        element: <Advertisement/>
    },
    {
        path: Admin__Help__Center,
        element: <HelpCenter type={HelpCenterType.default}/>
    },
    {
        path: Admin__Help__Center__Online__Guide,
        element: <HelpCenter type={HelpCenterType.onlineGuide}/>
    },
    {
        path: Admin__Help__Center__Support__Contact,
        element: <HelpCenter type={HelpCenterType.contactSupport}/>
    },
    {
        path: Admin__Announcements,
        element: <Announcements/>
    },
    {
        path: Admin__Settings,
        element: <Settings/>
    }


]


export {
    AdminPages
}