import {toLower} from "lodash-es";
import {promiseRequest} from "./RequestBuilder";
import {WOR_CONTENT_SERVICE_BASE_URL} from "./requestsSetup";
import useTimeOfYear from "../Hooks/useTimeOfYear";
import useUserConfig from "../Hooks/useUserConfig";

const useReadyRequest = () => {

    const {currentSeasons} = useTimeOfYear()

    const {selectedLanguage} = useUserConfig()

    const fetchListOfSections = async (sections: string[], requestConfigs: any = {}, additionalQuery: any, setState: any, customSeason: string | undefined = undefined) => {
        const query: any = {
            language: selectedLanguage,
            season: customSeason ?? toLower(currentSeasons),
            ...additionalQuery ?? {}
        };
        setState((state: any) => ({
            ...state,
            loadingSections: sections
        }))
        const results = await Promise.all(
            sections.map(async (section) => {
                try {
                    const requestConfig = requestConfigs[section]
                    const response = await promiseRequest(`${WOR_CONTENT_SERVICE_BASE_URL}${requestConfig?.route ?? ''}?query=${JSON.stringify(query)}`);
                    return {section, data: response};
                } catch (error) {
                    console.error(`Error fetching data for section ${section}:`, error);
                    setState((state: any) => ({
                        ...state,
                        loadingSections: [
                            ...state.loadingSections.filter((item: string) => item !== section)
                        ]
                    }))
                    return {section, data: null};
                } finally {
                    setState((state: any) => ({
                        ...state,
                        loadingSections: [
                            ...state.loadingSections.filter((item: string) => item !== section)
                        ]
                    }))
                }
            })
        );

        const dataMap = results.reduce((acc: any, {section, data}) => {
            acc[section] = data;
            return acc;
        }, {});

        setState((state: any) => ({
            ...state,
            ...dataMap,
            season: toLower(customSeason ?? toLower(currentSeasons))
        }))
    };

    const fetchSingleMethod = async (state: any, setState: any, methodPath: string, additionalQuery: any = {}, customSeason: string = "") => {
        try {
            const query: any = {
                language: selectedLanguage,
                season: customSeason ?? toLower(currentSeasons),
                ...additionalQuery ?? {}
            };
            setState((state: any) => ({
                ...state,
                requestSent: true,
                error: false,
                isLoading: true
            }))
            const response = await promiseRequest(`${WOR_CONTENT_SERVICE_BASE_URL}${methodPath}?query=${JSON.stringify(query)}`);
            setState((state: any) => ({
                ...state,
                isLoading: false,
                ...response
            }))
        } catch (error) {
            setState((state: any) => ({
                ...state,
                error: true
            }))
        } finally {
            setState((state: any) => ({
                ...state,
                isLoading: false
            }))
        }
    }


    return {
        fetchListOfSections,
        fetchSingleMethod
    }
}

export default useReadyRequest

