import {Country__Page} from "./Routes";
import CountryPage from "../../../Pages/CountryPage";

const CountryPages = [
    {
        path: Country__Page,
        element: <CountryPage/>
    }
]

export {
    CountryPages
}