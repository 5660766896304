import useSessionPageConfig from "../../../lib/Hooks/useSessionPageConfig";
import {Styled} from "../Footer.styles";

const Links = () => {

    const {publicSiteConfig} = useSessionPageConfig()

    return (
        <Styled.Links__Holder>
            {publicSiteConfig?.footer?.footerLinks?.map((footerSection) => {
                return (
                    <Styled.Links__Section
                        key={'section_key' + footerSection.title}
                    >
                        <Styled.Links__Section__Title>
                            {footerSection.title}
                        </Styled.Links__Section__Title>
                        {footerSection.options.map((link) => {
                            return (
                                <Styled.Link
                                    href={link.link ?? window.location.origin + link.path}
                                    key={'section_key' + footerSection.title + link.title}
                                >
                                    {link.title}
                                </Styled.Link>
                            )
                        })}

                    </Styled.Links__Section>
                )
            })}
        </Styled.Links__Holder>
    )
}

export default Links