import {Styled} from "../Analytics.styles";
import ChartLine from "../../../../components/ChartLine";
import React, {useContext} from "react";
import moment from "moment/moment";
import {ThemeContext} from "styled-components";
import {useTranslation} from "react-i18next";
import {faArrowRight, faCaretDown} from "@fortawesome/pro-solid-svg-icons";

const Header = () => {

    const {brdExclusiveColor, selectTagsBackgroundColor} = useContext<any>(ThemeContext)

    const {t} = useTranslation()

    let values = [11120, 12729, 3714, 21323, 41526, 2511, 3917];

    const chartData = values.map((option: any, index: number) => {
        return {
            name: moment(moment().add(-index - 1, 'days')).format('DD MMM'),
            count: option,
            previousCount: values[values.length - index - 1]
        }
    }).reverse()

    const mostVisitedPages: any = [
        {
            name: 'Main_Page_Italiy',
            count: 15000
        },
        {
            name: 'Main_Page_Sicilia',
            count: 7500
        },
        {
            name: 'Article_Italy_Dolce_Vita',
            count: 3242
        },
        {
            name: 'Article_Siclia_Pizza_Pasta',
            count: 2989
        },
        {
            name: 'Main_Page_Hilton_Warsaw',
            count: 2989
        }
    ];


    const optionsConfig: any = {
        'count': {
            color: brdExclusiveColor,
            width: 3
        },
        'previousCount': {
            color: selectTagsBackgroundColor,
            type: 'scatter',
            width: 1
        }
    }

    return (
        <Styled.Analytics__Over__View__Content__Holder>
            <Styled.Analytics__Visited__Pages>
                <Styled.Line__Chart__Title>
                    {t('lbl_analytics_from_last_seven_days')}
                </Styled.Line__Chart__Title>
                <Styled.Chart__Line__Holder>
                    <ChartLine
                        hideLegend={false}
                        optionsConfig={optionsConfig}
                        data={chartData}
                    />
                </Styled.Chart__Line__Holder>
                <Styled.Header__Show__More__Data__Holder>
                    <Styled.Header__Show__More__Data__Label>
                        {t('lbl_show_longer_span_analytics')}
                    </Styled.Header__Show__More__Data__Label>
                    <Styled.Header__Show__More__Data__Icon
                        icon={faArrowRight}
                    />
                </Styled.Header__Show__More__Data__Holder>
            </Styled.Analytics__Visited__Pages>
            <Styled.Additional__Value__Holder>
                <Styled.Line__Chart__Title>
                    {t('lbl_most_visited_pages')}
                </Styled.Line__Chart__Title>
                <Styled.This__Week__Visits>
                    97540
                </Styled.This__Week__Visits>
                <Styled.Additional__Options__Titles>
                    <Styled.Additional__Option__Section__Name__Title>
                        {t('lbl_page_name')}
                        <Styled.Section__Title__Icon
                            icon={faCaretDown}
                        />
                    </Styled.Additional__Option__Section__Name__Title>
                    <Styled.Additional__Option__Section__Count__Title>
                        {t('lbl_page_count')}
                        <Styled.Section__Title__Icon
                            icon={faCaretDown}
                        />
                    </Styled.Additional__Option__Section__Count__Title>
                </Styled.Additional__Options__Titles>
                <Styled.Additional__Value__Content>
                    {mostVisitedPages.map((pages: any) => (
                        <Styled.Additional__Option
                            key={'most_visited_pages_option_' + pages?.name}
                        >
                            <Styled.Additional__Option__Title>
                                {pages?.name}
                            </Styled.Additional__Option__Title>
                            <Styled.Additional__Option__Views>
                                {pages.count}
                            </Styled.Additional__Option__Views>
                        </Styled.Additional__Option>
                    ))}
                </Styled.Additional__Value__Content>
                <Styled.Header__Show__More__Data__Holder>
                    <Styled.Header__Show__More__Data__Label>
                        {t('lbl_show_more_detailed_page_information')}
                    </Styled.Header__Show__More__Data__Label>
                    <Styled.Header__Show__More__Data__Icon
                        icon={faArrowRight}
                    />
                </Styled.Header__Show__More__Data__Holder>
            </Styled.Additional__Value__Holder>
        </Styled.Analytics__Over__View__Content__Holder>
    )
}


export default Header