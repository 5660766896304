import {Styled} from "./TravelRequirements.styles"

const TravelRequirements = () => {


    return (
        <Styled.Travel__Requirements__Holder>
            HOLDER MAKS
        </Styled.Travel__Requirements__Holder>
    )
}
export default TravelRequirements