import PageHolder from "../../lib/UI/PageHolder";
import ImageGallery from "./ImageGallery";
import {Main__Page__Context} from "../../lib/Context/ContextList";
import NonSeparatedPart from "./NonSeparatedPart";
import SeparatedPart from "./SeparatedPart";
import {useEffect, useState} from "react";
import useReadyRequest from "../../lib/Requests/CustomRequests";
import {mainPageMethod} from "../../lib/Requests/RequestConfigs";


interface GrandSliderOption {
    title: string,
    image: string,
    path: string,
    location: string
}

interface FirstArticleOption {
    title: string
    image: string
    location: string
}

interface FirstArticles {
    title: string,
    list: FirstArticleOption[]
}

interface SecondArticleOtherOptions {
    title: string
    location: string
    path: string
}

interface SecondArticleOption {
    image: string
    title: string
    location: string
    path: string
}

interface SecondArticles {
    mainArticle: SecondArticleOption
    otherArticles: SecondArticleOtherOptions[]
}

interface SideContentBookBrdOffer {
    title: string
    image: string
    location: string
}

interface SideContent {
    bookBrdAdvertOffers: SideContentBookBrdOffer[]
}

interface HotelOption {
    title: string
    rating: string
    path: string
    city: string
    image: string
}

interface BottomArticleOption {
    title: string
    location: string
    path: string
}

type mainPageResponse = {
    pageBackground?: string
    grandSlider?: GrandSliderOption[]
    firstArticles?: FirstArticles
    secondArticles?: SecondArticles
    sideContent?: SideContent
    hotels?: HotelOption[]
    bottomArticles?: BottomArticleOption[]
}

type MainPageState = mainPageResponse & {
    requestSent: boolean,
    isLoading: boolean
}

const mainPageDefaultProps = {
    requestSent: false,
    isLoading: true
}


const MainPage = () => {

    const [mainPageState, setMainPageState] = useState<MainPageState>(mainPageDefaultProps)

    const {fetchSingleMethod} = useReadyRequest()

    const grandSlider = [
        {
            location: 'United Arabic Emirates • Dubai',
            link: 'https://localhost:3000/United-Arab-Emirates',
            title: 'The Place Of Wonders',
            image: 'https://www.visitdubai.com/-/media/gathercontent/poi/b/burj-khalifa/fallback-image/poi-burj-khalifa-3-dtcm-jun-2023.jpg'
        },
        {
            location: 'Italy • Rome',
            link: 'https://localhost:3000/Italy',
            title: 'Older Than You Can Imagine',
            image: 'https://tourismmedia.italia.it/is/image/mitur/20220127150143-colosseo-roma-lazio-shutterstock-756032350-1?wid=1600&hei=900&fit=constrain,1&fmt=webp'
        },
        {
            location: 'Greece • Athens',
            link: 'https://localhost:3000/Greece',
            title: 'Delicious Food And Not Only',
            image: 'https://www.state.gov/wp-content/uploads/2023/07/shutterstock_1168009126v2.jpg'
        },
        {
            location: 'Spain • Madrid',
            link: 'https://localhost:3000/Spain',
            title: 'There Is No Better Place',
            image: 'https://i.natgeofe.com/k/e800ca90-2b5b-4dad-b4d7-b67a48c96c91/spain-madrid.jpg?w=1084.125&h=609'
        },
        {
            location: 'Poland • Cracow',
            link: 'https://localhost:3000/Poland',
            title: 'Discover The History Of Empire',
            image: 'https://study.gov.pl/sites/default/files/ostap-senyuk-7rcdqeuq1rg-unsplash_0.jpg'
        }
    ]

    const firstArticles: any = {
        title: "As Warm As It Gets This Part Of Year",
        list: [
            {
                title: "Unveiling the Allure of Santorini's Timeless Elegance",
                image: 'https://a.cdn-hotels.com/gdcs/production18/d1838/041ae6b1-0a88-4c22-a648-53a22dd4a006.jpg',
                location: 'Greece • Santorini',
            },
            {
                title: "A Journey through the Enchanting Landscapes of Hawaii's Tropical Paradise",
                image: 'https://cdn.aarp.net/content/dam/aarp/travel/Domestic/2021/12/1140-oahu-hero.jpg',
                location: 'USA • Hawaii',
            },
            {
                title: "Exploring the Extravagance of the City of Gold",
                image: 'https://dubaitickets.tours/wp-content/uploads/2023/03/ain-dubai-ferris-wheel.jpg',
                location: 'UAE • Dubai',
            },
            {
                title: "A Tapestry of Art, Architecture, and Mediterranean Charm",
                image: 'https://www.spain.info/.content/imagenes/cabeceras-grandes/cataluna/park-guell-barcelona-s-305364611.jpg',
                location: 'Spain • Barcelona',
            },

        ]
    }

    const secondArticles: any = {
        mainArticle: {
            image: 'https://pbs.twimg.com/media/EFoV3P7W4AEPsNq.jpg',
            title: 'Soaring Above the City of Gold and Skyscrapers',
            location: 'Dubai • UAE',
            link: ''
        },
        otherArticles: [
            {
                title: 'Discovering Scotland\'s Historic Charms',
                location: 'Scotland',
                link: ''
            },
            {
                title: 'Unraveling Morocco\'s Cultural Tapestry',
                location: 'Morocco',
                link: ''
            },
            {
                title: 'Immerse Yourself in Japan\'s Ancient Traditions',
                location: 'Japan',
                link: ''
            },
            {
                title: 'Exploring Greece\'s Island Paradise',
                location: 'Greece',
                link: ''
            },
            {
                title: ' A Spiritual Journey Along the Ganges',
                location: 'India',
                link: ''
            },
            {
                title: 'Trekking Through Peru\'s Incan Legacy',
                location: 'Peru',
                link: ''
            },
            {
                title: 'Delving into the Heart of the American South',
                location: 'USA',
                link: ''
            },
            {
                title: 'Tracing Andalusia\'s Moorish Heritage',
                location: 'Spain',
                link: ''
            },
            {
                title: 'Island-Hopping in the Indian Ocean',
                location: 'Seychelles',
                link: ''
            },
            {
                title: 'Breathtaking Budapest: Unveiling Hungary\'s Architectural Marvels',
                location: 'Hungary',
                link: ''
            },
            {
                title: 'Exploring Polynesia\'s Ancient Mysteries',
                location: 'Chile',
                link: ''
            },
            {
                title: ' Indulging in Italy\'s Coastal Splendor',
                location: 'Italy',
                link: ''
            },
        ]
    }

    const SideContent: any = {
        bookBrdAdvertOffers: [
            {
                image: 'https://images.contentstack.io/v3/assets/blt00454ccee8f8fe6b/blt7d0d1c86efe4f725/6181151eef82350e50b94059/UK_Madrid_Spain_Header.jpg',
                location: 'Spain',
                title: 'Try Delicious Spanish food',
                link: 'https://bookbrd.com'
            },
            {
                image: 'https://images.prismic.io/visiticeland/4007a9bd-142a-4bc8-8d62-2895238851b4_hvammsvik%20hot%20springs.png?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max&w=1748&h=1094',
                location: 'Iceland',
                title: 'Follow The Trails Of Nature',
                link: 'https://bookbrd.com'
            },
            {
                image: 'https://images.contentstack.io/v3/assets/blt00454ccee8f8fe6b/blt7d0d1c86efe4f725/6181151eef82350e50b94059/UK_Madrid_Spain_Header.jpg',
                location: 'Portugal',
                title: 'Experience Fado Live In Bar By The Sea',
                link: 'https://bookbrd.com'
            },
            {
                image: 'https://cms.accuweather.com/wp-content/uploads/2023/05/cnn-L19jb21wb25lbnRzL2ltYWdlL2luc3RhbmNlcy9jbGhzeXQybHcwMDI2MjZxaDhoZjk1MjMx-L19wYWdlcy9jbGhzeXQybDIwMDFsMjZxaGR2cG84d3Q0.jpg',
                location: 'Maldives',
                title: 'Sun, Beach, Good Food, Beautiful View Everything You Can Desire',
                link: 'https://bookbrd.com'
            },
        ]
    }

    const bottomNonPromotedArticles = [
        {
            title: "Unraveling Morocco's Cultural Tapestry",
            location: "Morocco",
            link: ""
        },
        {
            title: "Exploring Greece's Island Paradise",
            location: "Santorini, Greece",
            link: ""
        },
        {
            title: "Immerse Yourself in Japan's Ancient Traditions",
            location: "Kyoto, Japan",
            link: ""
        },
        {
            title: "A Spiritual Journey Along the Ganges",
            location: "Varanasi, India",
            link: ""
        },
        {
            title: "Trekking Through Peru's Incan Legacy",
            location: "Machu Picchu, Peru",
            link: ""
        },
        {
            title: "Delving into the Heart of the American South",
            location: "Charleston, USA",
            link: ""
        },
        {
            title: "Tracing Andalusia's Moorish Heritage",
            location: "Granada, Spain",
            link: ""
        },
        {
            title: "Island-Hopping in the Indian Ocean",
            location: "Seychelles",
            link: ""
        },
        {
            title: "Unveiling Hungary's Architectural Marvels",
            location: "Budapest, Hungary",
            link: ""
        },
        {
            title: "Exploring Polynesia's Ancient Mysteries",
            location: "Easter Island, Chile",
            link: ""
        },
        {
            title: "Indulging in Italy's Coastal Splendor",
            location: "Amalfi Coast, Italy",
            link: ""
        },
        {
            title: "Chasing the Northern Lights in Iceland",
            location: "Reykjavik, Iceland",
            link: ""
        },
        {
            title: "Embracing Australia's Coastal Charm and Urban Buzz",
            location: "Sydney, Australia",
            link: ""
        },
        {
            title: "Immersing in the Rich History of Rome",
            location: "Rome, Italy",
            link: ""
        },
        {
            title: "Savoring the Flavors of Parisian Cuisine",
            location: "Paris, France",
            link: ""
        },
        {
            title: "Roaming the Streets of Old Havana",
            location: "Havana, Cuba",
            link: ""
        },
        {
            title: "Exploring the Rich Heritage of Cairo",
            location: "Cairo, Egypt",
            link: ""
        },
        {
            title: "Adventures in the Amazon Rainforest",
            location: "Amazon, Brazil",
            link: ""
        },
        {
            title: "Discovering the Charms of Cape Town",
            location: "Cape Town, South Africa",
            link: ""
        },
        {
            title: "Journeying Through the Land of Fire and Ice",
            location: "Iceland",
            link: ""
        },
        {
            title: "Immersing in the Vibrant Culture of Rio de Janeiro",
            location: "Rio de Janeiro, Brazil",
            link: ""
        },
        {
            title: "Exploring the Enchanting Temples of Angkor Wat",
            location: "Angkor Wat, Cambodia",
            link: ""
        },
        {
            title: "Discovering the Stunning Beauty of New Zealand's Fiordland",
            location: "Fiordland, New Zealand",
            link: ""
        }]

    const hotelsOptions: any = [
        {
            title: 'Sheraton Frankfurt Airport Hotel & Conference Center',
            rating: 4,
            link: '',
            city: 'Frankfurt',
            image: '',
        },
        {
            title: 'Four Seasons Hotel Madrid',
            rating: 5,
            link: '',
            city: 'Madrid',
            image: '',
        },
        {
            title: 'The Dilly',
            rating: 5,
            link: '',
            city: 'London',
            image: '',
        },
        {
            title: 'Hilton Warsaw City',
            rating: 4,
            link: '',
            city: 'Warsaw',
            image: ''
        },
        {
            title: 'Sofitel Dubai Jumeirah Beach',
            rating: 5,
            link: '',
            city: 'Dubai',
            image: 'https://lh3.googleusercontent.com/proxy/qFul7wo5_cMcZJo_UdlO4JLoYyFCvIuIu0UZnC_ysAusatspRDvpFmhHgVTT8GMSkyjTWFz6OkdX_bGapeDW5ly7kMrZe053BaXjC9yQHkEUSpMLS0wjSPkY0W-810kvBH1Lt9lVKGmQhYGseRPA3x9Au4UqFA=s680-w680-h510',
        },
        {
            title: 'Hilton Berlin',
            rating: 4,
            link: '',
            city: 'Berlin',
            image: 'https://lh3.googleusercontent.com/p/AF1QipMI9wmQnSk2Ey4B14-t3KcTwdrjCEJZEC2fTcYd=s1360-w1360-h1020',
        },
        {
            title: 'Hermitage Hotel Prague',
            rating: 4,
            link: '',
            city: 'Prague',
            image: 'https://cf.bstatic.com/xdata/images/hotel/max1280x900/498409053.jpg?k=6241b3ce826fcff93e90391a420f19b31995a95336a32b582bf9cd3088a25dcd&o=&hp=1',
        },
        {
            title: 'Hotel Monopol',
            rating: 5,
            link: '',
            city: 'Wrocław',
            image: 'https://lh3.googleusercontent.com/p/AF1QipOqpOiirHBnkI7arloN0uWhlPxv_MnVQxxwWK2H=s1360-w1360-h1020'
        },
    ]

    const mainPageContextValue = {
        secondArticles,
        SideContent,
        grandSlider,
        firstArticles,
        hotelsOptions,
        bottomNonPromotedArticles
    }

    const fetchPageContent = async () => {
        try {
            setMainPageState((state: any) => ({
                ...state,
                requestSent: true,
                isLoading: true
            }))
            fetchSingleMethod(mainPageState, setMainPageState, mainPageMethod)
        } catch (e) {
            console.log({e});
        } finally {
            setMainPageState((state: any) => ({
                ...state,
                isLoading: false
            }))
        }

    }

    useEffect(() => {
        if (!mainPageState.requestSent) {
            fetchPageContent()
        }
    }, [])

    console.log({mainPageState})

    return (
        <Main__Page__Context.Provider value={mainPageState ?? mainPageContextValue as any}>
            <PageHolder
                backgroundImage={mainPageState?.pageBackground}
            >
                <ImageGallery/>
                <SeparatedPart/>
                <NonSeparatedPart/>
            </PageHolder>
        </Main__Page__Context.Provider>
    )
}

export default MainPage