import styled from "styled-components";
import Image from "../../lib/UI/Image";
import FlexBox from "../../lib/UI/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Page__Title = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: auto 0;
  padding: 10px;
  background-color: ${({theme}) => theme.backgroundColor};
  color: ${({theme}) => theme.color};
  font-size: 4vw;
  font-weight: bold;

  @media (min-width: 1100px) {
    font-size: 44px;
  }
  @media (max-width: 800px) {
    font-size: 30px;
  }
  @media (max-width: 400px) {
    font-size: 20px;
  }
`
const Page__Title__Flag = styled(Image)`
  margin: auto 10px;
  height: 30px;
  width: 60px;
  object-fit: fill;
  display: none;

  @media (max-width: 800px) {
    display: flex;
  }

  @media (max-width: 400px) {
    height: 25px;
    width: 50px;
  }

`
const Rating__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  margin-left: 5px;
`

const Rating__Star = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  color: ${({theme}) => theme.ratingStarColor};
`
const Page__Title__Skeleton__Holder = styled(FlexBox)`
  height: 2vw;
  font-weight: bold;
  aspect-ratio: 6 /1;

  margin-bottom: 10px;
  @media (min-width: 1100px) {
    height: 44px;
    margin-bottom: 5px;
  }
  @media (max-width: 800px) {
    height: 30px;
    margin-bottom: 5px;
  }
  @media (max-width: 400px) {
    margin-bottom: 0px;
    height: 20px;
  }
`

export const Styled = {
    Page__Title__Skeleton__Holder,
    Rating__Star,
    Rating__Holder,
    Page__Title,
    Page__Title__Flag
}