import {Styled} from "./HotelRooms.styles";

type HotelImages = {
    images: string[]
}

const HotelImages = (
    {
        images
    }: HotelImages
) => {


    return (
        <>
            <Styled.HOTEL__IMAGES__PLACEHOLDER
                src={images[0]}
            />
        </>
        
    )
}

export default HotelImages