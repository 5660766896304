import {Styled} from "./Settings.styles"
import PageHolder from "../../../lib/UI/PageHolder";
import {useTranslation} from "react-i18next";
import {faGear} from "@fortawesome/pro-solid-svg-icons";

const Settings = () => {

    const {t} = useTranslation()

    const sections = [
        {
            title: 'General Administration'
        }
    ]

    return (
        <PageHolder>
            <Styled.Settings__Title>
                <Styled.Settings__Icon
                    icon={faGear}
                />
                {t('lbl_settings')}
            </Styled.Settings__Title>
            <Styled.Setting__Sections__Content__Holder>

            </Styled.Setting__Sections__Content__Holder>
        </PageHolder>
    )

}

export default Settings