import {Styled} from "./Landscapes.styles"

type LandscapeOptionProps = {
    option: any
}

const LandscapeOption = (
    {
        option
    }: LandscapeOptionProps
) => {

    const {
        image,
        title,
        description
    } = option

    return (
        <Styled.Landscape__Option>
            <Styled.Landscape__Option__Image
                src={image}
            />
            <Styled.Landscape__Option__Description__Holder>
                <Styled.Landscape__Title>
                    {title}
                </Styled.Landscape__Title>
                <Styled.Landscape__Description>
                    {description}
                </Styled.Landscape__Description>
            </Styled.Landscape__Option__Description__Holder>
        </Styled.Landscape__Option>
    )
}


export default LandscapeOption