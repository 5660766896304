import {Business__Become__Supplier, Business_FAQ} from "./Routes";
import BecomeSupplier from "../../../Pages/BecomeSupplier";
import BusinessFAQ from "../../../Pages/BusinessFAQ";

const BusinessPages: any = [
    {
        path: Business__Become__Supplier,
        element: <BecomeSupplier/>
    },
    {
        path: Business_FAQ,
        element: <BusinessFAQ/>
    }
]

export {
    BusinessPages
}