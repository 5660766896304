import {Styled} from "./Analytics.styles"
import PageHolder from "../../../lib/UI/PageHolder";
import {faChartPie} from "@fortawesome/pro-solid-svg-icons";
import {useTranslation} from "react-i18next";
import React from "react";
import '../../../lib/Style/lineChart.css'
import Header from "./Sections/Header";
import MiddleContent from "./Sections/MiddleContent";

const Analytics = () => {

    const {t} = useTranslation()


    return (
        <PageHolder>
            <Styled.Analytics__Holder>
                <Styled.Analytics__Header>
                    <Styled.Analytics__Title>
                        <Styled.Analytics__Icon
                            icon={faChartPie}
                        />
                        Analytics
                    </Styled.Analytics__Title>
                </Styled.Analytics__Header>
                <Styled.Analytics__Over__View__Section>
                    <Styled.Analytics__Over__View__Title>
                        {t('lbl_overview')}
                    </Styled.Analytics__Over__View__Title>
                    <Header/>
                    <MiddleContent/>
                </Styled.Analytics__Over__View__Section>
            </Styled.Analytics__Holder>
        </PageHolder>
    )
}

export default Analytics