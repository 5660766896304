import {
    Region__Articles,
    Region__Attractions,
    Region__Hotels,
    Region__Page,
    Region__Particular__Attractions,
    Region__Particular__Hotel
} from "./Routes";
import RegionHotelsPage from "../../../Pages/RegionHotelsPage";
import RegionAttractionsPage from "../../../Pages/RegionAttractionsPage";
import ParticularAttractionPage from "../../../Pages/RegionAttractionsPage/ParticularAttractionPage";
import RegionPage from "../../../Pages/RegionPage";
import HotelPage from "../../../Pages/HotelPage";


const RegionHotelRoutes = [
    {
        path: Region__Hotels,
        element: <RegionHotelsPage/>
    },
    {
        path: Region__Particular__Hotel,
        element: <HotelPage/>
    },
]

const RegionAttractionsRoutes = [
    {
        path: Region__Attractions,
        element: <RegionAttractionsPage/>
    },
    {
        path: Region__Particular__Attractions,
        element: <ParticularAttractionPage/>
    },
]

const RegionArticleRoutes = [
    {
        path: Region__Articles,
        element: <RegionAttractionsPage/>
    },
]

const RegionPages = [
    ...RegionHotelRoutes,
    ...RegionAttractionsRoutes,
    ...RegionArticleRoutes,
    {
        path: Region__Page,
        element: <RegionPage/>
    },
]
export {
    RegionPages
}