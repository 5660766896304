import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import Text from "../../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {rgba} from "polished";

const Analytics__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Analytics__Header = styled(FlexBox)`
  width: calc(100% - 20px);
  padding: 0 10px;
  gap: 20px 10px;
  margin-top: 40px;
`
const Analytics__Title = styled(Text)`
  font-size: 26px;
  font-weight: bold;
`
const Analytics__Icon = styled(FontAwesomeIcon)`
  margin-right: 15px;
  height: 30px;
  width: 30px;
`
const Analytics__Over__View__Section = styled(FlexBox)`
  flex-direction: column;
  margin-top: 40px;

`
const Analytics__Over__View__Title = styled(Text)`
  font-size: 26px;
`
const Analytics__Over__View__Content__Holder = styled(FlexBox)`
  width: 100%;
  flex-wrap: nowrap;
  gap: 20px;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 10px;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`
const Chart__Holder = styled(FlexBox)`
  height: 250px;
  width: 250px;
`
const Additional__Value__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 350px;
  flex-shrink: 0;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.color};

`
const Analytics__Visited__Pages = styled(FlexBox)`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.color};
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
`
const Chart__Line__Holder = styled(FlexBox)`
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-height: 260px;
  @media (max-width: 850px) {
    height: 250px;
  }
`
const Line__Chart__Title = styled(Text)`
  padding-top: 5px;
  padding-bottom: 10px;
  font-weight: bold;
`
const Additional__Value__Content = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: scroll;
  max-height: 178px;
  max-width: 100%;
  flex-grow: 1;
  @media (max-width: 850px) {
    max-height: unset;
  }
`
const Header__Show__More__Data__Label = styled(Text)`
  font-weight: bold;
  cursor: pointer;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Header__Show__More__Data__Icon = styled(FontAwesomeIcon)`
  height: 18px;
  width: 18px;
  margin-left: 8px;
  cursor: pointer;
  transition: 200ms;
  color: ${({theme}) => theme.color};
`
const Header__Show__More__Data__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  margin-left: auto;
  padding: 10px 10px;
  max-width: 100%;
  transition: 200ms;
  flex-shrink: initial;
  border-radius: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: auto;

  &:hover ${Header__Show__More__Data__Label} {
    color: ${({theme}) => theme.brdExclusiveColor};
  }

  &:hover ${Header__Show__More__Data__Icon} {
    color: ${({theme}) => theme.brdExclusiveColor};
  }

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.1)};
  }
`
const Additional__Option = styled(FlexBox)`
  padding: 12px 0px;
  box-sizing: border-box;
  height: auto;
  flex-shrink: 0;
  max-width: 100%;
  flex-wrap: nowrap;
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
  overflow: hidden;

  &:first-of-type {
    border-top: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
  }
`
const Additional__Option__Title = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-shrink: 1;
`
const Additional__Option__Views = styled(Text)`
  flex-shrink: 0;
  margin-left: 15px;
`

const Additional__Options__Titles = styled(FlexBox)`
  flex-wrap: nowrap;
  padding: 10px 0;
`
const Additional__Option__Section__Name__Title = styled(Text)`
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Section__Title__Icon = styled(FontAwesomeIcon)`

  margin-left: 4px;
`
const Additional__Option__Section__Count__Title = styled(Text)`
  margin-left: auto;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const This__Week__Visits = styled(Text)`
  font-size: 26px;
  padding: 10px 0;
  text-align: left;
  font-weight: bold;
`
const Analytics__User__Engagment__Section = styled(FlexBox)`
  margin-top: 20px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  max-width: 710px;
  border: 1px solid ${({theme}) => theme.color};
  flex-wrap: nowrap;
  border-radius: 5px;

  @media (max-width: 720px) {
    height: auto;
    flex-direction: column;
  }
`
const Engagment__Section = styled(FlexBox)`
  flex-direction: column;
  padding: 0 10px;
  width: calc(100%);
  box-sizing: border-box;
`
const Pie__Chart__Holder = styled(FlexBox)`
  width: 400px;
  height: 400px;
  @media (max-width: 720px) {
    width: 100%;
    border-bottom: 1px solid ${({theme}) => theme.color};
  }
  @media (max-width: 440px) {
    width: 100%;
    height: calc(100vw - 40px);
  }
`
const Pie__Chart__Legend__Holder = styled(FlexBox)`
  flex-grow: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  @media (max-width: 720px) {
    margin-top: 10px;
  }
`
const Pie__Chart__legend__Options__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-bottom: 10px;
  max-width: 100%;
  max-height: 230px;
  flex-wrap: nowrap;
  overflow: auto;
  @media (max-width: 720px) {
    max-height: unset;
  }
`
const Pie__Chart__Legend__Option = styled(FlexBox)`
  width: 100%;
  box-sizing: border-box;
  padding: 15px 10px;
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.6)};


  &:first-of-type {
    border-top: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
  }
`
const Pie__Chart__Option__Color = styled(FlexBox)<{ fill: string }>`
  height: 15px;
  width: 15px;
  margin: auto 0;
  background-color: ${({fill}) => fill};
`
const Pie__Chart__Option__Label = styled(Text)`
  margin-left: 8px;
`
const Pie__Chart__Option__Value = styled(Text)`
  margin: auto 0;
  margin-left: auto;
`
const Pie__Chart__Legend__Title__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  padding: 10px;
`
const Pie__Chart__Legend__Title__Name = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => rgba(theme.color, 0.6)};
  margin-right: auto;
`
const Pie__Chart__Legend__Title__Value = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`

export const Styled = {
    Pie__Chart__Legend__Title__Value,
    Pie__Chart__Legend__Title__Name,
    Pie__Chart__Legend__Title__Holder,
    Pie__Chart__Option__Value,
    Pie__Chart__Option__Label,
    Pie__Chart__Option__Color,
    Pie__Chart__Legend__Option,
    Pie__Chart__legend__Options__Holder,
    Pie__Chart__Legend__Holder,
    Pie__Chart__Holder,
    Engagment__Section,
    Analytics__User__Engagment__Section,
    This__Week__Visits,
    Additional__Option__Section__Count__Title,
    Section__Title__Icon,
    Additional__Option__Section__Name__Title,
    Additional__Options__Titles,
    Additional__Option__Views,
    Additional__Option__Title,
    Additional__Option,
    Header__Show__More__Data__Icon,
    Header__Show__More__Data__Holder,
    Header__Show__More__Data__Label,
    Additional__Value__Content,
    Line__Chart__Title,
    Chart__Line__Holder,
    Analytics__Visited__Pages,
    Additional__Value__Holder,
    Chart__Holder,
    Analytics__Over__View__Content__Holder,
    Analytics__Over__View__Title,
    Analytics__Over__View__Section,
    Analytics__Icon,
    Analytics__Title,
    Analytics__Header,
    Analytics__Holder
}
