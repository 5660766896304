import {Styled} from "./BookingModule.styles"
import {faClock} from "@fortawesome/pro-solid-svg-icons";
import {useTranslation} from "react-i18next";
import BookingOptions from "./BookingOptions";

type AttractionBookingModule = {
    name: string,
    span: string
    price: any
    bookingOptions: any
}

const AttractionBookingModule = (
    {
        name,
        span,
        price,
        bookingOptions
    }: AttractionBookingModule
) => {
    const {t} = useTranslation()

    return (
        <Styled.Attractions__Booking__Module__Content__Holder>
            <Styled.Attractions__Top__Layer__Content__Holder>
                <Styled.Attractions__Title__Duration__Holder>
                    <Styled.Attractions__Title>
                        {name}
                    </Styled.Attractions__Title>
                    <Styled.Attractions__Duration>
                        <Styled.Attractions__Clock__Icon
                            icon={faClock}
                        />
                        {span}
                    </Styled.Attractions__Duration>
                </Styled.Attractions__Title__Duration__Holder>
                <Styled.Attractions__Price__Value>
                    {t('lbl_price')}: {price?.amount}{price?.currency} {t('lbl_per_person_short')}
                </Styled.Attractions__Price__Value>
            </Styled.Attractions__Top__Layer__Content__Holder>
            <Styled.Attractions__Booking__Options__Title>
                {t('lbl_booking_options')}
            </Styled.Attractions__Booking__Options__Title>
            <BookingOptions
                bookingOptions={bookingOptions}
            />
        </Styled.Attractions__Booking__Module__Content__Holder>
    )
}

export default AttractionBookingModule