import styled from "styled-components";
import Text from "../../lib/UI/Text";
import FlexBox from "../../lib/UI/FlexBox";
import Image from "../../lib/UI/Image";
import Button from "../../lib/UI/Button";

const Become__Supplier__Title = styled(Text)`
  font-size: 26px;
  padding: 20px 10px;
  text-align: center;
`
const Become__Supplier__Section = styled(FlexBox)`
  padding: 0 10px;
  padding-bottom: 20px;
  flex-direction: column;

  &:first-of-type {
    margin-top: 30px;
  }
`
const Become__Supplier__Section__Title = styled(Text)`
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0;
`
const Become__Supplier__Why__Us = styled(Text)`
  margin-bottom: 10px;
`
const Why__Us__Holder = styled(FlexBox)`
  flex-direction: column;
  padding-top: 15px;
  gap: 10px;
`
const Become__Supplier__Why__Us__Option = styled(Text)`
`
const Become__Supplier__Why__Us__Holder = styled(FlexBox)`
  flex-direction: column;
  gap: 3px 0;
`
const Become__Supplier__Become__Supplier = styled(Text)`
  margin-bottom: 10px;
`
const Become__Supplier__Become__Supplier__Holder = styled(FlexBox)`
  flex-direction: column;
  padding-top: 15px;
  gap: 10px;
`
const Become__Supplier__How__Become__Option = styled(Text)`

`
const Become__Supplier__Our__Reach__Image = styled(Image)`
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  aspect-ratio: 2 / 1;
  background-color: transparent;
`
const Become__Supplier__Join__Now__Holder = styled(FlexBox)`
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
`
const Become__Supplier__Join__Now__Button = styled(Button)`
  padding: 5px 10px;
  min-width: 140px;
  max-width: 250px;
`
const Become__Supplier__Join__Now__Title = styled(Text)`

`
const Become__Supplier__Question__Form__Holder = styled(FlexBox)`
  flex-direction: column;
  max-width: 800px;
  padding-top: 10px;
  gap: 10px;
`
const Question__Form__Text__Input = styled(FlexBox)`
  max-width: 400px;
`
const Question__Form__Text__Area = styled(FlexBox)`
  max-width: 800px;
  min-height: 400px;
  flex-direction: column;
`
const Question__Form__Submit__Button = styled(Button)`
  margin-left: auto;
  min-width: 125px;
  padding: 2px 5px;
`
const Question__Form__Title = styled(Text)`
  line-height: 24px;
`

export const Styled = {
    Question__Form__Title,
    Question__Form__Submit__Button,
    Question__Form__Text__Area,
    Question__Form__Text__Input,
    Become__Supplier__Question__Form__Holder,
    Become__Supplier__Join__Now__Title,
    Become__Supplier__Join__Now__Button,
    Become__Supplier__Join__Now__Holder,
    Become__Supplier__Our__Reach__Image,
    Become__Supplier__How__Become__Option,
    Become__Supplier__Become__Supplier__Holder,
    Become__Supplier__Become__Supplier,
    Become__Supplier__Why__Us__Holder,
    Become__Supplier__Why__Us__Option,
    Why__Us__Holder,
    Become__Supplier__Why__Us,
    Become__Supplier__Section__Title,
    Become__Supplier__Section,
    Become__Supplier__Title
}