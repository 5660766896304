import {Styled} from "./Overview.styles"
import {useTranslation} from "react-i18next";
import SkeletonBox from "../../../../lib/UI/SkeletonBox/SkeletonBox";

type OverviewProps = {
    value?: string
    quickData?: any
    isLoading?: boolean
}

function getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Overview = (
    {
        value,
        isLoading,
        quickData
    }: OverviewProps
) => {

    const {t} = useTranslation()


    return (
        <Styled.Overview__Content__Holder>
            {isLoading ? (
                <>
                    {Array.from({length: getRandomNumber(10, 13)}).map((_: any) => (
                        <SkeletonBox
                            style={{
                                height: '12px',
                                maxWidth: 'unset',
                                minHeight: 'unset',
                                width: `${getRandomNumber(85, 98)}%`
                            }}
                        />
                    ))}
                </>
            ) : (
                <Styled.Overview__Quick__Description>
                    {value?.includes('<BREAK>') ? value?.split('<BREAK>')?.map((line: string) => (<>{line}<br/><br/></>)) : value}
                </Styled.Overview__Quick__Description>
            )}
        </Styled.Overview__Content__Holder>
    )
}
export default Overview