import {Styled} from "./WORLoader.styles"
import React from "react";

type WORLoader = {
    style?: React.CSSProperties
}

const WORLoader = (
    {
        style
    }: WORLoader
) => {


    return (
        <Styled.WOR__Loading__Logo
            src={window.location.origin + '/wor.svg'}
            style={style}
        />
    )
}

export default WORLoader