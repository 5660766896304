import {Styled} from "./ExcursionPlan.styles"
import {faArrowDown} from "@fortawesome/pro-solid-svg-icons";

type ExcursionPlan = {
    steps: any
}

const ExcursionPlan = (
    {
        steps
    }: ExcursionPlan
) => {
    return (
        <Styled.Excursion__Plan__Content__Holder>
            {steps.map((step: any, index: number) => {

                const {
                    time,
                    description
                } = step

                const isLast = index === steps.length - 1

                return (
                    <Styled.Excursion__Plan__Step__Holder>
                        <Styled.Excursion__Plan__Step__Time>
                            {time}
                        </Styled.Excursion__Plan__Step__Time>
                        <Styled.Excursion__Time__Line__Holder>
                            <Styled.Excursion__Time__Line/>
                            <Styled.Excursion__Time__Ball/>
                            {isLast && (
                                <Styled.Excursion__Time__Continue__Arrow
                                    icon={faArrowDown}
                                />
                            )}
                        </Styled.Excursion__Time__Line__Holder>
                        <Styled.Excursion__Plan__Step__Description>
                            {description}
                        </Styled.Excursion__Plan__Step__Description>
                    </Styled.Excursion__Plan__Step__Holder>
                )
            })}
        </Styled.Excursion__Plan__Content__Holder>
    )
}

export default ExcursionPlan