import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import {rgba} from "polished";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../../../lib/UI/Text";
import Image from "../../../lib/UI/Image";
import Button from "../../../lib/UI/Button";

const Contact__Page__Main__Half__Option = styled(FlexBox)`
  flex-direction: column;
  background-color: ${({theme}) => rgba(theme.color, 0.1)};
  min-width: 300px;
  height: 300px;
  flex-grow: 1;
  transition: 500ms;
  cursor: pointer;
  max-width: 50%;
  box-sizing: border-box;

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.3)};
  }

  &:first-of-type {
    border-right: 1px solid ${({theme}) => theme.color};
  }

  &:last-of-type {
    border-left: 1px solid ${({theme}) => theme.color};;
  }

  @media (max-width: calc(600px + 2vw)) {
    max-width: unset;
    &:last-of-type {
      border-left: 0;
      border-top: 1px solid ${({theme}) => theme.color};
    }

    &:first-of-type {
      border-right: 0;
      border-bottom: 1px solid ${({theme}) => theme.color};
    }
  }
`
const Contact__Page__Main__Holder = styled(FlexBox)`
  width: 100%;
  margin-top: 60px;
`
const Contact__Page__Option__Icon = styled(FontAwesomeIcon)`
  width: 60px;
  height: 60px;
  margin: 80px auto 35px auto;
  color: ${({theme}) => theme.color}
`
const Contact__Page__Option__Title = styled(Text)`
  font-size: 24px;
  text-align: center;
  font-weight: bold;
`
const Contact__Title = styled(Text)`
  font-size: 26px;
  font-weight: bold;
  margin-top: 40px;
  padding: 0 10px;
`
const Contact__Icon = styled(FontAwesomeIcon)`
  margin-right: 15px;
  height: 30px;
  width: 30px;
`
const Online__Guide__Section = styled(FlexBox)`
  width: 100%;
  padding-bottom: 20px;
  flex-direction: column;
  flex-wrap: nowrap;
`
const Online__Guide__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-top: 40px;
  padding: 0 10px;
`
const Online__Guide__Section__Title = styled(Text)`
  font-size: 26px;
  white-space: nowrap;
  margin-bottom: 20px;
`
const Online__Guide__Section__Videos__Holder = styled(FlexBox)`
  flex-direction: initial;
  width: 100%;
  box-sizing: border-box;
`
const Online__Guide__Video__Option__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  width: calc(25%);
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
  transition: background-color 200ms;

  @media (max-width: 800px) {
    width: calc(100% / 3);
  }
  @media (max-width: 600px) {
    width: calc(100% / 2);
  }
  @media (max-width: 400px) {
    width: 100%;
  }

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.1)};
  }
`
const Online__Guide__Video__Thumb__Image = styled(Image)`
  width: 100%;
  aspect-ratio: 1.7 / 1;
`
const Online__Guide__Video__Title = styled(Text)`
  font-size: 22px;
  padding-top: 6px;

`

const Help__Center__Support = styled(FlexBox)`
  flex-direction: column;
  padding: 0 10px;
`
const Support__First__Information = styled(Text)`
  font-size: 18px;
  margin-top: 20px;
`
const Support__Form__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-top: 20px;
  padding-top: 20px;
  gap: 10px;
  border-top: 1px solid ${({theme}) => rgba(theme.color, 0.3)};
`
const Support__Report__Type = styled(FlexBox)`
  max-width: 400px;
  flex-direction: column;
`

const Support__Field__Title = styled(Text)`
  font-size: 14px;
  padding-bottom: 4px;
`
const Support__Report__Message = styled(FlexBox)`
  flex-direction: column;
  max-width: 800px;
  min-height: 350px;
`
const Support__Report__Buttons = styled(FlexBox)`
  flex-flow: row-reverse;
  max-width: 800px;
  width: 100%;
`
const Support__Report__Submit = styled(Button)`
  padding: 0 5px;
  min-width: 150px;
`
const Support__Report__Checkboxes = styled(FlexBox)`
  flex-direction: column;
  margin: 0 5px;
`


export const Styled = {
    Support__Report__Checkboxes,
    Support__Report__Submit,
    Support__Report__Buttons,
    Support__Report__Message,
    Support__Field__Title,
    Support__Report__Type,
    Support__Form__Holder,
    Support__First__Information,
    Help__Center__Support,
    Online__Guide__Video__Title,
    Online__Guide__Video__Thumb__Image,
    Online__Guide__Video__Option__Holder,
    Online__Guide__Section__Videos__Holder,
    Online__Guide__Section__Title,
    Online__Guide__Holder,
    Online__Guide__Section,
    Contact__Title,
    Contact__Icon,
    Contact__Page__Option__Icon,
    Contact__Page__Option__Title,
    Contact__Page__Main__Holder,
    Contact__Page__Main__Half__Option
}