import PageHolder from "../../lib/UI/PageHolder";
import {Styled} from "./DiscoverWorld.styles";
import {useTranslation} from "react-i18next";
import SectionTabs, {tabSizes} from "../../components/Sections/SectionTabs";
import {useState} from "react";
import AIAgentPanel from "./AIAgentPanel";
import RecommendedDestinations from "./RecommendedDestinations";
import ReadyPanel from "./ReadyPanel";
import {useNavigate, useParams} from "react-router-dom";
import {Discover__World__No__Param} from "../../lib/Const/Routes/Routes";

export enum whereToTypes {
    Agent = 'Ai-Assistant',
    Panel = 'Panel'
}


const DiscoverWorld = () => {

    const {t} = useTranslation()

    const [dataState, setDataState] = useState<any>({
        [whereToTypes.Agent]: {
            isLoading: false,
            list: [
                {
                    title: 'Countries',
                    list: [
                        {
                            image: 'https://www.state.gov/wp-content/uploads/2023/07/shutterstock_433413835v2.jpg',
                            title: 'Italy'
                        },
                        {
                            image: 'https://images.ctfassets.net/wv75stsetqy3/18jOEJrhKM7WA81nkCKZV8/6f70df258ed1233d5c3a8e3f01298b06/Greece.jpg?q=60&fit=fill&fm=webp',
                            title: 'Greece'
                        },
                        {
                            image: 'https://deih43ym53wif.cloudfront.net/berlin-germany-shutterstock_314149679_87b7a543ce.jpeg',
                            title: 'Germany'
                        },
                        {
                            image: 'https://cdn.britannica.com/42/93842-050-295D32A0/US-Capitol-place-meeting-Congress-Washington-DC.jpg',
                            title: 'United States America'
                        },
                        {
                            image: 'https://wise.com/imaginary-v2/moving-to-mexico-guide.jpg',
                            title: 'Mexico'
                        },
                        {
                            image: 'https://www.state.gov/wp-content/uploads/2019/04/Japan-2107x1406.jpg',
                            title: 'Japan'
                        },
                        {
                            image: 'https://www.national-geographic.pl/media/cache/slider_big/uploads/media/default/0014/23/malediwy.jpeg',
                            title: 'Maldives'
                        }, {
                            image: 'https://www.national-geographic.pl/media/cache/slider_big/uploads/media/default/0014/14/austria-z-czego-slynie-ten-europejski-kraj-przewodnik-po-austrii-fot-getty-images.jpeg',
                            title: 'Austria'
                        },

                    ]
                },
                {
                    title: 'Regions',
                    list: [
                        {
                            title: 'Tuscany',
                            image: 'https://lp-cms-production.imgix.net/2023-02/GettyRF_498131251.jpg'
                        },
                        {
                            title: 'Bavaria',
                            image: 'https://www.tripsavvy.com/thmb/Qtava5-rENpFV4mFa_BpGRkyH00=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-866749682-5c813562c9e77c0001e98fb4.jpg'
                        },
                        {
                            title: 'Catalonia',
                            image: 'https://virtuoso-prod.dotcms.cloud/dA/e255051d-3ec7-414f-8019-f63a21b4eca9/previewImage/CataloniaHero.jpg'
                        },
                        {
                            image: 'https://s3.treasury.qld.gov.au/files/WhyQld-phone-banner.jpg',
                            title: 'Queensland'
                        },
                        {
                            image: 'https://images.winalist.com/blog/wp-content/uploads/2021/05/26144030/AdobeStock_215601634-1024x684.jpeg',
                            title: 'Provence'
                        },
                        {
                            image: 'https://images.squarespace-cdn.com/content/v1/609d3f1781f4a87463f238cb/1706897100550-9USNLL2L7UV6DYOP74BT/image-asset.jpeg',
                            title: 'Andalusia'
                        },
                        {
                            image: 'https://media.blogto.com/articles/20220804-ontario-place.jpg?w=2048&cmd=resize_then_crop&height=1365&quality=70',
                            title: 'Ontario'
                        },
                        {
                            image: 'https://intrepidtimes.com/wp-content/uploads/2019/08/DSCN0995-scaled.jpg',
                            title: 'Sichuan'
                        }
                    ]
                },
                {
                    title: 'Landscapes',
                    list: [
                        {
                            image: 'https://upload.wikimedia.org/wikipedia/commons/a/aa/Dawn_on_the_S_rim_of_the_Grand_Canyon_%288645178272%29.jpg',
                            title: 'Grand Canyon'
                        },
                        {
                            image: 'https://japanupclose.web-japan.org/files/100462016.jpeg',
                            title: 'Mount Fuji'
                        },
                        {
                            image: 'https://cdn.sailing-whitsundays.com/web/images/articles/1781/heros/reef-hero1.jpg',
                            title: 'Great Barrier Reef'
                        },
                        {
                            image: 'https://cdn.britannica.com/31/130031-050-469B9571/stream-Amazon-Rainforest-Ecuador.jpg',
                            title: 'Amazon Rainforest'
                        },
                    ]
                }
            ]
        },
        [whereToTypes.Panel]: {
            isLoading: false,
            showMore: false,
            selected: [],
            list: [
                {
                    title: 'Countries',
                    list: [
                        {
                            image: 'https://www.state.gov/wp-content/uploads/2023/07/shutterstock_433413835v2.jpg',
                            title: 'Italy'
                        },
                        {
                            image: 'https://images.ctfassets.net/wv75stsetqy3/18jOEJrhKM7WA81nkCKZV8/6f70df258ed1233d5c3a8e3f01298b06/Greece.jpg?q=60&fit=fill&fm=webp',
                            title: 'Greece'
                        },
                        {
                            image: 'https://deih43ym53wif.cloudfront.net/berlin-germany-shutterstock_314149679_87b7a543ce.jpeg',
                            title: 'Germany'
                        },
                        {
                            image: 'https://cdn.britannica.com/42/93842-050-295D32A0/US-Capitol-place-meeting-Congress-Washington-DC.jpg',
                            title: 'United States America'
                        },
                        {
                            image: 'https://wise.com/imaginary-v2/moving-to-mexico-guide.jpg',
                            title: 'Mexico'
                        },
                        {
                            image: 'https://www.state.gov/wp-content/uploads/2019/04/Japan-2107x1406.jpg',
                            title: 'Japan'
                        },
                        {
                            image: 'https://www.national-geographic.pl/media/cache/slider_big/uploads/media/default/0014/23/malediwy.jpeg',
                            title: 'Maldives'
                        }, {
                            image: 'https://www.national-geographic.pl/media/cache/slider_big/uploads/media/default/0014/14/austria-z-czego-slynie-ten-europejski-kraj-przewodnik-po-austrii-fot-getty-images.jpeg',
                            title: 'Austria'
                        },

                    ]
                },
                {
                    title: 'Regions',
                    list: [
                        {
                            title: 'Tuscany',
                            image: 'https://lp-cms-production.imgix.net/2023-02/GettyRF_498131251.jpg'
                        },
                        {
                            title: 'Bavaria',
                            image: 'https://www.tripsavvy.com/thmb/Qtava5-rENpFV4mFa_BpGRkyH00=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-866749682-5c813562c9e77c0001e98fb4.jpg'
                        },
                        {
                            title: 'Catalonia',
                            image: 'https://virtuoso-prod.dotcms.cloud/dA/e255051d-3ec7-414f-8019-f63a21b4eca9/previewImage/CataloniaHero.jpg'
                        },
                        {
                            image: 'https://s3.treasury.qld.gov.au/files/WhyQld-phone-banner.jpg',
                            title: 'Queensland'
                        },
                        {
                            image: 'https://images.winalist.com/blog/wp-content/uploads/2021/05/26144030/AdobeStock_215601634-1024x684.jpeg',
                            title: 'Provence'
                        },
                        {
                            image: 'https://images.squarespace-cdn.com/content/v1/609d3f1781f4a87463f238cb/1706897100550-9USNLL2L7UV6DYOP74BT/image-asset.jpeg',
                            title: 'Andalusia'
                        },
                        {
                            image: 'https://media.blogto.com/articles/20220804-ontario-place.jpg?w=2048&cmd=resize_then_crop&height=1365&quality=70',
                            title: 'Ontario'
                        },
                        {
                            image: 'https://intrepidtimes.com/wp-content/uploads/2019/08/DSCN0995-scaled.jpg',
                            title: 'Sichuan'
                        }
                    ]
                },
                {
                    title: 'Landscapes',
                    list: [
                        {
                            image: 'https://upload.wikimedia.org/wikipedia/commons/a/aa/Dawn_on_the_S_rim_of_the_Grand_Canyon_%288645178272%29.jpg',
                            title: 'Grand Canyon'
                        },
                        {
                            image: 'https://japanupclose.web-japan.org/files/100462016.jpeg',
                            title: 'Mount Fuji'
                        },
                        {
                            image: 'https://cdn.sailing-whitsundays.com/web/images/articles/1781/heros/reef-hero1.jpg',
                            title: 'Great Barrier Reef'
                        },
                        {
                            image: 'https://cdn.britannica.com/31/130031-050-469B9571/stream-Amazon-Rainforest-Ecuador.jpg',
                            title: 'Amazon Rainforest'
                        },
                    ]
                }
            ]
        },
        currentTab: whereToTypes.Agent
    })

    const {type} = useParams()

    const navigate = useNavigate()

    const onChangeTab = (tab: whereToTypes | string) => {
        navigate(Discover__World__No__Param + '/' + tab)
    }

    return (
        <PageHolder>
            <Styled.Discover__World__Content__Holder>
                <Styled.Where__To__Go__Title>
                    {t('lbl_where_to_go')}
                </Styled.Where__To__Go__Title>
                <Styled.Where__To__Go__Subtitle>
                    {t('lbl_where_to_go_subtitle')}
                </Styled.Where__To__Go__Subtitle>
                <Styled.Where__To__Go__Panel__Holder>
                    <SectionTabs
                        selected={type}
                        size={tabSizes.large}
                        tabs={[whereToTypes.Panel, whereToTypes.Agent]}
                        onClick={onChangeTab}
                    />
                    {type === whereToTypes.Agent && (
                        <AIAgentPanel/>
                    )}
                    {type === whereToTypes.Panel && (
                        <ReadyPanel
                            state={dataState[whereToTypes.Panel]}
                            setState={setDataState}
                        />
                    )}
                    <RecommendedDestinations
                        value={dataState[type as string]}
                    />
                </Styled.Where__To__Go__Panel__Holder>
            </Styled.Discover__World__Content__Holder>
        </PageHolder>
    )
}

export default DiscoverWorld