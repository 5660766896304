import styled from "styled-components";
import FlexBox from "../../../../lib/UI/FlexBox";
import Image from "../../../../lib/UI/Image";
import Text from "../../../../lib/UI/Text";
import Button from "../../../../lib/UI/Button";
import {rgba} from "polished";

const Landscape__Content__Holder = styled(FlexBox)`
  width: 100%;
  height: auto;
  flex-direction: column;
`
const Landscape__Option = styled(FlexBox)`
  flex-wrap: nowrap;
  padding: 10px;
  cursor: pointer;
  transition: 200ms background-color;

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.05)};
  }

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 0 ;
    padding-bottom: 10px;
  }
`
const Landscape__Option__Image = styled(Image)`
  flex-shrink: 0;
  width: 33vw;
  height: 25vw;
  object-fit: cover;
  @media (max-width: 800px) {
    width: 43vw;
    height: 33vw;
  }
  @media (max-width: 600px) {
    width: calc(100vw - 20px);
    height: calc(80vw);
  }
  @media (min-width: 1100px) {
    width: calc(1100px / 3);
    height: 275px;
  }
`
const Landscape__Option__Description__Holder = styled(FlexBox)`
  flex-direction: column-reverse;
  flex-flow: column-reverse;
  margin-left: 10px;
  @media (max-width: 600px) {
    margin-left: 0px;
    margin-top: 15px;
  }
`
const Landscape__Title = styled.h2`
  color: ${({theme}) => theme.color};
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  padding-top: 5px;
  text-align: left;
`
const Landscape__Description = styled(Text)`
  text-align: left;
  font-size: 18px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Show__More__Landscapes__Button = styled(Button)`
  padding: 0 15px;
  margin: 10px auto;
  min-width: 125px;
`


export const Styled = {
    Show__More__Landscapes__Button,
    Landscape__Description,
    Landscape__Title,
    Landscape__Option__Description__Holder,
    Landscape__Option__Image,
    Landscape__Content__Holder,
    Landscape__Option
}