import {Styled} from "./ImagesGallery.styles"
import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import Drawer from "../../lib/UI/Drawer";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import SkeletonBox from "../../lib/UI/SkeletonBox/SkeletonBox";

type ImagesGalleryProps = {
    images: string[]
    isVideo?: boolean
    flag?: string
    isLoading?: boolean
    drawerCustomTitle?: string
}

const defaultDrawerControls = {
    isLoading: false,
    isVideo: false,
    isOpen: false,
    selectedImage: ''
}

const ImagesGallery = (
    {
        isLoading,
        images,
        flag,
        drawerCustomTitle
    }: ImagesGalleryProps
) => {

    const [drawerControls, setDrawerControls] = useState<any>(defaultDrawerControls)

    const {t} = useTranslation()

    const onShowMoreClickHandler = (event: any) => {
        setDrawerControls((state: any) => ({
            ...state,
            isOpen: true
        }))
    }

    const onDrawerClose = () => {
        setDrawerControls((state: any) => ({
            ...state,
            selectedImage: '',
            isOpen: false
        }))
    }

    const onDrawerImageClickHandler = (image: string) => {
        setDrawerControls((state: any) => ({
            ...state,
            selectedImage: image
        }))
    }

    const onDrawerImageSelectedClose = () => {
        setDrawerControls((state: any) => ({
            ...state,
            selectedImage: ''
        }))
    }

    const getFirstValueType = (source: string) => {
        if (!source) {
            return 'image'
        }
        if (source.includes('.jpg') || source.includes('.png')) {
            return 'image'
        }
        if (source.includes('.mp4')) {
            return 'video'
        }
    }

    const mainContent = () => {
        switch (getFirstValueType(images[0])) {
            case "image":
                return (
                    <Styled.Main__Image
                        src={images[0]}
                    />
                )
            case "video":
                return (
                    <Styled.Main__Video
                        src={images[0]}
                        loop={true}
                        autoPlay={true}
                        muted={true}
                    />
                )
            default:
                return null
        }
    }

    const imageGallery = (source: string) => {
        switch (getFirstValueType(source)) {
            case "image":
                return (
                    <Styled.Gallery__Image
                        onClick={() => onDrawerImageClickHandler(source)}
                        key={'image_gallery_drawer' + source}
                        src={source}
                    />
                )
            case "video":
                return (
                    <Styled.Gallery__Video
                        onClick={() => onDrawerImageClickHandler(source)}
                        key={'image_gallery_drawer' + source}
                        src={source}
                        loop={true}
                        autoPlay={true}
                        muted={true}
                    />
                )
            default:
                return (
                    <Styled.Gallery__Image
                        onClick={() => onDrawerImageClickHandler(source)}
                        key={'image_gallery_drawer' + source}
                        src={source}
                    />
                )
        }
    }

    const imageFocused = (source: string) => {
        switch (getFirstValueType(source)) {
            case "image":
                return (
                    <Styled.Focused__Image
                        onClick={() => onDrawerImageClickHandler(source)}
                        src={source}
                    />
                )
            case "video":
                return (
                    <Styled.Focused__Video
                        onClick={() => onDrawerImageClickHandler(source)}
                        src={source}
                        loop={true}
                        autoPlay={true}
                        muted={true}
                    />
                )
            default:
                return (
                    <Styled.Focused__Image
                        onClick={() => onDrawerImageClickHandler(source)}
                        src={source}
                    />
                )
        }
    }

    const wideContent = (
        <Styled.Wide__Screen__Images__Gallery__Holder>
            <Styled.Main__Image__Holder>
                {isLoading ?
                    <SkeletonBox
                        style={{
                            margin: '0px',
                            maxWidth: 'unset',
                            maxHeight: 'unset',
                            width: '100%',
                            height: '100%',
                            borderRadius: '0px'
                        }}
                    /> :
                    mainContent() as any}
            </Styled.Main__Image__Holder>
            <Styled.Side__Images__Holder>
                {Array.from({length: 2}).map((_, index: number) => (
                    <Styled.Side__Image__Holder>
                        {isLoading ? (
                            <SkeletonBox
                                style={{
                                    height: '204px',
                                    width: '100%',
                                    margin: '0px',
                                    borderRadius: '0px',
                                    maxHeight: 'unset',
                                    maxWidth: 'unset'
                                }}
                            />
                        ) : (
                            <>
                                <Styled.Side__Image
                                    src={index === 0 && flag ? flag : images[index + 1]}
                                />
                                {index === 1 && (
                                    <Styled.More__Images__Holder
                                        onClick={onShowMoreClickHandler}
                                    >
                                        <Styled.Side__Image__More__Label>
                                            {t('lbl_more_images', {count: images.length})}
                                        </Styled.Side__Image__More__Label>
                                    </Styled.More__Images__Holder>
                                )}
                            </>
                        )}
                    </Styled.Side__Image__Holder>
                ))}
            </Styled.Side__Images__Holder>
        </Styled.Wide__Screen__Images__Gallery__Holder>
    )

    const smallContent = (
        <Styled.Small__Screen__Images__Gallery__Holder>
            {isLoading ?
                <SkeletonBox
                    style={{
                        margin: '0px',
                        maxWidth: 'unset',
                        maxHeight: 'unset',
                        width: '100%',
                        height: '100%',
                        borderRadius: '0px'
                    }}
                /> :
                mainContent() as any}
            <Styled.Small__Image__Positioner>
                {isLoading ? null
                    : (
                        <Styled.Small__Image__Content__Holder>
                            <Styled.Small__Image
                                src={images[1]}
                            />
                            <Styled.More__Images__Holder
                                onClick={onShowMoreClickHandler}
                            >
                                <Styled.Side__Image__More__Label>
                                    {t('lbl_more_images', {count: images.length})}
                                </Styled.Side__Image__More__Label>
                            </Styled.More__Images__Holder>
                        </Styled.Small__Image__Content__Holder>
                    )}
            </Styled.Small__Image__Positioner>
        </Styled.Small__Screen__Images__Gallery__Holder>
    )


    const imagesDrawer = useMemo(() => (
            <Drawer
                position={"right"}
                isOpen={drawerControls.isOpen}
                onClose={onDrawerClose}
                title={drawerCustomTitle ?? t('lbl_image_gallery')}
                contentStyles={{
                    width: '100vw',
                    position: 'relative'
                }}
            >
                <Styled.Gallery__Images__Holder>
                    {images && images.map((image: string) => imageGallery(image))}
                </Styled.Gallery__Images__Holder>
                {drawerControls.selectedImage && (
                    <Styled.Focus__Image__Holder>
                        <Styled.Close__Icon
                            icon={faTimes}
                            onClick={onDrawerImageSelectedClose}
                        />
                        {imageFocused(drawerControls.selectedImage)}

                    </Styled.Focus__Image__Holder>
                )}
            </Drawer>
        ), [images, drawerControls.isOpen, drawerControls.selectedImage]
    )


    return (
        <>
            {wideContent}
            {smallContent}
            {imagesDrawer}
        </>
    )
}

export default ImagesGallery