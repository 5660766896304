import {Styled} from "../Sections/SectionTypes/HotelRooms/HotelRooms.styles"
import Tooltip from "../../lib/UI/Tooltip";
import {
    faBathtub,
    faChampagneGlasses,
    faEthernet,
    faHouseLaptop,
    faTimes,
    faTv,
    faVault,
    faWhiskeyGlassIce
} from "@fortawesome/pro-solid-svg-icons";

export enum FacilitiesType {
    room = 'rooms',
    hotel = 'hotel'
}

type FacilitiesProps = {
    type: FacilitiesType
    options: any[]
}

const Facilities = (
    {
        type,
        options
    }: FacilitiesProps
) => {

    const roomIcons: any = {
        room_bathtub: faBathtub,
        room_minibar: faWhiskeyGlassIce,
        room_safe: faVault,
        room_flat_tv: faTv,
        room_working_desk: faHouseLaptop,
        room_ethernet: faEthernet,
        room_lounge_access: faChampagneGlasses
    }

    const hotelIcons: any = {}

    const Icons: any = {
        [FacilitiesType.room]: roomIcons,
        [FacilitiesType.hotel]: hotelIcons
    }

    return (
        <Styled.Room__Facilities__Holder>
            {options.map((facility: any) => (
                <Tooltip
                    content={<Styled.Room__Facility__ToolTip>{facility.title}</Styled.Room__Facility__ToolTip>}
                >
                    <Styled.Room__Facility>
                        <Styled.Room__Facility__Icon
                            icon={Icons[type][facility.id] ?? faTimes}
                        />
                    </Styled.Room__Facility>
                </Tooltip>
            ))}

        </Styled.Room__Facilities__Holder>
    )
}
export default Facilities