import styled from "styled-components";
import FlexBox from "../../../../lib/UI/FlexBox";
import Text from "../../../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Excursion__Plan__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 15px 0;
`
const Excursion__Plan__Step__Holder = styled(FlexBox)`
  flex-direction: initial;
  flex-wrap: nowrap;
`
const Excursion__Time__Line__Holder = styled(FlexBox)`
  flex-direction: column;
  position: relative;
`
const Excursion__Time__Line = styled(FlexBox)`
  margin-left: 6px;
  height: 100%;
  border-right: 3px solid ${({theme}) => theme.brdExclusiveColor};

`
const Excursion__Time__Ball = styled(FlexBox)`
  position: absolute;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  background-color: ${({theme}) => theme.brdExclusiveColor};
  top: 42px;
  border-radius: 50%;
`
const Excursion__Time__Continue__Arrow = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.brdExclusiveColor};
  width: 21px;
  height: 28px;
  margin-left: -3px;
  bottom: -5px;
  position: absolute;
  z-index: 20;
`

const Excursion__Plan__Step__Description = styled(Text)`
  margin-left: 20px;
  padding: 40px 0;
`
const Excursion__Plan__Step__Time = styled(Text)`
  width: 75px;
  flex-shrink: 0;
  white-space: nowrap;
  margin-right: 10px;
  padding: 40px 0;
`


export const Styled = {
    Excursion__Time__Continue__Arrow,
    Excursion__Plan__Step__Time,
    Excursion__Plan__Step__Description,
    Excursion__Time__Ball,
    Excursion__Time__Line,
    Excursion__Plan__Content__Holder,
    Excursion__Time__Line__Holder,
    Excursion__Plan__Step__Holder
}