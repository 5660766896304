import PageHolder from "../../lib/UI/PageHolder";
import ImagesGallery from "../../components/ImagesGallery";
import React, {createElement, useRef} from "react";
import {useParams} from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import SectionTabs from "../../components/Sections/SectionTabs";
import Section from "../../components/Sections/Section";
import {useTranslation} from "react-i18next";
import Artciles from "../../components/Sections/SectionTypes/Artciles";
import RegionMap from "../../components/Sections/SectionTypes/RegionMap/RegionMap";
import {geoJSOn} from "../../components/InterActiveMap/mapvalue";
import Overview from "../../components/Sections/SectionTypes/Overview";
import Landscapes from "../../components/Sections/SectionTypes/Landscapes";

const RegionPage = () => {

    const {RegionName} = useParams()

    const sectionsRef = useRef<any>({})

    const {t} = useTranslation()

    const regionData: any = {
        backgroundImage: 'https://content.api.news/v3/images/bin/f82dfbbabbe5522db96a14d65e91c2e6',
        flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Sicilian_Flag.svg/800px-Sicilian_Flag.svg.png",
        images: [
            window.location.origin + '/sicilia_video.mp4',
            'https://media.istockphoto.com/id/497312814/photo/old-harbor-with-wooden-fishing-boat-in-cefalu-sicily.jpg?s=612x612&w=0&k=20&c=jnPqsVAPCp0A4Qkr5CgVHOgKdaCHurGCWsIMtlvHPI0=',
            'https://tourismmedia.italia.it/is/image/mitur/20210323172805-agrigento-templi-shutterstock-1438251122?wid=1600&hei=900&fit=constrain,1&fmt=webp',
            'https://media.istockphoto.com/id/1451071102/photo/syracuse-italy-street-scene-at-twilight.jpg?s=612x612&w=0&k=20&c=SbwdESExzj28k6n0RgKl6XFKmZaouAFIxtnnLaHV5hg=',
            'https://www.nationalgeographic.it/upload/ngi-hero/sicilia.jpg'
        ]
    }

    const {
        backgroundImage,
        flag,
        images
    } = regionData

    const fuckingMapContent: any = {
        mapStyle: 'mapbox://styles/mksbrd/cltvgzxuc009i01qs9vl62rzl',
        initialState: {
            latitude: 42.5,
            longitude: 12.5,
            zoom: 5
        },
        geoJson: geoJSOn
    }

    const sectionTabs: any = {
        'About': {
            customTitle: "lbl_about_region",
            value: 'Sicily, the largest island in the Mediterranean Sea, exudes a captivating charm that transcends time. With its rich history, stunning landscapes, and vibrant culture, Sicily enchants visitors from around the globe.\n' +
                '\n' +
                '<BREAK>\n' +
                'The island is a mosaic of ancient ruins, including the Valley of the Temples in Agrigento and the Greek Theater in Taormina, bearing testament to its storied past as a crossroads of civilizations. Its rugged coastline, framed by azure waters and golden sands, offers idyllic retreats for sun-seekers and adventurers alike.\n' +
                '\n' +
                '<BREAK>\n' +
                'But Sicily\'s allure extends beyond its physical beauty. Its cuisine, a fusion of Mediterranean flavors, tantalizes the taste buds with dishes like arancini, cannoli, and fresh seafood delicacies. And the warmth of its people, with their passionate zest for life, leaves a lasting impression on every visitor.\n' +
                '\n' +
                '<BREAK>\n' +
                'In Sicily, time seems to slow down, inviting you to savor each moment. Whether you\'re exploring ancient ruins, lounging on sun-drenched beaches, or indulging in a leisurely meal with loved ones, Sicily weaves a tapestry of unforgettable experiences, leaving you enchanted and eager to return.'
        },
        'Landscapes': {
            customTitle: 'lbl_Landscapes',
            options: [
                {
                    image: 'https://hispanico.pl/wp-content/uploads/2023/05/wulkan-etna-widok-pejzaz-dym-natura-przyroda-wlochy-europa.jpg',
                    title: 'Mount Etna',
                    description: 'One of the oldest and still active vulcanos in europe'
                },
                {
                    image: 'https://lp-cms-production.imgix.net/2024-02/Italy-Sicily-Palermo-Photon-Photos-GettyImages-1931315329-RF.jpg?auto=format&q=75&w=1920',
                    title: 'Palermo',
                    description: 'Capital of Sicily and not only'
                },
                {
                    image: 'https://lp-cms-production.imgix.net/2024-02/Italy-Sicily-Favignana-Maremagnum-GettyImages-595333295-RF.jpg?auto=format&q=75&w=1920',
                    title: 'Valley of the Temples',
                    description: 'UNESCO’s temple logo is a mirror of star-turn Tempio della Concordia'
                }
            ]
        },
        'RegionLists': {
            ...fuckingMapContent
        },
        'Articles': {
            options: [
                {
                    title: 'Seaside Serenity Beckons Travelers',
                    image: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/526281323.jpg?k=61f27fff06fe49052521a00982b0068132775a32c8466d5cae33a414d1d81f46&o=&hp=1',
                    time: '15:41',
                    date: '28.03.2024',
                    shortDesc: 'Seaside at it\'s finest',

                    content:
                        'Rediscovering the Pleasures of Slow Living\n' +
                        'In today\'s fast-paced world, the allure of the Dolce Vita lifestyle, rooted in the Italian concept of enjoying life\'s simple pleasures, is experiencing a revival. This article delves into the essence of Dolce Vita and its significance in the context of contemporary society.\n' +
                        'The Dolce Vita lifestyle advocates for a slower pace, emphasizing savoring moments, fostering meaningful connections, and indulging in the finer things in life. It encourages individuals to prioritize experiences over possessions, quality over quantity, and mindfulness over mindlessness.\n' +
                        'Amidst the relentless hustle and bustle of modern life, embracing the Dolce Vita philosophy offers a reprieve—a chance to recalibrate priorities and find contentment in the present moment. Whether it\'s relishing a leisurely meal with loved ones, strolling through cobblestone streets, or appreciating art and culture, adopting elements of Dolce Vita can enrich one\'s overall well-being.\n' +
                        'Furthermore, the article explores how the principles of Dolce Vita extend beyond leisure and leisurely pursuits. It examines how incorporating mindfulness and intentionality into various aspects of life, such as work and relationships, can lead to greater fulfillment and satisfaction.\n' +
                        'While the allure of productivity and constant connectivity may seem enticing, the Dolce Vita lifestyle serves as a gentle reminder of the importance of balance and moderation. It encourages individuals to cultivate a harmonious relationship with time, allowing space for rest, reflection, and rejuvenation.\n' +
                        'In essence, embracing Dolce Vita isn\'t about completely abandoning modernity but rather about finding harmony between the conveniences of the digital age and the timeless joys of slow living. By rekindling an appreciation for life\'s simple pleasures and embracing a more deliberate approach to living, one can experience a profound sense of fulfillment and joy—a true embodiment of the Dolce Vita spirit.\n',
                    link: window.location.origin + window.location.pathname + '/' + 'Dolce_Vita'
                },
                {
                    title: 'Echoes of History, Vibrant Present',
                    image: 'https://cdna.artstation.com/p/assets/images/images/070/790/110/large/artist-archana-mishra-river-on-historic-city-02-1131591.jpg?1703527546',
                    shortDesc: 'An artist\'s rendition of a historic cityscape with a tranquil river',
                    time: '18:41',
                    date: '19.03.2024',
                    content: 'Italy, a country of unparalleled culture, rich history, and exquisite cuisine, is primarily known for two exceptional dishes: pizza and pasta. Not only are they national symbols of Italy, but they have also become true culinary icons worldwide. Italian food is not just about taste; it\'s also about history, tradition, and passion passed down through generations.\n' +
                        '\n' +
                        'The origins of pizza can be traced back to Naples, where in the 18th century, it served as a nutritious and inexpensive meal for the city\'s poor inhabitants. Today, it\'s one of the most popular dishes globally. The secret to authentic Italian pizza lies in simplicity and the quality of ingredients. The dough should be thin, elastic, and crispy, while the tomato sauce must be made from ripe, sweet tomatoes and aromatic herbs.\n' +
                        '\n' +
                        'Similarly, pasta has its roots deeply embedded in Italy\'s history. The first mentions of it date back to the times of ancient Romans. Contemporary pasta, in its various forms, owes its popularity to Italy. From spaghetti to penne, the variety is endless. The key to perfect pasta is using durum wheat semolina for a firm texture and pairing it with the right sauce, whether it\'s a rich ragu or a simple olive oil and garlic.\n' +
                        '\n' +
                        'Italian cuisine is a celebration of flavors, textures, and traditions, and pizza and pasta stand as shining examples of its culinary prowess. Whether enjoyed in a rustic trattoria in the streets of Rome or a modern pizzeria in New York City, these dishes continue to captivate taste buds and bring people together around the world.',
                    link: window.location.origin + window.location.pathname + '/' + 'Pizza_Pasta'
                },
            ]
        },
    }

    const sections: any = {
        Articles: Artciles,
        RegionLists: RegionMap,
        About: Overview,
        Landscapes: Landscapes
    }

    const onTabSelected = (tab: string) => {
        sectionsRef.current[tab].scrollIntoView({behavior: 'smooth', block: 'nearest'})
    }


    return (
        <PageHolder
            backgroundImage={backgroundImage}
        >
            <PageTitle
                title={RegionName as string}
                flag={flag}
            />
            <ImagesGallery
                images={images}
                flag={flag}
            />
            <SectionTabs
                customTitles={{value: sectionTabs, props: {place: RegionName}}}
                tabs={Object.keys(sectionTabs)}
                onClick={onTabSelected}
            />
            {Object.keys(sectionTabs).map((section: string) => {

                const customName = sectionTabs[section].customTitle

                return (
                    <Section
                        content={sections[section] ?
                            createElement(
                                sections[section] as any,
                                {...sectionTabs[section]},
                            ) : null
                        }
                        ref={sectionsRef}
                        sectionName={section}
                        title={t(customName ?? `lbl_${section}`, {place: RegionName})}
                    />
                )
            })}
        </PageHolder>
    )
}

export default RegionPage