import {Styled} from "./SecondArticles.styles"
import {useTranslation} from "react-i18next";
import useMainPage from "../useMainPage";
import {chunk} from "lodash-es";
import {useEffect, useState} from "react";


const resizeFunc = () => {
    if (window.innerWidth > 500) {
        return 3
    }
    if (window.innerWidth < 500) {
        return 2
    }
    return 0
}

const SecondArticles = () => {

    const {t} = useTranslation()

    const {secondArticles} = useMainPage()

    const [rowsNumber, setRowsNumber] = useState<number>(resizeFunc())

    const {
        mainArticle,
        otherArticles,
        title
    } = secondArticles ?? {}

    const wideScreenContent = (
        <Styled.Wide__Screen__Content__Holder>
            <Styled.Second__Articles__Top__Articles__Holder>
                {otherArticles && Array.from({length: 3}).map((_: any, index: number) => (
                    <Styled.Top__Bottom__Article
                        key={'top_articles_' + otherArticles[index]?.title}
                    >
                        <Styled.Main__Article__Place>
                            {otherArticles[index]?.location}
                        </Styled.Main__Article__Place>
                        <Styled.Top__Bottom__Title>
                            {otherArticles[index]?.title}
                        </Styled.Top__Bottom__Title>
                    </Styled.Top__Bottom__Article>
                ))}
            </Styled.Second__Articles__Top__Articles__Holder>
            <Styled.Second__Articles__Middle__Articles__Holder>
                <Styled.Middle__Small__Articles__Holder>
                    {otherArticles && Array.from({length: 3}).map((_: any, index: number) => (
                        <Styled.Side__Article
                            key={'left_articles_' + otherArticles[index + 3]?.title}
                        >
                            <Styled.Main__Article__Place>
                                {otherArticles[index + 3]?.location}
                            </Styled.Main__Article__Place>
                            <Styled.Top__Bottom__Title>
                                {otherArticles[index + 3]?.title}
                            </Styled.Top__Bottom__Title>
                        </Styled.Side__Article>
                    ))}
                </Styled.Middle__Small__Articles__Holder>
                <Styled.Second__Articles__Main__Article__Holder>
                    <Styled.Main__Article__Image
                        src={mainArticle?.image}
                    />
                    <Styled.Main__Article__Place>
                        {mainArticle?.location}
                    </Styled.Main__Article__Place>
                    <Styled.Main__Article__Title>
                        {mainArticle?.title}
                    </Styled.Main__Article__Title>
                </Styled.Second__Articles__Main__Article__Holder>
                <Styled.Middle__Small__Articles__Holder>
                    {otherArticles && Array.from({length: 3}).map((_: any, index: number) => (
                        <Styled.Side__Article
                            key={'right_articles_' + otherArticles[index + 6]?.title}
                        >
                            <Styled.Main__Article__Place>
                                {otherArticles[index + 6]?.location}
                            </Styled.Main__Article__Place>
                            <Styled.Top__Bottom__Title>
                                {otherArticles[index + 6]?.title}
                            </Styled.Top__Bottom__Title>
                        </Styled.Side__Article>
                    ))}
                </Styled.Middle__Small__Articles__Holder>
            </Styled.Second__Articles__Middle__Articles__Holder>
            <Styled.Second__Articles__Bottom__Articles__Holder>
                {otherArticles && Array.from({length: 3}).map((_: any, index: number) => (
                    <Styled.Top__Bottom__Article
                        key={'bottom_articles_' + otherArticles[index + 9]?.title}
                    >
                        <Styled.Main__Article__Place>
                            {otherArticles[index + 9]?.location}
                        </Styled.Main__Article__Place>
                        <Styled.Top__Bottom__Title>
                            {otherArticles[index + 9]?.title}
                        </Styled.Top__Bottom__Title>
                    </Styled.Top__Bottom__Article>
                ))}
            </Styled.Second__Articles__Bottom__Articles__Holder>
        </Styled.Wide__Screen__Content__Holder>
    )

    const smallScreenContent = (
        <Styled.Small__Screen__Content__Holder>
            <Styled.Second__Articles__Main__Article__Holder>
                <Styled.Main__Article__Image
                    src={mainArticle?.image}
                />
                <Styled.Main__Article__Place>
                    {mainArticle?.location}
                </Styled.Main__Article__Place>
                <Styled.Main__Article__Title>
                    {mainArticle?.title}
                </Styled.Main__Article__Title>
            </Styled.Second__Articles__Main__Article__Holder>
            <Styled.Second__Medium__Articles__Other__Articles>
                {chunk(otherArticles, rowsNumber)?.map((options: any, index: number) => (
                    <Styled.Second__Articles__Medium__Row__Holder
                        key={'row_second_articles' + index}
                    >
                        {options.map((article: any) => (
                            <Styled.Other__Article
                                key={'other_article_' + article?.title}
                            >
                                <Styled.Main__Article__Place>
                                    {article?.location}
                                </Styled.Main__Article__Place>
                                <Styled.Top__Bottom__Title>
                                    {article?.title}
                                </Styled.Top__Bottom__Title>
                            </Styled.Other__Article>
                        ))}
                    </Styled.Second__Articles__Medium__Row__Holder>
                ))}
            </Styled.Second__Medium__Articles__Other__Articles>
        </Styled.Small__Screen__Content__Holder>
    )

    useEffect(() => {
        window.addEventListener('resize', () => {
            setRowsNumber(resizeFunc())
        })
        return () => {
            window.removeEventListener('resize', () => {
                setRowsNumber(resizeFunc())
            })
        }
    }, [])

    return (
        <Styled.Second__Articles__Holder>
            <Styled.Section__Title>
                {t(title ?? '')}
            </Styled.Section__Title>
            {wideScreenContent}
            {smallScreenContent}
        </Styled.Second__Articles__Holder>
    )
}
export default SecondArticles