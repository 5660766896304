import {Styled} from "../Billing.styles"
import {useTranslation} from "react-i18next";
import {faBug, faCaretDown, faQuestion} from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "../../../../lib/UI/Tooltip";

const SecondSection = () => {

    const {t} = useTranslation()

    const billingLastTransations = [
        {
            title: 'Region monthly subscription',
            amount: -80
        },
        {
            title: 'Advanced AI assistant monthly subscription',
            amount: -80
        },
        {
            title: 'Funds Pay in',
            amount: 8000
        },
        {
            title: 'Advertisement',
            amount: -6300
        },
        {
            title: 'Advertisement',
            amount: -1300
        },
        {
            title: 'Challenge Winner Prize',
            amount: 3000
        },

    ]

    const optionActionButtons = [
        {
            icon: faQuestion,
            title: 'Details'
        },
        {
            icon: faBug,
            title: 'Report Issue'
        },
    ]

    return (
        <Styled.Second__Section>
            <Styled.Billing__Last__Transactions__Holder>
                <Styled.Last__Transaction__Title>
                    {t('lbl_last_transactions_title')}
                </Styled.Last__Transaction__Title>
                <Styled.Billing__last__Transactions__Titles>
                    <Styled.Billing__Last__Transaction__Name__Title>
                        {t('lbl_transaction_type')}
                        <Styled.List__Titles__Icon
                            icon={faCaretDown}
                        />
                    </Styled.Billing__Last__Transaction__Name__Title>
                    <Styled.Billing__Last__Transaction__Name__Amount>
                        {t('lbl_transaction_amount')}
                        <Styled.List__Titles__Icon
                            icon={faCaretDown}
                        />
                    </Styled.Billing__Last__Transaction__Name__Amount>
                </Styled.Billing__last__Transactions__Titles>
                <Styled.Billing__Last__Transactions__List__Holder>
                    {billingLastTransations.map((transaction: any) => {
                        const {title, amount} = transaction

                        return (
                            <Styled.Billing__Last__Transaction__Option
                                key={'last_transaction_latest_' + title}
                            >
                                <Styled.Billing__Last__Transaction__Hover__Options__Holder>
                                    {optionActionButtons.map((option: any) => {

                                        return (
                                            <Tooltip
                                                content={option.title}
                                            >
                                                <Styled.Billing__Last__Transaction__Hover__Option__Icon
                                                    icon={option.icon}
                                                />
                                            </Tooltip>
                                        )

                                    })}

                                </Styled.Billing__Last__Transaction__Hover__Options__Holder>
                                <Styled.Billing__Last__Transaction__Option__Title>
                                    {title}
                                </Styled.Billing__Last__Transaction__Option__Title>
                                <Styled.Billing__Last__Transaction__Option__Amount
                                    isNegative={amount < 0}
                                >
                                    {amount}
                                    {' $'}
                                </Styled.Billing__Last__Transaction__Option__Amount>
                            </Styled.Billing__Last__Transaction__Option>
                        )
                    })}
                </Styled.Billing__Last__Transactions__List__Holder>
                <Styled.Billing__Last__Transaction__Show__All
                    label={t('lbl_show_all_transactions')}
                />
            </Styled.Billing__Last__Transactions__Holder>
        </Styled.Second__Section>
    )
}
export default SecondSection