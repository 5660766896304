import PageHolder from "../../lib/UI/PageHolder";
import {Styled} from "./BusinessFAQ.styles";
import {useTranslation} from "react-i18next";
import {faChevronDown, faChevronUp} from "@fortawesome/pro-solid-svg-icons";
import {useState} from "react";
import {includes} from "lodash-es";

const BusinessFAQ = () => {

    const [faqState, setFaqState] = useState<any>(
        {
            openedOptionsList: []
        })

    const {t} = useTranslation()

    const onFAQOptionClickHandler = (id: any) => {
        if (includes(faqState.openedOptionsList, id)) {
            setFaqState((state: any) => ({
                ...state,
                openedOptionsList: [
                    ...state.openedOptionsList.filter((item: any) => item !== id),
                ]
            }))
        } else {
            setFaqState((state: any) => ({
                ...state,
                openedOptionsList: [
                    ...state.openedOptionsList,
                    id
                ]
            }))
        }
    }

    const questions = [
        {
            question: 'How Does The Subscription Work',
            answer: 'The subscription process is very simple. First, securely link your credit card to your account. Once that\'s done, you can set up a payment plan that best fits your needs. You have the option to choose a standard monthly subscription or create a custom plan based on your specific usage of the World of Regions. After setting up your preferred plan, your subscription will automatically renew according to your chosen schedule, ensuring you have continuous access to all our services. If you have any questions or need assistance, our support team is always available to help.',
            id: 1
        },
        {
            question: 'Who can become supplier',
            answer: 'To become a supplier, you need to be one of the following: a hotel, a regional authority, a country representative, or a representative of an attraction. This ensures that our platform maintains a high standard of quality and authenticity in the services and experiences offered. If you fit into one of these categories and are interested in joining our network, we welcome you to apply and become a part of the World of Regions.',
            id: 2
        },
        {
            question: 'How Do I Get Started With Your Services',
            answer: 'To get started with our services, you first need to set up an account. After setting up your account, you will have access to an online guide where you can find answers to all your questions about the admin panel and the use of solutions provided by us.',
            id: 3
        },
    ]

    return (
        <PageHolder>
            <Styled.Business__FAQ__Title>
                {t('lbl_business_faq_title')}
            </Styled.Business__FAQ__Title>
            <Styled.Business__FAQ__Description>
                {t('lbl_faq_description')}
            </Styled.Business__FAQ__Description>
            <Styled.Business__FAQ__Questions__Holder>
                {questions.map((option: any, index: number) => {

                    const isOpen = includes(faqState.openedOptionsList, option.id)

                    return (
                        <Styled.Business__FAQ__Option
                            key={`faq_business_${index}`}
                        >
                            <Styled.Business__FAQ__Top__Part
                                onClick={() => onFAQOptionClickHandler(option?.id)}
                            >
                                <Styled.Business__FAQ__Question__Label>
                                    {option?.question}
                                </Styled.Business__FAQ__Question__Label>
                                <Styled.Business__FAQ__Option__Icon
                                    icon={isOpen ? faChevronUp : faChevronDown}
                                />
                            </Styled.Business__FAQ__Top__Part>
                            <Styled.Business__FAQ__Answer__Holder
                                isOpen={isOpen}
                            >
                                <Styled.Business__FAQ__Answer>
                                    {option.answer}
                                </Styled.Business__FAQ__Answer>
                            </Styled.Business__FAQ__Answer__Holder>
                        </Styled.Business__FAQ__Option>
                    )
                })}
            </Styled.Business__FAQ__Questions__Holder>
        </PageHolder>
    )
}

export default BusinessFAQ