import {Styled} from "./TravelTips.styles"

export enum sectionTypeEnum {
    list = 'list',
    plainText = 'plainText',
    multipleList = 'multipleList'
}

type DescriptionContentPartProps = {
    sectionType: sectionTypeEnum
    value: any[]
}

const DescriptionContentPart = (
    {
        sectionType,
        value
    }: DescriptionContentPartProps
) => {


    if (sectionType === sectionTypeEnum.list) {
        return (
            <>
                {value?.map((option: any, index: number) => {

                    return (
                        <Styled.What__To__Take__Option
                            index={index}
                            key={'description_content_what_to_take_' + index}
                        >
                            <span
                                style={{
                                    fontWeight: 'bold'
                                }}
                            >
                               {'•'}
                            </span>
                            {' '}
                            {option}
                        </Styled.What__To__Take__Option>
                    )
                })}
            </>
        )
    }

    if (sectionType === sectionTypeEnum.multipleList) {
        return (
            <>
                {value.map((option: any) => (
                    <Styled.Multiple__List__Holder>
                        <Styled.Multiple__List__Title>
                            {option?.title}
                        </Styled.Multiple__List__Title>
                        {option.options.map((subOptions: any) => (
                            <Styled.Multiple__List__Title>
                                 <span
                                     style={{
                                         marginLeft: '5px',
                                         fontWeight: 'bold'
                                     }}
                                 >
                                   {'•'}
                                </span>
                                {' '}
                                {subOptions}
                            </Styled.Multiple__List__Title>
                        ))}
                    </Styled.Multiple__List__Holder>
                ))}
            </>
        )
    }

    if (sectionType === sectionTypeEnum.plainText) {
        return (
            <Styled.How__To__Plan>
                {value}
            </Styled.How__To__Plan>
        )
    }

    return null
}

export default DescriptionContentPart