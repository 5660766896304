import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Image from "../../lib/UI/Image";
import {rgba} from "polished";
import Text from "../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Wide__Screen__Images__Gallery__Holder = styled(FlexBox)`
  flex-direction: initial;
  flex-wrap: nowrap;
  width: 100%;
  @media (max-width: 800px) {
    display: none;
  }
`
const Main__Image__Holder = styled(FlexBox)`
  position: relative;
  width: 70%;
  height: 412.5px;
  overflow: hidden;
`
const Main__Image = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Main__Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
`

const Side__Images__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-grow: 1;
  width: 30%;
`
const Side__Image = styled(Image)`
  width: 100%;
  height: 204px;

  object-fit: cover;


`
const Side__Image__Holder = styled(FlexBox)`
  position: relative;
  margin-left: 5px;

  &:last-of-type {
    margin-top: 5px;
  }
`
const More__Images__Holder = styled(FlexBox)`
  cursor: pointer;
  position: absolute;
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.6)};
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`
const Side__Image__More__Label = styled(Text)`
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin: auto;

  @media (max-width: 800px) {
    font-size: 21px;
  }
`
const Small__Screen__Images__Gallery__Holder = styled(FlexBox)`
  position: relative;
  width: 100vw;
  height: calc(50vw);
  display: none;

  @media (max-width: 800px) {
    display: flex;
  }
`
const Small__Image__Positioner = styled(FlexBox)`
  position: absolute;
  z-index: 100;
  bottom: 10px;
  right: 10px;
`
const Small__Image__Content__Holder = styled(FlexBox)`
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid ${({theme}) => theme.color};
  border-radius: 5px;
  overflow: hidden;
`
const Small__Image = styled(Image)`
  width: 40vw;
  height: 20vw;
  @media (max-width: 400px) {
    width: 50vw;
    height: 25vw;
  }
`
const Gallery__Image = styled(Image)`
  cursor: pointer;
  transition: 200ms, height 0ms, width 0ms;
  width: calc(100vw / 3 - 40px / 3);
  height: 16.5vw;

  @media (max-width: 800px) {
    width: calc(50vw - 30px / 2);
    height: calc(30vw - 30px / 2);
  }
  @media (min-width: 1100px) {
    width: calc(1100px / 3 - 40px / 3);
    height: 177px;
  }


  &:hover {
    transform: scale(1.02);
  }
`
const Gallery__Video = styled.video`
  cursor: pointer;
  object-fit: cover;
  transition: 200ms, height 0ms, width 0ms;
  width: calc(100vw / 3 - 40px / 3);
  height: 16.5vw;

  @media (max-width: 800px) {
    width: calc(50vw - 30px / 2);
    height: calc(30vw - 30px / 2);
  }
  @media (min-width: 1100px) {
    width: calc(1100px / 3 - 40px / 3);
    height: 177px;
  }


  &:hover {
    transform: scale(1.02);
  }
`

const Gallery__Images__Holder = styled(FlexBox)`
  flex-direction: initial;
  position: relative;
  padding: 10px;
  height: auto;
  flex-grow: 1;
  gap: 10px;
  max-width: 1100px;
  margin: 0 auto;

`
const Focus__Image__Holder = styled(FlexBox)`
  position: absolute;
  flex-wrap: nowrap;
  flex-direction: column;
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.8)};
  right: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
`
const Close__Icon = styled(FontAwesomeIcon)`
  height: 24px;
  width: 24px;
  margin: 5px;
  margin-left: auto;
  cursor: pointer;
  color: ${({theme}) => theme.color};
`
const Focused__Image = styled(Image)`
  width: 100%;
  max-height: calc(100%);
  background-color: transparent;
  object-fit: contain;
`
const Focused__Video = styled.video`
  width: 100%;
  max-height: calc(100% - 36px);
  background-color: transparent;
  object-fit: contain;
`


export const Styled = {
    Focused__Video,
    Main__Video,
    Focused__Image,
    Close__Icon,
    Focus__Image__Holder,
    Gallery__Image,
    Gallery__Video,
    Gallery__Images__Holder,
    Small__Image,
    Small__Image__Content__Holder,
    Small__Image__Positioner,
    Small__Screen__Images__Gallery__Holder,
    Side__Image__More__Label,
    More__Images__Holder,
    Side__Image__Holder,
    Side__Image,
    Side__Images__Holder,
    Wide__Screen__Images__Gallery__Holder,
    Main__Image,
    Main__Image__Holder
}