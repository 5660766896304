import {Styled} from "../../Advertisement.styles";
import {sectionTypes} from "./MainPageAdvertisment";

type DesktopProps = {
    onAdvertDetailsClickHandler: (name: sectionTypes) => void
    visualiserState: any
}

const Desktop = (
    {
        onAdvertDetailsClickHandler,
        visualiserState
    }: DesktopProps
) => {

    return (
        <Styled.Main__Page__Desktop__Holder>
            <Styled.Main__Page__First__Article__Visualiser
                onClick={() => {
                    onAdvertDetailsClickHandler(sectionTypes.firstAdvert)
                }}
                isActive={sectionTypes.firstAdvert === visualiserState.active}>
                <Styled.First__Article__Title>
                    {sectionTypes.firstAdvert}
                </Styled.First__Article__Title>
            </Styled.Main__Page__First__Article__Visualiser>
            <Styled.Main__Page__Desktop__Separated__Section>
                <Styled.Main__Page__Desktop__Separated__Bigger__Area>
                    <Styled.Main__Page__Desktop__B__Section__Holder
                        isActive={sectionTypes.secondAdvert === visualiserState.active}
                    >
                        {Array.from({length: 4}).map((_: any, index: number) => (
                            <Styled.Main__Page__Desktop__B__Option
                                key={'b_section_option' + index}
                                onClick={() => {
                                    onAdvertDetailsClickHandler(sectionTypes.secondAdvert)
                                }}
                                isActive={sectionTypes.secondAdvert === visualiserState.active}
                            >
                                <Styled.Second__Article__Title>
                                    {sectionTypes.secondAdvert}
                                </Styled.Second__Article__Title>
                            </Styled.Main__Page__Desktop__B__Option>
                        ))}
                    </Styled.Main__Page__Desktop__B__Section__Holder>
                    <Styled.Main__Page__Desktop__D__Option
                        onClick={() => {
                            onAdvertDetailsClickHandler(sectionTypes.fourthAdvert)
                        }}
                        isActive={sectionTypes.fourthAdvert === visualiserState.active}
                    >
                        <Styled.Second__Article__Title>
                            {sectionTypes.fourthAdvert}
                        </Styled.Second__Article__Title>
                    </Styled.Main__Page__Desktop__D__Option>
                </Styled.Main__Page__Desktop__Separated__Bigger__Area>
                <Styled.Main__Page__Desktop__Separated__Smaller__Area
                    isActive={sectionTypes.thirdAdvert === visualiserState.active}
                >
                    {Array.from({length: 4}).map((_: any, index: number) => (
                        <Styled.Main__Page__Desktop__C__Option
                            key={'option_advert_c' + index}
                            onClick={() => {
                                onAdvertDetailsClickHandler(sectionTypes.thirdAdvert)
                            }}
                            isActive={sectionTypes.thirdAdvert === visualiserState.active}
                        >
                            <Styled.Second__Article__Title>
                                {sectionTypes.thirdAdvert}
                            </Styled.Second__Article__Title>
                        </Styled.Main__Page__Desktop__C__Option>
                    ))}
                </Styled.Main__Page__Desktop__Separated__Smaller__Area>
            </Styled.Main__Page__Desktop__Separated__Section>
        </Styled.Main__Page__Desktop__Holder>
    )
}

export default Desktop