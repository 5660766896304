import PageHolder from "../../lib/UI/PageHolder";
import {Styled} from "./BecomeSupplier.styles";
import {useTranslation} from "react-i18next";
import WhyBecomeSupplier from "./SupplierSections/WhyBecomeSupplier";
import {createElement} from "react";
import HowToBecomeSupplier from "./SupplierSections/HowToBecomeSupplier";
import ExistingSuppliers from "./SupplierSections/ExistingSuppliers";
import OurReach from "./SupplierSections/OurReach";
import JoinUsNow from "./SupplierSections/JoinUsNow";
import QuestionsForm from "./SupplierSections/QuestionsForm";

const BecomeSupplier = () => {

    const {t} = useTranslation()

    const becomeSupplierSection = [
        {
            title: t('lbl_become_supplier_why_title'),
            element: WhyBecomeSupplier
        },
        {
            title: t('lbl_become_supplier_how_to_become'),
            element: HowToBecomeSupplier
        },
        {
            title: t('lbl_become_supplier_our_reach'),
            element: OurReach
        },
        {
            title: t('lbl_become_supplier_our_existing_clients'),
            element: ExistingSuppliers
        },
        {
            title: t('lbl_become_supplier_join_us'),
            element: JoinUsNow
        },
        {
            title: t('lbl_become_supplier_questions'),
            element: QuestionsForm
        },
    ]

    return (
        <PageHolder>
            {becomeSupplierSection.map((option: any, index: number) => (
                <Styled.Become__Supplier__Section
                    key={`supplier_section_${index}`}
                >
                    <Styled.Become__Supplier__Section__Title>
                        {option?.title}
                    </Styled.Become__Supplier__Section__Title>
                    {option?.element ? createElement(
                        option?.element as any,
                        {},
                    ) : null}
                </Styled.Become__Supplier__Section>
            ))}
        </PageHolder>
    )
}
export default BecomeSupplier