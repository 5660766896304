import {Styled} from "./HotelRooms.styles"
import {faArrowDown, faBedFront, faUpRightAndDownLeftFromCenter, faUser} from "@fortawesome/pro-solid-svg-icons";
import Facilities, {FacilitiesType} from "../../../Facilites";
import {useTranslation} from "react-i18next";
import HotelImages from "./HotelImages";

type RoomOptionProps = {
    room: any
}

const RoomOption = (
    {
        room
    }: RoomOptionProps
) => {

    const {t} = useTranslation()

    const {
        name,
        images,
        details,
        facilities
    } = room

    const detailIcon: any = {
        roomSize: faUpRightAndDownLeftFromCenter,
        roomPeople: faUser,
        roomBedsNumber: faBedFront
    }

    const detailCustomSufix: any = {
        roomPeopleMultiple: t('lbl_room_people_multiple'),
        roomBedsNumberMultiple: t('lbl_room_beds)_multiple')
    }


    return (
        <Styled.Room__Option>
            <HotelImages
                images={images}
            />
            <Styled.Room__Information__Content>
                <Styled.Room__Title>
                    {name}
                </Styled.Room__Title>
                <Facilities
                    type={FacilitiesType.room}
                    options={facilities}
                />
                <Styled.Details__Holder>
                    {Object.keys(details).map((detailName: string) => {
                        const detailsValue = details[detailName]
                        return (
                            <Styled.Room__Detail__Holder>
                                <Styled.Room__Detail__Icon
                                    icon={detailIcon[detailName] ?? faArrowDown}
                                />
                                <Styled.Room__Detail>
                                    {detailsValue}{' '}{detailCustomSufix[detailName]}
                                </Styled.Room__Detail>
                            </Styled.Room__Detail__Holder>
                        )
                    })}
                </Styled.Details__Holder>
            </Styled.Room__Information__Content>
        </Styled.Room__Option>
    )
}

export default RoomOption