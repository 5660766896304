import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import Image from "../../../lib/UI/Image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {rgba} from "polished";
import Text from "../../../lib/UI/Text";

const Hotels__Content__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Section__Title = styled.h2`
  font-size: 2.5vw;
  color: ${({theme}) => theme.color};
  font-weight: bold;
  padding: 0 10px;
  margin: 5px 0;
  @media (min-width: 1100px) {
    font-size: 27px;
  }
  @media (max-width: 800px) {
    font-size: 20px;
  }
  @media (max-width: 400px) {
    font-size: 20px;
  }
`
const Hotels__Options__Holder = styled(FlexBox)`
  width: 100%;
  height: auto;
`
const Hotel__Option = styled.a`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;

  width: calc(100vw / 4);

  @media (min-width: 1100px) {
    width: 275px;
  }

  @media (max-width: 800px) {
    width: calc(100vw / 3);
  }
  @media (max-width: 600px) {
    width: calc(100vw / 2);
  }

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.1)};
  }
`
const Hotel__Image = styled(Image)`
  flex-shrink: 0;
  height: calc(100vw / 6 - 20px);
  width: 100%;
  @media (max-width: 600px) {
    height: calc(100vw / 4 - 20px);
  }
  @media (min-width: 1100px) {
    height: 163px;
  }
`
const Hotel__lower__Content__Holder = styled(FlexBox)`
  padding: 10px;
  padding-bottom: 0px;
  flex-direction: column;
`
const Hotels__Rating__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  gap: 2px;
`
const Rating__Star = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  color: ${({theme}) => theme.ratingStarColor};
`
const Hotel__Title = styled.h3`
  margin: 0;
  padding-top: 2px;
  margin-top: 5px;
  padding-bottom: 2px;
  flex-shrink: 0;
  color: ${({theme}) => theme.color};
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 3em;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-height: 20px;
  -webkit-box-orient: vertical;
  font-size: 20px;
`
const Location__Rating__Holder = styled(FlexBox)`
  gap: 5px;
  max-width: 100%;
  flex-wrap: nowrap;
`
const Location__Name = styled(Text)`
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
export const Styled = {
    Location__Name,
    Location__Rating__Holder,
    Hotel__Title,
    Rating__Star,
    Hotels__Rating__Holder,
    Hotel__lower__Content__Holder,
    Hotel__Image,
    Hotel__Option,
    Hotels__Options__Holder,
    Hotels__Content__Holder,
    Section__Title
}