import {useParams} from "react-router-dom";
import PageHolder from "../../lib/UI/PageHolder";
import {Styled} from "./ContientPage.styles";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDebouncedCallback} from "use-debounce";
import {continentConfig, continentSections} from "../../lib/Requests/RequestConfigs";
import useReadyRequest from "../../lib/Requests/CustomRequests";
import MapWithOptions from "../../components/Sections/SectionTypes/MapWithOptions/MapWithOptions";
import {includes} from "lodash-es";
import PageTitle from "../../components/PageTitle";
import useCustomEventListener from "../../lib/Hooks/useCustomEventListener";
import {SEASON_EVENT} from "../../components/Navigation/SeasonPicker";

const sections = [
    continentSections.SiteData,
    continentSections.CountryList,
]

const defaultState: any = {
    loadingSections: sections
}

const ContinentPage = () => {

    const {continent} = useParams()

    const {t} = useTranslation()

    const {fetchListOfSections} = useReadyRequest()

    const [continentContent, setContinentContent] = useState(defaultState)

    const fetchPage = useDebouncedCallback(() => {
        const query = {
            continentId: continent?.split('_')[1]
        }
        fetchListOfSections(
            sections,
            continentConfig,
            query,
            setContinentContent,
            undefined,
        );
    }, 50)

    useCustomEventListener(SEASON_EVENT, fetchPage)

    useEffect(() => {
        fetchPage()
    }, [])

    return (
        <PageHolder
            backgroundImage={continentContent[continentSections.SiteData]?.backgroundImage}
        >
            <Styled.Continent__Content__Holders>
                <PageTitle
                    title={continentContent[continentSections.SiteData]?.name}
                    isLoading={includes(continentContent.loadingSections, continentSections.SiteData)}
                />
                <MapWithOptions
                    customOptionsTitle={t('lbl_countries')}
                    isLoading={includes(continentContent.loadingSections, continentSections.CountryList)}
                    options={continentContent[continentSections.CountryList]?.options}
                    mapStyle={continentContent[continentSections.CountryList]?.mapStyle}
                    initialState={continentContent[continentSections.CountryList]?.initialState}
                    geoJson={{}}
                />
            </Styled.Continent__Content__Holders>
        </PageHolder>
    )

}


export default ContinentPage
