import {Styled} from "../BecomeSupplier.styles"

const OurReach = () => {

    return (
        <Styled.Become__Supplier__Our__Reach__Image
            src={window.location.origin + '/Our_Reach_wor.png'}
        />
    )
}

export default OurReach