import {Styled} from "./MyProducts.styles"
import PageHolder from "../../../lib/UI/PageHolder";
import {faList} from "@fortawesome/pro-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {statusOptions} from "../../../lib/Const/AdminOptions";

const MyProducts = () => {

    const {t} = useTranslation()

    const myProducts = [
        {
            type: 'Region',
            status: 'active',
            title: 'Sicily',
            image: 'https://content.api.news/v3/images/bin/f82dfbbabbe5522db96a14d65e91c2e6'
        },
        {
            type: 'Country',
            status: 'active',
            title: 'Italy',
            image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/Flag_of_Italy.svg/2560px-Flag_of_Italy.svg.png'
        },
        {
            type: 'Hotel',
            status: 'active',
            title: 'Modica Boutique Hotel',
            image: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/294069773.jpg?k=3e4b80026cd3dc1a0688ae5081d0f6b4e887cb29cc815e3d046e66962ce58802&o=&hp=1'
        },
        {
            type: 'Attraction',
            status: 'active',
            title: 'Etna Excursion',
            image: 'https://tuitam.org.pl/wp-content/uploads/2022/09/DSC_8570.jpg'
        },

    ]

    return (
        <PageHolder>
            <Styled.My__Products__Holder>
                <Styled.My__Products__Header>
                    <Styled.My__Products__Title>
                        <Styled.My__Products__Icon
                            icon={faList}
                        />
                        My Products
                    </Styled.My__Products__Title>
                    <Styled.My__Products__Action__Buttons>
                        <Styled.My__Products__Transfer__Product>
                            {t('lbl_transfer_product')}
                        </Styled.My__Products__Transfer__Product>
                        <Styled.My__Products__New__Product>
                            {t('lbl_new_product')}
                        </Styled.My__Products__New__Product>
                    </Styled.My__Products__Action__Buttons>
                </Styled.My__Products__Header>

                <Styled.My__Products__Top__Titles>
                    <Styled.My__Products__Top__Name>
                        {t('lbl_product_name')}
                    </Styled.My__Products__Top__Name>
                    <Styled.My__Products__Top__Status>
                        {t('lbl_status')}
                    </Styled.My__Products__Top__Status>
                    <Styled.My__Products__Top__Type>
                        {t('lbl_product_type')}
                    </Styled.My__Products__Top__Type>
                </Styled.My__Products__Top__Titles>
                <Styled.My__Products__Content__Holder>
                    {myProducts.map((option: any) => (
                        <Styled.My__Product>
                            <Styled.My__Product__Wide__Content>
                                <Styled.My__Product__Image
                                    src={option.image}
                                />
                                <Styled.My__Product__Title>
                                    {option.title}
                                </Styled.My__Product__Title>
                                <Styled.My__Product__Status__Holder>
                                    <Styled.My__Product__Status
                                        backgroundColor={statusOptions[option.status].backgroundColor}
                                    >
                                        {t('lbl_' + option.status)}
                                    </Styled.My__Product__Status>
                                </Styled.My__Product__Status__Holder>

                                <Styled.My__Product_Type>
                                    {option.type}
                                </Styled.My__Product_Type>
                            </Styled.My__Product__Wide__Content>
                            <Styled.My__Product__Small__Content>
                                <Styled.My__Product__Image
                                    src={option.image}
                                />
                                <Styled.My__Product__Small__Details__Holder>
                                    <Styled.My__Product__Title>
                                        {option.title}
                                    </Styled.My__Product__Title>
                                    <Styled.My__Product__Status__Holder>
                                        <Styled.My__Product__Type>
                                            {t('lbl_status')}:
                                        </Styled.My__Product__Type>
                                        <Styled.My__Product__Status
                                            backgroundColor={statusOptions[option.status].backgroundColor}
                                        >
                                            {t('lbl_' + option.status)}
                                        </Styled.My__Product__Status>
                                    </Styled.My__Product__Status__Holder>
                                    <Styled.My__Product_Type>
                                        <span
                                            style={{
                                                fontWeight: 'normal',
                                                marginRight: '8px'
                                            }}
                                        >
                                        {t('lbl_product_type')}:
                                            </span>
                                        {option.type}
                                    </Styled.My__Product_Type>
                                </Styled.My__Product__Small__Details__Holder>
                            </Styled.My__Product__Small__Content>
                        </Styled.My__Product>
                    ))}
                </Styled.My__Products__Content__Holder>
            </Styled.My__Products__Holder>
        </PageHolder>
    )
}

export default MyProducts