import InterActiveMap from "../../../InterActiveMap";
import React, {useState} from "react";
import {Styled} from "../../../../Pages/RegionPage/RegionPage.styles";
import SectionTabs, {tabSizes} from "../../SectionTabs";
import WORLoader from "../../../../lib/UI/WORLoader";
import {attractionOption, hotelOption, RegionEnum, RegionMapType} from "../../../../lib/Types";
import Text from "../../../../lib/UI/Text";
import HotelBox from "../../../../Pages/RegionPage/HotelBox";
import AttractionBox from "../../../../Pages/RegionPage/AttractionBox";
import {useTranslation} from "react-i18next";

type RegionMapProps = {
    options: any[]
    mapStyle: string
    initialState: any
    geoJson: any
}


const RegionMapTabs = [
    RegionEnum.hotel,
    RegionEnum.attractions
]


export const hotelOptions: hotelOption[] = [
    {
        name: 'Modica Boutique Hotel',
        image: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/294069773.jpg?k=3e4b80026cd3dc1a0688ae5081d0f6b4e887cb29cc815e3d046e66962ce58802&o=&hp=1',
        rating: 4,
        description: 'Nestled amidst the verdant hills, this boutique gem offers an escape into serenity. With panoramic views of lush landscapes, each room is a cocoon of comfort and elegance. Indulge in culinary delights at our rooftop restaurant or rejuvenate in the spa sanctuary. Whether it\'s a romantic getaway or a tranquil solo sojourn, Sapphire Haven Retreat promises an unforgettable experience.',
        location: 'Modica'
    },
    {
        name: 'Casa Nostra Boutique Hotel & Spa',
        image: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/409762412.jpg?k=77651785fbefde6137d8ba5b4bb0c36e1cfbf59b24b4678877b4a5675a22e049&o=&hp=1',
        rating: 3,
        description: 'let along the sun-kissed shores, this resort is a haven for beach lovers. Wake up to the melody of crashing waves in your spacious sea-facing suite. Dive into azure waters or lounge by the poolside with a tropical cocktail in hand. With a fusion of luxury and laid-back charm, Golden Sands Oasis invites you to unwind and create lasting memories by the sea.',
        location: 'Palermo'
    },
    {
        name: 'Itria Palace',
        image: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/198270086.jpg?k=66134054e1c01d7fd0aa840036a96594e64380f8f3292d65e92ed22567335428&o=&hp=1',
        rating: 4,
        description: 'Tucked away in a mystical forest, this enchanting lodge beckons adventurers and nature enthusiasts alike. Immerse yourself in the tranquility of the wilderness from your cozy cabin, complete with rustic charm and modern comforts. Embark on hiking trails or simply unwind by the crackling fireplace with a book. At Mystic Woodland Lodge, immerse yourself in the magic of nature\'s embrace.',
        location: 'Ragusa'
    },
    {
        name: 'Room Of Andrea Hotel',
        image: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/206650551.jpg?k=9ac14ae8df5da3545eef236102fe5b5f0fda8091fd07a0ec86d27a176e2dc76c&o=&hp=1',
        rating: 4,
        description: 'Cradled amidst towering peaks, this retreat offers a symphony of luxury and rugged wilderness. Retreat to your elegantly appointed chalet with breathtaking mountain vistas. Explore nearby trails, embark on a scenic helicopter tour, or unwind with a fireside massage. With gourmet dining and alpine adventures at your doorstep, Eternal Peaks Resort promises an unforgettable mountain getaway.',
        location: 'Trapani'
    },
    {
        name: 'Domus Maris Relais Boutique Hotel',
        image: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/99282074.jpg?k=20c680a0f9a59ffb2af4bc953bfcaf12e8a325b2d738fced1eb4ab62e83d4e99&o=&hp=1',
        rating: 3,
        description: 'Perched atop cliffs overlooking azure waters, this coastal sanctuary is a slice of paradise. Wake up to panoramic ocean views from your private terrace suite. Dive into crystal-clear waters for a snorkeling adventure or relax on the pristine beach with a beachfront picnic. With personalized service and coastal chic ambiance, Azure Bayview Haven invites you to unwind and embrace the coastal lifestyle.',
        location: 'Trapani'
    },
]

export const attractionOptions: attractionOption[] = [
    {
        name: 'Etna',
        image: 'https://hispanico.pl/wp-content/uploads/2023/05/wulkan-etna-widok-pejzaz-dym-natura-przyroda-wlochy-europa.jpg',
        description: 'Embark on an unforgettable journey to Mount Etna, Europe\'s most active volcano. Discover the raw beauty of Sicily\'s rugged landscapes as you ascend to the summit. Witness billowing plumes of smoke and fiery lava flows against a backdrop of stunning panoramic views. Explore ancient lava caves, hike scenic trails, and savor authentic Sicilian cuisine in quaint mountain villages. A trip to Mount Etna promises thrilling adventures and awe-inspiring natural wonders at every turn.',
        priceWise: 1,
        span: '8 hours'
    },
    {
        name: 'Cyclops Riviera Mini-Cruise',
        image: 'https://dreamisland.it/wp-content/uploads/2023/02/mini-crociera-acitrezza.webp',
        description: 'Embark on a mini-cruise adventure along the enchanting Cyclops Riviera, where legend meets reality. Sail along the rugged coastline of Sicily, named after the mythical Cyclops who, according to legend, hurled massive rocks into the sea. Admire picturesque fishing villages nestled between towering cliffs and turquoise waters. Discover hidden sea caves and secluded coves accessible only by boat. Snorkel in crystal-clear waters teeming with marine life or simply relax on deck and soak in the Mediterranean sun. The Cyclops Riviera Mini-Cruise promises a day of exploration and relaxation along one of Sicily\'s most captivating coastlines.',
        priceWise: 2,
        span: '4 hours'
    },
    {
        name: 'Alcantara Body Rafting and River Trekking',
        image: 'https://dreamisland.it/wp-content/uploads/2023/01/body-raften-alcantara.webp',
        description: 'Embark on an adrenaline-fueled journey through the stunning Alcantara Gorge with our Body Rafting and River Trekking excursion. Strap on your gear and plunge into the icy waters of the Alcantara River, where sheer volcanic cliffs tower above. Navigate through narrow passages and cascading waterfalls, feeling the rush of the currents against your skin. Hike along ancient lava formations, sculpted over millennia by the forces of nature. Whether you\'re a thrill-seeker or a nature enthusiast, Alcantara Body Rafting and River Trekking promises an unforgettable adventure in the heart of Sicily\'s untamed wilderness.',
        priceWise: 1,
        span: '1-2 hours'
    },
    {
        name: 'Palermo Cathedral',
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Panoramica_Cattedrale_di_Palermo.jpg/1200px-Panoramica_Cattedrale_di_Palermo.jpg',
        description: 'Step into centuries of history at Palermo Cathedral, a masterpiece of Norman architecture in Sicily. Marvel at its intricate facade adorned with Arab-Norman influences, showcasing a blend of cultures. Explore the opulent interior, adorned with Byzantine mosaics, intricate frescoes, and royal tombs. Witness the interplay of light and shadow in its sacred halls, where the whispers of the past echo through time. A visit to Palermo Cathedral is a journey through Sicily\'s rich cultural heritage and spiritual significance.',
        priceWise: 1,
        span: '1-3 hours'
    },
    {
        name: 'Ursino Castle',
        image: 'https://lh3.googleusercontent.com/p/AF1QipM8heR50_22SHG7SWknlcEGQlTnYur0tNzRcsWQ=s680-w680-h510',
        description: 'Transport yourself to medieval Sicily with a visit to Ursino Castle, a formidable fortress steeped in history. Perched majestically overlooking the city of Catania, this imposing structure was built in the 13th century by Frederick II. Explore its sturdy walls and looming towers, once bastions of defense against invaders. Delve into the castle\'s storied past through interactive exhibits and archaeological findings. From its panoramic views of Mount Etna to its evocative dungeons, Ursino Castle offers a captivating glimpse into Sicily\'s turbulent past.',
        priceWise: 2,
        span: '2-4 hours'
    },


]


const RegionMap = (
    {
        options,
        mapStyle,
        initialState,
        geoJson
    }: RegionMapProps
) => {

    const {t} = useTranslation()

    const [mapState, setMapState] = useState<RegionMapType>({
        type: RegionEnum.hotel,
        isLoading: false,
        isDrawerOpen: false,
        options: {
            [RegionEnum.hotel]: hotelOptions,
            [RegionEnum.attractions]: attractionOptions
        }
    })

    const buttonLabels: any = {
        [RegionEnum.hotel]: t('lbl_show_more_hotels_button'),
        [RegionEnum.attractions]: t('lbl_show_more_attractions_button')
    }

    const onChangeTab = (tab: string) => {
        setMapState((state: RegionMapType) => ({
            ...state,
            type: tab as RegionEnum
        }))
    }

    const onShowMoreButton = () => {
        if (mapState.type === RegionEnum.hotel) {
            window.open(window.location.origin + window.location.pathname + '/Hotels', '_self')
        }
        if (mapState.type === RegionEnum.attractions) {
            window.open(window.location.origin + window.location.pathname + '/Attractions', '_self')
        }
    }

    const sectionLoader = (
        <Styled.Map__Loader__Holder>
            <WORLoader
                style={{
                    margin: 'auto'
                }}
            />
        </Styled.Map__Loader__Holder>
    )

    const sectionOptions = (
        <>
            {mapState.options[mapState.type]?.map((option: hotelOption | attractionOption) => {
                if (mapState.type === RegionEnum.hotel) {
                    return (
                        <HotelBox
                            linkPrefix={window.location.origin + window.location.pathname + '/Hotels'}
                            option={option as hotelOption}
                        />
                    )
                }
                if (mapState.type === RegionEnum.attractions) {
                    return (
                        <AttractionBox
                            linkPrefix={window.location.origin + window.location.pathname + '/Attractions'}
                            option={option as attractionOption}
                        />
                    )
                }
                return (
                    <Text>
                        {mapState.type}
                    </Text>
                )
            })}
        </>
    )

    return (
        <Styled.Region__Map__Content__Holder>
            <Styled.Region__Map>
                <InterActiveMap
                    mapStyle={mapStyle}
                    initialState={initialState}
                    geoJson={geoJson}
                />
                {/*Map Loader*/}
                {mapState.isLoading && sectionLoader}
            </Styled.Region__Map>
            <Styled.Map__Options__Holder>
                <SectionTabs
                    size={tabSizes.medium}
                    selected={mapState.type}
                    tabs={RegionMapTabs}
                    onClick={onChangeTab}
                />
                {sectionOptions}
                <Styled.More__Options__Button
                    onClick={onShowMoreButton}
                    label={buttonLabels[mapState.type]}
                />
                {/*Options Loader*/}
                {mapState.isLoading && sectionLoader}
            </Styled.Map__Options__Holder>
        </Styled.Region__Map__Content__Holder>
    )
}

export default RegionMap