import {Styled} from "../Footer.styles"
import {useState} from "react";
import {useTranslation} from "react-i18next";

const MapSite = () => {

    const [isSiteMapOpen, setIsSiteMapOpen] = useState<boolean>(false)


    const {t} = useTranslation()

    const toggleSiteMap = () => {
        setIsSiteMapOpen((state: boolean) => !state)
    }

    return (
        <Styled.Site__Map__Holder>
            <Styled.Footer__Map__Site__Title
                onClick={toggleSiteMap}
            >
                {isSiteMapOpen ? t('lbl_hide_site_map') : t('lbl_show_site_map')}
            </Styled.Footer__Map__Site__Title>
            {isSiteMapOpen && (
                <Styled.Map__Site__Map>
                    SITE MAP
                </Styled.Map__Site__Map>
            )}
        </Styled.Site__Map__Holder>
    )
}

export default MapSite