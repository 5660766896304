import useStorage from "./useStorage";
import {USER__CONFIG__STORAGE} from "../Const/Configs/UserConf";
import {languagesOptions} from "../../components/Navigation/LanguagePicker";

const getDefaultState = () => {

    return {
        language: "en"
    }
}

type configType = {
    language: string
}

const useUserConfig = () => {

    const [state, setState] = useStorage<configType>('localStorage', USER__CONFIG__STORAGE, getDefaultState())


    const onLanguageChangeHandler = (language: string) => {
        setState((state: configType) => ({
            ...state,
            language
        }))
        window.location.reload()
    }

    return {
        selectedLanguage: state.language,
        languageData: languagesOptions[state.language],
        onLanguageChangeHandler
    }
}
export default useUserConfig