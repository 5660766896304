import {Styled} from "./HotelRooms.styles"
import RoomOption from "./RoomOption";

type HotelRoomsProps = {
    options: any[]
}

const HotelRooms = (
    {
        options
    }: HotelRoomsProps
) => {


    return (
        <Styled.Hotel__Rooms__Holder>
            {options.map((room: any, index: number) => {
                return (
                    <RoomOption
                        room={room}
                        key={'room_option' + index + room?.name}
                    />
                )
            })}
        </Styled.Hotel__Rooms__Holder>
    )
}

export default HotelRooms