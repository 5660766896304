import {Styled} from "../Analytics.styles"
import {useTranslation} from "react-i18next";
import ChartPie from "../../../../components/ChartPie";
import {faArrowRight, faCaretDown} from "@fortawesome/pro-solid-svg-icons";
import React from "react";

const MiddleContent = () => {

    const {t} = useTranslation()

    const data = [
        {
            "name": "image_gallery_open",
            "value": 1800,
            fill: '#ff0000'
        },
        {
            "name": "Landscape_open",
            "value": 900,
            fill: '#53ff00',
        },
        {
            "name": "Article_open",
            "value": 789,
            fill: '#2986ff'
        },
        {
            "name": "Landscapes_more",
            "value": 678,
            fill: '#fffc00'
        },
        {
            "name": "room_type_interaction",
            "value": 653,
            fill: '#c800ff'
        },
        {
            "name": "room_type_show_more",
            "value": 432,
            fill: '#00f7ff'
        },

    ];

    return (
        <Styled.Engagment__Section>
            <Styled.Analytics__Over__View__Title>
                {t('lbl_user_activity_on_main_site')}
            </Styled.Analytics__Over__View__Title>
            <Styled.Analytics__User__Engagment__Section>
                <Styled.Pie__Chart__Holder>
                    <ChartPie
                        data={data}
                    />
                </Styled.Pie__Chart__Holder>
                <Styled.Pie__Chart__Legend__Holder>
                    <Styled.Line__Chart__Title>
                        {t('lbl_user_activity_on_main_page')}
                    </Styled.Line__Chart__Title>
                    <Styled.This__Week__Visits>
                        5252
                    </Styled.This__Week__Visits>
                    <Styled.Pie__Chart__Legend__Title__Holder>
                        <Styled.Pie__Chart__Legend__Title__Name>
                            {t('lbl_section_name')}
                            <Styled.Section__Title__Icon
                                icon={faCaretDown}
                            />
                        </Styled.Pie__Chart__Legend__Title__Name>
                        <Styled.Pie__Chart__Legend__Title__Value>
                            {t('lbl_section_value')}
                            <Styled.Section__Title__Icon
                                icon={faCaretDown}
                            />
                        </Styled.Pie__Chart__Legend__Title__Value>
                    </Styled.Pie__Chart__Legend__Title__Holder>
                    <Styled.Pie__Chart__legend__Options__Holder>
                        {data.map((option: any) => {

                            const {fill, name, value} = option

                            return (
                                <Styled.Pie__Chart__Legend__Option>
                                    <Styled.Pie__Chart__Option__Color
                                        fill={fill}
                                    />
                                    <Styled.Pie__Chart__Option__Label>
                                        {name}
                                    </Styled.Pie__Chart__Option__Label>
                                    <Styled.Pie__Chart__Option__Value>
                                        {value}
                                    </Styled.Pie__Chart__Option__Value>
                                </Styled.Pie__Chart__Legend__Option>
                            )
                        })}
                    </Styled.Pie__Chart__legend__Options__Holder>
                    <Styled.Header__Show__More__Data__Holder>
                        <Styled.Header__Show__More__Data__Label>
                            {t('lbl_show_more_detailed_user_action')}
                        </Styled.Header__Show__More__Data__Label>
                        <Styled.Header__Show__More__Data__Icon
                            icon={faArrowRight}
                        />
                    </Styled.Header__Show__More__Data__Holder>
                </Styled.Pie__Chart__Legend__Holder>
            </Styled.Analytics__User__Engagment__Section>
        </Styled.Engagment__Section>
    )
}
export default MiddleContent