import useStorage from "./useStorage";
import {AppConfigDefaultState, AppConfigStorage} from "../Context/Providers/AppConfigProvider";
import {AppConfig} from "../Types/publicPageConfig";

const useSessionPageConfig = () => {

    const [state, setState] = useStorage<AppConfig>('sessionStorage', AppConfigStorage, AppConfigDefaultState)


    return {
        state,
        publicSiteConfig: state.publicSiteConfig,
        setState
    }

}


export default useSessionPageConfig