import {Styled} from "../../Advertisement.styles"
import {useState} from "react";
import ViewMode from "../../ViewMode";
import {useTranslation} from "react-i18next";
import {faChevronDown, faChevronUp, faTimes} from "@fortawesome/pro-solid-svg-icons";
import Desktop from "./Desktop";
import Text from "../../../../../lib/UI/Text";
import FlexBox from "../../../../../lib/UI/FlexBox";
import Button from "../../../../../lib/UI/Button";

export enum viewTypes {
    desktop = 'desktop',
    tablet = 'tablet',
    mobile = 'mobile'
}

export enum sectionTypes {
    firstAdvert = 'A',
    secondAdvert = 'B',
    thirdAdvert = 'C',
    fourthAdvert = 'D'
}

enum typeOfAdvert {
    slider = 'Slider',
    fixed = 'Fixed'

}

const sections: any = {
    [sectionTypes.firstAdvert]: {
        attributes: [
            'The Most Succesful',
            'The Most Visible',
            'The Biggest',
        ],
        analytics: [
            {
                label: 'Average Daily Impressions',
                count: '189 092 82',
            },
            {
                label: 'Average Daily Redirections',
                count: '189 82',
            },
        ],
        pricing: [
            {
                title: '1 Day',
                values: [
                    {
                        label: 'First Slide',
                        amount: 750
                    },
                    {
                        label: 'Other Slides',
                        amount: 250
                    }
                ]
            },
            {
                title: '1 Week',
                values: [
                    {
                        label: 'First Slide',
                        amount: 5000
                    },
                    {
                        label: 'Other Slides',
                        amount: 1600
                    }
                ]
            },
        ],
        type: typeOfAdvert.slider
    }
}


const visualiserDefault = {
    isOpen: false,
    type: viewTypes.desktop,
    active: undefined
}


const MainPageAdvertisement = () => {

    const [visualiserState, setVisualiserState] = useState(visualiserDefault)

    const {t} = useTranslation()

    const onViewTypeChangeHandler = (type: viewTypes) => {
        setVisualiserState((state: any) => ({
            ...state,
            type
        }))
    }

    const onAdvertDetailsClickHandler = (type: sectionTypes | undefined) => {

        setVisualiserState((state: any) => ({
            ...state,
            active: type
        }))

    }

    const toggleSection = () => {
        setVisualiserState((state: any) => ({
            ...state,
            isOpen: !state.isOpen
        }))
    }

    return (
        <Styled.MainPage__Advertisement__Holder>
            <Styled.Close__Able__Section__Header
                isOpen={visualiserState.isOpen}
                onClick={toggleSection}
            >
                <Styled.Advertisement__Section__Title>
                    Main Page
                </Styled.Advertisement__Section__Title>
                <Styled.Close__Able__Section__Icon
                    style={{
                        marginLeft: 'auto'
                    }}
                    icon={visualiserState?.isOpen ? faChevronUp : faChevronDown}
                />
            </Styled.Close__Able__Section__Header>
            {visualiserState?.isOpen && (
                <Styled.Advertisement__Section__Content__Holder>
                    <Styled.Advertisement__Main___Page__Visual__Holder>
                        <ViewMode
                            onChange={onViewTypeChangeHandler}
                            active={visualiserState.type}
                        />
                        <Styled.Main__Page__Advertisement__Visualiser>
                            {visualiserState.type === viewTypes.desktop && (
                                <Desktop
                                    onAdvertDetailsClickHandler={onAdvertDetailsClickHandler}
                                    visualiserState={visualiserState}
                                />
                            )}
                        </Styled.Main__Page__Advertisement__Visualiser>
                    </Styled.Advertisement__Main___Page__Visual__Holder>
                    <Styled.Advertisement__Selected__Section__Details>
                        <Styled.Advertisement__Selected__Section__Header>
                            <Styled.Selected__Option__Title>
                                {visualiserState.active ? t('lbl_selected_advertisement', {name: visualiserState.active}) : t('lbl_no_selected_advertisement')}
                            </Styled.Selected__Option__Title>
                            {visualiserState.active && (
                                <Styled.Advertisement__Selected__Section__Clear__Icon
                                    icon={faTimes}
                                    onClick={() => onAdvertDetailsClickHandler(undefined)}
                                />
                            )}
                        </Styled.Advertisement__Selected__Section__Header>
                        {visualiserState.active && (
                            <Styled.Advertisement__Selected__Section__Content>
                                <Styled.Advertisement__Selected__Section__Section__Description>
                                    {sections[visualiserState?.active]?.attributes.map((attribute: any, index: number) => (
                                        <span style={{
                                            margin: '2px 0',
                                            fontSize: `${20 + index}px`
                                        }}>
                                        {attribute} {sections[visualiserState.active as any]?.attributes.length - 1 !== index && ','}
                                            <br/>
                                    </span>
                                    ))}
                                </Styled.Advertisement__Selected__Section__Section__Description>
                                <Styled.Advertisement__Selected__Analytics>
                                    <Styled.Advertisement__Analytics__Title>
                                        {t('lbl_analytics')}
                                    </Styled.Advertisement__Analytics__Title>
                                    {sections[visualiserState.active]?.analytics.map((option: any) => (
                                        <FlexBox
                                            style={{
                                                margin: '2px 0'
                                            }}
                                        >
                                            <Text>
                                                {option.label}:
                                            </Text>
                                            <Text
                                                style={{
                                                    marginLeft: 'auto',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {option.count}
                                            </Text>
                                        </FlexBox>
                                    ))}
                                </Styled.Advertisement__Selected__Analytics>
                                <Styled.Advertisement__Selected__Section__Pricing>
                                    <Styled.Advertisement__Selected__Pricing__Title>
                                        {t('lbl_pricing')}
                                    </Styled.Advertisement__Selected__Pricing__Title>
                                    {sections[visualiserState.active]?.pricing.map((prices: any) => (
                                        <>
                                            <Styled.Advertisement__Price__Type__Title>
                                                {prices.title}
                                            </Styled.Advertisement__Price__Type__Title>
                                            {prices.values.map((item: any) => (
                                                <FlexBox
                                                    style={{
                                                        margin: '3px 0',
                                                    }}
                                                >
                                                    <Text>
                                                        {item.label}:
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            fontWeight: 'bold',
                                                            marginLeft: 'auto'
                                                        }}
                                                    >
                                                        {item.amount}{' $'}
                                                    </Text>
                                                </FlexBox>
                                            ))}
                                        </>
                                    ))}
                                </Styled.Advertisement__Selected__Section__Pricing>
                                <Styled.Advertisement__Selected__Action__Buttons>
                                    <Button
                                        label={'Show available dates'}
                                    />
                                </Styled.Advertisement__Selected__Action__Buttons>
                            </Styled.Advertisement__Selected__Section__Content>
                        )}
                    </Styled.Advertisement__Selected__Section__Details>
                </Styled.Advertisement__Section__Content__Holder>
            )}
        </Styled.MainPage__Advertisement__Holder>
    )
}

export default MainPageAdvertisement