import Modal from "../../lib/UI/Modal/ModalsTypes/Modal";
import {useTranslation} from "react-i18next";
import useNavigation from "../../lib/Hooks/useNavigation";
import SeasonPicker from "./SeasonPicker";
import SectionTabs, {tabSizes} from "../Sections/SectionTabs";
import {isUndefined} from "lodash-es";
import LanguagePicker from "./LanguagePicker";
import {useState} from "react";
import useOnClickOutside from "../../lib/Hooks/useOnClickOutside";

export enum typeChange {
    language = 'change_language',
    season = 'change_season'
}

const SeasonLanguageModal = () => {

    const {t} = useTranslation()

    const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

    const {
        isOpenWideScreenDrawer,
        toggleChangeSeason,
    } = useNavigation()


    const onCloseModal = () => {
        toggleChangeSeason(undefined)
    }

    const onTabsChangeHandler = (tab: typeChange) => {
        toggleChangeSeason(tab)
    }

    useOnClickOutside(modalElement, onCloseModal);

    return (
        <Modal
            ref={setModalElement}
            title={t('lbl_' + isOpenWideScreenDrawer)}
            isOpen={!isUndefined(isOpenWideScreenDrawer)}
            onClose={onCloseModal}
            width={600}
        >
            <SectionTabs
                size={tabSizes.medium}
                selected={isOpenWideScreenDrawer}
                tabs={[typeChange.language, typeChange.season]}
                onClick={(tab: string) => onTabsChangeHandler(tab as typeChange)}
            />
            {isOpenWideScreenDrawer === typeChange.language && (
                <LanguagePicker
                    isDrawer={false}
                />
            )}
            {isOpenWideScreenDrawer === typeChange.season && (
                <SeasonPicker
                    onChange={onCloseModal}
                />
            )}
        </Modal>
    )
}

export default SeasonLanguageModal