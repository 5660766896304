import {
    faChartMixed,
    faChartPie,
    faCircleInfo,
    faCommentExclamation,
    faCreditCard,
    faGear,
    faList,
    faPen,
    faRectangleAd,
    faWandMagicSparkles
} from "@fortawesome/pro-solid-svg-icons";
import {
    Admin__Advertisement,
    Admin__Analytics,
    Admin__Announcements,
    Admin__Billing,
    Admin__Help__Center,
    Admin__My__Products,
    Admin__Settings
} from "../Routes/Routes";

const Quick__Access: any = {
    'new_article': {
        icon: faPen
    },
    'see_analytics': {
        icon: faChartMixed
    },
    'manage_page': {
        icon: faWandMagicSparkles
    },
    'plan_advertisement': {
        icon: faRectangleAd
    }
}

const Admin__options = [
    {

        title: 'My Products',
        icon: faList,
        pathname: Admin__My__Products
    },
    {
        title: 'Analytics',
        icon: faChartPie,
        pathname: Admin__Analytics
    },
    {
        title: 'Advertisement',
        icon: faRectangleAd,
        pathname: Admin__Advertisement
    },
    {
        title: 'Billing',
        icon: faCreditCard,
        pathname: Admin__Billing
    },
    {
        title: 'Help Center',
        icon: faCircleInfo,
        pathname: Admin__Help__Center
    },
    {
        title: 'Announcements',
        icon: faCommentExclamation,
        pathname: Admin__Announcements
    },
    {
        title: 'Settings',
        icon: faGear,
        pathname: Admin__Settings
    },
]

const statusOptions: any = {
    'active': {
        backgroundColor: 'rgba(26,255,0,0.62)',
    },
    'pending': {
        backgroundColor: 'rgba(253,120,74,0.4)',
    },
    'suspended': {
        backgroundColor: 'rgba(255,0,0,0.4)',
    }
}


export {
    statusOptions,
    Admin__options,
    Quick__Access
}