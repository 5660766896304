import styled from "styled-components";
import FlexBox from "../FlexBox";

const PageHolder = styled(FlexBox)`
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
`
const Left__Background = styled(FlexBox)<{ src: string | undefined }>`
  display: none;
  background-color: transparent;
  background-image: url(${({src}) => src});
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  @media (min-width: 1100px) {
    display: flex;
    position: fixed;
    left: 0px;
    width: calc(calc(100vw - 1100px) / 2);
    top: 60px;
    bottom: 0px;
  }
`

const Right__Background = styled(FlexBox)<{ src: string | undefined }>`
  display: none;
  background-color: transparent;
  background-image: url(${({src}) => src});
  background-position: left;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  @media (min-width: 1100px) {
    display: flex;
    position: fixed;
    right: 0px;
    width: calc(calc(100vw - 1100px) / 2);
    top: 60px;
    bottom: 0px;
  }
`


export const Styled = {
    Left__Background,
    Right__Background,
    PageHolder
}