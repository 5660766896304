import {Admin__Page__Context, ContextProviderProps} from "../ContextList";
import {useState} from "react";
import {AdminPageContext} from "../DefaultContextValues";

const AdminPageProvider = (
    {
        children
    }: ContextProviderProps) => {

    const [state, setState] = useState(AdminPageContext)

    return (
        <Admin__Page__Context.Provider value={[state, setState] as any}>
            {children}
        </Admin__Page__Context.Provider>
    )
}

export default AdminPageProvider