import {Styled} from "./SectionTabs.styles"
import {useTranslation} from "react-i18next";
import {isUndefined} from "lodash-es";
import SkeletonBox from "../../../lib/UI/SkeletonBox/SkeletonBox";

export enum tabSizes {
    small = 'small',
    medium = 'medium',
    large = 'large'
}

type SectionTypesProps = {
    tabs: string[]
    isLoading?: boolean
    customTitles?: any
    onClick: (tab: string) => void
    selected?: string
    size?: tabSizes
}


const SectionTabs = (
    {
        isLoading,
        customTitles,
        tabs,
        onClick,
        selected,
        size = tabSizes.large
    }: SectionTypesProps
) => {

    const {t} = useTranslation()

    const tabFontSizes: any = {
        [tabSizes.large]: '20px',
        [tabSizes.medium]: '16px',
        [tabSizes.small]: '14px'
    }

    const getTitle = (tab: string, customTitle: string) => {
        if (customTitle) {
            return t(customTitle, {...customTitles.props as any})
        }
        return t(`lbl_${tab}`)

    }

    return (
        <Styled.Section__Tabs__Holder>
            {isLoading ? (
                <>
                    {Array.from({length: 10}).map((_: any) => (
                        <Styled.Section__Tab
                            isInteractive={!isUndefined(selected)}
                            isSelected={false}
                            fontSize={tabFontSizes[size]}
                            onClick={() => false}
                        >
                            <SkeletonBox/>

                        </Styled.Section__Tab>
                    ))}
                </>
            ) : (
                <>
                    {tabs.map((tab: string) => {

                        const isSelected = isUndefined(selected) ? true : tab === selected
                        const customTitle: string = customTitles?.value[tab]?.customTitle ?? ''

                        return (
                            <Styled.Section__Tab
                                isInteractive={!isUndefined(selected)}
                                isSelected={isSelected}
                                fontSize={tabFontSizes[size]}
                                onClick={() => onClick(tab)}
                            >
                        <span
                            style={{
                                whiteSpace: 'nowrap',
                                margin: 'auto 0'
                            }}
                        >
                            {getTitle(tab, customTitle) as string}
                        </span>
                            </Styled.Section__Tab>
                        )
                    })}
                </>
            )}
        </Styled.Section__Tabs__Holder>
    )
}

export default SectionTabs