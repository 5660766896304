import {Styled} from "./Advertisement.styles"
import useMainPage from "../useMainPage";

const AdvertisementBox = () => {

    const {advertisementBox} = useMainPage()

    return (
        <Styled.Advertisement__Box__Holder>
            <Styled.Advertisement__Anchor
                target={'_blank'}
                href={advertisementBox?.link}
            >
                <Styled.Advert__Image
                    src={advertisementBox?.image}
                />

            </Styled.Advertisement__Anchor>
        </Styled.Advertisement__Box__Holder>
    )
}

export default AdvertisementBox