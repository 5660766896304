import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import {rgba} from "polished";
import Image from "../../lib/UI/Image";
import Text from "../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../lib/UI/Button";

const Region__Map__Content__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Region__Map = styled(FlexBox)`
  width: 100%;
  height: 550px;
  position: relative;

  @media (max-width: 1100px) {
    height: 50vw;
  }
  @media (max-width: 600px) {
    height: 75vw;
  }
`
const Map__Type__Tabs__Holder = styled(FlexBox)`
  flex-direction: initial;
  flex-wrap: nowrap;
`
const Map__Loader__Holder = styled(FlexBox)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 10;
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.9)};
`
const Map__Options__Holder = styled(FlexBox)`
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 300px;
  gap: 10px;
`
const Option__Box = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: initial;
  border: 1px solid ${({theme}) => theme.color};
  padding: 15px;
  border-radius: 10px;
  transition: box-shadow 200ms;
  text-decoration: none !important;

  &:hover {
    box-shadow: ${({theme}) => `0px 0px 16px 0px ${rgba(theme.color, 0.6)}`};
  }

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
`
const Option__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-left: 10px;
  @media (max-width: 600px) {
    margin-left: 0px;
  }
`

const Option__Image = styled(Image)`
  width: 240px;
  height: 160px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 5px;
  @media (max-width: 600px) {
    width: 100%;
    height: calc(50vw - 40px);
  }
`
const Option__Name = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  position: relative;
  flex-direction: initial;
  flex-wrap: nowrap;
`
const Hotel__Rating__Star = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  color: ${({theme}) => theme.ratingStarColor};
`
const Attraction__Dollar = styled(FontAwesomeIcon)`
  width: 15px;
  height: 15px;
  color: ${({theme}) => theme.ratingStarColor};
`

const Hotel__Rating__Stars__Holder = styled(FlexBox)`
  flex-direction: initial;
  flex-wrap: nowrap;
  margin-left: 4px;
`
const Title__Holder = styled(FlexBox)`
  flex-direction: initial;
  flex-wrap: nowrap;
`
const Hotel__Location = styled(Text)`
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Hotel__Location__Icon = styled(FontAwesomeIcon)`
  height: 14px;
  width: 14px;
  margin-top: 2px;
  margin-right: 4px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Hotel__Location__Holder = styled(FlexBox)`
  flex-direction: initial;
  flex-wrap: nowrap;
  margin-top: 4px;
`
const Hotel__Description = styled(Text)`
  margin-top: 10px;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: calc(6em);
  -webkit-line-clamp: 5;
  overflow: hidden;
  -webkit-box-orient: vertical;
`
const Price__Wise__ToolTip = styled(FlexBox)`
  padding: 2px;
  flex-direction: column;
  min-width: 200px;
`
const Price__Wise__ToolTip__Title = styled(Text)`
  font-weight: bold;
  margin-bottom: 10px;
`
const Price__Wise__Option = styled(FlexBox)`
  flex-wrap: nowrap;
  padding: 2px 0;
`
const Price__Wise__Star__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 45px;
  flex-shrink: 0;
`
const Price__Wise__Option__Value = styled(Text)<{ isSelected: boolean }>`
  margin-left: auto;
  color: ${({theme, isSelected}) => isSelected ? theme.ratingStarColor : theme.color};
`
const More__Options__Button = styled(Button)`
  margin: 0 auto;
  padding: 0 15px;
  min-width: 150px;
`
const Drawer__Options__Holder = styled(FlexBox)`
  flex-direction: column;
  gap: 10px 0;
  padding: 10px;
`

export const Styled = {
    Drawer__Options__Holder,
    More__Options__Button,
    Price__Wise__Option__Value,
    Price__Wise__Star__Holder,
    Price__Wise__Option,
    Price__Wise__ToolTip__Title,
    Price__Wise__ToolTip,
    Hotel__Description,
    Hotel__Location__Holder,
    Hotel__Location__Icon,
    Hotel__Location,
    Title__Holder,
    Hotel__Rating__Stars__Holder,
    Hotel__Rating__Star,
    Attraction__Dollar,
    Option__Name,
    Option__Image,
    Option__Content__Holder,
    Option__Box,
    Map__Options__Holder,
    Map__Loader__Holder,
    Map__Type__Tabs__Holder,
    Region__Map,
    Region__Map__Content__Holder
}