import {Styled} from "./RegionAttraction.styles";
import React from "react";
import {useTranslation} from "react-i18next";

type FiltersProps = {
    isOpen: boolean
    onApply: () => void
    onClear: () => void
}

const Filters = (
    {
        isOpen,
        onApply,
        onClear
    }: FiltersProps
) => {

    const {t} = useTranslation()

    const content = (
        <Styled.Filters__Content>
            cotent
        </Styled.Filters__Content>
    )

    const controls = (
        <Styled.Filters__Controls__Holder>
            <Styled.Filter__Controls__Button
                onClick={onClear}
                label={t('lbl_clear')}
            />
            <Styled.Filter__Controls__Button
                onClick={onApply}
                label={t('lbl_apply')}
            />
        </Styled.Filters__Controls__Holder>

    )

    return (
        <Styled.FiltersHolder
            isOpen={isOpen}
        >
            {content}
            {controls}
        </Styled.FiltersHolder>
    )

}

export default Filters