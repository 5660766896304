import PageHolder from "../../lib/UI/PageHolder";
import {Styled} from "../RegionAttractionsPage/RegionAttraction.styles";
import {hotelOptions} from "../../components/Sections/SectionTypes/RegionMap/RegionMap";
import React from "react";
import HotelBox from "../RegionPage/HotelBox";
import {hotelOption} from "../../lib/Types";
import PageTitle from "../../components/PageTitle";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const RegionHotelsPage = () => {


    const HotelsPageValues = {
        backgroundImage: 'https://lh3.googleusercontent.com/proxy/c85B1OpOcl_dOPImG-Bcc4xvLjAwA5UeTTfW_4qAL__TUUzjSwH2t10klsEcIpa8FsbRso3uCvFh5VIoLJk0t2WmBNobH2MuMFtfhFN4eHSyivx7LRU9vJ1274SrDYgjwjCuBuICEE2I3wuVlW4NhkbaQvvmHQ=s1360-w1360-h1020'
    }

    const {countryName, RegionName} = useParams()

    const {
        backgroundImage
    } = HotelsPageValues

    const {t} = useTranslation()

    return (
        <PageHolder
            backgroundImage={backgroundImage}
        > <PageTitle
            title={`${t('lbl_hotels')} ${t('lbl_in')} ${RegionName}`}
        />
            <Styled.Region__Options__Content__Holder>
                {hotelOptions.map((option) => (
                    <HotelBox
                        key={'attraction_page_' + option.name}
                        linkPrefix={window.location.origin + window.location.pathname}
                        option={option as hotelOption}
                    />
                ))}
            </Styled.Region__Options__Content__Holder>
        </PageHolder>
    )
}
export default RegionHotelsPage