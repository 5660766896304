import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import Image from "../../../lib/UI/Image";

const Advertisement__Box__Holder = styled(FlexBox)`
  width: calc(100% - 10px);
  height: 219px;
  margin: 0 5px;
  @media (max-width: 1100px) {
    height: calc((100vw - 231px) / 4);
    width: calc(100vw - 231px);
  }
  @media (max-width: 900px) {
    width: calc(100vw);
    margin: 0;
    height: 25vw;
  }
  @media (max-width: 600px) {
    height: 33vw;
  }
`
const Advert__Image = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Advertisement__Anchor = styled.a`
  width: 100%;
  cursor: pointer;
  position: relative;
  height: 100%;
`

export const Styled = {
    Advertisement__Anchor,
    Advert__Image,
    Advertisement__Box__Holder
}