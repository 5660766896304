const AppConfigContext = {
    isOpenDrawer: false
}
const MainPageContext = {
    isLoading: true
}

const AdminPageContext = {
    company: 'World Of Regions',
    accountStatus: 'active',
    companyFunction: 'Owner',
    userName: 'Maks',
    quickActions: [
        {

            type: 'see_analytics',
            title: 'My Analytics'
        },
        {
            type: 'plan_advertisement',
            title: 'Plan Advertisement'
        },
        {
            type: 'new_article',
            title: 'Write an Article'
        },
        {
            type: 'manage_page',
            title: 'Manage Page Content'
        },
    ]
}


export {
    AdminPageContext,
    MainPageContext,
    AppConfigContext
}