enum RegionEnum {
    hotel = 'hotels',
    attractions = 'attractions',
}

interface hotelOption {
    name: string,
    image: string,
    rating: number,
    location: string,
    description: string
}

interface attractionOption {
    name: string
    span: string
    description: string
    image: string
    priceWise: number
}


interface RegionOptions {
    [RegionEnum.hotel]?: hotelOption[],
    [RegionEnum.attractions]?: attractionOption[]
}

interface RegionMapType {
    type: RegionEnum,
    isLoading: boolean,
    isDrawerOpen: boolean,
    options: RegionOptions
}


export {
    RegionEnum
}
export type {
    hotelOption,
    attractionOption,
    RegionOptions,
    RegionMapType
}
