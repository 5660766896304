import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Image from "../../lib/UI/Image";
import {rgba} from "polished";

const Country__Title = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: auto 0;
  padding: 10px;
  background-color: ${({theme}) => theme.backgroundColor};
  color: ${({theme}) => theme.color};
  font-size: 4vw;
  font-weight: bold;

  @media (min-width: 1100px) {
    font-size: 44px;
  }
  @media (max-width: 800px) {
    font-size: 30px;
  }
  @media (max-width: 400px) {
    font-size: 20px;
  }
`
const Country__Image = styled(Image)`
  width: 100%;
  padding: 15px 0;
  background-color: ${({theme}) => theme.backgroundColor};
  height: 50vw;

  @media (min-width: 1100px) {
    height: 550px;
  }
  @media (max-width: 800px) {
    height: 75vw;
  }
`
const Country__Separator = styled(FlexBox)`
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.color};
`
const Country__Section__Background = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: auto;
  background-color: ${({theme}) => theme.backgroundColor};
`
const Country__Left__Background = styled(FlexBox)<{ src: string }>`
  display: none;
  background-image: url(${({src}) => src});
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  @media (min-width: 1100px) {
    display: flex;
    position: fixed;
    left: 0px;
    width: calc(calc(100vw - 1100px) / 2);
    top: 60px;
    bottom: 0px;
  }
`

const Country__Right__Background = styled(FlexBox)<{ src: string }>`
  display: none;
  background-image: url(${({src}) => src});
  background-position: left;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  @media (min-width: 1100px) {
    display: flex;
    position: fixed;
    right: 0px;
    width: calc(calc(100vw - 1100px) / 2);
    top: 60px;
    bottom: 0px;
  }
`
const Country__Map = styled(FlexBox)`
  width: 100%;
  height: 550px;


  @media (max-width: 1100px) {
    height: 50vw;
  }
  @media (max-width: 600px) {
    height: 75vw;
  }
`
const Region__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  flex-wrap: nowrap;
  height: auto;
`
const Places__Holder = styled(FlexBox)`
  margin-top: 15px;
  width: 100%;
  gap: 10px;
`
const Country__Flag = styled(Image)`
  margin: auto 10px;
  height: 30px;
  width: 60px;
  object-fit: fill;
  display: none;

  @media (max-width: 800px) {
    display: flex;
  }

  @media (max-width: 400px) {
    height: 25px;
    width: 50px;
  }

`
const Country__Title__Holder = styled(FlexBox)`
  flex-direction: initial;
  flex-wrap: nowrap;
  padding: 5px 0;

`
const Country__Map__Loader = styled(FlexBox)`
  width: 100%;
  height: 550px;


  @media (max-width: 1100px) {
    height: 50vw;
  }
  @media (max-width: 600px) {
    height: 75vw;
  }
  @keyframes skeletonColor {
    0% {
      background: ${({theme}) => rgba(theme.color, 0.3)}
    }
    50% {
      background: ${({theme}) => rgba(theme.color, 0.1)}
    }
    100% {
      background: ${({theme}) => rgba(theme.color, 0.3)}
    }
  }
  animation: skeletonColor 2s linear infinite;
`
const Place__Option__PlaceHolder = styled(FlexBox)`
  width: calc(25vw - 12.5px);
  height: 80px;
  border-radius: 5px;
  @media (min-width: 1100px) {
    width: 262.5px;
  }
  @media (max-width: 800px) {
    width: calc(33.33vw - 14px)
  }
  @media (max-width: 650px) {
    width: calc(50vw - 15px)
  }
  @media (max-width: 450px) {
    width: calc(100vw - 20px)
  }
  animation: skeletonColor 2s linear infinite;
`


export const Styled = {
    Place__Option__PlaceHolder,
    Country__Map__Loader,
    Country__Title__Holder,
    Country__Flag,
    Places__Holder,
    Region__Holder,
    Country__Map,
    Country__Right__Background,
    Country__Left__Background,
    Country__Section__Background,
    Country__Separator,
    Country__Image,
    Country__Title,
}