import styled from "styled-components";
import FlexBox from "../../../lib/UI/FlexBox";
import Image from "../../../lib/UI/Image";
import Text from "../../../lib/UI/Text";
import {rgba} from "polished";

const Second__Articles__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Wide__Screen__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  @media (max-width: 600px) {
    display: none;
  }
`

const Second__Articles__Main__Article__Holder = styled.a`
  display: flex;
  width: calc((100vw - 231px) / 2);
  height: calc((100vw - 231px) / 2);
  flex-shrink: 0;
  flex-direction: column;
  flex-wrap: nowrap;
  transition: background-color 200ms;
  cursor: pointer;
  @media (min-width: 1100px) {
    width: 434.5px;
    height: 434.5px;
  }
  @media (max-width: 900px) {
    width: 50vw;
    height: 50vw;
  }
  @media (max-width: 600px) {
    width: 100vw;
    height: 100vw;
    border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
  }

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.1)};
  }
`
const Second__Articles__Top__Articles__Holder = styled(FlexBox)`
  width: 100%;
  min-height: 90px;
  height: calc((100vw - 231px) / 8);
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.6)};

  @media (max-width: 900px) {
    height: calc((100vw - 231px) / 6);
  }
  @media (min-width: 1100px) {
    height: 114px;
  }
`
const Second__Articles__Bottom__Articles__Holder = styled(FlexBox)`
  width: 100%;
  min-height: 90px;
  height: calc((100vw - 231px) / 8);
  border-top: 1px solid ${({theme}) => rgba(theme.color, 0.6)};

  @media (max-width: 900px) {
    height: calc((100vw - 231px) / 6);
  }
  @media (min-width: 1100px) {
    height: 114px;
  }
`
const Second__Articles__Middle__Articles__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
`
const Middle__Small__Articles__Holder = styled(FlexBox)`
  width: calc((100vw - 211px) / 4);
  height: calc((100vw - 231px) / 2);
  @media (max-width: 900px) {
    width: 25vw;
    height: 50vw;
  }
  @media (min-width: 1100px) {
    width: 222.25px;
    height: 434.5px;
  }
`
const Section__Title = styled.h2`
  font-size: 2.5vw;
  color: ${({theme}) => theme.color};
  font-weight: bold;
  padding: 0 10px;
  margin: 5px 0;
  @media (min-width: 1100px) {
    font-size: 27px;
  }
  @media (max-width: 800px) {
    font-size: 20px;
  }
  @media (max-width: 400px) {
    font-size: 20px;
  }
`
const Main__Article__Image = styled(Image)`
  height: 100%;
  width: 100%;
  flex-shrink: 1;
  object-fit: cover;

`
const Main__Article__Title = styled.h3`
  margin: 0;
  padding: 0 5px;
  padding-top: 2px;
  margin-bottom: 10px;
  height: 50px;
  flex-shrink: 0;
  color: ${({theme}) => theme.color};
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 2em;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 23px;
  -webkit-box-orient: vertical;
  font-size: 24px;
`
const Top__Bottom__Title = styled.h3`
  margin: 0;
  padding: 0 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 10px;
  flex-shrink: 0;
  color: ${({theme}) => theme.color};
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 3em;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-height: 20px;
  -webkit-box-orient: vertical;
  font-size: 20px;
`

const Main__Article__Place = styled(Text)`
  padding: 0 5px;
  padding-top: 5px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Top__Bottom__Article = styled.a`
  padding: 5px;
  align-content: center;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  display: flex;
  width: calc(100% / 3);
  height: calc(100%);
  transition: background-color 200ms;
  cursor: pointer;
  box-sizing: border-box;
  border-right: 1px solid ${({theme}) => rgba(theme.color, 0.6)};


  &:last-of-type {
    border-right: none;
  }

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.1)};
  }
`
const Side__Article = styled.a`
  padding: 5px;
  align-content: center;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  display: flex;
  width: calc(100%);
  height: calc(100% / 3);
  box-sizing: border-box;
  transition: background-color 200ms;
  cursor: pointer;
  border-bottom: 1px solid ${({theme}) => rgba(theme.color, 0.6)};


  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.1)};
  }
`
const Small__Screen__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  display: none;
  @media (max-width: 600px) {
    display: flex;
  }
`
const Second__Medium__Articles__Other__Articles = styled(FlexBox)`
  width: 100%;
  height: auto;
  flex-direction: column;
  flex-wrap: nowrap;
`
const Second__Small__Articles__Other__Articles = styled(FlexBox)`
  display: none;
  width: 100%;
  height: auto;
  @media (max-width: 500px) {
    display: flex;
  }
`

const Other__Article = styled.a`
  flex-direction: column;
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  width: calc(100% / 3);
  height: 110px;
  padding: 10px 5px;
  border-right: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
  flex-shrink: 0;
  @media (max-width: 500px) {
    width: 50%;
  }


  &:last-of-type {
    border-right: none;
  }


  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.1)};
  }
`
const Second__Articles__Medium__Row__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
  height: auto;
`

export const Styled = {
    Second__Small__Articles__Other__Articles,
    Second__Articles__Medium__Row__Holder,
    Other__Article,
    Second__Medium__Articles__Other__Articles,
    Small__Screen__Content__Holder,
    Wide__Screen__Content__Holder,
    Side__Article,
    Top__Bottom__Title,
    Top__Bottom__Article,
    Main__Article__Place,
    Main__Article__Title,
    Main__Article__Image,
    Section__Title,
    Middle__Small__Articles__Holder,
    Second__Articles__Middle__Articles__Holder,
    Second__Articles__Bottom__Articles__Holder,
    Second__Articles__Top__Articles__Holder,
    Second__Articles__Main__Article__Holder,
    Second__Articles__Holder
}