import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Text from "../../lib/UI/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {rgba} from "polished";
import Image from "../../lib/UI/Image";

const Travel__Tips__Content__Holder = styled(FlexBox)`
  padding: 0 10px;
  margin-top: 20px;
  flex-direction: column;

`
const Travel__Tips__Title = styled(Text)`
  padding-bottom: 15px;
  color: ${({theme}) => theme.color};
  font-size: 4vw;
  font-weight: bold;

  @media (min-width: 1100px) {
    font-size: 44px;
  }
  @media (max-width: 800px) {
    font-size: 30px;
  }
  @media (max-width: 400px) {
    font-size: 20px;
  }
`
const Travel__Tips__Subtitle = styled(Text)`
  font-size: 18px;
  margin-bottom: 40px;
`
const Travel__Tips__General__Content = styled(FlexBox)`
  margin-bottom: 20px;
  flex-direction: column;
`
const Travel__Tips__General__Option = styled(FlexBox)`
  flex-direction: column;
  width: 25%;
  height: 400px;
`
const Travel__Tips__General_Filters__Holder = styled(FlexBox)`
  margin-bottom: 20px;
  flex-direction: column;
`
const Travel__Tips__Show__Filters__Label = styled(Text)`
  margin-left: auto;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: 200ms color;

  &:hover {
    color: ${({theme}) => rgba(theme.color, 0.8)};
  }
`
const Travel__Tips__Show__Filters__Icon = styled(FontAwesomeIcon)`
  margin-left: 4px;
`
const Travel__Tips__Filter__Box__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 10px 0;
`
const Travel__Tips__Country__Section__Holder = styled(FlexBox)`
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 30px;
`
const Travel__Tips__Country__Options = styled(FlexBox)`

`
const Travel__Tips__Country__Image = styled(Image)`
  width: 100%;
  height: 100%;
  transition: 200ms;
  -webkit-filter: grayscale(30%);

`
const Travel__Tips__Country__Option = styled.a`
  display: flex;
  text-decoration: none;
  flex-direction: column;
  width: calc(100% / 4);
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 5px;
  transition: 200ms;

  &:hover ${Travel__Tips__Country__Image} {
    -webkit-filter: grayscale(0%);
  }

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.1)};
  }

  @media (max-width: 900px) {
    width: calc(100% / 3);
  }
  @media (max-width: 600px) {
    width: 50%;
  }

`

const Travel__Tips__Country__Flag = styled(Image)`
  position: absolute;
  margin-top: 10px;
  margin-left: calc(100% - 30% - 10px);
  width: 30%;
  aspect-ratio: 2 /1;
  object-fit: contain;
  background-color: transparent;
  object-position: center;
  z-index: 100;
`
const Travel__Tips__Country__Option__Image__Holder = styled(FlexBox)`
  position: relative;
  width: 100%;
  aspect-ratio: 1.5 / 1;
  border-radius: 5px;
  overflow: hidden;
`
const Travel__Tips__Country__Title = styled(Text)`
  font-weight: bold;
  font-size: 20px;
  padding-top: 8px;
`
const Travel__Tips__Country__Continent__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-top: 10px;
`
const Travel__Tips__Country__Continent__Title = styled(Text)`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 5px;

`


export const Styled = {
    Travel__Tips__Country__Continent__Title,
    Travel__Tips__Country__Continent__Holder,
    Travel__Tips__Country__Title,
    Travel__Tips__Country__Option__Image__Holder,
    Travel__Tips__Country__Flag,
    Travel__Tips__Country__Image,
    Travel__Tips__Country__Option,
    Travel__Tips__Country__Options,
    Travel__Tips__Country__Section__Holder,
    Travel__Tips__Filter__Box__Holder,
    Travel__Tips__Show__Filters__Icon,
    Travel__Tips__Show__Filters__Label,
    Travel__Tips__General_Filters__Holder,
    Travel__Tips__General__Option,
    Travel__Tips__General__Content,
    Travel__Tips__Subtitle,
    Travel__Tips__Title,
    Travel__Tips__Content__Holder
}