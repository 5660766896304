import {Styled} from "./AdminPage.styles"
import PageHolder from "../../lib/UI/PageHolder";
import WelcomeSection from "./components/WelcomeSection";
import AdminPageProvider from "../../lib/Context/Providers/AdminPageProvider";
import QuickActionSection from "./components/QuickActionSection";
import Options from "./components/Options";

const AdminPage = () => {

    return (
        <AdminPageProvider>
            <PageHolder>
                <Styled.Admin__Page__Holder>
                    <WelcomeSection/>
                    <QuickActionSection/>
                    <Options/>
                </Styled.Admin__Page__Holder>
            </PageHolder>
        </AdminPageProvider>
    )
}

export default AdminPage