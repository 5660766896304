import NavigationDrawer from "../Navigation/NavigationDrawer";
import SeasonModal from "../Navigation/SeasonLanguageModal";

const AdditionalModules = () => {

    return (
        <>
            <SeasonModal/>
            <NavigationDrawer/>
        </>
    )
}

export default AdditionalModules
