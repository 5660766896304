import styled from "styled-components";
import FlexBox from "../../lib/UI/FlexBox";
import Image from "../../lib/UI/Image";
import {Page__Loader__options} from "./index";


const Page__Holder = styled(FlexBox)<{ animationtype: string }>`
  @keyframes LogoPulsate {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyFrames endLoadingLogo {
    0% {
      width: 40%;
    }
    50% {
      width: 0%;
    }
    100% {
      width: 0%;
    }
  }
  @keyFrames endLoadingHolder {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  position: fixed;
  z-index: 99999999;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: ${({theme}) => theme.backgroundColor};
  animation: ${({animationtype}) => animationtype === Page__Loader__options.end ? 'endLoadingHolder 2s linear' : undefined};
`
const Loader__WOR__Logo = styled(Image)<{ animationtype: string }>`
  width: 40%;
  max-width: 400px;
  background-color: transparent;
  margin: auto;
  transition: width 200ms;
  animation: ${({animationtype}) => animationtype === Page__Loader__options.end ? 'endLoadingLogo 2s linear' : 'LogoPulsate 2s linear infinite'};
`


export const Styled = {
    Page__Holder,
    Loader__WOR__Logo
}